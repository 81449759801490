import { EnumCurrentStatus } from '@Types/Apis/Report';
import TextTransformList from '../textTransform.json';
import RegionTransformList from '../regionTransform.json';

/** 리포트 상태 포멧변환 */
export const statusFormatter = (val?: string | null) => {
  if (!val) return '';
  else {
    if (val === 'initial0') return '초안';
    else if (val === 'analysis') return '작성중';
    else return '발행';
  }
};

/** 날짜시간 변환 2024-02-19T00:51:00.000Z => 2024-02-19 00:51:00 */
export const dateFormatter = (val: string | null, type: 'date' | 'datetime', krOff?: boolean) => {
  if (val === null || val === '') return '-';

  const processedDate = new Date(val);
  processedDate.setHours(processedDate.getHours() + 9);

  if (krOff) {
    if (type === 'date') {
      return val.split('T')[0];
    } else {
      return val.split('.')[0].replace('T', ' ');
    }
  } else {
    if (type === 'date') {
      return processedDate.toISOString().split('T')[0];
    } else {
      return processedDate.toISOString().split('.')[0].replace('T', ' ');
    }
  }
};

export const enumFormatter = (value: string) => {
  const list: { [key: string]: { eng: string; kr: string } } = TextTransformList;

  return list[value]?.eng || value;
};

export const currentStatusFormatter = (type: EnumCurrentStatus) => {
  switch (type) {
    case EnumCurrentStatus.waiting:
      return '대기중';
    case EnumCurrentStatus.writing:
      return '작성중';
    case EnumCurrentStatus.review:
      return '검토상태';
    case EnumCurrentStatus.creationFailed:
      return '생성실패';
    case EnumCurrentStatus.analyzing:
      return '분석중';
    case EnumCurrentStatus.analysisFailed:
      return '분석실패';
    case EnumCurrentStatus.issueCompleted:
      return '발행완료';
    case EnumCurrentStatus.initial0:
      return '초안';
    case EnumCurrentStatus.analysis:
      return '검토';
    case EnumCurrentStatus.publish0:
      return '발행';
  }
};

export const byteSizeFormatter = (size: number) => {
  const byteUnits = ['KB', 'MB', 'GB', 'TB'];

  for (let i = 0; i < byteUnits.length; i++) {
    size = Math.floor(size / 1024);

    if (size < 1024) return size.toFixed(1) + byteUnits[i];
  }
};

export const regionCodeFormatter = (code: string) => {
  const list: { [key: string]: string } = RegionTransformList;

  return list[code];
};
