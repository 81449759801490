import VerticalSplitLinkGroup from 'components/v2/VerticalSplitLinkGroup';
import { useEffect, useState } from 'react';
import { termLinks } from 'utils/Links';
import BackIcon from 'assets/svgs/v2/ico_back.svg';
import EmailIcon from 'assets/svgs/v2/ico_mail_title.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import { useMutation } from 'react-query';

import apis from 'apis/v2';
import { useAuth } from 'contexts/AuthProvider';

const SendEmail = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { state } = useLocation();

  const [timer, setTimer] = useState(0);

  const { mutateAsync: _reqEmailById } = useMutation((data: { userId: string }) => apis.SignUp.reqEmailById(data))

  const sendEmail = () => {
    _reqEmailById(state.userId).then(({data}) => {
      if(data.result == ErrorCode.SUCCESS) {
        useToast(ErrorCode.SUCCESS, 'Verification email sent successfully.');
      } else {
        useToast(ErrorCode.UNKNOWN, 'Verification email is failed.');
      }
    })
  };

  const startTimer = () => {
    sendEmail();
    setTimer(20);
  };

  //화면 첫 진입 시 이메일 보내야함.
  useEffect(() => {
    if (!state) navigate('/auth');
  },[state]);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {setTimer(prev => prev - 1);},1000);
    }
  },[timer]);


  return (
    <>
      <article id="join-send-email-article">
        {process.env.REACT_APP_TYPE === 'PROD' ?
        <div className="prod-content">
          <h3><img src={EmailIcon} width={32} height={32} />Thank you for joining us. </h3>
          <h4>
          Your requested account has been created. However, the <br/>
          service can only be used after approval. We will assist you so <br/>
          that you can use it after verification. Thank you.
          </h4>
          <h5>
          Contact: curidy@lgcns.com
          </h5>
          <div className="button-group">
            <button
              className="big-sub-btn" 
              onClick={() => logout()}
            >
              Ok
            </button>
          </div>
        </div>
        : 
        <>
        <div className="header">
          <button type="button" onClick={() => navigate(-1)}>
            <img src={BackIcon} width={26} height={26} />
          </button>
        </div>
        <div className="content">
          <h3><img src={EmailIcon} width={32} height={32} />Verify your email</h3>
          <h4>
            You’re almost done! Complete the sign-up by verifying your email.
            <br />
            <br />
            Verification email sent.<br />
            Open your email and click the ‘Verify’ button.
          </h4>
          <h5>
            Didn’t receive the verification email? Check your spam folder.
          </h5>
          <div className="button-group">
            <button 
              className="big-sub-btn" 
              type="button" 
              onClick={startTimer} 
              disabled={timer > 0}
            >
              {timer > 0 ? `Resend email (${timer}s)` : 'Resend email'}
            </button>
            <button
              className="big-main-btn" 
              onClick={() => logout()}
            >
              Login
            </button>
          </div>
        </div>
        </>
        }
      </article>
      <VerticalSplitLinkGroup list={termLinks} />
    </>
  );
};
export default SendEmail;