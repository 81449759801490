import { gql } from '@apollo/client';

const query = gql`
  query getAwsDescribeManagedProductsByVendor(
    $request: AwsDescribeManagedProductsByVendorRequest
    $cloudId: Long
    $region: String
  ) {
    getAwsDescribeManagedProductsByVendor(
      describeManagedProductsByVendorRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsDescribeManagedProductsByVendorResponse {
          managedProducts {
            vendorName
            managedRuleSetName
            productId
            productLink
            productTitle
            productDescription
            snsTopicArn
            isVersioningSupported
            isAdvancedManagedRuleSet
          }
        }
      }
    }
  }
`;
export default query;
