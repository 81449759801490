import { gql } from '@apollo/client';

const query = gql`
query getMembersByOrganId($reqData: ReqGetMemberPage) {
  getMembersByOrganId(reqData: $reqData) {
    result
    totalPages
    totalElements
    content {
      ... on Member {
        id
        fullName
        userId
        projectCount
        createdAt
        modifiedAt
        systemAuthCode
        ownerYn
      }
    }
  }
}
`;
export default query;