import IpRegex from './IpRegex';

type Options = {
  exact?: boolean;
}

const defaultOpts: Options = {exact: false};
const v4str = `${IpRegex.v4().source}\\/(3[0-2]|[12]?[0-9])`;
const v6str = `${IpRegex.v6().source}\\/(12[0-8]|1[01][0-9]|[1-9]?[0-9])`;

const v4exact = new RegExp(`^${v4str}$`);
const v6exact = new RegExp(`^${v6str}$`);
const v46exact = new RegExp(`(?:^${v4str}$)|(?:^${v6str}$)`);

const CidrRegex = ({exact}: Options = defaultOpts) => exact ? v46exact : new RegExp(`(?:${v4str})|(?:${v6str})`, "g");
export const v4 = CidrRegex.v4 = ({exact}: Options = defaultOpts) => exact ? v4exact : new RegExp(v4str, "g");
export const v6 = CidrRegex.v6 = ({exact}: Options = defaultOpts) => exact ? v6exact : new RegExp(v6str, "g");

export default CidrRegex;