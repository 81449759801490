import { gql } from '@apollo/client';

const query = gql`
  query getHistReportFiles($reqGetReportFileList: ReqGetReportFileList) {
    getHistReportFiles(reqGetReportFileList: $reqGetReportFileList) {
      __typename
      result
      size
      number
      totalElements
      totalPages
      content {
        __typename
        ... on ReportFile {
          __typename
          id
          reportId
          fileName
          statusCode
          comment
          version
          modifiedByName
          modifiedAt
        }
      }
    }
  }
`;

export default query;