import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { ActionDropdownListDataType } from 'layouts/v3/MgdLayout/components/ActionDropdownAtom';
import MgdLayout, {
  DetailTypeEnum,
  IMgdDetailContentProps,
  IMgdDetailKeyValueProps,
  IMgdDetailTableProps,
  IMgdExtraOverviewDetailContentProps,
  IMgdMainExtraContentProps,
  IMgdTablePaginationProps,
  IMgdTabProps,
  IMgdTotalPageProps,
  MgdLayoutTypeEnum,
  TextTypeEnum,
} from 'layouts/v3/MgdLayout';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import lazyGetAwsAllListFirewallPolicies from 'graphql/queries/getAwsAllListFirewallPolicies';
import {
  detailFirewallPolicy,
  detailFirewallTabs,
  FIREWALL_COMMOMVALUE_MAPPING,
  FIREWALL_RULES_PROTOCOL_MAPPING,
  FirewallConfigurationSyncStateMapping,
  FirewallStatusMapping,
  mainTabs,
} from './Common/Constant';
import lazyGetAwsAllListFirewalls from 'graphql/queries/getAwsAllListFirewallsPage';
import lazyGetAwsFirewallPage from 'graphql/queries/getAwsFirewall';
import {
  extractCertificateArnData,
  extractResourceArnData,
  getFormatedDateWithTimezone,
  getStrValueOrNull,
} from './Common/Function';
import lazyGetAwsFirewallPolicy from 'graphql/queries/getAwsFirewallPolicy';
import lazyGetAwsRuleGroupMetadata from 'graphql/queries/getAwsRuleGroupMetadata';
import lazyGetAwsTLSInspectionConfiguration from 'graphql/queries/getAwsTLSInspectionConfiguration';
import lazyGetAwsAllListRuleGroupsFirewall from 'graphql/queries/getAwsAllListRuleGroupsFirewall';
import lazyGetAwsDescribeRuleGroup from 'graphql/queries/getAwsDescribeRuleGroup';
import lazyGetAwsLoggingConfigurationFirewall from 'graphql/queries/getAwsLoggingConfigurationFirewall';
import lazyGetAwsListTlsInspectionConfigurations from 'graphql/queries/getAwsAllListTLSInspectionConfigurations';
import lazyGetAwsDescribeCertificate from 'graphql/queries/getAwsDescribeCertificate';
import { AwsServerCertificateScope } from 'graphql/types/AwsTlsInspectionConfiguration';
import lazyGetAwsAllListGroups from 'graphql/queries/getAwsAllListGroups';
import lazyGetAwsGroup from 'graphql/queries/getAwsGroup';
import lazyGetAwsGroupQuery from 'graphql/queries/getAwsGroupQuery';
import lazyGetAwsAllListGroupResources from 'graphql/queries/getAwsAllListGroupResources';
import lazyGetAwsAllResources from 'graphql/queries/getAwsAllResources';
import lazyGetAwsTags from 'graphql/queries/getAwsTags';
import FirewallCreation from 'pages/v2/Organ/Management/Firewall/FirewallCreation';
import EditFirewallDetailModal from './EditFirewallDetailModal';
import EditFirewallPolicyModal from './EditFirewallPolicy';
import ProtectionAgainstChangeModal from './ProtectionAgainstChange';
import EditKMSEncryptionModal from './EditKMSEncryptionModal';
import StatelessActionsModal from './StatelessActionsModal';
import UpdateTagsModal from './UpdateTagsModal';
import UnmanagedStateModal from './UnmanagedStateModal';
import EditPriority from './EditPriority';
import DisassociateRuleGroups from './DisassociateRuleGroups';
import DeleteResource from './DeleteResource';
import AddManagedStatefullRuleGroupModal from './AddManagedStatefulRuleGroupModal';
import EditVPCAndSubnetsModal from './EditVPCAndSubnetsModal';
import RuleGroupCreation from 'pages/v2/Organ/Management/Firewall/RuleGroupCreation';
import ManageTLSConfigurationModal from './ManageTLSConfigurationModal';
import EditFirewallPolicyDetailModal from './EditFirewallPolicyDetailModal';
import EditPolicyVariablesModal from './EditPolicyVariablesModal';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeFirewallPolicyResponse } from 'graphql/types/AwsFirewallPolicy';
import FirewallPolicyCreation from './FirewallPolicyCreation';
import { useOutletContext } from 'react-router-dom';
import FirewallPoliciesPreviewCreation from './FirewallPoliciesPreviewCreation';
import DeleteRuleModal from './DeleteRuleModal';
import EditRuleVariablesModal from './EditRuleVariablesModal';
import EditIPSetReferenceModal from './EditIPSetReferenceModal';
import EditRuleModal from './EditRuleModal';
import { orderAlphabetical } from '../Utils/Sorting';
import { variableCombineNextToken } from '../Utils';
import { handleFormatText } from 'utils/Common';

interface INetworkProps {
  projectId: number;
  cloudName: string;
  regionCode: string;
  regions: string[];
  pageBackClick: () => void;
  recentRelatedClouds: DropdownListDataType[];
  relatedCloudSelected: DropdownListDataType;
  recentRelatedCloudOnChange: (option: DropdownListDataType) => void;
}

const Firewall = ({
  projectId,
  cloudName,
  regionCode,
  regions,
  pageBackClick,
  recentRelatedClouds,
  relatedCloudSelected,
  recentRelatedCloudOnChange,
}: INetworkProps) => {
  const { defaultRegionList, updateDefaultRegionList } = useOutletContext<{
    defaultRegionList: { projectId: number; cloudId: number; name: string; value: string }[];
    updateDefaultRegionList: (projectId: number, cloudId: number, name: string, value: string) => void;
  }>();

  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [layoutType, setLayoutType] = useState<MgdLayoutTypeEnum>(MgdLayoutTypeEnum.MGD_LIST_SUMMARY);
  const [regionList, setRegionList] = useState<DropdownListDataType[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<DropdownListDataType>({
    name: '',
    value: '',
  });
  const [mainNextToken, setMainNextToken] = useState<string>('');
  const [mainCreateBtnVisible, setMainCreateBtnVisible] = useState<boolean>(false);
  const [mainCreateBtnLabel, setMainCreateBtnLabel] = useState<string>('');
  const [mainSelected, setMainSelected] = useState<string>('');
  const [mainTabSelected, setMainTabSelected] = useState<IMgdTabProps>(mainTabs[0]);
  const [mainSearchDropdown, setMainSearchDropdown] = useState<DropdownListDataType[]>();
  const [search, setSearch] = useState('');
  const [mainSearchValue, setMainSearchValue] = useState<string>('');
  const [mainFilterValue, setMainFilterValue] = useState<DropdownListDataType>({
    name: '',
    value: '',
  });
  const [isSetDefaultFilterfield, setIsSetDefaultFilterfield] = useState<boolean>(true);
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [searchData, setSearchData] = useState<RowType[]>([]);
  const [mainThreatSignatureTbl, setMainThreatSignatureTbl] = useState<{ rows: RowType[]; columns: ColumnType[] }>();
  const [mainTblColumns, setMainTblColumns] = useState<ColumnType[]>([]);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainExtraNextToken, setMainExtraNextToken] = useState<string>('');
  const [mainExtraTablePagination, setMainExtraTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const itemPerPageOfDetailTable = 10;

  const [extraOverviewDetail, setExtraOverviewDetail] = useState<IMgdExtraOverviewDetailContentProps>();
  const [detailTitle, setDetailTitle] = useState<string>('');

  const [detailTabList, setDetailTabList] = useState<IMgdTabProps[]>(detailFirewallTabs);
  const [detailTabSelected, setDetailTabSelected] = useState<IMgdTabProps>();
  const [awsFirewallPageData, setAwsFirewallPageData] = useState<any[]>([]);
  const [firewallPolicyPageData, setFirewallPolicyPageData] = useState<any[]>([]);
  const [ruleGroupPageData, setRuleGroupPageData] = useState<any[]>([]);
  const [awsRuleGroupPageData, setAwsRuleGroupPageData] = useState<any[]>([]);
  const [tlsInspectionPageData, setTlsInspectionPageData] = useState<any[]>([]);
  const [resourceGroupPageData, setResourceGroupPageData] = useState<any[]>([]);
  const [detailContents, setDetailContents] = useState<IMgdDetailContentProps[]>([]);

  const [detailTitleBtnDelVisible, setDetailTitleBtnDelVisible] = useState<boolean>(false);
  const [creationScreenName, setCreationScreenName] = useState<'FIREWALL' | 'RULE_GROUP' | 'FIREWALL_POLICY' | ''>('');
  const [editFirewallDetailModalOpened, setEditFirewallDetailModalOpned] = useState<boolean>(false);
  const [deleteResourceModalOpened, setDeleteResourceModalOpened] = useState<boolean>(false);
  const [editVpcAndSubnetsModalOpened, setEditVpcAndSubnetsModalOpened] = useState<boolean>(false);
  const [editFirewallPolicyModalOpened, setEditFirewallPolicyModalOpened] = useState<boolean>(false);
  const [protectionAgainstChangeModalOpened, setProtectionAgainstChangeModalOpened] = useState<boolean>(false);
  const [customerManageKeyModalOpened, setCustomerManageKeyModalOpened] = useState<boolean>(false);
  const [firewallTagsModalOpened, setFirewallTagsModalOpened] = useState<boolean>(false);
  const [editPriorityStatelessModalOpened, setEditPriorityStatelessModalOpened] = useState<boolean>(false);
  const [editPriorityStatefulModalOpened, setEditPriorityStatefulModalOpened] = useState<boolean>(false);
  const [statelessDefaultActionsModalOpened, setStatelessDefaultActionsModalOpened] = useState<boolean>(false);
  const [addUnManageStatelessRGModalOpened, setAddUnManageStatelessRGModalOpened] = useState<boolean>(false);
  const [addUnManageStatefulRGModalOpened, setAddUnManageStatefulRGModalOpened] = useState<boolean>(false);
  const [disassociateRGModalOpened, setDisassociateRGModalOpened] = useState<boolean>(false);
  const [deleteFirewallPolicyResourceModalOpened, setDeleteFirewallPolicyResourceModalOpened] =
    useState<boolean>(false);
  const [addManagedStatefulRGModalOpened, setAddManagedStatefulRGModalOpened] = useState<boolean>(false);
  const [manageTLSConfigurationModalOpened, setManageTLSConfigurationModalOpened] = useState<boolean>(false);
  const [editFirewallPolicyDetailsModalOpened, setEditFirewallPolicyDetailsModalOpened] = useState<boolean>(false);
  const [editPolicyVariablesModalOpened, setEditPolicyVariablesModalOpened] = useState<boolean>(false);
  const [editTagsModalOpened, setEditTagsModalOpened] = useState<boolean>(false);
  const [editFirewallRuleGroupDetailModalOpened, setEditFirewallRuleGroupDetailModalOpened] = useState<boolean>(false);
  const [editFirewallRuleVariablesModalOpened, setEditFirewallRuleVariablesModalOpened] = useState<boolean>(false);
  const [deleteFirewallRuleVariablesModalOpened, setDeleteFirewallRuleVariablesModalOpened] = useState<boolean>(false);
  const [editFirewallIpSetReferencesModalOpened, setEditFirewallIpSetReferencesModalOpened] = useState<boolean>(false);
  const [deleteFirewallIpSetReferencesModalOpened, setDeleteFirewallIpSetReferencesModalOpened] =
    useState<boolean>(false);
  const [addFirewallNewRuleModalOpened, setAddFirewallNewRuleModalOpened] = useState<boolean>(false);
  const [deleteFirewallRuleGroupModalOpened, setDeleteFirewallRuleGroupModalOpened] = useState<boolean>(false);
  const [editFirewallRuleGroupModalOpened, setEditFirewallRuleGroupModalOpened] = useState<boolean>(false);
  const [isHeaderButtonTableDetailShowed, setIsHeaderButtonTableDetailShowed] = useState<boolean>(false);
  const [statelessRuleGroupDropdownList, setStatelessRuleGroupDropdownList] = useState<ActionDropdownListDataType[]>([
    {
      id: 0,
      name: 'Actions',
      value: '',
    },
    {
      id: 1,
      name: 'Add unmanaged stateless rule groups',
      value: 'Add unmanaged stateless rule groups',
    },
    {
      id: 2,
      name: 'Disassociate from policy',
      value: 'Disassociate from policy',
      disabled: true,
    },
  ]);
  const [statefulRuleGroupDropdownList, setStatefulRuleGroupDropdownList] = useState<ActionDropdownListDataType[]>([
    {
      id: 0,
      name: 'Actions',
      value: '',
    },
    {
      id: 1,
      name: 'Add unmanaged stateful rule groups',
      value: 'Add unmanaged stateful rule groups',
    },
    {
      id: 2,
      name: 'Add managed stateful rule groups',
      value: 'Add managed stateful rule groups',
    },
    {
      id: 3,
      name: 'Disassociate from policy',
      value: 'Disassociate from policy',
      disabled: true,
    },
  ]);
  const [mainApiIsLoading, setMainApiIsLoading] = useState<boolean>(false);
  const [detailApiIsLoading, setDetailApiIsLoading] = useState<boolean>(false);
  const [extraOverviewDetailApiIsLoading, setExtraOverviewDetailApiIsLoading] = useState<boolean>(false);

  const [getEnumTypeCode] = lazyGetEnumTypeCode();
  const [getAwsAllListFirewallPolicies] = lazyGetAwsAllListFirewallPolicies();
  const [getAwsAllListFirewalls] = lazyGetAwsAllListFirewalls();
  const [getAwsFirewallPage] = lazyGetAwsFirewallPage();
  const [getAwsFirewallPolicy] = lazyGetAwsFirewallPolicy();
  const [getAwsRuleGroupMetadata] = lazyGetAwsRuleGroupMetadata();
  const [getAwsAllListTLSInspectionConfigurations] = lazyGetAwsListTlsInspectionConfigurations();
  const [getAwsTLSInspectionConfiguration] = lazyGetAwsTLSInspectionConfiguration();
  const [getAwsAllListRuleGroupsFirewall] = lazyGetAwsAllListRuleGroupsFirewall();
  const [getAwsDescribeRuleGroup] = lazyGetAwsDescribeRuleGroup();
  const [getAwsLoggingConfigurationFirewall] = lazyGetAwsLoggingConfigurationFirewall();
  const [getAwsDescribeCertificate] = lazyGetAwsDescribeCertificate();
  const [getAwsAllListGroups] = lazyGetAwsAllListGroups();
  const [getAwsGroup] = lazyGetAwsGroup();
  const [getAwsGroupQuery] = lazyGetAwsGroupQuery();
  const [getAwsAllListGroupResources] = lazyGetAwsAllListGroupResources();
  const [getAwsAllResources] = lazyGetAwsAllResources();
  const [getAwsTags] = lazyGetAwsTags();

  const cloudId = useMemo(() => {
    return Number(relatedCloudSelected.value);
  }, [relatedCloudSelected]);
  const pinButtonClickHandle = () => {
    updateDefaultRegionList(projectId, cloudId, selectedRegion.name || '', selectedRegion.value.toString());
  };

  useEffect(() => {
    const hasDefaultRegion = defaultRegionList.find(r => {
      return r.projectId === projectId && r.cloudId === cloudId;
    });
    if (!!hasDefaultRegion) {
      setSelectedRegion({
        name: hasDefaultRegion.name,
        value: hasDefaultRegion.value,
      });
    } else {
      getEnumTypeCode({
        variables: { text: regionCode || 'AwsRegionCode' },
      }).then(res => {
        if (res.data) {
          const regionsRes: DropdownListDataType[] = res.data.getEnumTypeCode.data.filter(val =>
            regions.includes(val.value),
          ).map((val) => ({
            ...val,
            name: val.value
          }));
          setRegionList(regionsRes);
          const currentRegion = regionsRes.find(
            r => r.name === selectedRegion.name && r.value === selectedRegion.value,
          );
          setSelectedRegion(currentRegion || regionsRes[0]);
        }
      });
    }
  }, [defaultRegionList]);

  const resetDetailData = () => {
    setMainSelected('');
    setDetailContents([]);
    setExtraOverviewDetail(undefined);
  };

  const handleHyperlinkMainClick = (e: any, tab: IMgdTabProps, searchVal: string, filter: DropdownListDataType) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setMainTabSelected(tab);
    setMainFilterValue(filter);
    // setMainSearchValue({
    //   name: filter.value as string,
    //   value: searchVal,
    // });
    resetDetailData();
  };

  const resetMainStates = useCallback(() => {
    setAwsFirewallPageData([]);
    setFirewallPolicyPageData([]);
    setRuleGroupPageData([]);
    setAwsRuleGroupPageData([]);
    setTlsInspectionPageData([]);
    setResourceGroupPageData([]);
    setMainThreatSignatureTbl(undefined);
  }, []);

  const mainTabClickHandler = useCallback(
    (tabSelected: IMgdTabProps) => {
      setMainTabSelected(mainTabs.find(tabItem => tabItem.id === tabSelected.id) || mainTabs[0]);

      setMainSelected('');
      setIsSetDefaultFilterfield(true);
      setMainSearchValue('');

      resetMainStates();

      switch (tabSelected) {
        case mainTabs[0]:
          getFirewallMainDataRows();
          break;
        case mainTabs[1]:
          getFirewallPolicyMainDataRows();
          break;
        case mainTabs[2]:
          getRuleGroupMainDataRows();
          break;
        case mainTabs[3]:
          getAwsManagedDomainList();
          getAwsManagedThreatSignatures();
          break;
        case mainTabs[4]:
          getTlsInspectionMainDataRows();
          break;
        case mainTabs[5]:
          getResourceGroupMainDataRows();
          break;
        default:
          break;
      }
    },
    [selectedRegion],
  );

  const onSearchKeyDownHandler = useCallback(
    (e: any) => {
      if (e.key === 'Enter') {
        if (mainSearchValue != '') {
          const rearchResult = searchData.filter(row => row.name.includes(mainSearchValue));
          setMainTblRows(rearchResult);
          setMainTblTotal({
            totalPage: Math.ceil(rearchResult.length / mainTablePagination.limit),
            totalElement: rearchResult.length,
          });
        } else {
          setMainTblRows(searchData);
          setMainTblTotal({
            totalPage: Math.ceil(searchData.length / mainTablePagination.limit),
            totalElement: searchData.length,
          });
        }
      }
    },
    [mainSearchValue, searchData, mainTablePagination],
  );

  const handleHDetailHyperlinkMainClick = (currTab: IMgdTabProps, data: any) => {
    switch (currTab) {
      case mainTabs[0]:
        break;

      default:
        break;
    }
  };

  const getVariableData = useCallback(
    (requestType: string): any => {
      if (requestType == mainTabs[0].id) {
        return {
          cloudId: cloudId,
          region: selectedRegion.value as string,
          request: {
            maxResults: mainTablePagination.limit,
            pageInfo: {
              page: mainTablePagination.currentPage - 1,
              size: mainTablePagination.limit,
              orders: [
                {
                  target: mainTablePagination.target === 'relObjectName' ? 'relObjectId' : mainTablePagination.target,
                  direction: mainTablePagination.direction,
                },
              ],
            },
          },
        };
      }
      if (requestType == mainTabs[1].id) {
        return {
          cloudId: cloudId,
          region: selectedRegion.value as string,
          request: {
            maxResults: mainTablePagination.limit,
            pageInfo: {
              page: mainTablePagination.currentPage - 1,
              size: mainTablePagination.limit,
              orders: [
                {
                  target: mainTablePagination.target === 'relObjectName' ? 'relObjectId' : mainTablePagination.target,
                  direction: mainTablePagination.direction,
                },
              ],
            },
          },
        };
      }
      if (requestType == mainTabs[2].id) {
        return {
          cloudId: cloudId,
          region: selectedRegion.value as string,
          request: {
            maxResults: mainTablePagination.limit,
            pageInfo: {
              page: mainTablePagination.currentPage - 1,
              size: mainTablePagination.limit,
              orders: [
                {
                  target: mainTablePagination.target === 'relObjectName' ? 'relObjectId' : mainTablePagination.target,
                  direction: mainTablePagination.direction,
                },
              ],
            },
          },
        };
      }
      if (requestType == mainTabs[3].id) {
        return {
          cloudId: cloudId,
          region: selectedRegion.value as string,
          request: {
            maxResults: mainTablePagination.limit,
            pageInfo: {
              page: mainTablePagination.currentPage - 1,
              size: mainTablePagination.limit,
              orders: [
                {
                  target: mainTablePagination.target === 'relObjectName' ? 'relObjectId' : mainTablePagination.target,
                  direction: mainTablePagination.direction,
                },
              ],
            },
          },
        };
      }
      if (requestType == mainTabs[4].id) {
        return {
          cloudId: cloudId,
          region: selectedRegion.value as string,
          request: {
            maxResults: mainTablePagination.limit,
            pageInfo: {
              page: mainTablePagination.currentPage - 1,
              size: mainTablePagination.limit,
              orders: [
                {
                  target: mainTablePagination.target === 'relObjectName' ? 'relObjectId' : mainTablePagination.target,
                  direction: mainTablePagination.direction,
                },
              ],
            },
          },
        };
      }
      if (requestType == mainTabs[5].id) {
        return {
          cloudId: cloudId,
          region: selectedRegion.value as string,
          request: {
            maxResults: mainTablePagination.limit,
            pageInfo: {
              page: mainTablePagination.currentPage - 1,
              size: mainTablePagination.limit,
              orders: [
                {
                  target: 'groupName',
                  direction: mainTablePagination.direction,
                },
              ],
            },
            filters: {
              name: 'configuration-type',
              values: ['AWS::NetworkFirewall::Index'],
            },
          },
        };
      }
      return {
        cloudId: cloudId,
        region: selectedRegion.value as string,
        request: {
          maxResults: mainTablePagination.limit,
        },
      };
    },
    [selectedRegion, mainTablePagination, cloudId],
  );

  // INIT LOADING
  useEffect(() => {
    resetDetailData();
    resetMainStates();
    getEnumTypeCode({
      variables: {
        text: regionCode || 'AwsRegionCode',
      },
    }).then(res => {
      if (res.data) {
        const regionsRes: DropdownListDataType[] = res.data.getEnumTypeCode.data.filter(val =>
          regions.includes(val.value),
        ).map((val) => ({
          ...val,
          name: val.value
        }));
        setRegionList(regionsRes);
        setSelectedRegion(regionsRes[0]);
      }
    });
  }, [cloudId]);

  // this effect for getting data for list-up
  useEffect(() => {
    let summaryTabs: IMgdTabProps[] = detailFirewallTabs;
    setMainExtraTablePagination(prev => ({ ...prev, currentPage: 1 }));
    setMainThreatSignatureTbl(undefined);

    switch (mainTabSelected) {
      case mainTabs[0]: // Firewall tab
        summaryTabs = detailFirewallTabs;
        getFirewallMainDataRows();
        getFirewallMainDataColumns();
        break;
      case mainTabs[1]: // Firewall Policy tab
        summaryTabs = detailFirewallPolicy;
        getFirewallPolicyMainDataRows();
        getFirewallPolicyMainDataColumns();
        break;
      case mainTabs[2]: // Rule group tab
        summaryTabs = [];
        getRuleGroupMainDataRows();
        getRuleGroupMainDataColumns();
        break;
      case mainTabs[3]: // AWS rule group tab
        summaryTabs = [];
        getAwsManagedDomainList();
        getAwsManagedThreatSignatures();
        getAwsRuleGroupMainDataColumns();
        break;
      case mainTabs[4]: // TLS inspection tab
        summaryTabs = [];
        getTlsInspectionMainDataRows();
        getTlsInspectionMainDataColumns();
        break;
      case mainTabs[5]: // Resource group tab
        summaryTabs = [];
        getResourceGroupMainDataRows();
        getResourceGroupMainDataColumns();
        break;
      default:
        break;
    }
    setDetailTabList(summaryTabs);
  }, [mainTabSelected, selectedRegion]);

  useEffect(() => {
    let detailTabSelected: IMgdTabProps = {
      id: '',
      name: '',
      title: '',
    };
    resetDetailData();
    switch (mainTabSelected) {
      case mainTabs[0]: // Firewall tab
        detailTabSelected = detailFirewallTabs[0];
        break;
      case mainTabs[1]:
        detailTabSelected = detailFirewallPolicy[0];
        break;
      case mainTabs[2]:
        setDetailTabList([]);
        break;
      case mainTabs[3]:
        break;
      case mainTabs[4]:
        setDetailTabList([]);
        break;
      case mainTabs[5]:
        setDetailTabList([]);
        break;
      default:
        detailTabSelected = {
          id: '',
          name: '',
          title: '',
        };
        break;
    }
    setDetailTabSelected(detailTabSelected);
  }, [mainTabSelected]);

  useEffect(() => {
    if (mainSelected) {
      switch (mainTabSelected) {
        case mainTabs[0]:
          getFirewallDetailData();
          break;
        case mainTabs[1]:
          getFirewallPolicyDetailData();
          break;
        case mainTabs[2]:
          getRuleGroupFirewallDetailData();
          break;
        case mainTabs[3]:
          getAwsRuleGroupFirewallDetailData();
          break;
        case mainTabs[4]:
          getTLSInspectionDetailData();
          break;
        case mainTabs[5]:
          getResourcesGroupDetailData();
          break;
        default:
          break;
      }
    }
  }, [mainSelected, detailTabSelected, isHeaderButtonTableDetailShowed]);

  useEffect(() => {
    if (mainSelected) {
      switch (mainTabSelected) {
        case mainTabs[0]:
          setDetailTabSelected(detailFirewallTabs[0]);
          break;
        case mainTabs[1]:
          setDetailTabSelected(detailFirewallPolicy[0]);
          break;
        default:
          break;
      }
    }
  }, [mainSelected]);

  useEffect(() => {
    switch (mainTabSelected) {
      case mainTabs[0]:
        setMainCreateBtnVisible(true);
        setMainCreateBtnLabel('Create firewall');
        break;
      case mainTabs[1]:
        setMainCreateBtnVisible(true);
        setMainCreateBtnLabel('Create firewall policy');
        break;
      case mainTabs[2]:
        setMainCreateBtnVisible(true);
        setMainCreateBtnLabel('Create rule group');
        break;
      default:
        setMainCreateBtnVisible(false);
        setMainCreateBtnLabel('');
        break;
    }
  }, [mainTabSelected, detailTabSelected]);

  useEffect(() => {
    switch (mainTabSelected) {
      case mainTabs[0]:
        if (layoutType === MgdLayoutTypeEnum.MGD_DETAIL) {
          setDetailTitleBtnDelVisible(true);
        }
        break;
      case mainTabs[1]:
        if (layoutType === MgdLayoutTypeEnum.MGD_DETAIL) {
          setDetailTitleBtnDelVisible(true);
        }
        break;

      default:
        setDetailTitleBtnDelVisible(false);
        break;
    }
  }, [mainTabSelected, layoutType]);

  useEffect(() => {
    if (layoutType === MgdLayoutTypeEnum.MGD_DETAIL) {
      setIsHeaderButtonTableDetailShowed(true);
    } else {
      setIsHeaderButtonTableDetailShowed(false);
    }
  }, [layoutType]);

  const getFirewallMainDataRows = useCallback(
    async (nextToken?: string) => {
      if (selectedRegion.value != '') {
        setMainApiIsLoading(true);
        const awsListAllFirewall = await getAwsAllListFirewalls({
          variables: variableCombineNextToken(getVariableData(mainTabs[0].id), nextToken),
        }).then(({ data: awsFirewallData }) => {
          return awsFirewallData?.getAwsAllListFirewalls;
        });
        if (awsListAllFirewall?.data && awsListAllFirewall.data.length > 0) {
          const fetchListAllFirewall = awsListAllFirewall.data.map(async metaData => {
            const firewallVariable = {
              cloudId,
              region: selectedRegion.value as string,
              request: {
                firewallName: metaData?.firewallName || '',
                firewallArn: metaData?.firewallArn || '',
              },
            };
            return await getAwsFirewallPage({
              variables: firewallVariable,
            }).then(({ data: awsFirewallDetail }) => {
              const getAwsFirewall = awsFirewallDetail?.getAwsFirewall?.data?.[0];
              if (getAwsFirewall) {
                return {
                  id: metaData?.firewallArn,
                  name: metaData?.firewallName,
                  status: getAwsFirewall?.firewallStatus?.status,
                  configurationState: getAwsFirewall?.firewallStatus?.configurationSyncStateSummary,
                  updateToken: getAwsFirewall?.updateToken,
                  firewall: getAwsFirewall?.firewall,
                  firewallStatus: getAwsFirewall?.firewallStatus,
                };
              }
            });
          });

          const listAllFirewallData = await Promise.all(fetchListAllFirewall);
          const firewallDataResult: RowType[] = [];
          listAllFirewallData.map(firewall => {
            firewallDataResult.push({
              id: firewall?.id,
              name: firewall?.name,
              status: firewall?.status,
              configurationState: firewall?.configurationState,
            });
          });
          const allawsFirewallPageData = [...awsFirewallPageData, ...listAllFirewallData];
          setAwsFirewallPageData(allawsFirewallPageData);
          setMainNextToken(awsListAllFirewall?.nextToken as string);
          setMainTblRows(allawsFirewallPageData);
          setSearchData(allawsFirewallPageData);
          setMainTblTotal({
            totalPage: Math.ceil(allawsFirewallPageData.length / mainTablePagination.limit),
            totalElement: allawsFirewallPageData.length,
          });
          setMainApiIsLoading(false);
        } else {
          setAwsFirewallPageData([]);
          setMainTblRows([]);
          setSearchData([]);
          setMainNextToken('');
          setMainApiIsLoading(false);
        }
      }
    },
    [selectedRegion, awsFirewallPageData, mainTablePagination, getVariableData],
  );

  const getFirewallPolicyMainDataRows = useCallback(
    (nextToken?: string) => {
      if (selectedRegion.value != '') {
        setMainApiIsLoading(true);
        const variable = variableCombineNextToken(getVariableData(mainTabSelected.id), nextToken);

        getAwsAllListFirewallPolicies({
          variables: variable,
        }).then(({ data: listFireWalls }) => {
          const getAwsAlllistFirewallPolicie = listFireWalls?.getAwsAlllistFirewallPolicies;
          if (getAwsAlllistFirewallPolicie) {
            const firewallPolicyData: RowType[] = [];
            getAwsAlllistFirewallPolicie?.data?.forEach(item => {
              firewallPolicyData.push({
                ...item,
                id: item.arn,
              });
            });

            const allFirewallPolicyData = [...firewallPolicyPageData, ...firewallPolicyData];
            setFirewallPolicyPageData(allFirewallPolicyData);
            setMainNextToken(getAwsAlllistFirewallPolicie?.nextToken as string);
            setMainTblRows(allFirewallPolicyData);
            setSearchData(allFirewallPolicyData);
            setMainTblTotal({
              totalPage: Math.ceil(allFirewallPolicyData.length / mainTablePagination.limit),
              totalElement: allFirewallPolicyData.length,
            });
            setMainApiIsLoading(false);
          } else {
            setFirewallPolicyPageData([]);
            setMainTblRows([]);
            setSearchData([]);
            setMainNextToken('');
            setMainApiIsLoading(false);
          }
        });
      }
    },
    [selectedRegion, firewallPolicyPageData, mainTablePagination, getVariableData],
  );

  const getRuleGroupMainDataRows = useCallback(
    async (nextToken?: string) => {
      if (selectedRegion.value != '') {
        setMainApiIsLoading(true);
        const variables = variableCombineNextToken(getVariableData(mainTabSelected.id), nextToken);
        const awsListAllRuleGroupsFirewall = await getAwsAllListRuleGroupsFirewall({
          variables: variables,
        }).then(({ data }) => {
          return data?.getAwsAllListRuleGroupsFirewall;
        });
        if (
          awsListAllRuleGroupsFirewall &&
          awsListAllRuleGroupsFirewall?.data &&
          awsListAllRuleGroupsFirewall?.data?.length > 0
        ) {
          const fetchListAllRuleGroupsFirewall = awsListAllRuleGroupsFirewall.data?.map(async metaData => {
            const ruleGroupFirewallVariable = {
              cloudId,
              region: selectedRegion.value as string,
              request: {
                ruleGroupName: metaData?.name || '',
                ruleGroupArn: metaData?.arn || '',
              },
            };
            return await getAwsDescribeRuleGroup({
              variables: ruleGroupFirewallVariable,
            }).then(({ data: ruleGroup }) => {
              const ruleGroupResponse = ruleGroup?.getAwsDescribeRuleGroup?.data?.[0]?.ruleGroupResponse;
              if (ruleGroupResponse) {
                return {
                  id: metaData?.arn,
                  name: metaData?.name,
                  type: ruleGroupResponse?.type === 'STATEFUL' ? 'Stateful' : 'Stateless',
                };
              }
            });
          });

          const listAllRuleGroupFirewallData = await Promise.all(fetchListAllRuleGroupsFirewall);
          const ruleGroupFirewallDataResult: RowType[] = [];
          listAllRuleGroupFirewallData.map(firewall => {
            ruleGroupFirewallDataResult.push({
              id: firewall?.id,
              name: firewall?.name,
              type: firewall?.type,
            });
          });

          const allRuleGroupPageData = [...ruleGroupPageData, ...listAllRuleGroupFirewallData];
          setRuleGroupPageData(allRuleGroupPageData);
          setMainNextToken(awsListAllRuleGroupsFirewall?.nextToken as string);
          setMainTblRows(allRuleGroupPageData);
          setSearchData(allRuleGroupPageData);
          setMainTblTotal({
            totalPage: Math.ceil(allRuleGroupPageData.length / mainTablePagination.limit),
            totalElement: allRuleGroupPageData.length,
          });
          setMainApiIsLoading(false);
        } else {
          setRuleGroupPageData([]);
          setMainTblRows([]);
          setSearchData([]);
          setMainNextToken('');
          setMainApiIsLoading(false);
        }
      }
    },
    [selectedRegion, ruleGroupPageData, mainTablePagination, getVariableData],
  );

  const getAwsManagedDomainList = useCallback(
    async (nextToken?: string) => {
      if (selectedRegion.value != '') {
        setMainApiIsLoading(true);
        const awsRuleGroupRows: RowType[] = [];
        // Domain and IP rule groups section
        const variable = {
          ...getVariableData(mainTabs[3].id),
          request: {
            ...getVariableData(mainTabs[3].id).request,
            scope: 'MANAGED',
            managedType: 'AWS_MANAGED_DOMAIN_LISTS',
          },
        };
        const awsListRuleGroup = await getAwsAllListRuleGroupsFirewall({
          variables: variableCombineNextToken(variable, nextToken),
        }).then(({ data: awsListRuleGroup }) => {
          return awsListRuleGroup?.getAwsAllListRuleGroupsFirewall;
        });

        if (awsListRuleGroup?.data) {
          for (const ruleGroup of awsListRuleGroup?.data) {
            const awsRuleGroupVariable = {
              cloudId: cloudId,
              region: selectedRegion.value as string,
              request: {
                ruleGroupArn: ruleGroup.arn,
              },
            };
            const ruleGroupMetaData = await getAwsRuleGroupMetadata({
              variables: awsRuleGroupVariable,
            }).then(({ data: metaData }) => {
              return metaData?.getAwsRuleGroupMetadata;
            });
            awsRuleGroupRows.push({
              id: ruleGroupMetaData?.data?.[0]?.ruleGroupArn,
              name: ruleGroupMetaData?.data?.[0]?.ruleGroupName,
              description: ruleGroupMetaData?.data?.[0]?.description ? ruleGroupMetaData?.data?.[0]?.description : '-',
              capacity: ruleGroupMetaData?.data?.[0]?.capacity,
              ruleOrder: ruleGroupMetaData?.data?.[0]?.statefulRuleOptions?.ruleOrder ?? '-',
            });
          }
        }

        const allAwsRuleGroupPageData = [...awsRuleGroupPageData, ...awsRuleGroupRows];
        setAwsRuleGroupPageData(allAwsRuleGroupPageData);
        setMainNextToken(awsListRuleGroup?.nextToken as string);
        setMainTblRows(allAwsRuleGroupPageData);
        setSearchData(allAwsRuleGroupPageData);
        setMainTblTotal({
          totalPage: Math.ceil(allAwsRuleGroupPageData.length / mainTablePagination.limit),
          totalElement: allAwsRuleGroupPageData.length,
        });
      } else {
        setAwsRuleGroupPageData([]);
        setMainTblRows([]);
        setSearchData([]);
        setMainNextToken('');
        setMainApiIsLoading(false);
      }
    },
    [selectedRegion, getVariableData],
  );

  const getAwsManagedThreatSignatures = useCallback(
    async (nextToken?: string) => {
      if (selectedRegion.value != '') {
        const awsRuleGroupThreatRows: RowType[] = [];
        const variableThreat = {
          ...getVariableData(mainTabs[3].id),
          request: {
            ...getVariableData(mainTabs[3].id).request,
            scope: 'MANAGED',
            managedType: 'AWS_MANAGED_THREAT_SIGNATURES',
          },
        };
        const awsListRuleGroupThreat = await getAwsAllListRuleGroupsFirewall({
          variables: variableCombineNextToken(variableThreat, nextToken),
        }).then(({ data: awsListRuleGroup }) => {
          return awsListRuleGroup?.getAwsAllListRuleGroupsFirewall;
        });

        if (awsListRuleGroupThreat?.data) {
          for (const ruleGroup of awsListRuleGroupThreat?.data) {
            const awsRuleGroupVariable = {
              cloudId: cloudId,
              region: selectedRegion.value as string,
              request: {
                ruleGroupArn: ruleGroup.arn,
              },
            };
            const ruleGroupMetaData = await getAwsRuleGroupMetadata({
              variables: awsRuleGroupVariable,
            }).then(({ data: metaData }) => {
              return metaData?.getAwsRuleGroupMetadata;
            });

            awsRuleGroupThreatRows.push({
              id: ruleGroupMetaData?.data?.[0]?.ruleGroupArn,
              name: ruleGroupMetaData?.data?.[0]?.ruleGroupName,
              description: ruleGroupMetaData?.data?.[0]?.description ? ruleGroupMetaData?.data?.[0]?.description : '-',
              capacity: ruleGroupMetaData?.data?.[0]?.capacity,
              ruleOrder: ruleGroupMetaData?.data?.[0]?.statefulRuleOptions?.ruleOrder ?? '-',
              lastModifiedTime: getFormatedDateWithTimezone(ruleGroupMetaData?.data?.[0]?.lastModifiedTime),
            });
          }

          const awsRuleGroupThreatColumns = [
            {
              field: 'name',
              label: 'Name',
              sort: true,
              renderCell: (row: RowType) => (
                <p
                  className="link"
                  onClick={() => {
                    setLayoutType(MgdLayoutTypeEnum.MGD_DETAIL);
                    setMainSelected(row.id as string);
                    getAwsRuleGroupFirewallDetailData();
                  }}
                >
                  {handleFormatText(row.name)}
                </p>
              ),
            },
            {
              field: 'description',
              label: 'Description',
              sort: true,
            },
            {
              field: 'capacity',
              label: 'Capacity',
              sort: true,
            },
            {
              field: 'ruleOrder',
              label: 'Rule order',
              sort: true,
            },
            {
              field: 'lastModifiedTime',
              label: 'Last updated',
              sort: true,
            },
          ];
          const totalRows = [...(mainThreatSignatureTbl?.rows || []), ...awsRuleGroupThreatRows];
          setMainThreatSignatureTbl({ rows: totalRows, columns: awsRuleGroupThreatColumns });
          setMainExtraNextToken(awsListRuleGroupThreat?.nextToken as string);
          setMainApiIsLoading(false);
        }
      } else {
        setAwsRuleGroupPageData([]);
        setMainTblRows([]);
        setMainExtraNextToken('');
        setMainApiIsLoading(false);
      }
    },
    [selectedRegion, getVariableData, mainThreatSignatureTbl],
  );

  const getTlsInspectionMainDataRows = useCallback(
    (nextToken?: string) => {
      if (selectedRegion.value != '') {
        setMainApiIsLoading(true);
        const variables = getVariableData(mainTabSelected.id);
        getAwsAllListTLSInspectionConfigurations({
          variables: variableCombineNextToken(variables, nextToken),
        }).then(({ data: listTLS }) => {
          const awsAllListTLSInspectionConfigurations = listTLS?.getAwsAllListTLSInspectionConfigurations;
          if (awsAllListTLSInspectionConfigurations) {
            const tlsData: RowType[] = [];
            awsAllListTLSInspectionConfigurations?.data?.forEach(item => {
              tlsData.push({
                ...item,
                id: item.arn,
              });
            });

            const allAwsRouteTables = [...tlsInspectionPageData, ...tlsData];
            setTlsInspectionPageData(allAwsRouteTables);
            setMainNextToken(awsAllListTLSInspectionConfigurations?.nextToken as string);
            setMainTblRows(allAwsRouteTables);
            setSearchData(allAwsRouteTables);
            setMainTblTotal({
              totalPage: Math.ceil(allAwsRouteTables.length / mainTablePagination.limit),
              totalElement: allAwsRouteTables.length,
            });
            setMainApiIsLoading(false);
          } else {
            setTlsInspectionPageData([]);
            setMainTblRows([]);
            setSearchData([]);
            setMainNextToken('');
            setMainApiIsLoading(false);
          }
        });
      }
    },
    [selectedRegion, tlsInspectionPageData, mainTablePagination, getVariableData],
  );

  const getResourceGroupMainDataRows = useCallback(
    (nextToken?: string) => {
      if (selectedRegion.value != '') {
        setMainApiIsLoading(true);
        const variables = getVariableData(mainTabSelected.id);
        getAwsAllListGroups({
          variables: variableCombineNextToken(variables, nextToken),
        }).then(({ data: listGroups }) => {
          const awsAllListGroups = listGroups?.getAwsAllListGroups;
          if (awsAllListGroups) {
            const groupsData: RowType[] = [];
            awsAllListGroups?.data?.forEach(item => {
              groupsData.push({
                ...item,
                name: item.groupName,
                id: item.groupArn,
              });
            });

            const allResourceGroupPageData = [...resourceGroupPageData, ...groupsData];
            setResourceGroupPageData(allResourceGroupPageData);
            setMainNextToken(awsAllListGroups?.nextToken as string);
            setMainTblRows(allResourceGroupPageData);
            setSearchData(allResourceGroupPageData);
            setMainTblTotal({
              totalPage: Math.ceil(allResourceGroupPageData.length / mainTablePagination.limit),
              totalElement: allResourceGroupPageData.length,
            });
            setMainApiIsLoading(false);
          } else {
            setResourceGroupPageData([]);
            setMainTblRows([]);
            setSearchData([]);
            setMainNextToken('');
            setMainApiIsLoading(false);
          }
        });
      }
    },
    [selectedRegion, resourceGroupPageData, mainTablePagination, getVariableData],
  );

  const getFirewallMainDataColumns = () => {
    let mainColumns: ColumnType[] = [];
    mainColumns = [
      {
        label: 'Name',
        field: 'name',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <span
                className="link"
                onClick={() => {
                  setLayoutType(MgdLayoutTypeEnum.MGD_DETAIL);
                  setDetailTabSelected(detailFirewallTabs[0]);
                }}
              >
                {handleFormatText(row?.name)}
              </span>
            }
          </>
        ),
      },
      {
        label: 'Status',
        field: 'statusMapping',
        sort: true,
        renderCell: (row: RowType) => (
          <p className={`${FirewallStatusMapping[row?.status]?.style}`}>{handleFormatText(FirewallStatusMapping[row?.status]?.text)}</p>
        ),
      },
      {
        label: 'Configuration sync state',
        field: 'configurationStateMapping',
        sort: true,
        renderCell: (row: RowType) => (
          <p className={`${FirewallConfigurationSyncStateMapping[row?.configurationState]?.style}`}>
            {handleFormatText(FirewallConfigurationSyncStateMapping[row?.configurationState]?.text)}
          </p>
        ),
      },
    ];
    setMainTblColumns(mainColumns);
  };

  const getFirewallPolicyMainDataColumns = () => {
    let mainColumns: ColumnType[] = [];
    mainColumns = [
      {
        label: 'Name',
        field: 'name',
        sort: true,
        renderCell: row => (
          <span
            className="link"
            onClick={() => {
              setLayoutType(MgdLayoutTypeEnum.MGD_DETAIL);
              setDetailTabSelected(detailFirewallPolicy[0]);
            }}
          >
            {handleFormatText(row.name)}
          </span>
        ),
      },
    ];

    setMainTblColumns(mainColumns);
  };

  const getRuleGroupMainDataColumns = () => {
    const mainColumns: ColumnType[] = [
      {
        label: 'Name',
        field: 'name',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <span
                className="link"
                onClick={() => {
                  setLayoutType(MgdLayoutTypeEnum.MGD_DETAIL);
                }}
              >
                {handleFormatText(row?.name)}
              </span>
            }
          </>
        ),
      },
      {
        label: 'Type',
        field: 'type',
        sort: true,
      },
    ];
    setMainTblColumns(mainColumns);
  };

  const getAwsRuleGroupMainDataColumns = () => {
    const awsRuleGroupColumns: ColumnType[] = [
      {
        field: 'name',
        label: 'Name',
        sort: true,
      },
      {
        field: 'description',
        label: 'Description',
        sort: true,
      },
      {
        field: 'capacity',
        label: 'Capacity',
        sort: true,
      },
      {
        field: 'ruleOrder',
        label: 'Rule order',
        sort: true,
      },
    ];

    setMainTblColumns(awsRuleGroupColumns);
  };

  const getTlsInspectionMainDataColumns = () => {
    const mainColumns: ColumnType[] = [
      {
        label: 'Name',
        field: 'name',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <span
                className="link"
                onClick={() => {
                  setLayoutType(MgdLayoutTypeEnum.MGD_DETAIL);
                }}
              >
                {handleFormatText(row?.name)}
              </span>
            }
          </>
        ),
      },
    ];
    setMainTblColumns(mainColumns);
  };

  const getResourceGroupMainDataColumns = () => {
    const mainColumns: ColumnType[] = [
      {
        label: 'Name',
        field: 'name',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <span
                className="link"
                onClick={() => {
                  // getResourcesGroupDetailData();
                  setMainSelected(row.id as string);
                  setLayoutType(MgdLayoutTypeEnum.MGD_DETAIL);
                }}
              >
                {handleFormatText(row?.groupName)}
              </span>
            }
          </>
        ),
      },
    ];
    setMainTblColumns(mainColumns);
  };

  const getDetailTabOneListUpFirewallPolicySumary = async (
    awsFirewallPolicy: IGqlResponseNewData<AwsDescribeFirewallPolicyResponse>,
  ) => {
    if (awsFirewallPolicy) {
      const networkFirewallRuleGroups: IMgdDetailContentProps[] = [];
      // Stateless default action section
      networkFirewallRuleGroups.push({
        type: DetailTypeEnum.MULTICOLUMN,
        contents: [
          {
            title: 'Stateless default actions',
            subTitle:
              'Stateless default actions determine how Network Firewall should handle packets that don’t match any stateless rule group contained in this policy. You must set stateless default action regardless of whether you define stateless rule groups for the policy.',
            contents: [
              {
                title: 'Actions for full packets',
                description:
                  awsFirewallPolicy?.data?.[0]?.firewallPolicy?.statelessDefaultActions?.[0] === 'aws:forward_to_sfe'
                    ? 'Forward to stateful rule groups.'
                    : awsFirewallPolicy?.data?.[0]?.firewallPolicy?.statelessDefaultActions?.[0]?.substring(4),
              },
              {
                title: 'Actions for fragmented packets',
                description:
                  awsFirewallPolicy?.data?.[0]?.firewallPolicy?.statelessFragmentDefaultActions?.[0] ===
                  'aws:forward_to_sfe'
                    ? 'Forward to stateful rule groups.'
                    : awsFirewallPolicy?.data?.[0]?.firewallPolicy?.statelessFragmentDefaultActions?.[0].substring(4),
              },
            ],
          },
        ],
        actionGroup: [
          {
            title: 'Edit',
            onClick: () => editDetailBtnClickedHandler('statelessDefaultActions'),
          },
        ],
      });

      // Stateless rule groups section (table)
      const statelessRows: RowType[] = [];

      if (awsFirewallPolicy.data?.[0]?.firewallPolicy) {
        const statelessRuleGroupReferences =
          awsFirewallPolicy?.data?.[0]?.firewallPolicy?.statelessRuleGroupReferences ?? [];

        for (const item of statelessRuleGroupReferences) {
          const awsRuleGroupVariable = {
            cloudId: cloudId,
            region: selectedRegion.value as string,
            request: {
              ruleGroupArn: item.resourceArn,
              type: 'STATELESS',
            },
          };

          const ruleGroupMetaData = await getAwsRuleGroupMetadata({
            variables: awsRuleGroupVariable,
          }).then(({ data: ruleGroupData }) => {
            return ruleGroupData?.getAwsRuleGroupMetadata;
          });

          statelessRows.push({
            id: ruleGroupMetaData?.data?.[0]?.ruleGroupName,
            priority: item.priority,
            name: ruleGroupMetaData?.data?.[0]?.ruleGroupName,
            capacity: ruleGroupMetaData?.data?.[0]?.capacity,
          });
        }
        statelessRows.sort((a, b) => {
          return a.priority - b.priority;
        });
        networkFirewallRuleGroups.push({
          type: DetailTypeEnum.TABLE,
          contents: [
            {
              title: 'Stateless rule groups',
              rows: statelessRows ? statelessRows : [],
              columns: [
                {
                  field: 'priority',
                  label: 'Priority',
                  width: 100,
                  sort: true,
                },
                {
                  field: 'name',
                  label: 'Name',
                  sort: true,
                  renderCell: row => {
                    return <p className="link">{handleFormatText(row.name)}</p>;
                  },
                },
                {
                  field: 'capacity',
                  label: 'Capacity',
                  sort: true,
                },
              ],
              reportCheckedList: (list: string[]) => {
                if (list.length > 0) {
                  const dropdownEditedList = statelessRuleGroupDropdownList.map((item, index) => {
                    if (index === statelessRuleGroupDropdownList.length - 1) {
                      item.disabled = false;
                    }
                    return item;
                  });
                  setStatelessRuleGroupDropdownList(dropdownEditedList);
                } else {
                  const dropdownEditedList = statelessRuleGroupDropdownList.map((item, index) => {
                    if (index === statelessRuleGroupDropdownList.length - 1) {
                      item.disabled = true;
                    }
                    return item;
                  });
                  setStatelessRuleGroupDropdownList(dropdownEditedList);
                }
              },
              hasPagination: true,
              total: {
                totalPage: Math.ceil(statelessRows.length / itemPerPageOfDetailTable),
                totalElement: statelessRows?.length,
              },
              tablePagination: mainTablePagination,
              updateTablePagination: () => {},
              isShowButtonGroups: isHeaderButtonTableDetailShowed,
              buttons: [
                {
                  title: 'Edit priority',
                  onClick: () => editDetailBtnClickedHandler('statelessEditPriority'),
                },
              ],
              isShowDropdown: isHeaderButtonTableDetailShowed,
              dropdowns: [
                {
                  dropList: statelessRuleGroupDropdownList.slice(1),
                  dropValue: statelessRuleGroupDropdownList[0],
                  dropOnchange: option => editDetailBtnClickedHandler('addUnmanageStatelessRG', option),
                },
              ],
            },
          ] as IMgdDetailTableProps[],
        });
      }

      // Stateful rule evaluation order and default actions section
      networkFirewallRuleGroups.push({
        type: DetailTypeEnum.MULTICOLUMN,
        contents: [
          {
            title: 'Stateful rule evaluation order and default actions section',
            subTitle: 'The way that your stateful rules are ordered for evaluation.',
            contents: [
              {
                title: 'Rule order',
                description: awsFirewallPolicy?.data?.[0]?.firewallPolicy?.statefulEngineOptions?.ruleOrder,
              },
              {
                title: 'Default actions',
                description: awsFirewallPolicy?.data?.[0]?.firewallPolicy?.statefulDefaultActions?.[0].substring(4),
              },
            ],
          },
        ],
      });
      // ---

      // Stateful rule groups section (table)
      const statefulRows: any[] = [];

      if (awsFirewallPolicy?.data?.[0]?.firewallPolicy) {
        const statefulRuleGroupReferences = awsFirewallPolicy?.data?.[0]?.firewallPolicy.statefulRuleGroupReferences
          ? awsFirewallPolicy?.data?.[0]?.firewallPolicy.statefulRuleGroupReferences
          : [];

        for (const item of statefulRuleGroupReferences) {
          const awsRuleGroupVariable = {
            cloudId: cloudId,
            region: selectedRegion.value as string,
            request: {
              ruleGroupArn: item.resourceArn,
              type: 'STATEFUL',
            },
          };

          const ruleGroupMetaData = await getAwsRuleGroupMetadata({
            variables: awsRuleGroupVariable,
          }).then(({ data: ruleGroupData }) => {
            return ruleGroupData?.getAwsRuleGroupMetadata;
          });

          statefulRows.push({
            id: ruleGroupMetaData?.data?.[0]?.ruleGroupName,
            priority: item.priority,
            name: ruleGroupMetaData?.data?.[0]?.ruleGroupName,
            capacity: ruleGroupMetaData?.data?.[0]?.capacity,
            isManaged: ruleGroupMetaData?.data?.[0]?.ruleGroupArn?.includes('aws-managed') ? 'Yes' : 'No',
          });
        }
        statefulRows.sort((a, b) => {
          return a.priority - b.priority;
        });
        networkFirewallRuleGroups.push({
          type: DetailTypeEnum.TABLE,
          contents: [
            {
              title: 'Stateful rule groups',
              rows: statefulRows ? statefulRows : [],
              columns: [
                {
                  field: 'priority',
                  label: 'Priority',
                  width: 100,
                  sort: true,
                },
                {
                  field: 'name',
                  label: 'Name',
                  sort: true,
                  renderCell: row => {
                    return <p className="link">{handleFormatText(row.name)}</p>;
                  },
                },
                {
                  field: 'capacity',
                  label: 'Capacity',
                  width: 200,
                  sort: true,
                },
                {
                  field: 'isManaged',
                  label: 'Is managed',
                  width: 200,
                  sort: true,
                },
              ],
              reportCheckedList: (list: string[]) => {
                if (list.length > 0) {
                  const dropdownEditedList = statefulRuleGroupDropdownList.map((item, index) => {
                    if (index === statefulRuleGroupDropdownList.length - 1) {
                      item.disabled = false;
                    }
                    return item;
                  });
                  setStatefulRuleGroupDropdownList(dropdownEditedList);
                } else {
                  const dropdownEditedList = statefulRuleGroupDropdownList.map((item, index) => {
                    if (index === statefulRuleGroupDropdownList.length - 1) {
                      item.disabled = true;
                    }
                    return item;
                  });
                  setStatefulRuleGroupDropdownList(dropdownEditedList);
                }
              },
              hasPagination: true,
              total: {
                totalPage: Math.ceil(statefulRows.length / itemPerPageOfDetailTable),
                totalElement: statefulRows?.length,
              },
              tablePagination: mainTablePagination,
              updateTablePagination: () => {},
              isShowButtonGroups: isHeaderButtonTableDetailShowed,
              buttons: [
                {
                  title: 'Edit priority',
                  onClick: () => editDetailBtnClickedHandler('statefulEditPriority'),
                },
              ],
              isShowDropdown: isHeaderButtonTableDetailShowed,
              dropdowns: [
                {
                  dropList: statefulRuleGroupDropdownList.slice(1),
                  dropValue: statefulRuleGroupDropdownList[0],
                  dropOnchange: option => editDetailBtnClickedHandler('addUnmanageStatefulRG', option),
                },
              ],
            },
          ] as IMgdDetailTableProps[],
        });
      }
      setDetailContents(networkFirewallRuleGroups);
      setDetailApiIsLoading(false);
    }
  };

  const getFirewallDetailData = async () => {
    setDetailApiIsLoading(true);
    const detailData = awsFirewallPageData.find(mainData => mainData.id === mainSelected);
    let firewallPolicyName = '';
    switch (detailTabSelected) {
      case detailFirewallTabs[0]:
        if (detailData) {
          const detailContentData: IMgdDetailContentProps[] = [];
          const firewallDetailSection: IMgdDetailContentProps = {
            type: DetailTypeEnum.MULTICOLUMN,
            contents: [
              {
                title: 'Firewall details',
                contents: [
                  {
                    title: 'Name',
                    description: getStrValueOrNull(detailData?.firewall?.firewallName),
                  },
                  {
                    title: 'Desciption',
                    description: getStrValueOrNull(detailData?.firewall?.description),
                  },
                ],
              },
            ],
            actionGroup: [
              {
                title: 'Edit',
                onClick: () => editDetailBtnClickedHandler('firewallDetails'),
              },
            ],
          };
          detailContentData.push(firewallDetailSection);

          const vpcDetailSection: IMgdDetailContentProps = {
            type: DetailTypeEnum.MULTICOLUMN,
            contents: [
              {
                title: 'VPC',
                contents: [
                  {
                    title: 'Associated VPC',
                    type: TextTypeEnum.LINK,
                    description: getStrValueOrNull(detailData?.firewall?.vpcId),
                  },
                  {
                    title: 'Firewall subnets',
                    type: TextTypeEnum.LINK,
                    description: getStrValueOrNull(detailData?.firewall?.subnetMappings[0]?.subnetId),
                  },
                ],
              },
            ],
            actionGroup: [
              {
                title: 'Edit',
                onClick: () => editDetailBtnClickedHandler('vpc'),
              },
            ],
          };
          detailContentData.push(vpcDetailSection);

          const firewallStatus = detailData?.firewallStatus;
          const firewallEndpointSectionContent = [];
          const availabilityZone = Object.entries(firewallStatus?.syncStates).map(([key]) => {
            return key;
          });
          if (availabilityZone && availabilityZone.length > 0) {
            const firewallEndpointSectionContentRows: RowType[] = [];
            const firewallEndpointSectionContentColumns: ColumnType[] = [
              {
                label: 'Availability zone',
                field: 'availabilityZone',
                sort: true,
              },
              {
                label: 'Firewall subnet',
                field: 'firewallSubnet',
                sort: true,
              },
              {
                label: 'Endpoint ID',
                field: 'endpointId',
                sort: true,
              },
              {
                label: 'Firewall endpoint status',
                field: 'endpointStatus',
                sort: true,
                renderCell: (row: RowType) => (
                  <p className={`${FirewallStatusMapping[row?.endpointStatus]?.style}`}>
                    {handleFormatText(FirewallStatusMapping[row?.endpointStatus]?.text)}
                  </p>
                ),
              },
            ];
            availabilityZone.map(zone => {
              const attachment = firewallStatus?.syncStates[zone]?.attachment;
              if (attachment) {
                firewallEndpointSectionContentRows.push({
                  availabilityZone: zone,
                  firewallSubnet: getStrValueOrNull(attachment?.subnetId),
                  endpointId: getStrValueOrNull(attachment?.endpointId),
                  endpointStatus: getStrValueOrNull(attachment?.status),
                });
              }
            });
            firewallEndpointSectionContent.push({
              title: 'Firewall endpoints',
              rows: firewallEndpointSectionContentRows,
              columns: firewallEndpointSectionContentColumns,
              total: {
                totalPage: Math.ceil(firewallEndpointSectionContentRows.length / itemPerPageOfDetailTable),
                totalElement: firewallEndpointSectionContentRows.length,
              },
              tablePagination: mainTablePagination,
              updateTablePagination: () => {},
            });
          }
          const firewallEndpointSection: IMgdDetailContentProps = {
            type: DetailTypeEnum.TABLE,
            contents: firewallEndpointSectionContent,
          };
          detailContentData.push(firewallEndpointSection);

          const mainVariable = getVariableData(mainTabs[0].id);
          const policyVariable = {
            cloudId: mainVariable.cloudId,
            region: mainVariable.region,
            request: {
              firewallPolicyArn: detailData?.id,
              firewallPolicyName: detailData?.name,
            },
          };
          setExtraOverviewDetailApiIsLoading(true);
          getAwsFirewallPolicy({
            variables: policyVariable,
          })
            .then(({ data: firewallPollicy }) => {
              if (firewallPollicy?.getAwsFirewallPolicy?.data?.[0]?.firewallPolicyResponse) {
                firewallPolicyName = getStrValueOrNull(
                  firewallPollicy?.getAwsFirewallPolicy?.data?.[0]?.firewallPolicyResponse?.firewallPolicyName,
                );
                const firewallPolicyDetailSection: IMgdDetailContentProps = {
                  type: DetailTypeEnum.MULTICOLUMN,
                  contents: [
                    {
                      title: 'Firewall policy',
                      contents: [
                        {
                          title: 'Associated firewall policy',
                          type: TextTypeEnum.LINK,
                          description: firewallPolicyName,
                        },
                      ],
                    },
                  ],
                  actionGroup: [
                    {
                      title: 'Edit',
                      onClick: () => console.log('Firewall policy EDIT CLICKED'),
                    },
                  ],
                };
                detailContentData.push(firewallPolicyDetailSection);
              }
            })
            .then(() => {
              const configSyncStateSectionContent = [];
              if (availabilityZone && availabilityZone.length > 0) {
                const configSyncStateSectionContentRows: RowType[] = [];
                const configSyncStateSectionContentColumns: ColumnType[] = [
                  {
                    label: 'Resource name',
                    field: 'resourceName',
                    sort: true,
                  },
                  {
                    label: 'Resource type',
                    field: 'resourceType',
                    sort: true,
                  },
                  {
                    label: 'Availability Zone',
                    field: 'alilabilityZone',
                    sort: true,
                  },
                  {
                    label: 'Sync state',
                    field: 'syncStateMapping',
                    sort: true,
                    renderCell: (row: RowType) => (
                      <p className={`${FirewallConfigurationSyncStateMapping[row?.syncState]?.style}`}>
                        {handleFormatText(FirewallConfigurationSyncStateMapping[row?.syncState]?.text)}
                      </p>
                    ),
                  },
                ];
                availabilityZone.map(zone => {
                  const configs = firewallStatus?.syncStates[zone]?.config;
                  if (configs) {
                    const configKeys = Object.entries(configs).map(([key]) => {
                      return key;
                    });
                    configKeys.map(key => {
                      const config = firewallStatus?.syncStates[zone]?.config[key];
                      const configKeySlashParts = key.split('/');
                      const resourceName = configKeySlashParts[configKeySlashParts.length - 1];
                      const configKeyColonParts = configKeySlashParts[configKeySlashParts.length - 2].split(':');
                      if (config) {
                        configSyncStateSectionContentRows.push({
                          resourceName: resourceName,
                          resourceType: configKeyColonParts.pop(),
                          alilabilityZone: zone,
                          syncState: getStrValueOrNull(config?.syncStatus),
                        });
                      }
                    });
                  }
                });
                configSyncStateSectionContent.push({
                  title: 'Configuration sync state',
                  rows: configSyncStateSectionContentRows,
                  columns: configSyncStateSectionContentColumns,
                  total: {
                    totalPage: Math.ceil(configSyncStateSectionContentRows.length / itemPerPageOfDetailTable),
                    totalElement: configSyncStateSectionContentRows.length,
                  },
                  tablePagination: mainTablePagination,
                  updateTablePagination: () => {},
                });
              }

              const configSyncStateSection: IMgdDetailContentProps = {
                type: DetailTypeEnum.TABLE,
                contents: configSyncStateSectionContent,
              };
              detailContentData.push(configSyncStateSection);

              if (
                detailData?.firewall?.deleteProtection != undefined &&
                detailData?.firewall?.subnetChangeProtection != undefined
              ) {
                const changeProtectionDetailSection: IMgdDetailContentProps = {
                  type: DetailTypeEnum.MULTICOLUMN,
                  contents: [
                    {
                      title: 'Change protection',
                      contents: [
                        {
                          title: 'Delete protection',
                          description: detailData?.firewall?.deleteProtection == false ? 'Disable' : 'Enable',
                        },
                        {
                          title: 'Subnet change protection',
                          description: detailData?.firewall?.subnetChangeProtection == false ? 'Disable' : 'Enable',
                        },
                      ],
                    },
                  ],
                  actionGroup: [
                    {
                      title: 'Edit',
                      onClick: () => editDetailBtnClickedHandler('changeProtection'),
                    },
                  ],
                };
                detailContentData.push(changeProtectionDetailSection);
              }
            })
            .then(() => {
              const awsLoggingConfigVariable = {
                cloudId: mainVariable.cloudId,
                region: mainVariable.region,
                request: {
                  firewallArn: detailData?.id,
                  firewallName: detailData?.name,
                },
              };
              getAwsLoggingConfigurationFirewall({
                variables: awsLoggingConfigVariable,
              })
                .then(({ data: awsLogging }) => {
                  const loggingConfig = awsLogging?.getAwsLoggingConfigurationFirewall?.data?.loggingConfiguration;
                  if (loggingConfig) {
                    const firewallPolicyDetailSection: IMgdDetailContentProps = {
                      type: DetailTypeEnum.MULTICOLUMN,
                      contents: [
                        {
                          title: 'Logging',
                          subTitle:
                            'Network Firewall generates logs for stateful rule groups. You can configure different destinations for different log types.',
                          contents: [
                            {
                              title: 'Log type',
                              description: loggingConfig?.logType ? 'Not configured' : 'Configured',
                            },
                            {
                              title: 'Alert log destination',
                              description: loggingConfig?.logDestinationType ? 'Not configured' : 'Configured',
                            },
                            {
                              title: 'Flow log destination',
                              description: loggingConfig?.logDestination ? 'Not configured' : 'Configured',
                            },
                          ],
                        },
                      ],
                      actionGroup: [
                        {
                          title: 'Edit',
                          onClick: () => console.log('Logging EDIT CLICKED'),
                        },
                      ],
                    };
                    detailContentData.push(firewallPolicyDetailSection);
                  }
                })
                .then(() => {
                  const encryptionConfiguration = detailData?.firewall?.encryptionConfiguration;
                  if (encryptionConfiguration) {
                    const firewallCustomerManagerSection: IMgdDetailContentProps = {
                      type: DetailTypeEnum.MULTICOLUMN,
                      contents: [
                        {
                          title: 'Customer managed key',
                          contents: [
                            {
                              title: 'Key type',
                              description: getStrValueOrNull(encryptionConfiguration?.type),
                            },
                          ],
                        },
                      ],
                      actionGroup: [
                        {
                          title: 'Edit',
                          onClick: () => editDetailBtnClickedHandler('customerManageKey'),
                        },
                      ],
                    };
                    detailContentData.push(firewallCustomerManagerSection);
                  }

                  const tags = detailData?.firewall?.tags;
                  const tagsSectionContent = [];
                  if (tags && tags.length > 0) {
                    const tagsSectionContentRows: RowType[] = [];
                    const tagsSectionContentColumns: ColumnType[] = [
                      {
                        label: 'Key',
                        field: 'key',
                        sort: true,
                      },
                      {
                        label: 'Value',
                        field: 'value',
                        sort: true,
                      },
                    ];

                    tags.map((tag: any) => {
                      tagsSectionContentRows.push({
                        key: getStrValueOrNull(tag.key),
                        value: getStrValueOrNull(tag.value),
                      });
                    });
                    tagsSectionContent.push({
                      title: 'Firewall tags',
                      rows: tagsSectionContentRows,
                      columns: tagsSectionContentColumns,
                      total: {
                        totalPage: 1,
                        totalElement: tagsSectionContentRows.length,
                      },
                      tablePagination: mainTablePagination,
                      updateTablePagination: () => {},
                      isShowButtonGroups: isHeaderButtonTableDetailShowed,
                      buttons: isHeaderButtonTableDetailShowed
                        ? [
                            {
                              title: 'Edit',
                              onClick: () => editDetailBtnClickedHandler('firewallTags'),
                            },
                          ]
                        : [],
                    });
                  }
                  const firewallTagsSection: IMgdDetailContentProps = {
                    type: DetailTypeEnum.TABLE,
                    contents: tagsSectionContent,
                  };
                  detailContentData.push(firewallTagsSection);
                })
                .then(() => {
                  setDetailContents(detailContentData);
                  setDetailApiIsLoading(false);
                  setExtraOverviewDetailApiIsLoading(false);
                });
            });

          setDetailTitle('');
        } else {
          setDetailContents([]);
        }
        break;
      case detailFirewallTabs[1]:
        if (detailData) {
          const awsFirewallPolicyVariable = {
            cloudId: cloudId,
            region: selectedRegion.value as string,
            request: {
              firewallPolicyArn: detailData?.firewall?.firewallPolicyArn,
            },
          };

          const awsFirewallPolicy = await getAwsFirewallPolicy({
            variables: awsFirewallPolicyVariable,
          }).then(({ data: awsFirewallPolicyData }) => {
            return awsFirewallPolicyData?.getAwsFirewallPolicy;
          });
          if (awsFirewallPolicy) {
            getDetailTabOneListUpFirewallPolicySumary(awsFirewallPolicy);
          }
        } else {
          setDetailContents([]);
        }
        break;
      case detailFirewallTabs[2]:
        setDetailContents([]);
        setDetailApiIsLoading(false);
        break;
      default:
        setDetailTabSelected(detailFirewallTabs[0]);
        setDetailContents([]);
        setDetailApiIsLoading(false);
        break;
    }
    if (detailData) {
      setExtraOverviewDetail({
        title: getStrValueOrNull(detailData?.name),
        contents: [
          {
            title: 'Overview',
            contents: [
              {
                title: 'Firewall status',
                description: FirewallStatusMapping[getStrValueOrNull(detailData?.firewallStatus?.status)].text,
              },
              {
                title: 'Associated firewall policy',
                description: firewallPolicyName ? firewallPolicyName : '-',
              },
              {
                title: 'Associated VPC',
                description: getStrValueOrNull(detailData?.firewall?.vpcId),
              },
            ],
          },
        ],
      });
    }
  };

  const getFirewallPolicyDetailData = async () => {
    setDetailApiIsLoading(true);
    const networkFirewallRuleGroups: IMgdDetailContentProps[] = [];
    const awsFirewallPolicyVariable = {
      cloudId: cloudId,
      region: selectedRegion.value as string,
      request: {
        firewallPolicyArn: mainSelected,
      },
    };

    const awsFirewallPolicy = await getAwsFirewallPolicy({
      variables: awsFirewallPolicyVariable,
    }).then(({ data: awsFirewallPolicyData }) => {
      return awsFirewallPolicyData?.getAwsFirewallPolicy;
    });

    if (awsFirewallPolicy) {
      if (detailTabSelected?.id === detailFirewallPolicy[0].id) {
        getDetailTabOneListUpFirewallPolicySumary(awsFirewallPolicy);
      } else if (detailTabSelected?.id === detailFirewallPolicy[1].id) {
        // TLS inspection configuration tabs
        if (awsFirewallPolicy?.data?.[0]?.firewallPolicy?.tlsInspectionConfigurationArn) {
          const tlsInspectionConfigVar = {
            cloudId: cloudId,
            region: selectedRegion.value as string,
            request: {
              tlsInspectionConfigurationArn:
                awsFirewallPolicy?.data?.[0]?.firewallPolicy?.tlsInspectionConfigurationArn,
            },
          };

          const tlsInspectionConf = await getAwsTLSInspectionConfiguration({
            variables: tlsInspectionConfigVar,
          }).then(({ data: tlsInspectionConfData }) => {
            return tlsInspectionConfData?.getAwsTLSInspectionConfiguration;
          });

          if (tlsInspectionConf) {
            networkFirewallRuleGroups.push({
              type: DetailTypeEnum.TABLE,
              contents: [
                {
                  title: 'TLS inspection configuration',
                  rows: [
                    {
                      name: tlsInspectionConf?.data?.[0]?.tlsInspectionConfigurationResponse
                        .tlsInspectionConfigurationName,
                      description: tlsInspectionConf?.data?.[0]?.tlsInspectionConfigurationResponse.description,
                    },
                  ],
                  columns: [
                    {
                      field: 'name',
                      label: 'Name',
                      sort: true,
                      renderCell: row => {
                        return <p className="link">{handleFormatText(row.name)}</p>;
                      },
                    },
                    {
                      field: 'description',
                      label: 'Description',
                      sort: true,
                    },
                  ],
                  hasPagination: true,
                  total: {
                    totalPage: 1,
                    totalElement: 1,
                  },
                  isShowButtonGroups: isHeaderButtonTableDetailShowed,
                  buttons: [
                    {
                      title: 'Manage TLS inspection configuration',
                      onClick: () => editDetailBtnClickedHandler('manageTLSConfiguration'),
                    },
                  ],
                  tablePagination: mainTablePagination,
                  updateTablePagination: () => {},
                },
              ] as IMgdDetailTableProps[],
            });
          }
        } else {
          networkFirewallRuleGroups.push({
            type: DetailTypeEnum.TABLE,
            contents: [
              {
                title: 'TLS inspection configuration',
                rows: [],
                columns: [
                  {
                    field: 'priority',
                    label: 'Priority',
                    sort: true,
                  },
                  {
                    field: 'name',
                    label: 'Name',
                    sort: true,
                    renderCell: row => {
                      return <p className="link">{handleFormatText(row.name)}</p>;
                    },
                  },
                  {
                    field: 'capacity',
                    label: 'Capacity',
                    sort: true,
                  },
                ],
                hasPagination: true,
                total: {
                  totalPage: 0,
                  totalElement: 0,
                },
                tablePagination: mainTablePagination,
                updateTablePagination: () => {},
              },
            ] as IMgdDetailTableProps[],
          });
        }

        setDetailContents(networkFirewallRuleGroups);
        setDetailApiIsLoading(false);
      } else {
        // Detail section
        networkFirewallRuleGroups.push({
          type: DetailTypeEnum.DETAIL,
          title: 'Details',
          contents: [
            {
              id: 'name',
              type: TextTypeEnum.NORMAL,
              title: 'Name',
              description: awsFirewallPolicy?.data?.[0]?.firewallPolicyResponse?.firewallPolicyName,
            },
            {
              id: 'description',
              type: TextTypeEnum.NORMAL,
              title: 'Description',
              description: awsFirewallPolicy?.data?.[0]?.firewallPolicyResponse?.description ?? '-',
            },
            {
              id: 'useCount',
              type: TextTypeEnum.NORMAL,
              title: 'Use count',
              description: awsFirewallPolicy?.data?.[0]?.firewallPolicyResponse?.numberOfAssociations,
            },
            {
              id: 'streamExceptionPolicy',
              type: TextTypeEnum.NORMAL,
              title: 'Stream exception policy',
              description: awsFirewallPolicy?.data?.[0]?.firewallPolicy?.statefulEngineOptions?.streamExceptionPolicy,
            },
            {
              id: 'status',
              type: TextTypeEnum.STATUS,
              title: 'Status',
              description: awsFirewallPolicy?.data?.[0]?.firewallPolicyResponse?.firewallPolicyStatus,
            },
          ],
          actionGroup: [
            {
              title: 'Edit',
              onClick: () => editDetailBtnClickedHandler('editFirewallPolicyDetails'),
            },
          ],
        });

        // Customer managed keys section
        networkFirewallRuleGroups.push({
          type: DetailTypeEnum.MULTICOLUMN,
          contents: [
            {
              title: 'Customer managed keys',
              contents: [
                {
                  title: 'key type',
                  description: awsFirewallPolicy?.data?.[0]?.firewallPolicyResponse?.encryptionConfiguration?.type,
                },
              ],
            },
          ],
          actionGroup: [
            {
              title: 'Edit',
              onClick: () => editDetailBtnClickedHandler('customerManageKey'),
            },
          ],
        });

        // Policies variables section
        const { policyVariables } = awsFirewallPolicy?.data?.[0]?.firewallPolicy;
        const ruleVariables = policyVariables?.ruleVariables;
        networkFirewallRuleGroups.push({
          type: DetailTypeEnum.MULTICOLUMN,
          contents: [
            {
              title: 'Policies variable',
              contents: [
                {
                  title: 'HOME_Net variable override values',
                  description: ruleVariables?.length ? ruleVariables : '-',
                },
              ],
            },
          ],
          actionGroup: [
            {
              title: 'Edit',
              onClick: () => editDetailBtnClickedHandler('policyVariables'),
            },
          ],
        });

        // Firewall tags section
        const tagRows = awsFirewallPolicy?.data?.[0]?.firewallPolicyResponse?.tags;
        networkFirewallRuleGroups.push({
          type: DetailTypeEnum.TABLE,
          contents: [
            {
              title: `Firewall tags (${awsFirewallPolicy?.data?.[0]?.firewallPolicyResponse.tags.length})`,
              rows: tagRows,
              columns: [
                {
                  field: 'key',
                  label: 'Key',
                  sort: true,
                },
                {
                  field: 'value',
                  label: 'Value',
                  sort: true,
                },
              ],
              hasPagination: true,
              total: {
                totalPage: 1,
                totalElement: tagRows.length,
              },
              isShowButtonGroups: isHeaderButtonTableDetailShowed,
              buttons: [
                {
                  title: 'Edit',
                  onClick: () => editDetailBtnClickedHandler('policyTags'),
                },
              ],
              tablePagination: mainTablePagination,
              updateTablePagination: () => {},
            },
          ] as IMgdDetailTableProps[],
        });
        setDetailContents(networkFirewallRuleGroups);
        setDetailApiIsLoading(false);
      }
    }
  };

  const getRuleGroupFirewallDetailData = async () => {
    const detailData = ruleGroupPageData.find(mainData => mainData.id === mainSelected);
    if (detailData) {
      setDetailApiIsLoading(true);
      const ruleGroupFirewallVariable = {
        cloudId,
        region: selectedRegion.value as string,
        request: {
          ruleGroupName: detailData?.name || '',
          ruleGroupArn: detailData?.id || '',
        },
      };
      getAwsDescribeRuleGroup({
        variables: ruleGroupFirewallVariable,
      }).then(({ data: ruleGroup }) => {
        const getAwsDescribeRuleGroup = ruleGroup?.getAwsDescribeRuleGroup?.data?.[0];
        if (getAwsDescribeRuleGroup) {
          // standard rule group details
          const ruleGroupDetailContents: IMgdDetailKeyValueProps[] = [
            {
              id: 'name',
              type: TextTypeEnum.NORMAL,
              title: 'Name',
              description: getStrValueOrNull(getAwsDescribeRuleGroup.ruleGroupResponse?.ruleGroupName),
            },
            {
              id: 'type',
              type: TextTypeEnum.NORMAL,
              title: 'Type',
              description: FIREWALL_COMMOMVALUE_MAPPING[getAwsDescribeRuleGroup.ruleGroupResponse?.type],
            },
            {
              id: 'useCount',
              type: TextTypeEnum.NORMAL,
              title: 'Use count',
              description: getAwsDescribeRuleGroup.ruleGroupResponse?.numberOfAssociations,
            },
            {
              id: 'description',
              type: TextTypeEnum.NORMAL,
              title: 'Desciption',
              description: getStrValueOrNull(getAwsDescribeRuleGroup.ruleGroupResponse?.description),
            },
            {
              id: 'capacity',
              type: TextTypeEnum.NORMAL,
              title: 'Capacity',
              description: getAwsDescribeRuleGroup.ruleGroupResponse?.capacity,
            },
            {
              id: 'status',
              type: TextTypeEnum.STATUS,
              title: 'Status',
              description: getStrValueOrNull(
                FIREWALL_COMMOMVALUE_MAPPING[getAwsDescribeRuleGroup.ruleGroupResponse?.ruleGroupStatus],
              ),
            },
          ];
          if (getAwsDescribeRuleGroup.ruleGroupResponse?.type === 'STATEFUL') {
            ruleGroupDetailContents.push({
              id: 'ruleOrder',
              type: TextTypeEnum.NORMAL,
              title: 'Stateful rule order',
              description: getStrValueOrNull(
                FIREWALL_COMMOMVALUE_MAPPING[getAwsDescribeRuleGroup.ruleGroup?.statefulRuleOptions?.ruleOrder],
              ),
            });
          }
          const ruleGroupDetailSection: IMgdDetailContentProps = {
            type: DetailTypeEnum.DETAIL,
            title:
              getAwsDescribeRuleGroup.ruleGroupResponse?.type === 'STATEFUL'
                ? 'Stateful standard rule group details'
                : 'Stateless rule group details',
            actionGroup: [
              {
                title: 'Edit',
                onClick: () => editDetailBtnClickedHandler('editFirewallRuleGroupDetails'),
              },
            ],
            contents: ruleGroupDetailContents,
          };
          //Rules Variables
          const ruleVariables = getAwsDescribeRuleGroup?.ruleGroup?.ruleVariables;
          const ruleVariablesSectionContent = [];
          const ruleVariablesSectionContentRows: RowType[] = [];
          const ruleVariablesSectionContentColumns: ColumnType[] = [
            {
              label: 'Name',
              field: 'name',
              sort: true,
            },
            {
              label: 'Type',
              field: 'type',
              sort: true,
            },
          ];
          if (ruleVariables?.ipSets) {
            const ipSets = Object.entries(ruleVariables?.ipSets).map(([key, value]) => {
              return key;
            });
            if (ipSets && ipSets.length > 0) {
              ipSets.map((key, index) => {
                ruleVariablesSectionContentRows.push({
                  name: key,
                  type: 'IP Set',
                });
              });
            }
          }
          if (ruleVariables?.portSets) {
            const portSets = Object.entries(ruleVariables?.portSets).map(([key, value]) => {
              return key;
            });
            if (portSets && portSets.length > 0) {
              portSets.map((key, index) => {
                ruleVariablesSectionContentRows.push({
                  name: key,
                  type: 'Ports',
                });
              });
            }
          }
          ruleVariablesSectionContent.push({
            title: 'Rules variables',
            description:
              'Define IP sets and ports as variables. These variables can be used within this rule group for standard stateful rules and Suricate compatible rule strings.',
            rows: ruleVariablesSectionContentRows,
            columns: ruleVariablesSectionContentColumns,
            hasPagination: false,
            total: {
              totalPage: Math.ceil(ruleVariablesSectionContentRows.length / itemPerPageOfDetailTable),
              totalElement: ruleVariablesSectionContentRows.length,
            },
            isShowButtonGroups: isHeaderButtonTableDetailShowed,
            buttons: [
              {
                iconClassName: 'iconDelete',
                onClick: () => editDetailBtnClickedHandler('deleteFirewallRuleVariables'),
              },
              {
                title: 'Edit',
                onClick: () => editDetailBtnClickedHandler('editFirewallRuleVariables'),
              },
            ],
            tablePagination: mainTablePagination,
            updateTablePagination: (key: string, value: number | string | OrderDirection) =>
              setMainTablePagination(prev => ({
                ...prev,
                [key]: value,
              })),
          });
          const ruleVariablesSection: IMgdDetailContentProps = {
            type: DetailTypeEnum.TABLE,
            contents: ruleVariablesSectionContent,
          };

          //IP state references
          const ipStateReferences = getAwsDescribeRuleGroup?.ruleGroup?.referenceSets;
          const ipStateReferencesSectionContent = [];
          const ipStateReferencesSectionContentRows: RowType[] = [];
          const ipStateReferencesSectionContentColumns: ColumnType[] = [
            {
              label: 'Name',
              field: 'name',
              sort: true,
            },
            {
              label: 'ARN',
              field: 'arn',
              sort: true,
            },
          ];
          if (ipStateReferences?.ipSetReferences) {
            Object.entries(ipStateReferences?.ipSetReferences).map(([key, value]) => {
              ipStateReferencesSectionContentRows.push({
                name: key,
                arn: value?.referenceArn,
              });
            });
          }
          ipStateReferencesSectionContent.push({
            title: 'IP state references',
            rows: ipStateReferencesSectionContentRows,
            columns: ipStateReferencesSectionContentColumns,
            total: {
              totalPage: Math.ceil(ipStateReferencesSectionContentRows.length / itemPerPageOfDetailTable),
              totalElement: ipStateReferencesSectionContentRows.length,
            },
            isShowButtonGroups: isHeaderButtonTableDetailShowed,
            buttons: [
              {
                iconClassName: 'iconDelete',
                onClick: () => editDetailBtnClickedHandler('deleteFirewallIpSetReferences'),
              },
              {
                title: 'Edit',
                onClick: () => editDetailBtnClickedHandler('editFirewallIpSetReferences'),
              },
            ],
            tablePagination: mainTablePagination,
            updateTablePagination: (key: string, value: number | string | OrderDirection) =>
              setMainTablePagination(prev => ({
                ...prev,
                [key]: value,
              })),
          });
          const ipStateReferencesSection: IMgdDetailContentProps = {
            type: DetailTypeEnum.TABLE,
            contents: ipStateReferencesSectionContent,
          };

          // Rules
          const rulesSectionContent = [];
          const rulesSectionContentRows: RowType[] = [];
          let rulesSectionContentColumns: ColumnType[] = [];
          if (getAwsDescribeRuleGroup?.ruleGroupResponse?.type === 'STATEFUL') {
            rulesSectionContentColumns = [
              {
                label: 'Protocol',
                field: 'protocol',
              },
              {
                label: 'Source',
                field: 'source',
              },
              {
                label: 'Destination',
                field: 'destination',
              },
              {
                label: 'Source port',
                field: 'sourcePort',
              },
              {
                label: 'Destination port',
                field: 'destinationPort',
              },
              {
                label: 'Direction',
                field: 'direction',
              },
              {
                label: 'Action',
                field: 'action',
              },
              {
                label: 'Keyword',
                field: 'keyword',
              },
            ];
            const statefulRules = getAwsDescribeRuleGroup?.ruleGroup?.rulesSource?.statefulRules;
            statefulRules.map(item => {
              rulesSectionContentRows.push({
                protocol: item.header?.protocol || 'All',
                source: item.header?.source || 'ANY',
                destination: item.header?.destination || 'ANY',
                sourcePort: item.header?.sourcePort || 'ANY',
                destinationPort: item.header?.destinationPort || 'ANY',
                direction: item.header?.direction || 'Forward',
                action: item.action || '-',
                keyword: item.ruleOptions?.[0]
                  ? `${item.ruleOptions[0].keyword}:${item.ruleOptions[0].settings[0]}`
                  : '-',
              });
            });
          } else {
            rulesSectionContentColumns = [
              {
                label: 'Priority',
                field: 'priority',
              },
              {
                label: 'Protocol',
                field: 'protocol',
              },
              {
                label: 'Source',
                field: 'source',
              },
              {
                label: 'Destination',
                field: 'destination',
              },
              {
                label: 'Source port range',
                field: 'sourcePort',
              },
              {
                label: 'Destination port range',
                field: 'destinationPort',
              },
              {
                label: 'Action',
                field: 'action',
              },
              {
                label: 'Custom Action',
                field: 'customAction',
              },
              {
                label: 'Masks',
                field: 'masks',
              },
              {
                label: 'Flags',
                field: 'flags',
              },
            ];
            const statelessRulesAndCustomActions =
              getAwsDescribeRuleGroup?.ruleGroup?.rulesSource?.statelessRulesAndCustomActions;
            statelessRulesAndCustomActions?.statelessRules.map(item => {
              rulesSectionContentRows.push({
                priority: item.priority,
                protocol:
                  FIREWALL_RULES_PROTOCOL_MAPPING[item.ruleDefinition?.matchAttributes?.protocols?.[0]]?.text || 'All',
                source: item.ruleDefinition?.matchAttributes?.sources?.[0]?.addressDefinition || '0.0.0.0/0',
                destination: item.ruleDefinition?.matchAttributes?.destinations?.[0]?.addressDefinition || '0.0.0.0/0',
                sourcePort: item.ruleDefinition?.matchAttributes?.sourcePorts?.[0]
                  ? `${item.ruleDefinition?.matchAttributes?.sourcePorts?.[0]?.fromPort}:${item.ruleDefinition?.matchAttributes?.sourcePorts?.[0]?.toPort}`
                  : '-',
                destinationPort: item.ruleDefinition?.matchAttributes?.destinationPorts?.[0]
                  ? `${item.ruleDefinition?.matchAttributes?.destinationPorts?.[0]?.fromPort}:${item.ruleDefinition?.matchAttributes?.destinationPorts?.[0]?.toPort}`
                  : '-',
                action: item.ruleDefinition?.actions?.[0] || '-',
                customAction: statelessRulesAndCustomActions.customActions?.[0]?.actionName || '-',
                masks: item.ruleDefinition?.matchAttributes?.tcpFlags?.[0]?.masks || '-',
                flags: item.ruleDefinition?.matchAttributes?.tcpFlags?.[0]?.flags || '-',
              });
            });
          }
          rulesSectionContent.push({
            title: 'Rules',
            rows: rulesSectionContentRows,
            columns: rulesSectionContentColumns,
            hasPagination: false,
            total: {
              totalPage: Math.ceil(rulesSectionContentRows.length / itemPerPageOfDetailTable),
              totalElement: rulesSectionContentRows.length,
            },
            isShowButtonGroups: isHeaderButtonTableDetailShowed,
            buttons: [
              {
                title: 'Add Rule',
                onClick: () => editDetailBtnClickedHandler('addFirewallNewRule'),
              },
              {
                title: 'Delete',
                onClick: () => editDetailBtnClickedHandler('deleteFirewallRuleGroup'),
              },
              {
                title: 'Move up',
                onClick: () => {},
              },
              {
                title: 'Move down',
                onClick: () => {},
              },
              {
                title: 'Edit',
                onClick: () => editDetailBtnClickedHandler('editFirewallRuleGroup'),
              },
            ],
            tablePagination: mainTablePagination,
            updateTablePagination: (key: string, value: number | string | OrderDirection) =>
              setMainTablePagination(prev => ({
                ...prev,
                [key]: value,
              })),
          });
          const rulesSection: IMgdDetailContentProps = {
            type: DetailTypeEnum.TABLE,
            contents: rulesSectionContent,
          };

          //Customer managed keys
          const customerManagedKeysSection: IMgdDetailContentProps = {
            type: DetailTypeEnum.MULTICOLUMN,
            contents: [
              {
                title: 'Customer managed keys',
                contents: [
                  {
                    title: 'key type',
                    description: getStrValueOrNull(
                      getAwsDescribeRuleGroup.ruleGroupResponse?.encryptionConfiguration?.type,
                    ),
                  },
                ],
              },
            ],
            actionGroup: [
              {
                title: 'Edit',
                onClick: () => editDetailBtnClickedHandler('customerManageKey'),
              },
            ],
          };

          //Tags
          const tags = getAwsDescribeRuleGroup?.ruleGroupResponse?.tags;
          const tagsSectionContent = [];
          const tagsSectionContentRows: RowType[] = [];
          const tagsSectionContentColumns: ColumnType[] = [
            {
              label: 'Key',
              field: 'key',
              sort: true,
            },
            {
              label: 'Value',
              field: 'value',
              sort: true,
            },
          ];
          if (tags) {
            tags.map(item => {
              tagsSectionContentRows.push({
                key: item.key,
                value: item.value,
              });
            });
          }

          tagsSectionContent.push({
            title: 'Tags',
            description: 'Apply tags to your resources to help organize and identify them.',
            rows: tagsSectionContentRows,
            columns: tagsSectionContentColumns,
            total: {
              totalPage: 1,
              totalElement: tagsSectionContentRows.length,
            },
            isShowButtonGroups: isHeaderButtonTableDetailShowed,
            buttons: [
              {
                title: 'Edit',
                onClick: () => editDetailBtnClickedHandler('policyTags'),
              },
            ],
            tablePagination: mainTablePagination,
            updateTablePagination: (key: string, value: number | string | OrderDirection) =>
              setMainTablePagination(prev => ({
                ...prev,
                [key]: value,
              })),
          });
          const tagsSection: IMgdDetailContentProps = {
            type: DetailTypeEnum.TABLE,
            contents: tagsSectionContent,
          };

          setDetailTitle(detailData?.name);
          setDetailContents([
            ruleGroupDetailSection,
            ruleVariablesSection,
            ipStateReferencesSection,
            rulesSection,
            customerManagedKeysSection,
            tagsSection,
          ]);
          setDetailApiIsLoading(false);
        }
      });
    } else {
      setDetailContents([]);
    }
  };

  const getAwsRuleGroupFirewallDetailData = async () => {
    if (mainSelected && mainSelected !== '') {
      setDetailApiIsLoading(true);
      const awsRuleGroupFirewallDetail: IMgdDetailContentProps[] = [];
      const describeRuleGroupVar = {
        cloudId: cloudId,
        region: selectedRegion.value as string,
        request: {
          ruleGroupArn: mainSelected,
        },
      };

      const describeRuleGroup = await getAwsDescribeRuleGroup({
        variables: describeRuleGroupVar,
      }).then(({ data: awsDescribeRuleGroupData }) => {
        return awsDescribeRuleGroupData?.getAwsDescribeRuleGroup;
      });
      // Detail section
      awsRuleGroupFirewallDetail.push({
        type: DetailTypeEnum.DETAIL,
        title: 'Details',
        contents: [
          {
            id: 'name',
            type: TextTypeEnum.NORMAL,
            title: 'Name',
            description: describeRuleGroup?.data?.[0]?.ruleGroupResponse.ruleGroupName,
          },
          {
            id: 'description',
            type: TextTypeEnum.NORMAL,
            title: 'Description',
            description: describeRuleGroup?.data?.[0]?.ruleGroupResponse.description,
          },
          {
            id: 'type',
            type: TextTypeEnum.NORMAL,
            title: 'Type',
            description: describeRuleGroup?.data?.[0]?.ruleGroupResponse.type,
          },
          {
            id: 'statefulRuleOrder',
            type: TextTypeEnum.NORMAL,
            title: 'Stateful rule order',
            description: describeRuleGroup?.data?.[0]?.ruleGroup.statefulRuleOptions?.ruleOrder,
          },
          {
            id: 'capacity',
            type: TextTypeEnum.NORMAL,
            title: 'Capacity',
            description: describeRuleGroup?.data?.[0]?.ruleGroupResponse.capacity,
          },
          {
            id: 'lastUpdated',
            type: TextTypeEnum.NORMAL,
            title: 'Last updated',
            description: describeRuleGroup?.data?.[0]?.ruleGroupResponse.lastModifiedTime,
          },
        ],
      });

      // Amazon SNS topic section
      awsRuleGroupFirewallDetail.push({
        type: DetailTypeEnum.MULTICOLUMN,
        contents: [
          {
            title: 'Amazon SNS topic',
            subTitle:
              'Subscribe to Amazon SNS change notifications about this rule group. Copy the ARN, and use it as the Topic ARN when you Crete a subscription in the SNS console.',
            contents: [
              {
                title: '',
                description: describeRuleGroup?.data?.[0].ruleGroupResponse.snsTopic ?? '-',
                isBorder: true,
                type: TextTypeEnum.COPY,
                button: {
                  title: 'Copy ARN',
                  action: () => {},
                },
              },
            ],
          },
        ],
      });

      // Rules section
      awsRuleGroupFirewallDetail.push({
        type: DetailTypeEnum.MULTICOLUMN,
        contents: [
          {
            title: 'Rules',
            subTitle:
              'Suricata campatible APS rules. You can copy these rules and use them as the basis for your own rule group.',
            contents: [
              {
                title: '',
                description: describeRuleGroup?.data?.[0]?.ruleGroup.rulesSource.rulesString ?? '-',
                isBorder: true,
                type: TextTypeEnum.COPY,
                button: {
                  title: 'Copy ARN',
                  action: () => {},
                },
              },
            ],
          },
        ],
      });

      // Rule variables section
      const ruleVariables = describeRuleGroup?.data?.[0]?.ruleGroup.ruleVariables;
      const ruleVariablesSectionContent = [];
      const ruleVariablesSectionContentRows: RowType[] = [];
      const ruleVariablesSectionContentColumns: ColumnType[] = [
        {
          label: 'Name',
          field: 'name',
          sort: true,
        },
        {
          label: 'Type',
          field: 'type',
          sort: true,
        },
      ];
      if (ruleVariables?.ipSets) {
        const ipSets = Object.entries(ruleVariables?.ipSets).map(([key, value]) => {
          return key;
        });
        if (ipSets && ipSets.length > 0) {
          ipSets.map((key, index) => {
            ruleVariablesSectionContentRows.push({
              name: key,
              type: 'IP Set',
            });
          });
        }
      }
      if (ruleVariables?.portSets) {
        const portSets = Object.entries(ruleVariables?.portSets).map(([key, value]) => {
          return key;
        });
        if (portSets && portSets.length > 0) {
          portSets.map((key, index) => {
            ruleVariablesSectionContentRows.push({
              name: key,
              type: 'Ports',
            });
          });
        }
      }
      ruleVariablesSectionContent.push({
        title: 'Rules variables',
        rows: ruleVariablesSectionContentRows,
        columns: ruleVariablesSectionContentColumns,
        total: {
          totalPage: Math.ceil(ruleVariablesSectionContentRows.length / itemPerPageOfDetailTable),
          totalElement: ruleVariablesSectionContentRows.length,
        },
        tablePagination: mainTablePagination,
        updateTablePagination: (key: string, value: number | string | OrderDirection) =>
          setMainTablePagination(prev => ({
            ...prev,
            [key]: value,
          })),
      });
      const ruleVariablesSection: IMgdDetailContentProps = {
        type: DetailTypeEnum.TABLE,
        contents: ruleVariablesSectionContent,
      };
      awsRuleGroupFirewallDetail.push(ruleVariablesSection);
      setDetailContents(awsRuleGroupFirewallDetail);
      setDetailApiIsLoading(false);
    }
  };

  const getTLSInspectionDetailData = async () => {
    const detailData = tlsInspectionPageData.find(mainData => mainData.id === mainSelected);
    if (detailData) {
      setDetailApiIsLoading(true);
      const variables = {
        cloudId,
        region: selectedRegion.value as string,
        request: {
          tlsInspectionConfigurationArn: detailData?.id || '',
        },
      };
      const awsTLSInspectionConfig = await getAwsTLSInspectionConfiguration({
        variables: variables,
      }).then(({ data: tlsInspection }) => {
        return tlsInspection?.getAwsTLSInspectionConfiguration?.data?.[0];
      });

      if (awsTLSInspectionConfig) {
        // TLS inspection configuration details
        const tlsInspectionDetailSection: IMgdDetailContentProps = {
          type: DetailTypeEnum.DETAIL,
          title: 'TLS inspection configuration details',
          contents: [
            {
              title: 'Name',
              id: 'name',
              type: TextTypeEnum.NORMAL,
              description: getStrValueOrNull(
                awsTLSInspectionConfig.tlsInspectionConfigurationResponse?.tlsInspectionConfigurationName,
              ),
            },
            {
              title: 'Description',
              id: 'description',
              type: TextTypeEnum.NORMAL,
              description: getStrValueOrNull(awsTLSInspectionConfig.tlsInspectionConfigurationResponse?.description),
            },
            {
              title: 'Status',
              id: 'status',
              type: TextTypeEnum.STATUS,
              description:
                FIREWALL_COMMOMVALUE_MAPPING[
                  awsTLSInspectionConfig.tlsInspectionConfigurationResponse?.tlsInspectionConfigurationStatus
                ],
            },
            {
              title: 'Use count',
              id: 'useCount',
              type: TextTypeEnum.NORMAL,
              description: getStrValueOrNull(
                String(awsTLSInspectionConfig.tlsInspectionConfigurationResponse?.numberOfAssociations),
              ),
            },
          ],
        };
        const certificateInboundArn: string[] = [];
        const certificateOutboundArn: string[] = [];
        let scopeConfig: AwsServerCertificateScope[] = [];

        awsTLSInspectionConfig.tlsInspectionConfiguration?.serverCertificateConfigurations?.map(item => {
          item.serverCertificates?.map(el => {
            if (el.resourceArn) {
              certificateInboundArn.push(el.resourceArn);
            }
          });
          if (item.certificateAuthorityArn) {
            certificateOutboundArn.push(item.certificateAuthorityArn);
          }
          if (item.scopes) {
            scopeConfig = item.scopes;
          }
        });

        //Server certificates for inbound SSL/TLS inspection
        const inboundSectionContent = [];
        const inboundSectionContentRows: RowType[] = [];
        const inboundSectionContentColumns: ColumnType[] = [
          {
            label: 'Certificate ID',
            field: 'certificateId',
          },
          {
            label: 'Account ID',
            field: 'accountId',
          },
          {
            label: 'Domain name',
            field: 'domainName',
          },
        ];
        if (certificateInboundArn.length > 0) {
          await Promise.all(
            certificateInboundArn.map(async arn => {
              const certificateInboundVariables = {
                cloudId,
                region: selectedRegion.value as string,
                request: {
                  certificateArn: arn || '',
                },
              };
              const awsCertificateInbound = await getAwsDescribeCertificate({
                variables: certificateInboundVariables,
              }).then(({ data: awsCertificate }) => {
                return awsCertificate?.getAwsDescribeCertificate?.data?.certificate;
              });
              if (awsCertificateInbound) {
                inboundSectionContentRows.push({
                  certificateId: awsCertificateInbound.certificateArn
                    ? extractCertificateArnData(awsCertificateInbound.certificateArn)?.id
                    : '-',
                  accountId: awsCertificateInbound.certificateArn
                    ? extractCertificateArnData(awsCertificateInbound.certificateArn)?.accountId
                    : '-',
                  domainName: awsCertificateInbound.domainName || '-',
                });
              }
            }),
          );
        }
        inboundSectionContent.push({
          title: 'Server certificates for inbound SSL/TLS inspection',
          rows: inboundSectionContentRows,
          columns: inboundSectionContentColumns,
          hasPagination: false,
          total: {
            totalPage: Math.ceil(inboundSectionContentRows.length / itemPerPageOfDetailTable),
            totalElement: inboundSectionContentRows.length,
          },
          tablePagination: mainTablePagination,
          updateTablePagination: (key: string, value: number | string | OrderDirection) =>
            setMainTablePagination(prev => ({
              ...prev,
              [key]: value,
            })),
        });
        const inboundSection: IMgdDetailContentProps = {
          type: DetailTypeEnum.TABLE,
          contents: inboundSectionContent,
        };

        //CA certificate for outbound SSL/TLS inspection
        const outboundSectionContent = [];
        const outboundSectionContentRows: RowType[] = [];
        const outboundSectionContentColumns: ColumnType[] = [
          {
            label: 'Certificate ID',
            field: 'certificateId',
          },
          {
            label: 'Account ID',
            field: 'accountId',
          },
          {
            label: 'Domain name',
            field: 'domainName',
          },
        ];
        if (certificateOutboundArn.length > 0) {
          certificateOutboundArn.forEach(async arn => {
            const certificateOutboundVariables = {
              cloudId,
              region: selectedRegion.value as string,
              request: {
                certificateArn: arn,
              },
            };
            const awsCertificateOutbound = await getAwsDescribeCertificate({
              variables: certificateOutboundVariables,
            }).then(({ data: awsCertificate }) => {
              return awsCertificate?.getAwsDescribeCertificate?.data?.certificate;
            });
            if (awsCertificateOutbound) {
              outboundSectionContentRows.push({
                certificateId: awsCertificateOutbound.certificateArn
                  ? extractCertificateArnData(awsCertificateOutbound.certificateArn)?.id
                  : '-',
                accountId: awsCertificateOutbound.certificateArn
                  ? extractCertificateArnData(awsCertificateOutbound.certificateArn)?.accountId
                  : '-',
                domainName: awsCertificateOutbound.domainName || '-',
              });
            }
          });
        }
        outboundSectionContent.push({
          title: 'CA certificate for outbound SSL/TLS inspection',
          rows: outboundSectionContentRows,
          columns: outboundSectionContentColumns,
          hasPagination: false,
          total: {
            totalPage: Math.ceil(outboundSectionContentRows.length / itemPerPageOfDetailTable),
            totalElement: outboundSectionContentRows.length,
          },
          tablePagination: mainTablePagination,
          updateTablePagination: (key: string, value: number | string | OrderDirection) =>
            setMainTablePagination(prev => ({
              ...prev,
              [key]: value,
            })),
        });
        const outboundSection: IMgdDetailContentProps = {
          type: DetailTypeEnum.TABLE,
          contents: outboundSectionContent,
        };

        //Scope configuration
        const scopeSectionContent = [];
        const scopeSectionContentRows: RowType[] = [];
        const scopeSectionContentColumns: ColumnType[] = [
          {
            label: 'Protocol',
            field: 'protocol',
          },
          {
            label: 'Source',
            field: 'source',
          },
          {
            label: 'Destination',
            field: 'destination',
          },
          {
            label: 'Source port',
            field: 'sourcePort',
          },
          {
            label: 'Destination port',
            field: 'destinationPort',
          },
        ];
        scopeConfig.map(item => {
          scopeSectionContentRows.push({
            protocol: FIREWALL_RULES_PROTOCOL_MAPPING[item.protocols?.[0]]?.text || 'All',
            source: item.sources?.[0]?.addressDefinition || '0.0.0.0/0',
            destination: item.destinations?.[0]?.addressDefinition || '0.0.0.0/0',
            sourcePort: `${item.sourcePorts?.[0]?.fromPort}:${item.sourcePorts?.[0]?.toPort}` || '-',
            destinationPort: `${item.destinationPorts?.[0]?.fromPort}:${item.destinationPorts?.[0]?.toPort}` || '-',
          });
        });
        scopeSectionContent.push({
          title: 'Scope configuration',
          rows: scopeSectionContentRows,
          columns: scopeSectionContentColumns,
          hasPagination: false,
          total: {
            totalPage: Math.ceil(scopeSectionContentRows.length / itemPerPageOfDetailTable),
            totalElement: scopeSectionContentRows.length,
          },
          tablePagination: mainTablePagination,
          updateTablePagination: (key: string, value: number | string | OrderDirection) =>
            setMainTablePagination(prev => ({
              ...prev,
              [key]: value,
            })),
        });
        const scopeSection: IMgdDetailContentProps = {
          type: DetailTypeEnum.TABLE,
          contents: scopeSectionContent,
        };

        //Customer managed keys
        const customerManagedKeysSection: IMgdDetailContentProps = {
          type: DetailTypeEnum.MULTICOLUMN,
          contents: [
            {
              title: 'Customer managed keys',
              contents: [
                {
                  title: 'key type',
                  description: getStrValueOrNull(
                    awsTLSInspectionConfig.tlsInspectionConfigurationResponse?.encryptionConfiguration?.type,
                  ),
                },
              ],
            },
          ],
        };

        //Certificate revocation status
        const certificateStatusSection: IMgdDetailContentProps = {
          type: DetailTypeEnum.MULTICOLUMN,
          contents: [
            {
              title: 'Certificate revocation status',
              contents: [
                {
                  title: 'Enabled',
                  description: awsTLSInspectionConfig.tlsInspectionConfiguration?.serverCertificateConfigurations?.[0]
                    ?.checkCertificateRevocationStatus
                    ? 'Yes'
                    : 'No',
                },
              ],
            },
          ],
        };

        //Tags
        const tags = awsTLSInspectionConfig?.tlsInspectionConfigurationResponse?.tags;
        const tagsSectionContent = [];
        const tagsSectionContentRows: RowType[] = [];
        const tagsSectionContentColumns: ColumnType[] = [
          {
            label: 'Key',
            field: 'key',
            sort: true,
          },
          {
            label: 'Value',
            field: 'value',
            sort: true,
          },
        ];
        if (tags) {
          tags.map(item => {
            tagsSectionContentRows.push({
              key: item.key,
              value: item.value,
            });
          });
        }

        tagsSectionContent.push({
          title: 'Tags',
          description: 'Apply tags to your resources to help organize and identify them.',
          rows: tagsSectionContentRows,
          columns: tagsSectionContentColumns,
          total: {
            totalPage: 1,
            totalElement: tagsSectionContentRows.length,
          },
          tablePagination: mainTablePagination,
          updateTablePagination: (key: string, value: number | string | OrderDirection) =>
            setMainTablePagination(prev => ({
              ...prev,
              [key]: value,
            })),
        });
        const tagsSection: IMgdDetailContentProps = {
          type: DetailTypeEnum.TABLE,
          contents: tagsSectionContent,
        };

        setDetailTitle(detailData?.name);
        setDetailContents([
          tlsInspectionDetailSection,
          inboundSection,
          outboundSection,
          scopeSection,
          customerManagedKeysSection,
          certificateStatusSection,
          tagsSection,
        ]);
        setDetailApiIsLoading(false);
      }
    } else {
      setDetailContents([]);
    }
  };

  const getResourcesGroupDetailData = async () => {
    const detailData = awsFirewallPageData.find(mainData => mainData.id === mainSelected);
    if (detailData) {
      setDetailApiIsLoading(true);
      const variables = {
        cloudId,
        region: selectedRegion.value as string,
        request: {
          group: detailData?.id || '',
        },
      };
      const awsGroup = await getAwsGroup({
        variables: variables,
      }).then(({ data: group }) => {
        return group?.getAwsGroup?.data;
      });

      if (awsGroup) {
        const groupDetailSection: IMgdDetailContentProps = {
          type: DetailTypeEnum.MULTICOLUMN,
          contents: [
            {
              title: 'Details',
              contents: [
                {
                  title: 'Name',
                  type: TextTypeEnum.NORMAL,
                  description: getStrValueOrNull(awsGroup.group?.name),
                },
                {
                  title: 'Description',
                  type: TextTypeEnum.NORMAL,
                  description: getStrValueOrNull(awsGroup.group?.description),
                },
                {
                  title: 'Group ARN',
                  type: TextTypeEnum.COPY,
                  description: getStrValueOrNull(awsGroup.group?.groupArn),
                },
              ],
            },
          ],
        };

        //Grouping criteria
        const variablesGroupQuery = {
          cloudId,
          region: selectedRegion.value as string,
          request: {
            group: detailData?.id || '',
          },
        };
        const awsGroupQueryData = await getAwsGroupQuery({
          variables: variablesGroupQuery,
        }).then(({ data: groupQuery }) => {
          return groupQuery?.getAwsGroupQuery?.data?.[0];
        });

        let groupQuery;
        if (awsGroupQueryData?.groupQuery?.resourceQuery?.query) {
          groupQuery = JSON.parse(awsGroupQueryData?.groupQuery?.resourceQuery?.query);
        }
        const groupCriteriaSection: IMgdDetailContentProps = {
          type: DetailTypeEnum.MULTICOLUMN,
          contents: [
            {
              title: 'Grouping criteria',
              contents: [
                {
                  title: 'Resource types',
                  type: TextTypeEnum.NORMAL,
                  description: getStrValueOrNull(groupQuery.ResourceTypeFilters?.[0]),
                },
                {
                  title: 'Tags',
                  type: TextTypeEnum.NORMAL,
                  description: getStrValueOrNull(groupQuery.TagFilters?.[0].Key),
                },
              ],
            },
          ],
        };

        //Matching resources
        const matchingResourcesSectionContent = [];
        const matchingResourcesSectionContentRows: RowType[] = [];
        const matchingResourcesSectionContentColumns: ColumnType[] = [
          {
            label: 'Identifier',
            field: 'identifier',
          },
          {
            label: 'Name',
            field: 'name',
          },
          {
            label: 'Service',
            field: 'service',
          },
          {
            label: 'Type',
            field: 'type',
          },
          {
            label: 'Region',
            field: 'region',
          },
        ];
        const variablesGroupResources = {
          cloudId: cloudId,
          region: selectedRegion.value as string,
          request: {
            maxResults: 50,
            group: detailData.id,
            pageInfo: {
              page: 0,
              size: 50,
              orders: [
                {
                  target: 'resourceArn',
                  direction: OrderDirection.ASC,
                },
              ],
            },
          },
        };
        const awsGroupResourcesData = await getAwsAllListGroupResources({
          variables: variablesGroupResources,
        }).then(({ data: groupResources }) => {
          return groupResources?.getAwsAllListGroupResources?.data;
        });
        if (awsGroupResourcesData) {
          const resourceARNList: string[] = awsGroupResourcesData.map(gs => {
            return gs.resourceArn;
          });
          const variablesResources = {
            cloudId: cloudId,
            region: selectedRegion.value as string,
            request: {
              resourceARNList: resourceARNList,
              pageInfo: {
                page: 0,
                size: 100,
                orders: [
                  {
                    target: 'resourceArn',
                    direction: OrderDirection.ASC,
                  },
                ],
              },
            },
          };
          const awsResourcesData = await getAwsAllResources({
            variables: variablesResources,
          }).then(({ data: groupResources }) => {
            return groupResources?.getAwsAllResources?.data;
          });
          if (awsResourcesData) {
            awsResourcesData.map(item => {
              matchingResourcesSectionContentRows.push({
                identifier: extractResourceArnData(item.resourceARN).id,
                name: item.tags.find(i => i.key === 'Name')?.value,
                service: FIREWALL_COMMOMVALUE_MAPPING[extractResourceArnData(item.resourceARN).service || ''],
                type: FIREWALL_COMMOMVALUE_MAPPING[extractResourceArnData(item.resourceARN).resourceType || ''],
                region: extractResourceArnData(item.resourceARN).region,
              });
            });
          }
        }

        matchingResourcesSectionContent.push({
          title: 'Matching resources',
          description: 'The resources in your account in the current Region that match the grouping criteria.',
          rows: matchingResourcesSectionContentRows,
          columns: matchingResourcesSectionContentColumns,
          hasPagination: false,
          total: {
            totalPage: Math.ceil(matchingResourcesSectionContentRows.length / itemPerPageOfDetailTable),
            totalElement: matchingResourcesSectionContentRows.length,
          },
          tablePagination: mainTablePagination,
          updateTablePagination: (key: string, value: number | string | OrderDirection) =>
            setMainTablePagination(prev => ({
              ...prev,
              [key]: value,
            })),
        });
        const matchingResourcesSection: IMgdDetailContentProps = {
          type: DetailTypeEnum.TABLE,
          contents: matchingResourcesSectionContent,
        };

        //Resource group tags
        const variablesGroupTags = {
          cloudId,
          region: selectedRegion.value as string,
          request: {
            arn: detailData?.id || '',
          },
        };
        const awsTags = await getAwsTags({
          variables: variablesGroupTags,
        }).then(({ data: tags }) => {
          return tags?.getAwsTags?.data?.[0];
        });
        const tagsSectionContent = [];
        const tagsSectionContentRows: RowType[] = [];
        const tagsSectionContentColumns: ColumnType[] = [
          {
            label: 'Key',
            field: 'key',
            sort: true,
          },
          {
            label: 'Value',
            field: 'value',
            sort: true,
          },
        ];
        if (awsTags) {
          Object.entries(awsTags?.tags).map(([key, value]) => {
            tagsSectionContentRows.push({
              key: key,
              value: value,
            });
          });
        }

        tagsSectionContent.push({
          title: 'Resource group tags',
          description:
            'The tags applied to the resource group. The tags specified here are applied to the resource group itself, not the individual resources within it.',
          rows: tagsSectionContentRows,
          columns: tagsSectionContentColumns,
          total: {
            totalPage: 1,
            totalElement: tagsSectionContentRows.length,
          },
          tablePagination: mainTablePagination,
          updateTablePagination: (key: string, value: number | string | OrderDirection) =>
            setMainTablePagination(prev => ({
              ...prev,
              [key]: value,
            })),
        });
        const tagsSection: IMgdDetailContentProps = {
          type: DetailTypeEnum.TABLE,
          contents: tagsSectionContent,
        };

        setDetailTitle(detailData?.name);
        setDetailContents([groupDetailSection, groupCriteriaSection, matchingResourcesSection, tagsSection]);
        setDetailApiIsLoading(false);
      }
    } else {
      setDetailContents([]);
    }
  };

  const detailTitleBtnDelClickedHandler = (mainCurrentTab: IMgdTabProps) => {
    switch (mainCurrentTab) {
      case mainTabs[0]:
        setDeleteResourceModalOpened(true);
        break;
      case mainTabs[1]:
        setDeleteFirewallPolicyResourceModalOpened(true);
        break;

      default:
        break;
    }
  };

  const editDetailBtnClickedHandler = (key: string, option?: DropdownListDataType) => {
    switch (key) {
      case 'firewallDetails':
        setEditFirewallDetailModalOpned(true);
        break;
      case 'vpc':
        setEditVpcAndSubnetsModalOpened(true);
        break;
      case 'firewallPolicy':
        setEditFirewallPolicyModalOpened(true);
        break;
      case 'changeProtection':
        setProtectionAgainstChangeModalOpened(true);
        break;
      case 'customerManageKey':
        setCustomerManageKeyModalOpened(true);
        break;
      case 'firewallTags':
        setFirewallTagsModalOpened(true);
        break;
      case 'statelessDefaultActions':
        setStatelessDefaultActionsModalOpened(true);
        break;
      case 'statelessEditPriority':
        setEditPriorityStatelessModalOpened(true);
        break;
      case 'statefulEditPriority':
        setEditPriorityStatefulModalOpened(true);
        break;
      case 'addUnmanageStatelessRG':
        if (option == statelessRuleGroupDropdownList[1]) {
          setAddUnManageStatelessRGModalOpened(true);
        }
        if (option == statelessRuleGroupDropdownList[2]) {
          setDisassociateRGModalOpened(true);
        }
        break;
      case 'addUnmanageStatefulRG':
        if (option == statefulRuleGroupDropdownList[1]) {
          setAddUnManageStatefulRGModalOpened(true);
        }
        if (option == statefulRuleGroupDropdownList[2]) {
          setAddManagedStatefulRGModalOpened(true);
        }
        if (option == statefulRuleGroupDropdownList[3]) {
          setDisassociateRGModalOpened(true);
        }
        break;
      case 'editFirewallPolicyDetails':
        setEditFirewallPolicyDetailsModalOpened(true);
        break;
      case 'manageTLSConfiguration':
        setManageTLSConfigurationModalOpened(true);
        break;
      case 'policyVariables':
        setEditPolicyVariablesModalOpened(true);
        break;
      case 'policyTags':
        setEditTagsModalOpened(true);
        break;
      case 'editFirewallRuleGroupDetails':
        setEditFirewallRuleGroupDetailModalOpened(true);
        break;
      case 'editFirewallRuleVariables':
        setEditFirewallRuleVariablesModalOpened(true);
        break;
      case 'deleteFirewallRuleVariables':
        setDeleteFirewallRuleVariablesModalOpened(true);
        break;
      case 'editFirewallIpSetReferences':
        setEditFirewallIpSetReferencesModalOpened(true);
        break;
      case 'deleteFirewallIpSetReferences':
        setDeleteFirewallIpSetReferencesModalOpened(true);
        break;
      case 'addFirewallNewRule':
        setAddFirewallNewRuleModalOpened(true);
        break;
      case 'deleteFirewallRuleGroup':
        setDeleteFirewallRuleGroupModalOpened(true);
        break;
      case 'editFirewallRuleGroup':
        setEditFirewallRuleGroupModalOpened(true);
        break;
      default:
        break;
    }
  };

  const handleClickCreateButton = () => {
    switch (mainTabSelected) {
      case mainTabs[0]:
        setCreationScreenName('FIREWALL');
        break;
      case mainTabs[1]:
        setCreationScreenName('FIREWALL_POLICY');
        break;
      case mainTabs[2]:
        setCreationScreenName('RULE_GROUP');
        break;
      default:
        break;
    }
  };

  const fetchMoreMainRowsHandler = useCallback(
    (nextToken: string) => {
      switch (mainTabSelected) {
        case mainTabs[0]:
          getFirewallMainDataRows(nextToken);
          break;
        case mainTabs[1]:
          getFirewallPolicyMainDataRows(nextToken);
          break;
        case mainTabs[2]:
          getRuleGroupMainDataRows(nextToken);
          break;
        case mainTabs[3]:
          getAwsManagedDomainList(nextToken);
          break;
        case mainTabs[4]:
          getTlsInspectionMainDataRows(nextToken);
          break;
        case mainTabs[5]:
          getResourceGroupMainDataRows(nextToken);
          break;
        default:
          break;
      }
    },
    [mainTabSelected],
  );

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      fetchMoreMainRowsHandler(mainNextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, mainTablePagination, mainTblTotal, mainNextToken]);

  const mainExtraContentCurrentPage = useMemo(() => {
    if (
      mainThreatSignatureTbl &&
      mainExtraTablePagination.currentPage >
        Math.ceil(mainThreatSignatureTbl?.rows.length / mainExtraTablePagination.itemPerPage) &&
      !!mainExtraNextToken
    ) {
      getAwsManagedThreatSignatures(mainExtraNextToken);
    }

    const startIndex = (mainExtraTablePagination.currentPage - 1) * mainExtraTablePagination.itemPerPage;
    const endIndex = startIndex + mainExtraTablePagination.itemPerPage;

    return orderAlphabetical(
      mainThreatSignatureTbl?.rows || [],
      mainExtraTablePagination.target,
      mainExtraTablePagination.direction,
    ).slice(startIndex, endIndex);
  }, [mainThreatSignatureTbl, mainExtraTablePagination]);

  const mainExtraContent = useMemo((): IMgdMainExtraContentProps[] => {
    if (mainThreatSignatureTbl) {
      return [
        {
          rows: mainExtraContentCurrentPage,
          columns: mainThreatSignatureTbl.columns,
          total: {
            totalPage: Math.ceil(mainThreatSignatureTbl.rows.length / mainExtraTablePagination.itemPerPage),
            totalElement: mainThreatSignatureTbl.rows.length,
          },
          tablePagination: mainExtraTablePagination,
          updateTablePagination: (key: string, value: number | string | OrderDirection) =>
            setMainExtraTablePagination(prev => ({
              ...prev,
              [key]: value,
            })),
          checkedList: [''],
          reportCheckedList: () => {},
          currentReport: mainSelected,
          reportSelected: () => {},
          ableFetchMore: !!mainExtraNextToken,
        },
      ];
    }
    return [];
  }, [mainExtraContentCurrentPage, mainExtraTablePagination, mainThreatSignatureTbl, mainExtraNextToken]);

  const renderPage = useMemo(() => {
    if (creationScreenName === '') {
      switch (layoutType) {
        case MgdLayoutTypeEnum.MGD_DETAIL:
          const detailInfo =
            mainTblRows.find(r => r?.id == mainSelected) ??
            mainExtraContentCurrentPage.find(r => r?.id == mainSelected);
          return (
            <MgdLayout
              layoutType={layoutType}
              pageTitle={`${detailInfo?.name != undefined ? detailInfo?.name : ''}`}
              hasFavorite={false}
              pageBackClick={() => {
                setLayoutType(MgdLayoutTypeEnum.MGD_LIST_SUMMARY);
                setMainSelected('');
                resetDetailData();
                setDetailTabSelected(detailTabList[0]);
              }}
              rows={[]}
              columns={[]}
              total={mainTblTotal}
              tablePagination={mainTablePagination}
              updateTablePagination={(key: string, value: number | string | OrderDirection) =>
                setMainTablePagination(prev => ({
                  ...prev,
                  [key]: value,
                }))
              }
              checkedList={checkedList}
              reportCheckedList={(list: string[]) => setCheckedList(list)}
              currentReport={mainSelected}
              reportSelected={(id: string) => {
                setMainSelected(id);
              }}
              mainTabList={mainTabs}
              mainTabItemClick={mainTabClickHandler}
              mainTitle={''}
              mainTabSelected={mainTabSelected}
              detailTabList={detailTabList}
              detailTabItemClick={setDetailTabSelected}
              detailTabSelected={detailTabSelected}
              detailHyperLinkClick={handleHDetailHyperlinkMainClick}
              extraOverviewDetail={extraOverviewDetail}
              detailsExtraData={detailContents}
              detailTitleBtnDelVisible={detailTitleBtnDelVisible}
              detailTitleBtnDelClicked={() => detailTitleBtnDelClickedHandler(mainTabSelected)}
              mainIsLoading={mainApiIsLoading}
              detailIsLoading={detailApiIsLoading}
              extraOverviewDetailIsLoading={extraOverviewDetailApiIsLoading}
            />
          );
        default:
          return (
            <MgdLayout
              layoutType={layoutType}
              pageTitle={cloudName}
              hasFavorite={true}
              pageBackClick={pageBackClick}
              recentRelatedClouds={recentRelatedClouds}
              relatedCloudSelected={relatedCloudSelected}
              recentRelatedCloudOnChange={relatedCloud => recentRelatedCloudOnChange(relatedCloud)}
              headerDropList={regionList}
              headerDropValue={selectedRegion}
              headerDropOnchange={val => {
                setSelectedRegion(() => ({
                  name: val.name ? val.name : '',
                  value: String(val.value),
                }));
              }}
              rows={mainRowsCurrentPage}
              columns={mainTblColumns}
              total={mainTblTotal}
              tablePagination={mainTablePagination}
              updateTablePagination={(key: string, value: number | string | OrderDirection) =>
                setMainTablePagination(prev => ({
                  ...prev,
                  [key]: value,
                }))
              }
              checkedList={checkedList}
              reportCheckedList={(list: string[]) => setCheckedList(list)}
              currentReport={mainSelected}
              reportSelected={(id: string) => {
                if (mainTabSelected != mainTabs[5]) setMainSelected(id);
              }}
              isPined={
                !!defaultRegionList.find(r => {
                  return r.projectId === projectId && r.cloudId === cloudId;
                })
              }
              pinBtnActHandle={pinButtonClickHandle}
              pinBtnDisabled={false}
              mainTabList={mainTabs}
              mainTabItemClick={mainTabClickHandler}
              mainTitle={mainTabSelected.title}
              mainTabSelected={mainTabSelected}
              // mainTypeList={mainSearchDropdown}
              // mainTypeValue={mainFilterValue}
              // mainTypeDropdownOnchange={val => {
              //   setMainFilterValue(val);
              //   setMainSearchValue(prev => ({
              //     value: prev.value,
              //     name: val.value.toString(),
              //   }));
              // }}
              searchValue={mainSearchValue}
              searchInputOnchange={str => setMainSearchValue(str)}
              onSearchKeyDown={(event: any) => onSearchKeyDownHandler(event)}
              detailTabList={detailTabList}
              detailTabItemClick={setDetailTabSelected}
              detailTitle={detailTitle}
              detailContents={detailContents}
              detailTabSelected={detailTabSelected}
              detailHyperLinkClick={handleHDetailHyperlinkMainClick}
              extraOverviewDetail={extraOverviewDetail}
              extraMainContent={mainExtraContent}
              mainIsLoading={mainApiIsLoading}
              detailIsLoading={detailApiIsLoading}
              extraOverviewDetailIsLoading={extraOverviewDetailApiIsLoading}
              mainCreateBtnVisible={mainCreateBtnVisible}
              mainCreateBtnLabel={mainCreateBtnLabel}
              mainCreateBtnClicked={() => {
                handleClickCreateButton();
              }}
            />
          );
      }
    }
  }, [
    layoutType,
    regionList,
    selectedRegion,
    mainTabSelected,
    mainTblRows,
    mainTblColumns,
    mainTblTotal,
    mainSelected,
    detailTabSelected,
    mainTablePagination,
    mainSearchDropdown,
    // mainFilterValue,
    mainSearchValue,
    detailTabList,
    detailTitle,
    detailContents,
    extraOverviewDetail,
    mainExtraContent,
    creationScreenName,
    detailTitleBtnDelVisible,
    statelessRuleGroupDropdownList,
    statefulRuleGroupDropdownList,
    mainApiIsLoading,
    detailApiIsLoading,
    extraOverviewDetailApiIsLoading,
    mainRowsCurrentPage,
    mainExtraContentCurrentPage,
    recentRelatedClouds,
    relatedCloudSelected,
  ]);
  return (
    <div
      className="category"
      style={{
        height: '100%',
        flex: 1
      }}
    >
      {renderPage}
      <EditFirewallDetailModal
        enumType={'EditFireWallDetail'}
        header={'Edit firewall details'}
        valueName={'test-firewall'}
        placeholderDescription={'description'}
        open={editFirewallDetailModalOpened}
        onClose={() => setEditFirewallDetailModalOpned(false)}
      />
      <AddManagedStatefullRuleGroupModal
        open={addManagedStatefulRGModalOpened}
        onClose={() => setAddManagedStatefulRGModalOpened(false)}
      />
      <EditVPCAndSubnetsModal
        vpcId={'vpc-09f2c7417c7e90bf'}
        currentData={[
          {
            availabilityZoneId: 'Ap-northeast-2a',
            subnetId: 'vpc-09f2c7417c7e90bf',
            ipAdressType: 'IPv4',
          },
        ]}
        open={editVpcAndSubnetsModalOpened}
        onClose={() => setEditVpcAndSubnetsModalOpened(false)}
      />
      <EditFirewallPolicyModal
        header={'Edit firewall policy'}
        open={editFirewallPolicyModalOpened}
        onClose={() => setEditFirewallPolicyModalOpened(false)}
      />
      <ProtectionAgainstChangeModal
        header={'Protection against changes'}
        open={protectionAgainstChangeModalOpened}
        onClose={() => setProtectionAgainstChangeModalOpened(false)}
      />
      <EditKMSEncryptionModal
        header={'Edit KMS encryption settings'}
        open={customerManageKeyModalOpened}
        onClose={() => setCustomerManageKeyModalOpened(false)}
      />
      <UpdateTagsModal
        header={'Edit tags'}
        subHeader={'Edit firewall tags'}
        currentDatas={[]}
        columns={['Key', 'Value-option']}
        note={'You can add up to 48 more tags.'}
        open={firewallTagsModalOpened}
        onSave={() => {}}
        onClose={() => setFirewallTagsModalOpened(false)}
      />
      <StatelessActionsModal
        header={'Stateless default actions'}
        open={statelessDefaultActionsModalOpened}
        onClose={() => setStatelessDefaultActionsModalOpened(false)}
      />
      <UnmanagedStateModal
        header={'Add unmanaged stateless rule groups'}
        note={'Select and add the stateless rule groups that you want in your firewall policy.'}
        subHeader={'Stateless rule group'}
        columns={[
          {
            label: 'Name',
            field: 'name',
            sort: true,
          },
        ]}
        row={[
          {
            id: 1,
            name: 'test stateless group1',
          },
          {
            id: 2,
            name: 'test stateless group2',
          },
          {
            id: 3,
            name: 'test stateless group3',
          },
          {
            id: 4,
            name: 'test stateless group4',
          },
        ]}
        btnTitle={'Add stateless rule group'}
        open={addUnManageStatelessRGModalOpened}
        onChangeValue={() => {}}
        onClose={() => setAddUnManageStatelessRGModalOpened(false)}
      />

      <UnmanagedStateModal
        header={'Add unmanaged stateful rule groups'}
        note={'Select and add the stateful rule groups that you want in your firewall policy.'}
        subHeader={'Stateful rule group'}
        columns={[
          {
            label: 'Name',
            field: 'name',
            sort: true,
          },
        ]}
        icon={'stateful'}
        row={[
          {
            id: '1',
            name: 'test stateless group1',
          },
          {
            id: '2',
            name: 'test stateless group2',
          },
          {
            id: '3',
            name: 'test stateless group3',
          },
          {
            id: '4',
            name: 'test stateless group4',
          },
        ]}
        btnTitle={'Add stateful rule group'}
        open={addUnManageStatefulRGModalOpened}
        onChangeValue={() => {}}
        onClose={() => setAddUnManageStatefulRGModalOpened(false)}
      />

      <EditPriority
        header={'Edit priority'}
        subHeader={'Available subnets'}
        columns={[
          {
            label: 'Priority',
            field: 'priority',
            sort: true,
          },
          {
            label: 'Name',
            field: 'name',
            sort: true,
          },
          {
            label: 'Capacity',
            field: 'capacity',
            sort: true,
          },
        ]}
        isStateless={true}
        rows={[
          {
            id: '1',
            priority: 5,
            name: 'test stateless group1',
            capacity: 2,
          },
          {
            id: '2',
            priority: 6,
            name: 'test stateless group2',
          },
          {
            id: '3',
            priority: 7,
            name: 'test stateless group3',
          },
          {
            id: '4',
            priority: 0,
            name: 'test stateless group4',
          },
        ]}
        open={editPriorityStatelessModalOpened}
        onChangeValue={() => {}}
        onClose={() => setEditPriorityStatelessModalOpened(false)}
      />

      <EditPriority
        header={'Edit priority'}
        subHeader={'Available subnets'}
        columns={[
          {
            label: 'Priority',
            field: 'priority',
            sort: true,
          },
          {
            label: 'Name',
            field: 'name',
            sort: true,
          },
          {
            label: 'Capacity',
            field: 'capacity',
            sort: true,
          },
        ]}
        rows={[
          {
            id: '1',
            priority: 5,
            name: 'test stateful group1',
            capacity: 2,
          },
          {
            id: '2',
            priority: 6,
            name: 'test stateful group2',
          },
          {
            id: '3',
            priority: 7,
            name: 'test stateful group3',
          },
          {
            id: '4',
            priority: 0,
            name: 'test stateful group4',
          },
        ]}
        open={editPriorityStatefulModalOpened}
        onChangeValue={() => {}}
        onClose={() => setEditPriorityStatefulModalOpened(false)}
      />

      <DisassociateRuleGroups
        name={
          'Are you sure you want to Disassociate the rule groups: test-stateless-now from firewall policy test2-firewall-policy?'
        }
        header={'Disassociate rule groups'}
        onDelete={() => {}}
        open={disassociateRGModalOpened}
        onClose={() => setDisassociateRGModalOpened(false)}
      />

      <DeleteResource
        header={'Delete resource'}
        onDelete={() => {}}
        open={deleteResourceModalOpened}
        onClose={() => setDeleteResourceModalOpened(false)}
      />

      <DeleteResource
        header={'Delete resource'}
        titleWarning={'Are you sure you want to delete the girewall policy: test-firewall-policy?'}
        subWarning={'This will delete the selected firewall policy. Deleting the firewall policy cannot be undone.'}
        onDelete={() => {}}
        open={deleteFirewallPolicyResourceModalOpened}
        onClose={() => setDeleteFirewallPolicyResourceModalOpened(false)}
      />

      <ManageTLSConfigurationModal
        header={'Manage TLS inspection configuration'}
        open={manageTLSConfigurationModalOpened}
        onClose={() => setManageTLSConfigurationModalOpened(false)}
      />

      <EditFirewallPolicyDetailModal
        header={'Edit firewall policy details'}
        open={editFirewallPolicyDetailsModalOpened}
        onClose={() => setEditFirewallPolicyDetailsModalOpened(false)}
      />

      <EditPolicyVariablesModal
        header={'Edit policy variables'}
        open={editPolicyVariablesModalOpened}
        onClose={() => setEditPolicyVariablesModalOpened(false)}
      />

      <UpdateTagsModal
        header={'Edit Tags'}
        subHeader={'Edit policy tags'}
        note={'You can add up to 48 more tags.'}
        currentDatas={[]}
        columns={['Key', 'Value-option']}
        onSave={() => {}}
        open={editTagsModalOpened}
        onClose={() => setEditTagsModalOpened(false)}
      />

      <EditFirewallDetailModal
        enumType={'EditRuleGroup'}
        header={'Edit rule group details'}
        valueName={'test-firewall-rule-group'}
        placeholderDescription={'The description can have 0-256 characters.'}
        open={editFirewallRuleGroupDetailModalOpened}
        onClose={() => setEditFirewallRuleGroupDetailModalOpened(false)}
      />

      <EditRuleVariablesModal
        header={'Edit rule variables'}
        note={
          'Define IP sets and ports as variables. These variables can be used within this rule group for standard stateful rules and Suricate compatible rule strings.'
        }
        currentIpDatas={[{ index: 1, ipName: 'home-net', ipValue: '10.10.034' }]}
        currentPortDatas={[{ index: 1, portName: 'Allow port', portValue: '22\n43\n43' }]}
        columnPortVariables={['Variable name', 'Port variable values']}
        columnIpVariables={['Variable name', 'Values']}
        onSave={() => {}}
        open={editFirewallRuleVariablesModalOpened}
        onClose={() => setEditFirewallRuleVariablesModalOpened(false)}
      />

      <DeleteRuleModal
        header={'Delete rule variables'}
        titleWarning={
          'Are you sure you want to delete the following rule varaibles from the rule group-test-firewall-rule group'
        }
        note={'Rule variables to delete:'}
        valueInput={'home_net'}
        onDelete={() => {}}
        open={deleteFirewallRuleVariablesModalOpened}
        onClose={() => setDeleteFirewallRuleVariablesModalOpened(false)}
      />

      <EditIPSetReferenceModal
        header={'Edit IP set references'}
        currentDatas={[]}
        columns={['Variable name', 'Resource ID', 'CIDRs']}
        note={
          'Define IP sets and ports as variables. These variables can be used within this rule group for standard stateful rules and Suricate compatible rule strings.'
        }
        open={editFirewallIpSetReferencesModalOpened}
        onSave={() => {}}
        onClose={() => setEditFirewallIpSetReferencesModalOpened(false)}
      />

      <DeleteRuleModal
        header={'Delete IP set references'}
        titleWarning={
          'Are you sure you want to delete the following references from the rule group: test-firewall-rule-group?'
        }
        note={'IP Set references to delete'}
        valueInput={'ip_set_ref_text1_up_set_ref_test2'}
        onDelete={() => {}}
        open={deleteFirewallIpSetReferencesModalOpened}
        onClose={() => setDeleteFirewallIpSetReferencesModalOpened(false)}
      />

      <EditRuleModal
        enumType={'editRule'}
        header={'Edit rule'}
        open={editFirewallRuleGroupModalOpened}
        onClose={() => setEditFirewallRuleGroupModalOpened(false)}
      />

      <DeleteRuleModal
        header={'Delete rule'}
        titleWarning={
          'Are you sure you want to delete the following references from the rule group: test-firewall-rule-group?'
        }
        note={
          'Protocol: HTTP \n Source: ANy, Port: ANY \n Destination: ANY, Port: ANY \n Direction: Forward \n Action: Pass \n Rule Options: sid1'
        }
        onDelete={() => {}}
        open={deleteFirewallRuleGroupModalOpened}
        onClose={() => setDeleteFirewallRuleGroupModalOpened(false)}
      />

      <EditRuleModal
        enumType={'addRule'}
        header={'Add new rule'}
        open={addFirewallNewRuleModalOpened}
        onClose={() => setAddFirewallNewRuleModalOpened(false)}
      />

      <DeleteResource
        header={'Delete resource'}
        titleWarning={'Are you sure you want to delete the rule groups: test-stateless-rule-group?'}
        subWarning={'This will delete the selected rule group. Deleting the rule group cannot be undone.'}
        onDelete={() => {}}
        open={false}
        onClose={() => {}}
      />

      {creationScreenName === 'FIREWALL' && (
        <FirewallCreation
          pageBackClick={() => {
            setLayoutType(MgdLayoutTypeEnum.MGD_LIST_SUMMARY);
            setMainSelected('');
            setCreationScreenName('');
          }}
        />
      )}
      {creationScreenName === 'RULE_GROUP' && (
        <RuleGroupCreation
          pageBackClick={() => {
            setLayoutType(MgdLayoutTypeEnum.MGD_LIST_SUMMARY);
            setMainSelected('');
            setCreationScreenName('');
          }}
        />
      )}
      {false && (
        <FirewallPoliciesPreviewCreation
          pageBackClick={() => {
            setLayoutType(MgdLayoutTypeEnum.MGD_LIST_SUMMARY);
            setMainSelected('');
            setCreationScreenName('');
          }}
        />
      )}
      {creationScreenName === 'FIREWALL_POLICY' && (
        <FirewallPolicyCreation
          onBackButtonClicked={() => {
            setLayoutType(MgdLayoutTypeEnum.MGD_LIST_SUMMARY);
            setMainSelected('');
            setCreationScreenName('');
          }}
        />
      )}
    </div>
  );
};

export default Firewall;
