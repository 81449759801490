import InputAtom from 'components/v2/atoms/InputAtom';
import './index.scss';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import { useEffect, useState } from 'react';

interface IMFAResyncDeviceModalModalProps extends IBaseModalProps {
  content?: () => JSX.Element;
  buttonTitle?: string;
  onConfirm?: (code1: string, code2: string) => void;
}

const MFAResyncDeviceModal = ({
  title,
  content,
  buttonTitle,
  onConfirm,
  ...baseModalProps
}:IMFAResyncDeviceModalModalProps) => {
  const [code1, setCode1] = useState('');
  const [code2, setCode2] = useState('');

  const sendApi = () => {
    //todo gql 쏘고 부모한테 결과 올려주기
    onConfirm && onConfirm(code1, code2);
  }

  useEffect(() => {
    if(!baseModalProps.open) {
      setCode1('');
      setCode2('');
    }
  },[baseModalProps.open]);

  return (
    <BaseModal
      title={title}
      className="uniform"
      closeIcon={false}
      {...baseModalProps}
    >
      <div className="mfa-resync-device-modal">
        <h5>
        Fill in two consecutive codes from your MFA device to resynchronize it.
        </h5>
        <InputAtom
          placeholder='MFA Code 1'
          onChangeValue={(val: string) => setCode1(val)}
          value={code1}
        />
        <InputAtom 
          placeholder='MFA Code 2'
          onChangeValue={(val: string) => setCode2(val)}
          value={code2}
        />
        <div className="btns flex j-end">
          <button 
            className="big-sub-btn-body2regular flex j-center a-center"
            onClick={baseModalProps.onClose}  
          >
          Cancel
          </button>
          <button 
            className="big-main-btn-body2regular flex j-center a-center"
            onClick={sendApi}
          >
            {buttonTitle}
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default MFAResyncDeviceModal;
