import { gql } from '@apollo/client';

const query = gql`
mutation replaceAwsNetworkAcIEntry($request: AwsReplaceNetworkAclEntryRequest, $cloudId: Long, $region: String) {
  replaceAwsNetworkAcIEntry(
    replaceNetworkAclEntryRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      __typename
    }
  }
}
`;
export default query;
