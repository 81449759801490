import { gql } from '@apollo/client';

const query = gql`
mutation deleteAwsTags($request: AwsDeleteTagsRequest, $cloudId: Long, $region: String) {
  deleteAwsTags(deleteTagsRequest: $request, cloudId: $cloudId, region: $region) {
    result
  }
}
`;
export default query;
