import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { ProjectInfo } from 'graphql/types/ProjectInfo';

export interface IUpdateProjectVariables {
  reqData: {
    projectId: number;
    organId: number;
    name?: string;
    thirdPartTypeCode?: string;
    attrKey1?: string;
    attrValue1?: string;
    monitorTn?: boolean;
    msspYn?: boolean;
  }
}

export interface IUpdateProjectResponseData {
  updateProject: IGqlResponseData<ProjectInfo[]>;
}

/** 프로젝트 수정 */
const updateProject = () => useMutation<IUpdateProjectResponseData, IUpdateProjectVariables>(query);
export default updateProject;