import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { DashboardListType } from 'graphql/types/DashboardListType';

export interface IGetDashboardsByOragnIdVariables {
  organId: number
}

export interface IGetDashboardsByOragnIdResponseData {
  getDashboardsByOrganId: IGqlResponseData<DashboardListType[]>;
}

const lazyGetDashboardsByOragnId = () => useLazyQuery<IGetDashboardsByOragnIdResponseData, IGetDashboardsByOragnIdVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetDashboardsByOragnId;