import { useLazyQuery } from '@apollo/client';
import query from './query';
import {AwsDescribeKeyResponse} from "graphql/types/AwsKey";
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsDescribeKeyVariables {
  cloudId: number;
  region: string;
  request: {
    keyId?: string;
    grantTokens?: string[];
  }
}

export interface IAwsDescribeKeyResponseData {
  getAwsDescribeKey: IGqlResponseNewData<AwsDescribeKeyResponse>;
}

const lazyGetAwsDescribeKey = () => useLazyQuery<IAwsDescribeKeyResponseData, IAwsDescribeKeyVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsDescribeKey;