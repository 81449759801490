import { useEffect, useMemo, useState } from 'react';
import EventSettingGrid from './components/EventSettingGrid';
import EventSettingDetail from './components/EventSettingDetail';
import { OrderDirection, RowType } from '@Types/v2/Table';
import lazyGetEventSettingPage from 'graphql/queries/getEventSettingPage';
import { enumFormatter } from 'utils/Formatter';
import { useParams } from 'react-router-dom';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import CreateEventSettingModal from 'components/v2/modals/CreateEventSettingModal';
import Icon from 'components/v2/atoms/Icon';
import EventSettingIcon from 'assets/svgs/v2/ico_redcircle_event_setting.svg';
import DeleteEventSettingModal from 'components/v2/modals/DeleteEventSettingModal';
import deleteEventSetting from 'graphql/mutations/deleteMyEventSettingByIds';
import getProjectInfoByOrgan from 'graphql/queries/getProjectInfoByOrgan';

const EventSetting = () => {
  const { organId } = useParams();

  const [modalIsOpen, setModalIsOpen] = useState({
    create: false,
    delete: false
  }); 
  
  const [isShowing, setIsShowing] = useState<'DATA_GRID' | 'DETAIL'>('DATA_GRID');
  /* 프로젝트 리스트 */
  const [pjList, setPjList] = useState<{name: string, value: number}[]>([]);
  /* 중요도 리스트 */
  const [priorityTypeList, setPriorityTypeList] = useState<{name: string; value: string}[]>([]);
  /* 메시지수신타입 리스트 */
  const [msgReceiveTypeList, setMsgReceiveTypeList] = useState<{name: string; value: string; }[]>([]);

  const [search, setSearch] = useState({
    priorityCode: 'all',
    searchText: ''
  });
  const [total, setTotal] = useState({
    totalPage: 0,
    totalElement: 0
  });
  const [rows, setRows] = useState<RowType[]>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');

  /* 메세지 수신타입 리스트 조회 */
  const [getMsgSenderTypeList] = lazyGetEnumTypeCode();
  /* 중요도 리스트 조회 */
  const [getPriorityTypeList] = lazyGetEnumTypeCode();
  /* 이벤트 설정 페이지 조회 */
  const [getEventSetting, { loading: loadingGetEventSetting }] = lazyGetEventSettingPage();
  /* 이벤트 설정 삭제 */
  const [deleteEventSet] = deleteEventSetting();
  /* 조직id에 따른 pjlist 가져오기 */
  const [getProjectListByOrganId] = getProjectInfoByOrgan();

  const getEventSettingData = useMemo(() => ({
    reqGetEventSettingPage: {
      organId: Number(organId),
      priority: search.priorityCode === 'all' ? undefined : search.priorityCode, /* 중요도 전체는 undefined */
      text: search.searchText === '' ? undefined : search.searchText, /* 검색어 없으면 undefined */
      pageInfo: {
        page: tablePagination.currentPage - 1,
        size: tablePagination.limit,
        orders: [{
          target: tablePagination.target === 'projectName' ? 'projectId' : tablePagination.target,
          direction: tablePagination.direction 
        }]
      }
    }
  }), [
    tablePagination,
    isShowing,
    search,

    organId, 
  ]);

  const renderShowing = useMemo(() => {
    switch (isShowing) {
      default:
        return <EventSettingGrid
          onSubmitSearch={(readyToSearch: {
            priorityCode: string;
            searchText: string;
          }) => { 
            setSearch({ ...readyToSearch })
            setTablePagination(prev => ({
              ...prev,
              currentPage: 1
            }));
          }}
          tablePagination={tablePagination}
          updateTablePagination={(key:string, value: number | string | OrderDirection) => setTablePagination(prev => ({
            ...prev,
            [key]: value
          }))}
          total={total}
          rows={rows}
          checkedList={checkedList}
          reportCheckedList={(list:string[]) => setCheckedList(list)}
          reportSelected={(id:string) => {
            setSelected(id);
            setIsShowing('DETAIL');
          }}
          openCreateModal={() => setModalIsOpen(prev => ({
            ...prev,
            create: true
          }))}
          openDeleteModal={() => setModalIsOpen(prev => ({
            ...prev,
            delete: true
          }))}
          isLoading={loadingGetEventSetting}
        />
      case 'DETAIL': 
        return <EventSettingDetail
          pjList={pjList}
          refetchPjList={() => getPjList()}
          priorityTypeList={priorityTypeList}
          msgReceiveTypeList={msgReceiveTypeList}
          data={rows.find(val => val.settingId === Number(selected)) as RowType} 
          convertGridIsShowing={() => {
            setSelected('');
            setIsShowing('DATA_GRID');
          }}
          openDeleteModal={() =>  {
            setCheckedList([selected]);
            setModalIsOpen(prev => ({
              ...prev,
              delete: true
            }))

         }}    
        onEditSuccess={() => { 
          getEventSettingRows();
        }}
        />
    }
  }, [ 
    tablePagination,
    isShowing, 
    rows, 
    total,
    checkedList, 
    selected,
    
    pjList, 
    loadingGetEventSetting,
  ])

  const getEventSettingRows = () => {
    getEventSetting({variables: getEventSettingData}).then(({data}) => {
      if (data) {
        if (data.getEventSettingPageByParam) {
          setRows(data.getEventSettingPageByParam.content.map(val => (
            {
              ...val,
              id: val.settingId
            }
          )));
          setTotal({
            totalPage: data.getEventSettingPageByParam.totalPages,
            totalElement: data.getEventSettingPageByParam.totalElements
          })
        }
      }
    })
  }

  const getPjList = () => {
    /* 프로젝트 리스트 받기 */
    getProjectListByOrganId({ variables: { organId: Number(organId) }}).then(({ data }) => {
      if (data) {
        const result = data.getProjectInfoByOrganId.data.map(val => ({
          name: val.name,
          value:val.projectId
        }))
        setPjList(result); 
      }
    })
  }

  /* 이벤트설정 rows 받기 */
  useEffect(() => {
    getEventSettingRows();
  }, [getEventSettingData])

  useEffect(() => {
    /* 수신타입 받기 */
    getMsgSenderTypeList({variables: {text: 'MsgSenderTypeCode'}}).then(({data}) => {
      if (data) {
        if (data.getEnumTypeCode.data) {
          setMsgReceiveTypeList(data.getEnumTypeCode.data.map(val => ({
            name: String(enumFormatter(val.value)),
            value: val.value
          })));
        }
      }
    })
    /* 중요도 리스트 받기 */
    getPriorityTypeList({ variables: { text: 'PriorityCode'}}).then(({ data }) => {
      if (data) {
        if (data.getEnumTypeCode.data) {
          setPriorityTypeList(data.getEnumTypeCode.data.map(val => ({
            name: String(enumFormatter(val.value)),
            value: val.value
          })))
        }
      }
    })
    /* 프로젝트 리스트 받기 */
    getPjList();
  }, []);

  return (
    <div className='event-setting'>
      {renderShowing}
      <CreateEventSettingModal
        pjList={pjList}
        refetchPjList={() => getPjList()}
        priorityTypeList={priorityTypeList}
        msgReceiveTypeList={msgReceiveTypeList} 
        open={modalIsOpen.create}
        title={() => <>
          <Icon width={32} height={32} src={EventSettingIcon} />
          Create event-setting
        </>}
        onCreateSuccess={() => {
          setModalIsOpen(prev => ({
            ...prev,
            create: false
          }));
          getEventSettingRows();
        }}

        onClose={() => {
          setModalIsOpen(prev => ({
            ...prev,
            create: false
          }));
        } }     
      />

      <DeleteEventSettingModal 
        open={modalIsOpen.delete} 
        data={rows.filter(val => checkedList.includes(String(val.id)))} 
        title={() => 
          <>
            <Icon width={32} height={32} src={EventSettingIcon}  />
            Delete event-settings
          </>
        } 
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          delete: false
        }))}
        onDelete={() => {
          deleteEventSet({variables: { eventSettingIds: checkedList.map(val => Number(val))} }).then(({data}) => {
            if (data) {
              if(data.deleteEventSettingByIds.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, 'Event-setting is deleted successfully.');
                setModalIsOpen(prev => ({
                  ...prev,
                  delete: false
                }))
                
                if (isShowing === 'DETAIL') {
                  setIsShowing('DATA_GRID');
                }

                if ((rows.length === checkedList.length) && total.totalPage !== 1) {
                  setTablePagination(prev => ({
                    ...prev,
                    currentPage: tablePagination.currentPage - 1
                  }));
                } else {
                  getEventSettingRows();
                }

                setCheckedList([]);
              } else {
                console.log(data.deleteEventSettingByIds.result);
                useToast(ErrorCode.UNKNOWN, 'Delete event-setting failed.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, 'Delete event-setting failed.');
            }
          })
        }} 
      />
    </div>
  );
}
export default EventSetting;