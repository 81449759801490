import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { UserGroupDetailPropsType } from '../../types';
import { handleFormatText } from 'utils/Common';
import MgdTooltip from 'components/v2/MgdTooltip';
import TableJsonView from 'layouts/v3/MgdLayout/components/TableJsonView';
import lazyGetAwsListAttachedGroupPolicies, {
  IGetAwsListAttachedGroupPoliciesVariables,
} from 'graphql/queries/getAwsListAttachedGroupPolicies';
import lazyGetAwsPolicy, { IAwsPolicyVariables } from 'graphql/queries/getAwsPolicy';
import lazyGetAwsPolicyVersion, { IAwsPolicyVersionVariables } from 'graphql/queries/getAwsPolicyVersion';
import lazyGetAwsListGroupPolicies, {
  IGetAwsListGroupPoliciesVariables,
} from 'graphql/queries/getAwsListGroupPolicies';
import lazyGetAwsGroupPolicy, { IAwsGroupPolicyVariables } from 'graphql/queries/getAwsGroupPolicy';
import lazyGetAwsListEntitiesForPolicy, {
  IAwsListEntitiesForPolicyVariables,
} from 'graphql/queries/getAwsListEntitiesForPolicy';

import './style.scss';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';

const Permissions = (props: UserGroupDetailPropsType) => {
  const { userGroup, cloudId, region } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<any>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [getAwsListAttachedGroupPolicies] = lazyGetAwsListAttachedGroupPolicies();
  const [getAwsPolicy] = lazyGetAwsPolicy();
  const [getAwsPolicyVersion] = lazyGetAwsPolicyVersion();
  const [getAwsListGroupPolicies] = lazyGetAwsListGroupPolicies();
  const [getAwsGroupPolicy] = lazyGetAwsGroupPolicy();
  const [getAwsListEntitiesForPolicy] = lazyGetAwsListEntitiesForPolicy();

  const handleRenderAttachedEntities = (data: any) => {
    if (!data?.length) return <div>-</div>;

    return (
      <div id={`my-tooltip-${data[0].groupId}`}>
        <MgdTooltip id={data[0].groupId} title={`${data.length}`}>
          <div className="table-tooltip-container">
            <p>Attached entities Groups</p>

            {data.map((item: any, index: number) => {
              return (
                <div className="link" key={index}>
                  {handleFormatText(item.groupName)}
                </div>
              );
            })}
          </div>
        </MgdTooltip>
      </div>
    );
  };

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Policy name',
        field: 'policyName',
        sort: true,
      },
      {
        label: 'Type',
        field: 'type',
        sort: true,
      },
      {
        label: 'Attached entities',
        field: 'attachedEntities',
        sort: true,
        renderCell: row => handleRenderAttachedEntities(row.attachedEntities),
      },
    ];
  }, []);

  const getBetweenTwoDate = (value: any) => {
    if (!value) return 'Not accessed in the tracking period';

    const currentDate = new Date();
    const prevTime = new Date(value);

    // @ts-ignore
    const diffMs = currentDate - prevTime;
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      return 'Today';
    } else if (diffDays === 1) {
      return 'Yesterday';
    } else {
      return `${diffDays} days ago`;
    }
  };

  const handleGetAwsListAttachedGroupPolicies = (groupName: string) => {
    const requestVariable: IGetAwsListAttachedGroupPoliciesVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        groupName,
      },
    };
    return getAwsListAttachedGroupPolicies({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsPolicy = (policyArn: string) => {
    const requestVariable: IAwsPolicyVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        policyArn,
      },
    };
    return getAwsPolicy({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsPolicyVersion = (policyArn: string, versionId: string) => {
    const requestVariable: IAwsPolicyVersionVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        policyArn,
        versionId,
      },
    };
    return getAwsPolicyVersion({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsListGroupPolicies = (groupName: string) => {
    const requestVariable: IGetAwsListGroupPoliciesVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        groupName,
      },
    };
    return getAwsListGroupPolicies({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsGroupPolicy = (groupName: string, policyName: string) => {
    const requestVariable: IAwsGroupPolicyVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        groupName,
        policyName,
      },
    };
    return getAwsGroupPolicy({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsListEntitiesForPolicy = (policyArn: string) => {
    const requestVariable: IAwsListEntitiesForPolicyVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        policyArn,
      },
    };
    return getAwsListEntitiesForPolicy({ variables: variableCombineNextToken(requestVariable) });
  };

  const fetchData = useCallback(async () => {
    try {
      if (!userGroup?.arn) return;

      setIsLoading(true);
      const [listAttachedGroupPolicies, listGroupPolicies]: any = await Promise.all([
        handleGetAwsListAttachedGroupPolicies(userGroup.groupName),
        handleGetAwsListGroupPolicies(userGroup.groupName),
      ]);

      if (!listAttachedGroupPolicies?.data?.getAwsListAttachedGroupPolicies?.data?.[0]?.attachedPolicies?.length) {
        setIsLoading(false);
        return;
      }

      const totalResult: RowType[] = [];

      for (
        let i = 0;
        i < listAttachedGroupPolicies.data.getAwsListAttachedGroupPolicies.data[0].attachedPolicies.length;
        i++
      ) {
        const policyItem = listAttachedGroupPolicies.data.getAwsListAttachedGroupPolicies.data[0].attachedPolicies[i];
        const [policy, groupPolicy] = await Promise.all([
          handleGetAwsPolicy(policyItem.policyArn),
          handleGetAwsGroupPolicy(
            userGroup.groupName,
            listGroupPolicies.data?.getAwsListGroupPolicies?.data?.[i]?.policyNames?.[0]?.policyName || '',
          ),
        ]);

        const [policyVersion, listEntitiesForPolicy] = await Promise.all([
          handleGetAwsPolicyVersion(
            policyItem.policyArn,
            policy.data?.getAwsPolicy?.data?.[0]?.policy?.defaultVersionId || '',
          ),
          handleGetAwsListEntitiesForPolicy(policy.data?.getAwsPolicy?.data?.[0]?.policy?.arn || ''),
        ]);

        const arrType = policyItem.policyArn?.split(':');

        const isAwsType = arrType?.[4] === 'aws';

        const policyName = isAwsType
          ? policyItem.policyName
          : listGroupPolicies?.data?.getAwsListGroupPolicies?.data?.[0]?.policyNames?.[0]?.policyName;

        const awsManagedDocument = decodeURIComponent(
          policyVersion?.data?.getAwsPolicyVersion?.data?.[0]?.policyVersion?.document || '',
        );

        const item = {
          id: (Math.random() + 1).toString(36).substring(2, 8),
          policyName,
          type: isAwsType ? 'AWS managed' : 'Customer managed',
          attachedEntities: listEntitiesForPolicy?.data?.getAwsListEntitiesForPolicy?.data?.[0]?.policyGroups || [],
          jsonContent: {
            title: policyName,
            description: '',
            value: policyVersion?.data?.getAwsPolicyVersion?.data?.[0]?.policyVersion?.document,
            jsonString: isAwsType
              ? awsManagedDocument
              : groupPolicy?.data?.getAwsGroupPolicy?.data?.[0]?.policyDocument,
          },
        };

        totalResult.push(item);
      }

      setMainTblRows(totalResult);
      setMainTblTotal({
        totalPage: Math.ceil(totalResult.length / tablePagination.itemPerPage),
        totalElement: totalResult.length,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [tablePagination, userGroup, cloudId, region, mainTblRows]);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    const arr: any = orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );

    return arr;
  }, [userGroup, mainTblTotal, tablePagination]);

  useEffect(() => {
    fetchData();
  }, [userGroup]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>Permissions policies</p>

        <p>You can attach up to 10 managed policies.</p>
      </div>
      {isLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <div className="detail-info-content">
          {!mainRowsCurrentPage.length && !isLoading ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <TableJsonView
                rows={mainRowsCurrentPage}
                columns={columns}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
                reportCheckedList={() => {}}
                reportSelected={() => {}}
              />

              {mainRowsCurrentPage?.length && !isLoading ? (
                <div className="fleet-instance pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{mainTblTotal.totalElement}</span>
                  </p>

                  <TableManagePagination
                    ableFetchMore={false}
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page => updateTablePagination('currentPage', page)}
                    totalPage={mainTblTotal.totalPage}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Permissions;
