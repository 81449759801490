import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IRevokeAwsSecurityGroupEgressVariables {
  cloudId: number;
  region: string;
  request: {
    groupId: string;
    securityGroupRuleIds: string[];
  };
}

export interface IRevokeAwsSecurityGroupEgressResponseData {
  revokeAwsSecurityGroupEgress: IGqlResponseData<undefined>;
}

const revokeAwsSecurityGroupEgressMutation = () =>
  useMutation<IRevokeAwsSecurityGroupEgressResponseData, IRevokeAwsSecurityGroupEgressVariables>(query);
export default revokeAwsSecurityGroupEgressMutation;
