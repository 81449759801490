import { useOutletContext, useParams } from 'react-router-dom';
import Occupied from './components/Occupied';
import { useMemo } from 'react';
import { ProjectInfo } from 'graphql/types/ProjectInfo';

import { useAuth } from 'contexts/AuthProvider';
import { Favorites } from '@Types/v2/Lnb';
import EmptyPage from './components/Empty';
import NotFoundFavorite from '../Favorite/components/NotFoundFavorite';
import NotFound from './components/NotFound';
import './index.scss';

/** 
  - 로그인 후 첫 페이지는 즐겨찾기 첫번쨰 프로젝트로 이동, 즐겨찾기 없을 경우 프로젝트의 첫번째 프로젝트로 이동, 둘다 없으면 empty 페이지 
  - 필요없으면 Navigate 로 변경 

 <>프로젝트 - id 없을때는 empty 페이지 띄우기 / </>
*/
const Project = () => {
  const { userInfo } = useAuth();
  const { organId, id } = useParams();
  const { projectList } = useOutletContext<{ projectList: ProjectInfo[];}>();

  const renderComponent = useMemo(() => {
    const getFavorites = localStorage.getItem('favorites');

    if (projectList.length === 0) {
      if (!getFavorites) return <EmptyPage />;  

      const favorites:Favorites = JSON.parse(getFavorites);
      const finMyOrgan = favorites.find(val => val.organId === Number(organId));
      
      if (finMyOrgan === undefined) return <EmptyPage />; 
   
      const findSub = finMyOrgan.sub.find(val => val.memberId === userInfo?.nemo.memberId)
      if (findSub === undefined) return <EmptyPage />;  

      const findList = findSub.list.find(val => val.id === Number(id));
      
      if (findList === undefined) return <NotFound />;  
      else { return <NotFoundFavorite paramId={id} />;} 
    } 
    else {
      const find = projectList.find(val => val.projectId === Number(id));
  
      if (find !== undefined) return <Occupied project={projectList.find(val => val.projectId === Number(id)) as ProjectInfo} />;
    
      if (!getFavorites) return <NotFound />; 
   
      const favorites:Favorites = JSON.parse(getFavorites);
      const finMyOrgan = favorites.find(val => val.organId === Number(organId));

      if (finMyOrgan === undefined) return <NotFound />;
       
      const findSub = finMyOrgan.sub.find(val => val.memberId === userInfo?.nemo.memberId)
      if (findSub === undefined) return <EmptyPage />; 
  
      const findList = findSub.list.find(val => val.id === Number(id));
      
      if (findList === undefined) return <NotFound />;
      else {return <NotFoundFavorite paramId={id} />}
    }
  }, [id, projectList, userInfo, organId ]);

  return (
    <div id="project-page" className="flex">
      {renderComponent}
    </div>
  );
};

export default Project;
