import { gql } from '@apollo/client';

const query = gql`
query getAwsDisassociateWebACL($request: AwsDisassociateWebAclRequest, $cloudId: Long, $region: String) {
  getAwsDisassociateWebACL(disassociateWebAclRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
    __typename
      }
    }
  }
`;
export default query;