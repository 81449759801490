import { EnumCurrentStatus, EnumReportType } from "@Types/Apis/Report";
import { OrganMemberType } from "@Types/v4/Member";

export const organInfoDummy = {
  organId: 1,
  name: '조직1',
  description: 'test test1',
  createdAt: '2023-12-23 10:12:23',
  createdBy: 'curidy111',
  createdByName: '네모네모11',
  modifiedAt: '2023-12-29 15:20:14',
  modifiedBy: 'curidy222',
  modifiedByName: '네모네모22',
  projectCnt: 1
};

export const projectInfoDummy = {
  projectId: 0,
  organId: 1,
  name: '히말라야 프로젝트',
  thirdPartTypeCode: '팔로 알토',
  attrKey1: '데이터속성',
  attrValue1: '가나다라',
  attrKey2: 'attrKey2',
  attrValue2: 'attrValue2',
  attrKey3: 'attrKey3',
  attrValue3: 'attrValue3',
  useYn: false,
  createdAt: '2023-12-23 10:12:23',
  createdBy: 'cd23531-543v-n3ag42-5h3d',
  createdByName: '홍길동',
  modifiedAt: '2023-12-23 10:12:23',
  modifiedBy: 'cd23531-543v-n3ag42-5h3d',
  modifiedByName: '한고은'
};

export const projectInfoDummy2 = {
  projectId: 1,
  organId: 1,
  name: '요세미티 프로젝트',
  thirdPartTypeCode: '팔로 알토',
  attrKey1: '데이터속성',
  attrValue1: '가나다라',
  attrKey2: 'attrKey2',
  attrValue2: 'attrValue2',
  attrKey3: 'attrKey3',
  attrValue3: 'attrValue3',
  useYn: false,
  createdAt: '2023-12-23 10:12:23',
  createdBy: 'lee_soon_shin',
  createdByName: '이순신',
  modifiedAt: '2023-12-23 10:12:23',
  modifiedBy: 'kim_cheol_soo',
  modifiedByName: '김철수'
};

export const projectInfoDummy3 = {
  projectId: 2,
  organId: 1,
  name: '빅서 프로젝트',
  thirdPartTypeCode: '팔로 알토',
  attrKey1: '데이터속성',
  attrValue1: '가나다라마바사아',
  attrKey2: 'attrKey2',
  attrValue2: 'attrValue2',
  attrKey3: 'attrKey3',
  attrValue3: 'attrValue3',
  useYn: false,
  createdAt: '2023-12-23 10:12:23',
  createdBy: 'YIN_ge1234',
  createdByName: '유인나',
  modifiedAt: '2023-12-23 10:12:23',
  modifiedBy: 'kang_hd',
  modifiedByName: '강호동'
};

export const pageDummy = {
  /* totalPages 를 안보거나 totalElements를 안보거나 */
  __typename: '', /* 안 봄 */
  first: false, /* first page */
  last: false, /* last page */
  number: -1, /* page number */
  size: -1, /* size of one page */
  totalPages: 1,
  totalElements: -1,
  numberOfElements: -1, /* 안 봄 */
  content: [
    {
      ...projectInfoDummy
    },
    {
      ...projectInfoDummy2
    },
    {
      ...projectInfoDummy3
    }
  ]
};

export const projectMemberPageDummies = [
  {
    id: '11',
    organId: 0,
    organName: '조직이름AA',
    userId: 'curidy',
    fullName: '큐리디',
    roleCode: 'ADMIN',
    alertYn: true,
    createdAt: '2024-01-05T12:40:15.786Z',
    modifiedAt: '2024-01-05T12:40:15.786Z',
    projectId: 0
  },
  {
    id: '22',
    organId: 0,
    organName: '조직이름AA',
    userId: 'nemo1111',
    fullName: '네모네모스폰지밥',
    roleCode: 'OWNER',
    alertYn: false,
    createdAt: '2024-01-05T12:40:15.786Z',
    modifiedAt: '2024-01-05T12:40:15.786Z',
    projectId: 0
  },
  {
    id: '33',
    organId: 0,
    organName: '조직이름AA',
    userId: 'ddoongy',
    fullName: '뚱이',
    roleCode: 'MEMBER',
    alertYn: false,
    createdAt: '2024-01-05T12:40:15.786Z',
    modifiedAt: '2024-01-05T12:40:15.786Z',
    projectId: 0
  },
  {
    id: '44',
    organId: 0,
    organName: '조직이름AA',
    userId: 'jing-jing-2',
    fullName: '징징이',
    roleCode: 'MEMBER',
    alertYn: false,
    createdAt: '2024-01-05T12:40:15.786Z',
    modifiedAt: '2024-01-05T12:40:15.786Z',
    projectId: 0
  },
  {
    id: '55',
    organId: 0,
    organName: '조직이름AA',
    userId: 'king_krap',
    fullName: '집게사장',
    roleCode: 'MEMBER',
    alertYn: true,
    createdAt: '2024-01-05T12:40:15.786Z',
    modifiedAt: '2024-01-05T12:40:15.786Z',
    projectId: 0
  },
  {
    id: '66',
    organId: 0,
    organName: '조직이름AA',
    userId: 'hamster_daram',
    fullName: '다람이',
    roleCode: 'MEMBER',
    alertYn: true,
    createdAt: '2024-01-05T12:40:15.786Z',
    modifiedAt: '2024-01-05T12:40:15.786Z',
    projectId: 0
  },
  {
    id: '1442566',
    organId: 0,
    organName: '조직이름AA',
    userId: 'pingping2',
    fullName: '핑핑이',
    roleCode: 'MEMBER',
    alertYn: true,
    createdAt: '2024-01-05T12:40:15.786Z',
    modifiedAt: '2024-01-05T12:40:15.786Z',
    projectId: 0
  },
  {
    id: '14425',
    organId: 0,
    organName: '조직이름AA',
    userId: 'king_krap',
    fullName: '집게사장',
    roleCode: 'MEMBER',
    alertYn: true,
    createdAt: '2024-01-05T12:40:15.786Z',
    modifiedAt: '2024-01-05T12:40:15.786Z',
    projectId: 1
  },
  {
    id: '144233',
    organId: 0,
    organName: '조직이름AA',
    userId: 'kkankkan_kingo',
    fullName: '깐깐징어',
    roleCode: 'MEMBER',
    alertYn: true,
    createdAt: '2024-01-05T12:40:15.786Z',
    modifiedAt: '2024-01-05T12:40:15.786Z',
    projectId: 1
  },
  {
    id: '1442566',
    organId: 0,
    organName: '조직이름AA',
    userId: 'pingping2',
    fullName: '핑핑이',
    roleCode: 'MEMBER',
    alertYn: true,
    createdAt: '2024-01-05T12:40:15.786Z',
    modifiedAt: '2024-01-05T12:40:15.786Z',
    projectId: 1
  }
];

export const weeklyReportDetailDummies = [
  {
    reportId: 0, 
    projectId: 0,
    projectName: '히말라야 프로젝트',
    name: '히말라야 프로젝트 주간111',
    reportType: EnumReportType.weekly00,
    startTime: '2023-04-01',
    endTime: '2023-04-08',
    currentVersion: 'v0.01',
    currentStatus: EnumCurrentStatus.waiting, /* 'initial0' | 'analysis' | 'publish0' | null; */
    createdBy: 'hong_gil_dong',
    createdByName: '홍길동',
    createdAt: '2023-05-01 08:39:28',
    modifiedBy: 'daehanmin',
    modifiedByName: '대한민',
    modifiedAt: ' 2023-05-01 08:39:28'
    // memoId: 
  },
  {
    reportId: 1, 
    projectId: 0,
    projectName: '히말라야 프로젝트',
    name: '히말라야 프로젝트 주간222',
    reportType: EnumReportType.weekly00,
    startTime: '2023-04-01',
    endTime: '2023-04-08',
    currentVersion: 'v0.02',
    currentStatus: EnumCurrentStatus.writing,
    createdBy: 'hong_gil_dong',
    createdByName: '홍길동',
    createdAt: '2023-05-01 08:39:28',
    modifiedBy: 'nari.gae',
    modifiedByName: '개나리',
    modifiedAt: ' 2023-05-01 08:39:28'
  // memoId: 
  },
  {
    reportId: 2, 
    projectId: 0,
    projectName: '히말라야 프로젝트',
    name: '히말라야 프로젝트 주간1',
    reportType: EnumReportType.weekly00,
    startTime: '2023-04-01',
    endTime: '2023-04-08',
    currentVersion: 'v0.01',
    currentStatus: EnumCurrentStatus.review,
    createdBy: 'hong_gil_dong',
    createdByName: '홍길동',
    createdAt: '2023-05-01 08:39:28',
    modifiedBy: 'nari.gae',
    modifiedByName: '개나리',
    modifiedAt: ' 2023-05-01 08:39:28'
  // memoId: 
  },
  {
    reportId: 3, 
    projectId: 0,
    projectName: '히말라야 프로젝트',
    name: '히말라야 프로젝트 주간2',
    reportType: EnumReportType.weekly00,
    startTime: '2023-04-09',
    endTime: '2023-04-16',
    currentVersion: 'v0.01',
    currentStatus: EnumCurrentStatus.creationFailed,
    createdBy: 'hong_gil_dong',
    createdByName: '홍길동',
    createdAt: '2023-05-10 08:39:28',
    modifiedBy: 'nari.gae',
    modifiedByName: '개나리',
    modifiedAt: ' 2023-05-10 08:39:28'
  // memoId: 
  },
  {
    reportId: 4, 
    projectId: 0,
    projectName: '히말라야 프로젝트',
    name: '히말라야 프로젝트 주간3',
    reportType: EnumReportType.weekly00,
    startTime: '2023-04-17',
    endTime: '2023-04-24',
    currentVersion: 'v0.01',
    currentStatus: EnumCurrentStatus.analyzing,
    createdBy: 'hong_gil_dong',
    createdByName: '홍길동',
    createdAt: '2023-06-01 08:39:28',
    modifiedBy: 'nari.gae',
    modifiedByName: '개나리',
    modifiedAt: ' 2023-08-01 08:39:28'
  // memoId: 
  },
  {
    reportId: 5, 
    projectId: 1,
    projectName: '요세미티 프로젝트',
    name: '요세미티 프로젝트 주간1',
    reportType: EnumReportType.weekly00,
    startTime: '2023-04-17',
    endTime: '2023-04-24',
    currentVersion: 'v0.02',
    currentStatus: EnumCurrentStatus.analysisFailed,
    createdBy: 'hong_gil_dong',
    createdByName: '홍길동',
    createdAt: '2023-06-01 08:39:28',
    modifiedBy: 'nari.gae',
    modifiedByName: '개나리',
    modifiedAt: ' 2023-06-01 08:39:28'
  // memoId: 
  },
  {
    reportId: 6, 
    projectId: 1,
    projectName: '요세미티 프로젝트',
    name: '요세미티 프로젝트 주간1',
    reportType: EnumReportType.weekly00,
    startTime: '2023-04-17',
    endTime: '2023-04-24',
    currentVersion: 'v0.01',
    currentStatus: EnumCurrentStatus.issueCompleted,
    createdBy: 'hong_gil_dong',
    createdByName: '홍길동',
    createdAt: '2023-12-01 08:39:28',
    modifiedBy: 'nari.gae',
    modifiedByName: '개나리',
    modifiedAt: ' 2023-06-01 08:39:28'
  // memoId: 
  },
  {
    reportId: 7, 
    projectId: 0,
    projectName: '히말라야 프로젝트',
    name: '히말라야 프로젝트',
    reportType: EnumReportType.weekly00,
    startTime: '2023-04-01',
    endTime: '2023-04-08',
    currentVersion: 'v0.01',
    currentStatus: EnumCurrentStatus.waiting, /* 'initial0' | 'analysis' | 'publish0' | null; */
    createdBy: 'hong_gil_dong',
    createdByName: '홍길동',
    createdAt: '2023-05-01 08:39:28',
    modifiedBy: 'nari.gae',
    modifiedByName: '개나리',
    modifiedAt: ' 2023-05-01 08:39:28'
    // memoId: 
  },
  {
    reportId: 8, 
    projectId: 0,
    projectName: '히말라야 프로젝트',
    name: '히말라야 프로젝트',
    reportType: EnumReportType.weekly00,
    startTime: '2023-04-01',
    endTime: '2023-04-08',
    currentVersion: 'v0.01',
    currentStatus: EnumCurrentStatus.writing,
    createdBy: 'hong_gil_dong',
    createdByName: '홍길동',
    createdAt: '2023-05-01 08:39:28',
    modifiedBy: 'nari.gae',
    modifiedByName: '개나리',
    modifiedAt: ' 2023-05-01 08:39:28'
  // memoId: 
  },
  {
    reportId: 9, 
    projectId: 0,
    projectName: '히말라야 프로젝트',
    name: '히말라야 프로젝트 주간1',
    reportType: EnumReportType.weekly00,
    startTime: '2023-04-01',
    endTime: '2023-04-08',
    currentVersion: 'v0.03',
    currentStatus: EnumCurrentStatus.review,
    createdBy: 'hong_gil_dong',
    createdByName: '홍길동',
    createdAt: '2023-05-01 08:39:28',
    modifiedBy: 'nari.gae',
    modifiedByName: '개나리',
    modifiedAt: ' 2024-01-01 08:39:28'
  // memoId: 
  },
  {
    reportId: 10, 
    projectId: 0,
    projectName: '히말라야 프로젝트',
    name: '히말라야 프로젝트 주간2',
    reportType: EnumReportType.weekly00,
    startTime: '2023-04-09',
    endTime: '2023-04-16',
    currentVersion: 'v0.01',
    currentStatus: EnumCurrentStatus.creationFailed,
    createdBy: 'hong_gil_dong',
    createdByName: '홍길동',
    createdAt: '2023-05-10 08:39:28',
    modifiedBy: 'nari.gae',
    modifiedByName: '개나리',
    modifiedAt: ' 2023-08-10 08:39:28'
  // memoId: 
  },
  {
    reportId: 11, 
    projectId: 0,
    projectName: '히말라야 프로젝트',
    name: '히말라야 프로젝트 주간3',
    reportType: EnumReportType.weekly00,
    startTime: '2023-04-17',
    endTime: '2023-04-24',
    currentVersion: 'v0.01',
    currentStatus: EnumCurrentStatus.analyzing,
    createdBy: 'hong_gil_dong',
    createdByName: '홍길동',
    createdAt: '2023-06-01 08:39:28',
    modifiedBy: 'nari.gae',
    modifiedByName: '개나리',
    modifiedAt: ' 2023-06-01 08:39:28'
  // memoId: 
  },
  {
    reportId: 12, 
    projectId: 1,
    projectName: '요세미티 프로젝트',
    name: '요세미티 프로젝트 주간3',
    reportType: EnumReportType.weekly00,
    startTime: '2023-04-17',
    endTime: '2023-04-24',
    currentVersion: 'v0.01',
    currentStatus: EnumCurrentStatus.analysisFailed,
    createdBy: 'hong_gil_dong',
    createdByName: '홍길동',
    createdAt: '2023-06-01 08:39:28',
    modifiedBy: 'dandelion',
    modifiedByName: '민들래',
    modifiedAt: ' 2023-06-01 08:39:28'
  // memoId: 
  },
  {
    reportId: 13, 
    projectId: 1,
    projectName: '요세미티 프로젝트',
    name: '요세미티 프로젝트 주간4',
    reportType: EnumReportType.weekly00,
    startTime: '2023-04-17',
    endTime: '2023-04-24',
    currentVersion: 'v0.04',
    currentStatus: EnumCurrentStatus.issueCompleted,
    createdBy: 'hong_gil_dong',
    createdByName: '홍길동',
    createdAt: '2023-06-01 08:39:28',
    modifiedBy: 'cherry_blossom',
    modifiedByName: '벚꽃',
    modifiedAt: ' 2023-06-01 08:39:28'
  // memoId: 
  }
];

export const histReportFileInfosDummies = [
  {
    id: 0,
    reportId: 0,
    fileName: '프로젝트 222_23.01.01 _23.01.07_주간리포트_v0.01.xlsx',
    statusCode: EnumCurrentStatus.initial0,
    version: 'v1.0',
    comment: 'test1 코멘트 메모입니다.',
    modifiedByName: '아연테스트',
    modifiedAt: '2023-12-14T06:50:10.188Z'
  },
  {
    id: 1,
    reportId: 0,
    fileName: '프로젝트 aaa_23.01.01 _23.01.07_주간리포트_v0.01.xlsx',
    statusCode: EnumCurrentStatus.initial0,
    version: 'v2.0',
    comment: '',
    modifiedByName: '아연테스트',
    modifiedAt: '2023-11-14T06:50:10.188Z'
  },
  {
    id: 2,
    reportId: 0,
    fileName: '프로젝트_23.01.01 _23.01.07_주간리포트_v0.01.xlsx',
    statusCode: EnumCurrentStatus.initial0,
    version: 'v1.0',
    comment: 'test 코멘트 메모입니다.',
    modifiedByName: '아연테스트',
    modifiedAt: '2023-10-14T06:50:10.188Z'
  },
  {
    id: 3,
    reportId: 0,
    fileName: '프로젝트_23.01.01 _23.01.07_주간리포트_v0.01.xlsx',
    statusCode: EnumCurrentStatus.publish0,
    version: 'v1.11',
    comment: 'test3 코멘트 메모입니다.',
    modifiedByName: '아연테스트',
    modifiedAt: '2023-12-14T06:50:10.188Z'
  },
  {
    id: 4,
    reportId: 0,
    fileName: '프로젝트_23.01.01 _23.01.07_주간리포트_v0.01.xlsx',
    statusCode: EnumCurrentStatus.analysis,
    version: 'v1.5',
    comment: '',
    modifiedByName: '아연테스트',
    modifiedAt: '2023-12-14T06:50:10.188Z'
  }
];

export const organMemberList:OrganMemberType[] = [
  {
    id: '1',
    fullName: '123',
    userId: '1',
    projectCount: 3,
    createdAt: '2023-12-14T06:50:10.188Z',
    modifiedAt: '2023-12-14T06:50:10.188Z',
    sysAuthCode: 'pj_membr',
    ownerYn: false
  }
];

export const myProjectAlertList = [
  {
    name: 'test',
    sms: false,
    slack: true,
    kakaotalk: false
  },{
    name: 'test2',
    sms: true,
    slack: true,
    kakaotalk: false
  }
]

export const myMFADevice = [
  {
    id: 1,
    name: 'first device',
    identifier: 'arn:aws:iam::4412341',
    createdAt: '2023-12-14T06:50:10.188Z'
  },{
    id: 2,
    name: 'second device',
    identifier: 'arn:aws:iam::4412342',
    createdAt: '2023-12-14T06:50:10.188Z'
  }
]