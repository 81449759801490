import { gql } from '@apollo/client';

const query = gql`
  query getAwsListUserTags($request: AwsListUserTagsRequest, $cloudId: Long, $region: String) {
    getAwsListUserTags(listUserTagsRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListUserTagsResponse {
          tags {
            key
            value
          }
          isTruncated
          marker
        }
      }
    }
  }
`;
export default query;
