import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsListUserPoliciesResponse } from 'graphql/types/AwsListUserPoliciesResponse';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsListUserPoliciesVariables {
  cloudId: number;
  region: string;
  request: {
    userName?: string;
  }
}

export interface IAwsListUserPoliciesResponseData {
  getAwsListUserPolicies: IGqlResponseNewData<AwsListUserPoliciesResponse>;
}

const lazyGetAwsListUserPolicies = () => useLazyQuery<IAwsListUserPoliciesResponseData, IAwsListUserPoliciesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListUserPolicies