import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';

export interface IAwsDisassociateWebACLVariables {
  cloudId: number;
  region: string;
  request: {
    resourceArn: string;
  }
}

export interface IAwsDisassociateWebACLResponseData {
  getAwsDisassociateWebACL: IGqlResponseData;
}

const lazyDisassociateAwsWebACL = () => useLazyQuery<IAwsDisassociateWebACLResponseData, IAwsDisassociateWebACLVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyDisassociateAwsWebACL;