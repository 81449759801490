import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { AwsTagType } from 'graphql/types/AwsVpc';

export interface IDeleteTagsVariables {
  cloudId: number;
  region: string;
  request: {
    dryRun?: boolean;
    resources: string[];
    tags: AwsTagType[];
  }
}

export interface IDeleteTagsResponseData {
  deleteAwsTags: IGqlResponseData<undefined>;
}

const deleteAwsTagsMutation = () => useMutation<IDeleteTagsResponseData, IDeleteTagsVariables>(query);
export default deleteAwsTagsMutation;