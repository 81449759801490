import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsListAttachedUserPoliciesResponseEntry } from 'graphql/types/AwsListAttachedUserPoliciesResponse';

export interface IAwsListAttachedUserPoliciesVariables {
  cloudId: number;
  region: string;
  request: {
    userName?: string;
    maxItems?: number;
  }
}

export interface IAwsListAttachedUserPoliciesResponseData {
  getAwsListAttachedUserPolicies: AwsListAttachedUserPoliciesResponseEntry;
}

const lazyGetAwsListAttachedUserPolicies = () => useLazyQuery<IAwsListAttachedUserPoliciesResponseData, IAwsListAttachedUserPoliciesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListAttachedUserPolicies