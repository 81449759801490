import { ColumnType, OrderDirection, RowType } from "@Types/v2/Table";
import { IMgdTablePaginationProps, IMgdTotalPageProps } from "layouts/v3/MgdLayout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PermissionPropsType } from "../types";
import lazyGetAwsListAttachedRolePolicies, { IGetAttachedRolePoliciesVariables } from "graphql/queries/getAwsListAttachedRolePolicies";
import lazyGetAwsPolicy, { IAwsPolicyVariables } from "graphql/queries/getAwsPolicy";
import lazyGetAwsListRolePolicies, { IGetListRolePoliciesVariables } from "graphql/queries/getAwsListRolePolicies";
import lazyGetAwsRolePolicy, { IGetRoleVariables } from "graphql/queries/getAwsRolePolicy";
import lazyGetAwsPolicyVersion, { IAwsPolicyVersionVariables } from "graphql/queries/getAwsPolicyVersion";
import { orderAlphabetical } from "pages/v2/Organ/Management/Utils/Sorting";
import TableJsonView, { RowHasJsonType } from "layouts/v3/MgdLayout/components/TableJsonView";

const Permissions = (props: PermissionPropsType) => {
  const {cloudId, region, role} = props;

  // API
  const [getAwsListAttachedRolePolicies, {loading: listAttachedRolePoliciesLoading}] = lazyGetAwsListAttachedRolePolicies();
  const [getAwsPolicy, {loading: policyLoading}] = lazyGetAwsPolicy();
  const [getAwsPolicyVersion, {loading: policyVersionLoading}] = lazyGetAwsPolicyVersion();

  const [getAwsListRolePolicies, {loading: rolePolyciesLoading}] = lazyGetAwsListRolePolicies();
  const [getAwsRolePolicy, {loading: rolePolicyLoading}] = lazyGetAwsRolePolicy();

  // State
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const isApiLoading = useMemo((): boolean => {
    return listAttachedRolePoliciesLoading || policyLoading || policyVersionLoading || rolePolyciesLoading || rolePolicyLoading;
  }, [listAttachedRolePoliciesLoading, policyLoading, policyVersionLoading, rolePolyciesLoading, rolePolicyLoading]);

  const columns = useMemo((): ColumnType[] => {
    return [
      { label: 'Policy name', field: 'policyName', sort: true},
      { label: 'Type', field: 'type', sort: true},
      { label: 'Attached entities', field: 'attachedEntities', sort: true},
    ];
  }, []);

  const updateTablePagination = useCallback((key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const checkAwsManagedPolicy = useCallback((policyArn: string): boolean => {
    const prefixArn = policyArn?.split('/')?.[0]?.split('arn:aws:iam::')?.[0];
    return prefixArn?.includes('aws:policy');
  }, []);

  const fetchPermissionPolicies = useCallback(async () => {
    const permissionPolicyRow: RowType[] = [];

    // Customer inline
    const reqListRolePoliciesVar: IGetListRolePoliciesVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        roleName: role?.roleName ?? ''
      }
    };
    const {data: listRolePolicesResponse} = await getAwsListRolePolicies({variables: reqListRolePoliciesVar});
    const policyNames = listRolePolicesResponse?.getAwsListRolePolicies?.data?.[0]?.policyNames;
    if (policyNames && policyNames.length > 0) {
      await Promise.all(
        policyNames.map(async policyName => {
          const reqRolePolicyVar: IGetRoleVariables = {
            cloudId: cloudId,
            region: region,
            request: {
              roleName: role?.roleName ?? '',
              policyName: policyName
            }
          };

          const {data: rolePolicyResponse} = await getAwsRolePolicy({variables: reqRolePolicyVar})
          const rolePolicy = rolePolicyResponse?.getAwsRolePolicy?.data?.[0];
          if (rolePolicy) {
            permissionPolicyRow.push({
              id: rolePolicy.policyName,
              policyName: rolePolicy.policyName,
              type: 'Customer inline',
              attachedEntities: 0,
              jsonContent: {
                title: rolePolicy.policyName,
                value: rolePolicy.policyDocument,
                jsonString: decodeURIComponent(rolePolicy.policyDocument)
              }
            });
          }
        })
      );
    }


    // Customer or AWS managed
    const reqAttachRolePoliciesVar: IGetAttachedRolePoliciesVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        roleName: role?.roleName ?? ''
      }
    };
    const {data: attachRolePoliciesResponse} = await getAwsListAttachedRolePolicies({variables: reqAttachRolePoliciesVar});
    const attachedPolicies = attachRolePoliciesResponse?.getAwsListAttachedRolePolicies?.data?.[0]?.attachedPolicies;
    
    if (attachedPolicies && attachedPolicies.length > 0) {
      await Promise.all(
        attachedPolicies.map(async policy => {
          const reqAwsPolicyVar: IAwsPolicyVariables = {
            cloudId: cloudId,
            region: region,
            request: {
              policyArn: policy.policyArn
            }
          }
          const {data: awsPolicyResponse} = await getAwsPolicy({variables: reqAwsPolicyVar});
          const policyDetail = awsPolicyResponse?.getAwsPolicy?.data?.[0]?.policy;
          if (policyDetail) {
            const reqPolicyVersion: IAwsPolicyVersionVariables = {
              cloudId: cloudId,
              region: region,
              request: {
                policyArn: policyDetail.arn,
                versionId: policyDetail.defaultVersionId
              }
            };
            const {data: policyVersionResponse} = await getAwsPolicyVersion({variables: reqPolicyVersion});
            const policyVersion = policyVersionResponse?.getAwsPolicyVersion?.data?.[0]?.policyVersion;
            let jsonContent = {};
            if (policyVersion && policyVersion.document) {
              jsonContent = {
                title: policyDetail.policyName,
                value: policyVersion.document,
                jsonString: decodeURIComponent(policyVersion.document)
              }
            }
            const isAwsManagedPolicy = checkAwsManagedPolicy(policyDetail.arn);
            permissionPolicyRow.push({
              id: policyDetail.policyId,
              policyName: policyDetail.policyName,
              type: isAwsManagedPolicy ? 'AWS managed - job function' : 'Customer managed',
              attachedEntities: policyDetail.attachmentCount,
              jsonContent: jsonContent
            });
          }
        })
      );
    }

    setMainTblRows(permissionPolicyRow);
    setMainTblTotal({
      totalPage: Math.ceil(permissionPolicyRow.length / tablePagination.itemPerPage),
      totalElement: permissionPolicyRow.length,
    });
  }, [cloudId, region, role, tablePagination]);

  const mainRowsCurrentPage = useMemo((): RowHasJsonType[] => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    ) as RowHasJsonType[];
  }, [mainTblTotal, tablePagination, mainTblRows]);

  useEffect(() => {
    fetchPermissionPolicies();
  }, [role]);

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>Permissions policies</p>
      </div>
      {isApiLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <div className="detail-info-content">
          {!mainRowsCurrentPage.length && !isApiLoading ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <TableJsonView
                rows={mainRowsCurrentPage}
                columns={columns}
                reportCheckedList={() => {}}
                reportSelected={id => {}}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
                isLoading={isApiLoading}
              />

              {mainRowsCurrentPage?.length && !isApiLoading ? (
                <div className="fleet-instance pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{mainTblTotal.totalElement}</span>
                  </p>
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Permissions;