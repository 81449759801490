import React, { useEffect, useState } from 'react';
import TagTab from "pages/v2/Organ/Management/EC2/components/TagTab";
import lazyGetAwsListUserTags, { IAwsListUserTagsVariables } from 'graphql/queries/getAwsListUserTags';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';

function Tags({cloudId, region, data = {}}: any) {
  const {userName} = data

  const [isLoading, setIsLoading] = useState(false)
  const [tags, setTags] = useState<any>([])

  const [getAwsListUserTags] = lazyGetAwsListUserTags()
  
  const handleGetAwsListUserTags = (userName: string) => {
    const requestVariable: IAwsListUserTagsVariables = {
      cloudId,
      region,
      request: {
        userName,
      },
    };
    return getAwsListUserTags({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetDetail = async () => {
    try {
      setIsLoading(true);
      const {data: listUserTags} = await handleGetAwsListUserTags(userName);

      setTags(listUserTags?.getAwsListUserTags?.data[0]?.tags || [])

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!userName) return;

    handleGetDetail();
  }, [userName]);

  const columns = [
    {
      label: 'Key',
      field: 'key',
      sort: true,
    },
    {
      label: 'Value',
      field: 'value',
      sort: true,
    },
  ]

  return (
    <TagTab
      title='Tags'
      rows={tags || []}
      columns={columns}
      isLoading={isLoading}
    />
  );
}

export default Tags;