import { useLazyQuery } from '@apollo/client';
import query from './query';
import { OrganProjectListPerMemberType } from '@Types/v4/Member';
import { IGqlResponseData } from 'graphql/types';

export interface IGetProjectsByMemberVariables {
  reqData: string
}

export interface IGetProjectsByMemberResponseData {
  getProjectsByMember: IGqlResponseData<OrganProjectListPerMemberType[]>;
}

const lazyGetProjectsByMember = () => useLazyQuery<IGetProjectsByMemberResponseData, IGetProjectsByMemberVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetProjectsByMember;