import { useEffect, useMemo, useState } from 'react';
import { OrderDirection, RowType } from '@Types/v2/Table';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import Icon from 'components/v2/atoms/Icon';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { MessageInfo } from 'apis/v2/Manage/schema';
import { enumFormatter } from 'utils/Formatter';
import DeleteMessageModal from 'components/v2/modals/DeleteMessageModal';
import MessageDetail from './components/MessageDetail';
import MessageGrid from './components/MessageGrid';
import MessageCircle from 'assets/svgs/v2/ico_redcircle_message.svg';
import lazyGetMessagePageByParam from 'graphql/queries/getMessagePageByParam';
import deleteMessageByIds from 'graphql/mutations/deleteMessageByIds';
import AddMessageModal from 'components/v2/modals/AddMessageModal';

const Message = () => {

  const [modalIsOpen, setModalIsOpen] = useState({
    create: false,
    delete: false
  }); 
  
  const [isShowing, setIsShowing] = useState<'DATA_GRID' | 'DETAIL'>('DATA_GRID'); 
  const [search, setSearch] = useState({
    msgUseType: 'all',
    searchText: ''
  });
  const [total, setTotal] = useState({
    totalPage: 0,
    totalElement: 0
  });
  const [rows, setRows] = useState<MessageInfo[]>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');

  const [msgUseTypeList, setMsgUseTypeList] = useState<{name: string; value: string}[]>([]);
  const [msgSenderTypeList, setMsgSenderTypeList] = useState<{name: string; value: string;  }[]>([]);

  /* 메세지 정보 조회(page) */
  const [getMessagePageByParam, { loading: loadingGetMessagePageByParam }] = lazyGetMessagePageByParam();
  /* 메세지 정보 삭제 */
  const [delMessage] = deleteMessageByIds();
  /* 메세지 용도 리스트 조회 */
  const [getMsgUseTypeCodeList] = lazyGetEnumTypeCode();
  /* 수신타입 리스트 조회 */
  const [getMsgSenderTypeCodeList] = lazyGetEnumTypeCode();

  const getMassagePageByParamData = useMemo(() => ({
    reqGetMessagePage: {
      messageType : search.msgUseType === 'all' ? undefined : search.msgUseType,
      text: search.searchText === '' ? undefined : search.searchText,
      pageInfo: {
        page: tablePagination.currentPage - 1,
        size: tablePagination.limit,
        orders: [{
          target: tablePagination.target === 'modifiedByName' ? 'modifiedBy' : tablePagination.target,
          direction: tablePagination.direction
        }]
      }
    }
  }), [
    tablePagination,
    search,
    isShowing
  ]);

  const renderShowing = useMemo(() => {
    switch (isShowing) {
      default:
        return <MessageGrid
          msgUseTypeList={msgUseTypeList}
          onSubmitSearch={(readyToSearch: {
            msgUseType: string;
            searchText: string;
          }) => {
            setSearch({ ...readyToSearch });
            setTablePagination(prev => ({
              ...prev,
              currentPage: 1
            }));
          }}
          tablePagination={tablePagination}
          updateTablePagination={(key:string, value: number | string | OrderDirection) => setTablePagination(prev => ({
            ...prev,
            [key]: value
          }))}
          total={total}
          rows={rows}
          checkedList={checkedList}
          reportCheckedList={(list:string[]) => setCheckedList(list)}
          reportSelected={(id:string) => {
            setSelected(id);
            setIsShowing('DETAIL');
          }}
          openCreateModal={() => setModalIsOpen(prev => ({
            ...prev,
            create: true
          }))}
          openDeleteModal={() => setModalIsOpen(prev => ({
            ...prev,
            delete: true
          }))}
          isLoading={loadingGetMessagePageByParam}
        />
      case 'DETAIL': 
        return <MessageDetail
          msgUseTypeList={msgUseTypeList}
          msgSenderTypeList={msgSenderTypeList}
          data={rows.find(val => val.id === Number(selected)) as RowType} 
          convertGridIsShowing={() => {
            setSelected('');
            setIsShowing('DATA_GRID');
          }}
          openDeleteModal={() =>  {
            setCheckedList([selected]);
            setModalIsOpen(prev => ({
              ...prev,
              delete: true
            }))

         }}    
         onEditSuccess={() => {
           getMessageRows ();   
          }}
        />
    }
  }, [ 
    tablePagination,
    isShowing, 
    rows, 
    total,
    checkedList, 
    selected,

    msgUseTypeList,
    msgSenderTypeList,
    loadingGetMessagePageByParam
  ])

  const getMessageRows = () => {
    getMessagePageByParam({ variables: getMassagePageByParamData }).then(({ data }) => {
      if (data) {
        if (data.getMessagePageByParam) {
          setRows(data.getMessagePageByParam.content)
          setTotal({
            totalPage: data.getMessagePageByParam.totalPages,
            totalElement: data.getMessagePageByParam.totalElements
          })
        }
      }
    })
  }

  /* 메세지rows 받기 */
  useEffect(() => {
    getMessageRows();
  }, [getMassagePageByParamData]);

  useEffect(() => {
    /* 메세지 용도 리스트 가져오기 */
    getMsgUseTypeCodeList({variables: { text: 'MsgUseTypeCode'}}).then(({ data }) => {
      if (data) {
        if (data.getEnumTypeCode.data) {
          setMsgUseTypeList(data.getEnumTypeCode.data.map(val => ({
            name: String(enumFormatter(val.value)),
            value: val.value
          })));
        }
      }
    })

    /* 수신타입 리스트 가져오기 */
    getMsgSenderTypeCodeList({variables: {text: 'MsgSenderTypeCode'}}).then(({ data }) => {
      if (data) {
        if (data.getEnumTypeCode.data) {
          setMsgSenderTypeList(data.getEnumTypeCode.data.map(val => ({
            name: String(enumFormatter(val.value)),
            value: val.value
          })))
        }
      }
    })
  }, [])

  return (
    <div className='message'>
      {renderShowing}
      <AddMessageModal
        msgUseTypeList={msgUseTypeList}
        msgSenderTypeList={msgSenderTypeList}
        open={modalIsOpen.create}
        title={() => <>
          <Icon width={32} height={32} src={MessageCircle} /> 
          Create message
        </>}
        onCreateSuccess={() => {
          setModalIsOpen(prev => ({
            ...prev,
            create: false
          }))
          getMessageRows();
        }}
        onClose={() => {
          setModalIsOpen(prev => ({
            ...prev,
            create: false
          }));
        }}    
      />

      <DeleteMessageModal 
        open={modalIsOpen.delete} 
        data={rows.filter(val => checkedList.includes(String(val.id)))} 
        title={() => 
          <>
            <Icon width={32} height={32} src={MessageCircle}  /> 
            Delete messages
          </>
        } 
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          delete: false
        }))}
        onDelete={() => {
          const deleteMessageData = {
            reqDelMessage: {
              messageIds: checkedList.map(val => Number(val))
            }
          }
          delMessage({ variables: deleteMessageData }).then(({data}) => {
            if (data) {
              if(data.deleteMessageByIds.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, 'Message is deleted successfully.');
                setModalIsOpen(prev => ({
                  ...prev,
                  delete: false
                }))

                if (isShowing === 'DETAIL') {
                  setIsShowing('DATA_GRID');
                }

                if ((rows.length === checkedList.length) && total.totalPage !== 1) {
                  setTablePagination(prev => ({
                    ...prev,
                    currentPage: tablePagination.currentPage - 1
                  }));
                } else {
                  getMessageRows (); 
                }

                setCheckedList([]);
              } else {
                console.log(data.deleteMessageByIds.result);
                useToast(ErrorCode.UNKNOWN, 'Delete message failed.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, 'Delete message failed.');
            }
          })
        }} 
      />
    </div>
  );
}
export default Message;
