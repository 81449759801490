import './index.scss';
import { useEffect, useState } from 'react';
import Details from '../Common/Components/Details';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';
import Routes from '../Common/Components/Routes';
import SubnetAssociations from '../Common/Components/SubnetAssociations';
import EdgeAssociations from '../Common/Components/EdgeAssociations';
import RoutePropagation from '../Common/Components/RoutePropagation';
import Tags from '../Common/Components/Tags';

const detailTabs: IMgdTabProps[] = [
  { id: 'details', name: 'Details', title: 'Details' },
  { id: 'route', name: 'Routes', title: 'Routes' },
  { id: 'subnetAssociations', name: 'Subnet associations', title: 'Subnet associations' },
  { id: 'edgeAssociations', name: 'Edge associations', title: 'Edge associations' },
  { id: 'routePropagation', name: 'Route propagation', title: 'Route propagation' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

const RouteTableSummary = ({ cloudId, region, data }: any) => {
  const [tabSelected, setTabSelected] = useState(detailTabs[0]);

  useEffect(() => {
    setTabSelected(detailTabs[0]);
  }, [data?.id]);

  const renderTab = () => {
    switch (tabSelected.id) {
      case detailTabs[0].id:
        return <Details data={data} />;

      case detailTabs[1].id:
        return <Routes data={data} />;

      case detailTabs[2].id:
        return <SubnetAssociations cloudId={cloudId} region={region} data={data} />;

      case detailTabs[3].id:
        return <EdgeAssociations cloudId={cloudId} region={region} data={data} />;

      case detailTabs[4].id:
        return <RoutePropagation cloudId={cloudId} region={region} data={data} />;

      case detailTabs[5].id:
        return <Tags data={data} />;

      default:
        return null;
    }
  };

  return data?.id ? (
    <div className="box-summary">
      <div className="box-summary-id">{data?.id}</div>
      
      <div className="detail-tab flex a-center">
        {detailTabs.map(tab => {
          return (
            <button
              className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
              key={tab.id}
              data-tab={tab.id}
              onClick={e => setTabSelected(tab)}
            >
              <p>{tab.name}</p>
            </button>
          );
        })}
      </div>

      <div className="content-tab">{renderTab()}</div>
    </div>
  ) : (
    <div className="detail-data">
      <p className="empty-page">Select a item</p>
    </div>
  );
};

export default RouteTableSummary;
