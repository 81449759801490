import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ElasticIpListPropsType } from '../types';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { ELASTIC_IP_FILTER_DROPDOWN } from './configs';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import InputAtom from 'components/v2/atoms/InputAtom';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { orderAlphabetical } from '../../../Utils/Sorting';
import { variableCombineNextToken } from '../../../Utils';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdTabLayout/types';
import { FilterPropsType } from '../../../EC2/components/Image/types';
import { handleFormatText } from 'utils/Common';
import lazyGetAwsAllAddresses, { IAwsAddresssVariables } from 'graphql/queries/getAwsAllAddress';
import { AwsAddressType } from 'graphql/types/AwsAddress';

const ElasticIpList = (props: ElasticIpListPropsType) => {
  const {
    cloudId,
    region,
    tabInformation,
    onElasticIpSelected,
    onElasticIpIdClicked,
    currentRouteState,
    setCurrentRouteState,
  } = props;

  // API
  const [getAwsAddresses, { loading: awsElasticIPLoading }] = lazyGetAwsAllAddresses();

  // State
  const [mainTblTotalRows, setMainTblTotalRows] = useState<RowType[]>([]);
  const [mainNextToken, setMainNextToken] = useState<string>('');
  const currentFilter = currentRouteState && ELASTIC_IP_FILTER_DROPDOWN.find(e => e.value === currentRouteState.key);
  const [elasticIpFilterDropdownValue, setElasticIpFilterDropdownValue] = useState<DropdownListDataType>(
    currentFilter || ELASTIC_IP_FILTER_DROPDOWN[0],
  );
  const [elasticIpFilterInputValue, setElasticIpFilterInputValue] = useState<FilterPropsType>({
    name: elasticIpFilterDropdownValue.value as string,
    value: currentRouteState?.value,
  });
  const [elasticIpFilter, setElasticIpFilter] = useState<FilterPropsType | undefined>(
    currentRouteState?.value ? elasticIpFilterInputValue : undefined,
  );
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });

  useEffect(() => {
    return () => {
      if (!currentRouteState) return;

      setCurrentRouteState(null);
    };
  }, [currentRouteState]);

  const mainTblColumns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Name',
        field: 'name',
        sort: true,
        renderCell: (row: RowType) => <>{handleFormatText(row?.name || '-')}</>,
      },
      {
        label: 'Allocated IPv4 address',
        field: 'publicIp',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <div
                className="link"
                onClick={e => {
                  e.stopPropagation();
                  onElasticIpIdClicked();
                  onElasticIpSelected(row as AwsAddressType);
                }}
              >
                {handleFormatText(row?.publicIp)}
              </div>
            }
          </>
        ),
      },
      { label: 'Type', field: 'type', sort: true },
      { label: 'Allocation ID', field: 'allocationId', sort: true },
      {
        label: 'Reverse DNS record',
        field: 'reverseDNSRecord',
        sort: true,
      },
      {
        label: 'Associated instance ID',
        field: 'associatedInstanceId',
        sort: true,
      },
      {
        label: 'Private IP address',
        field: 'privateIpAddress',
        sort: true,
        renderCell: (row: RowType) => <>{handleFormatText(row?.privateIpAddress ? row.privateIpAddress : '-')}</>,
      },
      {
        label: 'Association ID',
        field: 'associationId',
        sort: true,
        renderCell: (row: RowType) => <>{handleFormatText(row?.associationId ? row.associationId : '-')}</>,
      },
      {
        label: 'Network interface owner account ID',
        field: 'networkInterfaceOwnerId',
        sort: true,
        renderCell: (row: RowType) => (
          <>{handleFormatText(row?.networkInterfaceOwnerId ? row.networkInterfaceOwnerId : '-')}</>
        ),
      },
      {
        label: 'Network border group',
        field: 'networkBorderGroup',
        sort: true,
      },
    ];
  }, []);

  const handleSearchFunction = useCallback(() => {
    if (elasticIpFilterInputValue.value !== '') {
      setElasticIpFilter({ name: elasticIpFilterInputValue.name, value: elasticIpFilterInputValue.value });
    } else {
      setElasticIpFilter(undefined);
    }
  }, [elasticIpFilterInputValue]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setMainTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      fetchAwsAllElasticIps(mainNextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(mainTblTotalRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotalRows, mainTblTotal, mainTablePagination, mainNextToken, elasticIpFilterInputValue]);

  const awsElasticIpRequestVariables = useMemo((): IAwsAddresssVariables => {
    if (elasticIpFilter) {
      return {
        cloudId: cloudId,
        region: region.value as string,
        request: {
          filters: {
            name: elasticIpFilter?.name || '',
            values: [elasticIpFilter?.value.toLocaleLowerCase() || ''],
          },
        },
      };
    }
    return {
      cloudId: cloudId,
      region: region.value as string,
      request: {},
    };
  }, [cloudId, region, mainTablePagination, elasticIpFilter]);

  const fetchAwsAllElasticIps = useCallback(
    (nextToken?: string, isInitial?: boolean) => {
      const combinedVariable = variableCombineNextToken(awsElasticIpRequestVariables, nextToken);
      getAwsAddresses({ variables: combinedVariable }).then(({ data: awsElasticIpData }) => {
        const elasticIPData: RowType[] = [];
        const getAwsAddresses = awsElasticIpData?.getAwsAddresses;
        if (getAwsAddresses) {
          getAwsAddresses?.data?.map(address => {
            elasticIPData.push({
              ...address,
              id: address.publicIp,
              name: address?.tags?.find(t => t?.key == 'Name')?.value,
              type: address?.publicIp ? 'Public IP' : '-',
              reverseDNSRecord: '-',
              associatedInstanceId: '-',
            });
          });
          let allAwsElasticIP: RowType[] = [];
          if (isInitial) {
            allAwsElasticIP = elasticIPData;
          } else {
            allAwsElasticIP = [...mainTblTotalRows, ...elasticIPData];
          }
          setMainTblTotalRows(allAwsElasticIP);
          if (allAwsElasticIP.length > mainTablePagination.limit) {
            setMainNextToken(getAwsAddresses?.nextToken as string);
          } else {
            setMainNextToken('');
          }
          setMainTblTotal({
            totalPage: Math.ceil(allAwsElasticIP.length / mainTablePagination.itemPerPage),
            totalElement: allAwsElasticIP.length,
          });
        }
      });
    },
    [awsElasticIpRequestVariables, mainTblTotalRows, mainTblTotal, mainTablePagination],
  );

  const handleMainRowSelected = useCallback(
    (rowId: string) => {
      const rowSelected = mainTblTotalRows.find(row => row.id === rowId);

      if (!rowSelected) return;

      onElasticIpSelected(rowSelected as AwsAddressType);
    },
    [mainTblTotalRows],
  );

  // For initial data
  useEffect(() => {
    fetchAwsAllElasticIps('', true);
  }, [awsElasticIpRequestVariables]);

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>{tabInformation.title}</p>
        </div>
        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={ELASTIC_IP_FILTER_DROPDOWN}
            value={elasticIpFilterDropdownValue}
            handleClick={val => {
              setElasticIpFilterDropdownValue(val);
              setElasticIpFilterInputValue(prev => ({ ...prev, name: val.value as string }));
            }}
          />
          <InputAtom
            type={'text'}
            value={elasticIpFilterInputValue.value}
            defaultValue={''}
            onChangeValue={str => setElasticIpFilterInputValue(prev => ({ name: prev?.name, value: str }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {}}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSearchFunction();
              }
            }}
          />

          <button className="action-btn" onClick={handleSearchFunction}>
            Search
          </button>
        </div>
      </div>
      <div className="data-grid-wrap">
        <Table
          rows={mainRowsCurrentPage}
          columns={mainTblColumns}
          reportCheckedList={() => {}}
          reportSelected={id => handleMainRowSelected(id)}
          sortOption={{
            target: mainTablePagination.target,
            direction: mainTablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {
              updateTablePagination('target', target);
              updateTablePagination('direction', dir);
            },
          }}
          isLoading={awsElasticIPLoading}
          horizontalScrollable={true}
        />
        {mainRowsCurrentPage && mainRowsCurrentPage.length > 0 && awsElasticIPLoading === false && (
          <div className="pagination-wrapper flex a-center">
            <p className="flex a-center">
              Total <span>{mainTblTotal.totalElement}</span>
            </p>
            <TableManagePagination
              ableFetchMore={!!mainNextToken}
              currentPage={mainTablePagination.currentPage}
              updateCurrentPage={page =>
                setMainTablePagination(prev => ({
                  ...prev,
                  ['currentPage']: page,
                }))
              }
              totalPage={mainTblTotal.totalPage}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ElasticIpList;
