import { gql } from '@apollo/client';

const query = gql`
query getAwsListDeliveryStreams($request: AwsListDeliveryStreamsRequest, $cloudId: Long, $region: String) {
  getAwsListDeliveryStreams(
    listDeliveryStreamsRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsListDeliveryStreamsResponse {
        deliveryStreamNames
        hasMoreDeliveryStreams
      }
    }
  }
}
`;
export default query;