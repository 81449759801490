import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './styles/reset.scss';
import './index.scss';
import App from './App';
import GraphqlProvider from './contexts/GraphqlProvider';
import ApiProvider from './contexts/ApiProvider';
import 'react-toastify/dist/ReactToastify.css';
import AuthProvider from './contexts/AuthProvider';
import { Suspense } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import GlobalModalProvider from 'contexts/v2/GlobalModalProvider';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const RenderApp = () => {
  return (
    <AuthProvider>
      <ApiProvider>
        <GraphqlProvider>
          <GlobalModalProvider>
              <Suspense fallback={<div>...loading</div>}>
                <App />
              </Suspense>
          </GlobalModalProvider>
        </GraphqlProvider>
      </ApiProvider>
    </AuthProvider>
  );
};

root.render(
  <BrowserRouter>
    <RenderApp />
  </BrowserRouter>
);
