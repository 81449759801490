import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import ToggleSwitchAtom from 'components/v4/atoms/ToggleSwitchAtom';
import { useAuth } from 'contexts/AuthProvider';
import { myMFADevice } from 'utils/DummyData/Default';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, RowType } from '@Types/v2/Table';
import { dateFormatter } from 'utils/Formatter';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import MFARemoveDeviceModal from '../../MFARemoveDeviceModal';
import MFAResyncDeviceModal from '../../MFAResyncDeviceModal';
import MFASetupDeviceModal from '../../MFASetupDeviceModal';
import { ProfileAlertType } from '@Types/v4/Member';
import Icon from 'components/v2/atoms/Icon';
import RedCircleDevice from 'assets/svgs/v4/ico_red_circle_mfa_device.svg';
import InputAtom from 'components/v2/atoms/InputAtom';
import _ from 'lodash';

interface IEnvironmentProps {
  alertList: ProfileAlertType[];
  editAlertList: (list: ProfileAlertType[]) => void;
}

const Environment = ({
  alertList,
  editAlertList
}:IEnvironmentProps) => {

  const { token, userInfo } = useAuth();
  const [selectedDevice, setSelectedDevice] = useState('');
  const [rows, setRows] = useState<{
    id: number;
    name: string;
    identifier: string;
    createdAt: string;
  }[]>([]);
  const [openModal, setOpenModal] = useState<'remove'|'resync'|'assign'|''>();

  const checkAllAlertEnabled = useMemo(() => {
    return alertList.every(pj => pj.sms === true && pj.slack === true && pj.kakaotalk === true);
  }, [alertList]);

  const deviceColumns:ColumnType[] = useMemo(() => {
    return [
      {
        label: 'check',
        field: 'check',
        renderCell: (row: RowType) => <RadioAtom 
        label={""}
        value={row.id!.toString()}
        name={row.name}
        checked={selectedDevice}
        onChange={() => setSelectedDevice(row.id!.toString())}
        />,
        renderHeader: () => <span className="device-check-header"/>,
        width: 16 + 16 + 16
      },
      {
        label: 'Name',
        field: 'name',
        width: 100 + 16
      },
      {
        label: 'Identifier',
        field: 'identifier',
      },
      {
        label: 'Created at',
        field: 'createdAt',
        width: 80 + 16,
        renderCell: (row: RowType) => <>{dateFormatter(row.createdAt, 'date')}</>
      }
    ];
  }, [selectedDevice]);

  const changeProjectAlert = (index: number, key: 'sms' | 'slack' | 'kakaotalk') => {
    editAlertList(alertList.map((d, idx) => idx === index ? {...d, [key]: !d[key]} : d));
  };
  const changeAllProjectAlert = (type: boolean) => {
    editAlertList(alertList.map((d, idx) => ({...d, sms: type, slack: type, kakaotalk: type})));
  };
  const removeDevice = () => {
    setRows(prev => prev.filter((device) => device.id.toString() !== selectedDevice));
    modalClose();
  }
  const resyncDevice = (code1:string, code2:string) => {
    setRows(prev => prev.map((device) => device.id.toString() === selectedDevice ? {...device, name: code1, identifier: code2} : device));
    modalClose();
  }
  const addDevice = (code1:string, code2:string) => {
    const copy = _.cloneDeep(rows);
    copy.sort((a, b) => a.id - b.id);
console.log(copy[0]);
    setRows(prev => ([...prev, {id: copy[0].id + 1, name: code1, identifier: code2, createdAt: new Date().toISOString()}]))
    modalClose();
  }
  const modalClose = () => {
    setOpenModal('')
  }

  useEffect(() => {
    // setRows(myMFADevice);   
    setRows([]);
  }, [alertList, open])

  return (
    <>
      <div className="env-setting-box">
        <h4>Environment setting</h4>
        <div className="row-with-right-group flex j-between a-center">
          MFA (Optional)
          <div className="flex gap8">
            <button className="big-sub-btn" type="button" onClick={() => setOpenModal('remove')} disabled={selectedDevice === ''}>Remove</button>
            <button className="big-sub-btn" type="button" onClick={() => setOpenModal('resync')} disabled={selectedDevice === ''}>Resync</button>
            <button className="big-sub-btn" type="button" onClick={() => setOpenModal('assign')}>Assign</button>
          </div>
        </div>
        <div className="device-table-box">
          <Table
            rows={rows}
            columns={deviceColumns}
            reportSelected={(id) => setSelectedDevice(id)}
          />
          {rows.length === 0 && <div className="device-none">no item</div>}
        </div>
        <div className="row-with-right-group mb-16 flex j-between a-center">
          Alert setting
          <div className='flex a-center gap8'>
            <ToggleSwitchAtom 
              value={checkAllAlertEnabled}
              onClick={() => {
                changeAllProjectAlert(!checkAllAlertEnabled)
              }}
              width={34}
              height={22}
              space={3}
            />
            All
          </div>
        </div>
        <div className="row-with-right-group pl-8 mb-8 flex j-between a-center">
          Project name
          <div className='flex a-center gap16'>
            <span className="label">SMS</span>
            <span className="label">Slack</span>
            <span className="label">Kakaotalk</span>
          </div>
        </div>
        {alertList.map((pj, pjIdx) => 
        <div key={pjIdx} className={`row-with-right-group pl-8 pr-19 ${pjIdx < alertList.length - 1 ? 'mb-14' : ''} flex j-between a-center`}>
          {pj.name}
          <div className='flex a-center gap16'>
            <ToggleSwitchAtom 
              value={pj.sms}
              onClick={() => {
                changeProjectAlert(pjIdx, 'sms');
              }}
              width={34}
              height={22}
              space={3}
            />
            <ToggleSwitchAtom 
              value={pj.slack}
              onClick={() => {
                changeProjectAlert(pjIdx, 'slack');
              }}
              width={34}
              height={22}
              space={3}
            />
            <ToggleSwitchAtom 
              value={pj.kakaotalk}
              onClick={() => {
                changeProjectAlert(pjIdx, 'kakaotalk');
              }}
              width={34}
              height={22}
              space={3}
            />
          </div>
        </div>)}
      </div>
      
      <MFARemoveDeviceModal 
        open={openModal === 'remove'} 
        onClose={modalClose}
        title={() => <><Icon width={32} height={32} src={RedCircleDevice} />Remove MFA device</>}
        buttonTitle="Remove"
        onConfirm={removeDevice}
        content={() => <>
          This MFA device can no longer be used when signing in.
        </>}
      />
      <MFAResyncDeviceModal 
        open={openModal === 'resync'} 
        onClose={modalClose} 
        title={() => <><Icon width={32} height={32} src={RedCircleDevice} />Resync MFA device</>}
        buttonTitle="Resync"
        onConfirm={resyncDevice}
      />
      <MFASetupDeviceModal 
        open={openModal === 'assign'} 
        onClose={modalClose} 
        title={() => <><Icon width={32} height={32} src={RedCircleDevice} />Setup device</>}
        buttonTitle="Add device"
        onConfirm={addDevice}
        content={() => <>
        A virtual MFA device is an application running on your device  that you can configure by scanning a QR code.
        </>}
      />
    </>
  );
};

export default Environment;
