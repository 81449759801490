import { InboundOutboundPropsType } from '../types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import {
  generatePortRangeValue,
  getNetworkTypeName,
} from '../../../Function';
import { handleFormatText } from 'utils/Common';
import { NETWORK_RULES_PROTOCOL_MAPPING } from '../../../Constants';
import DetailTable from '../../DetailTable';

const InboundOutbound = (props: InboundOutboundPropsType) => {
  const { networkAcl, isInbound, rightButtons } = props;

  const [tablePagination, setTablePagination] = useState({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [rows, setRows] = useState<RowType[]>([]);

  const columns = useMemo((): ColumnType[] => {
    return [
      { label: 'Rule number', field: 'ruleNumber', sort: true, width: 160 },
      { label: 'Type', field: 'networkType', sort: true },
      { label: 'Protocol', field: 'protocol', sort: true },
      { label: 'Port range', field: 'portRange', sort: true },
      {
        label: isInbound ? 'Source' : 'Destination',
        field: 'cidr',
        sort: true,
      },
      {
        label: 'Allow/Deny',
        field: 'ruleAction',
        sort: true,
        renderCell: (row: RowType) => (
          <Fragment>{<div className={row.ruleAction.toLowerCase()}>{handleFormatText(row?.ruleAction)}</div>}</Fragment>
        ),
      },
    ];
  }, [isInbound]);

  const apiIsLoading = useMemo(() => {
    return false;
  }, []);

  const getDetailDataRows = useCallback(() => {
    const networkAclBound: RowType[] = [];
    networkAcl.entries
      .filter((e: { egress: boolean }) => (e.egress === isInbound ? false : true))
      .forEach((row: any) => {
        const protocolCode = row?.protocol ? row.protocol : '-';
        let protocolMapping = NETWORK_RULES_PROTOCOL_MAPPING[protocolCode];
        if (!protocolMapping) {
          protocolMapping = NETWORK_RULES_PROTOCOL_MAPPING['-'];
        }

        networkAclBound.push({
          ruleNumber: row.ruleNumber == '32767' ? '*' : row.ruleNumber,
          networkType: getNetworkTypeName(row.portRange, row.icmpTypeCode, row?.ipv6CidrBlock, protocolCode),
          cidr: row.cidrBlock ? row.cidrBlock : row.ipv6CidrBlock,
          protocol: protocolMapping.protocol,
          icmpTypeCode: row.icmpTypeCode,
          portRange: generatePortRangeValue(row.portRange, row.icmpTypeCode),
          ruleAction: row.ruleAction === 'allow' ? 'Allow' : 'Deny',
        });
      });
    setRows(networkAclBound);
  }, [networkAcl, isInbound]);

  const currentRowPage = useMemo((): RowType[] => {
    return orderAlphabetical(rows, tablePagination.target, tablePagination.direction);
  }, [rows, tablePagination]);

  useEffect(() => {
    getDetailDataRows();
  }, [isInbound]);

  return (
    <Fragment>
      {apiIsLoading ? (
        <div className="storage-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="listeners-info">
            <div className="table-container">
              <DetailTable
                title={isInbound ? 'Inbound' : 'Outbond'}
                data={currentRowPage}
                columns={columns}
                hasPagination={true}
                rightButtons={rightButtons}
              />
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default InboundOutbound;
