import { ColumnType } from '@Types/v2/Table';
import DetailTable from '../../../DetailTable';
import lazyGetAwsVpnGateways from 'graphql/queries/getAwsVpnGateways';
import { useEffect, useState } from 'react';

function RoutePropagation({ cloudId, region, data }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  const { vpcId, propagatingVgws } = data;

  const [getAwsVpnGateways] = lazyGetAwsVpnGateways();

  const handleGetData = async () => {
    try {
      setIsLoading(true);
      let arr: any = [];
      const vpnGWVariable = {
        cloudId,
        region,
        request: {
          filters: {
            name: 'attachment.vpc-id',
            values: [vpcId],
          },
        },
      };

      const { data: vpnGWData } = await getAwsVpnGateways({ variables: vpnGWVariable });
      setIsLoading(false);

      const vpnGatewaysData = vpnGWData?.getAwsVpnGateways?.data?.vpnGateways;

      if (!vpnGatewaysData?.length) {
        setDataTable([]);
        return;
      }

      vpnGatewaysData.forEach(item => {
        arr.push({
          virtualPrivateGateway: item.vpnGatewayId,
          propagation: propagatingVgws?.[0]?.gatewayId === item.vpnGatewayId ? 'Yes' : 'No',
        });
      });
      setDataTable(arr);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const columns: ColumnType[] = [
    {
      label: 'Virtual private gateway',
      field: 'virtualPrivateGateway',
      sort: true,
    },
    { label: 'Propagation', field: 'propagation', sort: true },
  ];

  return <DetailTable title="Route propagation" data={dataTable} columns={columns} isLoading={isLoading} />;
}

export default RoutePropagation;
