import { gql } from '@apollo/client';

const query = gql`
  mutation addDashboard($reqData: ReqAddDashboard) {
  addDashboard(reqData: $reqData) {
    result
    data {
      ... on Dashboard {
        id
        name
        templateId
        organId
        parameters
      }
    }
  }
}
`;

export default query;