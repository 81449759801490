import { gql } from '@apollo/client';

const query = gql`query getDashboardsByOrganId($organId: Long) {
	getDashboardsByOrganId(organId: $organId) {
    result
    data {
      ... on Dashboard {
        id
        name
        templateId
        organId
        parameters
      }
    }
  }
}`;
export default query;