export const COUNTRY_CODES_DATA = [
  {
    id: 0,
    value: 'AF',
    name: 'Afghanistan - AF',
  },
  {
    id: 1,
    value: 'AL',
    name: 'Albania - AL',
  },
  {
    id: 2,
    value: 'DZ',
    name: 'Algeria - DZ',
  },
  {
    id: 3,
    value: 'AS',
    name: 'American Samoa - AS',
  },
  {
    id: 4,
    value: 'AD',
    name: 'Andorra - AD',
  },
  {
    id: 5,
    value: 'AO',
    name: 'Angola - AO',
  },
  {
    id: 6,
    value: 'AI',
    name: 'Anguilla - AI',
  },
  {
    id: 7,
    value: 'AQ',
    name: 'Antarctica - AQ',
  },
  {
    id: 8,
    value: 'AG',
    name: 'Antigua and Barbuda - AG',
  },
  {
    id: 9,
    value: 'AR',
    name: 'Argentina - AR',
  },
  {
    id: 10,
    value: 'AM',
    name: 'Armenia - AM',
  },
  {
    id: 11,
    value: 'AW',
    name: 'Aruba - AW',
  },
  {
    id: 12,
    value: 'AU',
    name: 'Australia - AU',
  },
  {
    id: 13,
    value: 'AT',
    name: 'Austria - AT',
  },
  {
    id: 14,
    value: 'AZ',
    name: 'Azerbaijan - AZ',
  },
  {
    id: 15,
    value: 'BS',
    name: 'Bahamas - BS',
  },
  {
    id: 16,
    value: 'BH',
    name: 'Bahrain - BH',
  },
  {
    id: 17,
    value: 'BD',
    name: 'Bangladesh - BD',
  },
  {
    id: 18,
    value: 'BB',
    name: 'Barbados - BB',
  },
  {
    id: 19,
    value: 'BY',
    name: 'Belarus - BY',
  },
  {
    id: 20,
    value: 'BE',
    name: 'Belgium - BE',
  },
  {
    id: 21,
    value: 'BZ',
    name: 'Belize - BZ',
  },
  {
    id: 22,
    value: 'BJ',
    name: 'Benin - BJ',
  },
  {
    id: 23,
    value: 'BM',
    name: 'Bermuda - BM',
  },
  {
    id: 24,
    value: 'BT',
    name: 'Bhutan - BT',
  },
  {
    id: 25,
    value: 'BO',
    name: 'Bolivia - BO',
  },
  {
    id: 26,
    value: 'BA',
    name: 'Bosnia and Herzegovina - BA',
  },
  {
    id: 27,
    value: 'BW',
    name: 'Botswana - BW',
  },
  {
    id: 28,
    value: 'BR',
    name: 'Brazil - BR',
  },
  {
    id: 29,
    value: 'IO',
    name: 'British Indian Ocean Territory - IO',
  },
  {
    id: 30,
    value: 'VG',
    name: 'British Virgin Islands - VG',
  },
  {
    id: 31,
    value: 'BN',
    name: 'Brunei - BN',
  },
  {
    id: 32,
    value: 'BG',
    name: 'Bulgaria - BG',
  },
  {
    id: 33,
    value: 'BF',
    name: 'Burkina Faso - BF',
  },
  {
    id: 34,
    value: 'BI',
    name: 'Burundi - BI',
  },
  {
    id: 35,
    value: 'KH',
    name: 'Cambodia - KH',
  },
  {
    id: 36,
    value: 'CM',
    name: 'Cameroon - CM',
  },
  {
    id: 37,
    value: 'CA',
    name: 'Canada - CA',
  },
  {
    id: 38,
    value: 'CV',
    name: 'Cape Verde - CV',
  },
  {
    id: 39,
    value: 'KY',
    name: 'Cayman Islands - KY',
  },
  {
    id: 40,
    value: 'CF',
    name: 'Central African Republic - CF',
  },
  {
    id: 41,
    value: 'TD',
    name: 'Chad - TD',
  },
  {
    id: 42,
    value: 'CL',
    name: 'Chile - CL',
  },
  {
    id: 43,
    value: 'CN',
    name: 'China - CN',
  },
  {
    id: 44,
    value: 'CX',
    name: 'Christmas Island - CX',
  },
  {
    id: 45,
    value: 'CC',
    name: 'Cocos Islands - CC',
  },
  {
    id: 46,
    value: 'CO',
    name: 'Colombia - CO',
  },
  {
    id: 47,
    value: 'KM',
    name: 'Comoros - KM',
  },
  {
    id: 48,
    value: 'CK',
    name: 'Cook Islands - CK',
  },
  {
    id: 49,
    value: 'CR',
    name: 'Costa Rica - CR',
  },
  {
    id: 50,
    value: 'HR',
    name: 'Croatia - HR',
  },
  {
    id: 51,
    value: 'CU',
    name: 'Cuba - CU',
  },
  {
    id: 52,
    value: 'CW',
    name: 'Curacao - CW',
  },
  {
    id: 53,
    value: 'CY',
    name: 'Cyprus - CY',
  },
  {
    id: 54,
    value: 'CZ',
    name: 'Czech Republic - CZ',
  },
  {
    id: 55,
    value: 'CD',
    name: 'Democratic Republic of the Congo - CD',
  },
  {
    id: 56,
    value: 'DK',
    name: 'Denmark - DK',
  },
  {
    id: 57,
    value: 'DJ',
    name: 'Djibouti - DJ',
  },
  {
    id: 58,
    value: 'DM',
    name: 'Dominica - DM',
  },
  {
    id: 59,
    value: 'DO',
    name: 'Dominican Republic - DO',
  },
  {
    id: 60,
    value: 'TL',
    name: 'East Timor - TL',
  },
  {
    id: 61,
    value: 'EC',
    name: 'Ecuador - EC',
  },
  {
    id: 62,
    value: 'EG',
    name: 'Egypt - EG',
  },
  {
    id: 63,
    value: 'SV',
    name: 'El Salvador - SV',
  },
  {
    id: 64,
    value: 'GQ',
    name: 'Equatorial Guinea - GQ',
  },
  {
    id: 65,
    value: 'ER',
    name: 'Eritrea - ER',
  },
  {
    id: 66,
    value: 'EE',
    name: 'Estonia - EE',
  },
  {
    id: 67,
    value: 'ET',
    name: 'Ethiopia - ET',
  },
  {
    id: 68,
    value: 'FK',
    name: 'Falkland Islands - FK',
  },
  {
    id: 69,
    value: 'FO',
    name: 'Faroe Islands - FO',
  },
  {
    id: 70,
    value: 'FJ',
    name: 'Fiji - FJ',
  },
  {
    id: 71,
    value: 'FI',
    name: 'Finland - FI',
  },
  {
    id: 72,
    value: 'FR',
    name: 'France - FR',
  },
  {
    id: 73,
    value: 'PF',
    name: 'French Polynesia - PF',
  },
  {
    id: 74,
    value: 'GA',
    name: 'Gabon - GA',
  },
  {
    id: 75,
    value: 'GM',
    name: 'Gambia - GM',
  },
  {
    id: 76,
    value: 'GE',
    name: 'Georgia - GE',
  },
  {
    id: 77,
    value: 'DE',
    name: 'Germany - DE',
  },
  {
    id: 78,
    value: 'GH',
    name: 'Ghana - GH',
  },
  {
    id: 79,
    value: 'GI',
    name: 'Gibraltar - GI',
  },
  {
    id: 80,
    value: 'GR',
    name: 'Greece - GR',
  },
  {
    id: 81,
    value: 'GL',
    name: 'Greenland - GL',
  },
  {
    id: 82,
    value: 'GD',
    name: 'Grenada - GD',
  },
  {
    id: 83,
    value: 'GU',
    name: 'Guam - GU',
  },
  {
    id: 84,
    value: 'GT',
    name: 'Guatemala - GT',
  },
  {
    id: 85,
    value: 'GG',
    name: 'Guernsey - GG',
  },
  {
    id: 86,
    value: 'GN',
    name: 'Guinea - GN',
  },
  {
    id: 87,
    value: 'GW',
    name: 'Guinea-Bissau - GW',
  },
  {
    id: 88,
    value: 'GY',
    name: 'Guyana - GY',
  },
  {
    id: 89,
    value: 'HT',
    name: 'Haiti - HT',
  },
  {
    id: 90,
    value: 'HN',
    name: 'Honduras - HN',
  },
  {
    id: 91,
    value: 'HK',
    name: 'Hong Kong - HK',
  },
  {
    id: 92,
    value: 'HU',
    name: 'Hungary - HU',
  },
  {
    id: 93,
    value: 'IS',
    name: 'Iceland - IS',
  },
  {
    id: 94,
    value: 'IN',
    name: 'India - IN',
  },
  {
    id: 95,
    value: 'ID',
    name: 'Indonesia - ID',
  },
  {
    id: 96,
    value: 'IR',
    name: 'Iran - IR',
  },
  {
    id: 97,
    value: 'IQ',
    name: 'Iraq - IQ',
  },
  {
    id: 98,
    value: 'IE',
    name: 'Ireland - IE',
  },
  {
    id: 99,
    value: 'IM',
    name: 'Isle of Man - IM',
  },
  {
    id: 100,
    value: 'IL',
    name: 'Israel - IL',
  },
  {
    id: 101,
    value: 'IT',
    name: 'Italy - IT',
  },
  {
    id: 102,
    value: 'CI',
    name: 'Ivory Coast - CI',
  },
  {
    id: 103,
    value: 'JM',
    name: 'Jamaica - JM',
  },
  {
    id: 104,
    value: 'JP',
    name: 'Japan - JP',
  },
  {
    id: 105,
    value: 'JE',
    name: 'Jersey - JE',
  },
  {
    id: 106,
    value: 'JO',
    name: 'Jordan - JO',
  },
  {
    id: 107,
    value: 'KZ',
    name: 'Kazakhstan - KZ',
  },
  {
    id: 108,
    value: 'KE',
    name: 'Kenya - KE',
  },
  {
    id: 109,
    value: 'KI',
    name: 'Kiribati - KI',
  },
  {
    id: 110,
    value: 'XK',
    name: 'Kosovo - XK',
  },
  {
    id: 111,
    value: 'KW',
    name: 'Kuwait - KW',
  },
  {
    id: 112,
    value: 'KG',
    name: 'Kyrgyzstan - KG',
  },
  {
    id: 113,
    value: 'LA',
    name: 'Laos - LA',
  },
  {
    id: 114,
    value: 'LV',
    name: 'Latvia - LV',
  },
  {
    id: 115,
    value: 'LB',
    name: 'Lebanon - LB',
  },
  {
    id: 116,
    value: 'LS',
    name: 'Lesotho - LS',
  },
  {
    id: 117,
    value: 'LR',
    name: 'Liberia - LR',
  },
  {
    id: 118,
    value: 'LY',
    name: 'Libya - LY',
  },
  {
    id: 119,
    value: 'LI',
    name: 'Liechtenstein - LI',
  },
  {
    id: 120,
    value: 'LT',
    name: 'Lithuania - LT',
  },
  {
    id: 121,
    value: 'LU',
    name: 'Luxembourg - LU',
  },
  {
    id: 122,
    value: 'MO',
    name: 'Macau - MO',
  },
  {
    id: 123,
    value: 'MK',
    name: 'Macedonia - MK',
  },
  {
    id: 124,
    value: 'MG',
    name: 'Madagascar - MG',
  },
  {
    id: 125,
    value: 'MW',
    name: 'Malawi - MW',
  },
  {
    id: 126,
    value: 'MY',
    name: 'Malaysia - MY',
  },
  {
    id: 127,
    value: 'MV',
    name: 'Maldives - MV',
  },
  {
    id: 128,
    value: 'ML',
    name: 'Mali - ML',
  },
  {
    id: 129,
    value: 'MT',
    name: 'Malta - MT',
  },
  {
    id: 130,
    value: 'MH',
    name: 'Marshall Islands - MH',
  },
  {
    id: 131,
    value: 'MR',
    name: 'Mauritania - MR',
  },
  {
    id: 132,
    value: 'MU',
    name: 'Mauritius - MU',
  },
  {
    id: 133,
    value: 'YT',
    name: 'Mayotte - YT',
  },
  {
    id: 134,
    value: 'MX',
    name: 'Mexico - MX',
  },
  {
    id: 135,
    value: 'FM',
    name: 'Micronesia - FM',
  },
  {
    id: 136,
    value: 'MD',
    name: 'Moldova - MD',
  },
  {
    id: 137,
    value: 'MC',
    name: 'Monaco - MC',
  },
  {
    id: 138,
    value: 'MN',
    name: 'Mongolia - MN',
  },
  {
    id: 139,
    value: 'ME',
    name: 'Montenegro - ME',
  },
  {
    id: 140,
    value: 'MS',
    name: 'Montserrat - MS',
  },
  {
    id: 141,
    value: 'MA',
    name: 'Morocco - MA',
  },
  {
    id: 142,
    value: 'MZ',
    name: 'Mozambique - MZ',
  },
  {
    id: 143,
    value: 'MM',
    name: 'Myanmar - MM',
  },
  {
    id: 144,
    value: 'NA',
    name: 'Namibia - NA',
  },
  {
    id: 145,
    value: 'NR',
    name: 'Nauru - NR',
  },
  {
    id: 146,
    value: 'NP',
    name: 'Nepal - NP',
  },
  {
    id: 147,
    value: 'NL',
    name: 'Netherlands - NL',
  },
  {
    id: 148,
    value: 'AN',
    name: 'Netherlands Antilles - AN',
  },
  {
    id: 149,
    value: 'NC',
    name: 'New Caledonia - NC',
  },
  {
    id: 150,
    value: 'NZ',
    name: 'New Zealand - NZ',
  },
  {
    id: 151,
    value: 'NI',
    name: 'Nicaragua - NI',
  },
  {
    id: 152,
    value: 'NE',
    name: 'Niger - NE',
  },
  {
    id: 153,
    value: 'NG',
    name: 'Nigeria - NG',
  },
  {
    id: 154,
    value: 'NU',
    name: 'Niue - NU',
  },
  {
    id: 155,
    value: 'KP',
    name: 'North Korea - KP',
  },
  {
    id: 156,
    value: 'MP',
    name: 'Northern Mariana Islands - MP',
  },
  {
    id: 157,
    value: 'NO',
    name: 'Norway - NO',
  },
  {
    id: 158,
    value: 'OM',
    name: 'Oman - OM',
  },
  {
    id: 159,
    value: 'PK',
    name: 'Pakistan - PK',
  },
  {
    id: 160,
    value: 'PW',
    name: 'Palau - PW',
  },
  {
    id: 161,
    value: 'PS',
    name: 'Palestine - PS',
  },
  {
    id: 162,
    value: 'PA',
    name: 'Panama - PA',
  },
  {
    id: 163,
    value: 'PG',
    name: 'Papua New Guinea - PG',
  },
  {
    id: 164,
    value: 'PY',
    name: 'Paraguay - PY',
  },
  {
    id: 165,
    value: 'PE',
    name: 'Peru - PE',
  },
  {
    id: 166,
    value: 'PH',
    name: 'Philippines - PH',
  },
  {
    id: 167,
    value: 'PN',
    name: 'Pitcairn - PN',
  },
  {
    id: 168,
    value: 'PL',
    name: 'Poland - PL',
  },
  {
    id: 169,
    value: 'PT',
    name: 'Portugal - PT',
  },
  {
    id: 170,
    value: 'PR',
    name: 'Puerto Rico - PR',
  },
  {
    id: 171,
    value: 'QA',
    name: 'Qatar - QA',
  },
  {
    id: 172,
    value: 'CG',
    name: 'Republic of the Congo - CG',
  },
  {
    id: 173,
    value: 'RE',
    name: 'Reunion - RE',
  },
  {
    id: 174,
    value: 'RO',
    name: 'Romania - RO',
  },
  {
    id: 175,
    value: 'RU',
    name: 'Russia - RU',
  },
  {
    id: 176,
    value: 'RW',
    name: 'Rwanda - RW',
  },
  {
    id: 177,
    value: 'BL',
    name: 'Saint Barthelemy - BL',
  },
  {
    id: 178,
    value: 'SH',
    name: 'Saint Helena - SH',
  },
  {
    id: 179,
    value: 'KN',
    name: 'Saint Kitts and Nevis - KN',
  },
  {
    id: 180,
    value: 'LC',
    name: 'Saint Lucia - LC',
  },
  {
    id: 181,
    value: 'MF',
    name: 'Saint Martin - MF',
  },
  {
    id: 182,
    value: 'PM',
    name: 'Saint Pierre and Miquelon - PM',
  },
  {
    id: 183,
    value: 'VC',
    name: 'Saint Vincent and the Grenadines - VC',
  },
  {
    id: 184,
    value: 'WS',
    name: 'Samoa - WS',
  },
  {
    id: 185,
    value: 'SM',
    name: 'San Marino - SM',
  },
  {
    id: 186,
    value: 'ST',
    name: 'Sao Tome and Principe - ST',
  },
  {
    id: 187,
    value: 'SA',
    name: 'Saudi Arabia - SA',
  },
  {
    id: 188,
    value: 'SN',
    name: 'Senegal - SN',
  },
  {
    id: 189,
    value: 'RS',
    name: 'Serbia - RS',
  },
  {
    id: 190,
    value: 'SC',
    name: 'Seychelles - SC',
  },
  {
    id: 191,
    value: 'SL',
    name: 'Sierra Leone - SL',
  },
  {
    id: 192,
    value: 'SG',
    name: 'Singapore - SG',
  },
  {
    id: 193,
    value: 'SX',
    name: 'Sint Maarten - SX',
  },
  {
    id: 194,
    value: 'SK',
    name: 'Slovakia - SK',
  },
  {
    id: 195,
    value: 'SI',
    name: 'Slovenia - SI',
  },
  {
    id: 196,
    value: 'SB',
    name: 'Solomon Islands - SB',
  },
  {
    id: 197,
    value: 'SO',
    name: 'Somalia - SO',
  },
  {
    id: 198,
    value: 'ZA',
    name: 'South Africa - ZA',
  },
  {
    id: 199,
    value: 'KR',
    name: 'South Korea - KR',
  },
  {
    id: 200,
    value: 'SS',
    name: 'South Sudan - SS',
  },
  {
    id: 201,
    value: 'ES',
    name: 'Spain - ES',
  },
  {
    id: 202,
    value: 'LK',
    name: 'Sri Lanka - LK',
  },
  {
    id: 203,
    value: 'SD',
    name: 'Sudan - SD',
  },
  {
    id: 204,
    value: 'SR',
    name: 'Suriname - SR',
  },
  {
    id: 205,
    value: 'SJ',
    name: 'Svalbard and Jan Mayen - SJ',
  },
  {
    id: 206,
    value: 'SZ',
    name: 'Swaziland - SZ',
  },
  {
    id: 207,
    value: 'SE',
    name: 'Sweden - SE',
  },
  {
    id: 208,
    value: 'CH',
    name: 'Switzerland - CH',
  },
  {
    id: 209,
    value: 'SY',
    name: 'Syria - SY',
  },
  {
    id: 210,
    value: 'TW',
    name: 'Taiwan - TW',
  },
  {
    id: 211,
    value: 'TJ',
    name: 'Tajikistan - TJ',
  },
  {
    id: 212,
    value: 'TZ',
    name: 'Tanzania - TZ',
  },
  {
    id: 213,
    value: 'TH',
    name: 'Thailand - TH',
  },
  {
    id: 214,
    value: 'TG',
    name: 'Togo - TG',
  },
  {
    id: 215,
    value: 'TK',
    name: 'Tokelau - TK',
  },
  {
    id: 216,
    value: 'TO',
    name: 'Tonga - TO',
  },
  {
    id: 217,
    value: 'TT',
    name: 'Trinidad and Tobago - TT',
  },
  {
    id: 218,
    value: 'TN',
    name: 'Tunisia - TN',
  },
  {
    id: 219,
    value: 'TR',
    name: 'Turkey - TR',
  },
  {
    id: 220,
    value: 'TM',
    name: 'Turkmenistan - TM',
  },
  {
    id: 221,
    value: 'TC',
    name: 'Turks and Caicos Islands - TC',
  },
  {
    id: 222,
    value: 'TV',
    name: 'Tuvalu - TV',
  },
  {
    id: 223,
    value: 'VI',
    name: 'U.S. Virgin Islands - VI',
  },
  {
    id: 224,
    value: 'UG',
    name: 'Uganda - UG',
  },
  {
    id: 225,
    value: 'UA',
    name: 'Ukraine - UA',
  },
  {
    id: 226,
    value: 'AE',
    name: 'United Arab Emirates - AE',
  },
  {
    id: 227,
    value: 'GB',
    name: 'United Kingdom - GB',
  },
  {
    id: 228,
    value: 'US',
    name: 'United States - US',
  },
  {
    id: 229,
    value: 'UY',
    name: 'Uruguay - UY',
  },
  {
    id: 230,
    value: 'UZ',
    name: 'Uzbekistan - UZ',
  },
  {
    id: 231,
    value: 'VU',
    name: 'Vanuatu - VU',
  },
  {
    id: 232,
    value: 'VA',
    name: 'Vatican - VA',
  },
  {
    id: 233,
    value: 'VE',
    name: 'Venezuela - VE',
  },
  {
    id: 234,
    value: 'VN',
    name: 'Vietnam - VN',
  },
  {
    id: 235,
    value: 'WF',
    name: 'Wallis and Futuna - WF',
  },
  {
    id: 236,
    value: 'EH',
    name: 'Western Sahara - EH',
  },
  {
    id: 237,
    value: 'YE',
    name: 'Yemen - YE',
  },
  {
    id: 238,
    value: 'ZM',
    name: 'Zambia - ZM',
  },
  {
    id: 239,
    value: 'ZW',
    name: 'Zimbabwe - ZW',
  },
];
