import './style.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import Table from 'components/v2/dataDisplay/Table';
import { getBetweenTwoDate, randomString } from 'utils/Common';
import lazyGetAwsListAccesskeys, { IAwsListAccesskeysVariables } from 'graphql/queries/getAwsListAccesskeys';
import lazyGetAwsAccessKeyLastUsed, { IAwsAccessKeyLastUsedVariables } from 'graphql/queries/getAwsAccessKeyLastUsed';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';

const AccessKeys = (props: any) => {
  const { data = {}, cloudId, region } = props;
  const { userName } = data;

  const [isLoading, setIsLoading] = useState(false);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [getAwsListAccesskeys] = lazyGetAwsListAccesskeys();
  const [getAwsAccessKeyLastUsed] = lazyGetAwsAccessKeyLastUsed();

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Description',
        field: 'description',
        sort: true,
      },
      {
        label: 'Status',
        field: 'status',
        sort: true,
      },
      {
        label: 'Last used',
        field: 'lastUsed',
        sort: true,
      },
      {
        label: 'Created',
        field: 'created',
        sort: true,
      },
      {
        label: 'Last used region',
        field: 'lastUsedRegion',
        sort: true,
      },
      {
        label: 'Last used service',
        field: 'lastUsedService',
        sort: true,
      },
    ];
  }, []);

  const handleGetAwsListAccesskeys = (userName: string) => {
    const requestVariable: IAwsListAccesskeysVariables = {
      cloudId,
      region,
      request: {
        userName,
      },
    };

    const combinedVariable = variableCombineNextToken(requestVariable);
    return getAwsListAccesskeys({ variables: combinedVariable });
  };

  const handleGetAwsAccessKeyLastUsed = (accessKeyId: string) => {
    const requestVariable: IAwsAccessKeyLastUsedVariables = {
      cloudId,
      region,
      request: {
        accessKeyId,
      },
    };

    const combinedVariable = variableCombineNextToken(requestVariable);
    return getAwsAccessKeyLastUsed({ variables: combinedVariable });
  };

  const fetchData = useCallback(async () => {
    try {
      if (!userName) return;

      setIsLoading(true);
      const { data: listAccesskeys } = await handleGetAwsListAccesskeys(userName);

      if (!listAccesskeys?.getAwsListAccesskeys?.data?.[0]?.accessKeyMetadata?.length) {
        setIsLoading(false);
        return;
      }

      const result = await Promise.all(
        listAccesskeys.getAwsListAccesskeys.data[0].accessKeyMetadata.map(({ accessKeyId }: { accessKeyId: string }) =>
          handleGetAwsAccessKeyLastUsed(accessKeyId),
        ),
      );

      const totalResult = listAccesskeys.getAwsListAccesskeys.data[0].accessKeyMetadata.map((e, index: number) => ({
        ...e,
        id: randomString(),
        description: e.userName,
        status: e.status,
        lastUsed: getBetweenTwoDate(
          result?.[index]?.data?.getAwsAccessKeyLastUsed?.data?.[0]?.accessKeyLastUsed?.lastUsedDate,
        ),
        created: getBetweenTwoDate(e.createDate),
        lastUsedRegion: result?.[index]?.data?.getAwsAccessKeyLastUsed?.data?.[0]?.accessKeyLastUsed?.region,
        lastUsedService: result?.[index]?.data?.getAwsAccessKeyLastUsed?.data?.[0]?.accessKeyLastUsed?.serviceName,
      }));

      setMainTblRows(totalResult);
      setMainTblTotal({
        totalPage: Math.ceil(totalResult.length / tablePagination.itemPerPage),
        totalElement: totalResult.length,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [tablePagination, userName, cloudId, region, mainTblRows]);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [userName, mainTblTotal, tablePagination]);

  useEffect(() => {
    fetchData();
  }, [userName]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>Access keys</p>

        <p>
          Use access keys to send programmatic calls to AWS from the AWS CLI, AWS Tools for Powershell, AWS SDKs, or
          direct AWS API calls. You can have a maximum of two access keys (active or inactive) at a time.
        </p>
      </div>
      {isLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <div className="detail-info-content">
          {!mainRowsCurrentPage.length && !isLoading ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <Table
                rows={mainRowsCurrentPage}
                columns={columns}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
                isLoading={isLoading}
                horizontalScrollable={true}
              />

              {mainRowsCurrentPage?.length && !isLoading ? (
                <div className="fleet-instance pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{mainTblTotal.totalElement}</span>
                  </p>

                  <TableManagePagination
                    ableFetchMore={false}
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page => updateTablePagination('currentPage', page)}
                    totalPage={mainTblTotal.totalPage}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AccessKeys;
