import { IMgdTabProps } from 'layouts/v3/MgdTabLayout/types';

export const LIST_UP_TAB_LIST: IMgdTabProps[] = [
  {id: 'awsManagedKey',name: 'AWS Key', title: 'AWS Key'},
  {id: 'customerKey',name: 'Customer key', title: 'Customer key'},
  {id: 'secret',name: 'Secret', title: 'Secrets'}
];

export enum PageNodeEnum {
  MGD_LIST = 'MGD_LIST',
  MGD_CREATE = 'MGD_CREATE',
  MGD_DETAIL = 'MGD_DETAIL',
}
