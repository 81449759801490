import { useEffect, useRef, useState } from 'react';
import ManageHeader from 'components/v2/ManageHeader';
import ManageTab from 'components/v2/ManageTab';
import RedashComp from 'components/v4/RedashComp';
import './index.scss';

// https://20230701-dev-dashboard.chewknow.com/dashboards?order=-created_at&page=1&page_size=20&curidy=true

// https://20230701-dev-dashboard.chewknow.com/queries?order=-created_at&page=1&page_size=20&curidy=true

// https://20230701-dev-dashboard.chewknow.com/data_sources&curidy=true

const MENU = [
  {
    label: 'Dashboard',
    value: 'system-dashboard'
  },
  {
    label: 'Query',
    value: 'system-query'
  },
  {
    label: 'Datasource',
    value: 'system-datasource'
  }
]

/**
 * <>lg 운영자만 접근 가능한 대시보드 관리 페이지</>
 */
const ManageDashboard = () => {
  const frameRef = useRef<HTMLIFrameElement | null>(null);
  const [tab, setTab] = useState('system-dashboard');

  const changeTab = (value: string) => {
    if(frameRef.current) {
      console.log('key: ', value);
      frameRef.current.contentWindow?.postMessage(JSON.stringify({
        type: 'route', 
        key: value
      }), '*');

      setTab(value);
    }
  }

  useEffect(() => {
    changeTab('system-dashboard');
  },[]);

  return (
    <div id="manage-dashboard">
      <ManageHeader 
        title='Dashboard'
      />
      <ManageTab
        selected={tab}
        menu={MENU}
        convertTab={(value) => changeTab(value)}
      />
      <div className="dashboard-content">
        <RedashComp iframeRef={frameRef} src={`${process.env.REACT_APP_NEMO_DASHBOARD}/dashboards?order=-created_at&page=1&page_size=20&curidy=true`}/>
      </div>
    </div>
  );
}

export default ManageDashboard;