import './index.scss';
import { useEffect, useState } from 'react';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';
import Details from '../Common/Components/Details';
import RouteTable from '../Common/Components/RouteTable';
import NetworkACL from '../Common/Components/NetworkACL';
import CIDRReservations from '../Common/Components/CIDRReservations';
import Sharing from '../Common/Components/Sharing';
import Tags from '../Common/Components/Tags';

const detailTabs: IMgdTabProps[] = [
  { id: 'details', name: 'Details', title: 'Details' },
  { id: 'routeTable', name: 'Route table', title: 'Route table' },
  { id: 'networkACLInbound', name: 'Network ACL Inbound', title: 'Network ACL Inbound' },
  { id: 'networkACLOutbound', name: 'Network ACL Outbound', title: 'Network ACL Outbound' },
  { id: 'cidrReservations', name: 'CIDR reservations', title: 'CIDR reservations' },
  { id: 'sharing', name: 'Sharing', title: 'Sharing' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

const SubnetSummary = ({ cloudId, region, data }: any) => {
  const [tabSelected, setTabSelected] = useState(detailTabs[0]);

  useEffect(() => {
    setTabSelected(detailTabs[0]);
  }, [data?.id]);

  const renderTab = () => {
    switch (tabSelected.id) {
      case detailTabs[0].id:
        return <Details data={data} />;

      case detailTabs[1].id:
        return <RouteTable cloudId={cloudId} region={region} data={data} />;

      case detailTabs[2].id:
        return <NetworkACL cloudId={cloudId} region={region} data={data} currentTab={detailTabs[2]} />;

      case detailTabs[3].id:
        return <NetworkACL cloudId={cloudId} region={region} data={data} currentTab={detailTabs[3]} />;

      case detailTabs[4].id:
        return <CIDRReservations cloudId={cloudId} region={region} data={data} />;

      case detailTabs[5].id:
        return <Sharing cloudId={cloudId} region={region} data={data} />;

      case detailTabs[6].id:
        return <Tags data={data} />;

      default:
        return null;
    }
  };

  return data?.id ? (
    <div className="box-summary">
      <div className="box-summary-id">{data?.id}</div>
      
      <div className="detail-tab flex a-center">
        {detailTabs.map(tab => {
          return (
            <button
              className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
              key={tab.id}
              data-tab={tab.id}
              onClick={e => setTabSelected(tab)}
            >
              <p>{tab.name}</p>
            </button>
          );
        })}
      </div>

      <div className="content-tab">{renderTab()}</div>
    </div>
  ) : (
    <div className="detail-data">
      <p className="empty-page">Select a item</p>
    </div>
  );
};

export default SubnetSummary;
