import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import InputAtom from 'components/v2/atoms/InputAtom';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import { handleLinkClicked, variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { FilterPropsType } from 'pages/v2/Organ/Management/EC2/components/Image/types';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { handleFormatText } from 'utils/Common';
import lazyGetAllAwsVpc from 'graphql/queries/getAwsAllVPC';
import lazyGetAwsRouteTables from 'graphql/queries/getAwsRouteTables';
import lazyGetAwsNetworkAcls from 'graphql/queries/getAwsNetworkAcls';
import { DropdownListDataType } from 'components/Dropdown/types';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import { NETWORK_TAB_LIST } from '../../../configs';
import { useNavigate } from 'react-router-dom';
import { networkACLFilterDropdown, routeTableFilterDropdown, vpcFilterDropdown } from '../../../Constants';

const VPC = (props: any) => {
  const { cloudId, region, currentRegion, currentRouteState, setCurrentRouteState, relatedCloudSelected, setDetailItem, setIsDetailPage } =
    props;

  const navigate = useNavigate();

  const [getAllAwsVpc] = lazyGetAllAwsVpc();
  const [getAwsRouteTables] = lazyGetAwsRouteTables();
  const [getAwsNetworkAcls] = lazyGetAwsNetworkAcls();

  const [isLoading, setIsLoading] = useState(false);
  const [nextToken, setNextToken] = useState<string>('');
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
 
  const currentFilter = currentRouteState && routeTableFilterDropdown.find(e => e.value === currentRouteState.key);
  const [mainFilterValue, setMainFilterValue] = useState<DropdownListDataType>(
    currentFilter || vpcFilterDropdown[0],
  );
  const [searchValue, setSearchValue] = useState<FilterPropsType>({
    name: mainFilterValue.value as string,
    value: currentRouteState?.value || '',
  });
  const [scopeNFilterVariable, setScopeNFilterVariable] = useState<FilterPropsType>();

  useEffect(() => {
    return () => {
      if (!currentRouteState) return;

      setCurrentRouteState(null);
    };
  }, [currentRouteState]);

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Name',
        field: 'name',
        sort: true,
        renderCell: (row: RowType) => <>{handleFormatText(row?.name || '-')}</>,
      },
      {
        label: 'VPC ID',
        field: 'vpcId',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <div
                className="link"
                onClick={e => {
                  e.stopPropagation();
                  setDetailItem(row);
                  setIsDetailPage();
                }}
              >
                {handleFormatText(row?.vpcId)}
              </div>
            }
          </>
        ),
      },
      {
        label: 'Status',
        field: 'state',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <div className="status">
                <p>{handleFormatText(row?.state)}</p>
              </div>
            }
          </>
        ),
      },
      { label: 'IPv4 CIDR', field: 'cidrBlock', sort: true },
      {
        label: 'IPv6 CIDR',
        field: 'ipv6CidrBlockAssociationSet.ipv6CidrBlock',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {handleFormatText(
              String(
                row?.ipv6CidrBlockAssociationSet?.ipv6CidrBlock ? row.ipv6CidrBlockAssociationSet.ipv6CidrBlock : '-',
              ),
            )}
          </>
        ),
      },
      { label: 'DHCP option set', field: 'dhcpOptionsId', sort: true },
      {
        label: 'Main route table',
        field: 'mainRouteTableId',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <div
                className="link"
                onClick={e => {
                  handleLinkClicked({
                    navigate,
                    link: '/organ/1/manage/network',
                    type: ManagementTypeEnum.NETWORK,
                    tabId: NETWORK_TAB_LIST[1].id,
                    key: routeTableFilterDropdown[0].value.toString(),
                    value: row.mainRouteTableId,
                  });
                }}
              >
                {handleFormatText(row.mainRouteTableId)}
              </div>
            }
          </>
        ),
      },
      {
        label: 'Main network ACL',
        field: 'mainNetAclId',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <div
                className="link"
                onClick={e => {
                  handleLinkClicked({
                    navigate,
                    link: '/organ/1/manage/network',
                    type: ManagementTypeEnum.NETWORK,
                    tabId: NETWORK_TAB_LIST[6].id,
                    key: networkACLFilterDropdown[0].value.toString(),
                    value: row.mainNetAclId,
                  });
                }}
              >
                {handleFormatText(row.mainNetAclId)}
              </div>
            }
          </>
        ),
      },
      {
        label: 'Tenancy',
        field: 'instanceTenancy',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {handleFormatText(
              row?.instanceTenancy && row?.instanceTenancy.charAt(0).toUpperCase() + row?.instanceTenancy.slice(1),
            )}
          </>
        ),
      },
      {
        label: 'Default VPC',
        field: 'isDefault',
        renderCell: (row: RowType) => <>{row.isDefault === false ? 'No' : 'Yes'}</>,
        sort: true,
      },
      { label: 'Owner ID', field: 'ownerId', sort: true },
    ];
  }, [mainTblRows]);

  const getVariableData = useCallback(() => {
    if (searchValue?.value) {
      return {
        cloudId: relatedCloudSelected.value,
        region: currentRegion.value as string,
        request: {
          maxResults: mainTablePagination.limit,
          filters: {
            name: searchValue?.name || '',
            values: [searchValue?.value.toLocaleLowerCase() || ''],
          },
        },
      };
    }

    return {
      cloudId: relatedCloudSelected.value,
      region: currentRegion.value as string,
      request: {
        maxResults: mainTablePagination.limit,
      },
    };
  }, [currentRegion, currentRouteState, relatedCloudSelected, searchValue]);

  const getVpcMainDataRows = useCallback(
    async (nextToken?: string) => {
      try {
        setIsLoading(true);
        const { data: awsVpcResponse } = await getAllAwsVpc({
          variables: variableCombineNextToken(getVariableData(), nextToken),
        });

        const awsVpcData = awsVpcResponse?.getAwsAllVPC;
        if (awsVpcData?.data && awsVpcData.data?.length && awsVpcData.data.length > 0) {
          let awsVpcs: RowType[] = [];
          const awsVpcIds: string[] = [];
          awsVpcData.data.map(vpc => {
            awsVpcIds.push(vpc.vpcId);
            awsVpcs.push({
              ...vpc,
              id: vpc.vpcId,
              name: vpc?.tags?.find(t => t?.key == 'Name')?.value,
              enableDnsHostnames: true,
              enableDnsSupport: false,
              enableNetworkAddressUsageMetrics: false,
              firewallRuleAssociations: null,
            });
          });
          if (awsVpcs.length > 0 && awsVpcIds.length > 0) {
            const awsVariable = {
              cloudId,
              region,
              request: {
                maxResults: 50,
                filters: {
                  name: 'vpc-id',
                  values: awsVpcIds,
                },
              },
            };

            const { data: awsRouteTableData } = await getAwsRouteTables({ variables: awsVariable });

            if (awsRouteTableData?.getAwsRouteTables?.data?.[0]?.routeTables) {
              const awsRouteTableValues = awsRouteTableData?.getAwsRouteTables?.data?.[0]?.routeTables;
              awsVpcs = awsVpcs.map(vpc => {
                const routeTables = {
                  ...awsRouteTableValues.filter(
                    (routeTable: any) =>
                      routeTable.vpcId == vpc.vpcId &&
                      routeTable?.associations.find((association: any) => association.main === true),
                  )[0],
                };

                return {
                  ...vpc,
                  routeTables,
                  mainRouteTableId: String(routeTables?.associations[0]?.routeTableId || '-'),
                };
              });
            }

            const { data: awsNetworkAclsData } = await getAwsNetworkAcls({ variables: awsVariable });

            awsVpcs = awsVpcs.map(vpc => {
              const networkAcls = awsNetworkAclsData?.getAwsNetworkAcls?.data?.[0]?.networkAcls.filter(
                (netAcl: any) => netAcl.vpcId == vpc.vpcId,
              );
              return {
                ...vpc,
                networkAcls,
                mainNetAclId: String(networkAcls?.length ? networkAcls[0]?.networkAclId : '-'),
              };
            });

            let allAwsVpcs: RowType[] = [];
            if (nextToken) {
              allAwsVpcs = [...mainTblRows, ...awsVpcs];
            } else {
              allAwsVpcs = awsVpcs;
            }
            setNextToken(awsVpcData?.nextToken as string);
            setMainTblRows(allAwsVpcs);
            setMainTblTotal({
              totalPage: Math.ceil(allAwsVpcs.length / mainTablePagination.itemPerPage),
              totalElement: allAwsVpcs.length,
            });
            setIsLoading(false);
          }
        } else {
          setMainTblRows([]);
          setNextToken('');
          setIsLoading(false);
        }
      } catch {
        setIsLoading(false);
      }
    },
    [cloudId, region, mainTblRows, mainTablePagination, getVariableData, searchValue],
  );

  const mainRowsCurrentPage = useMemo((): RowType[] => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!nextToken) {
      getVpcMainDataRows(nextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    setMainTblTotal({
      totalPage: Math.ceil(mainTblRows.length / mainTablePagination.itemPerPage),
      totalElement: mainTblRows.length,
    });

    return orderAlphabetical(mainTblRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, scopeNFilterVariable, mainTablePagination, nextToken]);

  useEffect(() => {
    getVpcMainDataRows();
  }, [cloudId]);

  const handleSearch = useCallback(() => {
    if (searchValue?.value === scopeNFilterVariable?.value && searchValue.name === scopeNFilterVariable?.name) return;

    updateTablePagination('currentPage', 1);
    setScopeNFilterVariable(searchValue);
    getVpcMainDataRows();
  }, [searchValue, scopeNFilterVariable]);

  const handleSelectItem = useCallback(
    (id: string) => {
      const rowSelected = mainTblRows.find(row => row.id === id);

      if (!rowSelected) return;

      setDetailItem(rowSelected);
    },
    [mainTblRows],
  );

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>Your VPCs</p>
        </div>

        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={vpcFilterDropdown}
            value={mainFilterValue}
            handleClick={val => {
              setMainFilterValue(val);
              setSearchValue({ name: val?.value?.toString(), value: searchValue.value });
            }}
          />

          <InputAtom
            placeholder=""
            type={'text'}
            value={searchValue.value}
            defaultValue={''}
            onChangeValue={e => setSearchValue({ name: mainFilterValue?.value?.toString(), value: e })}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={handleSearch}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSearch();
              }
            }}
          />
          <button className="action-btn" onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>

      {!mainRowsCurrentPage?.length && !isLoading ? (
        <div className="data-grid-wrap">
          <p className="empty-row">Empty</p>
        </div>
      ) : (
        <div className="data-grid-wrap">
          <Table
            rows={mainRowsCurrentPage}
            columns={columns}
            reportCheckedList={() => {}}
            reportSelected={id => handleSelectItem(id)}
            sortOption={{
              target: mainTablePagination.target,
              direction: mainTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateTablePagination('target', target);
                updateTablePagination('direction', dir);
              },
            }}
            isLoading={isLoading}
            horizontalScrollable
          />

          {!!mainRowsCurrentPage?.length && !isLoading && (
            <div className="pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{mainTblTotal.totalElement}</span>
              </p>
              <TableManagePagination
                ableFetchMore={!!nextToken}
                currentPage={mainTablePagination.currentPage}
                updateCurrentPage={page =>
                  setMainTablePagination(prev => ({
                    ...prev,
                    ['currentPage']: page,
                  }))
                }
                totalPage={mainTblTotal.totalPage}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default VPC;
