import lazyGetAwsDescribeSecret, { IAwsDescribeSecretVariables } from 'graphql/queries/getAwsDescribeSecret';
import { SecretDetailPropsType } from './types';
import PageDetailTitle from '../../../components/PageDetailTitle';
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { AwsDescribeSecretType } from 'graphql/types/AwsDescribeSecret';
import { DETAIL_KEYS, DETAIL_TAB_LIST, DETAIL_TEXT_DES } from './configs';
import { IMgdDetailKeyValueProps, IMgdTabProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import lazyGetAwsListAliases from 'graphql/queries/getAwsListAliases';
import DetailTab from '../../../components/DetailTab';
import Overview from './Tabs/Overview';
import Rotations from './Tabs/Rotations';
import Replication from './Tabs/Replication';
import { ColumnType, RowType } from '@Types/v2/Table';
import TagTab from '../../../components/TagTab';
import Version from './Tabs/Version';

const SecretDetail = (props: SecretDetailPropsType) => {
  const { cloudId, region, secret, pageBackClick } = props;

  const [getAwsDescribeSecret, { loading: describeSecretLoading }] = lazyGetAwsDescribeSecret();
  const [getAwsListAliases, {loading: listAliasLoading}] = lazyGetAwsListAliases();

  const [detailTabSelected, setDetailTabSelected] = useState<IMgdTabProps>(DETAIL_TAB_LIST[0]);
  const [describeSecret, setDesribeSecret] = useState<AwsDescribeSecretType>();
  const [detailsOrderedData, setDetailsOrderedData] = useState<IMgdDetailKeyValueProps[]>([]);

  const isApiLoading = useMemo((): boolean => {
    return describeSecretLoading || listAliasLoading;
  }, [describeSecretLoading, listAliasLoading]);

  const getDetailContent = useCallback(async (describeSecret: AwsDescribeSecretType) => {
    const detailsData: IMgdDetailKeyValueProps[] = [];
    const orderedDetailsData: IMgdDetailKeyValueProps[] = [];
    for (const [key, value] of Object.entries(describeSecret)) {
      if (DETAIL_KEYS.indexOf(key) > -1) {
        let desValue = '-';
        if (key === DETAIL_KEYS[0]) {
          if (value) {
            const awsAliasesVariable = {
              cloudId: cloudId,
              region: region,
              request: { keyId: value as string },
            };
            const aliasName = await getAwsListAliases({ variables: awsAliasesVariable }).then(
              ({ data: alias }) => {
                return alias?.getAwsListAliases?.data?.[0]?.aliases?.[0]?.aliasName;
              },
            );
            desValue = aliasName || '-';
          } else {
            desValue = 'aws/secretsmanager';
          }
        }
        const textDes = DETAIL_TEXT_DES.find(text => text.id == key);
        if (typeof value === 'string') {
          desValue = value.toString();
        }

        if (textDes) {
          detailsData.push({
            id: key,
            type: textDes.type as TextTypeEnum,
            title: textDes.value,
            description: desValue,
          });
        }
      }
    }
    DETAIL_KEYS.map(key => {
      const dataByKey = detailsData.find(data => data.id == key);
      if (dataByKey) orderedDetailsData.push(dataByKey);
    });
    setDetailsOrderedData(orderedDetailsData);
  }, [cloudId, region]);

  const reqVariable = useMemo((): IAwsDescribeSecretVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: { secretId: secret.arn || secret.name },
    };
  }, [cloudId, region, secret]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const tagRows = useMemo((): RowType[] => {
    return secret?.tags ?? [];
  }, [secret]);

  const fetchDescribeSecret = useCallback(() => {
    getAwsDescribeSecret({ variables: reqVariable }).then(({ data: responseData }) => {
      const secretDetail = responseData?.getAwsDescribeSecret?.data?.[0];
      if (secretDetail) {
        setDesribeSecret(secretDetail);
        getDetailContent(secretDetail);
      }
    });
  }, [reqVariable, getDetailContent]);

  useEffect(() => {
    fetchDescribeSecret();
  }, [secret]);

  const detailContentNode = useMemo((): ReactNode => {
    switch (detailTabSelected) {
      case DETAIL_TAB_LIST[0]:
        return <Overview />;
      case DETAIL_TAB_LIST[1]:
        return <Rotations data={secret}  />
      case DETAIL_TAB_LIST[2]:
        return <Version cloudId={cloudId} region={region} secret={secret} />;
      case DETAIL_TAB_LIST[3]:
        return <Replication />
      case DETAIL_TAB_LIST[4]:
        return <TagTab columns={tagColumns} rows={tagRows} title={'Tags'} />
      default:
        return null;
    }
  }, [detailTabSelected, cloudId, region, tagColumns, tagRows, secret]);

  return (
    <div className="ec2-detail">
      <PageDetailTitle title={`${secret?.name}`} pageBackClick={pageBackClick} />

      {isApiLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <Fragment>
          {describeSecret && (
            <Fragment>
              <DetailTab title={'Secret details'} data={detailsOrderedData} isApiLoading={false} />

              <div className="tab-container">
                <div className="detail-tab flex a-center">
                  {DETAIL_TAB_LIST.map(tab => {
                    return (
                      <button
                        className={`detail-tab-items ${detailTabSelected?.id === tab.id && 'active'}`}
                        key={tab.id}
                        data-tab={tab.id}
                        onClick={e => setDetailTabSelected(tab)}
                      >
                        <p>{tab.name}</p>
                      </button>
                    );
                  })}
                </div>

                <div className="content-tab">{detailContentNode}</div>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default SecretDetail;
