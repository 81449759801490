import './index.scss';

import { ColumnType, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import { timeToKr } from 'utils/timeFormatter';

interface IDeleteSubCategoryModalProps extends IBaseModalProps {
  data: RowType[]
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteSubCategoryModal = ({  
  data,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteSubCategoryModalProps) => {
  const column:ColumnType[] = [
  { 
    label: 'Name',
    field: 'name'
  },
  {
    label: 'Unique delimiter',
    field: 'maskCode'
  },
  {
    label: 'Document title',
    field: 'title'
  },
  {
    label: 'Updated at',
    field: 'modifiedAt',
    renderCell: (row:RowType) => <>{!row.modifiedAt ? '-' : timeToKr(row.modifiedAt)}</>
  }]
  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-subCategory-modal">
        <div className="content">
        <Table columns={column} rows={data}/>
        </div>
        <div className="btns flex j-end">
          <button 
            className="big-sub-btn flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            Cancel
          </button>
          <button 
            className="big-main-btn flex j-center a-center" 
            onClick={onDelete}
          >
            Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteSubCategoryModal;
