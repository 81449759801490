import lazyGetAwsAllListSecretsPage, { IAwsAllListSecretsVariables } from "graphql/queries/getAwsAllListSecrets";
import { SecretListPropsType } from "../types";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ColumnType, OrderDirection, RowType } from "@Types/v2/Table";
import { AwsSecretListEntryType } from "graphql/types/AwsAllListSecrets";
import { SECRET_FILTER_DROPDOWN } from "./configs";
import { DropdownListDataType } from "components/Dropdown/types";
import { FilterPropsType } from "../../../EC2/components/Image/types";
import { IMgdTablePaginationProps, IMgdTotalPageProps } from "layouts/v3/MgdLayout";
import { getFormatedDateWithTimezone, handleFormatText } from "utils/Common";
import { orderAlphabetical } from "../../../Utils/Sorting";
import { variableCombineNextToken } from "../../../Utils";
import DropdownAtom from "components/v2/atoms/DropdownAtom";
import InputAtom from "components/v2/atoms/InputAtom";
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import Table from "components/v2/dataDisplay/Table";
import TableManagePagination from "components/v2/dataDisplay/TableManagePagination";

const SecretList = (props: SecretListPropsType) => {
  const { cloudId, region, tabInformation, onSecretSelected, onSecretIdClicked, currentRouteState, setCurrentRouteState, onNavigateCreation } = props;

  // API
  const [getAwsAllListSecrets, {loading: allListSecretLoading}] = lazyGetAwsAllListSecretsPage();
  
  // State
  const [mainTblTotalRows, setMainTblTotalRows] = useState<RowType[]>([]);
  const [mainTblTotalSearchRows, setMainTblTotalSearchRows] = useState<RowType[]>([]);
  const [mainNextToken, setMainNextToken] = useState<string>('');
  const [secretsSelected, setSecretsSelected] = useState<AwsSecretListEntryType[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const currentFilter = currentRouteState && SECRET_FILTER_DROPDOWN.find(e => e.value === currentRouteState.key);
  const [awsKeyFilterDropdownValue, setAwsKeyFilterDropdownValue] = useState<DropdownListDataType>(
    currentFilter || SECRET_FILTER_DROPDOWN[0],
  );
  const [awsKeyFilterInputValue, setAwsKeyFilterInputValue] = useState<FilterPropsType>({
    name: awsKeyFilterDropdownValue.value as string,
    value: currentRouteState?.value || '',
  });
  const [awsKeyFilter, setAwsKeyFilter] = useState<FilterPropsType | undefined>(currentRouteState?.value ? awsKeyFilterInputValue : undefined);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });

  const mainTblColumns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Secret name',
        field: 'name',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <span
                className="link"
                onClick={e => {
                  e.stopPropagation();
                  onSecretIdClicked();
                  onSecretSelected(row as AwsSecretListEntryType)
                }}
              >
                {handleFormatText(row?.name)}
              </span>
            }
          </>
        ),
      },
      { label: 'Description', field: 'description', sort: true },
      { label: 'Last retrieved (UTC)', field: 'lastAccessedDate', sort: true },
    ];
  }, []);

  const handleSearchFunction = useCallback(() => {
    if (awsKeyFilterInputValue.value !== '') {
      setAwsKeyFilter({ name: awsKeyFilterInputValue.name, value: awsKeyFilterInputValue.value });
      setMainTblTotalSearchRows(mainTblTotalRows);
      const searchedRows = mainTblTotalRows?.filter(row => row[awsKeyFilterInputValue.name] === awsKeyFilterInputValue.value);
      setMainTblTotalRows(searchedRows);
      setMainTblTotal({
        totalPage: Math.ceil(searchedRows.length / mainTablePagination.itemPerPage),
        totalElement: searchedRows.length,
      });
    } else {
      setAwsKeyFilter(undefined);
      setMainTblTotalRows(mainTblTotalSearchRows);
      setMainTblTotalSearchRows([]);
      setMainTblTotal({
        totalPage: Math.ceil(mainTblTotalSearchRows.length / mainTablePagination.itemPerPage),
        totalElement: mainTblTotalSearchRows.length,
      });
    }
  }, [awsKeyFilterInputValue, mainTblTotalSearchRows, mainTblTotalRows]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setMainTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      fetchAwsAllSecrets(mainNextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    let totalRows = mainTblTotalRows;
    totalRows = mainTblTotalRows?.filter(row => {
      return row?.[awsKeyFilter?.name ?? '']?.includes(awsKeyFilter?.value || '');
    });

    return orderAlphabetical(mainTblTotalRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotalRows, mainTblTotal, mainTablePagination, mainNextToken, awsKeyFilter]);

  const awsSecretRequestVariables = useMemo((): IAwsAllListSecretsVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: {
        maxResults: mainTablePagination.limit
      },
    };
  }, [cloudId, region, mainTablePagination]);

  const fetchAwsAllSecrets = useCallback(async (nextToken?: string, isInitial?: boolean) => {
    const combinedVariable = variableCombineNextToken(awsSecretRequestVariables, nextToken);
    const data = await getAwsAllListSecrets({ variables: combinedVariable }).then(({ data: responseData }) => {
      return responseData;
    });
    const awsAllListSecrets = data?.getAwsAllListSecrets?.data ?? [];
    const awsSecretRowData: RowType[] = [];

    if (awsAllListSecrets.length > 0) {
      awsAllListSecrets?.map(content => {
        const lastAccesseFormated = getFormatedDateWithTimezone(content?.lastAccessedDate);
        awsSecretRowData.push({
          ...content,
          lastAccessedDate: lastAccesseFormated,
          id: content?.arn,
        });
      });

      let allSecrets: RowType[] = [];
      if (isInitial) {
        allSecrets = awsSecretRowData;
      } else {
        allSecrets = [...mainTblTotalRows, ...awsSecretRowData];
      }

      setMainTblTotalRows(allSecrets);
      if (allSecrets.length > mainTablePagination.limit) {
        setMainNextToken(data?.getAwsAllListSecrets?.nextToken as string);
      } else {
        setMainNextToken('');
      }
      setMainTblTotal({
        totalPage: Math.ceil(allSecrets.length / mainTablePagination.itemPerPage),
        totalElement: allSecrets.length,
      });
    }
  }, [awsSecretRequestVariables]);

  useEffect(() => {
    return () => {
      if (!currentRouteState) return;

      setCurrentRouteState(null);
    };
  }, [currentRouteState]);

  const handleCustomerKeySelected = useCallback((listId: string[]) => {
    const awsSecrets: AwsSecretListEntryType[] = [];
    listId.map(rowId => {
      const rowSelected = mainTblTotalRows.find(row => row.id === rowId);
      if (rowSelected) {
        awsSecrets.push(rowSelected as AwsSecretListEntryType);
      }
    });
    setSecretsSelected(awsSecrets);
  }, [mainTblTotalRows]);

  const handleMainRowSelected = useCallback(
    (rowId: string) => {
      const rowSelected = mainTblTotalRows.find(row => row.id === rowId);

      if (!rowSelected) return;
      handleCustomerKeySelected([rowId]);
      onSecretSelected(rowSelected as AwsSecretListEntryType);
    },
    [mainTblTotalRows],
  );

  // For initial data
  useEffect(() => {
    fetchAwsAllSecrets('', true);
  }, [awsSecretRequestVariables]);
  
  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>{tabInformation.title}</p>
        </div>
        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={SECRET_FILTER_DROPDOWN}
            value={awsKeyFilterDropdownValue}
            handleClick={val => {
              setAwsKeyFilterDropdownValue(val);
              setAwsKeyFilterInputValue(prev => ({ ...prev, name: val.value as string }));
            }}
          />
          <InputAtom
            type={'text'}
            value={awsKeyFilterInputValue.value}
            defaultValue={''}
            onChangeValue={str => setAwsKeyFilterInputValue(prev => ({ name: prev?.name, value: str }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {}}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSearchFunction();
              }
            }}
          />

          <button className="action-btn" onClick={handleSearchFunction}>
            Search
          </button>
          {/* <button className="action-btn" onClick={() => onNavigateCreation()}>
            Create
          </button>
          <button className="action-btn" onClick={() => setDeleteModalVisible(true)} disabled={deleteButtonDisabled}>
            Delete
          </button> */}
        </div>
      </div>
      <div className="data-grid-wrap">
        <Table
          rows={mainRowsCurrentPage}
          columns={mainTblColumns}
          reportCheckedList={listId => handleCustomerKeySelected(listId)}
          reportSelected={id => handleMainRowSelected(id)}
          sortOption={{
            target: mainTablePagination.target,
            direction: mainTablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {
              updateTablePagination('target', target);
              updateTablePagination('direction', dir);
            },
          }}
          isLoading={allListSecretLoading}
          horizontalScrollable={true}
        />
        {mainRowsCurrentPage && mainRowsCurrentPage.length > 0 && allListSecretLoading === false && (
          <div className="pagination-wrapper flex a-center">
            <p className="flex a-center">
              Total <span>{mainTblTotal.totalElement}</span>
            </p>
            <TableManagePagination
              ableFetchMore={!!mainNextToken}
              currentPage={mainTablePagination.currentPage}
              updateCurrentPage={page =>
                setMainTablePagination(prev => ({
                  ...prev,
                  ['currentPage']: page,
                }))
              }
              totalPage={mainTblTotal.totalPage}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default SecretList;