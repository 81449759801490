import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, SectionContainer, SectionTitle, StepContainer } from '../../../../WebACL/CreateWebACL/components';
import InputGroup from '../../../../WebACL/CreateWebACL/components/InputGroup';
import Dropdown from 'components/Dropdown';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { regionCodeFormatter } from 'utils/Formatter';
import { CLOUDFRONT_OPTION } from '../../../../Commons/Constant';

type DescribeRuleGroupPropsType = {
  creationData: any;
  handleValueChanged: (key: string, value: any) => void;
  errors: {
    [key: string]: string;
  };
};

const DescribeRuleGroup = (props: DescribeRuleGroupPropsType) => {
  const { creationData, handleValueChanged, errors } = props;
  const { name, description, cloudWatchMetricName, region } = creationData ?? {};
  const [regionList, setRegionList] = useState<Array<{ name: string; value: string }>>([]);
  const [isCloudWatchMetricNameModified, setCloudWatchMetricNameModified] = useState(false);

  const [getEnumTypeCode] = lazyGetEnumTypeCode();

  const getRegionList = useCallback(() => {
    getEnumTypeCode({ variables: { text: 'AwsRegionCode' } }).then(res => {
      const codes =
        res?.data?.getEnumTypeCode?.data?.map(code => ({
          name: regionCodeFormatter(code?.value) || code?.name,
          value: code?.value,
        })) ?? [];

      setRegionList([{ name: CLOUDFRONT_OPTION.name, value: CLOUDFRONT_OPTION.value }, ...codes]);
      handleValueChanged('region', { name: CLOUDFRONT_OPTION.name, value: CLOUDFRONT_OPTION.value });
    });
  }, [getEnumTypeCode]);

  useEffect(() => {
    getRegionList();
  }, [getRegionList]);

  const handleChangeName = useCallback(
    (value: string) => {
      handleValueChanged('name', value);

      if (!isCloudWatchMetricNameModified) {
        handleValueChanged('cloudWatchMetricName', value);
      }
    },
    [isCloudWatchMetricNameModified, handleValueChanged],
  );

  const handleChangeMetricName = useCallback(
    (value: string) => {
      handleValueChanged('cloudWatchMetricName', value);

      if (!isCloudWatchMetricNameModified) {
        setCloudWatchMetricNameModified(true);
      }
    },
    [isCloudWatchMetricNameModified, handleValueChanged],
  );

  return (
    <StepContainer
      title={'Describe rule group'}
      description="A rule group is a collection of rules that you can add to your web ACLs."
    >
      <SectionContainer title={'Rule group details'}>
        <InputGroup>
          <Input
            title={'Name'}
            placeholder="Enter name"
            bottomDescription="The name must have 1-128 characters. Valid characters A-Z, a-z, 0-9, - (hyphen), and _ (underscore)."
            value={name}
            onValueChanged={handleChangeName}
            isRequired
            error={errors?.name}
          />
          <Input
            title={'Description'}
            placeholder="Enter description"
            bottomDescription="The description can have 1-256 characters."
            value={description}
            onValueChanged={value => handleValueChanged('description', value)}
          />
          <Input
            title={'CloudWatch metric name'}
            placeholder="Enter CloudWatch metric name"
            bottomDescription="The name must have 1-128 characters. Valid characters A-Z, a-z, 0-9, - (hyphen), and _ (underscore)."
            value={cloudWatchMetricName}
            onValueChanged={handleChangeMetricName}
            isRequired
            error={errors?.cloudWatchMetricName}
          />
          <Dropdown
            title={'Region'}
            isRequired
            data={regionList}
            id="region"
            handleClick={value => handleValueChanged('region', value)}
            value={region}
            placeholder="Choose region"
          />
        </InputGroup>
      </SectionContainer>
    </StepContainer>
  );
};

export default DescribeRuleGroup;
