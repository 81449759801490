import './index.scss';

import { ColumnType, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import CurrentStatusCard from 'pages/v2/Organ/Report/components/CurrentStatusCard';
import { currentStatusFormatter } from 'utils/Formatter';

interface IDeleteReportModalProps extends IBaseModalProps {
  data: RowType[]
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteReportModal = ({  
  data,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteReportModalProps) => {
  const column:ColumnType[] = [{
    label: 'Name',
    field: 'name',
    sort: true
  },
  {
    label: 'Project',
    field: 'projectName',
    width: 120,
    sort: true
  },{
    label: 'Status',
    field: 'currentStatus',
    width: 120,
    sort: true,
    valueGetter: (row:RowType) => currentStatusFormatter(row.currentStatus),
    renderCell: (row:RowType) => <CurrentStatusCard status={row.currentStatus} />
  },
  {
    label: 'Updated by',
    field: 'modifiedByName',
    width: 120,
    sort: true
  },];
  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-report-modal">
        <div className="header">
        Check selected reports.
        </div>
        <div className="content">
        <Table columns={column} rows={data}/>
        </div>
        <div className="btns flex j-end">
          <button 
            className="big-sub-btn flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            Cancel
          </button>
          <button 
            className="big-main-btn flex j-center a-center" 
            onClick={onDelete}
          >
          Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteReportModal;
