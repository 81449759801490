export const RESOURCE_TYPE_RADIO_LIST = [
  { id: 'CloudFront', name: 'CloudFront', label: 'Amazon CLoudFront distributions', value: 'CloudFront' },
  {
    id: 'regional',
    name: 'regional',
    label:
      'Regional resources (Application Load Balancers, Amazon API Gateway REST APIs, Amazon App Runner services, AWS AppSync GraphQL APIs, Amazon Cognito user pools and AWS Verified Access instances)',
    value: 'regional',
  },
];

export const BODY_SIZE_LIMIT_RADIO_LIST = [
  { id: 'default', name: 'Default', label: 'Default', value: 'default' },
  { id: '16KB', name: '16KB', label: '16KB', value: 'KB_16' },
  { id: '32KB', name: '32KB', label: '32KB', value: 'KB_32' },
  { id: '48KB', name: '48KB', label: '48KB', value: 'KB_48' },
  { id: '64KB', name: '64KB', label: '64KB', value: 'KB_64' },
];
