export const SCOPE_OF_INSPECTION_RADIO_LIST = [
  {
    id: 'all',
    name: 'all',
    label: 'Inpect all web requests',
    value: 'all',
  },
  {
    id: 'scopeDown',
    name: 'scopeDown',
    label: 'Only inspect requests that match a scope-down statement',
    value: 'scopeDown',
  },
];

export const STATEMENT_CONDITION_DROPDOWN_LIST = [
  {
    id: 0,
    value: 'DEFAULT',
    name: 'matches the statement',
  },
  {
    id: 1,
    value: 'AND',
    name: 'matches all the statements (AND)',
  },
  {
    id: 2,
    value: 'OR',
    name: 'matches at least one of the statements (OR)',
  },
  {
    id: 3,
    value: 'NOT',
    name: 'doesn’t match the statement (NOT)',
  },
];

export const OVERRIDE_RULE_ACTION_DROPDOWN_LIST = [
  {
    id: 0,
    value: 'allow',
    name: 'Override to Allow',
  },
  {
    id: 1,
    value: 'block',
    name: 'Override to Block',
  },
  {
    id: 2,
    value: 'count',
    name: 'Override to Count',
  },
  {
    id: 3,
    value: 'captcha',
    name: 'Override to Captcha',
  },
  {
    id: 4,
    value: 'challenge',
    name: 'Override to Challenge',
  },
];
