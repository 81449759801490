import { useEffect, useMemo, useState } from 'react';
import { OrderDirection, RowType } from '@Types/v2/Table';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import Icon from 'components/v2/atoms/Icon';
import EventSettingIcon from 'assets/svgs/v2/ico_redcircle_event_setting.svg';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import CreateCloudInformationModal from 'components/v2/modals/CreateCloudInformationModal';
import DeleteCloudInformationModal from 'components/v2/modals/DeleteCloudInformationModal';
import CloudInformationGrid from './components/CloudInformationGrid';
import CloudInformationDetail from './components/CloudInformationDetail';
import CloudIcon from 'assets/svgs/v2/ico_redcircle_cloud.svg';
import lazyGetRuleCloudPage from 'graphql/queries/getRuleCoudPageByParam';
import deleteRuleCloud from 'graphql/mutations/deleteRuleCloud';

const CloudInformation = () => {

  const [modalIsOpen, setModalIsOpen] = useState({
    create: false,
    delete: false
  }); 
  
  const [isShowing, setIsShowing] = useState<'DATA_GRID' | 'DETAIL'>('DATA_GRID');
  const [cloudKindList, setCloudKindList] = useState<{name: string; value: string; }[]>([]);  
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState({
    totalPage: 0,
    totalElement: 0
  });
  const [rows, setRows] = useState<RowType[]>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');

  
  const [getRuleCloudPageByParam, {loading: loadingGetRuleCloudPageByParam}] = lazyGetRuleCloudPage();
  const [delRuleCloud] = deleteRuleCloud();

  /* 클라우드 종류 조회 */
  const [getEnumTypeCode] = lazyGetEnumTypeCode();
  /* 국가코드 조회 (개발 예정)  */
  // api/signup/getCountryCode

  const getRuleCloudsPageData = useMemo(() => ({
    reqData: {
      text: search === '' ? undefined : search,
      pageInfo: {
        page: tablePagination.currentPage - 1,
        size: tablePagination.limit,
        orders: [{
          target: tablePagination.target === 'modifiedByName' ? 'modifiedBy' : tablePagination.target,
          direction: tablePagination.direction
        }]
      }
    }
  }), [
    tablePagination,
    search,
    isShowing,
  ]);

  const renderShowing = useMemo(() => {
    switch (isShowing) {
      default:
        return <CloudInformationGrid
          onSubmitSearch={(readyToSearch:string) => {
            setSearch(readyToSearch);
            setTablePagination(prev => ({
              ...prev,
              currentPage: 1
            }));
          }}
          tablePagination={tablePagination}
          updateTablePagination={(key:string, value: number | string | OrderDirection) => setTablePagination(prev => ({
            ...prev,
            [key]: value
          }))}
          total={total}
          rows={rows}
          checkedList={checkedList}
          reportCheckedList={(list:string[]) => setCheckedList(list)}
          reportSelected={(id:string) => {
            setSelected(id);
            setIsShowing('DETAIL');
          }}
          openCreateModal={() => setModalIsOpen(prev => ({
            ...prev,
            create: true
          }))}
          openDeleteModal={() => setModalIsOpen(prev => ({
            ...prev,
            delete: true
          }))}
          isLoading={loadingGetRuleCloudPageByParam}
        />
      case 'DETAIL': 
        return <CloudInformationDetail
          cloudKindList={cloudKindList}
          data={rows.find(val => val.cloudId === Number(selected)) as RowType} 
          convertGridIsShowing={() => {
            setSelected('');
            setIsShowing('DATA_GRID');
          }}
          openDeleteModal={() =>  {
            setCheckedList([selected]);
            setModalIsOpen(prev => ({
              ...prev,
              delete: true
            }))

         }}    
          onEditSuccess={() => {
            getRuleCloudPageRows();
          }}
        />
    }
  }, [ 
    tablePagination,
    isShowing, 
    rows, 
    total,
    checkedList, 
    selected,

    cloudKindList
  ])

  const getRuleCloudPageRows = () => {
    getRuleCloudPageByParam({ variables: getRuleCloudsPageData}).then(({ data }) => {
      if (data) {
        setRows(data.getRuleCloudPageByParam.content.map(val => (
          {
            ...val,
            id: val.cloudId
          }
        )))
        setTotal({
          totalPage: data.getRuleCloudPageByParam.totalPages,
          totalElement: data.getRuleCloudPageByParam.totalElements
        })
      }
    })
  }

  /* 클라우드 정보 rows 받기 */
  useEffect(() => {
    getRuleCloudPageRows();
  }, [getRuleCloudsPageData]);

  /* 클라우드 종류 리스트 가져오기 */
  useEffect(() => {
    getEnumTypeCode({variables: { text: 'CloudKindCode'}}).then(({ data }) => {
      if (data) {
        if (data.getEnumTypeCode.data) {
          setCloudKindList(data.getEnumTypeCode.data.map(val => ({
            name: val.name,
            value: val.value
          })));
        }
      }
    })
  }, [])

  return (
    <div className='cloud-information'>
      {renderShowing}
      <CreateCloudInformationModal
        cloudKindList={cloudKindList}
        open={modalIsOpen.create}
        title={() => <>
          <Icon width={32} height={32} src={CloudIcon} />
          Create rule cloud
        </>}
        onCreateSuccess={() => {
          setModalIsOpen(prev => ({
            ...prev,
            create: false
          }))
          getRuleCloudPageRows();
        }}

        onClose={() => {
          setModalIsOpen(prev => ({
            ...prev,
            create: false
          }));
        }}    
      />

      <DeleteCloudInformationModal 
        open={modalIsOpen.delete} 
        data={rows.filter(val => checkedList.includes(String(val.id)))} 
        title={() => 
          <>
            <Icon width={32} height={32} src={EventSettingIcon}  />
            Delete rule clouds
          </>
        } 
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          delete: false
        }))}
        onDelete={() => {
          const deleteRuleCloudData = {
            reqData: {
              ids: checkedList.map(val => Number(val))
            }
          }
          delRuleCloud({ variables: deleteRuleCloudData }).then(({data}) => {
            if (data) {

              if(data.deleteRuleCloud.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, 'Cloud provider is deleted successfully.');
                setModalIsOpen(prev => ({
                  ...prev,
                  delete: false
                }))
                
                if (isShowing === 'DETAIL') {
                  setIsShowing('DATA_GRID');
                }

                if ((rows.length === checkedList.length) && total.totalPage !== 1) {
                  setTablePagination(prev => ({
                    ...prev,
                    currentPage: tablePagination.currentPage - 1
                  }));
                } else {
                  getRuleCloudPageRows();
                }

                setCheckedList([]);
              } else {
                console.log(data.deleteRuleCloud.result);
                useToast(ErrorCode.UNKNOWN, 'Delete cloud provider failed.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, 'Delete cloud provider failed.');
            }
          })
        }} 
      />
    </div>
  );
}
export default CloudInformation;
