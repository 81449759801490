import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsGetRoleResponse } from 'graphql/types/AwsGetRoleResponse';

export interface IAwsRoleVariables {
  cloudId: number;
  region: string;
  request: {
    roleName: string;
  };
}

export interface IAwsRoleResponseData {
  getAwsRole: IGqlResponseNewData<AwsGetRoleResponse>;
}

const lazyGetAwsRole = () =>
  useLazyQuery<IAwsRoleResponseData, IAwsRoleVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsRole;
