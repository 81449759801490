import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface DeleteNetworkAclRuleEntry {
  dryRun?: boolean;
  egress: Boolean
  networkAclId: string;
  ruleNumber: number;
}
export interface IDeleteNetworkAclRuleEntryVariables {
  cloudId: number;
  region: string;
  request: {
    deleteNetworkAclEntries: DeleteNetworkAclRuleEntry[];
  }
}

export interface IDeleteNetworkAclEntryResponseData {
  deleteAwsNetworkAcIEntry: IGqlResponseData<undefined>;
}

const deleteNetworkAcIEntryMutation = () => useMutation<IDeleteNetworkAclEntryResponseData, IDeleteNetworkAclRuleEntryVariables>(query);
export default deleteNetworkAcIEntryMutation;