import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsGetRolePolicyResponseType } from 'graphql/types/AwsGetRolePolicyResponse';

export interface IGetRoleVariables {
  cloudId: number;
  region: string;
  request: {
    roleName: string;
    policyName: string;
  }
}

export interface IRoleResponseData {
  getAwsRolePolicy: IGqlResponseNewData<AwsGetRolePolicyResponseType>;
}

const lazyGetAwsRolePolicy = () => useLazyQuery<IRoleResponseData, IGetRoleVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsRolePolicy;