import { useMemo, useState } from 'react';
import ManageHeader from 'components/v2/ManageHeader';
import ManageTab from 'components/v2/ManageTab';
import CloudInformation from './CloudInformation';
import Category from './Category';
import SubCategory from './SubCategory';

const MENU = [
  {
    label: 'Rule Cloud',
    value: 'CLOUD_INFORMATION'
  },
  {
    label: 'Rule Category',
    value: 'CATEGORY'
  },
  {
    label: 'Rule Subcategory',
    value: 'SUB_CATEGORY'
  },
] 

/**
 * <>lg 운영자만 접근 가능한 분석규칙관리 페이지</>
 */
const Analysis = () => {
  const [tab, setTab] = useState('CLOUD_INFORMATION');

  const renderContents = useMemo(() => {
    switch (tab) {
      case 'CLOUD_INFORMATION':
        return <CloudInformation />;
      case 'CATEGORY':
        return <Category />;
      case 'SUB_CATEGORY':
      return <SubCategory />;
      default:
        return <CloudInformation />;
      }
  }, [tab])

  return (
    <div id="manage-analysis">
      <ManageHeader 
        title='Report rules'
      />
      <ManageTab
        selected={tab}
        menu={MENU}
        convertTab={(value) => setTab(value)}
      />
      {renderContents}
    </div>
  );
}

export default Analysis;