import { ManagementTypeEnum } from '..';

export const variableCombineNextToken = (variable: any, nextToken?: string) => {
  if (nextToken) {
    return {
      ...variable,
      request: {
        ...variable.request,
        nextToken: nextToken,
      },
    };
  }
  return variable;
};

export const handleLinkClicked = ({
  navigate,
  link = '/organ/1/manage/network',
  type = ManagementTypeEnum.NETWORK,
  tabId,
  key,
  value,
}: {
  navigate: any;
  link: string;
  type: string;
  tabId: string;
  key: string;
  value: string;
}) => {
  if (!value || value === '-') return;

  navigate(link, {
    replace: true,
    state: {
      type,
      tabId,
      key,
      value,
    },
  });
};
