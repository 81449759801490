import { NatGWDetailPropsType } from './types';
import PageDetailTitle from '../../../EC2/components/PageDetailTitle';
import { Fragment, ReactNode, useMemo, useState } from 'react';
import { TextTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import { DETAIL_KEYS, DETAIL_TAB_LIST, DETAIL_TEXT_DESCRIPTION } from './configs';
import { ColumnType, RowType } from '@Types/v2/Table';
import TagTab from '../../../EC2/components/TagTab';
import DetailTab from '../DetailTab';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdLayout';
import { IMgdTabProps } from 'layouts/v3/MgdTabLayout/types';
import IpV4Address from './Tabs/IpV4Address';
import { handleLinkClicked } from '../../../Utils';
import { ManagementTypeEnum } from '../../..';
import { NETWORK_TAB_LIST } from '../../configs';
import { elasticIpFilterDropdown, subnetFilterDropdown, vpcFilterDropdown } from '../../Constants';
import { useNavigate } from 'react-router-dom';

const NatGWDetail = (props: NatGWDetailPropsType) => {
  const navigate = useNavigate();

  const { natGW, pageBackClick } = props;

  const [detailTabSelected, setDetailTabSelected] = useState<IMgdTabProps>(DETAIL_TAB_LIST[0]);

  const natGWId = useMemo((): string => {
    return natGW?.natGatewayId ?? '';
  }, [natGW]);

  const handleGetLink = (key: string, value: string) => {
    switch (key) {
      case DETAIL_KEYS[4]:
        return handleLinkClicked({
          navigate,
          link: '/organ/1/manage/network',
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[5].id,
          key: elasticIpFilterDropdown[0].value.toString(),
          value,
        });

      case DETAIL_KEYS[7]:
        return handleLinkClicked({
          navigate,
          link: '/organ/1/manage/network',
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[0].id,
          key: vpcFilterDropdown[0].value.toString(),
          value,
        });

      case DETAIL_KEYS[8]:
        return handleLinkClicked({
          navigate,
          link: '/organ/1/manage/network',
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[2].id,
          key: subnetFilterDropdown[0].value.toString(),
          value,
        });

      default:
        return null;
    }
  };

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    if (natGW) {
      const detailsData: IMgdDetailKeyValueProps[] = [];
      const detailsDataOrdered: IMgdDetailKeyValueProps[] = [];
      Object.entries(natGW).map(([key, value]) => {
        if (DETAIL_KEYS.indexOf(key) > -1) {
          const textDes = DETAIL_TEXT_DESCRIPTION.find(text => text.id == key);
          let desValue = '-';
          if (typeof value === 'string') {
            desValue = value.toString();
          }
          if (textDes) {
            detailsData.push({
              id: key,
              type: textDes.type as TextTypeEnum,
              title: textDes.value,
              description: desValue,
              handleItemClick: () => handleGetLink(key, desValue),
            });
          }
        }
      });

      DETAIL_KEYS.map(key => {
        const dataByKey = detailsData.find(data => data.id == key);
        if (dataByKey) detailsDataOrdered.push(dataByKey);
      });
      return detailsDataOrdered;
    }
    return [];
  }, [natGW]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const tagRows = useMemo((): RowType[] => {
    return natGW?.tags ?? [];
  }, [natGW]);

  const detailContentNode = useMemo((): ReactNode => {
    switch (detailTabSelected) {
      default:
      case DETAIL_TAB_LIST[0]:
        return <IpV4Address natGW={natGW} />;
      case DETAIL_TAB_LIST[1]:
        return <TagTab title={'Tags'} rows={tagRows} columns={tagColumns} />;
    }
  }, [detailTabSelected, tagRows, tagColumns]);

  return (
    <div className="ec2-detail">
      <PageDetailTitle title={`NAT G/W / ${natGWId}`} pageBackClick={pageBackClick} />
      {natGW && (
        <Fragment>
          <DetailTab title={'Details'} data={detailTabContentData} isApiLoading={false} />
          <div className="tab-container">
            <div className="detail-tab flex a-center">
              {DETAIL_TAB_LIST.map(tab => {
                return (
                  <button
                    className={`detail-tab-items ${detailTabSelected?.id === tab.id && 'active'}`}
                    key={tab.id}
                    data-tab={tab.id}
                    onClick={e => setDetailTabSelected(tab)}
                  >
                    <p>{tab.name}</p>
                  </button>
                );
              })}
            </div>

            <div className="content-tab">{detailContentNode}</div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default NatGWDetail;
