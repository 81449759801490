import { enumFormatter } from "utils/Formatter";

export const DATA_INFLOWS = [
  {
    name: '팔로 알토',
    value: '팔로 알토'
  },
  {
    name: '데이터 유입A',
    value: '데이터 유입A'
  },
  {
    name: '데이터 유입B',
    value: '데이터 유입B'
  },
  {
    name: '데이터 유입C',
    value: '데이터 유입C'
  },
  {
    name: '데이터 유입D',
    value: '데이터 유입D'
  },
  {
    name: '데이터 유입E',
    value: '데이터 유입E'
  }
];

export const CLOUD_TYPES = [
  {
    name: 'GCP',
    value: 'GCP'
  },
  {
    name: 'AWS1',
    value: 'AWS1'
  },
  {
    name: 'AWS2',
    value: 'AWS2'
  },
  {
    name: 'AWS3',
    value: 'AWS3'
  },
  {
    name: 'AWS4',
    value: 'AWS4'
  },
  {
    name: 'AWS5',
    value: 'AWS5'
  },
  {
    name: 'AWS6',
    value: 'AWS6'
  }
];

export const ACCESS_KEYS = [
  {
    name: 'Access Key',
    value: 'AccessKey'
  },
  {
    name: 'Secret Key',
    value: 'SecretKey'
  },
  {
    name: 'Token',
    value: 'token'
  }
];

export const TABLE_OFFSET = [
  {
    id: 1,
    name: '10 items',
    value: 10
  },
  {
    id: 2,
    name: '15 items',
    value: 15
  },
  {
    id: 3,
    name: '20 items',
    value: 20
  }
];

export const ROLE = [
  {
    id: 1,
    name: '소유자',
    value: 'pj_owner'
  },
  {
    id: 2,
    name: '관리자',
    value: 'pj_admin'
  },
  {
    id: 3,
    name: '멤버',
    value: 'pj_member'
  }
];

export const PRIORITY = [
  {
    name: `${enumFormatter('high0000')}`,
    value: 'high0000',
  },
  {
    name: `${enumFormatter('veryhigh')}`,
    value: 'veryhigh'
  }
];

export const MSG_SENDER_TYPE = [
  {
    name: `${enumFormatter('email001')}`, /* 인증 이메일 */
    value: "email001",
  },
  {
    name: `${enumFormatter('sms00010')}`, /* 위험 문자 */
    value: 'sms00010'
  },
  {
    name: `${enumFormatter('email010')}`, /* 위험 이벤트 이메일 */
    value: 'email010'
  }
];