import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { DashboardListType } from 'graphql/types/DashboardListType';

export interface IUpdateDashboardVariables {
  reqData: {
    id: number;
    name?: string;
    parameters?: string;
  }
}

export interface IUpdateDashboardResponseData {
  updateDashboard: IGqlResponseData<DashboardListType[]>;
}

/** 조직 추가 */
const updateDashboard = () => useMutation<IUpdateDashboardResponseData, IUpdateDashboardVariables>(query);
export default updateDashboard;