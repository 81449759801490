import { useEffect, useState } from 'react';
import './index.scss';

import LabelInput from 'components/v2/LabelInput';
import Labeled from 'components/v2/atoms/Labeled';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import LabelTextarea from 'components/v2/LabelTextarea';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import { enumFormatter } from 'utils/Formatter';
import { useAuth } from 'contexts/AuthProvider';
import addProject, { IAddProjectResponseData, IAddProjectVariables } from 'graphql/mutations/addProject';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import GreenRemoveIcon from 'assets/svgs/v4/ico_green_remove.svg';
import Icon from 'components/v2/atoms/Icon';
import InputAtom from 'components/v2/atoms/InputAtom';

interface ICreateProjectModalProps extends IBaseModalProps {
  title: () => JSX.Element;
  onCreate: (data: IAddProjectResponseData) => void;
}

const CreateProjectModal = ({
  title,
  onCreate,
  ...baseModalProps
}:ICreateProjectModalProps) => {

  const { userInfo, role, updateToken } = useAuth();
  const [payload, setPayload] = useState({
    name: '',
    thirdPartTypeCode: '',
    attrKey1: 'data_attr',
    monitorYn: false, 
    msspYn: false 
  });
  const [thirdPartyCodeList, setThirdPartyCodeList] = useState<DropdownListDataType[]>([]); 
  const [attributes, setAttributes] = useState<{key: string, value: string}[]>([{key: '', value: ''}]);

  const [getEnumTypeCode] = lazyGetEnumTypeCode();
  const [isDisabled, setIsDisabled] = useState(true);
  const [nameError, setNameError] = useState('');
  const [dataSourceError, setDataSourceError] = useState('');
  
  /* 프로젝트 만들기 */
  const [createProject] = addProject();
  /* err
    첫번째 에러가 난 입력폼에 포커스
    데이터 속성은 선택 입력값
    프로젝트 이름 중복 - 이미 사용한 프로젝트 이름입니다. 
    프로젝트 이름 미입력 - 프로젝트 이름을 입력하세요.
    데이터 유입 미선택 - 데이터 유입을 선택하세요.
  */
  const onSubmit = (submitData: {
    name: string;
    thirdPartTypeCode: string;
    attrKey1: string;
    monitorYn : boolean;
    msspYn : boolean;
  }) => {
    if(submitData.monitorYn && submitData.thirdPartTypeCode === '') {
      setDataSourceError('select data source');
    } else {
      setDataSourceError('');
      if(userInfo) {
        let attrValue:{[key: string]: string} = {};
        attributes.forEach(attr => {
          attrValue[attr.key] = attr.value;
        });
        // todo thirdPartTypeCode 추후 필수값 아닌걸로 변경 필요
        const data:IAddProjectVariables = {
          reqData: {
            organId: userInfo.nemo.organId,
            name: submitData.name,
            monitorYn: submitData.monitorYn,
            msspYn: submitData.msspYn,
            ...(submitData.monitorYn && {
              thirdPartTypeCode: submitData.thirdPartTypeCode,
              attrKey1: 'data_attr',
              attrValue1: JSON.stringify(attrValue)
            })
          }
        };
          createProject({ variables: data }).then(({ data }) => {
            if(data) {
              if (data.addProject.result === ErrorCode.SUCCESS) {
                onCreate(data);
              } else {
                console.log(data.addProject.result);
                if (data.addProject.result === ErrorCode.PROJECT_DUPLICATED) {
                  useToast(ErrorCode.UNKNOWN, 'Already using project name.')
                } else {
                  useToast(ErrorCode.UNKNOWN, 'Project create failed.');  
                }
              }
            } else {
              //todo 프로젝트 이름 중복 오류 오면 setNameError 적용
              useToast(ErrorCode.UNKNOWN, 'Project create failed.');
            }
        }).catch((e) => {
          if(e.message.includes('null')) {
            setNameError('Project name is already using.')
          }
          useToast(ErrorCode.UNKNOWN, 'Project create failed.');
        });
      }
    }
  }
  const changeAttributeData = (idx: number, key: 'key'|'value', value: string) => {
    setAttributes(prev => prev.map((d, dIdx) => {
      if(dIdx === idx) {
        return {...d, [key]: value};
      } else {
        return d;
      }
    }));
  }
  const removeAttributeData = (idx: number) => {
    setAttributes(prev => prev.filter((d, dIdx) => dIdx !== idx));
  }


  useEffect(() => {
    if (baseModalProps.open) {
      getEnumTypeCode({variables: { text: 'ThirdPartyCode' }}).then(res => {
        if (res.data) {
          setThirdPartyCodeList(res.data.getEnumTypeCode.data.map(val => ({
            name: enumFormatter(val.value),
            value: val.value
          })));
        }
      })
      
      setPayload({
        name: '',
        thirdPartTypeCode: '',
        attrKey1: 'data_attr',
        monitorYn: false, 
        msspYn: false 
      });
      
      setIsDisabled(true);
      setDataSourceError('');
      setNameError('');
      setAttributes([{key: '', value: ''}]);
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal 
      {...baseModalProps}
      title={title}
    >
      <div className="create-project-modal">
        <div className="row-1 flex col">
          <LabelInput 
            title="Project name"
            value={payload.name}
            placeholder="Input project name"
            required={true}
            onChangeValue={(val) => {
              setPayload((prev) => ({
                ...prev,
                name: val
              }));
              setNameError('');
            }}
            errorMsg={nameError === '' ? undefined : nameError}
          />
          <Labeled
            title="Select services"
            required={true}
          >
            <label className='labeled-wrapper'>At least one service is selected</label>
          </Labeled>
          <div>
            <label className="labeled flex a-center">
              <CheckboxAtom id='manageYn'
                checked={payload.msspYn}
                onchange={() => {
                  setPayload((prev) => ({
                    ...prev,
                    msspYn: !prev.msspYn
                  }))
                } 
                  }
              />
              Managed security service
            </label>
            <div className='manage-security-info'>Manage cloud resources and cloud managed service.</div>
          </div>
          <label className="labeled flex a-center">
            <CheckboxAtom id='monitorYn'
              checked={payload.monitorYn}
              onchange={() => {
                setDataSourceError('');
                setIsDisabled(!isDisabled);
                setPayload((prev) => ({
                  ...prev,
                  monitorYn: !prev.monitorYn
                }))
              } 
               }
            />
              Monitoring service
          </label>
          <Labeled
            title="Data source"
            required={true}
            errorMsg={dataSourceError === '' ? undefined : dataSourceError}
          >
            <DropdownAtom
              disabled={isDisabled} 
              id={'data-inflow'} 
              data={thirdPartyCodeList} 
              placeholder="Select source"
              value={{
                name: thirdPartyCodeList.find(val => val.value === payload.thirdPartTypeCode)?.name 
                  ? thirdPartyCodeList.find(val => val.value === payload.thirdPartTypeCode)?.name 
                  : '',
                  value: payload.thirdPartTypeCode
              }} 
              handleClick={(val: DropdownListDataType) => {
                setDataSourceError('');
                setPayload((prev) => ({
                  ...prev,
                  thirdPartTypeCode: String(val.value)
                }))
              }
              } 
            />
          </Labeled>
          <Labeled title="Data attributes " className="attributes-box">
            <button className="add-attribute-btn flex a-center" onClick={() => setAttributes(prev => [...prev, {key: '', value: ''}])}>
              <span className="add-icon" />
              Add
            </button>
            {attributes.map((attr, attrIdx) => 
            <div className="flex attribute-group a-center" key={`attribute-data-${attrIdx}`}>
              <InputAtom
                placeholder="Key"
                value={attr.key}
                onChangeValue={(val) => changeAttributeData(attrIdx, 'key', val)}
                disabled={isDisabled} 
                noClear={true}
              />
              <InputAtom
                placeholder="Value"
                value={attr.value}
                onChangeValue={(val) => changeAttributeData(attrIdx, 'value', val)}
                disabled={isDisabled} 
                noClear={true}
              />
              {attributes.length > 1 && <Icon src={GreenRemoveIcon} width={24} height={24} onClick={() => removeAttributeData(attrIdx)}/>}
            </div>
            )}
          </Labeled>
        </div>
        <div className="row-2 flex">
          <button 
            className="big-sub-btn-body2regular flex j-center a-center"  
            onClick={baseModalProps.onClose}
          >
            Cancel
          </button>
          <button 
            className="big-main-btn-body2regular flex j-center a-center"  
            disabled={payload.name === '' || (!payload.msspYn && !payload.monitorYn)}
            onClick={() => onSubmit(payload)}
          >
            Create
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default CreateProjectModal;
