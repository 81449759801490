import { useEffect, useState } from 'react';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import InspectNode from '../InpectNode';
import {
  BODY_CONTENT_TO_INSPECT,
  CONTENT_TO_INSPECT,
  CONTENT_TYPE,
  DEFAULT_STATEMENT_ITEM,
  HEADERS_MATCH_SCOPE,
  IP_ADDRESS_SELECTOR,
  JA3_FINGERPRINT,
  JSON_MATCH_SCOPE,
  JSON_REQUEST,
  MATCH_SCOPE,
  MISSING_IP_ADDRESS,
  POSITION_INSIDE_HEADER,
  RULE_BUILDER_REQUEST_CONDITION_DATA,
  RULE_BUILDER_STATEMENT_INSPECT_DATA,
} from '../../../../constant';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';

import './index.scss';
import _ from 'lodash';

interface IStatementNodeProps {
  cloudId: number;
  selectedRegion: DropdownListDataType;
  value: string;
  statementData: any;
  setStatementData: (statement: any) => void;
  errors: {
    [key: string]: string;
  };
  ipSets: any;
  isHidePositionInsideHeader?: boolean;
}

const StatementNode = ({
  cloudId,
  selectedRegion,
  value,
  statementData,
  setStatementData,
  errors,
  ipSets,
  isHidePositionInsideHeader,
}: IStatementNodeProps) => {
  const isAddAnotherStatement = [
    RULE_BUILDER_REQUEST_CONDITION_DATA[1].value,
    RULE_BUILDER_REQUEST_CONDITION_DATA[2].value,
  ].includes(value);

  const handleAddAnotherStatement = () => {
    const arr: any = [...statementData];
    arr.push({ ...DEFAULT_STATEMENT_ITEM, id: _.uniqueId('regular-item') });
    setStatementData(arr);
  };

  const handleChangeAnotherStatement = (id: string, key: string, value: any) => {
    const arr: any = [...statementData];

    const index = arr.findIndex((e: { id: string }) => e.id === id);

    if (index < 0) return;

    arr[index] = {
      ...arr[index],
      [key]: value,
    };

    setStatementData(arr);
  };

  const handleRemoveAnotherStatement = (id: string) => {
    const arr: any = [...statementData].filter((e: { id: string }) => e.id !== id);

    setStatementData(arr);
  };

  return (
    <div className="rule-builder-statement">
      {statementData.map((e: any, index: number) => (
        <div key={e.id}>
          {isAddAnotherStatement && index ? <div className="space-16 condition">{value}</div> : null}

          {isAddAnotherStatement && index ? <div className="space-16" /> : null}

          <div className="rule-container-group rule-container-group-border">
            <div className="rule-container-group-title">
              <p>
                {e.statementResults ? 'NOT ' : ''}Statement {isAddAnotherStatement ? index + 1 : ''}
              </p>

              {isAddAnotherStatement && statementData?.length > 1 ? (
                <button className="btn-default" onClick={() => handleRemoveAnotherStatement(e.id)}>
                  Remove
                </button>
              ) : null}
            </div>

            <div className="rule-container-group-content">
              {isAddAnotherStatement ? (
                <div>
                  <p className="rule-container-group-content-label">Negate statement (NOT)</p>

                  <p className="rule-container-group-content-description">
                    Select this to match requests that don’t satisfy the statement criteria.
                  </p>

                  <div className="checkbox-container space-8">
                    <label>
                      <CheckboxAtom
                        checked={e.statementResults}
                        onchange={() => handleChangeAnotherStatement(e.id, 'statementResults', !e.statementResults)}
                      />
                      Negate statement results
                    </label>
                  </div>
                </div>
              ) : null}

              <div className="mw-50">
                <p className="rule-container-group-content-label">Inspect</p>

                <DropdownAtom
                  id={`dropdown-rule-statement-inspect-${e.id}`}
                  data={RULE_BUILDER_STATEMENT_INSPECT_DATA}
                  placeholder="Choose an inspection option"
                  value={e.inspect}
                  handleClick={val => handleChangeAnotherStatement(e.id, 'inspect', val)}
                  error={errors.inspect}
                />
              </div>

              <InspectNode
                cloudId={cloudId}
                selectedRegion={selectedRegion}
                values={e}
                handleChangeValues={handleChangeAnotherStatement}
                errors={errors}
                ipSets={ipSets}
                isHidePositionInsideHeader={isHidePositionInsideHeader}
              />
            </div>
          </div>
        </div>
      ))}

      {isAddAnotherStatement && (
        <>
          <div className="space-16" />

          <button className="btn-default" onClick={handleAddAnotherStatement}>
            Add another statement
          </button>
        </>
      )}
    </div>
  );
};

export default StatementNode;
