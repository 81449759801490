import { IMgdTabProps } from 'layouts/v3/MgdTabLayout/types';

export const NETWORK_TAB_LIST: IMgdTabProps[] = [
  {id: 'vpc',name: 'VPC',title: 'Your VPCs'},
  {id: 'routeTable',name: 'Route Table',title: 'Route Tables'},
  {id: 'subnet',name: 'Subnet',title: 'Subnets'},
  {id: 'internetGw',name: 'Internet G/W',title: 'Internet gateways'},
  {id: 'natGw',name: 'NAT G/W',title: 'NAT gateways'},
  {id: 'elasticIp',name: 'Elastic IP',title: 'Elastic IPs'},
  {id: 'networkAcl',name: 'Network ACL',title: 'Network ACLs'},
  {id: 'securityGroup',name: 'Security Group',title: 'Security groups'}
];

export enum PageNodeEnum {
  MGD_LIST = 'MGD_LIST',
  MGD_CREATE = 'MGD_CREATE',
  MGD_DETAIL = 'MGD_DETAIL',
}
