import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';
import { AwsUpdateRegexPatternSetResponse } from 'graphql/types/AwsUpdateRegexPatternSetResponse';

export interface AwsRegexLog {
  regexString: string;
}

export interface IUpdateRegexPatternSetVariables {
  cloudId: number;
  region: string;
  reqData: {
    name: string;
    scope: string;
    id: string;
    lockToken: string;
    description?: string;
    regularExpressionList: AwsRegexLog[];
  }
}

export interface IUpdateRegexPatternSetResponseData {
  updateAwsRegexPatternSet: IGqlResponseNewData<AwsUpdateRegexPatternSetResponse>;
}

const updateAwsRegexPatternSetMutation = () => useMutation<IUpdateRegexPatternSetResponseData, IUpdateRegexPatternSetVariables>(query);
export default updateAwsRegexPatternSetMutation;