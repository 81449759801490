import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsGetUserPolicyResponse } from 'graphql/types/AwsGetUserPolicyResponse';

export interface IAwsUserPolicyVariables {
  cloudId: number;
  region: string;
  request: {
    userName: string;
    policyName: string;
  };
}

export interface IAwsUserPolicyResponseData {
  getAwsUserPolicy: IGqlResponseNewData<AwsGetUserPolicyResponse>;
}

const lazyGetAwsUserPolicy = () =>
  useLazyQuery<IAwsUserPolicyResponseData, IAwsUserPolicyVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsUserPolicy;
