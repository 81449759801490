import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeLogGroups($request: AwsDescribeLogGroupsRequest, $cloudId: Long, $region: String) {
  getAwsDescribeLogGroups(describeLogGroupsRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsDescribeLogGroupsResponse {
        logGroups {
          logGroupName
          nextToken
          retentionInDays
          metricFilterCount
          arn
          storedBytes
          kmsKeyId
          dataProtectionStatus
          inheritedProperties
          logGroupClass
          logGroupArn
        }
        nextToken
        }
      }
    }
  }
`;
export default query;