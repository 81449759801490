import React, { useMemo, useEffect, useState } from 'react';
import DetailTable from 'pages/v2/Organ/Management/EC2/components/DetailTable';
import _ from 'lodash';
import { ButtonTypeEnum } from '../../../../WebACL/CreateWebACL/components/Button';
import { StepContainer } from '../../../../WebACL/CreateWebACL/components';
import { RULES_COLUMN_LIST } from '../../configs';

type SetRulePriorityPropsType = {
  creationData: any;
  handleValueChanged: (key: string, value: any) => void
};

const SetRulePriority = (props: SetRulePriorityPropsType) => {
  const { creationData, handleValueChanged } = props;

  const { rules = [] } = creationData ?? {};

  const ruleRows = useMemo(() => {
    return rules?.map((rule: any) => {
      const { name, action } = rule;

      return {
        id: name,
        name: name,
        capacity: 1,
        action: Object.keys(action)[0],
      };
    });
  }, [rules]);

  const [orderRuleRows, setOrderRuleRows] = useState(ruleRows);
  const [reportCheckedList, setReportCheckedList] = useState<Array<string>>([]);
  const [selectedRule, setSelectedRule] = useState('');

  useEffect(() => {
    setOrderRuleRows(ruleRows);
  }, [ruleRows]);

  const selectedRuleIndex = ruleRows.findIndex((item: any) => item.id === selectedRule);

  const rulesActionButtons = useMemo(() => {
    return [
      {
        id: 'moveUp',
        label: 'Move up',
        type: ButtonTypeEnum.GENERAL,
        onClick: () => {
          const newRules = [...rules];
          newRules[selectedRuleIndex].priority = newRules[selectedRuleIndex].priority - 1;
          newRules[selectedRuleIndex - 1].priority = newRules[selectedRuleIndex - 1].priority + 1;
          handleValueChanged('rules', _.orderBy(newRules, 'priority'));
        },
        disabled: selectedRuleIndex <= 0,
      },
      {
        id: 'moveDown',
        label: 'Move down',
        type: ButtonTypeEnum.GENERAL,
        onClick: () => {
          const newRules = [...rules];
          newRules[selectedRuleIndex].priority = newRules[selectedRuleIndex].priority + 1;
          newRules[selectedRuleIndex + 1].priority = newRules[selectedRuleIndex + 1].priority - 1;
          handleValueChanged('rules', _.orderBy(newRules, 'priority'));
        },
        disabled: selectedRuleIndex < 0 || selectedRuleIndex === orderRuleRows.length - 1,
      },
    ];
  }, [selectedRuleIndex, orderRuleRows, rules]);

  return (
    <StepContainer
      title={'Set rule priority'}
      description="AWS WAF evaluates the rules in the order shown, starting from the top. Move rules up or down to change the evaluation order."
    >
      <DetailTable
        title={'Rules'}
        columns={RULES_COLUMN_LIST}
        data={orderRuleRows}
        rightButtons={rulesActionButtons}
        isRadioTable={true}
        reportValue={selectedRule}
        reportSelected={setSelectedRule}
      />
    </StepContainer>
  );
};

export default SetRulePriority;
