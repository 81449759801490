import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsAttachedRolePoliciesResponseType } from 'graphql/types/AwsListAttachedRolePoliciesResponse';

export interface IGetAttachedRolePoliciesVariables {
  cloudId: number;
  region: string;
  request: {
    roleName: string;
  }
}

export interface IAttachedRolePoliciesResponseData {
  getAwsListAttachedRolePolicies: IGqlResponseNewData<AwsAttachedRolePoliciesResponseType>;
}

const lazyGetAwsListAttachedRolePolicies = () => useLazyQuery<IAttachedRolePoliciesResponseData, IGetAttachedRolePoliciesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListAttachedRolePolicies;