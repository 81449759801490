import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import InputAtom from 'components/v2/atoms/InputAtom';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { FilterPropsType } from 'pages/v2/Organ/Management/EC2/components/Image/types';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { PolicyPropsType } from '../../types';
import { UserGroupRowType } from '../types';
import { handleFormatText, handleGetAwsType } from 'utils/Common';
import { ErrorCode } from '@Types/error';
import TableJsonView from 'layouts/v3/MgdLayout/components/TableJsonView';
import lazyGetAwsListPolicies, { IAwsListPoliciesVariables } from 'graphql/queries/ListPoliciesRequest';
import lazyGetAwsPolicy, { IAwsPolicyVariables } from 'graphql/queries/getAwsPolicy';
import lazyGetAwsPolicyVersion, { IAwsPolicyVersionVariables } from 'graphql/queries/getAwsPolicyVersion';

const Policy = (props: PolicyPropsType) => {
  const { cloudId, region, setDataItem, setIsDetailPage } = props;

  const [getAwsListPolicies] = lazyGetAwsListPolicies();
  const [getAwsPolicy] = lazyGetAwsPolicy();
  const [getAwsPolicyVersion] = lazyGetAwsPolicyVersion();

  const [isLoading, setIsLoading] = useState(false);
  const [nextToken, setNextToken] = useState<string>('');
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [searchValue, setSearchValue] = useState<FilterPropsType>({
    name: '',
    value: '',
  });
  const [scopeNFilterVariable, setScopeNFilterVariable] = useState<FilterPropsType>();

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Policy name',
        field: 'policyName',
        sort: true,
        renderCell: (row: RowType) => (
          <span
            className="link"
            onClick={e => {
              e.stopPropagation();
              setDataItem(row as UserGroupRowType);
              setIsDetailPage();
            }}
          >
            {handleFormatText(row?.policyName)}
          </span>
        ),
      },
      {
        label: 'Type',
        field: 'type',
        sort: true,
      },
      {
        label: 'Used as',
        field: 'usedAs',
        sort: true,
      },
      {
        label: 'Description',
        field: 'description',
        sort: true,
      },
    ];
  }, [mainTblRows]);

  const handleGetAwsPolicy = (policyArn: string) => {
    const requestVariable: IAwsPolicyVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        policyArn,
      },
    };
    return getAwsPolicy({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsPolicyVersion = (policyArn: string, versionId: string) => {
    const requestVariable: IAwsPolicyVersionVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        policyArn,
        versionId,
      },
    };
    return getAwsPolicyVersion({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetUsedAs = (attachmentCount?: number, permissionsBoundaryUsageCount?: number) => {
    let usedAs = '';

    if (!!attachmentCount) {
      usedAs = `Permissions policy(${attachmentCount})`;
    }

    if (!!permissionsBoundaryUsageCount) {
      usedAs += (!!usedAs ? `, ` : '') + `Boundary(${permissionsBoundaryUsageCount})`;
    }

    return usedAs || 'None';
  };

  const fetchData = useCallback(
    async (nextToken?: string, isInitial?: boolean) => {
      try {
        setIsLoading(true);
        const requestVariable: IAwsListPoliciesVariables = {
          cloudId: cloudId,
          region: region,
          request: {
            maxItems: mainTablePagination.limit,
          },
        };

        const combinedVariable = variableCombineNextToken(requestVariable, nextToken);
        const { data } = await getAwsListPolicies({ variables: combinedVariable });

        if (data?.getAwsListPolicies?.result !== ErrorCode.SUCCESS) {
          setIsLoading(false);
          return;
        }

        setNextToken(data?.getAwsListPolicies?.nextToken || '');

        const rows = data?.getAwsListPolicies?.data?.[0].policies?.length
          ? await Promise.all(
              data.getAwsListPolicies.data[0].policies.map(async e => {
                const { data: dataAwsPolicy } = await handleGetAwsPolicy(e.arn);

                const { data: dataAwsPolicyVersion } = await handleGetAwsPolicyVersion(
                  dataAwsPolicy?.getAwsPolicy?.data?.[0]?.policy?.arn || '',
                  dataAwsPolicy?.getAwsPolicy?.data?.[0]?.policy?.defaultVersionId || '',
                );

                return {
                  ...e,
                  id: e.policyId,
                  policyName: e.policyName,
                  type: handleGetAwsType(e.arn),
                  usedAs: handleGetUsedAs(
                    dataAwsPolicy?.getAwsPolicy?.data?.[0]?.policy?.attachmentCount,
                    dataAwsPolicy?.getAwsPolicy?.data?.[0]?.policy?.permissionsBoundaryUsageCount,
                  ),
                  description: dataAwsPolicy?.getAwsPolicy?.data?.[0]?.policy?.description,
                  jsonContent: {
                    title: e.policyName,
                    description: 'Allow Access Analyzer to analyze resource metadata',
                    value: dataAwsPolicyVersion?.getAwsPolicyVersion?.data?.[0]?.policyVersion?.document,
                    jsonString: decodeURIComponent(dataAwsPolicyVersion?.getAwsPolicyVersion?.data?.[0]?.policyVersion?.document || ''),
                  },
                };
              }),
            )
          : [];

        let totalUserGroups: RowType[] = [];
        if (isInitial) {
          totalUserGroups = rows;
        } else {
          totalUserGroups = [...mainTblRows, ...rows];
        }

        setMainTblRows(totalUserGroups);
        setMainTblTotal({
          totalPage: Math.ceil(totalUserGroups.length / mainTablePagination.itemPerPage),
          totalElement: totalUserGroups.length,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [mainTablePagination, cloudId, region, mainTblRows],
  );

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!nextToken) {
      fetchData(nextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    let totalRows = mainTblRows;
    totalRows = mainTblRows.filter(row => {
      return row?.policyName.includes(scopeNFilterVariable?.value || '');
    });

    setMainTblTotal({
      totalPage: Math.ceil(totalRows.length / mainTablePagination.itemPerPage),
      totalElement: totalRows.length,
    });

    const arr: any = orderAlphabetical(totalRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );

    return arr;
  }, [mainTblRows, scopeNFilterVariable, mainTablePagination, nextToken]);

  useEffect(() => {
    fetchData('', true);
  }, [cloudId]);

  const handleSearch = useCallback(() => {
    updateTablePagination('currentPage', 1);

    if (searchValue?.value) {
      setScopeNFilterVariable(searchValue);
      return;
    }

    setScopeNFilterVariable(undefined);
  }, [searchValue]);

  const handleSelectItem = useCallback(
    (id: string) => {
      const rowSelected = mainTblRows.find(row => row.id === id);

      if (!rowSelected) return;

      setDataItem(rowSelected as UserGroupRowType);
    },
    [mainTblRows],
  );

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>Policies</p>

          <span className="title-description">A policy is an object in AWS that defines permissions.</span>
        </div>

        <div className="flex action a-center">
          <InputAtom
            placeholder="Find policies by name"
            type={'text'}
            value={searchValue.value}
            defaultValue={''}
            onChangeValue={e => setSearchValue(prev => ({ name: prev?.name, value: e }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={handleSearch}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSearch();
              }
            }}
          />

          {/* <button className="action-btn" onClick={handleSearch}>
            Search
          </button> */}
        </div>
      </div>

      {!mainRowsCurrentPage.length && !isLoading ? (
        <div className="data-grid-wrap">
          <p className="empty-row">Empty</p>
        </div>
      ) : (
        <div className="data-grid-wrap">
          <TableJsonView
            rows={mainRowsCurrentPage}
            columns={columns}
            reportCheckedList={() => {}}
            reportSelected={id => handleSelectItem(id)}
            sortOption={{
              target: mainTablePagination.target,
              direction: mainTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateTablePagination('target', target);
                updateTablePagination('direction', dir);
              },
            }}
            isLoading={isLoading}
          />

          {mainRowsCurrentPage && mainRowsCurrentPage.length > 0 && !isLoading && (
            <div className="pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{mainTblTotal.totalElement}</span>
              </p>
              <TableManagePagination
                ableFetchMore={!!nextToken}
                currentPage={mainTablePagination.currentPage}
                updateCurrentPage={page =>
                  setMainTablePagination(prev => ({
                    ...prev,
                    ['currentPage']: page,
                  }))
                }
                totalPage={mainTblTotal.totalPage}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default Policy;
