import { gql } from '@apollo/client';

const query = gql`
  query getAwsAccessKeyLastUsed($request: AwsGetAccessKeyLastUsedRequest, $cloudId: Long, $region: String) {
    getAwsAccessKeyLastUsed(getAccessKeyLastUsedRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsGetAccessKeyLastUsedResponse {
          userName
          accessKeyLastUsed
        }
      }
    }
  }
`;
export default query;
