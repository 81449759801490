import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListAccountAliasesResponse } from 'graphql/types/AwsListAccountAliasesResponse';

export interface IAwsListAccountAliasesVariables {
  cloudId: number;
  region: string;
  request: {
    maxItems?: number;
  };
}

export interface IAwsListAccountAliasesResponseData {
  getAwsListAccountAliases: IGqlResponseNewData<AwsListAccountAliasesResponse>;
}

const lazyGetAwsListAccountAliases = () =>
  useLazyQuery<IAwsListAccountAliasesResponseData, IAwsListAccountAliasesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListAccountAliases;
