import { gql } from '@apollo/client';

const query = gql`
  query getAwsRole($request: AwsGetRoleRequest, $cloudId: Long, $region: String) {
    getAwsRole(getRoleRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsGetRoleResponse {
          role {
            path
            roleName
            roleId
            arn
            createDate
            assumeRolePolicyDocument
            description
            maxSessionDuration
            permissionsBoundary {
              permissionsBoundaryType
              permissionsBoundaryArn
            }
            tags {
              key
              value
            }
            roleLastUsed {
              lastUsedDate
              region
            }
          }
        }
      }
    }
  }
`;
export default query;
