import { useMemo } from 'react';
import './styles.scss';

export enum TextType {
  Title1 = 'title-1',
  Title2 = 'title-2',
  Title3 = 'title-3',
  Subtitle1Semibold = 'sub-title-1-semibold',
  Body2Medium = 'body-2-medium',
  Body2Regular = 'body-2-regular',
  BodyMedium = 'body-medium',
  BodyRegular1 = 'body-regular-1',
  BodyRegular2 = 'body-regular-2',
  Body15Semibold = 'body-15-semibold',
  ButtonMedium = 'button-medium',
  SmallBodyMedium = 'small-body-medium',
  SmallBodyRegular = 'small-body-regular',
  CaptionSemibold = 'caption-semibold',
  Caption = 'caption',
  Input12Medium = 'input-12-medium',
  Subtitle15 = 'sub-title-15',
}

type TextPropsType = {
  text: string;
  color?: string;
  lineHeight?: number;
  fontSize?: number;
  type?: TextType;
};

const Text = (props: TextPropsType) => {
  const { text, color, lineHeight, fontSize, type } = props;

  const className = useMemo(() => {
    return type;
  }, [type]);

  const extraStyle = useMemo(() => {
    return {
      lineHeight,
      color,
      fontSize,
    };
  }, [lineHeight, color, fontSize]);

  return (
    <p id="text-atom" className={className} style={extraStyle}>
      {text}
    </p>
  );
};

export default Text;
