import { gql } from '@apollo/client';

const query = gql`
  mutation deleteIPSet($request: AwsDeleteIpSetRequest, $cloudId: Long, $region: String) {
    deleteIPSet(deleteIpSetRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        __typename
      }
    }
  }
`;
export default query;
