import { gql } from '@apollo/client';

const query = gql`
mutation createAwsTags($request: AwsCreateTagsRequest, $cloudId: Long, $region: String) {
  createAwsTags(createTagsRequest: $request, cloudId: $cloudId, region: $region) {
    result
  }
}
`;
export default query;
