import './index.scss';

import TablePagination from 'components/v2/dataDisplay/TablePagination';

const EmptyFavorite = () => {
  return (
    <div id="empty-favorite-page" className="flex col">
      <div className="content">
        <div className="flex col a-center">
          <div className="favorite-icon" />
          <p>Add favorite project.</p>
        </div>
      </div>
      <div className="pagination-wrapper flex a-center">
        <p className="flex a-center">Total <span>0</span></p>
        <TablePagination 
          currentPage={1}
          totalPage={1}
        />
      </div>
    </div>
  );
};

export default EmptyFavorite; 
