import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteNetworkACLVariables {
  cloudId: number;
  region: string;
  request: {
    networkAclId: string[];
  }
}

export interface IDeleteNetworkACLResponseData {
  deleteAwsNetworkAcl: IGqlResponseData<undefined>;
}

const deleteNetworkACLMutation = () => useMutation<IDeleteNetworkACLResponseData, IDeleteNetworkACLVariables>(query);
export default deleteNetworkACLMutation;