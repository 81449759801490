import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteAwsSecurityGroupVariables {
  cloudId: number;
  region: string;
  request: {
    groupId: string[];
  };
}

export interface IDeleteAwsSecurityGroupResponseData {
  deleteAwsSecurityGroup: IGqlResponseData<undefined>;
}

const deleteAwsSecurityGroupMutation = () =>
  useMutation<IDeleteAwsSecurityGroupResponseData, IDeleteAwsSecurityGroupVariables>(query);
export default deleteAwsSecurityGroupMutation;
