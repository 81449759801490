import { useMemo } from 'react';
import Box from 'components/atoms/Box';
import Text, { TextType } from 'components/atoms/Text';
import { COLORS } from 'utils/Colors';
import './styles.scss';
import Dropdown from '../Dropdown';
import _ from 'lodash';
import { DropdownListDataType } from '../Dropdown/types';

export enum ContentTypeEnum {
  TEXT = 'TEXT',
  DROPDOWN = 'DROPDOWN',
}

type ContentDropdownType = {
  data: Array<DropdownListDataType>;
  handleClick: (val: DropdownListDataType) => void;
  value: DropdownListDataType;
};

type DetailItemPropsType = {
  title?: string;
  contentType?: ContentTypeEnum;
  contentText?: string;
  contentDropdown?: ContentDropdownType;
};

const DetailItem = (props: DetailItemPropsType) => {
  const { title, contentType, contentText, contentDropdown } = props;

  const contentNode = useMemo(() => {
    if (!contentType) {
      return null;
    }

    switch (true) {
      case contentType === ContentTypeEnum.TEXT && !!contentText:
        return <Text text={contentText ?? ''} type={TextType.Body2Regular} color={COLORS.grey950} />;

      case contentType === ContentTypeEnum.DROPDOWN && contentDropdown !== undefined:
        return (
          <Dropdown
            id={_.uniqueId('dropdown-')}
            data={contentDropdown?.data ?? []}
            value={contentDropdown?.value}
            handleClick={contentDropdown!.handleClick}
          />
        );
      default:
        return null;
    }
  }, [contentType, contentText, contentDropdown]);
  return (
    <Box className="detail-item-container">
      {title && <Text type={TextType.SmallBodyRegular} color={COLORS.grey400} text={title} />}
      {contentNode}
    </Box>
  );
};

export default DetailItem;
