import { useEffect, useState } from 'react';
import './index.scss';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import LabelInput from 'components/v2/LabelInput';
import Labeled from 'components/v2/atoms/Labeled';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import DeleteUserDashboardModal from '../DeleteUserDashboard';
import Icon from 'components/v2/atoms/Icon';
import RedCircleGraph from 'assets/svgs/v4/ico_red_circle_graph.svg';
import { DashboardListType } from 'graphql/types/DashboardListType';
import updateDashboard, { IUpdateDashboardVariables } from 'graphql/mutations/updateDashboard';
import deleteDashboard from 'graphql/mutations/deleteDashboard';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import lazyGetDashboardById from 'graphql/queries/getDashboardById';
import { enumFormatter } from 'utils/Formatter';

interface IUpdateUserDashboardModalProps extends IBaseModalProps {
  dashboardId: number;
  onUpdate: (data: string) => void;
  onDelete: (data: string) => void;
}

const UpdateUserDashboardModal = ({
  dashboardId,
  title,
  onUpdate,
  onDelete,
  ...baseModalProps
}:IUpdateUserDashboardModalProps) => {
  const [parameter, setParameter] = useState({
    id: -1,
    name: '',
    type: '',
    templateId: -1,
    templateName: '',
    organId: -1,
    parameters: ''
  });
  const [requireParams, setRequireParams] = useState<{[key: string]: string}>({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  
  const [getDashboardSpec] = lazyGetDashboardById();
  const [_update] = updateDashboard();
  const [_delete] = deleteDashboard();
  const onUpdateClick = () => {
    const updateDashboardData:IUpdateDashboardVariables = {
      reqData: {
        id: dashboardId,
        name: parameter.name,
        parameters: JSON.stringify(requireParams)
      }
    }

    _update({ variables: updateDashboardData }).then(({ data }) => {
      if (data) {
        if (data.updateDashboard.result === ErrorCode.SUCCESS) {
          useToast(ErrorCode.SUCCESS, 'Selected dashboard is updated successfully');
          onUpdate(parameter.name);
        } else {
          useToast(ErrorCode.UNKNOWN, 'Update selected dashboard failed.');
        }
      } else {
        useToast(ErrorCode.UNKNOWN, 'Update selected dashboard failed.');
      }
    });
  }
  const onDeleteClick = () => {
    _delete({ variables: {id: dashboardId} }).then(({ data }) => {
      if (data) {
        if (data.deleteDashboard.result === ErrorCode.SUCCESS) {
          useToast(ErrorCode.SUCCESS, 'Selected dashboard is deleted successfully');
          onDelete(parameter.name);

          setDeleteModalOpen(false);
        } else {
          useToast(ErrorCode.UNKNOWN, 'Delete selected dashboard failed.');
        }
      } else {
        useToast(ErrorCode.UNKNOWN, 'Delete selected dashboard failed.');
      }
    });
  }

  useEffect(() => {
    if(baseModalProps.open) {
      getDashboardSpec({variables: {id: dashboardId}}).then(res => {
        if(res.data) {
          setParameter(res.data.getDashboardById.data[0]);
          try {
            const requireParamArray = JSON.parse(res.data.getDashboardById.data[0].parameters);
            setRequireParams(requireParamArray);
          } catch {
            setRequireParams({});
          }
        }
      })
    }
  },[baseModalProps.open, dashboardId])

  return (
    <BaseModal
      title={title}
      className="uniform"
      closeIcon
      {...baseModalProps}
    >
      <div className="update-user-dashboard-modal">
        <div className="input-box">
          <LabelInput title="Name"
            value={parameter.name}
            onChangeValue={(val: string) => setParameter(prev => ({
              ...prev,
              name: val
            }))}
            required
            placeholder='Input dashboard name'
          />
          <Labeled
            title="Type"
            required
          >
          <DropdownAtom
            id="type"
            disabled
            placeholder='Select your user dashboard type'
            data={[{name: enumFormatter(parameter.type), value: ''}]} 
            value={{name: enumFormatter(parameter.type), value: ''}} 
            handleClick={() => null}    
          />
          </Labeled>
          <Labeled 
            title="Template"
            required
          >
          <DropdownAtom
            id="template"
            disabled
            placeholder='Select your user dashboard template'
            data={[{name: parameter.templateName, value: parameter.templateId}]} 
            value={{name: parameter.templateName, value: parameter.templateId}} 
            handleClick={() => null}  
          />
          </Labeled>
          <pre className="template-description">
            {parameter.templateId === -1 ? 
              ' - Select your dashboard template -' : 
              `Resource usage dashboard is show ... \n${Object.keys(requireParams).map(d => `${d}: ...`).join('\n')}`
            }
          </pre>
          {parameter.templateId !== -1 && 
            <div className="require-parameter-wrap">
              {Object.keys(requireParams).map(key => 
                <LabelInput 
                  key={key}
                  title={key}
                  value={requireParams[key]}
                  onChangeValue={(val: string) => setRequireParams(prev => ({...prev, [key]: val}))}
                  required
                  placeholder='Input value'
                />
              )}
            </div>
          }
        </div>
        <div className="btns flex j-end">
          <button 
            className="big-sub-btn-body2regular flex j-center a-center"
            onClick={() => setDeleteModalOpen(true)}
          >
            Remove
          </button>
          <button 
            className="big-main-btn-body2regular flex j-center a-center"
            onClick={onUpdateClick}
          >
            Update
          </button>
        </div>
      </div>
      <DeleteUserDashboardModal 
      open={deleteModalOpen}
      title={() => 
        <>
          <Icon width={32} height={32} src={RedCircleGraph} />
          Remove dashboard
        </>
      }
      onClose={() => setDeleteModalOpen(false)}
      onConfirm={onDeleteClick}
      />
    </BaseModal>
  );
};

export default UpdateUserDashboardModal;
