import { useEffect, useState } from 'react';
import './index.scss';

import Labeled from 'components/v2/atoms/Labeled';
import LabelInput from 'components/v2/LabelInput';
import Icon from 'components/v2/atoms/Icon';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import LabelTextarea from 'components/v2/LabelTextarea';
import DeleteOrganizationModal from '../../DeleteOrganizationModal';
import LeaveOrganizationModal from '../../LeaveOrganizationModal';
import UserInfo from 'assets/svgs/v2/ico_user_info_title.svg';
import { useAuth } from 'contexts/AuthProvider';
import { checkLinkedCloudName } from 'utils/v2/ValidCheck';
import deleteOrgan from 'graphql/mutations/deleteOrgan';
import updateOrgan from 'graphql/mutations/updateOrgan';
import apis from 'apis/v2';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import { IUploadProfileImageReq } from 'apis/v2/File/schema';
import { OrganById } from 'apis/v2/Organ/schema';
import { Mb } from 'utils/v2/DummyData/File';

interface IOrganizationProps {
  organSettingOpen: boolean;
  onClose: () => void;
  projectList?: ProjectInfo[];
  organInfo:OrganById,
  onSuccessUpdateOrganInfo: () => void;
  organThumbnail: string;
  onSuccessUploadOrgThumbnail: () => void;
}

const Organization = ({
  organSettingOpen,
  onClose,
  projectList,
  organInfo,
  onSuccessUpdateOrganInfo,
  organThumbnail,
  onSuccessUploadOrgThumbnail
}: IOrganizationProps) => {
  const navigate = useNavigate();
  const { userInfo, role, token } = useAuth();

  const [modalIsOpen, setModalIsOpen] = useState({
    delete: false,
    leave: false
  });
  const [organ, setOrgan] = useState<OrganById>({
    organId: -1,
    name: '',
    description: '',
    createdAt: '',
    createdBy: '',
    createdByUserId: '',
    modifiedAt: '',
    modifiedBy: '',
    modifiedByUserId: '',
    createdByName: '',
    modifiedByName: ''
  });
  
  /* 조직썸네일 업로드 */
  const { mutateAsync: _uploadProfileImage } = useMutation((data: IUploadProfileImageReq) => apis.File.uploadProfileImage(token, data));  
  /* 조직 삭제 */
  const [removeOrgan] = deleteOrgan();
  /* 조직 업데이트 */
  const [editOrgan] = updateOrgan();

  useEffect(() => {
    setOrgan(organInfo);
  }, [organInfo, organSettingOpen])


  return (
    <div id="organization-container">
      <div className="content flex j-between">
        <div className="org-profile">
          <Labeled title="Organization avatar" required>
            <div 
              className="thumbnail-image"
              { ...(organThumbnail && organThumbnail !== '' && { 
                  style: { backgroundImage: `url('${organThumbnail}')`, backgroundSize: 'cover' } 
                })
              }
            >
            </div>
            { role === 'pj_owner' &&
            <>
              <input
                id="read-image"
                type="file"
                accept=".jpg, .png, .gif"
                onChange={(e) => {
                  if (e.target.files) {
                    const file = e.target.files[0];
                    if (!file.name.toLowerCase().endsWith('jpg') && !file.name.toLowerCase().endsWith('png') && !file.name.toLowerCase().endsWith('gif')) {
                      useToast(ErrorCode.UNKNOWN, '.jpg, .png, .gif 확장자만 가능합니다.');

                      return e.target.value ='';
                    }
                    
                    if (file.size > Mb) {
                      useToast(ErrorCode.UNKNOWN, '1MB이하 파일만 첨부해주세요.');

                      return e.target.value = '';
                    }

                    const uploadProfileImageData = {
                      file: file,
                      reqUploadProfileImage : {
                        id: organ.organId,
                        kind: 'organ'
                      }
                    }
                    _uploadProfileImage(uploadProfileImageData).then(({data}) => {
                      if (data) {
                        if(data.result === ErrorCode.SUCCESS) {
                          useToast(ErrorCode.SUCCESS, '사진이 등록되었습니다.');
                          onSuccessUploadOrgThumbnail();
                        } else {
                          if (data.result === ErrorCode.INVALID_EXTENSION) { useToast(ErrorCode.UNKNOWN, '.jpg, .png, .gif 확장자만 가능합니다.');} 
                          else {useToast(ErrorCode.UNKNOWN, '사진등록에 실패했습니다.');}
                          
                          console.log(data.result);
                        }
                      } else {
                        useToast(ErrorCode.UNKNOWN, '사진등록에 실패했습니다.');
                      }
                    })
                  }
                }} 
              />
              <label 
                htmlFor="read-image" 
                className="flex a-center j-center upload-image-button"
              >
                <div className="add-btn" />
                Upload image
              </label>
            </>
            }
          </Labeled>
        </div>
        <div className='organ-field'>
          <LabelInput 
            title="Name"
            required
            value={organ.name}
            disabled={role !== 'pj_owner'}
            onChangeValue={(str) => {
              if (str.length > 30) return;
              setOrgan(prev => ({
                ...prev,
                name: str
              }));
            }}
          />
          <LabelTextarea 
            title="Description" 
            value={organ.description}
            disabled={role !== 'pj_owner'}
            onChangeValue={(str) => setOrgan(prev => ({
              ...prev,
              description: str
            }))}
          />
          <LabelTextarea 
            title="information" 
            required
            value={''} //todo information 값
            disabled={true}
          />
        </div>
      </div>
      <div className="btns flex j-between">
      {/* 조직소유자일때 */}
      {role === 'pj_owner' ? (
        <>
          <button 
            className="dest-btn flex j-center a-center"
            onClick={() => setModalIsOpen(prev => ({
              ...prev,
              delete: true
            }))}
          >
            Delete organization
          </button> 
          <button 
            className="big-main-btn flex j-center a-center"
            disabled={checkLinkedCloudName(organ.name) || organ.name.length > 30 }
            onClick={() => {
              const updateOrganData = {
                organ: {
                  organId: organ.organId,
                  name: organ.name,
                  description: organ.description
                }
              };

              editOrgan({ variables: updateOrganData }).then(({ data }) => {
              if(data) {  
                if (data.updateOrgan.result === ErrorCode.SUCCESS) {
                  useToast(ErrorCode.SUCCESS, 'Organization information saved successfully.');

                  onSuccessUpdateOrganInfo();
                } else {
                  console.log(data.updateOrgan.result);
                  useToast(ErrorCode.UNKNOWN, 'Organization information save fail.');
                }
              } else {
                useToast(ErrorCode.UNKNOWN, 'Organization information save fail.');
              }
              });
            }}
          >
            Save
          </button>
        </>
      ) : 
      <button
        className="dest-btn flex j-center a-center"
        onClick={() => setModalIsOpen(prev => ({
          ...prev,
          leave: true
        })) } 
      >
        leave organization
      </button>
    }
    </div>

      <DeleteOrganizationModal
        organId={organ.organId}
        open={modalIsOpen.delete}
        data={organ.name}
        projectListLength={projectList ? projectList.length : -1}
        title={() => 
          <>
            <Icon width={32} height={32} src={UserInfo} />
            Delete organization
          </>
        }
        closeIcon={false}
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          delete: false
        }))}
        onDelete={() => {
          const delOrganData = {
            organ: {
              organIds: [organ.organId]
            }
          };

          removeOrgan({ variables: delOrganData }).then(({ data }) => {
            if(data) {
              if (data.deleteOrgan.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, 'Organization is deleted successfully.');
                setModalIsOpen(prev => ({
                  ...prev,
                  delete: false
                }));

                navigate('/join-organ', { replace: true });
              } else {
                useToast(ErrorCode.UNKNOWN, 'Organization is deleted failed.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, 'Organization is deleted failed.');
            }
          });
        } } 
      />

      <LeaveOrganizationModal 
        open={modalIsOpen.leave} 
        organId={organ.organId}
        organName={organ.name}
        memberId={String(userInfo?.nemo.memberId)}
        title={() => <>Leave this organization</>} 
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          leave: false
        }))}
        onLeaveSuccess={() => {
          onClose();
          setModalIsOpen(prev => ({
            ...prev,
            leave: false
          }));
          navigate('/join-organ', { replace: true });
        }}  
      />
    </div>
  );
};

export default Organization;
