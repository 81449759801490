import { gql } from '@apollo/client';

const query = gql`
mutation createAwsNetwoklAcl($request: AwsCreateNetworkAclRequest, $cloudId: Long, $region: String) {
  createAwsNetwoklAcl(createNetworkAclRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsCreateNetworkAclResponse {
         networkAcl
        clientToken
      }
    }
  }
}
`;
export default query;
