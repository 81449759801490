import './index.scss';

import EmptyProjectCard from '../cards/EmptyProjectCard';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import { useOutletContext } from 'react-router-dom';

const EmptyPage = () => {
  const { openCreatePJModal } = useOutletContext<{ openCreatePJModal: () => void }>();

  return (
    <div id="empty-page">
      <div className="flex col">
        <EmptyProjectCard 
          className="page" 
          openCreatePJModal={openCreatePJModal}
        />

        <div className="pagination-wrapper flex a-center">
          <p className="flex a-center">Total <span>0</span></p>
          <TablePagination 
            currentPage={1}
            totalPage={1}
          />
        </div>
      </div>
    </div>
  );
};

export default EmptyPage;
