import { useState } from 'react';
import './index.scss';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import LabelInput from 'components/v2/LabelInput';
import Icon from 'components/v2/atoms/Icon';
import QRCode from 'assets/svgs/v4/sample_qr_code.svg';

interface IMFASetupDeviceModalModalProps extends IBaseModalProps {
  content?: () => JSX.Element;
  buttonTitle?: string;
  onConfirm?: (code1:string, code2: string) => void;
}

const MFASetupDeviceModal = ({
  title,
  content,
  buttonTitle,
  onConfirm,
  ...baseModalProps
}:IMFASetupDeviceModalModalProps) => {
  const [code1, setCode1] = useState('');
  const [code2, setCode2] = useState('');
  
  const sendApi = () => {
    //todo gql 쏘고 부모한테 결과 올려주기
    onConfirm && onConfirm(code1, code2);
  }

  return (
    <BaseModal
      title={title}
      className="uniform"
      closeIcon={false}
      {...baseModalProps}
    >
      <div className="mfa-setup-device-modal">
        <h5>
          {content && content()}
        </h5>
        <div className="step-box">
          <div className="step-info-box">
            <div className="flex">
              <label>01.</label>
              Install a compatible application such as Google Authenticator or Authy app on your mobile device or computer.
            </div>
          </div>
          <div className="step-info-box flex col a-center">
            <div className="flex">
              <label>02.</label>
              <pre>Open your authenticator app, choose <span>Show QR code</span> on this page, then use the app to scan the code. Alternatively, you can type a secret key.</pre>
            </div>
            <div className="qr-box flex a-center j-center">
              <Icon width={123} height={123} src={QRCode}/>
            </div>
          </div>
          <div className="step-info-box">
            <div className="flex">
              <label>03.</label>
              Type two consecutive MFA codes below
            </div>
          </div>
        </div>
        <div className="input-box">
          <LabelInput title="Enter a code from your virtual app below"
          value={code1}
          onChangeValue={(val: string) => setCode1(val)}
          required
          placeholder='MFA Code 1'
          />
          <LabelInput title="Wait 30 seconds, and enter a second code entry"
          value={code2}
          onChangeValue={(val: string) => setCode2(val)}
          required
          placeholder='MFA Code 2'
          />
        </div>
        <div className="btns flex j-end">
          <button 
            className="big-sub-btn-body2regular flex j-center a-center"
            onClick={baseModalProps.onClose}  
          >
          Cancel
          </button>
          <button 
            className="big-main-btn-body2regular flex j-center a-center"
            onClick={sendApi}
          >
            {buttonTitle}
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default MFASetupDeviceModal;
