import { gql } from '@apollo/client';

const query = gql`
  mutation deleteDashboard($id: Long) {
  deleteDashboard(id: $id) {
    result
  }
}
`;

export default query;