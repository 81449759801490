import { RoleDetailPropsType } from "./types";
import { Fragment, ReactNode, useMemo, useState } from "react";
import { DETAIL_TAB_LIST, DETAIL_TEXT_DESCRIPTION } from "./configs";
import { IMgdDetailKeyValueProps, IMgdTabProps, TextTypeEnum } from "layouts/v3/MgdLayout";
import { AwsRoleType } from "graphql/types/AwsListRolesResponse";
import { convertSecondToHour, getFormatedDateWithTimezone } from "utils/Common";
import { ColumnType, RowType } from "@Types/v2/Table";
import PageDetailTitle from "../PageDetailTitle";
import DetailTab from "../DetailTab";
import TagTab from "../TagTab";
import Permissions from "./Tabs/Permissions";
import TrustedRelationship from "./Tabs/TrustedRelationship";
import AccessAdvisor from "./Tabs/AccessAdvisor";

const RoleDetail = (props: RoleDetailPropsType) => {
  const {cloudId, region, role, pageBackClick} = props;

  // State
  const [detailTabSelected, setDetailTabSelected] = useState<IMgdTabProps>(DETAIL_TAB_LIST[0]);

  // Detail Section
  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    if (role) {
      return DETAIL_TEXT_DESCRIPTION.map(item => {
        let description = role[item.id as keyof AwsRoleType];

        if (item.id === DETAIL_TEXT_DESCRIPTION[0].id) {
          description = getFormatedDateWithTimezone(description.toString())
        }
        
        if (item.id === DETAIL_TEXT_DESCRIPTION[3].id) {
          description = convertSecondToHour(parseInt(description.toString()));
        }

        return {
          id: item.id,
          type: item.type as TextTypeEnum,
          description: description,
          title: item.value,
        };
      });
    }
    return [];
  }, [role]);

  // Tags Section
  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const tagRows = useMemo((): RowType[] => {
    return role?.tags ?? [];
  }, [role]);

  const detailContentNode = useMemo((): ReactNode => {
    switch (detailTabSelected) {
      default:
      case DETAIL_TAB_LIST[0]:
        return <Permissions cloudId={cloudId} region={region} role={role} />;
      case DETAIL_TAB_LIST[1]:
        return <TrustedRelationship role={role} />;
      case DETAIL_TAB_LIST[2]:
        return <TagTab title={'Tags'} rows={tagRows} columns={tagColumns} />;
      case DETAIL_TAB_LIST[3]:
        return <AccessAdvisor cloudId={cloudId} region={region} role={role} />;
    }
  }, [role, detailTabSelected, cloudId, region]);

  return (
    <div className="ec2-detail">
      <PageDetailTitle title={`Role: ${role?.roleName}`} pageBackClick={pageBackClick} />

      {/* {isLoading ? (
        <div className="detail-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        // Content
      )} */}
      {role &&
        <Fragment>
          <DetailTab title={'Details'} data={detailTabContentData} isApiLoading={false} />

          <div className="tab-container">
            <div className="detail-tab flex a-center">
              {DETAIL_TAB_LIST.map(tab => {
                return (
                  <button
                    className={`detail-tab-items ${detailTabSelected?.id === tab.id && 'active'}`}
                    key={tab.id}
                    data-tab={tab.id}
                    onClick={e => setDetailTabSelected(tab)}
                  >
                    <p>{tab.name}</p>
                  </button>
                );
              })}
            </div>

            <div className="content-tab">{detailContentNode}</div>
          </div>
        </Fragment>
      }
    </div>
  );
}

export default RoleDetail;