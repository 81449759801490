export const RULES_COLUMN_LIST = [
  { field: 'name', label: 'Name', sort: true },
  { field: 'capacity', label: 'Capacity', sort: true },
  { field: 'action', label: 'Action', sort: true },
];

export const TOKEN_DOMAIN_COLUMN_LIST = [{ field: 'name', label: 'Name', sort: true }];

export const METRICS_COLUMNL_LIST = [
  { field: 'name', label: 'Rules', sort: true },
  { field: 'metricName', label: 'CloudWatch metric name', sort: true },
];
