import DetailTable from '../../../DetailTable';
import { ColumnType } from '@Types/v2/Table';
import { useEffect, useState } from 'react';
import lazyIGetResourceShareAssociations from 'graphql/queries/getAwsResourceShareAssociations';

function Sharing({ cloudId, region, data = {} }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  const { subnetArn } = data;

  const [getResourceShareAssociations] = lazyIGetResourceShareAssociations();

  const handleGetData = async () => {
    try {
      setIsLoading(true);
      let arr: any = [];
      const variables = {
        cloudId,
        region,
        request: {
          maxResults: 50,
          associationType: 'RESOURCE',
          resourceArn: subnetArn,
        },
      };

      const { data: resource } = await getResourceShareAssociations({ variables });

      resource?.getResourceShareAssociations?.data.resourceShareAssociations.forEach(item => {
        arr.push({
          name: item.resourceShareName,
          resourceShareArn: item.resourceShareArn,
          status: item.status,
          createdAt: item.creationTime,
          lastUpdate: item.lastUpdatedTime,
        });
      });
      setDataTable(arr);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const columns: ColumnType[] = [
    {
      label: 'Name',
      field: 'name',
      sort: true,
    },
    {
      label: 'Resource share ARN',
      field: 'resourceShareArn',
      sort: true,
    },
    {
      label: 'Status',
      field: 'status',
      sort: true,
    },
    {
      label: 'Created at',
      field: 'createdAt',
      sort: true,
    },
    {
      label: 'Last update',
      field: 'lastUpdate',
      sort: true,
    },
  ];

  return <DetailTable title="Sharing" data={dataTable} columns={columns} isLoading={isLoading} />;
}

export default Sharing;
