import { gql } from '@apollo/client';

const query = gql`
  query getAwsListSigningCertificates($request: AwsListSigningCertificatesRequest, $cloudId: Long, $region: String) {
    getAwsListSigningCertificates(listSigningCertificatesRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListSigningCertificatesResponse {
          certificates {
            userName
            certificateId
            certificateBody
            status
            uploadDate
          }
          isTruncated
          marker
        }
      }
    }
  }
`;
export default query;
