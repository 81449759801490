import lazyGetAwsRouteTables from 'graphql/queries/getAwsRouteTables';
import DetailTable from '../../../DetailTable';
import { ColumnType } from '@Types/v2/Table';
import lazyGetAwsSubnetsPageByParam from 'graphql/queries/getAwsSubnets';
import { useEffect, useState } from 'react';

function SubnetAssociations({ cloudId, region, data = {} }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  const { vpcId, associations = [] } = data;

  const [getAwsSubnet] = lazyGetAwsSubnetsPageByParam();
  const [getAwsRouteTables] = lazyGetAwsRouteTables();

  const handleGetData = async () => {
    try {
      setIsLoading(true);
      let arr: any = [];
      const awsSubnetVariable = {
        cloudId,
        region,
        request: {
          maxResults: 50,
          filters: {
            name: 'vpc-id',
            values: [vpcId],
          },
        },
      };

      const { data: awsSubnetData } = await getAwsSubnet({ variables: awsSubnetVariable });

      if (!awsSubnetData) {
        associations.forEach((row: any) => {
          arr.push({
            name: '-',
            subnetId: row.subnetId,
            ipv4cidr: '-',
            ipv6cidr: '-',
          });
        });
        setDataTable(arr);
        setIsLoading(false);
        return;
      }

      const subnetIds = awsSubnetData.getAwsSubnets?.data?.[0].subnets.map(subnet => subnet.subnetId);
      const awsRoutetableVariable = {
        cloudId,
        region,
        request: {
          maxResults: 50,
          filters: {
            name: 'association.subnet-id',
            values: subnetIds,
          },
        },
      };

      const { data: awsRoutetableData } = await getAwsRouteTables({ variables: awsRoutetableVariable });

      if (!awsRoutetableData) {
        setIsLoading(false);
        return;
      }

      const subnetVpc = awsRoutetableData?.getAwsRouteTables?.data?.[0]?.routeTables?.[0]?.associations?.[0];
      const subnetRoutetable = awsSubnetData.getAwsSubnets?.data?.[0].subnets;
      const subnet = subnetRoutetable.find(item => item.subnetId === subnetVpc?.subnetId);
      const subnetName = subnet?.tags.find(item => item.key === 'Name');

      associations.forEach((row: any) => {
        arr.push({
          name: subnetName?.value,
          subnetId: subnet?.subnetId,
          ipv4cidr: subnet?.cidrBlock,
          ipv6cidr: '-',
        });
      });
      setDataTable(arr);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const columns: ColumnType[] = [
    { label: 'Name', field: 'name', sort: true },
    { label: 'Subnet ID', field: 'subnetId', sort: true },
    { label: 'IPv4 CIDR', field: 'ipv4cidr', sort: true },
    { label: 'IPv6 CIDR', field: 'ipv6cidr', sort: true },
  ];

  return <DetailTable title="Subnet associations" data={dataTable} columns={columns} isLoading={isLoading} />;
}

export default SubnetAssociations;
