import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { DashboardTemplatesType } from 'graphql/types/DashboardTemplatesType';

export interface IGetDashboardTemplatesByTypeVariables {
  type: string
}

export interface IGetDashboardTemplatesByTypeResponseData {
  getDashboardTemplatesByType: IGqlResponseData<DashboardTemplatesType[]>;
}

const lazyGetDashboardTemplatesByType = () => useLazyQuery<IGetDashboardTemplatesByTypeResponseData, IGetDashboardTemplatesByTypeVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetDashboardTemplatesByType;