import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListPoliciesResponse } from 'graphql/types/AwsListPoliciesResponse';

export interface IAwsListPoliciesVariables {
  cloudId: number;
  region: string;
  request: {
    maxItems: number;
    nextToken?: string;
    filters?: {
      name: string;
      values: string[];
    };
  };
}

export interface IAwsListPoliciesResponseData {
  getAwsListPolicies: IGqlResponseNewData<AwsListPoliciesResponse>;
}

const lazyGetAwsListPolicies = () =>
  useLazyQuery<IAwsListPoliciesResponseData, IAwsListPoliciesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListPolicies;
