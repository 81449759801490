import './index.scss';

interface ITableSelectModalProps {
  /* 내 상태 (내가 소유자인지, 관리자인지, 멤버인지) */
  myRole: string; 
  /* 눌린 유저의 상태 (눌린유저가 소유자인지, 관리자인지, 멤버인지) */
  roleCode: string;
  position: {
    top: number,
    left: number
  }
  onClose: () => void;
  openChangeModal: (target: string) => void;
}

const TableSelectModal = ({
  myRole,
  roleCode,
  position,
  onClose,
  openChangeModal
}: ITableSelectModalProps) => {
  return (
    <div 
      className="table-select-background"
      onClick={onClose}
    >
      <ul
        style={{
          top: position.top,
          left: position.left
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/* 상대가 관리자라면 */}
        { roleCode === 'pj_admin' && <li onClick={() => openChangeModal('roleCode')}>Give member role</li> }
        {/* 상대가 일반 멤버라면 */}
        { roleCode === 'pj_membr' && <li onClick={() => openChangeModal('roleCode')}>Give admin role</li> }
        {/* 나는 프로젝트 소유자이고, 상대는 소유자가 아니라면 */}
        { myRole === 'pj_owner' && roleCode !== 'pj_owner' && <li onClick={() => openChangeModal('delegate')}>Transfer ownership</li> }
        {/* 상대가 소유자가 아니면 */}
        { roleCode !== 'pj_owner' && <li className="banish" onClick={() => openChangeModal('discharge')}>Banish from organization</li> }
      </ul>
    </div>
  );
};

export default TableSelectModal;
