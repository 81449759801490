import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';
import { AwsCreateRegexPatternSetResponse } from 'apis/v2/Manage/WafSchema';
import { AwsCreateWebAclRequestType, AwsCreateWebAclResponseType } from 'graphql/types/AwsCreateWebAcl';
import { AwsCreateRuleGroupRequestType, AwsCreateRuleGroupResponseType } from 'graphql/types/AwsCreateRuleGroup';

export type CreateRuleGroupVariablesType = {
  cloudId: number;
  region: string;
  request: AwsCreateRuleGroupRequestType;
};

export interface CreateRuleGroupResponseDataType {
  CreateWebAcl: IGqlResponseNewData<AwsCreateRuleGroupResponseType>;
}

const createRuleGroupMutation = () => useMutation<CreateRuleGroupResponseDataType, CreateRuleGroupVariablesType>(query);
export default createRuleGroupMutation;
