import {gql} from '@apollo/client';

const query = gql`
query getAwsRolePolicy($request: AwsGetRolePolicyRequest, $cloudId: Long, $region: String) {
  getAwsRolePolicy(getRolePolicyRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsGetRolePolicyResponse {
        roleName
        policyName
        policyDocument
      }
    }
    }
  }
`;
export default query;