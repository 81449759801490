import NetworkTitle from 'components/v3/NetworkTitle';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IMgdTabProps, MgdTabLayoutPropsType } from './types';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { MgdTabLayoutTypeEnum } from './configs';

import Icon from 'components/v2/atoms/Icon';
import PinIcon from 'assets/svgs/v3/ico_pin.svg';
import DropdownAtomRelatedCloud from '../MgdLayout/components/DropdownAtomRelatedCloud';

const MgdTabLayout = (props: MgdTabLayoutPropsType) => {
  const {
    layoutType = MgdTabLayoutTypeEnum.MGD_LIST,
    pageTitle,
    hasPrefixIcon,
    hasFavorite,
    isPined = false,
    onBackButtonClicked,
    headerDropList,
    headerDropValue,
    headerDropOnchange,
    pinBtnActHandle,
    pinBtnDisabled,
    mainTabList,
    mainTabItemClick,
    mainTabSelected,
    mainIsLoading,
    detailsExtraData,
    detailContents,
    noDetailsDataMessage,
    detailIsLoading,
    tabContentNode,
    tabSummaryNode,
    recentRelatedClouds,
    relatedCloudSelected,
    recentRelatedCloudOnChange,
  } = props;

  const handleActiveTabClick = (tab: IMgdTabProps) => {
    if (mainTabItemClick) {
      mainTabItemClick(tab);
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const firstHalfRef = useRef<HTMLDivElement>(null);
  const secondHalfRef = useRef<HTMLDivElement>(null);
  const resizerRef = useRef<HTMLDivElement>(null);

  const mgdLayoutRef = useRef<HTMLDivElement>(null);
  const rowOneRef = useRef<HTMLDivElement>(null);
  const rowTwoRef = useRef<HTMLDivElement>(null);

  const [resizeHeight, setResizeHeight] = useState<number>();

  useEffect(() => {
    let heightDependLayout: number = 43;
    const resizeContentHeight =
      Number(mgdLayoutRef.current?.clientHeight) -
      Number(rowOneRef.current?.clientHeight) -
      Number(rowTwoRef.current?.clientHeight) -
      heightDependLayout;

    setResizeHeight(resizeContentHeight);
  }, [mgdLayoutRef, rowOneRef, rowTwoRef, layoutType]);

  const updateStyle = (type: string, currentFirstContent: number, dy: number, dx: number) => {
    const container = containerRef.current;
    const firstHalfEle = firstHalfRef.current;

    if (!container || !firstHalfEle) {
      return;
    }
    if (type === 'height') {
      const containerHeight = container.getBoundingClientRect().height;
      const delta = currentFirstContent + dy;
      const newFirstHalfHeight = (delta * 100) / containerHeight;
      firstHalfEle.style.height = `${newFirstHalfHeight}%`;
    } else {
      const containerWidth = container.getBoundingClientRect().width;
      const delta = currentFirstContent + dx;
      const newFirstHalfWidth = (delta * 100) / containerWidth;
      firstHalfEle.style.width = `${newFirstHalfWidth}%`;
    }
  };

  const updateCursor = () => {
    const container = containerRef.current;
    const firstHalfEle = firstHalfRef.current;
    const resizerEle = resizerRef.current;
    const secondHalfEle = secondHalfRef.current;

    if (!container || !firstHalfEle || !resizerEle || !secondHalfEle) {
      return;
    } else {
      resizerEle.style.cursor = 'ns-resize';
      document.body.style.cursor = 'ns-resize';
      firstHalfEle.style.userSelect = 'none';
      firstHalfEle.style.pointerEvents = 'none';
      secondHalfEle.style.userSelect = 'none';
      secondHalfEle.style.pointerEvents = 'none';
    }
  };

  const resetCursor = () => {
    const container = containerRef.current;
    const firstHalfEle = firstHalfRef.current;
    const resizerEle = resizerRef.current;
    const secondHalfEle = secondHalfRef.current;

    if (!container || !firstHalfEle || !resizerEle || !secondHalfEle) {
      return;
    }

    resizerEle.style.removeProperty('cursor');
    document.body.style.removeProperty('cursor');
    firstHalfEle.style.removeProperty('user-select');
    firstHalfEle.style.removeProperty('pointer-events');
    secondHalfEle.style.removeProperty('user-select');
    secondHalfEle.style.removeProperty('pointer-events');
  };

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      const startPos = {
        x: e.clientX,
        y: e.clientY,
      };
      const currentFirstHeight = firstHalfRef.current?.getBoundingClientRect().height;
      const currentFirstWidth = firstHalfRef.current?.getBoundingClientRect().width;

      const handleMouseMove = (e: any) => {
        const dx = e.clientX - startPos.x;
        const dy = e.clientY - startPos.y;
        if (layoutType === MgdTabLayoutTypeEnum.MGD_LIST_MORE_SUMMARY) {
          updateStyle('width', currentFirstWidth ?? 0, dy, dx);
        } else {
          updateStyle('height', currentFirstHeight ?? 0, dy, dx);
        }

        updateCursor();
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        resetCursor();
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [layoutType],
  );

  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    const touch = e.touches[0];
    const startPos = {
      x: touch.clientX,
      y: touch.clientY,
    };
    const currentFirstHeight = firstHalfRef.current?.getBoundingClientRect().height;
    const currentFirstWidth = firstHalfRef.current?.getBoundingClientRect().width;

    const handleTouchMove = (e: any) => {
      const touch = e.touches[0];
      const dx = touch.clientX - startPos.x;
      const dy = touch.clientY - startPos.y;
      if (layoutType === MgdTabLayoutTypeEnum.MGD_LIST_MORE_SUMMARY) {
        updateStyle('width', currentFirstWidth ?? 0, dy, dx);
      } else {
        updateStyle('height', currentFirstHeight ?? 0, dy, dx);
      }
      updateCursor();
    };

    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
      resetCursor();
    };

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  }, []);

  const renderMainLayout = useMemo(() => {
    return (
      <>
        <div className="row-2 flex a-center" ref={rowTwoRef}>
          {mainTabList?.map(tab => {
            return (
              <button
                className={`tab-items ${mainTabSelected?.id === tab.id && 'active'}`}
                key={tab.id}
                data-tab={tab.id}
                onClick={e => handleActiveTabClick(tab)}
              >
                <p>{tab.name}</p>
              </button>
            );
          })}
        </div>

        <div className="resize-container vertical" ref={containerRef} style={{ height: `${resizeHeight}px` }}>
          <div className="top-half" ref={firstHalfRef}>
            {tabContentNode}
          </div>
          <div className="resizer-bar" ref={resizerRef} onMouseDown={handleMouseDown} onTouchStart={handleTouchStart} />
          <div className="bottom-half" ref={secondHalfRef}>
            {tabSummaryNode}
          </div>
        </div>
      </>
    );
  }, [
    layoutType,
    handleActiveTabClick,
    noDetailsDataMessage,
    mainIsLoading,
    detailIsLoading,
    detailContents,
    detailsExtraData,
  ]);

  return (
    <div id="mgd-list-layout" ref={mgdLayoutRef}>
      <div className="row-1 flex j-between a-center" ref={rowOneRef}>
        <div className="flex j-start a-center" id="title">
          <NetworkTitle
            pageTitle={relatedCloudSelected?.name ?? pageTitle}
            id={321}
            name={relatedCloudSelected?.name ?? pageTitle}
            hasPrefixIcon={hasPrefixIcon}
            hasFavorite={hasFavorite}
            pageBackClick={onBackButtonClicked}
          />
        </div>

        <div className="flex a-center" id="action">
          {recentRelatedClouds && relatedCloudSelected && recentRelatedCloudOnChange && (
            <Fragment>
              {'Related Cloud:'}
              <DropdownAtomRelatedCloud
                disabled={isPined}
                id="related-cloud-dropdown"
                className="related-cloud-select-box"
                data={recentRelatedClouds}
                value={relatedCloudSelected}
                handleClick={(val) => recentRelatedCloudOnChange(val)}
              />
            </Fragment>
          )}
          {headerDropList && headerDropValue && headerDropOnchange && (
            <Fragment>
              {'Region:'}
              <DropdownAtom
                disabled={isPined}
                id="regions-dropdown"
                className="header-select-box"
                data={headerDropList}
                value={headerDropValue}
                handleClick={val => headerDropOnchange(val)}
              />
            </Fragment>
          )}
          {pinBtnDisabled !== undefined && pinBtnActHandle && (
            <Icon
              className={`pin-btn ${pinBtnDisabled ? 'pin-btn-disabled' : ''}`}
              width={16}
              height={16}
              src={PinIcon}
              disabled={pinBtnDisabled}
              onClick={pinBtnActHandle}
            />
          )}
        </div>
      </div>
      {renderMainLayout}
    </div>
  );
};

export default MgdTabLayout;
