import DetailTab from "pages/v2/Organ/Management/components/DetailTab";
import { CryptoConfigDetailPropsType } from "./types";
import { useMemo } from "react";
import { IMgdDetailKeyValueProps, TextTypeEnum } from "layouts/v3/MgdLayout";

const CryptoConfig = (props: CryptoConfigDetailPropsType) => {
  const {metaData} = props;

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {

    const cryptConfigContentData: IMgdDetailKeyValueProps[] = [
      {
        id: 'keyType',
        type: TextTypeEnum.NORMAL,
        title: 'Key type',
        description:
          metaData?.keySpec && !metaData?.keySpec.startsWith('RSA_')
            ? 'Symmetric'
            : 'Asymmetric',
      },
      {
        id: 'origin',
        type: TextTypeEnum.NORMAL,
        title: 'Origin',
        description: metaData.origin || '-',
      },
      {
        id: 'keySpec',
        type: TextTypeEnum.NORMAL,
        title: 'Key Spec',
        description: metaData.keySpec || '-',
      },
      {
        id: 'keyUsage',
        type: TextTypeEnum.NORMAL,
        title: 'Key Usages',
        description: metaData.keyUsage || '-',
      },
    ];

    if (!metaData.keySpec.startsWith('SYMMETRIC_') && metaData.encryptionAlgorithms) {
      cryptConfigContentData.push({
        id: 'encryptionAlgorithms',
        type: TextTypeEnum.NORMAL,
        title: 'Encryption algorithms',
        description: metaData.encryptionAlgorithms,
      });
    }
    if (!metaData.keySpec.startsWith('SYMMETRIC_') && metaData.macAlgorithms) {
      cryptConfigContentData.push({
        id: 'macAlgorithms',
        type: TextTypeEnum.NORMAL,
        title: 'MAC algorithms',
        description: metaData.macAlgorithms,
      });
    }

    return cryptConfigContentData;
  }, [metaData]);


  return (
    <DetailTab title={'Cryptographic configuration'} data={detailTabContentData} isApiLoading={false} />
  );
}

export default CryptoConfig;