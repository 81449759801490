import Table from 'components/v2/dataDisplay/Table';
import './index.scss';
import { ColumnType, RowType } from '@Types/v2/Table';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import { enumFormatter } from 'utils/Formatter';
import { timeToKr } from 'utils/timeFormatter';

interface IDeleteCloudInformationModalProps extends IBaseModalProps {
  data: RowType[]
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteMessageModal = ({  
  data,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteCloudInformationModalProps) => {
  const COLUMNS:ColumnType[] = [
      {
        label: 'Title',
        field: 'title',
        sort: true,
      },
      {
        label: 'Usage',
        field: 'relObjectType',
        sort: true,
        renderCell: (row: RowType) => <>{enumFormatter(row.relObjectType)}</>
      },
      { 
        label: 'Received',
        field: 'sendTypeCode',
        sort: true,
        renderCell: (row: RowType) => <>{enumFormatter(row.sendTypeCode)}</>
      },
      {
        label: 'Updated at',
        field: 'modifiedAt',
        renderCell: (row:RowType) => <>{!row.modifiedAt ? '-' : timeToKr(row.modifiedAt)}</>, 
        sort: true
      }
    ];
  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-message-modal">
        <div className="content">
          <Table rows={data} columns={COLUMNS}/>
        </div>
        <div className="btns flex j-end">
          <button 
            className="big-sub-btn flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            Cancel
          </button>
          <button 
            className="big-main-btn flex j-center a-center" 
            onClick={onDelete}
          >
            Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteMessageModal;
