import './index.scss';
import { useEffect, useState } from 'react';
import PageDetailTitle from '../../PageDetailTitle';
import { policyTab } from '../Common/Constant';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import lazyGetAwsPolicy, { IAwsPolicyVariables } from 'graphql/queries/getAwsPolicy';
import Details from '../Common/Components/Details';
import Permissions from '../Common/Components/Permissions';
import EntitiesAttached from '../Common/Components/EntitiesAttached';
import PolicyVersions from '../Common/Components/PolicyVersions';
import AccessAdvisor from '../Common/Components/AccessAdvisor';

const PolicyDetail = (props: any) => {
  const { data, pageBackClick, cloudId, region } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [dataItem, setDataItem] = useState<any>();
  // @ts-ignore
  const [tabSelected, setTabSelected] = useState(policyTab[0]);

  const [getAwsPolicy] = lazyGetAwsPolicy();


  const handleGetAwsPolicy = (policyArn: string) => {
    const requestVariable: IAwsPolicyVariables = {
      cloudId,
      region,
      request: {
        policyArn,
      },
    };
    return getAwsPolicy({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetDetail = async () => {
    try {
      setIsLoading(true);
      const res = await handleGetAwsPolicy(data?.arn || '');
      setDataItem(res?.data?.getAwsPolicy?.data?.[0]?.policy);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTabSelected(policyTab[0]);

    if (!data?.id) return;

    handleGetDetail();
  }, [data?.id]);

  const renderTab = () => {
    switch (tabSelected.id) {
      case policyTab[0].id:
          return <Permissions cloudId={cloudId} region={region} data={data} pageBackClick={() => {}} />;
        
        case policyTab[1].id:
        return <EntitiesAttached cloudId={cloudId} region={region} data={data} pageBackClick={() => {}} />;
        
        case policyTab[2].id:
        return <PolicyVersions cloudId={cloudId} region={region} data={data} pageBackClick={() => {}} />;

      case policyTab[3].id:
        return <AccessAdvisor cloudId={cloudId} region={region} data={data} pageBackClick={() => {}} />;

      default:
        return null;
    }
  };

  return (
    <div className="page-detail">
      <PageDetailTitle title={`Policy: ${data?.policyName}`} pageBackClick={pageBackClick} />

      {!dataItem && isLoading ? (
        <div className="detail-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        <>
          <Details data={dataItem} />

          <div className="page-detail-tab">
            <div className="detail-tab flex a-center">
              {policyTab.map(tab => {
                return (
                  <button
                    className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
                    key={tab.id}
                    data-tab={tab.id}
                    onClick={e => setTabSelected(tab)}
                  >
                    <p>{tab.name}</p>
                  </button>
                );
              })}
            </div>

            <div className="content-tab">{renderTab()}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default PolicyDetail;
