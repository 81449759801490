import './index.scss';
import { Fragment, useEffect, useRef, useState } from 'react';
import ArrowIcon from 'assets/svgs/v2/ico_dropdown_arrow.svg';
import classNames from 'classnames';
import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';

type ValueType = string | number;

export type DropdownListDataType = {
  id?: number;
  name?: string;
  description?: string;
  value: ValueType;
  disable?: boolean;
  children?: DropdownListDataType[];
  type?: string;
  modifiedAt?: string;
};
interface IDropdownAtomProps {
  id: string;
  data: DropdownListDataType[];
  value: DropdownListDataType;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean | string;
  upsideDown?: number;
  handleClick: (val: DropdownListDataType) => void;
}

const DropdownAtomRelatedCloud = ({
  id,
  data,
  value,
  className,
  placeholder,
  disabled = false,
  error,
  upsideDown,
  handleClick,
}: IDropdownAtomProps) => {
  const listRef = useRef<null | HTMLDivElement>(null);

  const [bg, setBg] = useState({
    width: 0,
    top: 0,
    left: 0,
  });

  const openMenu = (parentId: string) => {
    Array.from(document.getElementsByClassName('dropdown-list')).forEach(el => {
      const parentEl = el.parentElement;
      if (parentEl?.id === parentId) {
        if (el.classList.contains('none')) {
          el.classList.remove('none');
        } else {
          el.classList.add('none');
        }
      } else {
        el.classList.add('none');
      }
    });
  };
  const closeAll = () => {
    Array.from(document.getElementsByClassName('dropdown-list')).forEach(el => el.classList.add('none'));
  };

  useEffect(() => {
    document.addEventListener('click', closeAll);

    return () => {
      document.removeEventListener('click', closeAll);
    };
  }, []);

  const getBetweenTwoDate = (value: any) => {
    if (!value) return 'Not accessed in the tracking period';

    const currentDate = new Date();
    const prevTime = new Date(value);

    // @ts-ignore
    const diffMs = currentDate - prevTime;
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      return 'now';
    } else if (diffDays === 1) {
      return '1 day';
    } else {
      return `${diffDays} days`;
    }
  };

  return (
    <div className={classNames('dropdown-atom-related-cloud', className)} id={`${id}-wrap`}>
      <button
        type="button"
        id={id}
        disabled={disabled}
        className={classNames(error && 'error')}
        onClick={e => {
          e.stopPropagation();
          openMenu(`${id}-wrap`);

          const dropdownAtomEl = document.getElementById(`${id}-wrap`);
          const listWrapEl = document.getElementById(`list-wrap ${id}`);

          if (!dropdownAtomEl || !listWrapEl) return;
          if (
            window.innerHeight - e.currentTarget.getBoundingClientRect().bottom >
            dropdownAtomEl.offsetHeight + listWrapEl.offsetHeight + 5
          ) {
            setBg({
              width: e.currentTarget.offsetWidth,
              top: e.currentTarget.getBoundingClientRect().bottom + 5,
              left: e.currentTarget.getBoundingClientRect().left,
            });
          } else {
            setBg({
              width: e.currentTarget.offsetWidth,
              top:
                e.currentTarget.getBoundingClientRect().bottom -
                (dropdownAtomEl.offsetHeight + listWrapEl.offsetHeight + 5),
              left: e.currentTarget.getBoundingClientRect().left,
            });
          }
        }}
      >
        <input readOnly placeholder={placeholder} value={value.name} disabled={disabled} />
        <img src={ArrowIcon} width={24} height={24} />
      </button>
      {error && typeof error === 'string' && (
        <span className="error-container">
          <img src={InputErrorIcon} width={16} height={16} />
          <p className="error-message">{error}</p>
        </span>
      )}

      {!disabled && (
        <div className="dropdown-list none" ref={listRef}>
          <div
            id={`list-wrap ${id}`}
            className="list-wrapper"
            style={{
              top: bg.top,
              left: bg.left,
            }}
          >
            <ul>
              {data.map((d, idx) =>
                d.type ? (
                  <button
                    key={(Math.random() + 1).toString(36).substring(2, 8)}
                    className="dropdown-btn"
                    onClick={e => {
                      e.stopPropagation();
                      handleClick(d);
                    }}
                  >
                    {d.name}
                  </button>
                ) : (
                  <Fragment key={(Math.random() + 1).toString(36).substring(2, 8)}>
                    <li
                      key={`${idx}-${d.value}`}
                      className={`list-item ${d?.disable ? 'disable' : ''} ${d.children ? 'parent' : ''}`}
                      onClick={e => {
                        if (d?.children) return;
                        e.stopPropagation();
                        handleClick(d);
                        e.currentTarget.parentElement?.parentElement?.parentElement?.classList.add('none');
                        setBg({
                          width: 0,
                          top: 0,
                          left: 0,
                        });
                      }}
                    >
                      <div>
                        {d.name}
                        {d.description && <pre>{d.description}</pre>}
                      </div>

                      <div>{getBetweenTwoDate(d.modifiedAt)}</div>
                    </li>

                    {d?.children?.length
                      ? d.children.map(i => (
                          <li
                            key={`${idx}-${i.value}`}
                            className={`list-item children ${i?.disable ? 'disable' : ''}`}
                            onClick={e => {
                              e.stopPropagation();
                              handleClick(i);
                              e.currentTarget.parentElement?.parentElement?.parentElement?.classList.add('none');
                              setBg({
                                width: 0,
                                top: 0,
                                left: 0,
                              });
                            }}
                          >
                            {i.name}
                            {i.description && <pre>{i.description}</pre>}
                          </li>
                        ))
                      : null}
                  </Fragment>
                ),
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
export default DropdownAtomRelatedCloud;
