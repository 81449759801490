import { gql } from '@apollo/client';

const query = gql`
  query getOrgan($reqData: ReqGetOrganPage) {
    getOrgan(reqGetOrganPage: $reqData) {
      __typename
      first
      number
      numberOfElements
      totalPages
      totalElements 
      last
      size
      content {
        __typename
        ... on OrganInfo {
          __typename
          organId
          name
          description
          projectCnt
          createdAt
          createdBy
          createdByName
          modifiedAt
          modifiedBy
          modifiedByName
          projectCnt
          ownerName
        }
      }
    }
  }
`;

export default query;