import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { NetworkAclRuleEntry } from '../replaceAwsNetworkAcIEntry';

export interface ICreateNetworkAclRuleEntryVariables {
  cloudId: number;
  region: string;
  request: {
    createNetworkAclEntry: NetworkAclRuleEntry[];
  };
}

export interface ICreateNetworkAclEntryResponseData {
  createAwsNetworkAclEntry: IGqlResponseData<undefined>;
}

const createNetworkAcIEntryMutation = () => useMutation<ICreateNetworkAclEntryResponseData, ICreateNetworkAclRuleEntryVariables>(query);
export default createNetworkAcIEntryMutation;