import DetailMultiColumn, { DetailMultiColumProps } from 'layouts/v3/MgdLayout/components/DetailMultiColumns';
import { OverviewDetailPropsType } from './types';
import { Fragment, useMemo } from 'react';

const Overview = (props: OverviewDetailPropsType) => {
  const secretData = useMemo((): DetailMultiColumProps[] => {
    return [
      { title: 'Secret value', subTitle: 'Retreive and view the secret value' },
    ];
  }, []);

  const resourceData = useMemo((): DetailMultiColumProps[] => {
    return [
      {
        title: 'Resource permissions-optional',
        subTitle: 'Add or edit a resource policy to access secrets across AWS accounts.',
      },
    ];
  }, []);

  return (
    <Fragment>
      <DetailMultiColumn
        data={secretData}
        handleHDetailHyperlinkMainClick={() => {}}
        currentTab={{ id: '', name: '', title: '' }}
      />
      <DetailMultiColumn
        data={resourceData}
        handleHDetailHyperlinkMainClick={() => {}}
        currentTab={{ id: '', name: '', title: '' }}
      />
    </Fragment>
  );
};

export default Overview;
