import { Fragment, useCallback, useMemo, useState } from 'react';
import { DetailTablePropsType } from './types';
import Table from 'components/v2/dataDisplay/Table';
import { DEFAULT_TABLE_PAGINATION_STATE } from './configs';
import { OrderDirection } from '@Types/v2/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import './index.scss';
import { orderAlphabetical } from '../../../Utils/Sorting';
import ButtonGroup from '../../../WAF/WebACL/CreateWebACL/components/ButtonGroup';

const DetailTable = (props: DetailTablePropsType) => {
  const { data, columns, title, description, hasPagination = true, rightButtons, caption, reportCheckedList, isLoading } = props;

  const [tablePagination, setTablePagination] = useState(DEFAULT_TABLE_PAGINATION_STATE);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const currentPageRows = useMemo(() => {
    const firstItemIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const lastItemIndex = tablePagination.currentPage * tablePagination.itemPerPage - 1;

    if (hasPagination === false) {
      return orderAlphabetical(data, tablePagination.target, tablePagination.direction);
    }

    return orderAlphabetical(data, tablePagination.target, tablePagination.direction)?.filter(
      (_, index) => index >= firstItemIndex && index <= lastItemIndex,
    );
  }, [data, tablePagination]);

  return (
    <div className="network-detail-table-container">
      <div className="detail-info-title">
        <div>
          <p className="title">
            {title}
            {!!caption && <p className="caption">{caption}</p>}
          </p>
          <p className="description">{description}</p>
        </div>

        <ButtonGroup buttons={rightButtons ?? []} />
      </div>

      <div className="detail-table-content">
        {currentPageRows.length === 0 && isLoading === false ? (
          <p className="empty-row">Empty</p>
        ) : (
          <Fragment>
            <Table
              rows={currentPageRows}
              columns={columns}
              sortOption={{
                target: tablePagination.target,
                direction: tablePagination.direction,
                onChangeSort: (target: string, dir: OrderDirection) => {
                  updateTablePagination('target', target);
                  updateTablePagination('direction', dir);
                },
              }}
              horizontalScrollable={true}
              isLoading={isLoading}
            />
            {hasPagination && (
              <Fragment>
                {data?.length > 0 && !isLoading && (
                  <div className="pagination-wrapper flex a-center">
                    <p className="flex a-center">
                      Total <span>{data.length}</span>
                    </p>
                    <TableManagePagination
                      ableFetchMore={false}
                      currentPage={tablePagination.currentPage}
                      updateCurrentPage={page => updateTablePagination('currentPage', page)}
                      totalPage={Math.ceil(data.length / tablePagination.itemPerPage)}
                    />
                  </div>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default DetailTable;
