import moment from 'moment';
import lazyGetAwsListSecretVersionIds, {
  IAwsLisSecretVersionIdsVariables,
} from 'graphql/queries/getAwsListSecretVersionIds';
import { VersionDetailPropsType } from './types';
import Table from 'components/v2/dataDisplay/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import CopyableText from 'components/v3/CopyableText';
import { getFormatedDateWithTimezone, handleFormatText } from 'utils/Common';
import { IMgdTablePaginationProps } from 'layouts/v3/MgdLayout';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { STAGING_LABLE_MAPPING } from './configs';

const Version = (props: VersionDetailPropsType) => {
  const { cloudId, region, secret } = props;

  const [getAwsListSecretVersionIds, { loading: listVersionLoading }] = lazyGetAwsListSecretVersionIds();

  //getAwsListSecretVersionIds
  const [rows, setRows] = useState<RowType[]>([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 100,
    itemPerPage: 100,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const columns = useMemo((): ColumnType[] => {
    return [
      { label: '', field: '', sort: false, width: 16 },
      {
        field: 'versionId',
        label: 'Version ID',
        sort: true,
        renderCell: row => {
          return <CopyableText value={row?.versionId} text={handleFormatText(row?.versionId)} />;
        },
      },
      {
        field: 'stagingLabel',
        label: 'Staging labels',
        sort: true,
        renderCell: row => {
          return <p className="text-green-01">{handleFormatText(row.stagingLabel)}</p>;
        },
      },
      { field: 'lastAccessed', label: 'Last accessed', sort: true },
      { field: 'createOnUtc', label: 'Created on UTC', sort: true },
    ];
  }, []);

  const rowsCurrentPage = useMemo(() => {
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(rows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [rows, mainTablePagination]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setMainTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const reqVariable = useMemo((): IAwsLisSecretVersionIdsVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: { secretId: secret.arn || secret.name },
    };
  }, [cloudId, region, secret]);

  const fetListVersion = useCallback(() => {
    getAwsListSecretVersionIds({ variables: reqVariable }).then(({data: responseData}) => {
      const listVersionData = responseData?.getAwsListSecretVersionIds?.data?.[0];
      if (listVersionData) {
        const versionIdRows: RowType[] = [];
        const versionIdList = listVersionData?.versions;

        if (versionIdList && versionIdList.length > 0) {
          versionIdList.map(versionIdData => {
            versionIdRows.push({
              id: versionIdData?.versionId,
              versionId: versionIdData?.versionId,
              stagingLabel: STAGING_LABLE_MAPPING[versionIdData?.versionStages.toString() || ''].label,
              lastAccessed: moment(versionIdData?.lastAccessedDate).format('MMMM DD, YYYY'),
              createOnUtc: getFormatedDateWithTimezone(versionIdData?.createdDate),
            });
          });
        }
        setRows(versionIdRows);
      }
    });
  }, [reqVariable]);

  useEffect(() => {
    fetListVersion();
  }, [secret]);

  return (
    <div className="detail-block-container">
      <div className="table-header">Secret versions</div>
      <Table
        rows={rowsCurrentPage}
        columns={columns}
        sortOption={{
          target: mainTablePagination.target,
          direction: mainTablePagination.direction,
          onChangeSort: (target: string, dir: OrderDirection) => {
            updateTablePagination('target', target);
            updateTablePagination('direction', dir);
          },
        }}
        isLoading={listVersionLoading}
        horizontalScrollable={true}
      />
    </div>
  );
};

export default Version;
