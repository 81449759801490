import { useEffect, useMemo, useState } from 'react';
import { OrderDirection, RowType } from '@Types/v2/Table';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import Icon from 'components/v2/atoms/Icon';
import UserInformationDetail from './components/UserInformationDetail';
import UserInformationGrid from './components/UserInformationGrid';
import { useParams } from 'react-router-dom';
import UserIcon from 'assets/svgs/v2/ico_redcircle_user.svg';
import DeleteUserModal from 'components/v2/modals/DeleteUserModal';
import lazyGetMemberPageByParam from 'graphql/queries/getMemberPageByParam';
import deleteMember from 'graphql/mutations/deleteMember';

const UserInformation = () => {

  const { organId } = useParams();

  const [modalIsOpen, setModalIsOpen] = useState({
    delete: false
  }); 
  
  const [isShowing, setIsShowing] = useState<'DATA_GRID' | 'DETAIL'>('DATA_GRID');
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState({
    totalPage: 0,
    totalElement: 0
  });
  const [rows, setRows] = useState<RowType[]>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'createdAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');

  /* 사용자 정보 조회 */
  const [getMemberPageByParam, { loading: loadingGetMemberPageByParam }] = lazyGetMemberPageByParam();
  /* 사용자 삭제 */
  const [delMember] = deleteMember();

  const getMemberPageByParamData = useMemo(() => ({
    reqData: {
      organId: organId ? Number(organId) : undefined, 
      text: search === '' ? undefined : search,
      pageInfo: {
        page: tablePagination.currentPage - 1,
        size: tablePagination.limit,
        orders: [{
          target: tablePagination.target,
          direction: tablePagination.direction
        }]
      }
    }
  }), [ 
    organId,
    tablePagination,
    search,
    isShowing
  ]);

  const renderShowing = useMemo(() => {
    switch (isShowing) {
      default:
        return <UserInformationGrid
          onSubmitSearch={(readyToSearch:string) => { 
            setSearch(readyToSearch);
            setTablePagination(prev => ({
              ...prev,
              currentPage: 1
            }));
          }}
          tablePagination={tablePagination}
          updateTablePagination={(key:string, value: number | string | OrderDirection) => setTablePagination(prev => ({
            ...prev,
            [key]: value
          }))}
          total={total}
          rows={rows}
          checkedList={checkedList}
          reportCheckedList={(list:string[]) => setCheckedList(list)}
          reportSelected={(id:string) => {
            setSelected(id);
            setIsShowing('DETAIL');
          }}
          openDeleteModal={() => setModalIsOpen(prev => ({
            ...prev,
            delete: true
          }))}
          isLoading={loadingGetMemberPageByParam}
        />
      case 'DETAIL': 
        return <UserInformationDetail
          data={rows.find(val => val.id === selected) as RowType} 
          convertGridIsShowing={() => {
            setSelected('');
            setIsShowing('DATA_GRID');
          }}
          openDeleteModal={() =>  {
            setCheckedList([selected]);
            setModalIsOpen(prev => ({
              ...prev,
              delete: true
            }))

         }}    
          onEditSuccess={() => {
            getMemberPageByParamRows();
          }}
        />
    }
  }, [ 
    tablePagination,
    isShowing, 
    rows, 
    total,
    checkedList, 
    selected,
  ])

  const getMemberPageByParamRows = () => {
    getMemberPageByParam({ variables: getMemberPageByParamData }).then(({ data }) => {
      if (data) {
        if (data.getMemberPageByParam) {
          setRows(data.getMemberPageByParam.content)
          setTotal({
            totalPage: data.getMemberPageByParam.totalPages,
            totalElement: data.getMemberPageByParam.totalElements
          })
        }
      }
    })
  }

  /* 사용자 정보 rows 받기 */
  useEffect(() => {
    getMemberPageByParamRows();
  }, [getMemberPageByParamData])

  return (
    <div className='user-information'>
      {renderShowing}
      <DeleteUserModal 
        open={modalIsOpen.delete} 
        data={rows.filter(val => checkedList.includes(String(val.id)))} 
        title={() => 
          <>
            <Icon width={32} height={32} src={UserIcon}  />
            Banish user
          </>
        } 
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          delete: false
        }))}
        onDelete={() => {
          const deleteUserData = {
            reqDelMember: {
              ids: checkedList.map(val => String(val))
            }
          }

          delMember({ variables: deleteUserData}).then(({ data }) => {
            if (data) {
              if(data.deleteMember.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, 'User information is deleted successfully.');
                setModalIsOpen(prev => ({
                  ...prev,
                  delete: false
                }))
                
                if (isShowing === 'DETAIL') {
                  setIsShowing('DATA_GRID');
                }
      
                if ((rows.length === checkedList.length) && total.totalPage !== 1) {
                  setTablePagination(prev => ({
                    ...prev,
                    currentPage: tablePagination.currentPage - 1
                  }));
                } else {
                  getMemberPageByParamRows();
                }
                
                setCheckedList([]);
              } else {
                console.log(data.deleteMember.result);
                useToast(ErrorCode.UNKNOWN, 'Delete user information failed.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, 'Delete user information failed.');
            }
          })
        }} 
      />
    </div>
  );
}
export default UserInformation;