import {gql} from '@apollo/client';

const query = gql`
query getAwsListRolePolicies($request: AwsListRolePoliciesRequest, $cloudId: Long, $region: String) {
  getAwsListRolePolicies(listRolePoliciesRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsListRolePoliciesResponse {
      policyNames
        isTruncated
        marker
        }
    }
  }
}
`;
export default query;