import './index.scss';
import { useState, useMemo, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import InputAtom from 'components/v2/atoms/InputAtom';
import IconDelete from 'assets/svgs/v3/ico_delete_network.svg';
import Icon from 'components/v2/atoms/Icon';
import { IMgdTablePaginationProps } from 'layouts/v3/MgdLayout';

interface IDeleteSecurityGroupModalProps extends IBaseModalProps {
  header: string;
  onDelete: (checkList: string[]) => void;
  rows: RowType[];
}

const DeleteSecurityGroupModal = ({ header, onDelete, rows, ...baseModalProps }: IDeleteSecurityGroupModalProps) => {
  const [payload, setPayload] = useState<string>('');
  const [checkedList, setCheckedList] = useState<string[]>([]);

  const isDisableDeleteButton = useMemo(() => {
    return payload != 'delete' || !checkedList.length;
  }, [payload, checkedList]);

  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 10,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const columns: ColumnType[] = [
    { label: 'Security group', field: 'securityGroup' },
  ];

  const dataTable = rows?.length
    ? rows.map(({ id, groupId }) => ({
        id,
        securityGroup: groupId,
      }))
    : [];

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload('');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconDelete} />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="delete-security-group-modal">
        <Table
          className="info-security-group-modal"
          rows={dataTable}
          columns={columns}
          reportCheckedList={(list: string[]) => {
            setCheckedList(list);
          }}
          sortOption={{
            target: mainTablePagination.target,
            direction: mainTablePagination.direction,
            onChangeSort: () => {},
          }}
        />
        <div className="delete-warning">
          <p>To confirm deletion, type delete in the field.</p>
          <InputAtom
            type={'text'}
            placeholder={'delete'}
            value={payload}
            noClear={true}
            hasPrefixIcon={true}
            onChangeValue={val => setPayload(val)}
          />
        </div>
        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" disabled={isDisableDeleteButton} onClick={() => onDelete(checkedList)}>
            Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteSecurityGroupModal;
