import { useMemo, useState } from 'react';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { IMgdTablePaginationProps } from 'layouts/v3/MgdTabLayout/types';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import './index.scss';
import ButtonGroup from '../../../WAF/WebACL/CreateWebACL/components/ButtonGroup';

type ButtonType = {
  id: string;
  label: string;
  type?: string;
  onClick: () => void;
  dropdownList?: Array<DropdownListDataType>;
};

export type TagPropsType = {
  title: string;
  description?: string;
  rows: RowType[];
  columns: ColumnType[];
  rightButtons?: Array<ButtonType>;
  isLoading?: boolean;
};

const TagTab = (props: TagPropsType) => {
  const { title, description, rows, columns, rightButtons, isLoading = false } = props;

  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const rowsCurrentPage = useMemo(() => {
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(rows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [rows, mainTablePagination]);

  return (
    <div className="tag-tabs">
      <div className="detail-info-title">
        <div>
          <p className="title">
            {title}
            {!!description && <span className="caption">{description}</span>}
          </p>
          <p className="description">{description}</p>
        </div>

        <ButtonGroup buttons={rightButtons ?? []} />
      </div>

      <div className="table-container">
        <Table
          rows={rowsCurrentPage}
          columns={columns}
          sortOption={{
            target: mainTablePagination.target,
            direction: mainTablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {
              setMainTablePagination(prev => ({
                ...prev,
                target: target,
                direction: dir,
              }));
            },
          }}
          horizontalScrollable={true}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default TagTab;
