import './style.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { handleLinkClicked, variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { handleFormatText, handleGetAwsType, randomString } from 'utils/Common';
import TableJsonView from 'layouts/v3/MgdLayout/components/TableJsonView';
import lazyGetAwsListAttachedGroupPolicies, {
  IGetAwsListAttachedGroupPoliciesVariables,
} from 'graphql/queries/getAwsListAttachedGroupPolicies';
import lazyGetAwsPolicy, { IAwsPolicyVariables } from 'graphql/queries/getAwsPolicy';
import lazyGetAwsPolicyVersion, { IAwsPolicyVersionVariables } from 'graphql/queries/getAwsPolicyVersion';
import lazyGetAwsListUserPolicies, { IAwsListUserPoliciesVariables } from 'graphql/queries/getAwsListUserPolicies';
import lazyGetAwsUserPolicy, { IAwsUserPolicyVariables } from 'graphql/queries/getAwsUserPolicy';
import lazyGetAwsListGroupsForUser, { IAwsListGroupsForUserVariables } from 'graphql/queries/getAwsListGroupsForUser';
import lazyGetAwsListAttachedUserPolicies, {
  IAwsListAttachedUserPoliciesVariables,
} from 'graphql/queries/getAwsListAttachedUserPolicies';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { useNavigate } from 'react-router-dom';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import { IAM_TAB_LIST } from '../../../../configs';

const Permissions = (props: any) => {
  const { data = {}, cloudId, region } = props;

  const { userName } = data;

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<any>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [getAwsListAttachedUserPolicies] = lazyGetAwsListAttachedUserPolicies();
  const [getAwsPolicy] = lazyGetAwsPolicy();
  const [getAwsPolicyVersion] = lazyGetAwsPolicyVersion();

  const [getAwsListUserPolicies] = lazyGetAwsListUserPolicies();
  const [getAwsUserPolicy] = lazyGetAwsUserPolicy();

  const [getAwsListGroupsForUser] = lazyGetAwsListGroupsForUser();
  const [getAwsListAttachedGroupPolicies] = lazyGetAwsListAttachedGroupPolicies();

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Policy name',
        field: 'policyName',
        sort: true,
      },
      {
        label: 'Type',
        field: 'type',
        sort: true,
      },
      {
        label: 'Attached via',
        field: 'attachedVia',
        sort: true,
        renderCell: (row: RowType) => {
          const attachedViaArr = row?.attachedVia?.split(' ');

          const isLink = attachedViaArr?.[0] === 'Group';

          return isLink ? (
            <div>
              <span>Group</span>{' '}

              <span
                className="link"
                onClick={e =>
                  handleLinkClicked({
                    navigate,
                    link: '/organ/1/manage/iam',
                    type: ManagementTypeEnum.IAM,
                    tabId: IAM_TAB_LIST[0].id,
                    key: '',
                    value: attachedViaArr?.[1],
                  })
                }
              >
                {handleFormatText(attachedViaArr?.[1])}
              </span>
            </div>
          ) : (
            <div>{row?.attachedVia}</div>
          );
        },
      },
    ];
  }, []);

  const handleGetAwsListAttachedUserPolicies = (userName: string) => {
    const requestVariable: IAwsListAttachedUserPoliciesVariables = {
      cloudId,
      region,
      request: {
        userName,
      },
    };
    return getAwsListAttachedUserPolicies({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsPolicy = (policyArn: string) => {
    const requestVariable: IAwsPolicyVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        policyArn,
      },
    };
    return getAwsPolicy({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsPolicyVersion = (policyArn: string, versionId: string) => {
    const requestVariable: IAwsPolicyVersionVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        policyArn,
        versionId,
      },
    };
    return getAwsPolicyVersion({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsListUserPolicies = (userName: string) => {
    const requestVariable: IAwsListUserPoliciesVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        userName,
      },
    };
    return getAwsListUserPolicies({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsUserPolicy = (userName: string, policyName: string) => {
    const requestVariable: IAwsUserPolicyVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        userName,
        policyName,
      },
    };
    return getAwsUserPolicy({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsListGroupsForUser = (userName: string) => {
    const requestVariable: IAwsListGroupsForUserVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        userName,
      },
    };
    return getAwsListGroupsForUser({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsListAttachedGroupPolicies = (groupName: string) => {
    const requestVariable: IGetAwsListAttachedGroupPoliciesVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        groupName,
      },
    };
    return getAwsListAttachedGroupPolicies({ variables: variableCombineNextToken(requestVariable) });
  };

  const fetchData = useCallback(async () => {
    try {
      if (!userName) return;

      setIsLoading(true);
      const [listAttachedUserPolicies, listUserPolicies, listGroupsForUser] = await Promise.all([
        handleGetAwsListAttachedUserPolicies(userName),
        handleGetAwsListUserPolicies(userName),
        handleGetAwsListGroupsForUser(userName),
      ]);

      const totalListAttachedUserPolicies =
        listAttachedUserPolicies?.data?.getAwsListAttachedUserPolicies?.data[0]?.attachedPolicies?.length || 0;

      const totalListUserPolicies = listUserPolicies?.data?.getAwsListUserPolicies?.data[0]?.policyNames?.length || 0;

      const totalListGroupsForUser = listGroupsForUser?.data?.getAwsListGroupsForUser?.data[0]?.groups?.length || 0;

      const totalResult: RowType[] = [];
      if (listAttachedUserPolicies?.data?.getAwsListAttachedUserPolicies?.data?.[0]?.attachedPolicies?.length) {
        for (let i = 0; i < totalListAttachedUserPolicies; i++) {
          const e = listAttachedUserPolicies?.data?.getAwsListAttachedUserPolicies?.data?.[0]?.attachedPolicies[i];
          const { data: policy } = await handleGetAwsPolicy(e.policyArn);
          const { data: policyVersion } = await handleGetAwsPolicyVersion(
            e.policyArn,
            policy?.getAwsPolicy?.data?.[0]?.policy?.defaultVersionId || '',
          );

          const item = {
            id: randomString(),
            policyName: e?.policyName,
            type: handleGetAwsType(e.policyArn),
            attachedVia: 'Directly',
            jsonContent: {
              title: e?.policyName,
              description: '',
              value: policyVersion?.getAwsPolicyVersion?.data?.[0]?.policyVersion?.document,
              jsonString: decodeURIComponent(
                policyVersion?.getAwsPolicyVersion?.data?.[0]?.policyVersion?.document || '',
              ),
            },
          };

          totalResult.push(item);
        }
      }

      if (listUserPolicies?.data?.getAwsListUserPolicies?.data[0]?.policyNames?.length) {
        for (let i = 0; i < totalListUserPolicies; i++) {
          const e = listUserPolicies?.data?.getAwsListUserPolicies?.data[0]?.policyNames?.[i];
          const { data: userPolicy } = await handleGetAwsUserPolicy(userName, e);

          const item = {
            id: randomString(),
            policyName: e,
            type: 'Customer inline',
            attachedVia: 'Inline',
            jsonContent: {
              title: e,
              description: '',
              value: userPolicy?.getAwsUserPolicy?.data?.[0]?.policyDocument,
              jsonString: decodeURIComponent(userPolicy?.getAwsUserPolicy?.data?.[0]?.policyDocument || ''),
            },
          };

          totalResult.push(item);
        }
      }

      if (listGroupsForUser?.data?.getAwsListGroupsForUser?.data[0]?.groups?.length) {
        for (let i = 0; i < totalListGroupsForUser; i++) {
          const e = listGroupsForUser?.data?.getAwsListGroupsForUser?.data[0]?.groups?.[i];
          const type = handleGetAwsType(e.arn);

          if (type === 'AWS managed' || type === 'Customer managed') {
            const { data: listAttachedGroupPolicies } = await handleGetAwsListAttachedGroupPolicies(e.groupName);

            if (listAttachedGroupPolicies?.getAwsListAttachedGroupPolicies?.data[0]?.attachedPolicies?.length) {
              for (
                let j = 0;
                j < listAttachedGroupPolicies?.getAwsListAttachedGroupPolicies?.data[0]?.attachedPolicies?.length;
                j++
              ) {
                const attachedPolicy =
                  listAttachedGroupPolicies?.getAwsListAttachedGroupPolicies?.data[0]?.attachedPolicies?.[j];

                const { data: policy } = await handleGetAwsPolicy(attachedPolicy?.policyArn);
                const { data: policyVersion } = await handleGetAwsPolicyVersion(
                  attachedPolicy?.policyArn,
                  policy?.getAwsPolicy?.data?.[0]?.policy?.defaultVersionId || '',
                );

                const item = {
                  id: randomString(),
                  policyName: attachedPolicy?.policyName,
                  type: handleGetAwsType(attachedPolicy?.policyArn),
                  attachedVia: `Group ${e.groupName}`,
                  jsonContent: {
                    title: attachedPolicy?.policyName,
                    description: '',
                    value: policyVersion?.getAwsPolicyVersion?.data?.[0]?.policyVersion?.document,
                    jsonString: decodeURIComponent(
                      policyVersion?.getAwsPolicyVersion?.data?.[0]?.policyVersion?.document || '',
                    ),
                  },
                };

                totalResult.push(item);
              }
            }
          }
        }
      }

      setMainTblRows(totalResult);
      setMainTblTotal({
        totalPage: Math.ceil(totalResult.length / tablePagination.itemPerPage),
        totalElement: totalResult.length,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [tablePagination, userName, cloudId, region, mainTblRows]);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    const arr: any = orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );

    return arr;
  }, [userName, mainTblTotal, tablePagination]);

  useEffect(() => {
    fetchData();
  }, [userName]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>Permissions policies</p>

        <p>Permissions are defined by policies attached to the user directly or through groups.</p>
      </div>
      {isLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <div className="detail-info-content">
          {!mainRowsCurrentPage.length && !isLoading ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <TableJsonView
                rows={mainRowsCurrentPage}
                columns={columns}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
                reportCheckedList={() => {}}
                reportSelected={() => {}}
              />

              {mainRowsCurrentPage?.length && !isLoading ? (
                <div className="fleet-instance pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{mainTblTotal.totalElement}</span>
                  </p>

                  <TableManagePagination
                    ableFetchMore={false}
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page => updateTablePagination('currentPage', page)}
                    totalPage={mainTblTotal.totalPage}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Permissions;
