import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import './index.scss';
import LabelInput from 'components/v2/LabelInput';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { TABLE_OFFSET } from 'utils/DummyData/Dropdown';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import Table from 'components/v2/dataDisplay/Table';
import IsLoading from 'components/v2/atoms/IsLoading';
import Labeled from 'components/v2/atoms/Labeled';
import { enumFormatter } from 'utils/Formatter';
import { timeToKr } from 'utils/timeFormatter';
import { useMemo, useState } from 'react';

interface IResourceGridProps {
  relObjTypeList: {
    name: string;
    value: string;
  }[]
  onSubmitSearch: (readyToSearch: {
    relObjectType: string;
    searchText: string;
  }) => void;
  total: {
    totalPage: number;
    totalElement: number;
  }
  rows: RowType[];
  tablePagination: {
    limit: number;
    target: string;
    direction: OrderDirection;
    currentPage: number;
  }
  updateTablePagination: (key: string, value: number | string | OrderDirection) => void;
  reportSelected: (id: string) => void;
  isLoading?: boolean;
}

const ResourceGrid = ({
  tablePagination,
  updateTablePagination,
  relObjTypeList,
  onSubmitSearch,
  total,
  rows,
  reportSelected,
  isLoading
}:IResourceGridProps) => {

  const [readyToSearch, setReadyToSearch] = useState({
    relObjectType: 'all',
    searchText: ''
  });

  const COLUMNS:ColumnType[] = useMemo(() => {
    return [
      {
        label: 'Name',
        field: 'name'
      },
      {
        label: 'Type',
        field: 'relObjectType',
        sort: true,
        width: 150,
        renderCell: (row: RowType) => <>{enumFormatter(row.relObjectType)}</>
      },
      {
        label: 'Format',
        field: 'resFormatCode',
        sort: true,
        width: 150,
        renderCell: (row: RowType) => <>{enumFormatter(row.relObjectType)}</>
      },
      {
        label: 'Used',
        field: 'useYn',
        sort: true,
        width: 150,
        renderCell: (row: RowType) => <>{row.useYn ? 'Yes' : 'No'}</>
      },
      {
        label: 'Updated by',
        field: 'modifiedByName',
        sort: true,
        width: 150
      },
      {
        label: 'Updated at',
        field: 'modifiedAt',
        sort: true,
        width: 120,
        renderCell: (row:RowType) => <>{!row.modifiedAt ? '-' : timeToKr(row.modifiedAt)}</>
      }
    ];
  }, [rows]);
  
  return (
    <div className='resource-grid'>
      {/* 검색영역 */}
      <form 
        className='search-wrap flex a-end'
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitSearch(readyToSearch);
        }}
      >
        <Labeled 
          title="Resource type"
          required
          className="resource-object-type"
          >
          <DropdownAtom 
            id='resource-object'
            data={
              [
                {
                  name: 'All',
                  value: 'all'
                },
                ...relObjTypeList
              ]
            } 
            value={{
              name: readyToSearch.relObjectType === 'all' ? 'All' : relObjTypeList.find(val => val.value === readyToSearch.relObjectType)?.name,
              value: readyToSearch.relObjectType
            }} 
            handleClick={(val) => {setReadyToSearch(prev => ({
              ...prev,
              relObjectType:String(val.value)
            })) }} 
          />
        </Labeled>
        <LabelInput 
          title="Search" 
          required
          placeholder='Input item name'
          value={readyToSearch.searchText}
          onChangeValue={str => setReadyToSearch(prev => ({
            ...prev,
            searchText: str
          }))}
        />
        <div className="btns flex a-end">
        <button 
          type="submit"
          className='big-main-btn'
        >
          Search
        </button>
        </div>
      </form>
      {/* 테이블 데이타 그리드 영역 */}
      <div className='data-contents'>
        <div className="data-grid-wrap">
          <div className="grid-header flex j-between">
            <div className="col-1 flex a-center">
              <div className="header-icon" /> Results <span>{total.totalElement}</span>
            </div>
            <div className="col-2 flex a-center">
              {
                total.totalElement !== 0 &&
                <DropdownAtom 
                  id="offset-limit"
                  data={TABLE_OFFSET}
                  value={{ 
                    name: TABLE_OFFSET.find(data => data.value === tablePagination.limit)?.name,
                    value: Number(tablePagination.limit)
                  }}
                  handleClick={(val) => { 
                    updateTablePagination('limit', Number(val.value))
                    updateTablePagination('currentPage', 1);
                  }}
                />
              }
            </div>
          </div>
          <div className="grid-main">
            { 
              rows.length === 0 
                ? <div className="empty-rows flex j-center a-center">
                  <div className="flex col a-center">
                    <div className='empty-icon' />
                    <h5>There is no item.</h5>
                  </div>
                </div> 
                : <>
                <Table 
                  rows={rows} 
                  columns={COLUMNS} 
                  reportSelected={reportSelected}
                  sortOption={{
                    target: tablePagination.target,
                    direction: tablePagination.direction,
                    onChangeSort: (target: string, dir: OrderDirection) => {
                      updateTablePagination('target', target);
                      updateTablePagination('direction', dir);
                    }
                  }}
                />
                <div className="pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{total.totalElement}</span>
                  </p>
                  <TablePagination
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page => updateTablePagination('currentPage', page)}
                    totalPage={total.totalPage}
                  />
                </div>
                </>
            }
          </div>
        </div>
        { isLoading && <IsLoading dimmed={rows.length !== 0} /> }
      </div>
    </div>
  )
}
export default ResourceGrid;