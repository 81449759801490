import { DropdownListDataType } from "components/Dropdown/types";
import { TextTypeEnum } from "layouts/v3/MgdLayout";

export const SECRET_FILTER_DROPDOWN: DropdownListDataType[] = [
  {id: 1, name: 'Name', value: 'name'},
  {id: 2, name: 'Description', value: 'description'}
];

export const DETAIL_TAB_LIST = [
  {id: 'overview', name: 'Overview', title: 'Overview'},
  {id: 'retation', name: 'Rotations', title: 'Rotations'},
  {id: 'version', name: 'Versions', title: 'Versions'},
  {id: 'replications', name: 'Replication', title: 'Replication'},
  {id: 'tags', name: 'Tags', title: 'Tags'}
];

export const DETAIL_KEYS = ['kmsKeyId', 'description', 'name', 'arn'];
export const DETAIL_TEXT_DES: {id: string, value: string, type: string}[] = [
  {id: 'kmsKeyId', value: 'Encryption key', type: TextTypeEnum.COPY},
  {id: 'description', value: 'Secret description', type: TextTypeEnum.NORMAL},
  {id: 'name', value: 'Secret name', type: TextTypeEnum.COPY},
  {id: 'arn', value: 'Secret ARN', type: TextTypeEnum.COPY}
];