import { ErrorCode, ErrorMessage } from '@Types/error';
import apis from 'apis/v2/index';
import PasswordTitleIcon from 'assets/svgs/v2/ico_pw_title.svg';
import NotificationAtom from 'components/v2/atoms/NotificationAtom';
import LabelInput from 'components/v2/LabelInput';
import { useAuth } from 'contexts/AuthProvider';
import { useToast } from 'hooks/v2/useToast';
import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { pwRegex } from 'utils/Regex';

interface IResetPwProps {
  nextStep: () => void;
}
const ResetPw = ({ nextStep }: IResetPwProps) => {
  const { logout } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const authToken = searchParams.get('authToken');
  
  const { mutateAsync: _resetPw } = useMutation(
    (data: { token: string, userPw: string }) => apis.User.resetPw(data)
  );

  const [inputs, setInputs] = useState({ pw: '', retryPw: '' });
  const [error, setError] = useState('');

  const disabledNextBtn = useMemo(() => {
    if (inputs.pw === '' || inputs.retryPw === '') {
      return true;
    } else {
      return false;
    }
  },[inputs]);

  const changeInputs = (key: 'pw' | 'retryPw', value: string) => {
    setInputs(prev => ({ ...prev, [key]: value }));
  };
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (inputs.pw === inputs.retryPw) {
      if (pwRegex.test(inputs.pw)) {
        if (authToken) {
          _resetPw({ token: authToken, userPw: inputs.pw }).then(({ data }) => {
            if (data.result === ErrorCode.SUCCESS) {
              nextStep();
            } else {
              setError(ErrorMessage[data.result]);
            }
          });
        } else {
          setError('This is an unauthorized access.');
          useToast(ErrorCode.UNKNOWN, 'This is an unauthorized access.');
          navigate('/auth');
        }
      } else {
        setError('Please enter a password that is between 8 and 12 characters long, consisting of a combination of numbers, letters, and special characters.');
      }
    } else {
      setError('The password you entered does not match.');
    }
  };

  return (
    <>
      <article id="reset-pw-new-article">
        <h2><img src={PasswordTitleIcon} width={32} height={32} />Change password</h2>
        <h3>Input your new password</h3>
        <form onSubmit={onSubmit}>
          <LabelInput
            title="New password" 
            id="reset-pw-new" 
            required
            placeholder="Input your new password"
            type="password"
            defaultValue={inputs.pw} 
            onChangeValue={(value: string) => changeInputs('pw', value)} 
          />
          <LabelInput
            title="Confirm password" 
            id="reset-pw-retry" 
            required
            placeholder="Input your confirm password"
            type="password"
            noClear={true}
            defaultValue={inputs.retryPw} 
            onChangeValue={(value: string) => changeInputs('retryPw', value)} 
          />
          {error !== '' && 
          <NotificationAtom text={error} />}
          <div className="button-group">
            <button
              className="big-sub-btn"
              onClick={() => logout()}
            >
              Back to login
            </button>
            <button 
              className="big-main-btn submit-btn" 
              disabled={disabledNextBtn}
            >
              Done
            </button>
          </div>
        </form>
      </article>
    </>
  );
};
export default ResetPw;