import './index.scss';
import { useEffect, useState } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import Icon from 'components/v2/atoms/Icon';
import InboundIcon from 'assets/svgs/v3/ico_inbound.svg';
import OutboundIcon from 'assets/svgs/v3/ico_outbound.svg';
import {
  disabledByType,
  disabledByTypeAndProtocol,
  getPortRangeByTypeAndProtocol,
  getPortRangeDataByType,
  getProtocolByType,
  getProtocolDataByType,
} from '../../Function';
import { randomString } from 'utils/Common';
import MultiSelectAutocomplete from 'components/v2/atoms/AutocompleteInputAtom';
import { RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN, RULE_TYPE_DROPDOWN } from '../SecurityGroup/Constant';

interface IEditNetworkRuleModalProps extends IBaseModalProps {
  header: string;
  isInbound: boolean;
  currentData: RuleRowData[];
  onSave: (data: RuleRowData[]) => void;
}

export interface SourceDestinationSourceType {
  name: string;
  value: string;
}

export interface RuleRowData {
  type: string;
  ipProtocol?: string;
  protocol: string;
  portRange: string;
  source: SourceDestinationSourceType;
  description: string;
}

const actionList: DropdownListDataType[] = [
  { name: 'Allow', value: 'allow' },
  { name: 'Deny', value: 'deny' },
];

interface IEditNetworkRuleTable {
  rows: RuleRowData[];
  sourceDestinationOption?: DropdownListDataType[];
  handleChange: (index: number, value: any, propName: keyof RuleRowData) => void;
  disabledByType: (type: string) => boolean;
  getProtocolDataByType: (type: string) => DropdownListDataType[];
  disabledByTypeAndProtocol: (type: string, protocol: string) => boolean;
  getPortRangeDataByType: (type: string, protocol: string) => DropdownListDataType[];
  handleDelete: (indexToRemove: number) => void;
  handleCreate: () => void;
  btnName?: string;
  isSubmit?: boolean;
}
export const NetworkRuleTable = ({
  rows,
  sourceDestinationOption = [],
  handleChange,
  disabledByType,
  getProtocolDataByType,
  disabledByTypeAndProtocol,
  getPortRangeDataByType,
  handleDelete,
  handleCreate,
  btnName,
  isSubmit,
}: IEditNetworkRuleTable) => {
  return (
    <>
      {!!rows?.length ? (
        <table className="table-rules">
          <thead className="table-header">
            <tr>
              <th>Type</th>
              <th>Protocol</th>
              <th>Port range</th>
              <th>Source</th>
              <th>Description</th>
            </tr>
          </thead>

          <tbody className="table-body">
            {rows.map((row, index) => {
              const isShowDropdow =
                row.type === RULE_TYPE_DROPDOWN[2].value &&
                [
                  RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN[2].value,
                  RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN[4].value,
                  RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN[10].value,
                ].includes(row.protocol);

              return (
                <tr key={index}>
                  <td>
                    <DropdownAtom
                      id={randomString()}
                      data={RULE_TYPE_DROPDOWN}
                      value={{
                        name: RULE_TYPE_DROPDOWN.find(val => val.value === row.type)?.name || '',
                        value: row.type,
                      }}
                      handleClick={val => handleChange(index, val.value as string, 'type')}
                    />
                  </td>
                  <td>
                    {row.type === RULE_TYPE_DROPDOWN[2].value ? (
                      <DropdownAtom
                        id={randomString()}
                        disabled={disabledByType(row.type)}
                        data={getProtocolDataByType(row.type)}
                        value={{
                          name: getProtocolDataByType(row.type).find(val => val.value === row.protocol)?.name || '',
                          value: row.protocol,
                        }}
                        handleClick={val => handleChange(index, val.value as string, 'protocol')}
                      />
                    ) : (
                      <InputAtom
                        value={row.protocol}
                        disabled={row.type !== RULE_TYPE_DROPDOWN[3].value}
                        noClear={true}
                        onChangeValue={(value: string) => handleChange(index, value, 'protocol')}
                        error={isSubmit && !row.protocol}
                      />
                    )}
                  </td>
                  <td>
                    {isShowDropdow ? (
                      <DropdownAtom
                        id={randomString()}
                        disabled={disabledByTypeAndProtocol(row.type, row.protocol)}
                        data={getPortRangeDataByType(row.type, row.protocol)}
                        value={{
                          name:
                            getPortRangeDataByType(row.type, row.protocol).find(val => val.value === row.portRange)
                              ?.name || '',
                          value: row.portRange,
                        }}
                        handleClick={val => handleChange(index, val.value as string, 'portRange')}
                      />
                    ) : (
                      <InputAtom
                        value={row.portRange}
                        disabled={disabledByTypeAndProtocol(row.type, row.protocol)}
                        noClear={true}
                        onChangeValue={(value: string) => {
                          if (!value) {
                            handleChange(index, '0', 'portRange');
                            return;
                          }

                          handleChange(index, value, 'portRange');
                        }}
                      />
                    )}
                  </td>
                  <td>
                    {/* <MultiSelectAutocomplete
                      data={sourceDestinationOption}
                      selectedValues={row.source}
                      placeholder="Select your regions"
                      handleClick={(val: DropdownListDataType) => {
                        const newValue = String(val.value);
                        if (row.source?.length) return;

                        handleChange(index, [newValue], 'source');
                      }}
                      handleRemove={(val: string) => {
                        handleChange(index, [], 'source');
                      }}
                    /> */}

                    <DropdownAtom
                      id={randomString()}
                      data={sourceDestinationOption}
                      value={row.source}
                      handleClick={(val: any) => handleChange(index, val, 'source')}
                      error={isSubmit && !row?.source?.value}
                    />
                  </td>
                  <td>
                    <InputAtom
                      id={randomString()}
                      value={row.description}
                      onChangeValue={(val: any) => handleChange(index, val, 'description')}
                    />
                  </td>
                  <td>
                    <button onClick={() => handleDelete(index)}>{`${btnName ? btnName : 'Remove'}`}</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}

      <button
        className="add-button"
        onClick={() => {
          handleCreate();
        }}
      >
        Add new tag
      </button>
    </>
  );
};

const EditNetworkRuleModal = ({
  header,
  isInbound,
  currentData,
  onSave,
  ...baseModalProps
}: IEditNetworkRuleModalProps) => {
  const [rows, setRows] = useState<RuleRowData[]>([]);

  useEffect(() => {
    if (baseModalProps.open) {
      setRows(currentData);
    }
  }, [baseModalProps.open]);

  const handleCreate = () => {
    setRows(prevState => {
      return prevState.concat({
        type: 'custom_tcp',
        protocol: '6',
        portRange: '0',
        source: {
          name: '',
          value: '',
        },
        description: '',
      });
    });
  };

  const handleDelete = (indexToRemove: number) => {
    const newData = rows.filter((_, index) => index != indexToRemove);
    setRows(newData);
  };

  const handleChange = (index: number, value: string, propName: keyof RuleRowData) => {
    const newRows = rows.map((row, i) => {
      if (i == index) {
        const newRow = { ...row, [propName]: value };
        if (propName === 'type') {
          const newValue = getProtocolByType(value, row.protocol, row.portRange);
          newRow.protocol = newValue.protocol;
          newRow.portRange = newValue.portRange;
        }
        if (propName === 'protocol') {
          newRow.portRange = getPortRangeByTypeAndProtocol(row.type, value, row.portRange);
        }
        return newRow;
      } else {
        return row;
      }
    });
    setRows(newRows);
  };

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={isInbound ? InboundIcon : OutboundIcon} />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-inbound-rules-model">
        <NetworkRuleTable
          rows={rows}
          handleChange={(index: number, value: string, propName: keyof RuleRowData) => {
            handleChange(index, value, propName);
          }}
          disabledByType={(type: string) => disabledByType(type)}
          getProtocolDataByType={(type: string) => getProtocolDataByType(type)}
          disabledByTypeAndProtocol={(type: string, protocol: string) => disabledByTypeAndProtocol(type, protocol)}
          getPortRangeDataByType={(type: string, protocol: string) => getPortRangeDataByType(type, protocol)}
          handleDelete={(indexToRemove: number) => {
            handleDelete(indexToRemove);
          }}
          handleCreate={() => {
            handleCreate();
          }}
        />

        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="save-btn" onClick={() => onSave(rows)}>
            Save changes
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditNetworkRuleModal;
