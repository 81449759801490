import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import InputAtom from 'components/v2/atoms/InputAtom';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import { handleLinkClicked, variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { OrderDirection, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { FilterPropsType } from 'pages/v2/Organ/Management/EC2/components/Image/types';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { handleFormatText } from 'utils/Common';
import { DropdownListDataType } from 'components/Dropdown/types';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import lazyGetAllAwsSubnet from 'graphql/queries/getAwsAllSubnets';
import lazyGetAwsNetworkAcls from 'graphql/queries/getAwsNetworkAcls';
import lazyGetAwsRouteTables from 'graphql/queries/getAwsRouteTables';
import lazyGetAwsAvailabilityZones from 'graphql/queries/getAwsAvailabilityZones';
import { networkACLFilterDropdown, routeTableFilterDropdown, subnetFilterDropdown, vpcFilterDropdown } from '../../../Constants';
import { NETWORK_TAB_LIST } from '../../../configs';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import { useNavigate } from 'react-router-dom';

const Subnets = (props: any) => {
  const {
    cloudId,
    region,
    currentRegion,
    currentRouteState,
    setCurrentRouteState,
    relatedCloudSelected,
    setDetailItem,
    setIsDetailPage,
  } = props;

  const navigate = useNavigate();

  const [getAllAwsSubnet] = lazyGetAllAwsSubnet();
  const [getAwsNetworkAcls] = lazyGetAwsNetworkAcls();
  const [getAwsRouteTables] = lazyGetAwsRouteTables();
  const [getAwsAvailabilityZones] = lazyGetAwsAvailabilityZones();

  const [isLoading, setIsLoading] = useState(false);
  const [nextToken, setNextToken] = useState<string>('');
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const currentFilter = currentRouteState && subnetFilterDropdown.find(e => e.value === currentRouteState.key);
  const [mainFilterValue, setMainFilterValue] = useState<DropdownListDataType>(
    currentFilter || subnetFilterDropdown[0],
  );
  const [searchValue, setSearchValue] = useState<FilterPropsType>({
    name: mainFilterValue.value as string,
    value: currentRouteState?.value || '',
  });
  const [scopeNFilterVariable, setScopeNFilterVariable] = useState<FilterPropsType>();

  useEffect(() => {
    return () => {
      if (!currentRouteState) return;

      setCurrentRouteState(null);
    };
  }, [currentRouteState]);

  const columns = [
    {
      label: 'Name',
      field: 'name',
      sort: true,
      renderCell: (row: RowType) => <>{handleFormatText(row?.name || '-')}</>,
    },
    {
      label: 'Subnet ID',
      field: 'subnetId',
      sort: true,
      renderCell: (row: RowType) => (
        <>
          {
            <div
              className="link"
              onClick={e => {
                e.stopPropagation();
                setDetailItem(row);
                setIsDetailPage();
              }}
            >
              {handleFormatText(row?.subnetId)}
            </div>
          }
        </>
      ),
    },
    {
      label: 'State',
      field: 'state',
      sort: true,
      renderCell: (row: RowType) => (
        <>
          {
            <div className="status">
              <p>{handleFormatText(row?.state)}</p>
            </div>
          }
        </>
      ),
    },
    {
      label: 'VPC',
      field: 'vpcId',
      sort: true,
      renderCell: (row: RowType) => (
        <>
          {
            <div
              className="link"
              onClick={e =>
                handleLinkClicked({
                  navigate,
                  link: '/organ/1/manage/network',
                  type: ManagementTypeEnum.NETWORK,
                  tabId: NETWORK_TAB_LIST[0].id,
                  key: vpcFilterDropdown[0].value.toString(),
                  value: row.vpcId,
                })
              }
            >
              {handleFormatText(row?.vpcId)}
            </div>
          }
        </>
      ),
    },
    { label: 'IPv4 CIDR', field: 'cidrBlock', sort: true },
    {
      label: 'IPv6 CIDR',
      field: 'ipv6CidrBlock',
      sort: true,
      renderCell: (row: RowType) => (
        <>
          {handleFormatText(
            row.ipv6CidrBlockAssociationSet?.ipv6CidrBlock ? row.ipv6CidrBlockAssociationSet.ipv6CidrBlock : '-',
          )}
        </>
      ),
    },
    { label: 'Availability Zone', field: 'availabilityZone', sort: true },
    {
      label: 'Availability Zone ID',
      field: 'availabilityZoneId',
      sort: true,
    },
    {
      label: 'Network border group',
      field: 'networkBorderGroup',
      sort: true,
    },
    {
      label: 'Route table',
      field: 'routeTableId',
      sort: true,
      renderCell: (row: RowType) => (
        <>
          {
            <div
              className="link"
              onClick={e =>
                handleLinkClicked({
                  navigate,
                  link: '/organ/1/manage/network',
                  type: ManagementTypeEnum.NETWORK,
                  tabId: NETWORK_TAB_LIST[1].id,
                  key: routeTableFilterDropdown[0].value.toString(),
                  value: row.routeTableId,
                })
              }
            >
              <p>{handleFormatText(row.routeTableId)}</p>
            </div>
          }
        </>
      ),
    },
    {
      label: 'Network ACL',
      field: 'networkAclId',
      sort: true,
      renderCell: (row: RowType) => (
        <>
          {
            <div
              className="link"
              onClick={e =>
                handleLinkClicked({
                  navigate,
                  link: '/organ/1/manage/network',
                  type: ManagementTypeEnum.NETWORK,
                  tabId: NETWORK_TAB_LIST[6].id,
                  key: networkACLFilterDropdown[0].value.toString(),
                  value: row.networkAclId,
                })
              }
            >
              <p>{handleFormatText(row.networkAclId)}</p>
            </div>
          }
        </>
      ),
    },
    {
      label: 'Default subnet',
      field: 'defaultForAz',
      sort: true,
      renderCell: (row: RowType) => <>{row.defaultForAz ? 'Yes' : 'No'}</>,
    },
    {
      label: 'Auto-assign public IPv4 address',
      field: 'mapPublicIpOnLaunch',
      sort: true,
      renderCell: (row: RowType) => <>{row.mapPublicIpOnLaunch ? 'Yes' : 'No'}</>,
    },
    {
      label: 'Auto-assign customer-owned IPv4 address',
      field: 'mapCustomerOwnedIpOnLaunch',
      sort: true,
      renderCell: (row: RowType) => <>{row.mapCustomerOwnedIpOnLaunch ? 'Yes' : 'No'}</>,
    },
    {
      label: 'Customer-owned IPv4 pool',
      field: 'customerOwnedIpv4Pool',
      sort: true,
      renderCell: (row: RowType) => (
        <>{handleFormatText(row.customerOwnedIpv4Pool ? row.customerOwnedIpv4Pool : '-')}</>
      ),
    },
    {
      label: 'Auto-assign IPv6 address',
      field: 'assignIpv6AddressOnCreation',
      sort: true,
      renderCell: (row: RowType) => <>{row.assignIpv6AddressOnCreation ? 'Yes' : 'No'}</>,
    },
    { label: 'Owner ID', field: 'ownerId', sort: true },
  ];

  const getVariableData = useCallback(() => {
    if (searchValue?.value) {
      return {
        cloudId: relatedCloudSelected.value,
        region: currentRegion.value as string,
        request: {
          maxResults: mainTablePagination.limit,
          filters: {
            name: searchValue?.name || '',
            values: [searchValue?.value.toLocaleLowerCase() || ''],
          },
        },
      };
    }

    return {
      cloudId: relatedCloudSelected.value,
      region: currentRegion.value as string,
      request: {
        maxResults: mainTablePagination.limit,
      },
    };
  }, [currentRegion, currentRouteState, relatedCloudSelected, searchValue]);

  const getVpcMainDataRows = useCallback(
    async (nextToken?: string) => {
      try {
        setIsLoading(true);
        const { data: awsSubnetData } = await getAllAwsSubnet({
          variables: variableCombineNextToken(getVariableData(), nextToken),
        });

        const subnetDataResult: RowType[] = [];
        const getAwsAllSubnets = awsSubnetData?.getAwsAllSubnets;

        if (getAwsAllSubnets) {
          const subnetIds = awsSubnetData.getAwsAllSubnets?.data?.map(subnet => {
            return subnet.subnetId;
          });
          const vpcIds = awsSubnetData.getAwsAllSubnets?.data?.map(subnet => {
            return subnet.vpcId;
          });
          const zoneIds = awsSubnetData.getAwsAllSubnets?.data?.map(subnet => {
            return subnet.availabilityZoneId;
          });

          const variable = {
            cloudId,
            region,
            request: {
              zoneIds: zoneIds,
            },
          };

          const { data: availabilityZone } = await getAwsAvailabilityZones({ variables: variable });

          const routetableVar = {
            cloudId,
            region,
            request: {
              maxResults: 50,
              filters: {
                name: 'association.subnet-id',
                values: subnetIds,
              },
            },
          };

          const { data: routetableData } = await getAwsRouteTables({ variables: routetableVar });

          const networkAclVar = {
            cloudId,
            region,
            request: {
              maxResults: 50,
              filters: {
                name: 'vpc-id',
                values: vpcIds,
              },
            },
          };

          const { data: networkAclData } = await getAwsNetworkAcls({ variables: networkAclVar });

          const zones = availabilityZone?.getAwsAvailabilityZones?.data.availabilityZones;
          const routeTables = routetableData?.getAwsRouteTables?.data?.[0]?.routeTables;
          const networkAcls = networkAclData?.getAwsNetworkAcls?.data?.[0]?.networkAcls;
          const subnets = awsSubnetData?.getAwsAllSubnets?.data;

          let listRouteTableWithSubnet: any[] = [];
          let listNetworkAclWithSubnet: any[] = [];

          routeTables?.forEach(item => {
            listRouteTableWithSubnet = listRouteTableWithSubnet.concat(item?.associations);
          });
          networkAcls?.forEach(item => {
            listNetworkAclWithSubnet = listNetworkAclWithSubnet.concat(item?.associations);
          });

          subnets?.map(subnet => {
            const routeTable = listRouteTableWithSubnet?.find(item => item.subnetId === subnet.subnetId);
            const networkAcl = listNetworkAclWithSubnet?.find(item => item.subnetId === subnet.subnetId);
            const zone = zones?.find(item => item.zoneId === subnet.availabilityZoneId);

            subnetDataResult.push({
              ...subnet,
              id: subnet.subnetId,
              name: subnet?.tags?.find(t => t?.key == 'Name')?.value,
              routeTableId: routeTable?.routeTableId || '-',
              networkAclId: networkAcl?.networkAclId || '-',
              networkBorderGroup: zone?.networkBorderGroup || '-',
            });
          });

          let allAwsSubnets: RowType[] = [];
          if (nextToken) {
            allAwsSubnets = [...mainTblRows, ...subnetDataResult];
          } else {
            allAwsSubnets = subnetDataResult;
          }
          setNextToken(getAwsAllSubnets?.nextToken as string);
          setMainTblRows(allAwsSubnets);
          setMainTblTotal({
            totalPage: Math.ceil(allAwsSubnets.length / mainTablePagination.itemPerPage),
            totalElement: allAwsSubnets.length,
          });
          setIsLoading(false);
        } else {
          setMainTblRows([]);
          setNextToken('');
          setIsLoading(false);
        }
      } catch {
        setIsLoading(false);
      }
    },
    [cloudId, region, mainTblRows, mainTablePagination, getVariableData, searchValue],
  );

  const mainRowsCurrentPage = useMemo((): RowType[] => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!nextToken) {
      getVpcMainDataRows(nextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    setMainTblTotal({
      totalPage: Math.ceil(mainTblRows.length / mainTablePagination.itemPerPage),
      totalElement: mainTblRows.length,
    });

    return orderAlphabetical(mainTblRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, scopeNFilterVariable, mainTablePagination, nextToken]);

  useEffect(() => {
    getVpcMainDataRows();
  }, [cloudId]);

  const handleSearch = useCallback(() => {
    if (searchValue?.value === scopeNFilterVariable?.value && searchValue.name === scopeNFilterVariable?.name) return;

    updateTablePagination('currentPage', 1);
    setScopeNFilterVariable(searchValue);
    getVpcMainDataRows();
  }, [searchValue, scopeNFilterVariable]);

  const handleSelectItem = useCallback(
    (id: string) => {
      const rowSelected = mainTblRows.find(row => row.id === id);

      if (!rowSelected) return;

      setDetailItem(rowSelected);
    },
    [mainTblRows],
  );

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>Subnets</p>
        </div>

        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={subnetFilterDropdown}
            value={mainFilterValue}
            handleClick={val => {
              setMainFilterValue(val);
              setSearchValue({ name: val?.value?.toString(), value: searchValue.value });
            }}
          />

          <InputAtom
            placeholder=""
            type={'text'}
            value={searchValue.value}
            defaultValue={''}
            onChangeValue={e => setSearchValue({ name: mainFilterValue?.value?.toString(), value: e })}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={handleSearch}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSearch();
              }
            }}
          />
          <button className="action-btn" onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>

      {!mainRowsCurrentPage?.length && !isLoading ? (
        <div className="data-grid-wrap">
          <p className="empty-row">Empty</p>
        </div>
      ) : (
        <div className="data-grid-wrap">
          <Table
            rows={mainRowsCurrentPage}
            columns={columns}
            reportCheckedList={() => {}}
            reportSelected={id => handleSelectItem(id)}
            sortOption={{
              target: mainTablePagination.target,
              direction: mainTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateTablePagination('target', target);
                updateTablePagination('direction', dir);
              },
            }}
            isLoading={isLoading}
            horizontalScrollable
          />

          {!!mainRowsCurrentPage?.length && !isLoading && (
            <div className="pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{mainTblTotal.totalElement}</span>
              </p>
              <TableManagePagination
                ableFetchMore={!!nextToken}
                currentPage={mainTablePagination.currentPage}
                updateCurrentPage={page =>
                  setMainTablePagination(prev => ({
                    ...prev,
                    ['currentPage']: page,
                  }))
                }
                totalPage={mainTblTotal.totalPage}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default Subnets;
