import { gql } from '@apollo/client';

const query = gql`
  query getAwsUserPolicy($request: AwsGetUserPolicyRequest, $cloudId: Long, $region: String) {
    getAwsUserPolicy(getUserPolicyRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsGetUserPolicyResponse {
          userName
          policyName
          policyDocument
        }
      }
    }
  }
`;
export default query;
