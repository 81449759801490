import { gql } from '@apollo/client';

const query = gql`
  mutation authorizeAwsSecurityGroupIngress(
    $request: AwsAuthorizeSecurityGroupIngressRequest
    $cloudId: Long
    $region: String
  ) {
    authorizeAwsSecurityGroupIngress(
      authorizeSecurityGroupIngressRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsAuthorizeSecurityGroupIngressResponse {
          returnValue
          securityGroupRules {
            securityGroupRuleId
            groupId
            groupOwnerId
            isEgress
            ipProtocol
            fromPort
            toPort
            cidrIpv4
            cidrIpv6
            prefixListId
            referencedGroupInfo {
              groupId
              peeringStatus
              userId
              vpcId
              vpcPeeringConnectionId
            }
            description
            tags {
              key
              value
            }
          }
        }
      }
    }
  }
`;
export default query;
