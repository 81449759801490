import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import InputAtom from 'components/v2/atoms/InputAtom';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import { handleLinkClicked, variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { OrderDirection, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { FilterPropsType } from 'pages/v2/Organ/Management/EC2/components/Image/types';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { handleFormatText } from 'utils/Common';
import { DropdownListDataType } from 'components/Dropdown/types';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import MgdTooltip from 'components/v2/MgdTooltip';
import lazyGetAllAwsRouteTable from 'graphql/queries/getAwsAllRouteTable';
import { routeTableFilterDropdown, subnetFilterDropdown, vpcFilterDropdown } from '../../../Constants';
import { useNavigate } from 'react-router-dom';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import { NETWORK_TAB_LIST } from '../../../configs';

const RouteTable = (props: any) => {
  const {
    cloudId,
    region,
    currentRegion,
    currentRouteState,
    setCurrentRouteState,
    relatedCloudSelected,
    setDetailItem,
    setIsDetailPage,
  } = props;

  const navigate = useNavigate();

  const [getAllAwsRouteTable] = lazyGetAllAwsRouteTable();

  const [isLoading, setIsLoading] = useState(false);
  const [nextToken, setNextToken] = useState<string>('');
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const currentFilter = currentRouteState && routeTableFilterDropdown.find(e => e.value === currentRouteState.key);
  const [mainFilterValue, setMainFilterValue] = useState<DropdownListDataType>(
    currentFilter || routeTableFilterDropdown[0],
  );
  const [searchValue, setSearchValue] = useState<FilterPropsType>({
    name: mainFilterValue.value as string,
    value: currentRouteState?.value || '',
  });
  const [scopeNFilterVariable, setScopeNFilterVariable] = useState<FilterPropsType>();

  useEffect(() => {
    return () => {
      if (!currentRouteState) return;

      setCurrentRouteState(null);
    };
  }, [currentRouteState]);

  const columns = [
    {
      label: 'Name',
      field: 'name',
      sort: true,
      renderCell: (row: RowType) => <>{handleFormatText(row?.name || '-')}</>,
    },
    {
      label: 'Route Table ID',
      field: 'routeTableId',
      sort: true,
      renderCell: (row: RowType) => (
        <>
          {
            <div
              className="link"
              onClick={e => {
                e.stopPropagation();
                setDetailItem(row);
                setIsDetailPage();
              }}
            >
              {handleFormatText(row?.routeTableId)}
            </div>
          }
        </>
      ),
    },
    {
      label: 'Explict subnet associations',
      field: 'associations',
      sort: true,
      renderCell: (row: RowType) => (
        <>
          {row?.associations?.length == 1 ? (
            <div
              className="link"
              onClick={e => {
                handleLinkClicked({
                  navigate,
                  link: '/organ/1/manage/network',
                  type: ManagementTypeEnum.NETWORK,
                  tabId: NETWORK_TAB_LIST[2].id,
                  key: subnetFilterDropdown[0].value.toString(),
                  value: row.associations[0].subnetId,
                });
              }}
            >
              {handleFormatText(row.associations[0].subnetId)}
            </div>
          ) : (
            row?.associations?.filter((item: any) => item?.subnetId)?.length > 0 && (
              <div id={`my-tooltip-${row?.routeTableId}`}>
                <MgdTooltip id={row?.routeTableId} title={`${row.associations?.length} subnets`}>
                  {row?.associations?.length > 1 &&
                    row?.associations.map((item: any, index: number) => {
                      if (item.subnetId) {
                        return (
                          <div
                            className="link"
                            onClick={e => {
                              handleLinkClicked({
                                navigate,
                                link: '/organ/1/manage/network',
                                type: ManagementTypeEnum.NETWORK,
                                tabId: NETWORK_TAB_LIST[2].id,
                                key: subnetFilterDropdown[0].value.toString(),
                                value: item.subnetId,
                              });
                            }}
                            key={index}
                          >
                            {handleFormatText(item.subnetId)}
                          </div>
                        );
                      }
                    })}
                </MgdTooltip>
              </div>
            )
            
          )}
        </>
      ),
    },
    { label: 'edge associations', field: 'edgeAssociations', sort: true },
    {
      label: 'Main',
      field: 'main',
      sort: true,
    },
    {
      label: 'VPC',
      field: 'vpcId',
      sort: true,
      renderCell: (row: RowType) => (
        <>
          {
            <div
              className="link"
              onClick={e => {
                handleLinkClicked({
                  navigate,
                  link: '/organ/1/manage/network',
                  type: ManagementTypeEnum.NETWORK,
                  tabId: NETWORK_TAB_LIST[0].id,
                  key: vpcFilterDropdown[0].value.toString(),
                  value: row.vpcId
                });
              }}
            >
              {handleFormatText(row?.vpcId)}
            </div>
          }
        </>
      ),
    },
    { label: 'Owner', field: 'ownerId', sort: true },
  ];

  const getVariableData = useCallback(() => {
    if (searchValue?.value) {
      return {
        cloudId: relatedCloudSelected.value,
        region: currentRegion.value as string,
        request: {
          maxResults: mainTablePagination.limit,
          filters: {
            name: searchValue?.name || '',
            values: [searchValue?.value.toLocaleLowerCase() || ''],
          },
        },
      };
    }

    return {
      cloudId: relatedCloudSelected.value,
      region: currentRegion.value as string,
      request: {
        maxResults: mainTablePagination.limit,
      },
    };
  }, [currentRegion, currentRouteState, relatedCloudSelected, searchValue]);

  const getVpcMainDataRows = useCallback(
    async (nextToken?: string) => {
      try {
        setIsLoading(true);
        const { data: awsRoutetableData } = await getAllAwsRouteTable({
          variables: variableCombineNextToken(getVariableData(), nextToken),
        });

        const routeTableData: RowType[] = [];
        const getAwsAllRouteTable = awsRoutetableData?.getAwsAllRouteTable;
        if (getAwsAllRouteTable) {
          getAwsAllRouteTable?.data?.map(routetable => {
            routeTableData.push({
              ...routetable,
              name: routetable?.tags?.find(t => t?.key == 'Name')?.value,
              id: routetable.routeTableId,
              main: routetable?.associations?.length == 1 ? (routetable.associations[0].main ? 'Yes' : 'No') : 'No',
            });
          });
          let allAwsRouteTables: RowType[] = [];
          if (nextToken) {
            allAwsRouteTables = [...mainTblRows, ...routeTableData];
          } else {
            allAwsRouteTables = routeTableData;
          }
          setNextToken(getAwsAllRouteTable?.nextToken as string);
          setMainTblRows(allAwsRouteTables);
          setMainTblTotal({
            totalPage: Math.ceil(allAwsRouteTables.length / mainTablePagination.itemPerPage),
            totalElement: allAwsRouteTables.length,
          });
          setIsLoading(false);
        } else {
          setMainTblRows([]);
          setNextToken('');
          setIsLoading(false);
        }
      } catch {
        setIsLoading(false);
      }
    },
    [cloudId, region, mainTblRows, mainTablePagination, getVariableData, searchValue],
  );

  const mainRowsCurrentPage = useMemo((): RowType[] => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!nextToken) {
      getVpcMainDataRows(nextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    setMainTblTotal({
      totalPage: Math.ceil(mainTblRows.length / mainTablePagination.itemPerPage),
      totalElement: mainTblRows.length,
    });

    return orderAlphabetical(mainTblRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, scopeNFilterVariable, mainTablePagination, nextToken]);

  useEffect(() => {
    getVpcMainDataRows();
  }, [cloudId]);

  const handleSearch = useCallback(() => {
    if (searchValue?.value === scopeNFilterVariable?.value && searchValue.name === scopeNFilterVariable?.name) return;

    updateTablePagination('currentPage', 1);
    setScopeNFilterVariable(searchValue);
    getVpcMainDataRows();
  }, [searchValue, scopeNFilterVariable]);

  const handleSelectItem = useCallback(
    (id: string) => {
      const rowSelected = mainTblRows.find(row => row.id === id);

      if (!rowSelected) return;

      setDetailItem(rowSelected);
    },
    [mainTblRows],
  );

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>Route Tables</p>
        </div>

        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={routeTableFilterDropdown}
            value={mainFilterValue}
            handleClick={val => {
              setMainFilterValue(val);
              setSearchValue({ name: val?.value?.toString(), value: searchValue.value });
            }}
          />

          <InputAtom
            placeholder=""
            type={'text'}
            value={searchValue.value}
            defaultValue={''}
            onChangeValue={e => setSearchValue({ name: mainFilterValue?.value?.toString(), value: e })}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={handleSearch}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSearch();
              }
            }}
          />
          <button className="action-btn" onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>

      {!mainRowsCurrentPage?.length && !isLoading ? (
        <div className="data-grid-wrap">
          <p className="empty-row">Empty</p>
        </div>
      ) : (
        <div className="data-grid-wrap">
          <Table
            rows={mainRowsCurrentPage}
            columns={columns}
            reportCheckedList={() => {}}
            reportSelected={id => handleSelectItem(id)}
            sortOption={{
              target: mainTablePagination.target,
              direction: mainTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateTablePagination('target', target);
                updateTablePagination('direction', dir);
              },
            }}
            isLoading={isLoading}
            horizontalScrollable
          />

          {!!mainRowsCurrentPage?.length && !isLoading && (
            <div className="pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{mainTblTotal.totalElement}</span>
              </p>
              <TableManagePagination
                ableFetchMore={!!nextToken}
                currentPage={mainTablePagination.currentPage}
                updateCurrentPage={page =>
                  setMainTablePagination(prev => ({
                    ...prev,
                    ['currentPage']: page,
                  }))
                }
                totalPage={mainTblTotal.totalPage}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default RouteTable;
