import { useLazyQuery } from '@apollo/client';
import { Paging } from 'graphql/types/Paging';
import { ResponsePage } from 'graphql/types/ResponsePage';
import query from './query';
import { OrganInfo } from 'graphql/types/OrganInfo';

export interface IGetOrganVariables {
  reqData: {
    text: string;
    pageInfo: Paging;
  }
}

export interface IGetOrganResponseData {
  getOrgan: ResponsePage<OrganInfo>;
}

/** 조직 정보 조회 (page) */
const lazyGetOrgan = () => useLazyQuery<IGetOrganResponseData, IGetOrganVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetOrgan;