export const COLORS = {
  GREY1: '#bcbcbc',
  grey50: '#f7f7f7',
  grey100: '#f2f2f2',
  grey200: '#E0E0E0',
  grey300: '#D2D2D2',
  grey400: '#a8a8a8',
  grey500: '#8e8e8e',
  grey600: '#696969',
  grey700: '#5e5e5e',
  grey950: '#515151',
  backgroundGrey: '#f2f2f2',
  disabledGrey: '#797878',
  lightBlack: '#515151',
  black: '#515151',
  green01: '#758c8b',
  green02: '#3b5b5a',
  green03: '#567b7a',
  green04: '#88a5a4',
  greenBackground: '#f0f4f4',
  greenLabel: '#DEEFEE',
  lightGreenBackground: '#f1f7f7',
  white: '#FFF',
  red: '#9a011c',
  red1: '#F24343',
  warningRed: '#f44336',
  lightRed: '#b46674',
  redBackground: '#f4e7eb',
  yellow: '#ffd600',
  yellowBackground: '#fff7db',
  orange: '#e58900',
  indigo700: '#4338ca',
  indigo500: '#6366f1',
  indigo100: '#e0e7ff',
  indigo50: '#eef2ff',
  greenOuterLine1: '#AFCFCE',
  lightGray: '#D9D9D9',
};
