import './style.scss';
import { useCallback, useEffect, useState } from 'react';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import lazyGetAwsPolicyVersion, { IAwsPolicyVersionVariables } from 'graphql/queries/getAwsPolicyVersion';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';

const Permissions = (props: any) => {
  const { data = {}, cloudId, region } = props;

  const { arn = '', defaultVersionId = '' } = data;

  const [isLoading, setIsLoading] = useState(false);
  const [document, setDocument] = useState('');

  const [getAwsPolicyVersion] = lazyGetAwsPolicyVersion();

  const handleGetAwsPolicyVersion = (policyArn: string, versionId: string) => {
    const requestVariable: IAwsPolicyVersionVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        policyArn,
        versionId,
      },
    };
    return getAwsPolicyVersion({ variables: variableCombineNextToken(requestVariable) });
  };

  const fetchData = useCallback(async () => {
    try {
      if (!arn || !defaultVersionId) return;

      setIsLoading(true);
      const { data: dataAwsPolicyVersion } = await handleGetAwsPolicyVersion(arn || '', defaultVersionId || '');
      setDocument(dataAwsPolicyVersion?.getAwsPolicyVersion?.data?.[0]?.policyVersion?.document || '');

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [data, cloudId, region]);

  useEffect(() => {
    fetchData();
  }, [data]);

  const copyToClipBoard = (value: string) => {
    navigator.clipboard.writeText(value).then(
      function () {
        useToast(ErrorCode.SUCCESS, 'Copied');
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      },
    );
  };

  return (
    <div className="detail-info">
      <div className="detail-info-title detail-info-title-permissions">
        <div>
          <p>Permissions defined in this policy</p>

          <p>
            Permissions defined in this policy document specify which actions are allowed or denied. To define
            permissions for an IAM identity (user, user group, or role), attach a policy to it.
          </p>
        </div>

        <button className="btn-copy" onClick={() => copyToClipBoard(document)}>
          Copy
        </button>
      </div>
      {isLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <div className="detail-info-content">
          <div className="json-content">
            <pre>{decodeURIComponent(document)}</pre>
          </div>
        </div>
      )}
    </div>
  );
};

export default Permissions;
