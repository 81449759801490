import VerticalSplitLinkGroup from 'components/v2/VerticalSplitLinkGroup';
import { useMemo, useState } from 'react';
import { termLinks } from 'utils/Links';
import BackIcon from 'assets/svgs/v2/ico_back.svg';
import UserTitleIcon from 'assets/svgs/v2/ico_user_info_title.svg';
import LabelInput from 'components/v2/LabelInput';
import { emailRegex, koEnNumSpRegex, nameRegex, onlyNumRegex } from 'utils/Regex';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import PhoneInput from 'components/v2/PhoneInput';
import { useMutation } from 'react-query';
import { IRegisterReq, IRegisterWithInvitationReq } from 'apis/v2/SignUp/schema';
import apis from 'apis/v2/index';
import { useToast } from 'hooks/v2/useToast';
import { IGetTermsReq } from 'apis/v2/Terms/schema';
import { ErrorCode } from '@Types/error';
import { useAuth } from 'contexts/AuthProvider';

interface ICheckInfoProps {
  idInputs: { id: string; pw: string; };
  inputs: { email: string; name: string; role: string; phone: string; countryCode: string; checked: boolean; };
  updateInputs: (key: 'email' | 'name' | 'role' | 'phone' | 'countryCode' | 'checked', value: string | boolean) => void;
  goPrevStep: () => void;
}

const CheckInfo = ({ idInputs, inputs, updateInputs, goPrevStep }:ICheckInfoProps) => {
  const { logout } = useAuth();
  const { signWithInv } = useOutletContext<{ signWithInv: { memberId: string; email: string; invitation: boolean; }}>();
  
  const navigate = useNavigate();
  const [error, setError] = useState<{ type: 'email' | 'name' | 'role' | 'phone', msg: string }>({ type: 'email', msg: '' });
  const [countryCode, setContryCode] = useState('+82');
  
  const { mutateAsync: _register } = useMutation((payload:IRegisterReq) => apis.SignUp.register(payload));
  const { mutateAsync: _registerWithInvitation } = useMutation((payload: IRegisterWithInvitationReq) => apis.SignUp.registerWithInvitation(payload));
  const { mutateAsync: _getTerms } = useMutation((payload:IGetTermsReq) => apis.Terms.getTerms(payload));

  const disabledNextBtn = useMemo(() => {
    if (inputs.email.length === 0 || inputs.name.length === 0) {
      return true;
    } else {
      return false;
    }
  },[inputs]);

  const tryAddInfo = (e:React.FormEvent<HTMLFormElement>) => {

    e.preventDefault();

    const email = e.currentTarget['join-email'].value;
    const name = e.currentTarget['join-name'].value;
    const role = e.currentTarget['join-role'].value;
    const phone = e.currentTarget['join-phone'].value;
    const checked = e.currentTarget['join-checked'].checked;
    if (!emailRegex.test(email)) {
      setError({ type: 'email', msg: 'The email format is invalid.' });
    } else if (name.length < 2 || name.length > 30) {
      setError({ type: 'name', msg: 'The username must be between 2 and 30 characters.' });
    } else if (!nameRegex.test(name)) {
      setError({ type: 'name', msg: 'The username can only contain Korean characters, letters, and numbers.' });
    } else if (role !== '' && role.length > 30) {
      setError({ type: 'role', msg: 'Please enter an Job title with a maximum of 30 characters.' });
    } else if (phone !== '' && (phone.length < 5 || phone.length > 16)) {
      setError({ type: 'phone', msg: 'Please enter a valid contact number.' });
    } else if (phone !== '' && !onlyNumRegex.test(phone)) {
      setError({ type: 'phone', msg: 'Please enter numbers only.' });
    } else {
      _getTerms({
        kindCode: 'service0',
        format: 'summary0'
      }).then(({ data }) => {
        if (data.result === ErrorCode.SUCCESS) {

          const signupData = {
            userId: idInputs.id,
            userPw: idInputs.pw,
            email: email,
            fullName: name,
            ranks: role === '' ? undefined : role,
            mobileNumber: phone === '' ? undefined : phone,
            terms: [{
              termsId: data.data[0].termId,
              mandatoryYn: false, /* mark: 4.17 공유시트 대화상으로 false로 바꿔달라고 요청: 원래는 data.data[0].mandatoryYn  <- true값을 확인 할 수 있음. */ 
              acceptYn: checked
            }]
          }

          if (signWithInv.invitation) {
            _registerWithInvitation({
              memberId: signWithInv.memberId,
              ...signupData
            }).then(({ data }) => {
              if (data.result === ErrorCode.SUCCESS) { 
                useToast(ErrorCode.SUCCESS, 'Your registration has been completed. Returns to the login screen.');
                logout();
              } 
              else { useToast(data.result); }
            })
          } else {
            _register({ ...signupData }).then(({ data }) => {
              if (data.result === ErrorCode.SUCCESS) {
                navigate('/auth/signup-sendemail', { state: { access: true, userId: idInputs.id } });
              } else if (data.result === ErrorCode.USER_ID_DUPLICATED) {
                setError({ type: 'email', msg: 'This email has already been verified.' });
              } else {
                useToast(data.result);
              }
            });
          }

        } else {
          useToast(data.result);
        }
      });
    }
  };

  return (
    <>
      <article id="join-check-info-article">
        <div className="header">
          <button type="button" onClick={goPrevStep}>
            <img src={BackIcon} width={26} height={26} />
          </button>
        </div>
        <div className="content">
          <h3><img src={UserTitleIcon} width={32} height={32} />Profile</h3>
          <form onSubmit={tryAddInfo}>
            <LabelInput 
              title="Email" 
              id="join-email" 
              placeholder="Input your email"
              value={inputs.email}
              disabled={signWithInv.invitation}
              onChangeValue={(value: string) => updateInputs('email', value)} 
              required={true}
              errorMsg={error.type === 'email' ? error.msg : undefined}
            />
            <LabelInput 
              title="Name" 
              id="join-name" 
              placeholder="Input your name (min 2 character, max 30 character)"
              defaultValue={inputs.name} 
              onChangeValue={(value: string) => {
                if (value.length > 30) return;
                if (koEnNumSpRegex.test(value)) { return useToast(ErrorCode.UNKNOWN, 'The username can only contain Korean characters, letters, and numbers.');}
                updateInputs('name', value)
            }} 
              required={true}
              errorMsg={error.type === 'name' ? error.msg : undefined}
            />
            <LabelInput 
              title="Job title" 
              id="join-role" 
              placeholder="Input your job title"
              defaultValue={inputs.role} 
              onChangeValue={(value: string) => updateInputs('role', value)}
              errorMsg={error.type === 'role' ? error.msg : undefined}
            />
            <PhoneInput 
              id="join-phone" 
              defaultValue={{ countryCode: countryCode, phone: inputs.phone }} 
              onChangeCountryCode={(value: string) => setContryCode(value)}
              onChangePhone={(value: string) => updateInputs('phone', value)}
              errorMsg={error.type === 'phone' ? error.msg : undefined}
            />
            <label className="labeled-checkbox-wrapper">
              <CheckboxAtom id="join-checked" />
              <pre>I agree <Link to={'/terms'} target="_blank">Terms of service</Link> and <Link to={'/terms/secure'} target="_blank">Privacy policy</Link></pre>
            </label>
            <button className="submit-btn big-main-btn-body2regular" disabled={disabledNextBtn}>Next</button>
          </form>
        </div>
      </article>
      <VerticalSplitLinkGroup list={termLinks} />
    </>
  );
};
export default CheckInfo;