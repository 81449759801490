import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListSshPublicKeysResponse } from 'graphql/types/AwsListSshPublicKeysResponse';

export interface IAwsListSSHPublicKeysVariables {
  cloudId: number;
  region: string;
  request: {
    maxItems?: number;
    userName?: string;
  };
}

export interface IAwsListSSHPublicKeysResponseData {
  getAwsListSSHPublicKeys: IGqlResponseNewData<AwsListSshPublicKeysResponse>;
}

const lazyGetAwsListSSHPublicKeys = () =>
  useLazyQuery<IAwsListSSHPublicKeysResponseData, IAwsListSSHPublicKeysVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListSSHPublicKeys;
