import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';

export interface IDisableLoggingMetricVariables {
  cloudId: number;
  region: string;
  reqData: {
    resourceArn: string;
  }
}

export interface IDisableLoggingMetricResponseData {
  deleteAwsLoggingConfiguration: IGqlResponseNewData<undefined>;
}

/** 요청사항 삭제 */
const deleteAwsLoggingConfigurationMutation = () => useMutation<IDisableLoggingMetricResponseData, IDisableLoggingMetricVariables>(query);
export default deleteAwsLoggingConfigurationMutation;