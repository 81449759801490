import { useEffect, useMemo, useRef, useState } from 'react';
import './index.scss';
import { useAuth } from 'contexts/AuthProvider';
import Labeled from 'components/v2/atoms/Labeled';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import addMessageSysAdmin from 'graphql/mutations/addMessageSysAdmin';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import { SandpackProvider } from '@codesandbox/sandpack-react';
import SandPackCode from 'components/v2/SandPackComp';
import { DummyMsgHtml } from 'utils/v2/DummyData/Html';
import LabelInput from 'components/v2/LabelInput';
import LabelTextarea from 'components/v2/LabelTextarea';

export interface IAddMessageModalProps {
  /** 모달이 켜지는 조건 */
  open: boolean;
  /** 모달이 꺼지는 함수 */
  onClose: () => void;
  /** className을 통한 외부 css 커스텀 @default 'default' */
  className?: string;
  /** 모달 바깥쪽 클릭을 통한 모달종료 @default true */
  closeOnClickBg?: boolean;
  /** 모달 애니메이션 재생시간 100ms단위, 설정범위: 양의정수 1~10 @default 3 */
  duration?: number;
  /** 모달 외부 장막의 opacity값 0.1단위, 설정범위: 양의정수 1~10 @default 3 */
  backgroundOpacity?: number;
  msgUseTypeList:{
    name: string;
    value: string;
  }[];
  msgSenderTypeList: {
    name: string;
    value: string;
  }[];
  title: () => JSX.Element;
  onCreateSuccess: () => void; 
}

let timer:NodeJS.Timeout;

const  AddMessageModal = ({
  open,
  onClose,
  title,
  className,
  closeOnClickBg = true,
  duration = 3,
  backgroundOpacity = 3,
  msgUseTypeList,
  msgSenderTypeList,
  onCreateSuccess,
}: IAddMessageModalProps) => {

  const modalRef = useRef<HTMLDivElement>(null);
  const { userInfo } = useAuth();

  /** 모달 고유 ID */
  const [id] = useState(Date.now());

  const [payload, setPayload] = useState({
    relObjectType: '',
    sendTypeCode: '',
    title: '',
  });
  /* 메세지 내용 */
  const [text, setText] = useState('');

  // 메세지 정보 추가
  const [addMessageSys] = addMessageSysAdmin();

  const enableButton = useMemo(() => {
    if (
      payload.relObjectType === '' ||
      payload.sendTypeCode === '' ||
      payload.title === '' ||
      text === ''
    ) { return true } else return false;
  }, [payload])

  useEffect(() => {
    if (open) {
      document.body.setAttribute(`modal-id-${id}`, 'true');
      modalRef.current?.classList.remove('out', 'active');
      modalRef.current?.classList.add('active');

      clearTimeout(timer);
    } else {
      modalRef.current?.classList.add('out');
      document.body.removeAttribute(`modal-id-${id}`);

      timer = setTimeout(() => {
        setPayload({
          relObjectType: '',
          sendTypeCode: '',
          title: '',
        });
        // setText('');
        setText(DummyMsgHtml);
      }, 3000);
    }
  }, [open, userInfo]);

    return (
      <div
        ref={modalRef}
        className="add-msg-modal-container modal-duration-5"
        data-duration={duration}
        data-bg-opacity={backgroundOpacity}
      >
      <div 
        className="modal-background" 
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          if (!closeOnClickBg) return;
          if (e.target === e.currentTarget) onClose && onClose();
        }}
      >
        <div className={`add-msg-modal ${className} flex`}>
          <div className="col-left">
            <div className="modal-head flex j-between a-center">
              <div className="modal-title flex a-center">{title()}</div>
              <button className="close-btn" onClick={onClose} />
            </div>
              <div className="contents-wrap flex col">
                <LabelInput 
                  required
                  title="Title"
                  placeholder='Input message title'
                  value={payload.title}
                  onChangeValue={(str) => setPayload(prev => ({
                    ...prev,
                    title: str
                  })) }
                /> 
                <Labeled 
                  title="Usage"
                  required
                >
                <DropdownAtom 
                    id="msg-use" 
                    data={msgUseTypeList} 
                    placeholder='Select message usage'
                    value={{
                    name: msgUseTypeList.find(val => val.value === payload.relObjectType)?.name
                      ? msgUseTypeList.find(val => val.value === payload.relObjectType)?.name
                      : '',
                    value: payload.relObjectType
                    }}
                    handleClick={(val) => {
                      setPayload(prev => ({
                        ...prev,
                        relObjectType: String(val.value)
                      }))
                    }}
                  />
                </Labeled>
                <Labeled 
                  title="Received type"
                  required
                >
                <DropdownAtom 
                    id="msg-sender" 
                    data={msgSenderTypeList} 
                    placeholder='Select user received type'
                    value={{
                    name: msgSenderTypeList.find(val => val.value === payload.sendTypeCode)?.name
                    ? msgSenderTypeList.find(val => val.value === payload.sendTypeCode)?.name
                    : '',
                    value: payload.sendTypeCode
                    }}
                    handleClick={(val) => {
                      setPayload(prev => ({
                        ...prev,
                        sendTypeCode: String(val.value)
                      }))
                    }}
                  />
                </Labeled>
                {/* <LabelTextarea
                  required
                  title="Body"
                  placeholder='Input message body'
                  value={text}
                  onChangeValue={(str) => setText(str)}
                /> */}
              </div>
              <div className="btns flex a-center">
                {/* <button 
                  disabled={payload.title === '' || payload.message === ''}
                  className='big-sub-btn'
                  onClick={() => {
                    window.open(
                      `/preview?title=${payload.title}&content=${payload.message.replace(/(?:\r\n|\r|\n)/g, '<br>')}`,
                      '_blank', ''
                    )
                  }}
                >
                  미리보기
                </button> */}
                <button 
                  className="big-main-btn flex j-center a-center"  
                  disabled={enableButton}
                  onClick={() => {
                    const addMessageData = {
                      reqAddMessage : {
                        ...payload,
                        message: text,
                        relObjectId:0, /* temp 확답 오기 전까진 이렇게 임시조치 */
                        version: '0.0.1' /* temp 확답 오기 전까진 이렇게 임시조치 */
                    }
                  }

                  addMessageSys({ variables: addMessageData }).then(({ data }) => {
                    if (data) {
                      if (data.addMessage.result === ErrorCode.SUCCESS) {
                        useToast(ErrorCode.SUCCESS, 'Message is created successfully.');
                        onCreateSuccess();
                      } else {
                        console.log(data.addMessage.result);
                        useToast(ErrorCode.UNKNOWN, 'Create message failed.');
                      }
                    } else {
                      useToast(ErrorCode.UNKNOWN, 'Create message failed.');
                    }
                  });
                  }}
                >
                  Create
                </button>
              </div>
          </div>
          <div className="col-right"> 
            <div>
              <div className="sandpack-label">
                <div><span>Html</span></div>
                <div><span>Preview</span></div>
              </div>
            { open &&
              <SandpackProvider template="static"> 
                <SandPackCode value={text} onChangeText={(val:string) => setText(val)} />
              </SandpackProvider>
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMessageModal;
