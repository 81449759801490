import './index.scss';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { IAMPropsType } from './types';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { useOutletContext } from 'react-router-dom';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import MgdTabLayout from 'layouts/v3/MgdTabLayout';
import { IAM_TAB_LIST, PageNodeEnum } from './configs';
import { MgdTabLayoutTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import UserGroups from './components/UserGropus/ListUp';
import UserGroupsDetail from './components/UserGropus/Detail';
import { UserGroupRowType } from './components/UserGropus/types';
import User from './components/User/ListUp';
import RoleList from './components/Role/RoleList';
import { AwsRoleType } from 'graphql/types/AwsListRolesResponse';
import RoleDetail from './components/Role/RoleDetail';
import Policy from './components/Policy/ListUp';
import PolicyDetail from './components/Policy/Detail';
import UserDetail from './components/User/Detail';

const IAM = (props: IAMPropsType) => {
  const {
    onBackButtonClicked,
    projectId,
    cloudName,
    regionCode,
    regions,
    recentRelatedClouds,
    relatedCloudSelected,
    recentRelatedCloudOnChange,
    currentRouteState,
    setCurrentRouteState,
  } = props;

  // Context
  const { defaultRegionList, updateDefaultRegionList } = useOutletContext<{
    defaultRegionList: { projectId: number; cloudId: number; name: string; value: string }[];
    updateDefaultRegionList: (projectId: number, cloudId: number, name: string, value: string) => void;
  }>();

  // API
  const [getEnumTypeCode] = lazyGetEnumTypeCode();

  // State
  const [pageType, setPageType] = useState<PageNodeEnum>(PageNodeEnum.MGD_LIST);
  const [currentRegion, setCurrentRegion] = useState<DropdownListDataType>({ name: '', value: '' });
  const [regionList, setRegionList] = useState<DropdownListDataType[]>([]);
  const [mainTabSelected, setMainTabSelected] = useState<IMgdTabProps>(IAM_TAB_LIST[0]);
  const [userGroup, setUserGroup] = useState<UserGroupRowType>();
  const [user, setUser] = useState<any>();
  const [role, setRole] = useState<AwsRoleType>();
  const [policy, setPolicy] = useState<UserGroupRowType>();

  useEffect(() => {
    if (!currentRouteState) return;

    const currentTab = IAM_TAB_LIST.find(e => e.id === currentRouteState.tabId);

    if (currentTab) setMainTabSelected(currentTab);
  }, [currentRouteState]);

  const cloudId = useMemo((): number => {
    return Number(relatedCloudSelected.value);
  }, [relatedCloudSelected]);

  // Get initial region
  const getInitialRegion = useCallback(() => {
    const hasDefaultRegion = defaultRegionList.find(r => {
      return r.projectId === projectId && r.cloudId === cloudId;
    });

    if (!!hasDefaultRegion) {
      setCurrentRegion({
        name: hasDefaultRegion.name,
        value: hasDefaultRegion.value,
      });
    } else {
      getEnumTypeCode({
        variables: { text: regionCode || 'AwsRegionCode' },
      }).then(res => {
        if (res.data) {
          const regionsRes: DropdownListDataType[] = res.data.getEnumTypeCode.data
            .filter(val => regions.includes(val.value))
            .map(val => ({
              ...val,
              name: val.value,
            }));
          setRegionList(regionsRes);
          const region = regionsRes.find(r => r.name === currentRegion.name && r.value === currentRegion.value);
          setCurrentRegion(region || regionsRes[0]);
        }
      });
    }
  }, [cloudId, defaultRegionList]);

  // For initial data
  useEffect(() => {
    getInitialRegion();
  }, [getInitialRegion]);

  const handleClearState = () => {
    setUserGroup(undefined);
    setUser(undefined);
    setRole(undefined);
    setPolicy(undefined);
    setPageType(PageNodeEnum.MGD_LIST);
  };

  useEffect(() => {
    handleClearState();
  }, [mainTabSelected]);

  const resetMainStates = useCallback(() => {
    if (!currentRouteState) return;

    setCurrentRouteState(null);
  }, [currentRouteState]);

  const mainTabClickHandler = useCallback(
    (tabSelected: IMgdTabProps) => {
      setMainTabSelected(IAM_TAB_LIST.find(tabItem => tabItem.id === tabSelected.id) || IAM_TAB_LIST[0]);
      resetMainStates();

      switch (tabSelected) {
        case IAM_TAB_LIST[0]:
          setUserGroup(undefined);
          break;
        case IAM_TAB_LIST[1]:
          setUser(undefined);
          break;
        case IAM_TAB_LIST[2]:
          break;
        case IAM_TAB_LIST[3]:
          setPolicy(undefined);
          break;
        default:
          break;
      }
    },
    [setMainTabSelected, resetMainStates],
  );

  const pinButtonClickHandle = useCallback(() => {
    updateDefaultRegionList(
      projectId,
      Number(relatedCloudSelected.value),
      currentRegion.name || '',
      currentRegion.value.toString(),
    );
  }, [relatedCloudSelected, currentRegion, projectId]);

  const tabContentNode = useMemo(() => {
    if (!currentRegion?.value || !cloudId) return;

    switch (mainTabSelected) {
      case IAM_TAB_LIST[0]: {
        return (
          <UserGroups
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            setUserGroup={setUserGroup}
            setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
          />
        );
      }
      case IAM_TAB_LIST[1]:
        return (
          <User
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            setDataItem={setUser}
            setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
          />
        );
      case IAM_TAB_LIST[2]:
        return (
          <RoleList
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            setRole={setRole}
            setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
          />
        );
      case IAM_TAB_LIST[3]:
        return (
          <Policy
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            setDataItem={setPolicy}
            setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
          />
        );
      default:
        return null;
    }
  }, [mainTabSelected, currentRegion, cloudId, currentRouteState]);

  const tabSummaryNode = useMemo(() => {
    switch (mainTabSelected) {
      case IAM_TAB_LIST[1]:
      case IAM_TAB_LIST[2]:
      case IAM_TAB_LIST[3]:
      default:
        return null;
    }
  }, [mainTabSelected, cloudId, currentRegion, projectId]);

  const pageBackClick = useCallback(() => {
    setUserGroup(undefined);
    setUser(undefined);
    setRole(undefined);
    setPolicy(undefined);
    setPageType(PageNodeEnum.MGD_LIST);
  }, []);

  const listNode = useMemo((): ReactNode => {
    return (
      <MgdTabLayout
        layoutType={MgdTabLayoutTypeEnum.MGD_LIST_SUMMARY}
        pageTitle={cloudName}
        hasFavorite={true}
        onBackButtonClicked={onBackButtonClicked}
        headerDropList={regionList}
        headerDropValue={currentRegion}
        headerDropOnchange={val => {
          setCurrentRegion(() => ({
            name: val.name ? val.name : '',
            value: String(val.value),
          }));
        }}
        isPined={
          !!defaultRegionList.find(r => {
            return r.projectId === projectId && r.cloudId === relatedCloudSelected.value;
          })
        }
        pinBtnActHandle={pinButtonClickHandle}
        pinBtnDisabled={false}
        mainTabList={IAM_TAB_LIST}
        mainTabItemClick={mainTabClickHandler}
        mainTitle={mainTabSelected?.title || ''}
        mainTabSelected={mainTabSelected}
        tabContentNode={tabContentNode}
        tabSummaryNode={tabSummaryNode}
        recentRelatedClouds={recentRelatedClouds}
        relatedCloudSelected={relatedCloudSelected}
        recentRelatedCloudOnChange={relatedCloud => recentRelatedCloudOnChange(relatedCloud)}
      />
    );
  }, [
    cloudName,
    onBackButtonClicked,
    regionList,
    currentRegion,
    defaultRegionList,
    projectId,
    relatedCloudSelected,
    pinButtonClickHandle,
    mainTabClickHandler,
    mainTabSelected,
    tabContentNode,
    tabSummaryNode,
    recentRelatedClouds,
    recentRelatedCloudOnChange,
  ]);

  const detailNode = useMemo(() => {
    switch (mainTabSelected) {
      case IAM_TAB_LIST[0]:
        return (
          <UserGroupsDetail
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            userGroup={userGroup}
            pageBackClick={pageBackClick}
          />
        );
      case IAM_TAB_LIST[1]:
        return (
          <UserDetail
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            data={user}
            pageBackClick={pageBackClick}
          />
        );
      case IAM_TAB_LIST[2]:
        return (
          <RoleDetail
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            role={role}
            pageBackClick={pageBackClick}
          />
        );
      case IAM_TAB_LIST[3]:
        return (
          <PolicyDetail
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            data={policy}
            pageBackClick={pageBackClick}
          />
        );
      default:
        return null;
    }
  }, [mainTabSelected, cloudId, currentRegion, userGroup, user, role, policy]);

  const pageNode = useMemo((): ReactNode => {
    switch (pageType) {
      default:
      case PageNodeEnum.MGD_LIST:
        return listNode;

      case PageNodeEnum.MGD_DETAIL:
        return detailNode;
    }
  }, [pageType, listNode, detailNode]);

  return <div className="iam-container">{pageNode}</div>;
};

export default IAM;
