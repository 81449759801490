import DetailTable from '../../../DetailTable';
import { ColumnType } from '@Types/v2/Table';
import { useEffect, useState } from 'react';
import { detailNetworkALCTabs, NETWORK_RULES_PROTOCOL_MAPPING } from '../Constant';
import { getNetworkPortRangeValue, getNetworkTypeValue } from '../Function';
import lazyGetAwsNetworkAcls from 'graphql/queries/getAwsNetworkAcls';

function NetworkACL({ cloudId, region, data, currentTab }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  const { vpcId } = data;

  const [getAwsNetworkAcls] = lazyGetAwsNetworkAcls();

  const handleGetData = async () => {
    try {
      setIsLoading(true);
      let arr: any = [];

      const variables = {
        cloudId,
        region,
        request: {
          maxResults: 50,
          filters: {
            name: 'vpc-id',
            values: [vpcId],
          },
        },
      };

      const { data: networkAclData } = await getAwsNetworkAcls({ variables });

      const data = networkAclData?.getAwsNetworkAcls?.data?.[0]?.networkAcls[0].entries.filter(
        (e: { egress: boolean }) => (e.egress === (currentTab.id == detailNetworkALCTabs[1].id) ? false : true),
      );
      data?.map(item => {
        const protocolCode = item?.protocol ? item.protocol : '-';
        let protocolMapping = NETWORK_RULES_PROTOCOL_MAPPING[protocolCode];
        if (!protocolMapping) {
          protocolMapping = NETWORK_RULES_PROTOCOL_MAPPING['-'];
        }
        arr.push({
          ruleNumber: item.ruleNumber.toString() == '32767' ? '*' : item.ruleNumber,
          type: getNetworkTypeValue(
            item.portRange,
            item.icmpTypeCode,
            item?.ipv6CidrBlock ? true : false,
            protocolCode,
          ),
          protocol: protocolMapping.protocol,
          portRange: getNetworkPortRangeValue(item.portRange, item.icmpTypeCode),
          source: item.cidrBlock ? item.cidrBlock : item.ipv6CidrBlock,
          allowDeny: item.ruleAction,
        });
      });

      setDataTable(arr);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetData();
  }, [currentTab]);

  const columns: ColumnType[] = [
    {
      label: 'Rule number',
      field: 'ruleNumber',
      sort: true,
    },
    {
      label: 'Type',
      field: 'type',
      sort: true,
    },
    {
      label: 'Protocol',
      field: 'protocol',
      sort: true,
    },
    {
      label: 'Port range',
      field: 'portRange',
      sort: true,
    },
    {
      label: currentTab?.id == "networkACLInbound" ? 'Source' : 'Destination',
      field: 'source',
      sort: true,
    },
    {
      label: 'Allow/Deny',
      field: 'allowDeny',
      sort: true,
    },
  ];

  return <DetailTable title={currentTab?.name} data={dataTable} columns={columns} isLoading={isLoading} />;
}

export default NetworkACL;
