export enum PlanTypeEnum {
  Basic = 'BASIC',
  Pro = 'PRO',
  Premiun = 'PREMIUM',
  Enterprise = 'ENTERPRISE'
}

export const PLAN_TYPE = [
  {
    type: PlanTypeEnum.Basic,
    title: 'Personal',
    description: '베이직은 이런 기능들이 있습니다.',
    benefit: [
      {
        useYn: true,
        feature: '베이직 기능1'
      },
      {
        useYn: true,
        feature: '베이직 기능2'
      },
      {
        useYn: false,
        feature: '베이직 기능3'
      },
      {
        useYn: false,
        feature: '베이직 기능4'
      }
    ]
  },
  {
    type: PlanTypeEnum.Pro,
    title: 'Teams',
    description: '프로는 이런 기능들이 있습니다.',
    benefit: [
      {
        useYn: true,
        feature: '프로 기능1'
      },
      {
        useYn: true,
        feature: '프로 기능2'
      },
      {
        useYn: false,
        feature: '프로 기능3'
      },
      {
        useYn: false,
        feature: '프로 기능4'
      }
    ]
  },
  {
    type: PlanTypeEnum.Premiun,
    title: 'Enterprise',
    description: '프리미엄은 이런 기능들이 있습니다.',
    benefit: [
      {
        useYn: true,
        feature: '프리미엄 기능1'
      },
      {
        useYn: true,
        feature: '프리미엄 기능2'
      },
      {
        useYn: true,
        feature: '프리미엄 기능3'
      },
      {
        useYn: false,
        feature: '프리미엄 기능4'
      }
    ]
  }
]