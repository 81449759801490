import ReactDOM from 'react-dom';
import React, { useState, ChangeEvent } from 'react';
import { DropdownListDataType } from '../DropdownAtom';
import './index.scss';
import ArrowIcon from 'assets/svgs/v2/ico_dropdown_arrow.svg';
import CloseIcon from 'assets/svgs/v3/ico_close.svg';

interface MultiSelectAutocompleteProps {
  data: DropdownListDataType[];
  selectedValues: string[];
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  handleClick: (val: DropdownListDataType) => void;
  handleRemove: (val: string) => void;
}

const MultiSelectAutocomplete: React.FC<MultiSelectAutocompleteProps> = ({
  className,
  data,
  placeholder,
  selectedValues,
  handleClick,
  handleRemove,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [filteredData, setFilteredData] =
    useState<DropdownListDataType[]>(data);
  const [showData, setShowData] = useState<boolean>(false);
  
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    if (value) {
      const filtered = data.filter(
        suggestion =>
          String(suggestion.value)
            .toLowerCase()
            .includes(value.toLowerCase()) &&
          !selectedValues.includes(String(suggestion.value)),
      ).sort((a: any, b: any) => {
        const char = value.toLowerCase();
        const charA = a?.value || '';
        const charB = b?.value || '';

        const startsWithCharA = charA.startsWith(char);
        const startsWithCharB = charB.startsWith(char);

        if (startsWithCharA && !startsWithCharB) return -1;

        if (!startsWithCharA && startsWithCharB) return 1;

        return charA.localeCompare(charB);
      });

      setFilteredData(filtered);
      setShowData(filtered.length > 0);
    } else {
      setShowData(false);
    }
  };

  return (
    <div className="regions">
       <div className={"input-region "+ className}>
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          placeholder={placeholder}
        />
        <img src={ArrowIcon} width={24} height={24} />
      </div>

      <div className="dropdown-list">
        {selectedValues.map((value, index) => (
          <div key={index}>
            <span>{value}</span>
            <button
              onClick={() => {
                handleRemove(value);
                setFilteredData([...filteredData, { value: value }]);
              }}
            >
              <img src={CloseIcon} width={24} height={24} />
            </button>
          </div>
        ))}

        {showData && inputValue && (
          <ul className="no-scrollbar">
            {filteredData.map(data => (
              <li
                key={data.id}
                onClick={() => {
                  handleClick(data);
                  if (!selectedValues.includes(String(data.value))) {
                    setInputValue('');
                    setFilteredData(
                      filteredData.filter(item => item.value !== data.value),
                    );
                    setShowData(false);
                  }
                }}
              >
                {data.value}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
export default MultiSelectAutocomplete;
