import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ConfigureMetricsPropsType } from '../type';
import { RadioButtonGroup, SectionContainer, SectionTitle, StepContainer } from '../components';
import DetailTable from 'pages/v2/Organ/Management/EC2/components/DetailTable';
import {
  REQUEST_SAMPLING_OPTIONS_RADIO_LIST,
  REQUEST_SAMPLING_ACTION_RADIO_LIST,
  RULES_EXCLUSION_COLUMN_LIST,
} from '../configs';
import { ButtonTypeEnum } from '../components/Button';
import { RowType } from '@Types/v2/Table';
import InputAtom from 'components/v2/atoms/InputAtom';

const ConfigureMetrics = (props: ConfigureMetricsPropsType) => {
  const { title, rules, creationData, setCreationData } = props;

  const [requestSamplingOption, setRequestSamplingOption] = useState(REQUEST_SAMPLING_OPTIONS_RADIO_LIST[0]);
  const [requestSamplingAction, setRequestSamplingAction] = useState(REQUEST_SAMPLING_ACTION_RADIO_LIST[0].value);
  const [exclusionRulesCheckedList, setExclusionRulesCheckedList] = useState<Array<string>>([]);

  useEffect(() => {
    setCreationData({
      ...creationData,
      requestSamplingAction,
      exclusionRulesCheckedList,
    });
  }, [requestSamplingAction, exclusionRulesCheckedList]);

  const rulesActionButtons = useMemo(() => {
    return [
      {
        id: 'moveUp',
        label: 'Move up',
        type: ButtonTypeEnum.GENERAL,
        onClick: () => {},
        disabled: true,
      },
      {
        id: 'moveDown',
        label: 'Move down',
        type: ButtonTypeEnum.GENERAL,
        onClick: () => {},
        disabled: true,
      },
    ];
  }, []);

  const ruleMetricRows = useMemo(() => {
    return rules.map((rule: any) => {
      const { id, name, visibilityConfig } = rule;

      return {
        id,
        rules: name,
        cloudWatchMetricName: visibilityConfig?.metricName,
      };
    });
  }, [rules]);

  const ruleExclusionRows = useMemo(() => {
    return rules.map((rule: any) => {
      const { name, id } = rule;

      return {
        id,
        name,
      };
    });
  }, [rules]);

  const exlusionNode = useMemo(() => {
    if (requestSamplingOption?.value !== REQUEST_SAMPLING_OPTIONS_RADIO_LIST[2].value) {
      return null;
    }

    return (
      <>
        <SectionContainer>
          <DetailTable
            title="Rules"
            description="Choose the rules that you want to disable request sampling for"
            data={ruleExclusionRows}
            columns={RULES_EXCLUSION_COLUMN_LIST}
            reportCheckedList={setExclusionRulesCheckedList}
          />
        </SectionContainer>
        <SectionContainer
          title="Request sampling for web ACL default action"
          description="Request sampling for web ACL default action"
        >
          <SectionTitle title={'Request sampling for web ACL default action'} />
          <RadioButtonGroup
            data={REQUEST_SAMPLING_ACTION_RADIO_LIST}
            value={requestSamplingAction}
            onChangeValue={setRequestSamplingAction}
          />
        </SectionContainer>
      </>
    );
  }, [requestSamplingOption, requestSamplingAction, ruleExclusionRows, ruleMetricRows]);

  const handleChangeMetricName = (id?: string, value?: string) => {
    const rulesChange = rules.map((e: any) => ({
      ...e,
      visibilityConfig: {
        ...e.visibilityConfig,
        sampledRequestsEnabled: true,
        cloudWatchMetricsEnabled: true,
        metricName: e.id === id ? value : e.visibilityConfig?.metricName,
      },
    }));

    setCreationData({
      ...creationData,
      rules: rulesChange,
    });
  };

  const METRICS_COLUMN_LIST = [
    { field: 'rules', label: 'Rules', sort: true },
    {
      field: 'cloudWatchMetricName',
      label: 'CloudWatch metric name',
      sort: true,
      renderCell: (record: RowType) => (
        <div style={{ padding: '4px' }}>
          <InputAtom
            value={record?.cloudWatchMetricName}
            onChangeValue={val => handleChangeMetricName(record?.id?.toString(), val)}
          />
        </div>
      ),
    },
  ];

  const handleChangeRequestSamplingOption = useCallback(
    (value: string) => {
      let sampledRequestsEnabled = '';

      if (value === 'enableSampledRequests') {
        sampledRequestsEnabled = 'Enabled';
      }

      if (value === 'enableSampledRequestsWithExclusions') {
        sampledRequestsEnabled = 'Disabled for specific rules';
      }

      if (value === 'disableSampledRequests') {
        sampledRequestsEnabled = 'Disabled';
      }

      setCreationData({
        ...creationData,
        sampledRequestsEnabled,
      });
    },
    [creationData],
  );

  return (
    <StepContainer title={title}>
      <SectionContainer>
        <DetailTable
          title={'Amazon CloudWatch metrics'}
          description="CloudWatch metric allow you to monitor web requests, web AGLs, and rules."
          columns={METRICS_COLUMN_LIST}
          data={ruleMetricRows}
          reportCheckedList={setExclusionRulesCheckedList}
          rightButtons={rulesActionButtons}
        />
      </SectionContainer>

      <SectionContainer
        title="Request sampling options"
        description="If you disable request sampling, you can’t view requests that match your web ACL rules."
      >
        <SectionTitle title={'Default action'} />
        <RadioButtonGroup
          data={REQUEST_SAMPLING_OPTIONS_RADIO_LIST}
          value={requestSamplingOption.value}
          onChangeValue={value => {
            setRequestSamplingOption(REQUEST_SAMPLING_OPTIONS_RADIO_LIST.find(option => option.value === value)!);
            handleChangeRequestSamplingOption(value);
          }}
        />
      </SectionContainer>
      {exlusionNode}
    </StepContainer>
  );
};

export default ConfigureMetrics;
