import { gql } from '@apollo/client';

const query = gql`
  query getAwsDescribeManagedRuleGroup($request: AwsDescribeManagedRuleGroupRequest, $cloudId: Long, $region: String) {
    getAwsDescribeManagedRuleGroup(describeManagedRuleGroupRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsDescribeManagedRuleGroupResponse {
          versionName
          snsTopicArn
          capacity
          rules {
            name
            action {
              block {
                customResponse {
                  responseCode
                  customResponseBodyKey
                  responseHeaders {
                    name
                    value
                  }
                }
              }
              allow {
                customRequestHandling {
                  insertHeaders {
                    name
                    value
                  }
                }
              }
              count {
                customRequestHandling {
                  insertHeaders {
                    name
                    value
                  }
                }
              }
              captcha {
                customRequestHandling {
                  insertHeaders {
                    name
                    value
                  }
                }
              }
              challenge {
                customRequestHandling {
                  insertHeaders {
                    name
                    value
                  }
                }
              }
            }
          }
          labelNamespace
          availableLabels {
            name
          }
          consumedLabels {
            name
          }
        }
      }
    }
  }
`;
export default query;
