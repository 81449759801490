import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import DetailTab from '../../../DetailTab';
import { useNavigate } from 'react-router-dom';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import { NETWORK_TAB_LIST } from '../../../../configs';
import { handleLinkClicked } from 'pages/v2/Organ/Management/Utils';
import { vpcFilterDropdown } from '../../../../Constants';

function Details({ data }: any) {
  const navigate = useNavigate();

  const { internetGatewayId, state, vpcId, ownerId } = data || {};

  const detailsData: IMgdDetailKeyValueProps[] = [
    {
      title: 'Internet gateway ID',
      description: internetGatewayId,
      type: TextTypeEnum.COPY,
    },
    {
      title: 'Status',
      description: state,
      type: TextTypeEnum.STATUS,
    },
    {
      title: 'VPC ID',
      description: vpcId,
      type: TextTypeEnum.LINK,
      handleItemClick: () =>
        handleLinkClicked({
          navigate,
          link: '/organ/1/manage/network',
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[0].id,
          key: vpcFilterDropdown[0].value.toString(),
          value: vpcId,
        }),
    },
    {
      title: 'Owner ID',
      description: ownerId,
      type: TextTypeEnum.COPY,
    },
  ];

  return <DetailTab title="Details" data={detailsData} />;
}

export default Details;
