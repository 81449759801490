import { gql } from '@apollo/client';

const query = gql`
  query getAwsListUserPolicies($request: AwsListUserPoliciesRequest, $cloudId: Long, $region: String) {
    getAwsListUserPolicies(listUserPoliciesRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListUserPoliciesResponse {
          policyNames
          isTruncated
          marker
        }
      }
    }
  }
`;
export default query;
