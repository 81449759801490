import { useMemo } from 'react';
import InputAtom from 'components/v2/atoms/InputAtom';
import {
  RULE_SUB_TYPE_SELECTOR,
  RuleSubTypeSelectorEnum,
} from 'pages/v2/Organ/Management/WAF/CustomRuleModal/constant';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import Regular from 'pages/v2/Organ/Management/WAF/CustomRuleModal/RuleBuilder/VisualEditor/Regular';
import RateBased from 'pages/v2/Organ/Management/WAF/CustomRuleModal/RuleBuilder/VisualEditor/RateBased';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import Button, { ButtonTypeEnum } from '../../../WebACL/CreateWebACL/components/Button';

type VisualPropsType = {
  cloudId: number;
  selectedRegion: DropdownListDataType;
  dropdownConditionRequestValue: DropdownListDataType;
  seconds: string;
  isCustomImmunityTime: boolean;
  setDropdownConditionRequestValue: (value: any) => void;
  setSeconds: (value: any) => void;
  setIsCustomImmunityTime: (value: any) => void;
  inspectionAndRateLimit: string;
  requestAggregationKeyData: any;
  setRequestAggregationKeyData: (value: any) => void;
  handleValidateRule: () => void;
  ruleName: string;
  setRuleName: (value: string) => void;
  ruleSubType: RuleSubTypeSelectorEnum;
  setRuleSubType: (value: RuleSubTypeSelectorEnum) => void;
  statementData: any;
  setStatementData: (data: any) => void;
  rateLimit: string;
  setRateLimit: (value: string) => void;
  evaluationWindowSec: { name: string; value: number };
  setEvaluationWindowSec: (data: { name: string; value: number }) => void;
  requestAggregationKeyType: string;
  setRequestAggregationKeyType: (value: string) => void;
  scopeOfInspectionAndRateLimiting: string;
  setScopeOfInspectionAndRateLimiting: (value: string) => void;
  headerFieldName: string;
  setHeaderFieldName: (value: string) => void;
  fallbackBehavior: string;
  setFallbackBehavior: (value: string) => void;
  action: string;
  setAction: (value: string) => void;
  isEnableBlockCustomResponse: boolean;
  setEnableBlockCustomResponse: (value: boolean) => void;
  customHeaderList: Array<any>;
  setCustomHeaderList: (list: Array<any>) => void;
  labelList: Array<any>;
  setLabelList: (list: Array<any>) => void;
  errors: {
    [key: string]: string;
  };
  validateLoading?: boolean;
  ipSets: any;
};

const Visual = (props: VisualPropsType) => {
  const {
    cloudId,
    selectedRegion,
    seconds,
    setSeconds,
    isCustomImmunityTime,
    setIsCustomImmunityTime,
    requestAggregationKeyData,
    setRequestAggregationKeyData,
    handleValidateRule,
    ruleName,
    setRuleName,
    ruleSubType,
    setRuleSubType,
    dropdownConditionRequestValue,
    setDropdownConditionRequestValue,
    statementData,
    setStatementData,
    rateLimit,
    setRateLimit,
    evaluationWindowSec,
    setEvaluationWindowSec,
    requestAggregationKeyType,
    setRequestAggregationKeyType,
    scopeOfInspectionAndRateLimiting,
    setScopeOfInspectionAndRateLimiting,
    headerFieldName,
    setHeaderFieldName,
    fallbackBehavior,
    setFallbackBehavior,
    isEnableBlockCustomResponse,
    setEnableBlockCustomResponse,
    customHeaderList,
    setCustomHeaderList,
    labelList,
    setLabelList,
    action,
    setAction,
    errors,
    validateLoading,
    ipSets,
  } = props;

  const renderElement = useMemo(() => {
    switch (ruleSubType) {
      case RULE_SUB_TYPE_SELECTOR[0].value:
        return (
          <Regular
            cloudId={cloudId}
            selectedRegion={selectedRegion}
            dropdownConditionRequestValue={dropdownConditionRequestValue}
            seconds={seconds}
            isCustomImmunityTime={isCustomImmunityTime}
            isEnable={isEnableBlockCustomResponse}
            customHeaderList={customHeaderList}
            anotherLabelList={labelList}
            setDropdownConditionRequestValue={setDropdownConditionRequestValue}
            setSeconds={setSeconds}
            setIsCustomImmunityTime={setIsCustomImmunityTime}
            setIsEnable={setEnableBlockCustomResponse}
            setCustomHeaderList={setCustomHeaderList}
            setAnotherLabelList={setLabelList}
            setAction={setAction}
            handleSelectAction={setAction}
            statementData={statementData}
            action={action}
            setStatementData={setStatementData}
            errors={errors}
            ipSets={ipSets}
          />
        );

      case RULE_SUB_TYPE_SELECTOR[1].value:
        return (
          <RateBased
            cloudId={cloudId}
            selectedRegion={selectedRegion}
            dropdownConditionRequestValue={dropdownConditionRequestValue}
            seconds={seconds}
            isCustomImmunityTime={isCustomImmunityTime}
            isEnable={isEnableBlockCustomResponse}
            customHeaderList={customHeaderList}
            anotherLabelList={customHeaderList}
            setDropdownConditionRequestValue={setDropdownConditionRequestValue}
            setSeconds={setSeconds}
            setIsCustomImmunityTime={setIsCustomImmunityTime}
            setIsEnable={setEnableBlockCustomResponse}
            setCustomHeaderList={setCustomHeaderList}
            setAnotherLabelList={setLabelList}
            setAction={setAction}
            handleSelectAction={setAction}
            rateLimit={rateLimit}
            evaluationWindow={evaluationWindowSec}
            requestAggregation={requestAggregationKeyType}
            inspectionAndRateLimit={scopeOfInspectionAndRateLimiting}
            headerFieldName={headerFieldName}
            positionInsideHeader={{
              name: 'First IP address',
              value: 'First IP address',
            }}
            missingIpAddress={fallbackBehavior}
            requestAggregationKeyData={requestAggregationKeyData}
            setRateLimit={setRateLimit}
            setEvaluationWindow={setEvaluationWindowSec}
            setRequestAggregation={setRequestAggregationKeyType}
            setInspectionAndRateLimit={setScopeOfInspectionAndRateLimiting}
            setHeaderFieldName={setHeaderFieldName}
            setPositionInsideHeader={() => {}}
            setMissingIpAddress={setFallbackBehavior}
            setRequestAggregationKeyData={setRequestAggregationKeyData}
            statementData={statementData}
            action={action}
            setStatementData={setStatementData}
            errors={errors}
            ipSets={ipSets}
          />
        );

      default:
        return null;
    }
  }, [
    ruleSubType,
    dropdownConditionRequestValue,
    seconds,
    isCustomImmunityTime,
    isEnableBlockCustomResponse,
    customHeaderList,
    labelList,
    rateLimit,
    evaluationWindowSec,
    requestAggregationKeyType,
    headerFieldName,
    fallbackBehavior,
    requestAggregationKeyData,
    statementData,
    action,
    setDropdownConditionRequestValue,
    setStatementData,
    errors,
    ipSets,
  ]);

  return (
    <>
      <div className="rule-container-group rule-container-group-border">
        <div className="rule-container-group-title">
          <p>Rule</p>

          <Button
            label="Validate"
            type={ButtonTypeEnum.GENERAL}
            onClick={handleValidateRule}
            loading={validateLoading}
          />
        </div>

        <div className="rule-container-group-content">
          <div className="input-group">
            <p className="input-group-label">Name</p>

            <div className="input-container">
              <InputAtom
                placeholder={''}
                onChangeValue={val => {
                  if (val.length <= 128) {
                    setRuleName(val);
                  }
                }}
                value={ruleName}
                required={true}
                noClear={true}
                error={errors.name}
              />

              <p className="text-note">
                The name must have 1-128 characters. Valid characters: A-Z, a-z, 0-9, -(hyphen), and _(underscore).
              </p>
            </div>
          </div>

          <div>
            <p className="rule-container-group-content-label">Type</p>

            <div className="space-8" />

            {RULE_SUB_TYPE_SELECTOR.map(({ id, label, value, name, description }) => (
              <RadioAtom
                key={id}
                label={label}
                value={value}
                name={name}
                description={description}
                checked={ruleSubType}
                onChange={type => setRuleSubType(type as RuleSubTypeSelectorEnum)}
              />
            ))}
          </div>
        </div>
      </div>

      {renderElement}
    </>
  );
};

export default Visual;
