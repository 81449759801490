import './index.scss';
import { useState } from 'react';
import PageDetailTitle from '../../PageDetailTitle';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';
import Details from '../Common/Components/Details';
import Tags from '../Common/Components/Tags';

const detailTabs: IMgdTabProps[] = [
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

const InternetDetail = (props: any) => {
  const { data, pageBackClick } = props;

  // @ts-ignore
  const [tabSelected, setTabSelected] = useState(detailTabs[0]);

  const renderTab = () => {
    switch (tabSelected.id) {
      case detailTabs[0].id:
        return <Tags data={data} />;

      default:
        return null;
    }
  };

  return (
    <div className="page-detail">
      <PageDetailTitle title={`Internet G/W / ${data?.id}`} pageBackClick={pageBackClick} />

      <>
        <Details data={data} />

        <div className="page-detail-tab">
          <div className="detail-tab flex a-center">
            {detailTabs.map(tab => {
              return (
                <button
                  className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
                  key={tab.id}
                  data-tab={tab.id}
                  onClick={e => setTabSelected(tab)}
                >
                  <p>{tab.name}</p>
                </button>
              );
            })}
          </div>

          <div className="content-tab">{renderTab()}</div>
        </div>
      </>
    </div>
  );
};

export default InternetDetail;
