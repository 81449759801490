import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import LabelInput from 'components/v2/LabelInput';
import LabelTextarea from 'components/v2/LabelTextarea';
import Labeled from 'components/v2/atoms/Labeled';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import lazyGetHistEventAlert from 'graphql/queries/getHistEventAlert';
import { ErrorCode } from '@Types/error';

interface ISendEventAlertModalProps extends IBaseModalProps {
  onSend:(dataPayload: {
    sendTypeCode: string;
    title: string;
    contents: string;
    members: {
      memberId: string;
      memberName: string;
    }[];
  }) => void;
  projectName: string;
  data: {
    reqData: {
        projectId: number;
        id: number;
        settingId: number;
    };
  }
}

const SendEventAlertModal = ({
  onSend,
  title,
  data,
  projectName,
  ...baseModalProps
}: ISendEventAlertModalProps) => {

  const [payload, setPayload] = useState<{
    title: string;
    contents: string;
    sendTypeCode: string;
  }>({
    title: '', /* 알림 제목 */
    contents: '', /* 알림 내용 */
    sendTypeCode: '' /* 알림 형태 */
  });
  const [originMembers, setOriginMembers] = useState<{ memberId: string; memberName: string; }[]>([]);
  const [members, setMembers] = useState<{ memberId: string; memberName: string; }[]>([]);

  const ableSendEventAlert = useMemo(() => {
    if (payload.title === '' || payload.contents === '' || payload.sendTypeCode === '' || members.length === 0) {
      return true;
    } else return false;
  }, [payload, members])

  /* 알림 발송 내용 조회 (수신자 및 알림 내용) */
  const [lazyGetSendAlertData] = lazyGetHistEventAlert();
  
  useEffect(() => {
    setPayload({
      title: '', 
      contents: '', 
      sendTypeCode: ''
    });

    lazyGetSendAlertData({ variables: data }).then(({ data }) => {
      if (data) {
        if (data.getHistEventAlertInfo.result === ErrorCode.SUCCESS) {
          const haveMembers = data.getHistEventAlertInfo.data.filter(val => val.projectMember);
          if (haveMembers.length !== 0) {
            const memberList = haveMembers.map(val => val.projectMember).flat();
            if (memberList.length !== 0) {
              const filteredYnTrue = memberList.filter(val => val.alertYn === true);
              if (filteredYnTrue.length !== 0 ) {
                const result = filteredYnTrue.map(val => ({
                  memberId: val.memberId,
                  memberName: val.memberName
                }));
                setMembers(result);
                setOriginMembers(result);
              }
            }
          }
  
        } else {
          console.log(data.getHistEventAlertInfo.result);
        }
      }
    });

  }, [baseModalProps.open]);
  
  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="send-alert-modal">
        <div className="border-wrap" />
        <div className="content-wrap">
          <LabelInput 
            title="Title"
            required 
            placeholder="Input alert message's title"
            value={payload.title}
            onChangeValue={str => setPayload(prev => ({
              ...prev,
              title: str
            }))}
          />
          <LabelTextarea 
            title="Body"
            required
            placeholder="Input alert message's body"
            value={payload.contents}
            onChangeValue={str => setPayload(prev => ({
              ...prev,
              contents: str
            }))}
          />
          <Labeled 
            title="Send by"
            required
          >
            <DropdownAtom 
              id="send-type-code"
              placeholder="Select hoe to send"
              data={[
                {
                  name: 'Email',
                  value: 'email'
                },
                {
                  name: 'Message',
                  value: 'message'
                }
              ]} 
              value={{
                name: payload.sendTypeCode === 'email' ? 'Email' : payload.sendTypeCode === 'message' ? 'Message' : '',
                value: payload.sendTypeCode
              }} 
              handleClick={val => {
                setPayload(prev => ({
                  ...prev,
                  sendTypeCode: String(val.value)
                }));
              }}            
            />
          </Labeled>
          <div className="members-wrap">
            <h5>Receivers</h5>
            <p className="flex j-between a-center"> 
              sent to members of the &lsquo;{ projectName }&lsquo;.
              <button
                disabled={originMembers.length === 0}
                onClick={() => setMembers(originMembers)}
              >
                Init
              </button>
            </p>
            <div className="flex j-start">
              
              { originMembers.length === 0 
                ? <div className="empty-members flex">알림발송이 가능한 구성원이 없습니다.</div>
                : members.length === 0 
                  ? <div className="empty-members flex">수신자는 최소 1명 이상 선택되야 합니다.</div>
                  : members.map(val => (
                    <div 
                      key={val.memberId}
                      className="member-chip flex j-between a-center"
                    >
                      {val.memberName}
                      <button 
                        className="del-btn" 
                        onClick={() => {
                          const left = members.filter(mem => mem.memberId !== val.memberId);
                          setMembers(left);
                        }}
                      />
                    </div>
                  ))
              }
            </div>
          </div>
        </div>
        <div className="btns">
          <button 
            className="big-main-btn flex j-center a-center"
            disabled={ ableSendEventAlert }
            onClick={() => {
              const dataPayload = {
                sendTypeCode: payload.sendTypeCode,
                title: payload.title,
                contents: payload.contents,
                members: members,
              };
              onSend(dataPayload);
            }}
          >
            Send
          </button>
        </div>

      </div>
    </BaseModal>
  );
};

export default SendEventAlertModal;
