import { useAuth } from "contexts/AuthProvider";
import { MutableRefObject, useEffect, useState } from "react";

const RedashComp = ({src, iframeRef}: {src: string, iframeRef?: MutableRefObject<HTMLIFrameElement | null>}) => {
  const {token} = useAuth();
  const [iframeHeight, setIframeHeight] = useState(150);

  const getIframeMessage = (ev: MessageEvent) => {
    try {
      // console.log('getMessage', ev.data, ev.origin);
      const parse = JSON.parse(ev.data);
      if(parse.type === 'resize') {
        setIframeHeight(parse.scrollHeight || 150);
      } else if(parse.type === 'request-token') {
        if(iframeRef && iframeRef.current) {
          iframeRef.current.contentWindow?.postMessage(JSON.stringify({
            type: 'token', 
            data: token
          }), '*');
        }
      }
    } catch (e) {}
  }
  useEffect(() => {
    window.addEventListener("message", getIframeMessage);

    () => {
      window.removeEventListener("message", getIframeMessage);
    }
  },[]);

  return <iframe ref={iframeRef} id="redash-iframe" className="redash-iframe" src={src} allow='*' height={iframeHeight}/>;
}
export default RedashComp;