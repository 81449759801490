import { useMemo, useState } from 'react';
import ManageHeader from 'components/v2/ManageHeader';
import ManageTab from 'components/v2/ManageTab';
import Dictionary from './Dictionary';
import Resource from './Resource';
import Message from './Message';
import MemoComp from './MemoComp';

const MENU = [
  {
    label: 'Dictionary',
    value: 'DICTIONARY'
  },
  {
    label: 'Resource',
    value: 'RESOURCE'
  },
  {
    label: 'Message',
    value: 'MESSAGE'
  },
  {
    label: 'Comment',
    value: 'MEMO'
  }
]

/**
 * <>lg운영자만 접근 가능한 공통정보관리 페이지</>
 */
const CommonInformation = () => {
  const [tab, setTab] = useState('DICTIONARY');

  const renderContents = useMemo(() => {
    switch (tab) {
      case 'DICTIONARY':
        return <Dictionary />;
      case 'RESOURCE':
        return <Resource />;
      case 'MESSAGE':
        return <Message />;
      case 'MEMO':
        return <MemoComp />;
    
      default:
        return <Dictionary />;
      }
  }, [tab])

  return (
    <div id="common-information">
      {/* header */}
      <ManageHeader 
        title='System Data'
      />
      <ManageTab
        selected={tab}
        menu={MENU}
        convertTab={(value) => setTab(value)}
      />
      {renderContents}
    </div>
  );
}

export default CommonInformation;