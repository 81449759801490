export const CREATE_RULE_GROUP_STEPS = [
  { id: 'describeRuleGroup', title: 'Step 1: Describe rule group' },
  {
    id: 'addRules',
    title: 'Add rules and set capacity',
    description:
      'Rules define attack patterns to look for in web requests and the action to take when a request matches the patterns. Add rules to this rule group and use this rule group in a web ACL.',
  },
  {
    id: 'setRulePriority',
    description:
      'AWS WAF evaluates the rules in the order shown, starting from the top. Move rules up or down to change the evaluation order.',
    title: 'Step 3. Set rule priority',
  },
  { id: 'review', title: 'Review and create rule group' },
];

export const RULES_COLUMN_LIST = [
  { field: 'name', label: 'Name', sort: true },
  { field: 'capacity', label: 'Capacity', sort: true },
  { field: 'action', label: 'Action', sort: true },
];