import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { DashboardListType } from 'graphql/types/DashboardListType';

export interface IAddDashboardVariables {
  reqData: {
    name: string;
    templateId: number;
    organId: number;
    parameters: string;
  }
}

export interface IAddDashboardResponseData {
  addDashboard: IGqlResponseData<DashboardListType[]>;
}

/** 조직 추가 */
const addDashboard = () => useMutation<IAddDashboardResponseData, IAddDashboardVariables>(query);
export default addDashboard;