import React, { useState, useCallback } from 'react';
import './index.scss';
import { type Content, Mode } from 'vanilla-jsoneditor';
import JSONEditor from 'components/JSONEditor';
import Button, { ButtonTypeEnum } from '../../../WebACL/CreateWebACL/components/Button';

type JsonPropsType = {
  json: Object;
  validateLoading?: boolean;
  handleValidateRule: () => void;
};

const Json = (props: JsonPropsType) => {
  const { json, validateLoading, handleValidateRule } = props;

  const [responseBodyTextareaValue, setResponseBodyTextareaValue] = useState('');
  const [jsonEditorContent, setJsonEditorContent] = useState<Content>({
    json,
  });

  const onValidate = useCallback(() => {}, []);

  return (
    <>
      <div className="rule-container-group rule-container-group-border rule-container-group-json">
        <div className="rule-container-group-title">
          <p>JSON</p>

          <Button
            label="Validate"
            type={ButtonTypeEnum.GENERAL}
            onClick={handleValidateRule}
            loading={validateLoading}
          />
        </div>

        <JSONEditor content={jsonEditorContent} onChange={setJsonEditorContent} />
      </div>
    </>
  );
};

export default Json;
