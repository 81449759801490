import { ColumnType } from '@Types/v2/Table';
import DetailTable from '../../../DetailTable';
import { useEffect, useState } from 'react';
import lazyGetAwsSubnetCidrReservations from 'graphql/queries/getSubnetCidrReservations';

function CIDRReservations({ cloudId, region, data }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  const { subnetId } = data;

  const [getAwsSubnetCidrReservations] = lazyGetAwsSubnetCidrReservations();

  const handleGetData = async () => {
    try {
      setIsLoading(true);
      let arr: any = [];
      const variables = {
        cloudId,
        region,
        request: {
          maxResults: 50,
          subnetId: subnetId,
        },
      };

      const { data: subnetCidrData } = await getAwsSubnetCidrReservations({ variables });

      subnetCidrData?.getAwsSubnetCidrReservations.data.subnetIpv4CidrReservations?.forEach(item => {
        arr.push({
          cidrReservationId: item.subnetCidrReservationId,
          cidr: item.cidr,
          reservationType: item.reservationType,
        });
      });
      setDataTable(arr);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const columns: ColumnType[] = [
    {
      label: 'CIDR reservation ID',
      field: 'cidrReservationId',
      sort: true,
    },
    { label: 'CIDR', field: 'cidr', sort: true },
    { label: 'Reservation type', field: 'reservationType', sort: true },
  ];

  return <DetailTable title="CIDR reservations" data={dataTable} columns={columns} isLoading={isLoading} />;
}

export default CIDRReservations;
