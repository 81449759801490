import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import LabelInput from 'components/v2/LabelInput';
import Labeled from 'components/v2/atoms/Labeled';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { numberRegex } from 'utils/Regex';
import LabelTextarea from 'components/v2/LabelTextarea';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import addSubCategory from 'graphql/mutations/addSubCategory';

interface ICreateSubCategoryModalProps extends IBaseModalProps {
  categoryList: {
    value: number;
    name: string;
  }[];
  title: () => JSX.Element;
  onCreateSuccess: () => void;
}

const CreateSubCategoryModal = ({
  categoryList,
  title,
  onCreateSuccess,
  ...baseModalProps
}:ICreateSubCategoryModalProps) => {

  const [payload, setPayload] = useState({
    categoryId: -1, /* 카테고리 정보 */
    name: '', /* 서브 카테고리 이름 */
    maskCode: '',
    description: '',
    title: '',
    template : '',
  });

  const [createSubCategory] = addSubCategory();

  const enableButton = useMemo(() => {
    if (payload.categoryId === -1 ||
      payload.name === '' ||
      payload.maskCode.length < 8 ||
      payload.description === '' ||
      payload.title === ''
    ) {
      return true;
    } else return false;
  }, [payload])

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload({
        categoryId: -1,
        name: '',
        maskCode: '',
        description: '',
        title: '',
        template : '',
      });
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal 
      {...baseModalProps}
      title={title}
    >
      <div className="create-subCategory-modal">
        <div className="contents-wrap">
          <LabelInput 
            title="Name"
            placeholder="Input rule category's name"
            required
            value={payload.name}
            onChangeValue={(val) => setPayload((prev) => ({
              ...prev,
              name: val
            }))}
          />
          <Labeled
            title="Category"
            required={true}
          >
            <DropdownAtom 
              id="select-category"
              placeholder='select category'
              data={categoryList} 
              value={{
                name: categoryList.find(val => val.value === payload.categoryId)?.name
                  ? categoryList.find(val => val.value === payload.categoryId)?.name
                  : '',
                value: payload.categoryId
              }} 
              handleClick={(val) => {
                setPayload(prev => ({
                  ...prev,
                  categoryId: Number(val.value)
                }))
              }}                
          />
          </Labeled>
          <LabelInput 
            title="Unique delimiter" 
            required
            placeholder='Input delimiter (mask code 8 digits)'
            value={payload.maskCode}
            onChangeValue={(val) => {
              if (val.length > 8) return;

              setPayload((prev) => ({
                ...prev,
                maskCode: val.replace(numberRegex, '')
              }))
            }}
          />
          <LabelInput 
            title="Description" 
            placeholder='Input description'
            value={payload.description}
            onChangeValue={(val) => {
              setPayload((prev) => ({
                ...prev,
                description: val
              }))
            }}
          />
          <LabelInput 
            title="Document title" 
            required
            placeholder='Input document title'
            value={payload.title}
            onChangeValue={(val) => {
              setPayload((prev) => ({
                ...prev,
                title: val
              }))
            }}
          />
          <LabelTextarea
            title="Document format" 
            placeholder='Input document format.'
            value={payload.template}
            onChangeValue={(val) => {
              setPayload((prev) => ({
                ...prev,
                template: val
              }))
            }}
          />
        </div>
        <div className="btns">
          <button 
            className="big-main-btn flex j-center a-center"  
            disabled={enableButton}
            onClick={() => {
              const createSubCategoryData = {
                reqData: {
                  ...payload
                }
              }
    
              createSubCategory({ variables: createSubCategoryData }).then(({ data }) => {
                if (data) {
                  if (data.addSubCategory.result === ErrorCode.SUCCESS) {
                    useToast(ErrorCode.SUCCESS, 'Subcategory is created successfully.');
                    onCreateSuccess();
                  } else {
                    console.log(data.addSubCategory.result);
                    useToast(ErrorCode.UNKNOWN, 'Create subcategory failed.');
                  }
                } else {
                  useToast(ErrorCode.UNKNOWN, 'Create subcategory failed.');
                }
              });
            }}
          >
            Create
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default CreateSubCategoryModal;
