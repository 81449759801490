import './index.scss';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';

interface IShareModalModalProps extends IBaseModalProps {
  content?: () => JSX.Element;
  buttonTitle?: string;
  onConfirm?: () => void;
}

const ShareModal = ({
  title,
  content,
  buttonTitle,
  onConfirm,
  ...baseModalProps
}:IShareModalModalProps) => {
  return (
    <BaseModal
      title={title}
      className="uniform"
      closeIcon={false}
      {...baseModalProps}
    >
      <div className="share-modal">
        <h5>
          {content && content()}
        </h5>
        <div className="btns flex j-end">
          <button 
            className="big-sub-btn flex j-center a-center"
            onClick={baseModalProps.onClose}  
          >
          Cancel
          </button>
          <button 
            className="big-main-btn flex j-center a-center"
            onClick={onConfirm}
          >
            {buttonTitle}
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default ShareModal;
