import React, { useMemo, useEffect, useState } from 'react';
import { SetRulePriorityPropsType } from '../type';
import { StepContainer } from '../components';
import DetailTable from 'pages/v2/Organ/Management/EC2/components/DetailTable';
import { RULES_COLUMN_LIST } from '../configs';
import { ButtonTypeEnum } from '../components/Button';
import _ from 'lodash';

const SetRulePriority = (props: SetRulePriorityPropsType) => {
  const { title, rules, setCreationData } = props;

  const ruleRows = useMemo(() => {
    return rules.map((rule: any) => {
      const { name, action } = rule;

      return {
        id: name,
        name: name,
        capacity: 1,
        action: action ? Object.keys(action)?.[0] : "Use rule actions",
      };
    });
  }, [rules]);

  const [orderRuleRows, setOrderRuleRows] = useState(ruleRows);
  const [selectedRule, setSelectedRule] = useState('');

  useEffect(() => {
    setOrderRuleRows(ruleRows)
  }, [ruleRows])

  const selectedRuleIndex = ruleRows.findIndex((item: any) => item.id === selectedRule);

  const rulesActionButtons = useMemo(() => {
    return [
      {
        id: 'moveUp',
        label: 'Move up',
        type: ButtonTypeEnum.GENERAL,
        onClick: () => {
          const newRules = [...rules];
          newRules[selectedRuleIndex].priority = newRules[selectedRuleIndex].priority - 1;
          newRules[selectedRuleIndex - 1].priority = newRules[selectedRuleIndex - 1].priority + 1;
          setCreationData({
            rules: _.orderBy(newRules, 'priority')
          })
        },
        disabled: selectedRuleIndex <= 0,
      },
      {
        id: 'moveDown',
        label: 'Move down',
        type: ButtonTypeEnum.GENERAL,
        onClick: () => {
          const newRules = [...rules];
          newRules[selectedRuleIndex].priority = newRules[selectedRuleIndex].priority + 1;
          newRules[selectedRuleIndex + 1].priority = newRules[selectedRuleIndex + 1].priority -1;
          setCreationData({
            rules: _.orderBy(newRules, 'priority')
          })
        },
        disabled: selectedRuleIndex < 0 || selectedRuleIndex === orderRuleRows.length - 1,
      },
    ];
  }, [selectedRuleIndex, orderRuleRows, rules]);

  return (
    <StepContainer title={title}>
      <DetailTable
        title={'Rules'}
        columns={RULES_COLUMN_LIST}
        data={orderRuleRows}
        rightButtons={rulesActionButtons}
        isRadioTable={true}
        reportValue={selectedRule}
        reportSelected={setSelectedRule}
      />
    </StepContainer>
  );
};

export default SetRulePriority;
