import { useId, useMemo } from 'react';
import './index.scss';

const defaultColorSet = {
  backgroundActive: '#758C8B',
  backgroundEnactive: '#E0E0E0',
  buttonActive: '#fff',
  buttonEnactive: '#fff'
};

interface IToggleSwitchProps {
  /** toggle 누를 시 value 바뀌도록 이벤트 필요 */
  onClick: () => void;
  /** toggle 상태 */
  value: boolean;
  /** toggle 너비 */
  width?: number;
  /** toggle 높이 */
  height?: number;
  /** toggle 버튼과 toggle 배경 사이 여백 */
  space?: number;
  /** 색상 변경 필요 시 사용
   * backgroundActive : value true 상태에서의 배경색
   * backgroundEnactive : value false 상태에서의 배경색
   * buttonActive : value true 상태에서의 버튼색
   * buttonEnactive : value false 상태에서의 버튼색
   */
  color?: {
    backgroundActive?: string;
    backgroundEnactive?: string;
    buttonActive?: string;
    buttonEnactive?: string;
  };
  disabled?: boolean;
  className?: string;
}

const ToggleSwitchAtom = ({
  onClick,
  value,
  width = 32,
  height = 18,
  space = 2,
  color = defaultColorSet,
  disabled,
  className
}: IToggleSwitchProps) => {
  const id = useId();
  const useColor = useMemo(() => ({ ...defaultColorSet, ...color }),[color]);
  const borderRadius = useMemo(() => (height / 2), [height]);

  return (
    <div className={`toggle-switch-atom-v4 ${className}`}>
      <input
        type="checkbox"
        className="toggle-switch-check"
        id={`toggle-switch-check-${id}`}
        checked={value}
        onClick={onClick}
        readOnly
        disabled={disabled}
      />
      <label 
        htmlFor={`toggle-switch-check-${id}`}
        className="toggle-switch-background"
        style={{ 
          width: `${width}px`, 
          height: `${height}px`, 
          backgroundColor: value ? useColor.backgroundActive : useColor.backgroundEnactive,
          borderRadius: `${borderRadius}px`
        }}
      >
        <span className="toggle-switch-button" 
          style={{ 
            width: `${height - (space * 2)}px`,
            height: `${height - (space * 2)}px`,
            top: `${space}px`, 
            left: value ? `${width - height + space}px` : `${space}px`,
            backgroundColor: value ? useColor.buttonActive : useColor.buttonEnactive
          }} />
      </label>
    </div>
  );
};

export default ToggleSwitchAtom;
