import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListRolePoliciesResponseType } from 'graphql/types/AwsListRolePoliciesResponse';

export interface IGetListRolePoliciesVariables {
  cloudId: number;
  region: string;
  request: {
    roleName: string;
  }
}

export interface IListRolePoliciesResponseData {
  getAwsListRolePolicies: IGqlResponseNewData<AwsListRolePoliciesResponseType>;
}

const lazyGetAwsListRolePolicies = () => useLazyQuery<IListRolePoliciesResponseData, IGetListRolePoliciesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListRolePolicies;