import './index.scss';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { useOutletContext } from 'react-router-dom';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import MgdTabLayout from 'layouts/v3/MgdTabLayout';
import { NETWORK_TAB_LIST, PageNodeEnum } from './configs';
import { MgdTabLayoutTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import VPC from './components/VPC/ListUp';
import VPCSummary from './components/VPC/Summary';
import VPCDetail from './components/VPC/Detail';
import { NetowrkPropsType } from './types';
import SecurityGroupList from './components/SecurityGroup/SecurityGroupList';
import SecurityGroupSummary from './components/SecurityGroup/SecurityGroupSummary';
import { AwsSecurityGroupType } from 'graphql/types/AwsSecurityGroup';
import SecurityGroupDetail from './components/SecurityGroup/SecurityGroupDetail';
import RouteTable from './components/RouteTable/ListUp';
import RouteTableSummary from './components/RouteTable/Summary';
import RouteTableDetail from './components/RouteTable/Detail';
import { AwsNetworkAclType } from 'graphql/types/AwsNetworkAcl';
import NetworlAclList from './components/NetworlAcl/NetworlAclList';
import NetworkAclSummary from './components/NetworlAcl/NetworlAclSummary';
import NetworlAclDetail from './components/NetworlAcl/NetworlAclDetail';
import Subnets from './components/Subnet/ListUp';
import SubnetSummary from './components/Subnet/Summary';
import SubnetDetail from './components/Subnet/Detail';
import { AwsAddressType } from 'graphql/types/AwsAddress';
import ElasticIpList from './components/ElasticIp/ElasticIpList';
import ElasticIpSummary from './components/ElasticIp/ElasticIpSummary';
import ElasticIpDetail from './components/ElasticIp/ElasticIpDetail';
import Internet from './components/Internet/ListUp';
import InternetSummary from './components/Internet/Summary';
import InternetDetail from './components/Internet/Detail';
import { AwsNatGatewayType } from 'graphql/types/AwsNatGateway';
import NatGWList from './components/NatGW/NatGWList';
import NatGWSummary from './components/NatGW/NatGWSummary';
import NatGWDetail from './components/NatGW/NatGWDetail';
import SecurityGroupCreation from './components/SecurityGroupCreation';
import NetWorkACLCreation from './components/NetWorkACLCreation';

const Network2 = (props: any) => {
  const {
    pageBackClick,
    projectId,
    cloudName,
    regionCode,
    regions,
    recentRelatedClouds,
    relatedCloudSelected,
    recentRelatedCloudOnChange,
    currentRouteState,
    setCurrentRouteState,
  } = props;

  // Context
  const { defaultRegionList, updateDefaultRegionList } = useOutletContext<{
    defaultRegionList: { projectId: number; cloudId: number; name: string; value: string }[];
    updateDefaultRegionList: (projectId: number, cloudId: number, name: string, value: string) => void;
  }>();

  // API
  const [getEnumTypeCode, { loading: enumTypeLoading }] = lazyGetEnumTypeCode();

  // State
  const [pageType, setPageType] = useState<PageNodeEnum>(PageNodeEnum.MGD_LIST);
  const [currentRegion, setCurrentRegion] = useState<DropdownListDataType>({ name: '', value: '' });
  const [regionList, setRegionList] = useState<DropdownListDataType[]>([]);
  const [layoutType, setLayoutType] = useState<MgdTabLayoutTypeEnum>(MgdTabLayoutTypeEnum.MGD_LIST_SUMMARY);
  const [mainTabSelected, setMainTabSelected] = useState<IMgdTabProps>(NETWORK_TAB_LIST[0]);
  const [isDetailPage, setIsDetailPage] = useState(false);
  const [vpcSummary, setVPCSummary] = useState<any>();
  const [routeTable, setRouteTable] = useState<any>();
  const [subnet, setSubnet] = useState<any>();
  const [internet, setInternet] = useState<any>();
  const [detailNatGW, setDetailNatGW] = useState<AwsNatGatewayType | undefined>();
  const [detailElasticIp, setDetailElasticIp] = useState<AwsAddressType | undefined>();
  const [detailNetworkAcl, setDetailNetworkAcl] = useState<AwsNetworkAclType | undefined>();
  const [detailSecurityGroup, setDetailSecurityGroup] = useState<AwsSecurityGroupType | undefined>();

  useEffect(() => {
    if (!currentRouteState) return;

    const currentTab = NETWORK_TAB_LIST.find(e => e.id === currentRouteState.tabId);

    if (currentTab) setMainTabSelected(currentTab);
  }, [currentRouteState]);

  const handleClearState = () => {
    setIsDetailPage(false);
    setVPCSummary(undefined);
    setRouteTable(undefined);
    setSubnet(undefined);
    setInternet(undefined);
    setDetailNatGW(undefined);
    setDetailElasticIp(undefined);
    setDetailNetworkAcl(undefined);
    setDetailSecurityGroup(undefined);
    setPageType(PageNodeEnum.MGD_LIST);
  };

  useEffect(() => {
    handleClearState();
  }, [mainTabSelected]);

  const cloudId = useMemo((): number => {
    return Number(relatedCloudSelected.value);
  }, [relatedCloudSelected]);
  // Get initial region
  const getInitialRegion = useCallback(() => {
    const hasDefaultRegion = defaultRegionList.find(r => {
      return r.projectId === projectId && r.cloudId === cloudId;
    });

    if (!!hasDefaultRegion) {
      setCurrentRegion({
        name: hasDefaultRegion.name,
        value: hasDefaultRegion.value,
      });
    } else {
      getEnumTypeCode({
        variables: { text: regionCode || 'AwsRegionCode' },
      }).then(res => {
        if (res.data) {
          const regionsRes: DropdownListDataType[] = res.data.getEnumTypeCode.data
            .filter(val => regions.includes(val.value))
            .map(val => ({
              ...val,
              name: val.value,
            }));
          setRegionList(regionsRes);
          const region = regionsRes.find(r => r.name === currentRegion.name && r.value === currentRegion.value);
          setCurrentRegion(region || regionsRes[0]);
        }
      });
    }
  }, [cloudId, defaultRegionList]);

  // For initial data
  useEffect(() => {
    getInitialRegion();
  }, [getInitialRegion]);

  const resetMainStates = useCallback(() => {
    if (!currentRouteState) return;

    setCurrentRouteState(null);
  }, [currentRouteState]);

  const mainTabClickHandler = useCallback(
    (tabSelected: IMgdTabProps) => {
      setMainTabSelected(NETWORK_TAB_LIST.find(tabItem => tabItem.id === tabSelected.id) || NETWORK_TAB_LIST[0]);
      resetMainStates();
    },
    [setMainTabSelected, resetMainStates],
  );

  const pinButtonClickHandle = useCallback(() => {
    updateDefaultRegionList(
      projectId,
      Number(relatedCloudSelected.value),
      currentRegion.name || '',
      currentRegion.value.toString(),
    );
  }, [relatedCloudSelected, currentRegion, projectId]);

  const tabContentNode = useMemo(() => {
    if (!currentRegion?.value || !cloudId) return;

    switch (mainTabSelected) {
      case NETWORK_TAB_LIST[0]: {
        return (
          <VPC
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            currentRegion={currentRegion}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            relatedCloudSelected={relatedCloudSelected}
            setDetailItem={setVPCSummary}
            setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
          />
        );
      }
      case NETWORK_TAB_LIST[1]:
        return (
          <RouteTable
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            currentRegion={currentRegion}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            relatedCloudSelected={relatedCloudSelected}
            setDetailItem={setRouteTable}
            setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
          />
        );
      case NETWORK_TAB_LIST[2]:
        return (
          <Subnets
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            currentRegion={currentRegion}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            relatedCloudSelected={relatedCloudSelected}
            setDetailItem={setSubnet}
            setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
          />
        );
      case NETWORK_TAB_LIST[3]:
        return (
          <Internet
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            currentRegion={currentRegion}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            relatedCloudSelected={relatedCloudSelected}
            setDetailItem={setInternet}
            setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
          />
        );
      case NETWORK_TAB_LIST[4]:
        return (
          <NatGWList
            cloudId={cloudId}
            region={currentRegion}
            tabInformation={mainTabSelected}
            onNatGWSelected={setDetailNatGW}
            onNatGWIdClicked={() => setPageType(PageNodeEnum.MGD_DETAIL)}
          />
        );
      case NETWORK_TAB_LIST[5]:
        return (
          <ElasticIpList
            cloudId={cloudId}
            region={currentRegion}
            tabInformation={mainTabSelected}
            onElasticIpSelected={setDetailElasticIp}
            onElasticIpIdClicked={() => setPageType(PageNodeEnum.MGD_DETAIL)}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
          />
        );
      case NETWORK_TAB_LIST[6]:
        return (
          <NetworlAclList
            cloudId={cloudId}
            region={currentRegion}
            tabInformation={mainTabSelected}
            onNetworkAclSelected={setDetailNetworkAcl}
            onNetworkAclIdClicked={() => setPageType(PageNodeEnum.MGD_DETAIL)}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
          />
        );
      case NETWORK_TAB_LIST[7]:
        return (
          <SecurityGroupList
            cloudId={cloudId}
            region={currentRegion}
            tabInformation={mainTabSelected}
            onSecurityGroupSelected={setDetailSecurityGroup}
            onSecurityGroupIdClicked={() => setPageType(PageNodeEnum.MGD_DETAIL)}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
          />
        );
      default:
        return null;
    }
  }, [mainTabSelected, currentRegion, cloudId, currentRouteState]);

  const tabSummaryNode = useMemo(() => {
    switch (mainTabSelected) {
      case NETWORK_TAB_LIST[0]:
        return <VPCSummary data={vpcSummary} />;
      case NETWORK_TAB_LIST[1]:
        return <RouteTableSummary cloudId={cloudId} region={currentRegion.value.toString()} data={routeTable} />;
      case NETWORK_TAB_LIST[2]:
        return <SubnetSummary cloudId={cloudId} region={currentRegion.value.toString()} data={subnet} />;
      case NETWORK_TAB_LIST[3]:
        return <InternetSummary cloudId={cloudId} region={currentRegion.value.toString()} data={internet} />;
      case NETWORK_TAB_LIST[4]:
        return <NatGWSummary natGW={detailNatGW!} />;
      case NETWORK_TAB_LIST[5]:
        return <ElasticIpSummary elasticIp={detailElasticIp!} />;
      case NETWORK_TAB_LIST[6]:
        return <NetworkAclSummary networkAcl={detailNetworkAcl!} />;
      case NETWORK_TAB_LIST[7]:
        return (
          <SecurityGroupSummary
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            securityGroup={detailSecurityGroup!}
          />
        );
      default:
        return null;
    }
  }, [
    mainTabSelected,
    cloudId,
    currentRegion,
    projectId,
    vpcSummary,
    routeTable,
    subnet,
    internet,
    detailNatGW,
    detailElasticIp,
    detailNetworkAcl,
    detailSecurityGroup,
  ]);

  const onPageBackClick = useCallback(() => {
    handleClearState();
  }, []);

  const detailNode = useMemo((): ReactNode => {
    switch (mainTabSelected) {
      case NETWORK_TAB_LIST[0]:
        return <VPCDetail data={vpcSummary} pageBackClick={onPageBackClick} />;
      case NETWORK_TAB_LIST[1]:
        return (
          <RouteTableDetail
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            data={routeTable}
            pageBackClick={onPageBackClick}
          />
        );
      case NETWORK_TAB_LIST[2]:
        return (
          <SubnetDetail
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            data={subnet}
            pageBackClick={onPageBackClick}
          />
        );
      case NETWORK_TAB_LIST[3]:
        return (
          <InternetDetail
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            data={internet}
            pageBackClick={onPageBackClick}
          />
        );
      case NETWORK_TAB_LIST[4]:
        return <NatGWDetail natGW={detailNatGW!} pageBackClick={onPageBackClick} />;
      case NETWORK_TAB_LIST[5]:
        return <ElasticIpDetail elasticIp={detailElasticIp!} pageBackClick={onPageBackClick} />;
      case NETWORK_TAB_LIST[6]:
        return (
          <NetworlAclDetail
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            networkAcl={detailNetworkAcl!}
            pageBackClick={onPageBackClick}
          />
        );
      case NETWORK_TAB_LIST[7]:
        return (
          <SecurityGroupDetail
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            securityGroup={detailSecurityGroup!}
            pageBackClick={onPageBackClick}
          />
        );
      default:
        return null;
    }
  }, [
    mainTabSelected,
    isDetailPage,
    cloudId,
    currentRegion,
    vpcSummary,
    routeTable,
    subnet,
    internet,
    detailNatGW,
    detailElasticIp,
    detailNetworkAcl,
    detailSecurityGroup,
    onPageBackClick,
  ]);

  const listNode = useMemo((): ReactNode => {
    return (
      <MgdTabLayout
        layoutType={layoutType}
        pageTitle={cloudName}
        hasFavorite={true}
        onBackButtonClicked={pageBackClick}
        headerDropList={regionList}
        headerDropValue={currentRegion}
        headerDropOnchange={val => {
          setCurrentRegion(() => ({
            name: val.name ? val.name : '',
            value: String(val.value),
          }));
        }}
        isPined={
          !!defaultRegionList.find(r => {
            return r.projectId === projectId && r.cloudId === relatedCloudSelected.value;
          })
        }
        pinBtnActHandle={pinButtonClickHandle}
        pinBtnDisabled={false}
        mainTabList={NETWORK_TAB_LIST}
        mainTabItemClick={mainTabClickHandler}
        mainTitle={mainTabSelected?.title || ''}
        mainTabSelected={mainTabSelected}
        tabContentNode={tabContentNode}
        tabSummaryNode={tabSummaryNode}
        recentRelatedClouds={recentRelatedClouds}
        relatedCloudSelected={relatedCloudSelected}
        recentRelatedCloudOnChange={relatedCloud => recentRelatedCloudOnChange(relatedCloud)}
      />
    );
  }, [
    layoutType,
    cloudName,
    pageBackClick,
    regionList,
    currentRegion,
    defaultRegionList,
    projectId,
    relatedCloudSelected,
    pinButtonClickHandle,
    mainTabClickHandler,
    mainTabSelected,
    tabContentNode,
    tabSummaryNode,
    recentRelatedClouds,
    recentRelatedCloudOnChange,
  ]);

  const creationNode = useMemo((): ReactNode => {
    switch (mainTabSelected) {
      case NETWORK_TAB_LIST[6]:
        return (
          <NetWorkACLCreation
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            pageBackClick={() => setPageType(PageNodeEnum.MGD_LIST)}
          />
        );
      case NETWORK_TAB_LIST[7]:
        return (
          <SecurityGroupCreation
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            pageBackClick={() => setPageType(PageNodeEnum.MGD_LIST)}
          />
        );
      default:
        break;
    }
  }, [mainTabSelected, cloudId, currentRegion]);

  const pageNode = useMemo((): ReactNode => {
    switch (pageType) {
      default:
      case PageNodeEnum.MGD_LIST:
        return listNode;

      case PageNodeEnum.MGD_DETAIL:
        return detailNode;

      case PageNodeEnum.MGD_CREATE:
        return creationNode;
    }
  }, [pageType, listNode, detailNode, creationNode]);

  return <div className="network-container">{pageNode}</div>;
};

export default Network2;
