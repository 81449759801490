import { IpV4AddressPropsType } from '../types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import DetailTable from 'pages/v2/Organ/Management/EC2/components/DetailTable';

const IpV4Address = (props: IpV4AddressPropsType) => {
  const { natGW, rightButtons } = props;

  const [tablePagination, setTablePagination] = useState({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [rows, setRows] = useState<RowType[]>([]);

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Private IPv4 address',
        field: 'privateIp',
        sort: true,
      },
      { label: 'Allocation ID', field: 'allocationId', sort: true },
      { label: 'Association ID', field: 'associationId', sort: true },
      { label: 'Public IPv4 address', field: 'publicIpv4Address', sort: true },
      {
        label: 'Network interface ID',
        field: 'networkInterfaceId',
        sort: true,
      },
      { label: 'Status', field: 'state', sort: true },
      { label: 'Failure message', field: 'failureMessage', sort: true },
    ];
  }, []);

  const apiIsLoading = useMemo(() => {
    return false;
  }, []);

  const getDetailDataRows = useCallback(() => {
    const networkAclAssociations: RowType[] = [];
    const natGatewayAddresses = natGW?.natGatewayAddresses;
    if (natGatewayAddresses) {
      natGatewayAddresses.map((gatewayAdd) => {
        if (gatewayAdd?.isPrimary === false) {
          networkAclAssociations.push({
            privateIp: gatewayAdd?.privateIp,
            allocationId: gatewayAdd?.allocationId,
            associationId: gatewayAdd?.associationId,
            publicIpv4Address: gatewayAdd?.publicIp,
            networkInterfaceId: gatewayAdd?.networkInterfaceId,
            state: gatewayAdd?.status,
            failureMessage: gatewayAdd?.failureMessage,
          });
        }
        
      });
    }
    
    setRows(networkAclAssociations);
  }, [natGW]);

  const currentRowPage = useMemo((): RowType[] => {
    return orderAlphabetical(rows, tablePagination.target, tablePagination.direction);
  }, [rows, tablePagination]);

  useEffect(() => {
    getDetailDataRows();
  }, []);

  return (
    <Fragment>
      {apiIsLoading ? (
        <div className="storage-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="listeners-info">
            <div className="table-container">
              <DetailTable
                title={'Subnet associations'}
                data={currentRowPage}
                columns={columns}
                hasPagination={true}
                rightButtons={rightButtons}
              />
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default IpV4Address;
