import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import Table from 'components/v2/dataDisplay/Table';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import { TABLE_OFFSET } from 'utils/DummyData/Dropdown';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { useOutletContext } from 'react-router-dom';
import { currentStatusFormatter } from 'utils/Formatter';
import LabelInput from 'components/v2/LabelInput';
import Labeled from 'components/v2/atoms/Labeled';
import DeleteReportModal from 'components/v2/modals/DeleteReportModal';
import Icon from 'components/v2/atoms/Icon';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import Report from 'assets/svgs/v2/ico_redcircle_report.svg';
import CreateReportModal from 'components/v2/modals/CreateReportModal';
import CurrentStatusCard from '../components/CurrentStatusCard';
import ReportDetail from '../components/ReportDetail';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import deleteReport from 'graphql/mutations/deleteReport';
import { timeToKr } from 'utils/timeFormatter';
import addWeeklyReport from 'graphql/mutations/addWeeklyReport';
import IsLoading from 'components/v2/atoms/IsLoading';
import { useAuth } from 'contexts/AuthProvider';
import getProjectInfoByOrgan from 'graphql/queries/getProjectInfoByOrgan';
import getWeeklyReportPageByParam from 'graphql/queries/getWeeklyReportPageByParam';
import { handleFormatText } from 'utils/Common';

/**
 * <>주간 리포트</>
*/
const WeeklyReport = () => {
  const { role } = useAuth();
  const { projectList, organizationId } = useOutletContext<{ projectList: ProjectInfo[], organizationId: number | null }>();

  const [modalIsOpen, setModalIsOpen] = useState({
    delete: false,
    create: false,
    report: false 
  });
  const [readyToSearch, setReadyToSearch] = useState({ project: -1, name: '' });
  const [search, setSearch] = useState({ project: -1, name: '' });
  const [total, setTotal] = useState({
    totalElement: 0,
    totalPage: 0
  });
  const [rows, setRows] = useState<RowType[] >([]); /* ReportDetail[] */
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');
  const [pjList, setPjList] = useState<{name: string; value: number}[]>([]);
  
  /** 주간 리포트 조회 */
  const [getWeeklyReports, { loading: loadingGetWeeklyReportPage }] = getWeeklyReportPageByParam();
  /** 리포트 삭제 */
  const [deleteReports] = deleteReport();
  /** 주간리포트 생성 */
  const [createWeekly] = addWeeklyReport();
  /* 조직id에 따른 pjlist 가져오기 for lg운영자 */
  const [getProjectListByOrganId] = getProjectInfoByOrgan();

  const getWeeklyReportData = useMemo(() => {
    return {
      reqData: {
        projectId: search.project === -1 ? undefined : search.project,
        text: search.name === '' ? undefined : search.name,
        organId: organizationId,
        pageInfo: {
          page: tablePagination.currentPage - 1,
          size: tablePagination.limit,
          orders: [{
            target: tablePagination.target === 'projectName' ? 'projectId' : tablePagination.target === 'modifiedByName' ? 'modifiedBy' : tablePagination.target,
            direction: tablePagination.direction
          }]
        }
      }
    };
  }, [projectList, search, tablePagination, search]);

  const COLUMNS:ColumnType[] = useMemo(() => {
    return [
      {
        label: 'Name',
        field: 'name',
        sort: true
      },
      {
        label: 'Project',
        field: 'projectName',
        sort: true
      },
      {
        label: 'Version',
        field: 'currentVersion',
        sort: true,
        renderCell: (row:RowType) => <>{handleFormatText(!row.currentVersion ? '-' : row.currentVersion)}</>
      },
      {
        label: 'Status',
        field: 'currentStatus',
        width: 120,
        sort: true,
        valueGetter: (row:RowType) => currentStatusFormatter(row.currentStatus),
        renderCell: (row:RowType) => <CurrentStatusCard status={row.currentStatus} />
      },
      {
        label: 'Updated by',
        field: 'modifiedByName',
        sort: true
      },
      {
        label: 'Updated at',
        field: 'modifiedAt',
        width: 120,
        renderCell: (row:RowType) => <>{timeToKr(row.modifiedAt)}</>, 
        sort: true

      }
    ];
  }, [rows]);

  const getWeeklyReport = () => {
    getWeeklyReports({ variables: getWeeklyReportData }).then(({ data }) => {
      if(data) {
        const newRows = data.getWeeklyReportPageByParam.content.map(val => ({ ...val, id: val.reportId }));
        setRows(newRows);
        setTotal({
          totalElement: data.getWeeklyReportPageByParam.totalElements,
          totalPage: data.getWeeklyReportPageByParam.totalPages
        });
      }
    });
  }

  useEffect(() => {
    if (role === 'sy_admin') {
      getProjectListByOrganId({variables: { organId: Number(organizationId)}}).then(({ data }) => {
        if (data) {
          setPjList(data.getProjectInfoByOrganId.data.map(val => ({
            name: val.name,
            value: val.projectId
          })))
        }
      })
    } else {
      setPjList(projectList.map(val => ({
        name: val.name,
        value: val.projectId
      })))
    }
  }, [role, organizationId, projectList]);

  useEffect(() => getWeeklyReport(), [getWeeklyReportData]);


  return (
    <>
    <div id="weekly-report-page">
      <div className="header flex j-between a-center">
        <div className="flex j-start a-center">
          Weekly report
        </div>
      </div>
      <div className="search flex j-start">
        <Labeled title="Project" required>
          <DropdownAtom 
            id="select-project" 
            data={[
              {
                name: 'All',
                value: -1
              },
              ...pjList
            ]} 
            value={{
              name: readyToSearch.project === -1 
                ? 'All' 
                : (pjList.find(pj => pj.value === readyToSearch.project)?.name 
                  ? pjList.find(pj => pj.value === readyToSearch.project)?.name 
                  : ''),
              value: readyToSearch.project
            }} 
            handleClick={(val:DropdownListDataType) => setReadyToSearch(prev => ({
              ...prev,
              project: Number(val.value)
            }))} 
          />
        </Labeled>
        <form 
          className="flex"
          onSubmit={(e) => {
            e.preventDefault();
            setSearch({
              project: readyToSearch.project,
              name: readyToSearch.name
            });
            setTablePagination(prev => ({
              ...prev,
              currentPage: 1
            }));
          }}
        >
          <LabelInput 
            title="Search"
            required
            placeholder="Input report name"
            value={readyToSearch.name}
            onChangeValue={(str) => setReadyToSearch(prev => ({
              ...prev,
              name: str
            }))}
          />
          <div className="btns">
            <button className="big-main-btn">
            search
            </button>
          </div>
        </form>
      </div>

      <div className='content-wrap'>
        <div className="content">
          <div className="content-head-wrap flex j-between">
            <div className="head-title flex a-center">
              <div className="document-icon" /> Results
            </div>
            <div className="head-btns flex a-center">
              {checkedList.length > 0 && `${checkedList.length} selected`}
              <button
                className="big-sub-btn delete"
                disabled={checkedList.length === 0}
                onClick={() => setModalIsOpen((prev) => ({
                  ...prev,
                  delete: true
                }))}
              >
              Delete
              </button>
              <button
                className="big-main-btn register"
                onClick={() => setModalIsOpen((prev) => ({
                  ...prev,
                  create: true
                }))}
              >
              Create
              </button>
              <DropdownAtom
                id="offset-limit"
                data={TABLE_OFFSET}
                value={{
                  name: TABLE_OFFSET.find(data => data.value === tablePagination.limit)?.name,
                  value: Number(tablePagination.limit)
                }}
                handleClick={(val) => {
                  setTablePagination(prev => ({
                    ...prev,
                    limit: Number(val.value),
                    currentPage: 1
                  }))
                }}
              />
            </div>
          </div>
          <div className="data-grid-wrap">
            <Table
              rows={rows}
              columns={COLUMNS}
              reportCheckedList={(list) => setCheckedList(list)}
              reportSelected={(id) => setSelected(id)}
              sortOption={{
                target: tablePagination.target,
                direction: tablePagination.direction,
                onChangeSort: (target: string, dir: OrderDirection) => {
                  setTablePagination(prev => ({
                    ...prev,
                    target: target,
                    direction: dir
                  }));
                }
              }}
            />
            {
              (!loadingGetWeeklyReportPage && rows.length === 0 && total.totalElement === 0) ? 
              <div className="empty-rows flex j-center a-center">
                <div className="flex col a-center">
                  <div />
                  <h5>There is no item.</h5>
                  <button 
                    className="big-main-btn flex j-center a-center"
                    onClick={() => setModalIsOpen(prev => ({
                      ...prev,
                      create: true
                    }))}
                  >
                    Create
                  </button>
                </div>
              </div>
               : (
                (rows.length === 0 && total.totalElement !== 0) ? 
                <div className="empty flex col j-center a-center">
                  <div className="search-icon" />
                  <h5>There is no item.</h5>
                  <p>Please try again with different search terms.</p>
                </div> :
                <div className="pagination-wrapper flex a-center">
                  <p className="flex a-center">Total <span>{total.totalElement}</span></p>
                  <TablePagination
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={(page) => setTablePagination(prev => ({
                      ...prev,
                      currentPage: page
                    }))}
                    totalPage={total.totalPage}
                  />
                </div>
                )
            }
          </div>
        </div>
        { loadingGetWeeklyReportPage && <IsLoading dimmed={rows.length !== 0 } /> }
      </div>

      <DeleteReportModal 
        open={modalIsOpen.delete} 
        data={rows.filter(val => checkedList.includes(String(val.id)))}
        title={() => 
          <>
            <Icon width={32} height={32} src={Report} />
            Delete reports
          </>
        } 
        onClose={() => { 
          setModalIsOpen(prev => ({
            ...prev,
            delete: false
          }));
        }}
        onDelete={() => {
          const data = {
            reqDelReport: {
              reportIds: checkedList.map(val => Number(val))
            }
          };

          deleteReports({ variables: data }).then(({ data }) => {
            if(data) {
              if (data.deleteReport.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, 'Weekly report is deleted successfully.');
                setModalIsOpen(prev => ({
                  ...prev,
                  delete: false
                }));
                
                if ((rows.length === checkedList.length) && total.totalPage !== 1) {
                  setTablePagination(prev => ({
                    ...prev,
                    currentPage: tablePagination.currentPage - 1
                  }));
                } else {
                  getWeeklyReport();
                }
              } else {
                console.log(data.deleteReport.result);
                useToast(ErrorCode.UNKNOWN, 'Delete Weekly report failed.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, 'Delete Weekly report failed.');
            }
          });

        }} 
      />

      <CreateReportModal 
        open={modalIsOpen.create} 
        title={() => 
          <>
            <Icon width={32} height={32} src={Report} />
            Create weekly report
          </>
        }
        placeHolder="예) 24.01.01 ~ 24.01.07 Weekly report"
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          create: false
        }))}
        onCreate={(payload) => {
          const addWeeklyReportData = {
            report: {
              ...payload
            }
          };
          createWeekly({variables: addWeeklyReportData}).then(({ data }) => {
            if (data) {
              if (data.addWeeklyReport.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, 'Weekly report is created successfully.');
                setModalIsOpen(prev => ({
                  ...prev,
                  create: false
                }));
                
                getWeeklyReport();
              } else {
                console.log(data.addWeeklyReport.result);
                useToast(ErrorCode.SUCCESS, 'Create weekly report failed.');
              }
            } else {
              useToast(ErrorCode.SUCCESS, 'Create weekly report failed.');
            }
          });
        }} 
      />

    </div>
    {
      selected !== '' && 
      <ReportDetail 
        data={rows.find(val => val.reportId === Number(selected)) as RowType} 
        getOffPage={() => setSelected('')}
        onPublishSuccess={() => getWeeklyReport()}
        relObjectType="report_w"
      />
    }
    </>
  );
};

export default WeeklyReport;
