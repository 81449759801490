import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { DashboardSpecType } from 'graphql/types/DashboardListType';

export interface IGetDashboardByIdVariables {
  id: number
}

export interface IGetDashboardByIdResponseData {
  getDashboardById: IGqlResponseData<DashboardSpecType[]>;
}

const lazyGetDashboardById = () => useLazyQuery<IGetDashboardByIdResponseData, IGetDashboardByIdVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetDashboardById;