import { ColumnType } from "@Types/v2/Table";
import { DropdownListDataType } from "components/Dropdown/types";
import { TextTypeEnum } from "layouts/v3/MgdTabLayout/configs";

export const DETAIL_TEXT_DESCRIPTION = [
  {id: 'networkAclId', value: 'Network ACL ID', type: TextTypeEnum.COPY},
  {id: 'associations', value: 'Associated with', type: TextTypeEnum.LINK, isTooltip:  true},
  {id: 'isDefault', value: 'Default', type: TextTypeEnum.NORMAL},
  {id: 'vpcId', value: 'VPC', type: TextTypeEnum.LINK},
  {id: 'ownerId', value: 'Owner ID', type: TextTypeEnum.COPY}
]

export const NETWORK_ACL_SUMMARY_TAB_LIST = [
  {id: 'details', name: 'Details', title: 'Details'},
  {id: 'inbound', name: 'Inbound', title:'Inbound'},
  {id: 'outbound', name: 'Outbound', title: 'Outbound'},
  {id: 'subnetAssociations', name: 'Subnet associations', title: 'Subnet associations'},
  {id: 'tags', name: 'Tags', title: 'Tags'}
];

export const DETAIL_TAB_LIST = [
  {id: 'inbound', name: 'Inbound', title:'Inbound'},
  {id: 'outbound', name: 'Outbound', title: 'Outbound'},
  {id: 'subnetAssociations', name: 'Subnet associations', title: 'Subnet associations'},
  {id: 'tags', name: 'Tags', title: 'Tags'}
];

export const NETWORK_ACL_FILTER_DROPDOWN: DropdownListDataType[] = [
  {id: 1, name: 'Network ACL ID', value: 'network-acl-id'},
  {id: 2, name: 'Name', value: 'tag:Name'},
  {id: 3, name: 'VPC ID', value: 'vpc-id'}
];
