import { SubnetAssociationPropsType } from '../types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import DetailTable from '../../DetailTable';

const SubnetAssociation = (props: SubnetAssociationPropsType) => {
  const { networkAcl, rightButtons } = props;

  const [tablePagination, setTablePagination] = useState({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [rows, setRows] = useState<RowType[]>([]);

  const columns = useMemo((): ColumnType[] => {
    return [
      { label: 'Name', field: 'name', sort: true },
      { label: 'Subnet ID', field: 'subnetId', sort: true },
      { label: 'Associated with', field: 'networkAclId', sort: true },
      {
        label: 'Availability zone',
        field: 'availabilityZone',
        sort: true,
      },
      { label: 'IPv4 CIDR', field: 'ipv4cidr', sort: true },
      { label: 'IPv6 CIDR', field: 'ipv6cidr', sort: true },
    ];
  }, []);

  const apiIsLoading = useMemo(() => {
    return false;
  }, []);

  const getDetailDataRows = useCallback(() => {
    const networkAclAssociations: RowType[] = [];
    networkAcl.associations.forEach((row: any) => {
      networkAclAssociations.push({
        id: row.subnetId,
        name: '-',
        subnetId: row.subnetId,
        networkAclId: 'networkAclId',
        availabilityZone: 'availabilityZone',
        ipv4cidr: '-',
        ipv6cidr: '-',
      });
    });
    setRows(networkAclAssociations);
  }, [networkAcl]);

  const currentRowPage = useMemo((): RowType[] => {
    return orderAlphabetical(rows, tablePagination.target, tablePagination.direction);
  }, [rows, tablePagination]);

  useEffect(() => {
    getDetailDataRows();
  }, []);

  return (
    <Fragment>
      {apiIsLoading ? (
        <div className="storage-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="listeners-info">
            <div className="table-container">
              <DetailTable
                title={'Subnet associations'}
                data={currentRowPage}
                columns={columns}
                hasPagination={true}
                rightButtons={rightButtons}
              />
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default SubnetAssociation;
