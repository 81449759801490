import { gql } from '@apollo/client';

const query = gql`
  query getOrganById($organId: Int) {
    getOrganById(organId: $organId) {
      result
      data {
        ... on Organ {
          organId
          name
          description
          createdAt
          createdBy
          createdByUserId
          createdByName
          modifiedAt
          modifiedBy
          modifiedByUserId
          modifiedByName
        }
      }
    }
  }
`;

export default query;