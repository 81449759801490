import { gql } from '@apollo/client';

const query = gql`query getDashboardById($id: Long) {
  getDashboardById(id: $id) {
    result
    data {
      ... on Dashboard {
        id
        name
        type
        templateId
        templateName
        organId
        parameters
      }
    }
  }
}`;
export default query;