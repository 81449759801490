import React, { useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, SectionContainer, SectionTitle, StepContainer } from '../../../../WebACL/CreateWebACL/components';
import DetailTable from 'pages/v2/Organ/Management/EC2/components/DetailTable';
import { RULES_COLUMN_LIST } from '../../configs';
import { ButtonType } from '../../../../WebACL/CreateWebACL/components/types';
import { ButtonTypeEnum } from '../../../../WebACL/CreateWebACL/components/Button';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import SectionItem from '../../../../WebACL/CreateWebACL/components/SectionItem';
import { AwsRulelogType } from 'graphql/types/AwsCheckCapacity';
import lazyGetAwsCheckCapacity from 'graphql/queries/getAwsCheckCapacity';
import CustomRuleModal from '../../../../CustomRuleModal';
import ManagedRuleModal from '../../../../ManagedRuleModal';
import _ from 'lodash';

type DescribeRuleGroupPropsType = {
  creationData: any;
  handleValueChanged: (key: string, value: any) => void;
  errors: {
    [key: string]: string;
  };
  cloudId: number;
  region: string;
};

const AddRulesAndSetCapacity = (props: DescribeRuleGroupPropsType) => {
  const { creationData, handleValueChanged, errors, cloudId } = props;
  const { name, description, cloudWatchMetricName, region, rules = [], capacity, minimumCapacity } = creationData ?? {};

  const [checkCapacity] = lazyGetAwsCheckCapacity();

  const [addRuleDropdownValue, setAddRuleDropdownValue] = useState({ name: 'Add rule', value: '' });
  const [isCustomRuleModalVisible, setCustomRuleModalVisible] = useState(false);
  const [isManagedRuleModalVisible, setManagedRuleModalVisible] = useState(false);

  const handleCheckCapacity = useCallback(
    async (rules: Array<AwsRulelogType>) => {
      const variables = {
        cloudId: cloudId,
        region: region?.value !== 'CloudFront' ? String(region?.value) : 'us-east-1',
        request: {
          scope: region?.value !== 'CloudFront' ? 'REGIONAL' : 'CLOUDFRONT',
          rules: rules.map(rule => _.omit(rule, 'capacity')),
        },
      };
      return await checkCapacity({ variables }).then(res => {
        return res?.data?.getAwsCheckCapacity?.data?.[0]?.capacity ?? 0;
      });
    },
    [cloudId, region],
  );

  const getMinimumCapacity = useCallback(async () => {
    const _minimumCapacity = await handleCheckCapacity(rules);

    handleValueChanged('minimumCapacity', _minimumCapacity);
  }, [rules.length]);

  useEffect(() => {
    getMinimumCapacity();
  }, [getMinimumCapacity]);

  const [selectedRule, setSeletedRule] = useState<string>('');

  const ruleRows = useMemo(() => {
    return rules.map((rule: any) => {
      const { name, action, capacity, id } = rule;

      return {
        id,
        name: name,
        capacity: capacity,
        action: Object.keys(action)[0],
      };
    });
  }, [rules]);

  const handleRemoveRule = useCallback(
    (ruleId: string) => {
      let newRules = rules.filter((item: any) => item.id !== ruleId);

      handleValueChanged('rules', newRules);
    },
    [rules],
  );

  const onDropdownValueChanged = useCallback((dropdown: DropdownListDataType) => {
    if (dropdown.value === 'managedRule') {
      setManagedRuleModalVisible(true);
    }

    if (dropdown.value === 'ownRule') {
      setCustomRuleModalVisible(true);
    }
  }, []);

  const rulesActionButtons: Array<ButtonType> = useMemo(() => {
    return [
      {
        id: 'edit',
        label: 'Edit',
        type: ButtonTypeEnum.GENERAL,
        onClick: () => setCustomRuleModalVisible(true),
        disabled: selectedRule === '',
      },
      {
        id: 'delete',
        label: 'Delete',
        type: ButtonTypeEnum.PRIMARY,
        onClick: () => handleRemoveRule(selectedRule),
        disabled: selectedRule === '',
      },
      {
        id: 'add',
        label: 'Add rule',
        type: ButtonTypeEnum.DROPDOWN,
        onClick: () => {},
        dropdownList: [{ name: 'Add my own rules and rule groups', value: 'ownRule' }],
        dropdownValue: addRuleDropdownValue,
        onDropdownValueChanged,
      },
    ];
  }, [addRuleDropdownValue, selectedRule]);

  const handleAddRule = useCallback(
    async (rule: any) => {
      setCustomRuleModalVisible(false);
      const capacity = await handleCheckCapacity([rule]);
      const newRules = [...rules, { ...rule, capacity, id: _.uniqueId() }];
      handleValueChanged(
        'rules',
        newRules.map((rule, index: number) => ({ ...rule, priority: index })),
      );
    },
    [rules],
  );

  return (
    <StepContainer
      title={'Add rules and set capacity'}
      description={
        'Rules define attack patterns to look for in web requests and the action to take when a request matches the patterns. Add rules to this rule group and use this rule group in a web ACL.'
      }
    >
      <DetailTable
        title={'Rules'}
        description={
          'If a request matches a rule, take the corresponding action. The rules are prioritized in order they appear.'
        }
        columns={RULES_COLUMN_LIST}
        data={ruleRows}
        rightButtons={rulesActionButtons}
        isRadioTable={true}
        reportValue={selectedRule}
        reportSelected={setSeletedRule}
      />

      <SectionContainer
        title="Capacity"
        description="Capacity limits the number and complexity of rules in a rule group. Estimate the capacity based on the capacity units used by the rules you've already added and your future plans for this rule group. After you create the rule group, you can't change the capacity.
        "
      >
        <SectionTitle title="Minimum required capacity" noBottomSpace />
        <SectionItem.Container title={"This is based on the rules you've already added to the rule group."} noTopSpace>
          <SectionItem.StyledText text={`${minimumCapacity ?? 1}`} />
          <Input
            title={'Capacity'}
            placeholder="Enter capacity"
            bottomDescription="Enter a number from 1 to 5,000"
            value={capacity}
            onValueChanged={value => handleValueChanged('capacity', value)}
            isRequired
            error={errors?.capacity}
          />
        </SectionItem.Container>
      </SectionContainer>

      {isCustomRuleModalVisible && (
        <CustomRuleModal
          isOpen={isCustomRuleModalVisible}
          onClose={() => setCustomRuleModalVisible(false)}
          cloudId={cloudId}
          selectedRegion={region}
          onAddRule={handleAddRule}
          webAcl={creationData}
          isRuleBuilderOnly
        />
      )}
    </StepContainer>
  );
};

export default AddRulesAndSetCapacity;
