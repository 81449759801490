import { gql } from '@apollo/client';

const query = gql`
query getProjectsByMember($reqData: UUID) {
  getProjectsByMember(reqData: $reqData) {
    result
    data {
      ... on Project {
        projectId
        name
      }
    }
  }
}
`;
export default query;