import './index.scss';
import { ColumnType, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import { enumFormatter } from 'utils/Formatter';

interface IDeleteDictionaryModalProps extends IBaseModalProps {
  data: RowType[]
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteDictionaryModal = ({  
  data,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteDictionaryModalProps) => {
  const COLUMNS:ColumnType[] = [
      {
        label: 'Use type',
        field: 'relObjectType',
        sort: true,
        renderCell: (row: RowType) => <>{String(enumFormatter(row.relObjectType))}</>
      },
      {
        label: 'Item name',
        field: 'relObjectName',
        sort: true
      },
      {
        label: 'Ref. value',
        field: 'defaultName',
        sort: true
      },
      {
        label: 'Setting value',
        field: 'domainName',
        sort: true
      }
    ];
  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-dictionary-modal">
        <div className="content">
          <Table rows={data} columns={COLUMNS}/>
        </div>
        <div className="btns flex j-end">
          <button 
            className="big-sub-btn flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            Cancel
          </button>
          <button 
            className="big-main-btn flex j-center a-center" 
            onClick={onDelete}
          >
          Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteDictionaryModal;
