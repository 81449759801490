import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import DetailTab from '../../../DetailTab';
import { handleLinkClicked } from 'pages/v2/Organ/Management/Utils';
import { useNavigate } from 'react-router-dom';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import { NETWORK_TAB_LIST } from '../../../../configs';
import { networkACLFilterDropdown, routeTableFilterDropdown } from '../../../../Constants';

function Details({ data, isLoading }: any) {
  const navigate = useNavigate();

  const {
    vpcId,
    state,
    enableDnsHostnames,
    enableDnsSupport,
    instanceTenancy,
    dhcpOptionsId,
    mainRouteTableId,
    mainNetAclId,
    isDefault,
    cidrBlock,
    ipv6Pool,
    ipv6CidrBlock,
    firewallRuleAssociations,
    firewallRoute53Associations,
    ownerId,
  } = data || {};

  const detailsData: IMgdDetailKeyValueProps[] = [
    {
      title: 'VPC ID',
      description: vpcId,
      type: TextTypeEnum.COPY,
    },
    {
      title: 'Status',
      description: state,
      type: TextTypeEnum.STATUS,
    },
    {
      title: 'DNS hostnames',
      description: enableDnsHostnames ? 'Enabled' : 'Disabled',
    },
    {
      title: 'DNS resolution',
      description: enableDnsSupport ? 'Enabled' : 'Disabled',
    },
    {
      title: 'Tenancy',
      description: instanceTenancy,
    },
    {
      title: 'DHCP option set',
      description: dhcpOptionsId,
    },
    {
      title: 'Main route table',
      description: mainRouteTableId,
      type: TextTypeEnum.LINK,
      handleItemClick: () =>
        handleLinkClicked({
          navigate,
          link: '/organ/1/manage/network',
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[1].id,
          key: routeTableFilterDropdown[0].value.toString(),
          value: mainRouteTableId,
        }),
    },
    {
      title: 'Main network ACL',
      description: mainNetAclId,
      type: TextTypeEnum.LINK,
      handleItemClick: () =>
        handleLinkClicked({
          navigate,
          link: '/organ/1/manage/network',
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[6].id,
          key: networkACLFilterDropdown[0].value.toString(),
          value: mainNetAclId,
        }),
    },
    {
      title: 'Default VPC',
      description: isDefault ? 'Yes' : 'No',
    },
    {
      title: 'IPv4 CIDR',
      description: cidrBlock,
    },
    {
      title: 'IPV6 pool',
      description: ipv6Pool,
    },
    {
      title: 'IP6 CIDR',
      description: ipv6CidrBlock,
    },
    {
      title: 'Network address usage metrics',
      description: firewallRuleAssociations,
      type: TextTypeEnum.NORMAL,
    },
    {
      title: 'Route 53 Resolver DNS Firewall rule groups',
      description: firewallRoute53Associations,
      type: TextTypeEnum.NORMAL,
    },
    {
      title: 'Owner ID',
      description: ownerId,
      type: TextTypeEnum.COPY,
    },
  ];

  return <DetailTab title="Details" data={detailsData} isApiLoading={isLoading} />;
}

export default Details;
