import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { DescribeWebAclStepPropsType } from '../components/types';
import StepContainer from '../components/StepContainer';
import SectionContainer from '../components/SectionContainer';
import SectionTitle from '../components/SectionTitle';
import { BODY_SIZE_LIMIT_RADIO_LIST, RESOURCE_TYPE_RADIO_LIST } from '../components/configs';
import RadioButtonGroup from '../components/RadioButtonGroup';
import Input from '../components/Input';
import InputGroup from '../components/InputGroup';
import { ButtonTypeEnum } from '../components/Button';
import DetailTable from 'pages/v2/Organ/Management/EC2/components/DetailTable';
import { ASSOCIATED_AWS_RESOURCES_COLUMN_LIST } from '../configs';
import { mapResourceType } from 'utils/Common';
import Dropdown from 'components/Dropdown';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { DropdownListDataType } from 'components/Dropdown/types';
import { regionCodeFormatter } from 'utils/Formatter';
import { RowType } from '@Types/v2/Table';
import SelectResourcesModal from '../../SelectResourcesModal';
import Icon from 'components/v2/atoms/Icon';
import AddResourcesIcon from 'assets/svgs/v3/ico_add_resources.svg';
import { CLOUDFRONT_OPTION, WafScopeEnum } from '../../../Commons/Constant';
import { nameRegexUtils } from 'utils/Regex';

const DescribeWebAclStep = (props: DescribeWebAclStepPropsType) => {
  const { title, onAddResourceButtonClicked, cloudId, setCreationData, creationData, isSubmit } = props;

  // API
  const [getEnumTypeCode] = lazyGetEnumTypeCode();

  // State
  const [resourceType, setResourceType] = useState(
    creationData?.region?.value === 'ap-northeast-2' ? RESOURCE_TYPE_RADIO_LIST[1].value : CLOUDFRONT_OPTION.value,
  );
  const [name, setName] = useState(creationData?.name ?? '');
  const [description, setDescription] = useState(creationData?.description ?? '');
  const [cloudWatchMetricName, setCloudWatchMetricName] = useState(creationData?.cloudWatchMetricName ?? '');
  const [regionList, setRegionList] = useState<Array<{ name: string; value: string }>>([]);
  const [region, setRegion] = useState<DropdownListDataType | undefined>(creationData?.region?.value ?? undefined);
  const [checkedAwsResource, setCheckedAwsResource] = useState<string>('');
  const [addResourceModalVisible, setAddResourceModalVisible] = useState(false);
  const [awsResourceRows, setAwsResourceRows] = useState<Array<RowType>>([]);
  const [resources, setResources] = useState<Array<RowType>>(creationData?.resources || []);
  const [isCloudWatchMetricNameModified, setCloudWatchMetricNameModified] = useState(false);
  const [bodySizeLimit, setBodySizeLimit] = useState(
    creationData?.bodySizeLimit || BODY_SIZE_LIMIT_RADIO_LIST[0].value,
  );

  // Validate
  const [nameValid, setNameValid] = useState<string>('');
  const [cloudWatchMetricNameValid, setCloudWatchMetricNameValid] = useState<string>('');

  useEffect(() => {
    setCreationData({
      name,
      description,
      region,
      cloudWatchMetricName,
      resources,
      bodySizeLimit,
      resourceType,
    });
  }, [name, description, region, cloudWatchMetricName, resources, bodySizeLimit]);

  const getRegionList = useCallback(() => {
    getEnumTypeCode({ variables: { text: 'AwsRegionCode' } }).then(res => {
      const codes =
        res?.data?.getEnumTypeCode?.data?.map(code => ({
          name: regionCodeFormatter(code?.value) || code?.name,
          value: code?.value,
        })) ?? [];

      setRegionList(codes);
      setRegion({
        name: creationData?.region?.name || CLOUDFRONT_OPTION.name,
        value: creationData?.region?.value || CLOUDFRONT_OPTION.value,
      });
    });
  }, [getEnumTypeCode]);

  useEffect(() => {
    getRegionList();
  }, [getRegionList]);

  const handleValidate = () => {
    if (!nameRegexUtils.test(name)) {
      setNameValid('Name is a required field');
    } else {
      setNameValid('');
    }

    if (!nameRegexUtils.test(cloudWatchMetricName)) {
      setCloudWatchMetricNameValid('CloudWatch metric name is a required field');
    } else {
      setCloudWatchMetricNameValid('');
    }
  };

  useEffect(() => {
    if (!isSubmit) return;

    handleValidate();
  }, [isSubmit]);

  const awsResources = useMemo(() => {
    if (resourceType === 'CloudFront') {
      return resources?.map(resource => {
        const { id, domainName } = resource ?? {};

        return {
          id,
          name: `${id} - ${domainName}`,
          resourceType: 'CloudFront Distribution',
          region: 'Global (CloudFront)',
        };
      });
    }

    return (
      resources?.map(resource => {
        const { id, loadBalancerName, type } = resource ?? {};

        return {
          id,
          name: loadBalancerName ?? '',
          resourceType: mapResourceType(type),
          region: region?.name ?? '',
        };
      }) ?? []
    );
  }, [resources, region]);

  useEffect(() => {
    setAwsResourceRows(awsResources);
  }, [awsResources]);

  const onRadioButtonGroupValueChanged = useCallback(
    (value: string) => {
      setResources([]);
      if (awsResourceRows) {
        setAwsResourceRows([]);
      }

      if (value === CLOUDFRONT_OPTION.value) {
        setRegion({ name: CLOUDFRONT_OPTION.name, value: CLOUDFRONT_OPTION.value });
        setResourceType(value as WafScopeEnum);

        return;
      }

      setRegion(regionList[13]); // TODO: 13 for Seoul, change to 0 when finish development
      setResourceType(value as WafScopeEnum);
    },
    [regionList],
  );

  const handleDeleteResource = useCallback(() => {
    const filteredResources = resources?.filter(row => checkedAwsResource !== row?.id?.toString());
    setResources(filteredResources);
  }, [resources, checkedAwsResource]);

  const associatedAwsResouresActionButtons = useMemo(() => {
    return [
      {
        id: 'remove',
        label: 'Remove',
        type: ButtonTypeEnum.GENERAL,
        onClick: handleDeleteResource,
        disabled: resources?.length <= 0 || !checkedAwsResource,
      },
      {
        id: 'add',
        label: 'Add AWS resources',
        type: ButtonTypeEnum.PRIMARY,
        onClick: () => setAddResourceModalVisible(true),
      },
    ];
  }, [onAddResourceButtonClicked, handleDeleteResource, resources, checkedAwsResource]);

  const handleChangeName = useCallback(
    (value: string) => {
      setName(value);

      if (!isCloudWatchMetricNameModified) {
        setCloudWatchMetricName(value);
      }
    },
    [isCloudWatchMetricNameModified],
  );

  const handleChangeMetricName = useCallback(
    (value: string) => {
      setCloudWatchMetricName(value);

      if (!isCloudWatchMetricNameModified) {
        setCloudWatchMetricNameModified(true);
      }
    },
    [isCloudWatchMetricNameModified],
  );

  const handleAddResource = useCallback(
    (resourceItem: any) => {
      const newResources = [...resources];

      if (resourceType === CLOUDFRONT_OPTION.value) {
        newResources.push(resourceItem);
        setResources(newResources);
        return;
      }

      newResources.push(resourceItem);
      setResources(newResources);
    },
    [resources],
  );

  return (
    <StepContainer title={title}>
      <SectionContainer title={'Web ACL details'}>
        <SectionTitle
          title={'Resource type'}
          description={
            'Choose the type of resource to associate with this web ACL. Changing this setting will reset the page.'
          }
        />
        <RadioButtonGroup
          data={RESOURCE_TYPE_RADIO_LIST}
          value={resourceType}
          onChangeValue={onRadioButtonGroupValueChanged}
        />
        <InputGroup>
          <Dropdown
            title={'Region'}
            isRequired
            data={regionList}
            id="region"
            handleClick={setRegion}
            value={region}
            placeholder="Choose region"
            disabled={resourceType === CLOUDFRONT_OPTION.value}
          />
          <Input
            title={'Name'}
            placeholder="Enter name"
            bottomDescription="The name must have 1-128 characters. Valid characters A-Z, a-z, 0-9, - (hyphen), and _ (underscore)."
            value={name}
            error={nameValid}
            onValueChanged={handleChangeName}
            isRequired
          />
          <Input
            title={'Description'}
            placeholder="Enter description"
            bottomDescription="The description can have 1-256 characters."
            value={description}
            onValueChanged={setDescription}
          />
          <Input
            title={'CloudWatch metric name'}
            placeholder="Enter CloudWatch metric name"
            bottomDescription="The name must have 1-128 characters. Valid characters A-Z, a-z, 0-9, - (hyphen), and _ (underscore)."
            value={cloudWatchMetricName}
            error={cloudWatchMetricNameValid}
            onValueChanged={handleChangeMetricName}
            isRequired
          />
        </InputGroup>
      </SectionContainer>
      <DetailTable
        columns={ASSOCIATED_AWS_RESOURCES_COLUMN_LIST}
        data={awsResourceRows}
        title={'Associated AWS resources'}
        caption={'-optional'}
        rightButtons={associatedAwsResouresActionButtons}
        isRadioTable={true}
        reportValue={checkedAwsResource}
        reportSelected={setCheckedAwsResource}
      />
      {resourceType === 'CloudFront' && (
        <SectionContainer
          title={'Web request body inspection'}
          caption="-optional"
          description="By default, rules that inspect the web request body are limited to the first 16 KB of content. You can increase this size for additional costs."
        >
          <SectionTitle
            title={'Body size limit'}
            description={'The AWS WAF default limit is 16 KB. Settings over 16 KB incur additional costs.'}
          />
          <RadioButtonGroup data={BODY_SIZE_LIMIT_RADIO_LIST} value={bodySizeLimit} onChangeValue={setBodySizeLimit} />
        </SectionContainer>
      )}
      <SelectResourcesModal
        cloudId={cloudId}
        region={region?.value?.toString() ?? ''}
        open={addResourceModalVisible}
        title={() => (
          <>
            <Icon width={32} height={32} src={AddResourcesIcon} />
            Add AWS resources
          </>
        )}
        onClose={() => setAddResourceModalVisible(false)}
        onAdd={resourceItem => {
          setAddResourceModalVisible(false);
          handleAddResource(resourceItem);
        }}
      />
    </StepContainer>
  );
};

export default DescribeWebAclStep;
