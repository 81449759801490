import { Routes, Route, Outlet } from 'react-router-dom';
import AuthLayoutv2 from 'layouts/v2/AuthLayout';
import LoginPage from 'pages/v2/Auth/Login';
import Signup from 'pages/v2/Auth/Signup';
import SignupVerify from 'pages/v2/Auth/SignupVerify';
import ResetPw from 'pages/v2/Auth/ResetPw';
import ResetPwVerify from 'pages/v2/Auth/ResetPwVerify';
import SendEmail from 'pages/v2/Auth/Signup/SendEmail';
import InviteProject from 'pages/v2/InviteProject';
import JoinOrgan from 'pages/v2/JoinOrgan';
import Project from 'pages/v2/Organ/Project';
import Favorite from 'pages/v2/Organ/Favorite';
import WeeklyReport from 'pages/v2/Organ/Report/WeeklyReport';
import MonthlyReport from 'pages/v2/Organ/Report/MonthlyReport';
import EventHistory from 'pages/v2/Organ/Report/EventHistory';
import SelectOrgan from 'pages/v2/SelectOrgan';
import ManageLayout from 'layouts/v2/ManageLayout';
import Event from 'pages/v2/Organ/Manage/Event'
import Analysis from 'pages/v2/Organ/Manage/Analysis';
import Users from 'pages/v2/Organ/Manage/Users';
import CommonInformation from 'pages/v2/Organ/Manage/CommonInformation';
import PaymentLayout from 'layouts/v2/PaymentLayout';
import Plan from 'pages/v2/Payment/Plan';
import CardManagement from 'pages/v2/Payment/CardManagement';
import Inquiry from 'pages/v2/Inquiry';
import Receipt from 'pages/v2/Payment/Receipt';
import Preview from 'pages/v2/Policy';
import Landing from 'pages/v2/Landing';
import PageNotFound from 'pages/v2/PageNotFound';
import EmptyPage from 'pages/v2/Organ/Project/components/Empty';
import VerifyUpdEmailToken from 'pages/v2/Auth/VerifyUpdEmailToken';
import VerifyInvitedMemberToken from 'pages/v2/Auth/VerifyInvitedMemberToken';
import Policy from 'pages/v2/Policy';
import MgdLayout from 'layouts/v3/MgdLayout';
import OrganLayoutv3 from 'layouts/v3/OrganLayoutv3';
import ManagementLayout from 'pages/v2/Organ/Management';
import Dashboard from 'pages/v4/Dashboard';
import ManageDashboard from 'pages/v2/Organ/Manage/ManageDashboard';
 
const App = () => {

  return (
    <Routes>
      <Route index element={<Landing />} />
      <Route path="terms" element={<Outlet />}>
        <Route index element={<Policy type="Service" />} />
        <Route path="secure" element={< Policy type="Privacy" />} />
      </Route>
      <Route path="auth" element={<AuthLayoutv2 />}>
        <Route index element={<LoginPage />} />
        <Route path="signup" element={<Signup />} />
        <Route path="signup-sendemail" element={<SendEmail />} />
        <Route path="userVerify" element={<SignupVerify />} />
        <Route path="reset-pw-sendemail" element={<ResetPw />} />
        <Route path="resetPw" element={<ResetPwVerify />} />
        <Route path="joinProject" element={ <VerifyInvitedMemberToken />} />
        <Route path="reqUpdateEmail" element={<VerifyUpdEmailToken />} />
      </Route>
      <Route path="invite-project" element={<InviteProject />} />
      <Route path="join-organ" element={<JoinOrgan />} />
      <Route path="organ/:organId" element={<OrganLayoutv3 />}>
        <Route index element={<Favorite />} />
        <Route path="select-organ" element={<SelectOrgan />} />
        <Route path="empty-project" element={<EmptyPage />} />
        <Route path="project/:id" element={<Project />} />
        <Route path="dashboard/:dashboardId" element={<Dashboard />} />
        <Route path="weekly-report" element={<WeeklyReport />} />
        <Route path="monthly-report" element={<MonthlyReport />} />
        <Route path="event-history" element={<EventHistory />} />
        <Route path="system" element={<ManageLayout />} >
          <Route path="dashboard" element={<ManageDashboard />}/>
          <Route path="event" element={ <Event />} />
          <Route path="analysis" element={<Analysis />} />
          <Route path="user" element={<Users />} />
          <Route path="common-information" element={<CommonInformation />} /> 
        </Route>
        {/* <Route path="network" element={<Network />} /> */}
        <Route path="manage">
          <Route path="network" element={<ManagementLayout />} />
          <Route path="waf" element={<ManagementLayout />} />
          <Route path="firewall" element={<ManagementLayout />} />
          <Route path="key-secret" element={<ManagementLayout />} />
          <Route path="instance" element={<ManagementLayout />} />
          <Route path="iam" element={<ManagementLayout />} />
        </Route>
      </Route>
      <Route path="payment" element={<PaymentLayout />}>
        <Route path="plan" element={<Plan />} />
        <Route path="card-management" element={<CardManagement />} />
        <Route path="receipt" element={<Receipt />} />
      </Route>
      <Route path="inquiry" element={<Inquiry />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default App;
