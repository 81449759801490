import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { NatGWListPropsType } from '../types';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { NAT_GW_FILTER_DROPDOWN } from './configs';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import InputAtom from 'components/v2/atoms/InputAtom';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { orderAlphabetical } from '../../../Utils/Sorting';
import { handleLinkClicked, variableCombineNextToken } from '../../../Utils';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdTabLayout/types';
import { FilterPropsType } from '../../../EC2/components/Image/types';
import { handleFormatText } from 'utils/Common';
import { IAwsAddresssVariables } from 'graphql/queries/getAwsAllAddress';
import lazyGetAllAwsNatGateways from 'graphql/queries/getAwsAllNatGateways';
import { AwsNatGatewayType } from 'graphql/types/AwsNatGateway';
import { useNavigate } from 'react-router-dom';
import { ManagementTypeEnum } from '../../..';
import { NETWORK_TAB_LIST } from '../../configs';
import { elasticIpFilterDropdown, subnetFilterDropdown, vpcFilterDropdown } from '../../Constants';

const NatGWList = (props: NatGWListPropsType) => {
  const { cloudId, region, tabInformation, onNatGWSelected, onNatGWIdClicked } = props;

  const navigate = useNavigate();

  // API
  const [getAllAwsNatGateways, { loading: awsNatGWLoading }] = lazyGetAllAwsNatGateways();

  // State
  const [mainTblTotalRows, setMainTblTotalRows] = useState<RowType[]>([]);
  const [mainNextToken, setMainNextToken] = useState<string>('');
  const [natGWFilterDropdownValue, setNatGWFilterDropdownValue] = useState<DropdownListDataType>(
    NAT_GW_FILTER_DROPDOWN[0],
  );
  const [natGWFilterInputValue, setNatGWFilterInputValue] = useState<FilterPropsType>({
    name: natGWFilterDropdownValue.value as string,
    value: '',
  });
  const [natGWFilter, setNatGWFilter] = useState<FilterPropsType>();
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });

  const mainTblColumns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Name',
        field: 'name',
        sort: true,
        renderCell: (row: RowType) => <>{handleFormatText(row?.name || '-')}</>,
      },
      {
        label: 'Nat gateway ID',
        field: 'id',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <div
                className="link"
                onClick={e => {
                  e.stopPropagation();
                  onNatGWIdClicked();
                  onNatGWSelected(row as AwsNatGatewayType);
                }}
              >
                {handleFormatText(row?.id)}
              </div>
            }
          </>
        ),
      },
      { label: 'Connectivity type', field: 'connectivityType', sort: true },
      { label: 'State', field: 'state', sort: true },
      { label: 'State message', field: 'stateMessage', sort: true },
      {
        label: 'Primary public IPv4 address',
        field: 'primaryPublicIpv4Address',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <div
                className="link"
                onClick={e =>
                  handleLinkClicked({
                    navigate,
                    link: '/organ/1/manage/network',
                    type: ManagementTypeEnum.NETWORK,
                    tabId: NETWORK_TAB_LIST[5].id,
                    key: elasticIpFilterDropdown[0].value.toString(),
                    value: row.primaryPublicIpv4Address,
                  })
                }
              >
                {handleFormatText(row?.primaryPublicIpv4Address)}
              </div>
            }
          </>
        ),
      },
      {
        label: 'Primary private IPv4 address',
        field: 'primaryPrivateIpv4Address',
        sort: true,
      },
      {
        label: 'Primary network interface ID',
        field: 'primaryNetworkInterfaceId',
        sort: true,
      },
      {
        label: 'VPC',
        field: 'vpcId',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <div
                className="link"
                onClick={e =>
                  handleLinkClicked({
                    navigate,
                    link: '/organ/1/manage/network',
                    type: ManagementTypeEnum.NETWORK,
                    tabId: NETWORK_TAB_LIST[0].id,
                    key: vpcFilterDropdown[0].value.toString(),
                    value: row.vpcId,
                  })
                }
              >
                {handleFormatText(row?.vpcId)}
              </div>
            }
          </>
        ),
      },
      {
        label: 'Subnet',
        field: 'subnetId',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <div
                className="link"
                onClick={e => {
                  handleLinkClicked({
                    navigate,
                    link: '/organ/1/manage/network',
                    type: ManagementTypeEnum.NETWORK,
                    tabId: NETWORK_TAB_LIST[2].id,
                    key: subnetFilterDropdown[0].value.toString(),
                    value: row.subnetId,
                  });
                }}
              >
                {handleFormatText(row?.subnetId)}
              </div>
            }
          </>
        ),
      },
      { label: 'Created', field: 'createTime', sort: true },
      { label: 'Deleted', field: '', sort: true },
    ];
  }, []);

  const handleSearchFunction = useCallback(() => {
    if (natGWFilterInputValue.value !== '') {
      setNatGWFilter({ name: natGWFilterInputValue.name, value: natGWFilterInputValue.value });
    } else {
      setNatGWFilter(undefined);
    }
  }, [natGWFilterInputValue]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setMainTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      fetchAwsAllNatGWs(mainNextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(mainTblTotalRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotalRows, mainTblTotal, mainTablePagination, mainNextToken, natGWFilterInputValue]);

  const awsNatGWRequestVariables = useMemo((): IAwsAddresssVariables => {
    if (natGWFilter) {
      return {
        cloudId: cloudId,
        region: region.value as string,
        request: {
          filters: {
            name: natGWFilter?.name || '',
            values: [natGWFilter?.value.toLocaleLowerCase() || ''],
          },
        },
      };
    }
    return {
      cloudId: cloudId,
      region: region.value as string,
      request: {},
    };
  }, [cloudId, region, mainTablePagination, natGWFilter]);

  const fetchAwsAllNatGWs = useCallback(
    (nextToken?: string, isInitial?: boolean) => {
      const combinedVariable = variableCombineNextToken(awsNatGWRequestVariables, nextToken);
      getAllAwsNatGateways({ variables: combinedVariable }).then(({ data: awsNatGWData }) => {
        const natGWData: RowType[] = [];
        const getAwsAllNatGateways = awsNatGWData?.getAwsAllNatGateways;
        if (getAwsAllNatGateways) {
          getAwsAllNatGateways?.data?.forEach(item => {
            natGWData.push({
              ...item,
              id: item.natGatewayId,
              name: item?.tags?.find(t => t?.key == 'Name')?.value,
              stateMessage: '-',
              primaryPublicIpv4Address: item.natGatewayAddresses[0].isPrimary
                ? item.natGatewayAddresses[0].publicIp
                : '-',
              primaryPrivateIpv4Address: item.natGatewayAddresses[0].privateIp,
              primaryNetworkInterfaceId: item.natGatewayAddresses[0].networkInterfaceId,
            });
          });

          let allAwsNatGW: RowType[] = [];
          if (isInitial) {
            allAwsNatGW = natGWData;
          } else {
            allAwsNatGW = [...mainTblTotalRows, ...natGWData];
          }
          setMainTblTotalRows(allAwsNatGW);
          if (allAwsNatGW.length > mainTablePagination.limit) {
            setMainNextToken(getAwsAllNatGateways?.nextToken as string);
          } else {
            setMainNextToken('');
          }
          setMainTblTotal({
            totalPage: Math.ceil(allAwsNatGW.length / mainTablePagination.itemPerPage),
            totalElement: allAwsNatGW.length,
          });
        }
      });
    },
    [awsNatGWRequestVariables, mainTblTotalRows, mainTblTotal, mainTablePagination],
  );

  const handleMainRowSelected = useCallback(
    (rowId: string) => {
      const rowSelected = mainTblTotalRows.find(row => row.id === rowId);

      if (!rowSelected) return;

      onNatGWSelected(rowSelected as AwsNatGatewayType);
    },
    [mainTblTotalRows],
  );

  // For initial data
  useEffect(() => {
    fetchAwsAllNatGWs('', true);
  }, [awsNatGWRequestVariables]);

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>{tabInformation.title}</p>
        </div>
        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={NAT_GW_FILTER_DROPDOWN}
            value={natGWFilterDropdownValue}
            handleClick={val => {
              setNatGWFilterDropdownValue(val);
              setNatGWFilterInputValue(prev => ({ ...prev, name: val.value as string }));
            }}
          />
          <InputAtom
            type={'text'}
            value={natGWFilterInputValue.value}
            defaultValue={''}
            onChangeValue={str => setNatGWFilterInputValue(prev => ({ name: prev?.name, value: str }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {}}
            onKeyDown={event => {}}
          />

          <button className="action-btn" onClick={handleSearchFunction}>
            Search
          </button>
        </div>
      </div>
      <div className="data-grid-wrap">
        <Table
          rows={mainRowsCurrentPage}
          columns={mainTblColumns}
          reportCheckedList={() => {}}
          reportSelected={id => handleMainRowSelected(id)}
          sortOption={{
            target: mainTablePagination.target,
            direction: mainTablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {
              updateTablePagination('target', target);
              updateTablePagination('direction', dir);
            },
          }}
          isLoading={awsNatGWLoading}
          horizontalScrollable={true}
        />
        {mainRowsCurrentPage && mainRowsCurrentPage.length > 0 && awsNatGWLoading === false && (
          <div className="pagination-wrapper flex a-center">
            <p className="flex a-center">
              Total <span>{mainTblTotal.totalElement}</span>
            </p>
            <TableManagePagination
              ableFetchMore={!!mainNextToken}
              currentPage={mainTablePagination.currentPage}
              updateCurrentPage={page =>
                setMainTablePagination(prev => ({
                  ...prev,
                  ['currentPage']: page,
                }))
              }
              totalPage={mainTblTotal.totalPage}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default NatGWList;
