import _ from 'lodash';

const caseCondition = (origKey: any, keyCase: any) => {
  let newKey = null;
  switch (keyCase) {
    case 'pascal':
      newKey = _.startCase(_.camelCase(origKey)).replace(/ /g, '');
      break;
    case 'camel':
      newKey = _.camelCase(origKey);
      break;
    case 'kebab':
      newKey = _.kebabCase(origKey);
      break;
    case 'snake':
      newKey = _.snakeCase(origKey);
      break;
    case 'upper':
      newKey = _.toUpper(origKey);
      break;
    case 'constant':
      newKey = _.upperCase(origKey).replace(/ /g, '_');
      break;
    case 'dot':
      newKey = _.lowerCase(origKey).replace(/ /g, '.');
      break;
    case 'path':
      newKey = _.lowerCase(origKey).replace(/ /g, '/');
      break;
    case 'lower':
      newKey = _.toLower(origKey);
      break;
    case 'sentence':
      newKey = _.upperFirst(_.lowerCase(origKey));
      break;
    case 'title':
      newKey = _.startCase(_.camelCase(origKey));
      break;
  }
  return newKey;
};

function keyConvertor(obj: any, keyCase: any) {
  if (typeof obj !== 'object' && obj !== null) {
    return obj;
  }
  let objectToSend: any, origKey, newKey: any, value;
  if (obj instanceof Array) {
    return obj.map(function (value) {
      if (typeof value === 'object') {
        value = keyConvertor(value, keyCase);
      }
      return value;
    });
  } else {
    objectToSend = {};
    for (origKey in obj) {
      if (obj.hasOwnProperty(origKey)) {
        newKey = caseCondition(origKey, keyCase);
        value = obj[origKey];
        if (value instanceof Array || (value !== null && value !== undefined && value.constructor === Object)) {
          value = keyConvertor(value, keyCase);
        }
        objectToSend[newKey] = value;
      }
    }
  }
  return objectToSend;
}

function allConvertor(obj: any, keyCase: any) {
  if (typeof obj !== 'object' && obj !== null) {
    return obj;
  }
  let objectToSend: any, origKey, newKey: any, value;
  if (obj instanceof Array) {
    return obj.map(function (value) {
      if (typeof value === 'object') {
        value = allConvertor(value, keyCase);
      }
      if (typeof value === 'string') {
        value = caseCondition(value, keyCase);
      }
      return value;
    });
  } else {
    objectToSend = {};
    for (origKey in obj) {
      if (obj.hasOwnProperty(origKey)) {
        newKey = caseCondition(origKey, keyCase);
        value = obj[origKey];
        if (value instanceof Array || (value !== null && value !== undefined && value.constructor === Object)) {
          value = allConvertor(value, keyCase);
        } else {
          if (typeof value === 'string') value = caseCondition(value, keyCase);
        }
        objectToSend[newKey] = value;
      }
    }
  }
  return objectToSend;
}

function valueConvertor(obj: any, keyCase: any) {
  if (typeof obj !== 'object' && obj !== null) {
    if (typeof obj !== 'string') return caseCondition(obj, undefined);
    else return obj;
  }
  let objectToSend: any, origKey, value;
  if (obj instanceof Array) {
    return obj.map(function (value) {
      if (typeof value === 'object') {
        value = valueConvertor(value, keyCase);
      }
      if (typeof value === 'string') {
        value = caseCondition(value, keyCase);
      }
      return value;
    });
  } else {
    objectToSend = {};
    for (origKey in obj) {
      if (obj.hasOwnProperty(origKey)) {
        value = obj[origKey];
        if (value instanceof Array || (value !== null && value !== undefined && value.constructor === Object)) {
          value = valueConvertor(value, keyCase);
        } else {
          if (typeof value === 'string') value = caseCondition(value, keyCase);
        }
        objectToSend[origKey] = value;
      }
    }
  }
  return objectToSend;
}

//-----------------------------------------------
const pascalCaseKeys = (obj: any) => {
  return keyConvertor(obj, 'pascal');
};
const camelCaseKeys = (obj: any) => {
  return keyConvertor(obj, 'camel');
};
const snakeCaseKeys = (obj: any) => {
  return keyConvertor(obj, 'snake');
};
const kebabCaseKeys = (obj: any) => {
  return keyConvertor(obj, 'kebab');
};
const upperCaseKeys = (obj: any) => {
  return keyConvertor(obj, 'upper');
};
const lowerCaseKeys = (obj: any) => {
  return keyConvertor(obj, 'lower');
};
const constantCaseKeys = (obj: any) => {
  return keyConvertor(obj, 'constant');
};
const dotCaseKeys = (obj: any) => {
  return keyConvertor(obj, 'dot');
};
const pathCaseKeys = (obj: any) => {
  return keyConvertor(obj, 'path');
};
const sentenceCaseKeys = (obj: any) => {
  return keyConvertor(obj, 'sentence');
};
const titleCaseKeys = (obj: any) => {
  return keyConvertor(obj, 'title');
};
//-------------------------------------------------
const pascalCaseValues = (obj: any) => {
  return valueConvertor(obj, 'pascal');
};
const camelCaseValues = (obj: any) => {
  return valueConvertor(obj, 'camel');
};
const snakeCaseValues = (obj: any) => {
  return valueConvertor(obj, 'snake');
};
const kebabCaseValues = (obj: any) => {
  return valueConvertor(obj, 'kebab');
};
const upperCaseValues = (obj: any) => {
  return valueConvertor(obj, 'upper');
};
const lowerCaseValues = (obj: any) => {
  return valueConvertor(obj, 'lower');
};
const constantCaseValues = (obj: any) => {
  return valueConvertor(obj, 'constant');
};
const dotCaseValues = (obj: any) => {
  return valueConvertor(obj, 'dot');
};
const pathCaseValues = (obj: any) => {
  return valueConvertor(obj, 'path');
};
const sentenceCaseValues = (obj: any) => {
  return valueConvertor(obj, 'sentence');
};
const titleCaseValues = (obj: any) => {
  return valueConvertor(obj, 'title');
};
//-------------------------------------------------
const pascalCaseAll = (obj: any) => {
  return allConvertor(obj, 'pascal');
};
const camelCaseAll = (obj: any) => {
  return allConvertor(obj, 'camel');
};
const snakeCaseAll = (obj: any) => {
  return allConvertor(obj, 'snake');
};
const kebabCaseAll = (obj: any) => {
  return allConvertor(obj, 'kebab');
};
const upperCaseAll = (obj: any) => {
  return allConvertor(obj, 'upper');
};
const lowerCaseAll = (obj: any) => {
  return allConvertor(obj, 'lower');
};
const constantCaseAll = (obj: any) => {
  return allConvertor(obj, 'constant');
};
const dotCaseAll = (obj: any) => {
  return allConvertor(obj, 'dot');
};
const pathCaseAll = (obj: any) => {
  return allConvertor(obj, 'path');
};
const sentenceCaseAll = (obj: any) => {
  return allConvertor(obj, 'sentence');
};
const titleCaseAll = (obj: any) => {
  return allConvertor(obj, 'title');
};
//----------------------------------------------

export {
  pascalCaseKeys,
  camelCaseKeys,
  snakeCaseKeys,
  kebabCaseKeys,
  upperCaseKeys,
  lowerCaseKeys,
  constantCaseKeys,
  dotCaseKeys,
  pathCaseKeys,
  sentenceCaseKeys,
  titleCaseKeys,
  //-------------------
  pascalCaseAll,
  camelCaseAll,
  snakeCaseAll,
  kebabCaseAll,
  upperCaseAll,
  lowerCaseAll,
  constantCaseAll,
  dotCaseAll,
  pathCaseAll,
  sentenceCaseAll,
  titleCaseAll,
  //---------------------
  pascalCaseValues,
  camelCaseValues,
  snakeCaseValues,
  kebabCaseValues,
  upperCaseValues,
  lowerCaseValues,
  constantCaseValues,
  dotCaseValues,
  pathCaseValues,
  sentenceCaseValues,
  titleCaseValues,
};
