import './style.scss';
import ConsoleSignIn from './ConsoleSignIn';
import MFA from './MFA';
import AccessKeys from './AccessKeys';
import SSHPublicKeys from './SSHPublicKeys';
import HTTPS from './HTTPS';
import SigningCertificates from './SigningCertificates';

const SecurityCredentials = (props: any) => {
  const { data = {}, cloudId, region } = props;

  return (
    <>
      <ConsoleSignIn cloudId={cloudId} region={region} data={data} />

      <MFA cloudId={cloudId} region={region} data={data} />

      <AccessKeys cloudId={cloudId} region={region} data={data} />

      <SSHPublicKeys cloudId={cloudId} region={region} data={data} />

      <HTTPS
        cloudId={cloudId}
        region={region}
        data={data}
        title="HTTPs Git credentials for AWS CodeCommit"
        description="Generate a user name and password you can use to authenticate HTTPS connections to AWS CodeCommit repositories. You can have a maximum of 2 sets of credentials (active or inactive)at a time."
        serviceName="codecommit.amazonaws.com"
      />

      <HTTPS
        cloudId={cloudId}
        region={region}
        data={data}
        title="Credentails for Amazon Keyspaces (for Apache Cassandra)"
        description="Generate a user name and password you can use to authenticate to Amazon Keyspaces. You can have a maximum of two sets of credentials (active or inactive)"
        serviceName="cassandra.amazonaws.com"
      />

      <SigningCertificates cloudId={cloudId} region={region} data={data} />
    </>
  );
};

export default SecurityCredentials;
