import './index.scss';
import { useEffect, useState } from 'react';
import PageDetailTitle from '../../PageDetailTitle';
import { userTab } from '../Common/Constant';
import Summary from '../Common/Components/Summary';
import Groups from '../Common/Components/Groups';
import Permissions from '../Common/Components/Permissions';
import Tags from '../Common/Components/Tags';
import SecurityCredentials from '../Common/Components/SecurityCredentials';
import AccessAdvisor from '../Common/Components/AccessAdvisor';

const UserDetail = (props: any) => {
  const { data, pageBackClick, cloudId, region } = props;

  // @ts-ignore
  const [tabSelected, setTabSelected] = useState(userTab[0]);

  useEffect(() => {
    setTabSelected(userTab[0]);
  }, [data?.id]);

  const renderTab = () => {
    switch (tabSelected.id) {
      case userTab[0].id:
        return <Permissions cloudId={cloudId} region={region} data={data} pageBackClick={() => {}} />;

      case userTab[1].id:
        return <Groups cloudId={cloudId} region={region} data={data} pageBackClick={() => {}} />;

      case userTab[2].id:
        return <Tags cloudId={cloudId} region={region} data={data} pageBackClick={() => {}} />;

      case userTab[3].id:
        return <SecurityCredentials cloudId={cloudId} region={region} data={data} pageBackClick={() => {}} />;

      case userTab[4].id:
        return <AccessAdvisor cloudId={cloudId} region={region} data={data} pageBackClick={() => {}} />;

      default:
        return null;
    }
  };

  return (
    <div className="page-detail">
      <PageDetailTitle title={`User: ${data?.userName}`} pageBackClick={pageBackClick} />

      <Summary cloudId={cloudId} region={region} data={data} />

      <div className="page-detail-tab">
        <div className="detail-tab flex a-center">
          {userTab.map(tab => {
            return (
              <button
                className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
                key={tab.id}
                data-tab={tab.id}
                onClick={e => setTabSelected(tab)}
              >
                <p>{tab.name}</p>
              </button>
            );
          })}
        </div>

        <div className="content-tab">{renderTab()}</div>
      </div>
    </div>
  );
};

export default UserDetail;
