import DetailTable from '../../../DetailTable';
import { ColumnType } from '@Types/v2/Table';
import lazyGetAllAwsInternetGateways from 'graphql/queries/getAwsAllInternetGateways';
import { useEffect, useState } from 'react';

function EdgeAssociations({ cloudId, region, data }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  const { associations = [] } = data;

  const [getAwsInternetGateways] = lazyGetAllAwsInternetGateways();

  const handleGetData = async () => {
    try {
      setIsLoading(true);
      let arr: any = [];
      const gatewayIds = associations.map((item: any) => {
        return item.gatewayId ? item.gatewayId : '';
      });

      const internetGWVariable = {
        cloudId,
        region,
        request: {
          maxResults: 50,
          filters: {
            name: 'internet-gateway-id',
            values: gatewayIds,
          },
          pageInfo: { page: 0, size: 100, orders: [] },
        },
      };

      const { data: internetGWData } = await getAwsInternetGateways({ variables: internetGWVariable });
      setIsLoading(false);

      const getAwsAllInternetGateways = internetGWData?.getAwsAllInternetGateways?.data;

      if (!getAwsAllInternetGateways?.length) {
        setDataTable([]);
        return;
      }

      getAwsAllInternetGateways.forEach(item => {
        const subnetName = item?.tags.find((r: { key: string }) => r.key === 'Name')?.value;
        arr.push({
          subnetId: subnetName ? subnetName : '-',
          state: item?.attachments[0]?.state,
          vpcId: item?.attachments[0]?.vpcId,
          ownerId: item.ownerId,
        });
      });
      setDataTable(arr);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const columns: ColumnType[] = [
    { label: 'ID', field: 'subnetId', sort: true },
    { label: 'State', field: 'state', sort: true },
    { label: 'VPC', field: 'vpcId', sort: true },
    { label: 'Owner', field: 'ownerId', sort: true },
  ];

  return <DetailTable title="Edge associations" data={dataTable} columns={columns} isLoading={isLoading} />;
}

export default EdgeAssociations;
