import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import LabelInput from 'components/v2/LabelInput';
import Labeled from 'components/v2/atoms/Labeled';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import PhoneInput from 'components/v2/PhoneInput';
import { numberRegex, onlyNumRegex } from 'utils/Regex';
import { checkEmail } from 'utils/v2/ValidCheck';
import { useMutation } from 'react-query';
import { IAddRuleCloudReq } from 'apis/v2/Manage/schema';
import apis from 'apis/v2';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import addRuleCloud from 'graphql/mutations/addRuleCloud';

interface ICreateCloudInformationModalProps extends IBaseModalProps {
  cloudKindList: {
    name: string;
    value: string;
  }[];
  title: () => JSX.Element;
  onCreateSuccess: () => void; 
}

const CreateCloundInformationModal = ({
  cloudKindList,
  title,
  onCreateSuccess,
  ...baseModalProps
}:ICreateCloudInformationModalProps) => {

  const [payload, setPayload] = useState({
    cloudKind: '',
    name: '',
    maskCode: '',
    contactName: '',
    contactEmail: '',
    countryCode: '+82',
    phoneNumber: '',
  });

  const [createRuleCloud] = addRuleCloud();

  const enableButton = useMemo(() => {
    if (
      payload.cloudKind === '' || 
      payload.name === '' || 
      (payload.contactEmail !== '' && checkEmail(payload.contactEmail)) ||
      payload.maskCode.length < 8
      ) {
        return true;
    } else false;
  }, [payload])


  useEffect(() => {
    if (baseModalProps.open) {
      setPayload({
        cloudKind: '',
        name: '',
        maskCode: '',
        contactName: '',
        contactEmail: '',
        countryCode: '+82',
        phoneNumber: '',
      });
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal 
      {...baseModalProps}
      title={title}
    >
      <div className="create-cloudInformation-modal">
        <div className="contents-wrap flex col">
          <LabelInput 
            title="Name"
            required
            placeholder={`Input cloud provider's name`}
            value={payload.name}
            onChangeValue={(str) => setPayload(prev => ({
              ...prev,
              name: str
            })) }
          /> 
          <Labeled
            title="Kind"
            required
          >
            <DropdownAtom 
              placeholder='Select Cloud kind'
              id="cloud-kind"
              data={cloudKindList} 
              value={{
                name: cloudKindList.find(val => val.value === payload.cloudKind)?.name 
                ? cloudKindList.find(val => val.value === payload.cloudKind)?.name
                : ''
                 ,
                value: payload.cloudKind
              }} 
              handleClick={(val) => {
                setPayload(prev => ({
                  ...prev,
                  cloudKind: String(val.value)
                }))
              }} 
            />
          </Labeled>
          <LabelInput 
            title="Unique delimiter"
            required
            placeholder='Input delimiter (mask code 8 digits)'
            value={payload.maskCode}
            onChangeValue={(str) => {
              if (str.length > 8) return;
              setPayload(prev => ({
                ...prev,
                maskCode: str.replace(numberRegex, '')
              })) 
            }   
          }
          /> 
          <LabelInput 
            title="Manager name"
            placeholder={`Input manager's name`}
            value={payload.contactName}
            onChangeValue={(str) => setPayload(prev => ({
              ...prev,
              contactName: str
            })) }
          /> 
          <LabelInput 
            title="Manager email"
            placeholder={`Input manager's email`}
            value={payload.contactEmail}
            onChangeValue={(str) => setPayload(prev => ({
              ...prev,
              contactEmail: str
            })) }
          /> 
          <PhoneInput 
            title="Manager phone (optional)"
            defaultValue={{
              countryCode: payload.countryCode,
              phone: payload.phoneNumber
            }}
            onChangeCountryCode={(value:string) => setPayload(prev => ({
              ...prev,
              countryCode: value
            }))}
            onChangePhone={(value: string) => setPayload(prev => ({
              ...prev,
              phoneNumber: value.replace(onlyNumRegex, '')
            }))}
          />
        </div>
        <div className="btns">
          <button 
            className="big-main-btn flex j-center a-center"  
            disabled={enableButton}
            onClick={() => {
              const createdRuleCloud = {
                reqData : {
                  cloudKind: payload.cloudKind,
                  name: payload.name,
                  maskCode: payload.maskCode,
                  contactName: payload.contactName,
                  contactEmail: payload.contactEmail,
                  contactMobile: payload.phoneNumber
                }
              }
  
              createRuleCloud({variables: createdRuleCloud}).then(({ data }) => {
                if (data) {
                  if (data.addRuleCloud.result === ErrorCode.SUCCESS) {
                    useToast(ErrorCode.SUCCESS, 'Rule cloud is created successfully.');
                    onCreateSuccess();
                  } else {
                    console.log(data.addRuleCloud.result);
                    useToast(ErrorCode.UNKNOWN, 'Create rule cloud failed.');
                  }
                } else {
                  useToast(ErrorCode.UNKNOWN, 'Create rule cloud failed.');
                }
              });
            }}
          >
            Create
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default CreateCloundInformationModal;
