import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { AwsTagSpecification } from 'graphql/types/AwsDescribeSpotInstanceFleetRequestsCombinedResponse';

export interface ICreateNetworkACLVariables {
  cloudId: number;
  region: string;
  request: {
    dryRun?: boolean;
    vpcId: string;
    tagSpecifications: [AwsTagSpecification]
    clientToken?: string;
  }
}

export interface ICreateNetworkACLResponseData {
  createAwsNetwoklAcl: IGqlResponseData<undefined>;
}

const createNetworkACLMutation = () => useMutation<ICreateNetworkACLResponseData, ICreateNetworkACLVariables>(query);
export default createNetworkACLMutation;