import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListPolicyVersionsResponse } from 'graphql/types/AwsListPolicyVersionsResponse';

export interface IAwsListPolicyVersionsVariables {
  cloudId: number;
  region: string;
  request: {
    maxItems?: number;
    policyArn: string;
    nextToken?: string;
  };
}

export interface IAwsListPolicyVersionsResponseData {
  getAwsListPolicyVersions: IGqlResponseNewData<AwsListPolicyVersionsResponse>;
}

const lazyGetAwsListPolicyVersions = () =>
  useLazyQuery<IAwsListPolicyVersionsResponseData, IAwsListPolicyVersionsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListPolicyVersions;
