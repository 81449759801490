import { useEffect, useMemo, useState, useCallback } from 'react';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import { useToast } from 'hooks/v2/useToast';
import lazyGetListResourcesForWebACL from 'graphql/queries/getListResourcesForWebACL';
import { AwsArnData, CLOUDFRONT_OPTION, CLOUDFRONT_REGION, WafScopeEnum } from '../../../Commons/Constant';
import SelectResourcesModal from '../../SelectResourcesModal';
import Icon from 'components/v2/atoms/Icon';
import AddResourcesIcon from '../../../../../../../../assets/svgs/v3/ico_add_resources.svg';
import lazyGetAwsListDistributionsByWebACLId from 'graphql/queries/getAwsListDistributionsByWebACLId';
import { regionCodeFormatter } from 'utils/Formatter';
import lazyAssociateAwsWebACL, { IAwsAssociateWebACLVariables } from 'graphql/queries/getAwsAssociateWebACL';
import { ErrorCode } from '@Types/error';
import lazyDisassociateAwsWebACL, { IAwsDisassociateWebACLVariables } from 'graphql/queries/getAwsDisassociateWebACL';

interface IAssociatedResourcesProps {
  cloudId: number;
  region: string;
  webACLArn: string;
}

const AssociatedResources = ({ cloudId, region, webACLArn }: IAssociatedResourcesProps) => {
  const [totalResources, setTotalResources] = useState({
    totalPage: 0,
    totalElement: 0,
  });
  const [resourceRows, setResourceRows] = useState<RowType[]>([]);
  const [resourcesTablePagination, setResourcesTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');
  const [modalIsOpen, setModalIsOpen] = useState({
    addAwsResources: false,
  });
  const [resourceArnData, setResourceArnData] = useState<string[]>([]);

  // API
  const [getListResourcesForWebACL, { loading: listResourcesForWebACLLoading }] = lazyGetListResourcesForWebACL();
  const [getAwsListDistributionsByWebACLId, { loading: awsListDistributionByWebACLIdLoading }] =
    lazyGetAwsListDistributionsByWebACLId();
  const [associateAwsWebACL, { loading: associateAwsWebACLLoading }] = lazyAssociateAwsWebACL();
  const [disassociateAwsWebACL, { loading: disassociateAwsWebACLLoading }] = lazyDisassociateAwsWebACL();

  const regionValue = useMemo((): string => {
    return region === CLOUDFRONT_OPTION.value ? CLOUDFRONT_REGION : region;
  }, [region]);

  const getListResourcesData = useMemo(() => {
    return {
      cloudId: cloudId,
      region: regionValue,
      request: {
        webACLArn: webACLArn,
      },
    };
  }, [cloudId, regionValue, webACLArn]);

  const getListDistributionData = useMemo(() => {
    return {
      cloudId: cloudId,
      region: regionValue,
      request: {
        webACLId: webACLArn,
      },
    };
  }, [cloudId, regionValue, webACLArn]);

  const isLoading = useMemo(() => {
    return listResourcesForWebACLLoading || awsListDistributionByWebACLIdLoading;
  }, [listResourcesForWebACLLoading, awsListDistributionByWebACLIdLoading]);

  const getWebACLs = useCallback(() => {
    if (region !== 'CloudFront') {
      getListResourcesForWebACL({ variables: getListResourcesData }).then(({ data }) => {
        if (data) {
          const resourceArns = data.getAwsListResourcesForWebACL.data?.[0].resourceArns;
          if (resourceArns) {
            let arnList: AwsArnData[] = [];
            resourceArns.map(arn => arnList.push(extractArnsData(arn)));
            setResourceArnData(resourceArns);
            setResourceRows(arnList);
            setTotalResources({
              totalElement: arnList.length,
              totalPage: Math.ceil(arnList.length / resourcesTablePagination.limit),
            });
          }
        }
      });
    }

    if (region === 'CloudFront') {
      getAwsListDistributionsByWebACLId({ variables: getListDistributionData }).then(res => {
        const distributions = res?.data?.getAwsListDistributionsByWebACLId?.data?.[0]?.distributionList?.items;

        const arns: AwsArnData[] =
          distributions?.map(distribution => {
            const arn = extractArnsData(distribution?.arn ?? '');
            const { id, service, region: arnRegion, accountId, resourceType, resourceName } = arn;
            return {
              id,
              service,
              region: arnRegion || region === 'CloudFront' ? 'Global (CloudFront)' : region,
              accountId,
              resourceType,
              resourceName: resourceName || `${distribution?.id} - ${distribution?.domainName}`,
            };
          }) ?? [];

        setResourceRows(arns);
        setTotalResources({
          totalElement: arns.length,
          totalPage: Math.ceil(arns.length / resourcesTablePagination.limit),
        });
      });
    }
  }, [
    region,
    getListResourcesForWebACL,
    getAwsListDistributionsByWebACLId,
    getListResourcesData,
    getListDistributionData,
  ]);

  useEffect(() => {
    getWebACLs();
  }, [getListResourcesData]);

  const columns: ColumnType[] = useMemo(() => {
    return [
      {
        label: 'Name',
        field: 'resourceName',
        sort: true,
      },
      {
        label: 'Resource type',
        field: 'resourceType',
        renderCell: (row: RowType) => <>{mapResourceType(row?.resourceType)}</>,
        sort: true,
      },
      {
        label: 'Region',
        field: 'region',
        sort: true,
      },
    ];
  }, [resourceRows]);

  const disassociateBtnDisabled = useMemo((): boolean => {
    return checkedList.length === 0 || disassociateAwsWebACLLoading;
  }, [checkedList, disassociateAwsWebACLLoading]);

  const extractArnsData = (arn: string) => {
    // Split the ARN string
    const arnComponents = arn.split(':');

    // Extract specific parts
    const service = arnComponents[2];
    const region = arnComponents[3];
    const accountId = arnComponents[4];
    const resourceData = arnComponents[5].split('/'); // Split resource type and name

    const resourceType = resourceData[0];
    const resourceName = resourceData[2];
    const resourceId = resourceData[3];

    const arnData: AwsArnData = {
      id: resourceId,
      service: service,
      region: regionCodeFormatter(region),
      accountId: accountId,
      resourceType: resourceType,
      resourceName: resourceName,
    };
    return arnData;
  };

  const mapResourceType = (type: string) => {
    switch (type) {
      default:
        return 'Application Load Balancer';

      case 'associatedAWSResources':
        return '';

      case 'distribution':
        return 'CloudFront Distribution';
    }
  };

  // TODO: Backend is checking API
  const handleAddResource = useCallback(
    (resource: any) => {
      const associateVariable: IAwsAssociateWebACLVariables = {
        cloudId: cloudId,
        region: regionValue,
        request: {
          webACLArn: webACLArn,
          resourceArn: resource?.arn || resource?.loadBalancerArn,
        },
      };
      associateAwsWebACL({ variables: associateVariable }).then(({ data: associateResponse }) => {
        if (associateResponse?.getAwsAssociateWebACL?.messages?.[0]) {
          useToast(ErrorCode.SUCCESS, 'Successfully associated web ACL');
          setModalIsOpen(prev => ({ ...prev, addAwsResources: false }));
          getWebACLs();
        } else {
          useToast(ErrorCode.UNKNOWN, 'Unsuccessfully associated web ACL');
        }
      });
    },
    [webACLArn, cloudId, regionValue],
  );

  const handleDisassociatedResource = useCallback(() => {
    const resources = resourceArnData.find(resource => resource.split('/').pop() === selected);
    if (resources) {
      const associateVariable: IAwsDisassociateWebACLVariables = {
        cloudId: cloudId,
        region: region,
        request: {
          resourceArn: resources,
        },
      };
      disassociateAwsWebACL({ variables: associateVariable }).then(({ data: disassociateResponse }) => {
        if (disassociateResponse?.getAwsDisassociateWebACL?.result === 'sc0000') {
          useToast(ErrorCode.SUCCESS, 'Successfully disassociated web ACL');
          setModalIsOpen(prev => ({ ...prev, addAwsResources: false }));
          getWebACLs();
        } else {
          useToast(ErrorCode.UNKNOWN, 'Unsuccessfully disassociated web ACL');
        }
      });
    }
  }, [cloudId, cloudId, resourceArnData, checkedList]);

  return (
    <div className="resize-container vertical">
      <div className="details">
        <div className="row-3">
          <div className="title flex j-between a-center">
            <div>
              <p>Associated AWS resources</p>
            </div>
            <div className="flex action a-center">
              <button className="action-btn" onClick={handleDisassociatedResource} disabled={disassociateBtnDisabled}>
                Disassociate
              </button>
              <button
                className="action-btn"
                onClick={() =>
                  setModalIsOpen(prev => ({
                    ...prev,
                    addAwsResources: true,
                  }))
                }
              >
                Add AWS resources
              </button>
            </div>
          </div>
        </div>

        {resourceRows.length == 0 && !isLoading ? (
          <div className="data-grid-wrap">
            <p className="empty-row">Empty</p>
          </div>
        ) : (
          <div className="data-grid-wrap">
            <Table
              rows={resourceRows}
              columns={columns}
              reportCheckedList={(list: string[]) => {
                setCheckedList(list);
                if (list.length > 0) {
                  setSelected(list[list.length - 1]);
                }
              }}
              reportSelected={(id: string) => {
                setSelected(id);
                setCheckedList([id]);
              }}
              horizontalScrollable={true}
              isLoading={isLoading}
            />
            <div className="pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{totalResources.totalElement}</span>
              </p>
              <TablePagination
                currentPage={resourcesTablePagination.currentPage}
                updateCurrentPage={page => {
                  setResourcesTablePagination(prev => ({
                    ...prev,
                    currentPage: page,
                  }));
                }}
                totalPage={totalResources.totalPage}
              />
            </div>
          </div>
        )}
      </div>
      <SelectResourcesModal
        cloudId={cloudId}
        region={region}
        open={modalIsOpen.addAwsResources}
        title={() => (
          <>
            <Icon width={32} height={32} src={AddResourcesIcon} />
            Add AWS resources
          </>
        )}
        onClose={() =>
          setModalIsOpen(prev => ({
            ...prev,
            addAwsResources: false,
          }))
        }
        isAdding={associateAwsWebACLLoading}
        onAdd={handleAddResource}
      />
    </div>
  );
};

export default AssociatedResources;
