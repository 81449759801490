import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from 'graphql/types/Paging';
import { AwsNetworkAclType } from 'graphql/types/AwsNetworkAcl';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsNetworkAclsPageVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    nextToken?: string;
    internetGatewayIds?: string[];
    dryRun?: boolean;
    filters?: {
      name: string;
      values: string[];
    }
    pageInfo?: Paging;
  }
}

export interface IAwsNetworkAclsResponseData {
  getAwsAllNetworkAcls: IGqlResponseNewData<AwsNetworkAclType>;
}

const lazyGetAllAwsNetworkAcls = () => useLazyQuery<IAwsNetworkAclsResponseData, IAwsNetworkAclsPageVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAllAwsNetworkAcls;