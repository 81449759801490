import LabelInput from 'components/v2/LabelInput';
import './index.scss';

import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import apis from 'apis/v2';

interface IDeleteOrganizationModalProps extends IBaseModalProps {
  organId: number;
  data: string;
  projectListLength: number;
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteOrganizationModal = ({  
  organId,
  data,
  projectListLength,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteOrganizationModalProps) => {

  const [passed, setPassed] = useState({
    emptyPj: false,
    completePay: false
  });
  const [orgName, setOrgName] = useState('');

  /* todo api 조직삭제시 payment status 확인 */
  const { mutateAsync: _getPaymentStatusByOrganId } = useMutation((data: { organId: number; }) => apis.Organ.getPaymentStatusByOrganId(data));

  const enableDeleteButton = useMemo(() => {
    if (orgName !== data || !passed.completePay || !passed.emptyPj) {
      return true;
    } else return false;
  }, [orgName, passed])

  useEffect(() => {
    if (!baseModalProps.open) {
      setOrgName('');

      _getPaymentStatusByOrganId({ organId: organId }).then(({ data }) => {
        if (data) {
          if (data.data) {
            setPassed(prev => ({
              ...prev,
              completePay: data.data.paymentYn
            }))
          }
        }
      })
      
      if (projectListLength === 0) {
        setPassed(prev => ({
          ...prev,
          emptyPj: true
        }))
      }
    }


  }, [baseModalProps.open, projectListLength, organId]);

  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-organization-modal">

        <div className="row-1">
        If you delete an organization, all data, including projects, will be deleted.
        </div>
        <div className="row-2">
          <h5>Please check the two items below to delete the organization.</h5>
          <div className="flex a-center">
            <div className={`icon ${passed.emptyPj && 'passed'}`} />
            Delete all project in this organization.
          </div>
          <div className="flex a-center">
            <div className={`icon ${passed.completePay && 'passed'}`} />
            Pay any outstanding fees.
          </div>
        </div>
        <div className="row-3">
          <LabelInput 
            title={data}
            required
            placeholder="Input organization name"
            value={orgName}
            onChangeValue={(str) => setOrgName(str)}
          />
        </div>
        <div className="btns flex j-between">
          <button 
            className="big-sub-btn-body2regular flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            Cancel
          </button>
          <button 
            className="big-main-btn-body2regular flex j-center a-center" 
            onClick={onDelete}
            disabled={ enableDeleteButton }
          >
            Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteOrganizationModal;
