import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthProvider';
import LabelInput from 'components/v2/LabelInput';
import lazySearchOrgan from 'graphql/queries/searchOrgan';
import { OrganById } from 'apis/v2/Organ/schema';
import IsLoading from 'components/v2/atoms/IsLoading';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { TABLE_OFFSET } from 'utils/DummyData/Dropdown';
import { timeToKr } from 'utils/timeFormatter';
import InfoCard from '../Organ/Project/components/cards/InfoCard';
import lazyGetOrganById from 'graphql/queries/getOrganById';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import { useMutation } from 'react-query';
import apis from 'apis/v2';
import { dateFormatter } from 'utils/Formatter';
import lazyGetOrgan, { IGetOrganVariables } from 'graphql/queries/getOrgan';
import { OrganInfo } from 'graphql/types/OrganInfo';

/** 
  <>
    lg 운영자만 접근 가능한 조직 선택 페이지 
    - 이건 페이지 하나밖에 없으니 페이지내에서 권한 확인 해줘야 함. 
    - 권한없으면 로그인으로 돌려보내기
  </> 
*/
const SelectOrgan = () => {
  const { userInfo, role, token } = useAuth();
  const navigate = useNavigate();
  
  const [readyToSearch, setReadyToSearch] = useState('');
  /* 조직리스트 */
  const [organList, setOrganList] = useState<OrganInfo[]>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [total, setTotal] = useState({
    totalElement: 0,
    totalPage: 0
  });
  const [organInfo, setOrganInfo] = useState<OrganInfo>({
    organId: -1,
    name: '',
    description: '',
    createdAt: '',
    createdBy: '',
    modifiedAt: '',
    modifiedBy: '',
    createdByName: '',
    modifiedByName: '',
    projectCnt: 0,
    userToken: ''
  });
  const [organThumbnail, setOrganThumbnail] = useState('');

  // 조직 이름으로 조직 검색
  const [getOrgan, { loading: loadingOrgList }] = lazyGetOrgan();

  const [getOganById] = lazyGetOrganById();
  const { mutateAsync: _downloadProfileImage } = useMutation((data: any) => apis.File.getDownloadProfileImage(token, data));
  
  const COLUMNS:ColumnType[] = useMemo(() => {
    return [
      {
        label: 'Name',
        field: 'name',
        sort: true
      },
      {
        label: 'Owner name',
        field: 'ownerName',
        sort: true
      },
      {
        label: 'Description',
        field: 'description',
        sort: true,
      },
      {
        label: 'Created',
        field: 'createdAt',
        renderCell: (row:RowType) => <>{timeToKr(row.createdAt)}</>, 
        sort: true
      },
      {
        label: 'Review',
        field: 'review',
        sort: true,
        renderCell: (row:RowType) => <button className="big-main-btn" onClick={() => navigate(`/organ/${row.organId}/dashboard/overview`)}>Switch</button>
      }
    ];
  }, [organList]);
  const onSubmitSearch = (search: string) => {
    const req:IGetOrganVariables = {
      reqData: {
        text: search,
        pageInfo: {
          page: tablePagination.currentPage - 1,
          size: tablePagination.limit,
          orders: [{
            target: tablePagination.target, 
            direction: tablePagination.direction 
          }]
        }
      }
    };
    getOrgan({ variables: req}).then(({ data }) => {
      if (data) {
        if (data.getOrgan.content) {
          setOrganList(data.getOrgan.content.map((d, idx) => ({...d, id: idx})));
          setTotal({
            totalElement: data.getOrgan.totalElements,
            totalPage: data.getOrgan.totalPages
          })
        }
      }
    })
  }
  const getOrganThumbnail = (id:number) => {
    _downloadProfileImage({ kind: 'organ', id: id}).then(({ data }) => {
      console.log(data);
      if (data) { setOrganThumbnail(data?.data?.[0] || undefined)} 
      else { useToast(ErrorCode.UNKNOWN, '조직썸네일을 불러오지 못했습니다.'); }}
    );
  }

  useEffect(() => {
    if (role !== 'sy_admin') {
      if (userInfo?.nemo.organId === null) { navigate('/join-organ')} 
      else {navigate(`/organ/${userInfo?.nemo.organId}/dashboard/overview`)}
    } else {
      onSubmitSearch(readyToSearch);
    }
  }, [role, userInfo]);


  return (
    <div id="select-organ-page">
      <div className="title">Organization</div>
      {organInfo.organId === -1 ? 
      <>
        <div className="search">
          <form 
            className='search-wrap flex a-center'
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitSearch(readyToSearch);
            }}
          >
            <LabelInput
              title="Search" 
              required
              placeholder='Input name'
              value={readyToSearch}
              onChangeValue={str => {
                setReadyToSearch(str)
              }}
            />
            <div className="btns flex a-end">
            <button 
              type="submit"
              className='big-main-btn'
            >
              Search
            </button>
            </div>
          </form>
        </div>
        <div className='content-wrap'>
          <div className="content">
            <div className="content-head-wrap flex j-between">
              <div className="head-title flex a-center">
                <div className="user-icon" /> Results
              </div>
              <div className="head-btns flex a-center">
                <DropdownAtom
                  id="offset-limit"
                  data={TABLE_OFFSET}
                  value={{
                    name: TABLE_OFFSET.find(data => data.value === tablePagination.limit)?.name,
                    value: Number(tablePagination.limit)
                  }}
                  handleClick={(val) => {
                    setTablePagination(prev => ({
                      ...prev,
                      limit: Number(val.value),
                      currentPage: 1
                    }))
                  }}
                />
              </div>
            </div>
            <div className="data-grid-wrap">
              <Table
                rows={organList}
                columns={COLUMNS}
                reportSelected={(id) => {
                  setOrganInfo(organList[Number(id)]);
                  getOrganThumbnail(organList[Number(id)].organId);
                }}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    setTablePagination(prev => ({
                      ...prev,
                      target: target,
                      direction: dir
                    }));
                  }
                }}
              />
              {
                (!loadingOrgList && organList.length === 0 && total.totalElement === 0) ? 
                <div className="empty-rows flex j-center a-center">
                  <div className="flex col a-center">
                    <div className='member-icon' />
                    <h5>Empty</h5>
                    <p>There is no organization.</p>
                  </div>
                </div>
                : (
                  (organList.length === 0 && total.totalElement !== 0) ? 
                  <div className="empty flex col j-center a-center">
                    <div className='member-icon' />
                    <h5>Empty</h5>
                    <p>Please try again with different search terms.</p>
                  </div> :
                  <div className="pagination-wrapper flex a-center">
                    <p className="flex a-center">Total <span>{total.totalElement}</span></p>
                    <TablePagination
                      currentPage={tablePagination.currentPage}
                      updateCurrentPage={(page) => setTablePagination(prev => ({
                        ...prev,
                        currentPage: page
                      }))}
                      totalPage={total.totalPage}
                    />
                  </div>
                  )
              }
            </div>
          </div>
          { loadingOrgList && <IsLoading dimmed={organList.length !== 0 } /> }
        </div>
      </> :
      <>
        <div className="spec-title flex a-center">
          <button 
            className="back-btn"
            onClick={() => setOrganInfo(prev => ({...prev, organId: -1}))}
          />
          {organInfo.name}
        </div>
        <div className="spec-content-box flex col">
          <InfoCard
            title="Avatar"
            content={() => <div 
              className="thumbnail" 
              {...(organThumbnail && organThumbnail !== '' && { 
                style: { backgroundImage: `url('${organThumbnail}')`, backgroundSize: 'cover' } 
              })}
            /> } 
          />
          <InfoCard
            title="Name"
            content={() => <>{organInfo.name}</> } 
          />
          <InfoCard 
            title="Description"
            content={() => <>{organInfo.description}</> } 
          />
          <InfoCard 
            title="Owner"
            content={() => <>{`${1} (${1}) / ${1}`}</> } 
          />
          <InfoCard 
            title="Created"
            content={() => <>{`${organInfo.createdByName} (${organInfo.createdBy}) / ${dateFormatter(organInfo.createdAt, 'datetime')}`}</> } 
          />
          <InfoCard 
            title="Updated"
            content={() => <>{`${organInfo.modifiedByName} (${organInfo.modifiedBy}) / ${dateFormatter(organInfo.modifiedAt, 'datetime')}`}</> } 
          />
        </div>
        <div className="btn-box flex">
          <button className="big-main-btn" onClick={() => navigate(`/organ/${organInfo.organId}/dashboard/overview`)}>Switch</button>
        </div>
      </>}
    </div>
  );
};

export default SelectOrgan;