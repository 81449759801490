import lazyGetAwsKeyPolicy, { IAwsKeyPolicyVariables } from "graphql/queries/getAwsKeyPolicy";
import { KeyPolicyDetailPropsType } from "./types";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import JsonViewer from "layouts/v3/MgdLayout/components/JsonViewer";

const KeyPolicy = (props: KeyPolicyDetailPropsType) => {
  const {customerKey, cloudId, region} = props;

  // API
  const [getAwsKeyPolicy, {loading: keyPolicyLoading}] = lazyGetAwsKeyPolicy();

  // State
  const [policyString, setPolicyString] = useState<string>();

  const keyPolicyVariable = useMemo((): IAwsKeyPolicyVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: {
        keyId: customerKey?.id ?? '',
      },
    }
  }, [cloudId, region, customerKey]);

  const fetchKeyPolicy = useCallback(() => {
    getAwsKeyPolicy({ variables: keyPolicyVariable }).then(({data: awsKeyPolicyResponse}) => {
      const awsKeyPolicyData = awsKeyPolicyResponse?.getAwsKeyPolicy?.data?.[0];
      if (awsKeyPolicyData) {
        setPolicyString(awsKeyPolicyData?.policy);
      }
    });
  }, [keyPolicyVariable]);

  useEffect(() => {
    fetchKeyPolicy();
  }, []);

  return (
    <Fragment>
      {keyPolicyLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <Fragment>
          {policyString &&
            <div className="detail-info-content">
            <JsonViewer
              data={JSON.parse(policyString ?? '')}
              title={'Key Policy'}
              key={`detail_info_key_policy`}
            />
          </div>
          }
        </Fragment>
        
      )}
    </Fragment>
  );
}

export default KeyPolicy;