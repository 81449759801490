import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import Table from 'components/v2/dataDisplay/Table';
import { UserGroupDetailPropsType } from '../../types';
import lazyGetAwsListGroupsForUser, { IAwsListGroupsForUserVariables } from 'graphql/queries/getAwsListGroupsForUser';
import { getBetweenTwoDate } from 'utils/Common';

import './style.scss';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';

const Users = (props: UserGroupDetailPropsType) => {
  const { userGroup, cloudId, region } = props;
  const [getAwsListGroupsForUser, { loading: isLoading }] = lazyGetAwsListGroupsForUser();
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainNextToken, setMainNextToken] = useState<string>('');

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'User name',
        field: 'userName',
        sort: true,
      },
      {
        label: 'Groups',
        field: 'groupsSize',
        sort: true,
      },
      {
        label: 'Last activity',
        field: 'passwordLastUsed',
        sort: true,
      },
      {
        label: 'Creation time',
        field: 'createDate',
        sort: true,
      },
    ];
  }, []);

  const handleGetAwsListGroupsForUser = (userName: string) => {
    const requestVariable: IAwsListGroupsForUserVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        maxItems: tablePagination.limit,
        userName,
      },
    };
    return getAwsListGroupsForUser({ variables: variableCombineNextToken(requestVariable) });
  };

  const fetchData = useCallback(async () => {
    if (!userGroup?.users?.length) return;

    const result: any = await Promise.all(userGroup.users.map(e => handleGetAwsListGroupsForUser(e.userName)));

    const totalResult = userGroup.users.map((e, index) => ({
      ...e,
      groupsSize: result[index]?.data?.getAwsListGroupsForUser?.data?.[0]?.groups?.length,
      passwordLastUsed: e.passwordLastUsed ? getBetweenTwoDate(e.passwordLastUsed) : 'None',
      createDate: getBetweenTwoDate(e.createDate),
    }));

    setMainTblRows(totalResult);
    setMainTblTotal({
      totalPage: Math.ceil(totalResult.length / tablePagination.itemPerPage),
      totalElement: totalResult.length,
    });
  }, [tablePagination, userGroup, cloudId, region, mainTblRows]);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [userGroup, mainTblTotal, tablePagination, mainNextToken]);

  useEffect(() => {
    fetchData();
  }, [userGroup]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>Users in this group</p>

        <p>
          An IAM user in an entity that you create in AWS to represent the person or application that uses it to
          interact with AWS.
        </p>
      </div>
      {isLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <div className="detail-info-content">
          {!mainRowsCurrentPage.length && !isLoading ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <Table
                rows={mainRowsCurrentPage}
                columns={columns}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
                isLoading={isLoading}
                horizontalScrollable={true}
              />

              {mainRowsCurrentPage?.length && !isLoading ? (
                <div className="fleet-instance pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{mainTblTotal.totalElement}</span>
                  </p>

                  <TableManagePagination
                    ableFetchMore={false}
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page => updateTablePagination('currentPage', page)}
                    totalPage={mainTblTotal.totalPage}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Users;
