import { useEffect, useMemo, useState } from 'react';
import { OrderDirection, RowType } from '@Types/v2/Table';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import Icon from 'components/v2/atoms/Icon';
import EventSettingIcon from 'assets/svgs/v2/ico_redcircle_event_setting.svg';
import EventRuleGrid from './components/EventRuleGrid';
import EventRuleDetail from './components/EventRuleDetail';
import CreateEventRuleModal from 'components/v2/modals/CreateEventRuleModal';
import DeleteEventRuleModal from 'components/v2/modals/DeleteEventRuleModal';
import lazyGetEventPageByParam, { IGetEventPageByParamVariables } from 'graphql/queries/getEventPageByParam';
import deleteEventRule from 'graphql/mutations/deleteEventRule';

const EventRules = () => {
  const [modalIsOpen, setModalIsOpen] = useState({
    create: false,
    delete: false
  }); 
  
  const [isShowing, setIsShowing] = useState<'DATA_GRID' | 'DETAIL'>('DATA_GRID');

  const [search, setSearch] = useState('');
  const [total, setTotal] = useState({
    totalPage: 0,
    totalElement: 0
  });
  const [rows, setRows] = useState<RowType[]>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');

  /* 이벤트 규칙 조회 */
  const [getEventRulePageByParam, { loading: loadingGetEventRule }] = lazyGetEventPageByParam();
  /* 이벤트 규칙 삭제 */
  const [delEventRule] = deleteEventRule();

  const getEventsData:IGetEventPageByParamVariables = useMemo(() => ({
    reqData: {
      text: search === '' ? undefined : search,
      pageInfo: {
        page: tablePagination.currentPage - 1,
        size: tablePagination.limit,
        orders: [{
          target: tablePagination.target,
          direction: tablePagination.direction
        }]
      }
    }
  }), [ 
    tablePagination, 
    search,
    isShowing
  ]);

  const renderShowing = useMemo(() => {
    switch (isShowing) {
      default:
        return <EventRuleGrid
          onSubmitSearch={(readyToSearch:string) => { 
            setSearch(readyToSearch);
            setTablePagination(prev => ({
              ...prev,
              currentPage: 1
            }));
          }}
          tablePagination={tablePagination}
          updateTablePagination={(key:string, value: number | string | OrderDirection) => setTablePagination(prev => ({
            ...prev,
            [key]: value
          }))}
          total={total}
          rows={rows}
          checkedList={checkedList}
          reportCheckedList={(list:string[]) => setCheckedList(list)}
          reportSelected={(id:string) => {
            setSelected(id);
            setIsShowing('DETAIL');
          }}
          openCreateModal={() => setModalIsOpen(prev => ({
            ...prev,
            create: true
          }))}
          openDeleteModal={() => setModalIsOpen(prev => ({
            ...prev,
            delete: true
          }))}
          isLoading={loadingGetEventRule}
        />
      case 'DETAIL': 
        return <EventRuleDetail
          data={rows.find(val => val.id === Number(selected)) as RowType} 
          convertGridIsShowing={() => {
            setSelected('');
            setIsShowing('DATA_GRID');
          }}
          openDeleteModal={() =>  {
            setCheckedList([selected]);
            setModalIsOpen(prev => ({
              ...prev,
              delete: true
            }))

         }}    
          onEditSuccess={() => {
            getEventsRows();
          }}
        />
    }
  }, [ 
    isShowing, 
    rows, 
    total,
    tablePagination,
    checkedList, 
    selected,
  ])

  const getEventsRows = () => {
    getEventRulePageByParam({ variables: getEventsData }).then(({ data }) => {
      if (data) {
        if (data.getEventPageByParam.content) {
          setRows(data.getEventPageByParam.content.map(val => (
            {
              ...val,
              id: val.eventId
            }
          )))
          setTotal({
            totalPage: data.getEventPageByParam.totalPages,
            totalElement: data.getEventPageByParam.totalElements
          })
        }
      }
    })
  }

  /* 이벤트규칙 rows 받기 */
  useEffect(() => {
    getEventsRows();
  }, [getEventsData])

  return (
    <div className='event-setting'>
      {renderShowing}
      <CreateEventRuleModal
        open={modalIsOpen.create}
        title={() => <>
          <Icon width={32} height={32} src={EventSettingIcon} />
          Create event-rule
        </>}
        onCreateSuccess={() => {
          setModalIsOpen(prev => ({
            ...prev,
            create: false
          }))
          getEventsRows();
          }}

          onClose={() => {
            setModalIsOpen(prev => ({
              ...prev,
              create: false
            }));
          }}    
        />

      <DeleteEventRuleModal 
        open={modalIsOpen.delete} 
        data={rows.filter(val => checkedList.includes(String(val.id)))} 
        title={() => 
          <>
            <Icon width={32} height={32} src={EventSettingIcon}  />
            Delete event-rules
          </>
        } 
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          delete: false
        }))}
        onDelete={() => {
          const deleteEventData = {
            reqData: {
              ids: checkedList.map(val => Number(val))
            }
          }

          delEventRule({ variables: deleteEventData }).then(({ data }) => {
            if (data) {
              if(data.deleteEvent.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, 'Event-rule is deleted successfully.');
                setModalIsOpen(prev => ({
                  ...prev,
                  delete: false
                }))
                
                if (isShowing === 'DETAIL') {
                  setIsShowing('DATA_GRID');
                }

                if ((rows.length === checkedList.length) && total.totalPage !== 1) {
                  setTablePagination(prev => ({
                    ...prev,
                    currentPage: tablePagination.currentPage - 1
                  }));
                } else {
                  getEventsRows();
                }

                setCheckedList([]);

              } else {
                console.log(data.deleteEvent.result);
                if (data.deleteEvent.result === ErrorCode.USING_IN_EVENT_SETTING) {
                  useToast(ErrorCode.UNKNOWN, '이벤트설정에서 사용중인 이벤트규칙은 삭제불가합니다.');
                } else {
                  useToast(ErrorCode.UNKNOWN, 'Delete event-rule failed.');
                }
              }
            } else {
              useToast(ErrorCode.UNKNOWN, 'Delete event-rule failed.');
            }
          })
        }} 
      />
    </div>
  );
}
export default EventRules;