import { RowType } from '@Types/v2/Table';
import './index.scss';
import { useEffect, useMemo, useState } from 'react';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import InputAtom from 'components/v2/atoms/InputAtom';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import updateTerms from 'graphql/mutations/updateTerms';
import { SandpackProvider } from '@codesandbox/sandpack-react';
import SandPackCode from 'components/v2/SandPackComp';
import prettify from 'html-prettify';
import lazyGetTermsById from 'graphql/queries/getTermsById';

interface ITermsDetailProps {
  termsKindList: {
    name: string;
    value: string;
  }[];
  data: RowType;
  convertGridIsShowing: () => void;
  openDeleteModal: () => void;
  onEditSuccess: () => void;
}

const TermsDetail = ({
  termsKindList,
  data,
  convertGridIsShowing,
  openDeleteModal,
  onEditSuccess
}: ITermsDetailProps) => {

  const { tooltip } = useGModal();

  const [edit, setEdit] = useState(false);
  const [detail, setDetail] = useState({
    termId: -1,
    version: '',
    kindCode: '',
    index: -1,
    mandatoryYn: false,
    useYn: false,
    title: '',
    text: ''
  });

  /* 약관 수정 */
  const [updTerms] = updateTerms();
  /* 약관 id로 조회 */
  const [getTermsById] = lazyGetTermsById();

  const origin = useMemo(() => {
    return {
      termId: data.termId,
      version: data.version,
      kindCode: data.kindCode,
      index: data.index,
      mandatoryYn: data.mandatoryYn,
      useYn: data.useYn,
      title: data.title,
      text: data.text
    }
  }, []);

  const enableEditButton = useMemo(() => {
    if (
      detail.kindCode === '' ||
      detail.title === '' ||
      detail.version === '' ||
      detail.text === ''
    ) {
      return true;
    } else return false;
  }, [detail]);

  const htmlPre = useMemo(() => {
    try {
      const html = prettify(data.text);
      if (html === '') {
        return data.text;
      } else {
        return html;
      }
    } catch {
      return data.text;
    }
  },[data]);

  useEffect(() => {
    getTermsById({ variables: { termsId: Number(data.id) } }).then(({ data }) => {
      if (data) {
        const result = data.getTermsById.data[0]

        setDetail({
          termId: result.termId,
          version: result.version,
          kindCode: result.kindCode,
          index: result.index,
          mandatoryYn: result.mandatoryYn,
          useYn: result.useYn,
          title: result.title,
          text: result.text
        });
      }
    })
  }, [data.id]);

  return (
    <div className='terms-detail'>
      <div className='terms-detail-header flex a-center'>
        <div className='back-btn' 
          onClick={convertGridIsShowing}
        />  
        {!edit ? detail.title : 'Edit terms'}
      </div>
      <div className='detail-contents'>
        <InfoCard
          title="약관 이름" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.title
                : <InputAtom
                  value={detail.title}
                  placeholder='Input name'
                  onChangeValue={(val) => setDetail((prev) => ({
                    ...prev,
                    title: val
                  }))}
                />
              }
            </>
          } 
        />
      <InfoCard
          title="Kind" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? enumFormatter(detail.kindCode)
                : <DropdownAtom 
                  id='kind-code'
                  placeholder='Select kind'
                  data={ termsKindList.map(val => ({ name: enumFormatter(val.value), value: val.value })) } 
                  value={{
                    name: termsKindList.find(val => val.value === detail.kindCode)
                      ? enumFormatter(String(termsKindList.find(val => val.value === detail.kindCode)?.value))
                      : '',
                    value: detail.kindCode
                  }} 
                  handleClick={(val) => {setDetail(prev => ({
                      ...prev,
                      kindCode: String(val.value)
                    })) 
                  }} 
                />
              }
            </>
          } 
        />
        <InfoCard
          title="Version" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.version
                : <InputAtom
                    value={detail.version}
                    placeholder='Input version'
                    onChangeValue={(val) => {
                      if (val.length > 10) return;
                      
                      setDetail((prev) => ({
                        ...prev,
                        version: val
                      }))
                    }
                  }
                  />
              }
            </>
          } 
        />
        <InfoCard
          title="Mandatory" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.mandatoryYn ? 'Yes' : 'No'
                : <div className='radio-wrap flex a-center'>
                <div className='flex a-center'>
                  <input 
                    type="radio" 
                    id="mandatory-rd1" 
                    name="mandatory" 
                    checked={detail.mandatoryYn} 
                    onChange={() => setDetail(prev => ({
                      ...prev,
                      mandatoryYn: true
                    }))}
                  />
                  <label 
                    htmlFor="mandatory-rd1" 
                    className={`mandatory-raido ${detail.mandatoryYn && 'checked'}`}
                  />
                  Yes
                </div>
                <div className='flex a-center'>
                  <input 
                    type="radio" 
                    id="mandatory-rd2" 
                    name="mandatory" 
                    checked={!detail.mandatoryYn} 
                    onChange={() => setDetail(prev => ({
                      ...prev,
                      mandatoryYn: false
                    }))}
                  />
                  <label 
                    htmlFor="mandatory-rd2" 
                    className={`mandatory-raido ${!detail.mandatoryYn && 'checked'}`}
                  />
                  No
                </div>
              </div>
              }
            </>
          } 
        />
        <InfoCard
          title="Activated" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.useYn ? 'Yes' : 'No'
                : <div className='radio-wrap flex a-center'>
                <div className='flex a-center'>
                  <input 
                    type="radio" 
                    id="useYn-rd1" 
                    name="useYn" 
                    checked={detail.useYn} 
                    onChange={() => setDetail(prev => ({
                      ...prev,
                      useYn: true
                    }))}
                  />
                  <label 
                    htmlFor="useYn-rd1" 
                    className={`useYn-raido ${detail.useYn && 'checked'}`}
                  />
                  Yes
                </div>
                <div className='flex a-center'>
                  <input 
                    type="radio" 
                    id="useYn-rd2" 
                    name="useYn" 
                    checked={!detail.useYn} 
                    onChange={() => setDetail(prev => ({
                      ...prev,
                      useYn: false
                    }))}
                  />
                  <label 
                    htmlFor="useYn-rd2" 
                    className={`useYn-raido ${!detail.useYn && 'checked'}`}
                  />
                  No
                </div>
              </div>
              }
            </>
          } 
        />
        {
          !edit &&
        <>
        <InfoCard
          title="Created"
          content={() => 
            <>
            <u
              onMouseOver={(e) => {
                tooltip.userTooltip({ 
                  top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                    ? e.currentTarget.getBoundingClientRect().bottom - 115
                    : e.currentTarget.getBoundingClientRect().bottom + 12,
                  left: e.currentTarget.getBoundingClientRect().left, 
                  userInfo: { userId: data.createdByUserId, fullName: data.createdByName, createdAt: !data.createdAt ? '-' : data.createdAt, thumbnail: '' }
                });
              }}
              onMouseLeave={tooltip.close}
            >
            {data.createdByName}({data.createdByUserId})</u> / {!data.createdAt ? '-' : dateFormatter(data.createdAt, 'datetime')}
            </> 
          } 
        />
        <InfoCard
          title="Updated"
          content={() => 
            <>
                <u
                  onMouseOver={(e) => {
                    tooltip.userTooltip({ 
                      top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                        ? e.currentTarget.getBoundingClientRect().bottom - 115
                        : e.currentTarget.getBoundingClientRect().bottom + 12,
                      left: e.currentTarget.getBoundingClientRect().left, 
                      userInfo: { userId: data.modifiedByUserId, fullName: data.modifiedByName, createdAt: !data.modifiedAt ? '-' : data.modifiedAt, thumbnail: '' }
                    });
                  }}
                  onMouseLeave={tooltip.close}
                >
                  {data.modifiedByName}({data.modifiedByUserId})</u> / {!data.modifiedAt ? '-' : dateFormatter(data.modifiedAt, 'datetime')}
            </> 
          } 
        />
        </>
        }
        <InfoCard
          title="Body" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? <pre>{htmlPre}</pre>
                : <div className='preview-wrap'>
                    <div className="sandpack-label">
                      <div><span>Html</span></div>
                      <div><span>Preview</span></div>
                    </div>
                    <SandpackProvider template="static"> 
                      <SandPackCode 
                        value={detail.text} 
                        onChangeText={(val:string) => setDetail(prev => ({
                          ...prev,
                          text: val
                        }))} 
                      />
                    </SandpackProvider>
                </div>
              }
            </>
          } 
        />
      </div>
      <div className="btns flex j-end">
        {
          !edit 
            ? <>
              <button 
                className="big-sub-btn"
                onClick={() => setEdit(true)}
              >
                Edit
              </button>
              <button 
                className="big-main-btn"
                onClick={openDeleteModal}
              >
                Delete
              </button> 
            </>
            : <>
              <button
                className="big-sub-btn"
                onClick={() => {
                  setDetail({ ...origin });
                  setEdit(false);
                }}
              >
                Cancel
              </button>
              <button 
                className="big-main-btn"
                onClick={() => { 
                const updateTermsData = {
                  reqData: {
                    ...detail,
                    index: undefined
                  }
                }

                updTerms({ variables: updateTermsData }).then(({ data }) => {
                  if (data) {
                    if (data.updateTerms.result === ErrorCode.SUCCESS) {
                      useToast(ErrorCode.SUCCESS, 'Term is updated successfully.');
                      setEdit(false);
                      onEditSuccess();
                    } else {
                      console.log(data.updateTerms.result);
                      useToast(ErrorCode.UNKNOWN, 'Update term failed.');
                    }
                  } else {
                    useToast(ErrorCode.UNKNOWN, 'Update term failed.');
                  }
                })
              }}
                disabled={ enableEditButton }
              >
                Save
              </button>
            </>
        }
      </div>
    </div>
  )
}
export default TermsDetail;