import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import DetailTab from '../../../DetailTab';
import { handleLinkClicked } from 'pages/v2/Organ/Management/Utils';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import { NETWORK_TAB_LIST } from '../../../../configs';
import { networkACLFilterDropdown } from '../../../../Constants';
import { useNavigate } from 'react-router-dom';

function Details({ data }: any) {
  const navigate = useNavigate();

  const {
    subnetId,
    subnetArn,
    state,
    cidrBlock,
    availableIpAddressCount,
    ipv6CidrBlock,
    availabilityZone,
    availabilityZoneId,
    networkBorderGroup,
    vpcId,
    routetableId,
    networkAclId,
    defaultForAz,
    mapPublicIpOnLaunch,
    assignIpv6AddressOnCreation,
    mapCustomerOwnedIpOnLaunch,
    customerOwnedIpv4Pool,
    outpostArn,
    ipv4cidr,
    ipv6cidr,
    ipv6only,
    privateDnsNameOptionsOnLaunch,
    enableDns64,
    ownerId,
  } = data || {};

  const handleFormatBoolean = (value: any) => {
    if (!value) return 'No';

    return 'Yes';
  };

  const handleStatus = (value: any) => {
    if (!value) return 'disabled';

    return 'available';
  };

  const detailsData: IMgdDetailKeyValueProps[] = [
    {
      title: 'Subnet ID',
      description: subnetId,
      type: TextTypeEnum.COPY,
    },
    {
      title: 'Subnet ARN',
      description: subnetArn,
      type: TextTypeEnum.COPY,
    },
    {
      title: 'State',
      description: state,
    },
    {
      title: 'IPv4 CIDR',
      description: cidrBlock,
      type: TextTypeEnum.COPY,
    },
    {
      title: 'Available IPv4 addresses',
      description: availableIpAddressCount,
      type: TextTypeEnum.COPY,
    },
    {
      title: 'IPv6 CIDR',
      description: ipv6CidrBlock,
    },
    {
      title: 'Availability zone',
      description: availabilityZone,
      type: TextTypeEnum.COPY,
    },
    {
      title: 'Availability zone ID',
      description: availabilityZoneId,
      type: TextTypeEnum.COPY,
    },
    {
      title: 'Network border group',
      description: networkBorderGroup,
    },
    {
      title: 'VPC',
      description: vpcId,
    },
    {
      title: 'Route table',
      description: routetableId,
      type: TextTypeEnum.LINK,
    },
    {
      title: 'Network ACL',
      description: networkAclId,
      type: TextTypeEnum.LINK,
      handleItemClick: () =>
        handleLinkClicked({
          navigate,
          link: '/organ/1/manage/network',
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[6].id,
          key: networkACLFilterDropdown[0].value.toString(),
          value: networkAclId,
        }),
    },
    {
      title: 'Default subnet',
      description: handleFormatBoolean(defaultForAz),
    },
    {
      title: 'Auto-assign public IPv4 address',
      description: handleFormatBoolean(mapPublicIpOnLaunch),
    },
    {
      title: 'Auto-assign IPv6 address',
      description: handleFormatBoolean(assignIpv6AddressOnCreation),
    },
    {
      title: 'Auto-assign customer-owned IPv4 address',
      description: handleFormatBoolean(mapCustomerOwnedIpOnLaunch),
    },
    {
      title: 'Customer-owned IPv4 pool',
      description: customerOwnedIpv4Pool,
    },
    {
      title: 'Outpost ID',
      description: outpostArn,
    },
    {
      title: 'IPv4 CIDR reservations',
      description: ipv4cidr,
    },
    {
      title: 'IPv6 CIDR reservations',
      description: ipv6cidr,
    },
    {
      title: 'IPv6-only',
      description: ipv6only,
    },
    {
      title: 'Hostname type',
      description: privateDnsNameOptionsOnLaunch?.hostnameType || '-',
    },
    {
      title: 'Resource name DNS a record',
      description: handleStatus(privateDnsNameOptionsOnLaunch?.enableResourceNameDnsARecord),
    },
    {
      title: 'Resource name DNS AAAA record',
      description: handleStatus(privateDnsNameOptionsOnLaunch?.enableResourceNameDnsAAAARecord),
    },
    {
      title: 'DNS64',
      description: handleStatus(enableDns64),
    },
    {
      title: 'Owner',
      description: ownerId,
      type: TextTypeEnum.COPY,
    },
  ];

  return <DetailTab title="Details" data={detailsData} />;
}

export default Details;
