import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import InputAtom from 'components/v2/atoms/InputAtom';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { checkEmail } from 'utils/v2/ValidCheck';
import { enumFormatter } from 'utils/Formatter';
import { useMutation } from 'react-query';
import apis from 'apis/v2';
import { useAuth } from 'contexts/AuthProvider';
import { IInviteProjectMemberReq } from 'apis/v2/User/schema';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import Labeled from 'components/v2/atoms/Labeled';

interface IInviteMemberModalProps extends IBaseModalProps {
  title: () => JSX.Element;
  onInviteSuccess: () => void;
  projectList:{
    name: string;
    value: number;
  }[]
}

const InviteMemberModal = ({
  title,
  onInviteSuccess,
  projectList,
  ...baseModalProps
}:IInviteMemberModalProps) => {
  const { token } = useAuth();

  const [payload, setPayload] = useState<{
    id: number;
    email: string;
    role: string;
  }[]>([]);
  const [roleCodeList, setRoleCodeList] = useState<{ 
    name: string; 
    value: string
  }[]>([]);
  const [selectProject, setSelectProject] = useState<{
    name: string;
    value: number;
  }>({name: '', value: -1});

  /** 권한코드 (pj_owner, pj_admin, pj_membr) 가져오기  */
  const [getRoleTypeCodeList] = lazyGetEnumTypeCode();
  /* 구성원 초대 */
  const { mutateAsync: _invitePjMember } = useMutation((payload:IInviteProjectMemberReq[]) => apis.User.inviteProjectMember(token, payload));

  const ableInvite = useMemo(() => {
    if (payload.length === 0 || payload.length !== 0 && payload.some(val => checkEmail(val.email) || val.role === '')) {
      return true;
    } else return false;
  }, [payload])
  
  
  useEffect(() => {
    if (baseModalProps.open) {
      getRoleTypeCodeList({ variables: {text: 'RoleCode'} }).then(res => {
        if (res.data) {
          setRoleCodeList(res.data.getEnumTypeCode.data.map(val => ({
            name: String(enumFormatter(val.value)),
            value: val.value
          })));
        }
      });
      setSelectProject({
        name: projectList.length === 0 ? '' : projectList[0].name,
        value: projectList.length === 0 ? 0 : Number(projectList[0].value)
      })
    } else {
      setPayload([{
        id: 0,
        email: '',
        role: ''
      }]);
      
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      {...baseModalProps}
      className={`invite-member-modal-base ${baseModalProps.className}`}
      title={title}
    >
      <div className="invite-member-modal">
        
        <div>
          <div className="row-1">
          Select a project and choose the members and permissions you want to invite.
          </div>
          <div className="row-3">
            <Labeled
              title="Project"
              required
              >
              <DropdownAtom
                className="project-dropdown-atom"
                id={'project-dropdown'}
                placeholder="Select project" 
                data={projectList}
                value={selectProject}
                disabled={projectList.length === 1}
                handleClick={(val:DropdownListDataType) => setSelectProject({
                  name: val.name || '',
                  value: Number(val.value)
              }) }
              />
            </Labeled>
            <Labeled
              title="Member and permission"
              required
            >
            {
              payload.map(val => (
                <div 
                  className="invite-row flex a-center" 
                  key={val.id}
                >
                  <InputAtom
                    placeholder="Input email address" 
                    value={val.email}
                    onChangeValue={(str) => {
                      const copy = payload.map(val => val);
                      const findIdx = copy.findIndex(data => data.id === val.id);
                      if (findIdx === -1) return;

                      copy[findIdx].email = str;
                      setPayload(copy);
                    }}
                  />
                  <DropdownAtom 
                    id={`role ${val.id}`}
                    data={roleCodeList.filter(val => val.value !== 'pj_owner')}
                    value={{
                      name: roleCodeList.find(role => role.value === val.role)?.name
                        ? roleCodeList.find(role => role.value === val.role)?.name
                        : '',
                      value: val.role
                    }} 
                    placeholder="Role"
                    handleClick={(value: DropdownListDataType) => {
                      const copy = payload.map(data => data);
                      const findIdx = copy.findIndex(data => data.id === val.id);
                      if (findIdx === -1) return;

                      copy[findIdx].role = String(value.value);
                      setPayload(copy);
                    }} 
                  />
                  {payload.length > 1 && <button 
                    className="delete-btn" 
                    onClick={() => {
                      const left = payload.filter(data => data.id !== val.id);
                      setPayload(left);
                    }} 
                  />}
                </div>
              ))
            }
            </Labeled>
          </div>
          <div className="row-2 flex j-end">
            <div 
              className="flex a-center"
              onClick={() => setPayload([
                ...payload, 
                {
                  id: payload.length === 0 ? 0 : payload[payload.length - 1].id + 1,
                  email: '',
                  role: ''
                }
              ])}
            >
            <button className="add-btn" />
              Add
            </div>
          </div>
        </div>
        <button 
          className="big-main-btn-body2regular flex j-center a-center" 
          disabled={ ableInvite }
          onClick={() => {
            if(projectList && projectList.length > 0 && selectProject.value !== -1) {
              const inviteData = payload.map(val => ({
                  projectId: selectProject.value,
                  email: val.email,
                  roleCode: val.role
                }))
              
              _invitePjMember(inviteData).then(({data}) => {
                if (data) {
                  onInviteSuccess();
                } else {
                  useToast(ErrorCode.UNKNOWN, 'Invite member failed');
                }
              })
            }
          }}
        > 
          Invite
        </button>
      </div>
    </BaseModal>
  );
};

export default InviteMemberModal;
