import DetailTab from "pages/v2/Organ/Management/components/DetailTab";
import { CryptoConfigDetailPropsType } from "./types";
import { useMemo } from "react";
import { IMgdDetailKeyValueProps, TextTypeEnum } from "layouts/v3/MgdLayout";

const CryptoConfig = (props: CryptoConfigDetailPropsType) => {
  const {metaData} = props;

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    return [
      {
        title: 'Key type',
        description: metaData?.keySpec.startsWith('SYMMETRIC_') ? 'Symmetric' : 'Asymmetric',
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Origin',
        description: metaData?.origin ?? '-',
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Key Spec',
        description: metaData?.keySpec ?? '-',
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Key Usage',
        description: metaData?.keyUsage,
        type: TextTypeEnum.NORMAL,
      },
    ]
  }, [metaData]);


  return (
    <DetailTab title={'Cryptographic configuration'} data={detailTabContentData} isApiLoading={false} />
  );
}

export default CryptoConfig;