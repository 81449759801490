import React from 'react';
import TagTab from "pages/v2/Organ/Management/EC2/components/TagTab";

function Tags({data = {}}: any) {
  const {tags = []} = data

  const columns = [
    {
      label: 'Key',
      field: 'key',
      sort: true,
    },
    {
      label: 'Value',
      field: 'value',
      sort: true,
    },
  ]
  return (
    <TagTab
      title='Tags'
      rows={tags}
      columns={columns}
    />
  );
}

export default Tags;