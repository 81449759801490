import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsLoggingGroupListResponse } from 'graphql/types/AwsLoggingGroups';

export interface IGetAwsDescribeLogGroupListVariables {
  cloudId: number;
  region: string;
  request: {
    logGroupNamePrefix: string;
  }
}

export interface IAwsDescribeLogGroupListResponseData {
  getAwsDescribeLogGroups: IGqlResponseNewData<AwsLoggingGroupListResponse>;
}

const lazyGetAwsDescribeLogGroups = () => useLazyQuery<IAwsDescribeLogGroupListResponseData, IGetAwsDescribeLogGroupListVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsDescribeLogGroups;