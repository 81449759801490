import './index.scss';

interface IInfoCardProps {
  icon?: JSX.Element;
  title: string;
  content: () => JSX.Element;
  className?: string;
  required?: boolean;
}

const InfoCard = ({
  icon,
  title,
  content,
  className,
  required
}:IInfoCardProps) => {

  return (
    <div className={`info-card flex j-start a-center ${className}`}>
      {icon && <div className="info-icon">
        {icon}
      </div>}
      <h5 className="flex">{title}</h5>
      <div className="info-content">{content()}</div>
    </div>
  );
};

export default InfoCard;
