import { gql } from '@apollo/client';

const query = gql`
mutation replaceAwsNetWorkAclAssociation($request: AwsReplaceNetworkAclAssociationRequest, $cloudId: Long, $region: String) {
  replaceAwsNetWorkAclAssociation(replaceNetworkAclAssociationRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsReplaceNetworkAclAssociationResponse {
        newAssociationId
        }
      }
    }
  }
`;
export default query;
