import { useMemo, useState } from 'react';
import './index.scss';

import countryCodes from 'country-codes-list';
import LabelInput from 'components/v2/LabelInput';
import Labeled from 'components/v2/atoms/Labeled';
import InputAtom from 'components/v2/atoms/InputAtom';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import Icon from 'components/v2/atoms/Icon';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import LabelTextarea from 'components/v2/LabelTextarea';
import MailTitle from 'assets/svgs/v2/ico_mail_title.svg';
import CheckTitle from 'assets/svgs/v2/ico_check_title.svg';
import RedCirclePw from 'assets/svgs/v2/ico_redcircle_pw.svg';
import { useAuth } from 'contexts/AuthProvider';
import { useMutation } from 'react-query';
import apis from 'apis/v2';
import { koEnNumSpRegex, numberRegex } from 'utils/Regex';
import { IUploadProfileImageReq } from 'apis/v2/File/schema';
import updatePassword from 'graphql/mutations/updatePassword';
import { Mb } from 'utils/v2/DummyData/File';
import { ProfileType } from 'components/v2/Header';
import ChangeEmailModal from 'components/v2/modals/ChangeEmailModal';
import EmailSendedModal from 'components/v2/modals/EmailSendModal';
import ResetPwModal from 'components/v2/modals/ResetPwModal';

interface IProfileProps {
  profileInfo: ProfileType,
  onEditProfileInfo: (key: string, value: string|number|boolean) => void;
  userThumbnail: string;
  onSuccessUploadUserThumbnail: () => void;
}

const Profile = ({
  profileInfo,
  onEditProfileInfo,
  userThumbnail,
  onSuccessUploadUserThumbnail
}:IProfileProps) => {

  const { token, userInfo } = useAuth();

  const [modalIsOpen, setModalIsOpen] = useState({
    email: false,
    emailSended: false,
    pw: false
  });
  
  /* 사용자썸네일 업로드 */
  const { mutateAsync: _uploadProfileImage } = useMutation((data: IUploadProfileImageReq) => apis.File.uploadProfileImage(token, data));
  /* 사용자 비밀번호 수정 */
  const [updatePw] = updatePassword();

  const countryCodesList = countryCodes.all().map(d => 
    ({ callingCode: d.countryCallingCode, countryCode: d.countryCode, languageCode: d.officialLanguageCode })
  );

  const codeDropdownData = useMemo(() => {
    return countryCodesList.map(d => ({ 
      value: `+${d.callingCode}`, 
      name: `+${d.callingCode}` })
    );
  },[countryCodesList]);

  return (
    <>
      <div className="profile-content">
        <div className="contain-left">
          <div className="profile-photo">
            <Labeled title="My avatar" required>
              <div 
                className="profile-image"
                { ...(userThumbnail && userThumbnail !== '' && { 
                    style: { backgroundImage: `url('${userThumbnail}')`, backgroundSize: 'cover' } 
                  })
                }
              >
              </div>
              <input 
                id="read-profile-image" 
                type="file" 
                accept=".jpg, .png, .gif"
                onChange={(e) => {
                  if (e.target.files) {
                    const file = e.target.files[0];
                    if (!file.name.toLowerCase().endsWith('jpg') && !file.name.toLowerCase().endsWith('png') && !file.name.toLowerCase().endsWith('gif')) {
                      useToast(ErrorCode.UNKNOWN, '.jpg, .png, .gif 확장자만 가능합니다.');

                      return e.target.value ='';
                    }

                    if (file.size > Mb) {
                      useToast(ErrorCode.UNKNOWN, '1MB이하 파일만 첨부해주세요.');

                      return e.target.value = '';
                    }

                    const uploadProfileImageData = {
                      file: file,
                      reqUploadProfileImage : { 
                        kind: 'member',
                        userId: profileInfo.userId
                      }
                    }
                    _uploadProfileImage(uploadProfileImageData).then(({data}) => {
                      if (data) {
                        if(data.result === ErrorCode.SUCCESS) {
                          useToast(ErrorCode.SUCCESS, '사진이 등록되었습니다.');
                          onSuccessUploadUserThumbnail();
                        } else {
                          if (data.result === ErrorCode.INVALID_EXTENSION) { useToast(ErrorCode.UNKNOWN, '.jpg, .png, .gif 확장자만 가능합니다.');} 
                          else {useToast(ErrorCode.UNKNOWN, '사진등록에 실패했습니다.');}
                          console.log(data.result);
                        }
                      } else {
                        useToast(ErrorCode.UNKNOWN, '사진등록에 실패했습니다.');
                      }
                    })
                  }
                }} 
              />
              <label htmlFor="read-profile-image" className="flex a-center j-center">
                <div className="add-btn" />
                Upload image
              </label>
            </Labeled>
          </div>
        </div>
        <div className="contain-right">
          <LabelInput
            title="Name"
            required
            placeholder="Input your name (min 2 character, max 30 character)"
            value={profileInfo.fullName}
            onChangeValue={(str) => {
              if (str.length > 30) return;
              if (koEnNumSpRegex.test(str)) { return useToast(ErrorCode.UNKNOWN, '특수문자는 포함할 수 없습니다.');}

              onEditProfileInfo('fullName', str.replace(koEnNumSpRegex, ''));
            }}
          />
          <LabelInput
            title="Organization"
            disabled
            required
            value={profileInfo.organName}
          />
          <LabelInput
            title="ID"
            disabled
            required
            value={profileInfo.userId}
          />
          <Labeled
            title="Email"
            required
            className="email-change-box"
          >
            <div className="row flex a-center">
              <InputAtom
                value={profileInfo.email}
                disabled
              />
              <button 
                className="big-sub-btn email-change-btn"
                onClick={() => setModalIsOpen(prev => ({
                  ...prev,
                  email: true
                }))}
              >
                Change
              </button>
            </div>
          {/* 
            mark todo: 나중에 실제로 값이 어떻게 넘어오는지 확인필요.
            sended 된 이메일로 실제 인증이 완료되기 전까지 화면에 아래 인증중 노출 
          */}
          {
            profileInfo.statusCode  === 'initail0' && /* 일단 이렇게 적어둠. 실제값은 inital0이 맞는가? */
            <h5>Once verification is complete, your registered email will be automatically changed.</h5>
          }
          </Labeled>
          <div className="row flex j-between a-center">
            <p>Password</p>
            <button
              className="big-sub-btn password-change-btn"
              onClick={() => setModalIsOpen(prev => ({
                ...prev,
                pw: true
              }))}
            >
              Change password
            </button>
          </div>
          <LabelInput
            title="Job title"
            placeholder="Input your job title"
            value={!profileInfo.ranks ? '' : profileInfo.ranks }
            onChangeValue={(str) => {
              if (profileInfo.ranks.length > 30) return;

              onEditProfileInfo('ranks', str);
            }}
          />
          <LabelTextarea
            title="Description"
            placeholder="Input your profile"
            value={!profileInfo.description ? '' : profileInfo.description}
            onChangeValue={(str) => onEditProfileInfo('description', str)}
          /> 
          <Labeled
            title="Mobile"
            className="contact-row"
          >
            <div className="flex">
              <DropdownAtom 
                id={'contact'} 
                data={codeDropdownData}
                value={{
                  name: codeDropdownData.find(code => code.value === profileInfo.countryCode)?.name 
                    ? codeDropdownData.find(code => code.value === profileInfo.countryCode)?.name
                    : '',
                  value: profileInfo.countryCode
                }} 
                handleClick={(val) => onEditProfileInfo('countryCode', String(val.value))} 
              />
              <InputAtom
                value={profileInfo.mobileNumber}
                placeholder="Input your mobile number"
                onChangeValue={(str) => {
                  if (str.length > 16) return;

                  onEditProfileInfo('mobileNumber', str.replace(numberRegex, ''))
                }
              }
              />
            </div>
          </Labeled>
        </div>
      </div>
      
      <ChangeEmailModal
        className="change-email-modal-wrap"
        existingEmail={profileInfo.email}
        open={modalIsOpen.email}
        title={() => 
          <>
            <Icon width={32} height={32} src={MailTitle} />
            Change email
          </>
        }
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          email: false
        }))}
        onSendSuccess={() => {
          setModalIsOpen(prev => ({
            ...prev,
            email: false,
            emailSended: true 
          })); 
        }}
      />

      <EmailSendedModal
        className="email-sended-modal-wrap"
        open={modalIsOpen.emailSended}
        title={() => 
          <>
            <Icon width={32} height={32} src={CheckTitle} />
            Check your email
          </>
        } 
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          emailSended: false
        }))}
      />

      <ResetPwModal
        className="change-password-modal-wrap"
        open={modalIsOpen.pw}
        title={() => 
          <>
            <Icon width={32} height={32} src={RedCirclePw} />
            Change password
          </>
        }
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          pw: false
        }))}
        onReset={(newPw:string) => {
          const updatePasswordData = {
            reqData:{
              userPw: newPw,
              email: profileInfo.email
            }
          }
          updatePw({ variables: updatePasswordData}).then(({ data }) => {
            if (data) {
              if (data.updatePassword.result === ErrorCode.SUCCESS){
                useToast(ErrorCode.SUCCESS, 'Password is updated successfully.');
                onEditProfileInfo('pw', newPw);
                setModalIsOpen(prev => ({
                  ...prev,
                  pw: false
                }));    
              } else {
                console.log(data.updatePassword.result);
                useToast(ErrorCode.UNKNOWN, 'Password update failed.');
              }
            }
          })
        }} 
      />
    </>
  );
};

export default Profile;
