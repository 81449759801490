import { gql } from '@apollo/client';

const query = gql`
  mutation deleteAwsSecurityGroup($request: AwsDeleteSecurityGroupRequest, $cloudId: Long, $region: String) {
    deleteAwsSecurityGroup(deleteSecurityGroupRequest: $request, cloudId: $cloudId, region: $region) {
      result
    }
  }
`;
export default query;
