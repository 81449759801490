import { useEffect, useMemo, useState } from "react";
import { DETAIL_TEXT_DESCRIPTION, NETWORK_ACL_SUMMARY_TAB_LIST } from "./configs";
import { NetworkAclSummaryPropsType } from "../types";
import DetailTab from "../DetailTab";
import { TextTypeEnum } from "layouts/v3/MgdTabLayout/configs";
import InboundOutbound from "./Tabs/InboundOutbound";
import TagTab from "../../../EC2/components/TagTab";
import { ColumnType, RowType } from "@Types/v2/Table";
import { AwsNetworkAclAssociationType, AwsNetworkAclType } from "graphql/types/AwsNetworkAcl";
import SubnetAssociation from "./Tabs/SubnetAssociation";
import { IMgdDetailKeyValueProps } from "layouts/v3/MgdLayout";
import { handleLinkClicked } from "../../../Utils";
import { ManagementTypeEnum } from "../../..";
import { NETWORK_TAB_LIST } from "../../configs";
import { subnetFilterDropdown, vpcFilterDropdown } from "../../Constants";
import { useNavigate } from "react-router-dom";

const NetworkAclSummary = (props: NetworkAclSummaryPropsType) => {
  const navigate = useNavigate()

  const {networkAcl, isSummaryHidden} = props;
  const [tabSelected, setTabSelected] = useState(NETWORK_ACL_SUMMARY_TAB_LIST[0]);

  const networkAclId = useMemo((): string => {
    return networkAcl?.networkAclId ?? '';
  }, [networkAcl]);

  const handleGetLink = (key: string, value: string) => {
    switch (key) {
      case DETAIL_TEXT_DESCRIPTION[1].id:
        return handleLinkClicked({
          navigate,
          link: '/organ/1/manage/network',
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[2].id,
          key: subnetFilterDropdown[0].value.toString(),
          value,
        });

      case DETAIL_TEXT_DESCRIPTION[3].id:
        return handleLinkClicked({
          navigate,
          link: '/organ/1/manage/network',
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[0].id,
          key: vpcFilterDropdown[0].value.toString(),
          value,
        });

      default:
        return null;
    }
  };

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    if (networkAcl) {
      return DETAIL_TEXT_DESCRIPTION.map(item => {
        let description: any = '';
        if (item.id === DETAIL_TEXT_DESCRIPTION[1].id) {
          const listsubnetIDs = networkAcl?.associations?.map((association: AwsNetworkAclAssociationType) => association?.subnetId);
          description =
            listsubnetIDs?.length > 1
              ? listsubnetIDs
              : networkAcl?.associations?.[0]?.subnetId;
        } else if (item.id === DETAIL_TEXT_DESCRIPTION[2].id) {
          description = networkAcl?.isDefault ? 'Yes' : 'No';
        } else {
          description = networkAcl[item.id as keyof AwsNetworkAclType];
        }

        return {
          id: item.id,
          type: item.type as TextTypeEnum,
          isTooltip: item?.isTooltip,
          description: description,
          title: item.value,
          handleItemClick: (value: string) => handleGetLink(item.id, value || description),
        };
      });
    }
    return [];
  }, [networkAcl]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const tagRows = useMemo((): RowType[] => {
    return networkAcl?.tags ?? [];
  }, [networkAcl]);

  const tabNode = useMemo(() => {
    switch (tabSelected.id) {
      case NETWORK_ACL_SUMMARY_TAB_LIST[0].id:
        return <DetailTab title={'Details'} data={detailTabContentData} isApiLoading={false} />;

      case NETWORK_ACL_SUMMARY_TAB_LIST[1].id:
        return <InboundOutbound networkAcl={networkAcl} isInbound={true} />;

      case NETWORK_ACL_SUMMARY_TAB_LIST[2].id:
        return <InboundOutbound networkAcl={networkAcl} isInbound={false} />;

      case NETWORK_ACL_SUMMARY_TAB_LIST[3].id:
        return <SubnetAssociation networkAcl={networkAcl} />;

      case NETWORK_ACL_SUMMARY_TAB_LIST[4].id:
        return <TagTab title={'Tags'} rows={tagRows} columns={tagColumns} />;

      default:
        return null;
    }
  }, [tabSelected, networkAcl, tagRows, tagColumns, detailTabContentData]);

  useEffect(() => {
    setTabSelected(NETWORK_ACL_SUMMARY_TAB_LIST[0]);
  }, [networkAcl]);

  if (!networkAcl) {
    return (
      <div className="detail-data">
        <p className="empty-page">Select a item</p>
      </div>
    );
  }

  return (
    <div className="box-summary">
      {!isSummaryHidden && <div className="box-summary-id">{networkAclId}</div>}

      <div className="detail-tab flex a-center">
        {NETWORK_ACL_SUMMARY_TAB_LIST.map(tab => {
          return (
            <button
              className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
              key={tab.id}
              data-tab={tab.id}
              onClick={e => setTabSelected(tab)}
            >
              <p>{tab.name}</p>
            </button>
          );
        })}
      </div>

      <div className="content-tab">{tabNode}</div>
    </div>
  );
}

export default NetworkAclSummary;