import './style.scss'
import {IMgdDetailKeyValueProps, TextTypeEnum} from "layouts/v3/MgdLayout";
import DetailTab from "../../../DetailTab";
import { getFormatedDateWithTimezone, handleGetAwsType } from "utils/Common";

function Details({data = {}}: any) {
  const {
    createDate,
    updateDate,
    arn = ''
  } = data

  const summary: IMgdDetailKeyValueProps[] = [
    {
      title: 'Type',
      description: handleGetAwsType(arn),
    },
    {
      title: 'Creation time',
      description: getFormatedDateWithTimezone(createDate, 'MMM DD, YYYY HH:MM'),
    },
    {
      title: 'Edited time',
      description: getFormatedDateWithTimezone(updateDate, 'MMM DD, YYYY HH:MM'),
    },
    {
      title: 'ARN',
      description: arn,
      type: TextTypeEnum.COPY
    },
  ]

  return (
    <div className='user-group-details-tab'>
      <DetailTab title='Policy detail' data={summary} />
    </div>
  );
}

export default Details;