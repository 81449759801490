import {gql} from '@apollo/client';

const query = gql`
query getAwsListAttachedRolePolicies($request: AwsListAttachedRolePoliciesRequest, $cloudId: Long, $region: String) {
  getAwsListAttachedRolePolicies(listAttachedRolePoliciesRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsListAttachedRolePoliciesResponse {
       attachedPolicies {
        policyName
        policyArn
      }
        isTruncated
        marker
        }
    }
  }
}
`;
export default query;