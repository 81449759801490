import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { RolePropsType } from "../types";
import { IMgdTablePaginationProps, IMgdTotalPageProps } from "layouts/v3/MgdLayout";
import { ColumnType, OrderDirection, RowType } from "@Types/v2/Table";
import { FilterPropsType } from "../../../EC2/components/Image/types";
import { getDateTimeBetweenTwoDate, handleFormatText } from "utils/Common";
import { orderAlphabetical } from "../../../Utils/Sorting";
import { AwsRoleType } from "graphql/types/AwsListRolesResponse";
import { variableCombineNextToken } from "../../../Utils";
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import InputAtom from "components/v2/atoms/InputAtom";
import Table from "components/v2/dataDisplay/Table";
import TableManagePagination from "components/v2/dataDisplay/TableManagePagination";
import lazyGetListRoles, { IGetListRolesVariables } from "graphql/queries/getAwsListRoles";
import lazyGetRole, { IAwsRoleVariables } from "graphql/queries/getAwsRole";

const RoleList = (props: RolePropsType) => {
  const { cloudId, region, setRole, setIsDetailPage } = props;

  //API
  const [getListRoles, {loading: getListRoleLoading}] = lazyGetListRoles();
  const [getRole, {loading: getRoleLoading}] = lazyGetRole();;

  // State
  const [nextToken, setNextToken] = useState<string>('');
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [searchValue, setSearchValue] = useState<FilterPropsType>({
    name: '',
    value: '',
  });
  const [scopeNFilterVariable, setScopeNFilterVariable] = useState<FilterPropsType>();

  const isApiLoading = useMemo((): boolean => {
    return getListRoleLoading || getRoleLoading;
  }, [getListRoleLoading, getRoleLoading]);

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Role name',
        field: 'roleName',
        sort: true,
        renderCell: (row: RowType) => (
          <span
            className="link"
            onClick={e => {
              e.stopPropagation();
              setRole(row as AwsRoleType);
              setIsDetailPage();
            }}
          >
            {handleFormatText(row?.roleName)}
          </span>
        ),
      },
      {
        label: 'Trusted entities',
        field: 'trustedEntities',
        sort: true,
      },
      {
        label: 'Last activity',
        field: 'lastActivity',
        sort: true,
      }
    ];
  }, []);

  const getTrustedEntities = useCallback((role: AwsRoleType): string => {
    const decodeString = decodeURIComponent(role?.assumeRolePolicyDocument);
    const jsonObject = JSON.parse(decodeString);
    const printcipal = jsonObject?.['Statement']?.[0]?.['Principal'];

    const federated = printcipal?.['Federated'];
    const aws = printcipal?.['AWS'];
    const service = printcipal?.['Service'];
    
    if (federated) {
      return `Identity Provider: ${federated}`;
    }
    if (aws) {
      const accountNumber = aws?.replace('arn:aws:iam::', '')?.split(':')?.[0];
      return `Account: ${accountNumber}`;
    }
    if (service && !Array.isArray(service)) {
      const serviceName = service?.replace('.amazonaws.com', '');
      return `Service: ${serviceName}`;
    }
    return '-';
  }, []);

  const getLastActivity = useCallback((role: AwsRoleType): string => {
    const lastUsedDate = role?.roleLastUsed?.lastUsedDate;
    if (!lastUsedDate) {
      return '-';
    }
    
    return getDateTimeBetweenTwoDate(lastUsedDate);
  }, []);

  const fetchRoles = useCallback(async (nextToken?: string, isInitial?: boolean) => {
    try {
      const requestVariable: IGetListRolesVariables = {
        cloudId: cloudId,
        region: region,
        request: {
          maxItems: mainTablePagination.limit,
        },
      };

      const combinedVariable = variableCombineNextToken(requestVariable, nextToken);

      const {data: listRoleResponse } = await getListRoles({variables: combinedVariable});
      const awsRoles = listRoleResponse?.getAwsListRoles?.data?.[0];

      if (awsRoles) {
        const roleRows = awsRoles?.roles?.length
          ?
            await Promise.all(
              awsRoles.roles.map(async role => {
                const reqVariable: IAwsRoleVariables = {
                  cloudId: cloudId,
                  region: region,
                  request: {
                    roleName: role.roleName
                  }
                }
                const {data: roleDetailResponse} = await getRole({variables: reqVariable});
                const roleDetail = roleDetailResponse?.getAwsRole?.data?.[0]?.role;
                if (roleDetail) {
                  return {
                    id: roleDetail.roleId,
                    trustedEntities: getTrustedEntities(roleDetail),
                    lastActivity: getLastActivity(roleDetail),
                    ...roleDetail
                  }
                }
              })
            )
          : [];

          let totalRoles: RowType[] = [];
          if (isInitial) {
            totalRoles = roleRows as RowType[];
          } else {
            totalRoles = [...mainTblRows, ...roleRows] as RowType[];
          }

          setNextToken(awsRoles?.marker);
          setMainTblRows(totalRoles);
          setMainTblTotal({
            totalPage: Math.ceil(totalRoles.length / mainTablePagination.itemPerPage),
            totalElement: totalRoles.length,
          });
      }
    } catch (error) {
    }
  }, [mainTblRows, mainTablePagination, cloudId, region]);

  const mainRowsCurrentPage = useMemo((): RowType[] => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!nextToken) {
      fetchRoles(nextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    let totalRows = mainTblRows;
    totalRows = mainTblRows?.filter(row => {
      return row?.roleName?.includes(scopeNFilterVariable?.value || '');
    });

    setMainTblTotal({
      totalPage: Math.ceil(totalRows.length / mainTablePagination.itemPerPage),
      totalElement: totalRows.length,
    });

    return orderAlphabetical(totalRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, scopeNFilterVariable, mainTablePagination, nextToken]);

  useEffect(() => {
    fetchRoles('', true);
  }, [cloudId]);

  const handleSearch = useCallback(() => {
    updateTablePagination('currentPage', 1);

    if (searchValue?.value) {
      setScopeNFilterVariable(searchValue);
      return;
    }

    setScopeNFilterVariable(undefined);
  }, [searchValue]);

  const handleSelectItem = useCallback(
    (id: string) => {
      const rowSelected = mainTblRows.find(row => row.id === id);

      if (!rowSelected) return;

      setRole(rowSelected as AwsRoleType);
    },
    [mainTblRows],
  );

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>Roles</p>

          <span className="title-description">
            An IAM role is an identity you can create that has specific permissions with credentials that are valid for short durations.
          </span>
        </div>

        <div className="flex action a-center">
          <InputAtom
            placeholder="Find user groups by name"
            type={'text'}
            value={searchValue.value}
            defaultValue={''}
            onChangeValue={e => setSearchValue(prev => ({ name: prev?.name, value: e }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={handleSearch}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSearch();
              }
            }}
          />

          <button className="action-btn" onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>

      {!mainRowsCurrentPage.length && !isApiLoading ? (
        <div className="data-grid-wrap">
          <p className="empty-row">Empty</p>
        </div>
      ) : (
        <div className="data-grid-wrap">
          <Table
            rows={mainRowsCurrentPage}
            columns={columns}
            reportCheckedList={() => {}}
            reportSelected={id => handleSelectItem(id)}
            sortOption={{
              target: mainTablePagination.target,
              direction: mainTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateTablePagination('target', target);
                updateTablePagination('direction', dir);
              },
            }}
            isLoading={isApiLoading}
            horizontalScrollable={true}
          />

          {mainRowsCurrentPage && mainRowsCurrentPage.length > 0 && !isApiLoading && (
            <div className="pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{mainTblTotal.totalElement}</span>
              </p>
              <TableManagePagination
                ableFetchMore={!!nextToken}
                currentPage={mainTablePagination.currentPage}
                updateCurrentPage={page =>
                  setMainTablePagination(prev => ({
                    ...prev,
                    ['currentPage']: page,
                  }))
                }
                totalPage={mainTblTotal.totalPage}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}

export default RoleList;