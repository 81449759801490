import { useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Base64 } from 'js-base64';
import jwtDecode from 'jwt-decode';
import { AuthTokenType } from '@Types/User';
import EmailIcon from 'assets/svgs/v2/ico_email_verify.svg';
import { useAuth } from 'contexts/AuthProvider';
import FooterComponent from 'components/v2/FooterComponent';

interface IVerifyProps {
  nextStep: () => void;
}
const Verify = ({ nextStep }:IVerifyProps) => {

  const { logout } = useAuth();

  const [searchParams] = useSearchParams();

  const tokenResult:'SUCCESS' | 'TOKENERROR' | 'UNKNOWN' = useMemo(() => {
    const authToken = searchParams.get('authToken');
    if (authToken) {
      try {
        const decodedToken = Base64.decode(authToken);
        const decodedData = jwtDecode(decodedToken) as AuthTokenType;

        if (new Date(decodedData.exp * 1000).getTime() > new Date().getTime()) {
          nextStep();
          return 'SUCCESS';
        } else {
          return 'TOKENERROR';
        }
      } catch {
        return 'UNKNOWN';
      }
    } else {
      return 'UNKNOWN';
  }
  },[searchParams]);

  const renderBody = (tokenResult: 'SUCCESS' | 'TOKENERROR' | 'UNKNOWN') => {
    if (tokenResult === 'TOKENERROR') {
      return (
        <>
          <h2>
          The verification period has expired.<br />
          Please restart the verification process.
          </h2>
          <Link to="/auth/reset-pw-sendemail" className="big-main-btn" replace>Done</Link>
        </>
      );
    } else if(tokenResult === 'UNKNOWN') {
      return (
        <>
          <h2>This is an unauthorized access.</h2>
          <button 
            className='big-main-btn'
            onClick={() => logout()}
          >
            Done
          </button>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <article id="reset-pw-verify-article">
        <img src={EmailIcon} width={56} height={56} />
        {renderBody(tokenResult)}
      </article>
      <FooterComponent />
    </>
  );
};
export default Verify;