import lazyGetAwsPublicKey, { IAwsPublicKeyVariables } from 'graphql/queries/getAwsPublicKey';
import { PublicKeyDetailPropsType } from './types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { AwsGetPublicKey } from 'graphql/types/AwsPublicKey';

const PublicKey = (props: PublicKeyDetailPropsType) => {
  const { cloudId, region, customerKey } = props;

  const [getAwsPublicKey, { loading: publicKeyLoading }] = lazyGetAwsPublicKey();

  const [publicKey, setPublicKey] = useState<AwsGetPublicKey>();

  const reqVariable = useMemo((): IAwsPublicKeyVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: { keyId: customerKey.keyId },
    };
  }, [cloudId, region, customerKey]);

  const fetchPublicKey = useCallback(() => {
    getAwsPublicKey({ variables: reqVariable }).then(({ data: responseData }) => {
      const awsPublicKey = responseData?.getAwsPublicKey?.data?.[0];
      if (awsPublicKey) {
        setPublicKey(awsPublicKey);
      }
    });
  }, [reqVariable]);

  useEffect(() => {
    fetchPublicKey();
  }, []);

  if (publicKeyLoading) {
    return (
      <div className="progress-container">
        <div className="progress-gif" />
        Loading ...
      </div>
    );
  }

  return (
    <Fragment>
      {publicKey && (
        <div className="content-tab">
          <div className="detail-info">
            <div className="detail-info-title">
              <p>Public key</p>
            </div>
            <div className="detail-info-content public-key">
              <p>-----BEGIN PUBLIC KEY-----</p>
              <p>{publicKey?.publicKey}</p>
              <p>-----END PUBLIC KEY-----</p>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default PublicKey;
