import './style.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import Table from 'components/v2/dataDisplay/Table';
import { getBetweenTwoDate, randomString } from 'utils/Common';
import lazyGetAwsAccessKeyLastUsed, { IAwsAccessKeyLastUsedVariables } from 'graphql/queries/getAwsAccessKeyLastUsed';
import lazyGetAwsListSSHPublicKeys, { IAwsListSSHPublicKeysVariables } from 'graphql/queries/getAwsListSSHPublicKeys';

const SSHPublicKeys = (props: any) => {
  const { data = {}, cloudId, region } = props;
  const { userName } = data;

  const [isLoading, setIsLoading] = useState(false);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [getAwsListSSHPublicKeys] = lazyGetAwsListSSHPublicKeys();
  const [getAwsAccessKeyLastUsed] = lazyGetAwsAccessKeyLastUsed();

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'SSH key ID',
        field: 'keyId',
        sort: true,
      },
      {
        label: 'Uploaded',
        field: 'uploaded',
        sort: true,
      },
      {
        label: 'Status',
        field: 'status',
        sort: true,
      },
    ];
  }, []);

  const handleGetAwsListSSHPublicKeys = (userName: string) => {
    const requestVariable: IAwsListSSHPublicKeysVariables = {
      cloudId,
      region,
      request: {
        userName,
      },
    };

    const combinedVariable = variableCombineNextToken(requestVariable);
    return getAwsListSSHPublicKeys({ variables: combinedVariable });
  };

  const fetchData = useCallback(async () => {
    try {
      if (!userName) return;

      setIsLoading(true);
      const { data: listSSHPublicKeys } = await handleGetAwsListSSHPublicKeys(userName);

      if (!listSSHPublicKeys?.getAwsListSSHPublicKeys?.data?.[0]?.sshPublicKeys) {
        setIsLoading(false);
        return;
      }

      const totalResult = listSSHPublicKeys?.getAwsListSSHPublicKeys?.data?.[0]?.sshPublicKeys?.map((e) => ({
        ...e,
        id: randomString(),
        keyId: e.sshPublicKeyId,
        uploaded: getBetweenTwoDate(e.uploadDate),
        status: e.status,
      }));

      setMainTblRows(totalResult || []);
      setMainTblTotal({
        totalPage: Math.ceil(totalResult.length / tablePagination.itemPerPage),
        totalElement: totalResult.length,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [tablePagination, userName, cloudId, region, mainTblRows]);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [userName, mainTblTotal, tablePagination]);

  useEffect(() => {
    fetchData();
  }, [userName]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>SSH public keys for AWS CodeCommit</p>

        <p>
          User SSH public keys to authenticate access to AWS CodeCommit repositories. You can have a maximum of five SSH
          public keys (active or inactive) at a time.
        </p>
      </div>
      {isLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <div className="detail-info-content">
          {!mainRowsCurrentPage.length && !isLoading ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <Table
                rows={mainRowsCurrentPage}
                columns={columns}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
                isLoading={isLoading}
                horizontalScrollable={true}
              />

              {mainRowsCurrentPage?.length && !isLoading ? (
                <div className="fleet-instance pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{mainTblTotal.totalElement}</span>
                  </p>
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SSHPublicKeys;
