import { gql } from '@apollo/client';

const query = gql`
  query getAwsListServiceSpecificCredentials(
    $request: AwsListServiceSpecificCredentialsRequest
    $cloudId: Long
    $region: String
  ) {
    getAwsListServiceSpecificCredentials(
      listServiceSpecificCredentialsRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsListServiceSpecificCredentialsResponse {
          serviceSpecificCredentials {
            userName
            status
            serviceUserName
            createDate
            serviceSpecificCredentialId
            serviceName
          }
        }
      }
    }
  }
`;
export default query;
