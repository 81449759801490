import { useCallback, useEffect, useMemo, useState } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import Icon from 'components/v2/atoms/Icon';
import IconEditDefaultWebAcl from 'assets/svgs/v3/ico_policy_variables.svg';
import './index.scss';
import Action from '../CustomRuleModal/components/Action';
import { Input, SectionTitle } from '../WebACL/CreateWebACL/components';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import SectionItem from '../WebACL/CreateWebACL/components/SectionItem';
import CustomHeader from '../CustomRuleModal/components/CustomHeader';
import ButtonGroup from '../WebACL/CreateWebACL/components/ButtonGroup';
import Button, { ButtonTypeEnum } from '../WebACL/CreateWebACL/components/Button';
import DropdownContainer from '../CustomRuleModal/components/DropdownContainer';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { DropdownListDataType } from 'components/Dropdown/types';
import JSONViewer from 'components/JSONViewer';

interface IEditDefaultWebAclActionModalProps extends IBaseModalProps {
  header: string;
  action: string;
  onSave: (data: any) => void;
}

const EditDefaultWebAclActionModal = ({
  header,
  action = 'allow',
  onSave,
  ...baseModalProps
}: IEditDefaultWebAclActionModalProps) => {
  const [currentAction, setCurrentAction] = useState(action);
  const [customHeaderList, setCustomHeaderList] = useState<Array<any>>([]);
  const [responseCode, setResponseCode] = useState('');
  const [specifyResponseBodyItem, setSpecifyResponseBodyItem] = useState<any>();
  const [isOpenCreateResponseBody, setIsOpenCreateResponseBody] = useState(false);

  useEffect(() => {
    if (baseModalProps.open) {
      setCurrentAction(action);
    }
  }, [baseModalProps.open]);

  const onSaveBtnClicked = useCallback(() => {
    onSave({ action: currentAction, headerList: customHeaderList, responseCode, specifyResponseBodyItem });
  }, [currentAction, customHeaderList, responseCode, specifyResponseBodyItem]);

  const actionButtons = useMemo(() => {
    return [
      {
        id: 'cancel',
        label: 'Cancel',
        type: ButtonTypeEnum.GENERAL,
        disabled: false,
        onClick: () => baseModalProps?.onClose?.(),
      },
      {
        id: 'save',
        label: 'Save',
        type: ButtonTypeEnum.PRIMARY,
        disabled: false,
        onClick: onSaveBtnClicked,
      },
    ];
  }, [onSaveBtnClicked]);

  const DEFAULT_HEADER = {
    key: '',
    value: '',
    isKeyValid: false,
    isValueValid: false,
  };

  const handleCustomHeaderValueChanged = useCallback(
    (value: string, key: 'key' | 'value', index: number) => {
      let newHeaders = [...customHeaderList];
      newHeaders[index][key] = value;
      setCustomHeaderList(newHeaders);
    },
    [customHeaderList],
  );

  const handleRemoveCustomHeader = useCallback(
    (index: number) => {
      let newHeaders = [...customHeaderList];
      newHeaders.splice(index, 1);
      setCustomHeaderList(newHeaders);
    },
    [customHeaderList],
  );

  const handleAddNewCustomHeader = useCallback(() => {
    setCustomHeaderList([...customHeaderList, { ...DEFAULT_HEADER }]);
  }, [customHeaderList]);

  const specifyResponseBodyOption = useMemo((): any => {
    return [];
  }, []);

  const handleChangeSpecifyResponseBody = (e: DropdownListDataType) => {
    if (!specifyResponseBodyOption?.length) return;

    const item = [].find(({ value }: { value: string }) => value === e.value);

    if (!item) return;

    setSpecifyResponseBodyItem(item);

    if (e?.value === specifyResponseBodyOption[1]?.value) setIsOpenCreateResponseBody(true);
  };

  const collapsibleContentNode = useMemo(() => {
    if (currentAction === 'allow') {
      return (
        <div className="collapsible-content">
          <CustomHeader
            customHeaderList={customHeaderList as []}
            setCustomHeaderList={setCustomHeaderList}
            description="With the Allow action, you can add custom headers to the web request. AWS WAF prefixes your custom header names with x-amzn-waf- when it inserts them."
          />
        </div>
      );
    }

    return (
      <div className="collapsible-content">
        <Input
          title="Response code"
          onValueChanged={setResponseCode}
          value={responseCode}
          isVertical
          placeholder="Enter response code"
        />
        <SectionTitle
          title="Response headers"
          description="Specify the custom headers to be included in the custom response. The header key cannot be “content-type”."
          caption="- optional"
          customStyle="title-section-without-padding"
        />
        {customHeaderList.map((header, index) => (
          <div className="header-row-container">
            <Input
              title={'Key'}
              placeholder="Header name"
              value={header.key}
              onValueChanged={value => handleCustomHeaderValueChanged(value, 'key', index)}
              isVertical
              isRequired
              error={header.isKeyValid}
            />
            <Input
              title={'Value'}
              placeholder="Header value"
              value={header.value}
              onValueChanged={value => handleCustomHeaderValueChanged(value, 'value', index)}
              isVertical
              error={header.isValueValid}
            />
            <Button label="Remove" onClick={() => handleRemoveCustomHeader(index)} />
          </div>
        ))}
        <Button label={'Add new custom header'} onClick={handleAddNewCustomHeader} type={ButtonTypeEnum.PRIMARY} />
        <SectionTitle
          title="Choose how you would like to specify the response body"
          description="Select an existing réponse body or create a new one. You can use a response body anywhere in the web ACL or rule group where you create it."
          caption="- optional"
          customStyle="title-section-without-padding"
        />
        <DropdownAtom
          id="specify-response-body-dropdown"
          placeholder="-"
          data={specifyResponseBodyOption}
          value={{
            value: specifyResponseBodyItem?.value || '',
            name: specifyResponseBodyItem?.name || '',
          }}
          handleClick={handleChangeSpecifyResponseBody}
        />

        {specifyResponseBodyItem?.responseBody ? (
          <>
            <SectionTitle
              title="Response body"
              description="The response body can be plain text, HTML, or JSON."
              customStyle="title-section-without-padding"
            />

            <JSONViewer
              data={specifyResponseBodyItem?.responseBody}
              bottomDescription="Response body cannot exceed 4 KB in size."
            />
          </>
        ) : null}
      </div>
    );
  }, [currentAction, responseCode, customHeaderList, specifyResponseBodyOption, specifyResponseBodyItem]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditDefaultWebAcl} />
          <div className="text-title-modal">{header}</div>
        </>
      )}
      className={'modal-edit-default-web-acl-action'}
      {...baseModalProps}
    >
      <div className="edit-web-acl-action-content">
        <div className="rule-container-group-content">
          <SectionTitle
            title="Action"
            description="Choose an action to take when a request originates from one of the IP addresses in this IP set."
          />
          <div className="radio-button-group">
            <RadioAtom
              checked={'allow'}
              value={currentAction}
              name="Allow"
              label="Allow"
              onChange={() => setCurrentAction('allow')}
            />
            <RadioAtom
              checked={'block'}
              value={currentAction}
              name="Block"
              label="Block"
              onChange={() => setCurrentAction('block')}
            />
          </div>

          <SectionItem.Collapsible
            title={currentAction === 'allow' ? 'Custom request' : 'Custom response'}
            caption="-optional"
            noBorder
          >
            {collapsibleContentNode}
          </SectionItem.Collapsible>
        </div>

        <ButtonGroup buttons={actionButtons} />
      </div>
    </BaseModal>
  );
};

export default EditDefaultWebAclActionModal;
