import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { CustomerKeyDetailPropsType } from './types';
import { AwsKeyMetadata } from 'graphql/types/AwsKey';
import { DETAIL_TAB_LIST, DETAIL_TEXT_DESCRIPTION } from './configs';
import { IMgdDetailKeyValueProps, IMgdTabProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import PageDetailTitle from '../../../components/PageDetailTitle';
import DetailTab from '../../../components/DetailTab';
import lazyGetAwsDescribeKey, { IAwsDescribeKeyVariables } from 'graphql/queries/getAwsDescribeKey';
import lazyGetAwsListAliases, { IAwsListAliasesVariables } from 'graphql/queries/getAwsListAliases';
import { AwsListAliasesResponse } from 'graphql/types/AwsListAliases';
import KeyPolicy from './Tabs/KeyPolicy';
import CryptoConfig from './Tabs/CryptoConfig';
import Tag from './Tabs/Tag';
import KeyRotation from './Tabs/KeyRotation';
import PublicKey from './Tabs/PublicKey';
import Regionality from './Tabs/Regionality';
import Aliases from './Tabs/Aliases';

const CustomerKeyDetail = (props: CustomerKeyDetailPropsType) => {
  const { cloudId, region, customerKey, pageBackClick } = props;

  // API
  const [getAwsDescribeKey, { loading: getAwsDescribeKeyLoading }] = lazyGetAwsDescribeKey();
  const [getAwsListAliases, { loading: getAwsListAliasLoading }] = lazyGetAwsListAliases();

  // State
  const [detailTabList, setDetailTabList] = useState<IMgdTabProps[]>(DETAIL_TAB_LIST);
  const [detailTabSelected, setDetailTabSelected] = useState<IMgdTabProps>(detailTabList[0]);
  const [describeKey, setDescribeKey] = useState<AwsKeyMetadata>();
  const [aliasEntry, setAliasEntry] = useState<AwsListAliasesResponse>();

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    if (customerKey) {
      return DETAIL_TEXT_DESCRIPTION.map(item => {
        let description = '';
        if (item.id === 'name') {
          description = aliasEntry?.aliases?.[0]?.aliasName || '-';
        } else if (item.id === 'status') {
          description = describeKey?.keyState || '-';
        } else if (item.id === 'createDate') {
          description = describeKey?.creationDate || '-';
        } else if (item.id === 'arn') {
          description = describeKey?.arn || '-';
        } else if (item.id === 'description') {
          description = describeKey?.description || '-';
        } else {
          description = describeKey?.multiRegion
            ? `Multi Region ${describeKey?.multiRegionConfiguration.multiRegionKeyType}`
            : 'Single Region';
        }
        return {
          id: item.id,
          type: item.type as TextTypeEnum,
          description: description,
          title: item.value,
        };
      });
    }
    return [];
  }, [customerKey, aliasEntry, describeKey]);

  const detailContentNode = useMemo((): ReactNode => {
    switch (detailTabSelected) {
      default:
      case DETAIL_TAB_LIST[0]:
        return <KeyPolicy cloudId={cloudId} region={region} customerKey={customerKey} />;
      case DETAIL_TAB_LIST[1]:
        if (describeKey) {
          return <CryptoConfig metaData={describeKey} />;
        }
        return null;
      case DETAIL_TAB_LIST[2]:
        return <Tag cloudId={cloudId} region={region} customerKey={customerKey} />;
      case DETAIL_TAB_LIST[3]:
        return <KeyRotation cloudId={cloudId} region={region} customerKey={customerKey} />;
      case DETAIL_TAB_LIST[4]:
        return <PublicKey cloudId={cloudId} region={region} customerKey={customerKey} />;
      case DETAIL_TAB_LIST[5]:
        if (describeKey) {
          return <Regionality metaData={describeKey} />;
        }
        return null;
      case DETAIL_TAB_LIST[6]:
        return <Aliases cloudId={cloudId} region={region} customerKey={customerKey} />;
    }
  }, [detailTabList, detailTabSelected, cloudId, region, describeKey, customerKey]);

  const describeVariable = useMemo((): IAwsDescribeKeyVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: {
        keyId: customerKey?.id ?? '',
      },
    };
  }, [cloudId, region, customerKey]);

  const fetchAwsDescribeKey = useCallback(() => {
    getAwsDescribeKey({ variables: describeVariable }).then(({ data: describeKeyResponse }) => {
      setDescribeKey(describeKeyResponse?.getAwsDescribeKey?.data?.[0]?.keyMetadata);
    });
  }, [describeVariable]);

  const aliasReq = useMemo((): IAwsListAliasesVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: { keyId: customerKey.keyId },
    };
  }, [cloudId, region, customerKey]);

  const fetchAwsAliasEntry = useCallback(() => {
    getAwsListAliases({ variables: aliasReq }).then(({ data: aliasReponse }) => {
      setAliasEntry(aliasReponse?.getAwsListAliases?.data?.[0]);
    });
  }, [aliasReq]);

  useEffect(() => {
    fetchAwsDescribeKey();
    fetchAwsAliasEntry();

    const tabList = [...DETAIL_TAB_LIST];
    if (customerKey.keyType === 'Asymmetric') {
      tabList.splice(3, 1);
    } else {
      tabList.splice(4, 1);
    }
    if(!customerKey.isMultiRegion) {
      tabList.splice(4, 1)
    } else {
      tabList.splice(3, 1)
    }
    setDetailTabList(tabList);
  }, [customerKey]);

  return (
    <div className="ec2-detail">
      <PageDetailTitle title={`${customerKey?.name}`} pageBackClick={pageBackClick} />

      {getAwsDescribeKeyLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <Fragment>
          {!describeKey ? (
            <div>
              <p>You do not have permission to access the KMS keys</p>
            </div>
          ) : (
            <Fragment>
              {customerKey && (
                <Fragment>
                  <DetailTab title={'General configuration'} data={detailTabContentData} isApiLoading={false} />

                  <div className="tab-container">
                    <div className="detail-tab flex a-center">
                      {detailTabList.map(tab => {
                        return (
                          <button
                            className={`detail-tab-items ${detailTabSelected?.id === tab.id && 'active'}`}
                            key={tab.id}
                            data-tab={tab.id}
                            onClick={e => setDetailTabSelected(tab)}
                          >
                            <p>{tab.name}</p>
                          </button>
                        );
                      })}
                    </div>

                    <div className="content-tab">{detailContentNode}</div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default CustomerKeyDetail;
