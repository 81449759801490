import { useLazyQuery, useQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';

type EnumCodeText = 'DictRelObjectType' |
'MemoRelObjectType' |
'MsgSenderTypeCode' |
'MsgUseTypeCode' |
'ResFormatCode' |
'ResRelObjectType' |
'AlarmStatusCode' |
'FileStatusCode' |
'PriorityCode' |
'ReportTypeCode' |
'HistEventStatusCode' |
'CloudKindCode' |
'RoleCode' |
'ThirdPartyCode' |
'AuthTypeCode' |
'LoginTypeCode' |
'SysAuthCode' |
'ServiceTypeCode' |
'AwsRegionCode' |
'MsspScopeCode' |
'FunctionType' |
'DashboardType';

export interface IGetEnumTypeCodeVariables {
  text: EnumCodeText;
}
export interface IGetEnumTypeCodeResponseData {
  getEnumTypeCode: IGqlResponseData<{
    description: any; name: string; value: string , defaultYn?: boolean;
}[]>;
}

const getEnumTypeCode = (variables: IGetEnumTypeCodeVariables) => useQuery<IGetEnumTypeCodeResponseData, IGetEnumTypeCodeVariables>(query, { variables, fetchPolicy: 'no-cache' });
export const lazyGetEnumTypeCode = () => useLazyQuery<IGetEnumTypeCodeResponseData>(query, { fetchPolicy: 'no-cache' });
export default getEnumTypeCode;