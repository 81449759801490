import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import { ErrorCode } from '@Types/error';
import PasswordTitleIcon from 'assets/svgs/v2/ico_pw_title.svg';
import EmailTitleIcon from 'assets/svgs/v2/ico_mail_title.svg';
import LabelInput from 'components/v2/LabelInput';
import VerticalSplitLinkGroup from 'components/v2/VerticalSplitLinkGroup';
import { useToast } from 'hooks/v2/useToast';
import { termLinks } from 'utils/Links';
import { useMutation } from 'react-query';
import apis from 'apis/v2/index';
import { useAuth } from 'contexts/AuthProvider';

/** <>비밀번호 재설정</> */
const ResetPw = () => {

  const { logout } = useAuth();
  
  const [input, setInput] = useState('');
  const [step, setStep] = useState(1);
  const [timer, setTimer] = useState(0);

  const { mutateAsync: _requestPw } = useMutation((userId: string) => apis.Auth.reqReset(userId));
  
  const disabledNextBtn = useMemo(() => {
    if (input.length === 0) {
      return true;
    } else {
      return false;
    }
  },[input]);
  
  const tryResetPw = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    _requestPw(input).then(({ data }) => {
      if (data.result === ErrorCode.SUCCESS) {
        setStep(1);
      } else {
        useToast(ErrorCode.UNKNOWN, 'The user does not exist.');
      }
    });
  };
  const sendEmail = () => {
    _requestPw(input);
    useToast(ErrorCode.SUCCESS, 'A password reset email sent successfully.');
  };
  const startTimer = () => {
    sendEmail();
    setTimer(20);
  };

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {setTimer(prev => prev - 1);},1000);
    }
  },[timer]);

  return (
    <>
      {step === 0 ? 
        <article id="reset-pw-page-article">
          <h2><img src={PasswordTitleIcon} width={32} height={32} />Reset password</h2>
          <h3>You can reset your password using your ID and email.</h3>
          <form onSubmit={tryResetPw}>
            <LabelInput
              title="ID or email" 
              id="reset-pw-id" 
              required
              placeholder="Input your ID or email"
              defaultValue={input} 
              onChangeValue={(value: string) => setInput(value)} 
            />
            <button className="big-main-btn submit-btn" disabled={disabledNextBtn}>Next</button>
          </form>
          <Link to={'/auth'}>back to login</Link>
        </article> :
        <>
          <article id="reset-pw-page-article" className="step2">
            <h2><img src={EmailTitleIcon} width={32} height={32} />Check your email</h2>
            <h3>
            A password-reset email is sent.<br />
            Open the email and click the 'Reset Password' button.
            </h3>
            <h4>
            Didn’t receive the verification email? Check your spam folder.
            </h4>
            <div className="button-group">
              <button 
                className="big-sub-btn" 
                type="button" 
                onClick={startTimer} 
                disabled={timer > 0}
              >
                {timer > 0 ? `Resend email (${timer}s)` : 'Resend email'}
              </button>
              <button
                className='big-main-btn'
                onClick={() => logout()}
              >
                Login
              </button>
            </div>
          </article>
          <VerticalSplitLinkGroup list={termLinks} />
        </>
      }
    </>
  );
};
export default ResetPw;