import moment from 'moment/moment';
import _ from 'lodash';

type AwsArnData = {
  id: string;
  service?: string;
  region?: string;
  accountId?: string;
  resourceType?: string;
  resourceName?: string;
};

export const extractResourceArnData = (arn: string) => {
  // Split the ARN string
  const arnComponents = arn.split(':');

  // arn:aws:cloudfront::443258847110:distribution/EA6FZD3YMB6LQ

  // Extract specific parts
  const service = arnComponents[2];
  const region = arnComponents[3];
  const accountId = arnComponents[4];
  const resourceData = arnComponents[5].split('/'); // Split resource type and name

  const resourceType = resourceData[0];
  const resourceId = resourceData[1];

  const resourceName = arnComponents[arnComponents.length - 1];

  const arnData: AwsArnData = {
    id: resourceId,
    service: service,
    region: region,
    accountId: accountId,
    resourceType: resourceType,
    resourceName,
  };
  return arnData;
};

export const mapResourceType = (type: string) => {
  switch (type) {
    default:
    case 'application':
      return 'Application Load Balancer';

    case 'associatedAWSResources':
      return '';

    case 'distribution':
      return 'CloudFront Distribution';
  }
};

const maxLength = 45;

export const handleFormatText = (value?: string | number | null | undefined) => {
  if (!value) return '';

  const valueStr = value.toString();

  return valueStr?.length > maxLength ? `${valueStr?.substring(0, maxLength)}...` : valueStr;
};

export const getBetweenTwoDate = (value: any) => {
  if (!value) return '';

  const currentDate = new Date();
  const prevTime = new Date(value);

  // @ts-ignore
  const diffMs = currentDate - prevTime;
  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(diffMs / (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
  const diffMinutes = Math.floor(diffMs / (1000 * 60 * 60 * 24) / (1000 * 60));

  if (diffDays === 0) {
    if (diffHours > 0) {
      return `${diffHours} hours ago`;
    } else if (diffMinutes > 0) {
      return `${diffMinutes} minutes ago`;
    } else {
      return 'Just now';
    }
  } else if (diffDays === 1) {
    return '1 day ago';
  } else if (diffDays === 2) {
    return '2 days ago';
  } else {
    return moment(value).format('DD/MM/YYYY, h:mm a');
  }
};

export const getDateTimeBetweenTwoDate = (value: string) => {
  if (!value) return '';

  const currentDate = new Date();
  const prevTime = new Date(value);

  const diffMs = currentDate.valueOf() - prevTime.valueOf();
  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor((diffMs / (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const diffMinutes = Math.floor((diffMs / (1000 * 60 * 60 * 24)) / (1000 * 60))

  if (diffDays === 0) {
    if (diffHours > 0) {
      return `${diffHours} hours ago`
    } else if (diffMinutes > 0) {
      return `${diffMinutes} minutes ago`
    } else {
      return 'Just now'
    }
  } else if (diffDays === 1) {
    return 'Yesterday';
  } else {
    return `${diffDays} days ago`;
  }
};

export const getFormatedDateWithTimezone = (dateString?: string, dateTimeFormat?: string) => {
  if (dateString) {
    let string: string = '';
    const date = moment(dateString);
    const isUTC = date.isUTC();
    const fullDateString = date.utcOffset(date.utcOffset()).format();
    const stringTimeZone = fullDateString.substring(fullDateString.length - 6, fullDateString.length);
    const format = dateTimeFormat ? dateTimeFormat : 'MMM DD, YYYY, hh:mm';

    string = `${format} ${stringTimeZone}`;
    const datetime = moment(dateString).format(string);
    const plusIndex = datetime.indexOf('+');
    const fullString = `${datetime.substring(0, plusIndex)} (${isUTC ? 'UTC' : 'GMT'}${datetime.substring(
      plusIndex,
      datetime.length,
    )})`;
    return fullString;
  }
  return '';
};

export const randomString = () => {
  return (Math.random() + 1).toString(36).substring(2, 8);
};

export const convertSecondToHour = (seconds: number) => {
  const modulus = seconds % 3600;
  const hourNumber = seconds / 3600;
  if (!modulus) {
    return hourNumber === 1 ? '1 hour' : `${hourNumber} hours`;
  }
  return `${seconds} seconds`;
}

export const isDev = process.env.NODE_ENV === 'development';

export const handleGetAwsType = (arn: string) => {
  if (!arn) return '';

  const arr = arn?.split(':');

  return arr?.[4] === 'aws' ? 'AWS managed' : (parseInt(arr?.[4]) ? 'Customer managed' : '' );
};
