import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import './index.scss';
import LabelInput from 'components/v2/LabelInput';
import Labeled from 'components/v2/atoms/Labeled';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { PRIORITY, TABLE_OFFSET } from 'utils/DummyData/Dropdown';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import Table from 'components/v2/dataDisplay/Table';
import IsLoading from 'components/v2/atoms/IsLoading';
import { enumFormatter } from 'utils/Formatter';
import CurrentStatusCard from 'pages/v2/Organ/Report/components/CurrentStatusCard';
import { timeToKr } from 'utils/timeFormatter';
import { useMemo, useState } from 'react';

interface IEventSettingGridProps {
  onSubmitSearch: (readyToSearch: {
    priorityCode: string;
    searchText: string;
  }) => void;
  total: {
    totalPage: number;
    totalElement: number;
  }
  rows: RowType[];
  tablePagination: {
    limit: number;
    target: string;
    direction: OrderDirection;
    currentPage: number;
  }
  updateTablePagination: (key: string, value: number | string | OrderDirection) => void;
  checkedList: string[];
  reportCheckedList: (list:string[]) => void;
  reportSelected: (id: string) => void;
  openCreateModal: () => void;
  openDeleteModal: () => void;
  isLoading?: boolean;
}

const EventSettingGrid = ({
  tablePagination,
  updateTablePagination,
  onSubmitSearch,
  total,
  rows,
  checkedList,
  reportCheckedList,
  reportSelected,
  openCreateModal,
  openDeleteModal,
  isLoading
}:IEventSettingGridProps) => {

  const [readyToSearch, setReadyToSearch] = useState({
    priorityCode: 'all',
    searchText: ''
  });

  const COLUMNS:ColumnType[] = useMemo(() => {
    return [
      {
        label: 'Name',
        field: 'name',
        sort: true
      },
      {
        label: 'Project',
        field: 'projectName',
        sort: true
      },
      {
        label: 'Priority',
        field: 'priorityCode',
        sort: true,
        renderCell: (row: RowType) => <CurrentStatusCard status={row.priorityCode} />,
      },
      {
        label: 'Receive type',
        field: 'msgSenderTypeCode',
        renderCell: (row: RowType) => <>{ !row.msgSenderTypeCode ? '-' : enumFormatter(row.msgSenderTypeCode)}</>
      },
      {
        label: 'Updated at',
        field: 'modifiedAt',
        renderCell: (row:RowType) => <>{!row.modifiedAt ? '-' : timeToKr(row.modifiedAt)}</>, 
        sort: true
      }
    ];
  }, [rows]);
  
  return (
    <div className='event-setting-grid'>
    
      <form 
        className='search-wrap flex a-end'
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitSearch(readyToSearch);
        }}
      >
        <Labeled 
          title="Priority"
          required
          className="label-priority"
          >
          <DropdownAtom 
            id='event-setting-priority'
            data={
              [
                {
                  name: 'All',
                  value: 'all'
                },
                ...PRIORITY
              ]
            } 
            value={{
              name: readyToSearch.priorityCode === 'all' ? 'All' : PRIORITY.find(val => val.value === readyToSearch.priorityCode)?.name,
              value: readyToSearch.priorityCode
            }} 
            handleClick={(val) => {
              setReadyToSearch(prev => ({
                ...prev,
                priorityCode: String(val.value)
              }))
            }} 
          />
        </Labeled>
        <LabelInput 
          title="Search" 
          required
          placeholder='Input name'
          value={readyToSearch.searchText}
          onChangeValue={str => {
            setReadyToSearch(prev => ({
              ...prev,
              searchText: str
            }))
          }}
        />
        <div className="btns flex a-end">
        <button 
          type="submit"
          className='big-main-btn'
        >
          Search
        </button>
        </div>
      </form>
      <div className='data-contents'>
        <div className="data-grid-wrap">
          <div className="grid-header flex j-between">
            <div className="col-1 flex a-center">
              <div className="header-icon" /> results <span>{total.totalElement}</span>
            </div>
            <div className="col-2 flex a-center">
              { checkedList.length > 0 && `${checkedList.length} selected` }
              <button 
                className="big-sub-btn delete"
                disabled={checkedList.length === 0 }
                onClick={openDeleteModal}
                >
                Delete
              </button>
              {
                total.totalElement !== 0 &&
                <>        
                <button 
                  className="big-main-btn register"
                  onClick={openCreateModal}
                >
                  Create
                </button> 
                <DropdownAtom 
                  id="offset-limit"
                  data={TABLE_OFFSET}
                  value={{ 
                    name: TABLE_OFFSET.find(data => data.value === tablePagination.limit)?.name,
                    value: Number(tablePagination.limit)
                  }}
                  handleClick={(val) => { 
                    updateTablePagination('limit', Number(val.value))
                    updateTablePagination('currentPage', 1);
                  }}
                />
                </>
              }
            </div>
          </div>
          <div className="grid-main">
            <Table
              rows={rows} 
              columns={COLUMNS} 
              reportCheckedList={reportCheckedList}
              reportSelected={reportSelected}
              sortOption={{
                target: tablePagination.target,
                direction: tablePagination.direction,
                onChangeSort: (target: string, dir: OrderDirection) => {
                  updateTablePagination('target', target);
                  updateTablePagination('direction', dir);
                }
              }}
            />
            
            { 
              rows.length === 0 
                ? <div className="empty-rows flex j-center a-center">
                  <div className="flex col a-center">
                    <div className='empty-icon' />
                    <h5>There is no item.</h5>
                    <p>Create your event setting.</p>
                    <button 
                      className='big-main-btn'
                      onClick={openCreateModal}
                    >
                      Create
                    </button>
                  </div>
                </div> 
                : <>
                <div className="pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{total.totalElement}</span>
                  </p>
                  <TablePagination
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page => updateTablePagination('currentPage', page)}
                    totalPage={total.totalPage}
                  />
                </div>
                </>
            }
          </div>
        </div>
        { isLoading && <IsLoading dimmed={rows.length !== 0} /> }
      </div>
    </div>
  )
}
export default EventSettingGrid;