import { useState, useEffect } from 'react';
import Box from 'components/atoms/Box';
import Dropdown from 'components/molecules/Dropdown';
import { STATEMENT_CONDITION_DROPDOWN_LIST } from '../EditRuleGroup/configs';
import StatementNode from '../../../CustomRuleModal/RuleBuilder/VisualEditor/Regular/StatementNode';
import { DropdownListDataType } from 'components/molecules/Dropdown/types';
import './styles.scss';
import { RULE_EDITOR_SELECTOR } from '../../../CustomRuleModal/constant';
import { type Content } from 'vanilla-jsoneditor';
import JSONEditor from 'components/JSONEditor';

type ScopeDownStatementPropsType = {
  cloudId: number;
  selectedRegion: DropdownListDataType;
  statementData: any;
  setStatementData: (data: any) => void;
  statementCondition: any;
  setStatementCondition: (data: any) => void;
  json: any;
};

const ScopeDownStatement = (props: ScopeDownStatementPropsType) => {
  const { cloudId, selectedRegion, statementData, setStatementData, statementCondition, setStatementCondition, json } =
    props;
  const [ruleEditorSelector, setRuleEditorSelector] = useState(RULE_EDITOR_SELECTOR[0].value);
  const [jsonEditorContent, setJsonEditorContent] = useState<Content>({
    json,
  });

  useEffect(() => {
    setJsonEditorContent({ json });
  }, [json]);

  const contentNode = () => {
    if (ruleEditorSelector === RULE_EDITOR_SELECTOR[1].value) {
      return <JSONEditor content={jsonEditorContent} onChange={setJsonEditorContent} className="json-editor-container"/>;
    }

    return (
      <>
        <div className="rule-builder-condition-request">
          <p className="rule-text-condition">If a request</p>
          <div className="dropdown-container">
            <Dropdown
              id="dropdown-rule-condition-request"
              data={STATEMENT_CONDITION_DROPDOWN_LIST}
              value={statementCondition}
              handleClick={setStatementCondition}
            />
          </div>
        </div>
        <StatementNode
          value={statementCondition.value.toString()}
          cloudId={cloudId}
          selectedRegion={selectedRegion}
          statementData={statementData}
          setStatementData={setStatementData}
          errors={{}}
          ipSets={[]}
          isHidePositionInsideHeader={false}
        />
      </>
    );
  };

  return (
    <Box className="scope-down-statement-container">
      <div className="rule-container-group-action">
        {RULE_EDITOR_SELECTOR.map(({ id, value, label }) => (
          <button
            key={id}
            className={value === ruleEditorSelector ? 'active' : ''}
            onClick={() => setRuleEditorSelector(value)}
          >
            {label}
          </button>
        ))}
      </div>
      {contentNode()}
    </Box>
  );
};

export default ScopeDownStatement;
