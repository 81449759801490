import { useEffect, useMemo, useState } from 'react';
import IconDeleteRed from 'assets/svgs/v3/ico_red_delete.svg';
import LabelInput from 'components/v2/LabelInput';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import Icon from 'components/v2/atoms/Icon';
import IconWarning from 'assets/svgs/v3/ico_warning.svg';
import './index.scss';

interface IDisableLoggingModalProps extends IBaseModalProps {
  onDiableLogging: () => void;
}

const DisableLoggingModal = ({ onDiableLogging, ...baseModalProps }: IDisableLoggingModalProps) => {
  const [payload, setPayload] = useState<string>('');

  const ableDisable = useMemo(() => {
    if (payload !== 'disable') {
      return true;
    } else return false;
  }, [payload]);

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload('');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal {...baseModalProps}
      title={() => (
        <>
          <Icon width={32} height={32} src={IconDeleteRed} />
          <div className="title-header-modal">Disable logging</div>
        </>
      )}
    >
      <div className="disable-logging-metric-modal">
        <div className={'content-container flex col'}>
          <div className="header">
            <div className="delete-warning">
              <Icon width={32} height={32} src={IconWarning} />
              <p>Are you sure you want to disable logging?</p>
            </div>
            <div className="delete-info">
              If you disable logging, your web ACL traffic is not logginged in the log group.
            </div>
          </div>
          <LabelInput
            title={'To confirm deletion, type "disable" in the field.'}
            required={true}
            value={payload}
            placeholder={'disable'}
            onChangeValue={val => setPayload(val)}
          />
        </div>
        <div className="button-group">
          <button className="sub-btn" onClick={baseModalProps.onClose}>
            Cancel
          </button>
          <button className="save-btn" disabled={ableDisable} onClick={() => onDiableLogging()}>
            Disable
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DisableLoggingModal;
