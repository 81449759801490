import { DropdownListDataType } from "components/Dropdown/types";
import { TextTypeEnum } from "layouts/v3/MgdTabLayout/configs";

export const DETAIL_KEYS = ['natGatewayId', 'connectivityType', 'state', 'natGatewayArn', 'primaryPublicIpv4Address', 'primaryPrivateIpv4Address',
                            'primaryNetworkInterfaceId', 'vpcId', 'subnetId', 'createTime', 'deleted'];
export const DETAIL_TEXT_DESCRIPTION: {id: string, value: string, type: string}[] = [
  {id: 'natGatewayId', value: 'NAT gateway ID', type: TextTypeEnum.COPY},
  {id: 'connectivityType', value: 'Connectivity type', type: TextTypeEnum.NORMAL},
  {id: 'state', value: 'State', type: TextTypeEnum.STATUS},
  {id: 'natGatewayArn', value: 'NAT gateway ARN', type: TextTypeEnum.NORMAL},
  {id: 'primaryPublicIpv4Address', value: 'Primary public IPv4 address', type: TextTypeEnum.LINK},
  {id: 'primaryPrivateIpv4Address', value: 'Primary private IPv4 address', type: TextTypeEnum.COPY},
  {id: 'primaryNetworkInterfaceId', value: 'Primary network interface ID', type: TextTypeEnum.NORMAL},
  {id: 'vpcId', value: 'VPC', type: TextTypeEnum.LINK},
  {id: 'subnetId', value: 'Subnet', type: TextTypeEnum.LINK},
  {id: 'createTime', value: 'Created', type: TextTypeEnum.NORMAL},
  {id: 'deleted', value: 'Deleted', type: TextTypeEnum.NORMAL},
];

export const NAT_GW_SUMMARY_TAB_LIST = [
  {id: 'details', name: 'Details', title: 'Details'},
  {id: 'secondaryIpv4Addresses', name: 'Secondary IPv4 addresses', title: 'Secondary IPv4 addresses'},
  {id: 'tags', name: 'Tags', title: 'Tags'}
];

export const DETAIL_TAB_LIST = [
  {id: 'secondaryIpv4Addresses', name: 'Secondary IPv4 addresses', title: 'Secondary IPv4 addresses'},
  {id: 'tags', name: 'Tags', title: 'Tags'}
];

export const NAT_GW_FILTER_DROPDOWN: DropdownListDataType[] = [
  {id: 1, name: 'NAT gateway ID', value: 'nat-gateway-id'},
  {id: 2, name: 'Name', value: 'tag:Name'},
  {id: 3, name: 'VPC', value: 'vpc-id'},
  {id: 4, name: 'Subnet ID', value: 'subnet-id'}
];
