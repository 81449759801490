import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsGetPolicyResponse } from 'graphql/types/AwsGetPolicyResponse';

export interface IAwsPolicyVariables {
  cloudId: number;
  region: string;
  request: {
    policyArn: string;
  };
}

export interface IAwsPolicyResponseData {
  getAwsPolicy: IGqlResponseNewData<AwsGetPolicyResponse>;
}

const lazyGetAwsPolicy = () =>
  useLazyQuery<IAwsPolicyResponseData, IAwsPolicyVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsPolicy;
