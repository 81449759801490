import { enumFormatter } from 'utils/Formatter';
import './index.scss';

import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import deleteProjectCloud from 'graphql/mutations/deleteProjectCloud';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType } from '@Types/v2/Table';

interface IDeleteCloudModalProps extends IBaseModalProps {
  data: { 
    name: string; 
    serviceType: string; 
    account: string;
  }[];
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteCloudModal = ({
  data,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteCloudModalProps) => {
  const column:ColumnType[] = [{
    field: 'name'
  },{
    field: 'serviceType'
  },{
    field: 'account'
  }];

  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-cloud-modal">
        
        <div className="row-1">
        Check your related clouds. Deleted related clouds is not restored.
        </div>
        <div className="row-2">
          <Table columns={column} rows={data}/>
        </div>
        <div className="row-3 flex j-end">
          <button 
            className="big-main-btn" 
            onClick={onDelete}
          >
            Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteCloudModal;
