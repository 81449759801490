import DetailTable from '../../../DetailTable';
import { ColumnType } from '@Types/v2/Table';
import lazyGetAwsRouteTables from 'graphql/queries/getAwsRouteTables';
import { useEffect, useState } from 'react';

function RouteTable({ cloudId, region, data = {} }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  const { subnetId } = data;

  const [getAwsRouteTables] = lazyGetAwsRouteTables();

  const handleGetData = async () => {
    try {
      setIsLoading(true);
      let arr: any = [];

      const variables = {
        cloudId,
        region,
        request: {
          maxResults: 50,
          filters: {
            name: 'association.subnet-id',
            values: [subnetId],
          },
        },
      };

      const { data: subnetData } = await getAwsRouteTables({ variables });

      subnetData?.getAwsRouteTables?.data?.[0]?.routeTables[0]?.routes?.map(item => {
        arr.push({
          destination: item.destinationCidrBlock,
          target: item.gatewayId,
        });
      });
      setDataTable(arr);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const columns: ColumnType[] = [
    { label: 'Destination', field: 'destination', sort: true },
    { label: 'Target', field: 'target', sort: true },
  ];

  return <DetailTable title="RouteTable" data={dataTable} columns={columns} isLoading={isLoading} />;
}

export default RouteTable;
