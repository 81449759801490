import { UserTooltipUserInfoType } from 'contexts/v2/GlobalModalProvider/types';
import './index.scss';
import { dateFormatter } from 'utils/Formatter';

interface IUserTooltipProps {
  top: number;
  left: number;
  userInfo?: UserTooltipUserInfoType;
}
const UserTooltip = ({ 
  top, 
  left, 
  userInfo 
}:IUserTooltipProps) => {
  if (userInfo === undefined) return <></>;
  
  return (
    <div 
      className="user-tooltip-comp" 
      style={{ 
        top: top, 
        left: left 
      }}
    >
      <div className="header flex a-center">
        <div 
          className="thumbnail" 
          {...(userInfo.thumbnail && userInfo.thumbnail !== '' && { 
            style: { backgroundImage: `url('${userInfo.thumbnail}')` } 
          })}
        />
        {userInfo.fullName}<span>({userInfo.userId})</span>
      </div>
      <div className="last-login flex j-center a-center">
        Last log in: { userInfo.createdAt === '-' ? 'unknown' : dateFormatter(userInfo.createdAt, 'date')}
      </div>
    </div>
  );
};
export default UserTooltip;
