import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { ProjectMemberInfo } from 'graphql/types/ProjectMemberInfo';

export interface IUpdateProjectMemberVariables {
  reqUpdProjectMember: {
    memberId: string;
    projectId: number;
    roleCode?: string;
    // smsAlertYn?: boolean;
    // emailAlertYn?: boolean;
    alertYn?: boolean;
    statusCode?: string;
    //email: string;  피그마상으로 일반유저와 달리 lg운영자는 email을 그냥 수정할 수 있음.
  }
}

export interface IUpdateProjectMemberResponseData {
  updateProjectMember: IGqlResponseData<ProjectMemberInfo>;
}

/** 멤버 정보 수정 (내 프로필 수정과 다름) */
const updateProjectMember = () => useMutation<IUpdateProjectMemberResponseData, IUpdateProjectMemberVariables>(query);
export default updateProjectMember;