import { useMemo, useState } from 'react';
import './index.scss';

import LabelInput from 'components/v2/LabelInput';
import { checkEmail } from 'utils/v2/ValidCheck';
import LabelTextarea from 'components/v2/LabelTextarea';
import { numberRegex } from 'utils/Regex';

/** 
 * <>영업팀에 문의 하는 페이지</>
 */
const Inquiry = () => {

  const [payload, setPayload] = useState({
    contactName: '',
    contactEmail: '',
    contactMobile: '',
    companyName: '',
    text: ''
  });

  const enableSubmitButton = useMemo(() => {
    if (
      payload.contactName === '' ||
      checkEmail(payload.contactEmail) || 
      payload.contactMobile === '' ||
      payload.companyName === ''
    ) { return true; } else return false;
  }, [payload])

  return (
    <div 
      id="inquiry-page" 
      className='flex j-center a-center'
    >
      <div className='inquiry-wrap'>
        <div className='header flex a-center'>
          <div className='inquiry-icon' />
          Contact sales
        </div>
        <div className='contents'>
          <LabelInput 
            title="Name" 
            placeholder='Input name'
            required
            value={payload.contactName}
            onChangeValue={str => {
              setPayload(prev => ({
                ...prev,
                contactName: str
              }))
            }}
          />
          <LabelInput 
            title="Email" 
            placeholder='Input email'
            required
            value={payload.contactEmail}
            onChangeValue={str => {
              setPayload(prev => ({
                ...prev,
                contactEmail: str
              }))
            }}
          />
          <LabelInput 
            title="Mobile" 
            placeholder='Input mobile number'
            required
            value={payload.contactMobile}
            onChangeValue={str => {
              setPayload(prev => ({
                ...prev,
                contactMobile: str.replace(numberRegex, '')
              }))
            }}
          />
          <LabelInput 
            title="Company name" 
            placeholder='Input company name'
            required
            value={payload.companyName}
            onChangeValue={str => {
              setPayload(prev => ({
                ...prev,
                companyName: str
              }))
            }}
          />
          <LabelTextarea 
            title="Questions" 
            placeholder='Input questions'
            value={payload.text}
            onChangeValue={str => {
              setPayload(prev => ({
                ...prev,
                text: str
              }))
            }}
          />
        </div>
        <div className='btns flex j-end'>
          <button 
            className='big-main-btn'
            disabled={ enableSubmitButton }
            onClick={() => console.log('등록 완료')}
          >
            Done
          </button>
        </div>

      </div>
    </div>
  )
}

export default Inquiry;