import { gql } from '@apollo/client';

const query = gql`
  mutation updateDashboard($reqData: ReqUpdDashboard) {
  updateDashboard(reqData: $reqData) {
    result
    data {
      ... on Dashboard {
        id
        name
        templateId
        organId
        parameters
      }
    }
  }
}
`;

export default query;