import './style.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import Table from 'components/v2/dataDisplay/Table';
import lazyGetAwsGenerateServiceLastAccessedDetails, {
  IAwsGenerateServiceLastAccessedDetailsVariables,
} from 'graphql/queries/getAwsGenerateServiceLastAccessedDetails';
import lazyGetAwsServiceLastAccessedDetails, {
  IAwsServiceLastAccessedDetailsVariables,
} from 'graphql/queries/getAwsServiceLastAccessedDetails';
import lazyGetAwsServiceLastAccessedDetailsWithEntities, {
  IAwsServiceLastAccessedDetailsWithEntitiesVariables,
} from 'graphql/queries/getAwsServiceLastAccessedDetailsWithEntities';

import { PolicyDetailPropsType } from '../../types';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';

const AccessAdvisor = (props: PolicyDetailPropsType) => {
  const { data, cloudId, region } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [getAwsGenerateServiceLastAccessedDetails] = lazyGetAwsGenerateServiceLastAccessedDetails();
  const [getAwsServiceLastAccessedDetails] = lazyGetAwsServiceLastAccessedDetails();
  const [getAwsServiceLastAccessedDetailsWithEntities] = lazyGetAwsServiceLastAccessedDetailsWithEntities();

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Service',
        field: 'service',
        sort: true,
      },
      {
        label: 'Last accessed',
        field: 'lastAccessed',
        sort: true,
      },
      {
        label: 'Access by entities',
        field: 'accessByEntities',
        sort: true,
      },
    ];
  }, []);

  const getBetweenTwoDate = (value: any) => {
    if (!value) return 'Not accessed in the tracking period';

    const currentDate = new Date();
    const prevTime = new Date(value);

    // @ts-ignore
    const diffMs = currentDate - prevTime;
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      return 'Today';
    } else if (diffDays === 1) {
      return 'Yesterday';
    } else {
      return `${diffDays} days ago`;
    }
  };

  const handleGetAwsListGroupsForUser = (arn: string) => {
    const requestVariable: IAwsGenerateServiceLastAccessedDetailsVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        arn,
      },
    };
    return getAwsGenerateServiceLastAccessedDetails({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsServiceLastAccessedDetails = (jobId: string) => {
    const requestVariable: IAwsServiceLastAccessedDetailsVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        jobId,
      },
    };
    return getAwsServiceLastAccessedDetails({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsServiceLastAccessedDetailsWithEntities = (jobId: string, serviceNamespace: string) => {
    const requestVariable: IAwsServiceLastAccessedDetailsWithEntitiesVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        jobId,
        serviceNamespace,
      },
    };
    return getAwsServiceLastAccessedDetailsWithEntities({ variables: variableCombineNextToken(requestVariable) });
  };

  const fetchData = useCallback(async () => {
    try {
      if (!data?.arn) return;

      setIsLoading(true);
      const result = await handleGetAwsListGroupsForUser(data.arn);

      if (!result?.data?.getAwsGenerateServiceLastAccessedDetails?.data?.length) {
        setIsLoading(false);
        return;
      }

      const totalResult: RowType[] = [];

      result.data.getAwsGenerateServiceLastAccessedDetails.data.forEach(async e => {
        let jobStatus;
        let servicesLastAccessed: any;
        do {
          const serviceLastAccessedDetailsResponse = await handleGetAwsServiceLastAccessedDetails(e.jobId);
          const serviceLastAccessedDetailsData =
            serviceLastAccessedDetailsResponse?.data?.getAwsServiceLastAccessedDetails?.data?.[0];
          jobStatus = serviceLastAccessedDetailsData?.jobStatus;
          servicesLastAccessed = serviceLastAccessedDetailsData?.servicesLastAccessed;
        } while (jobStatus != 'COMPLETED');

        if (!servicesLastAccessed?.length) {
          setIsLoading(false);
          return;
        }

        for (let i = 0; i < servicesLastAccessed.length; i++) {
          const servicesLastAccessedItem = servicesLastAccessed[i];
          const serviceLastAccessedDetailsWithEntities = await handleGetAwsServiceLastAccessedDetailsWithEntities(
            e.jobId,
            servicesLastAccessedItem.serviceNamespace,
          );

          const item = {
            service: servicesLastAccessedItem.serviceName,
            lastAccessed: getBetweenTwoDate(servicesLastAccessedItem.lastAuthenticated),
            accessByEntities:
              serviceLastAccessedDetailsWithEntities?.data?.getAwsServiceLastAccessedDetailsWithEntities?.data?.[0]
                ?.entityDetailsList?.[0]?.entityInfo?.name,
          };

          totalResult.push(item);
        }

        setMainTblRows(totalResult);
        setMainTblTotal({
          totalPage: Math.ceil(totalResult.length / tablePagination.itemPerPage),
          totalElement: totalResult.length,
        });
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    }
  }, [tablePagination, data, cloudId, region, mainTblRows]);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [data, mainTblTotal, tablePagination]);

  useEffect(() => {
    fetchData();
  }, [data]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>Allowed services</p>

        <p>IAM reports activity for services and management actions.</p>
      </div>
      {isLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <div className="detail-info-content">
          {!mainRowsCurrentPage.length && !isLoading ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <Table
                rows={mainRowsCurrentPage}
                columns={columns}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
                isLoading={isLoading}
                horizontalScrollable={true}
              />

              {mainRowsCurrentPage?.length && !isLoading ? (
                <div className="fleet-instance pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{mainTblTotal.totalElement}</span>
                  </p>

                  <TableManagePagination
                    ableFetchMore={false}
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page => updateTablePagination('currentPage', page)}
                    totalPage={mainTblTotal.totalPage}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AccessAdvisor;
