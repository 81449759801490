import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { MemberInfo } from 'graphql/types/MemberInfo';

export interface IUpdateMemberVariables {
  reqUpdMember: {
    id: string;
    fullName?: string;
    email?: string;
    mobileNumber?: string;
    ranks?: string;
    description?: string;
    alertYn?: boolean;
    systemAuthCode?: string;
  }
}

export interface IUpdateMemberResponseData {
  updateMember: IGqlResponseData<MemberInfo[]>;
}

/** 내 프로필 또는 lg운영기능을 통한 해당 멤버 정보수정 */
const updateMember = () => useMutation<IUpdateMemberResponseData, IUpdateMemberVariables>(query);
export default updateMember;