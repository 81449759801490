import { gql } from '@apollo/client';

const query = gql`
  query getAwsListPolicyVersions($request: AwsListPolicyVersionsRequest, $cloudId: Long, $region: String) {
    getAwsListPolicyVersions(listPolicyVersionsRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListPolicyVersionsResponse {
          versions {
            document
            versionId
            isDefaultVersion
            createDate
          }
          isTruncated
          marker
        }
      }
    }
  }
`;
export default query;
