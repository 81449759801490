import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsCheckCapacityRequestType, AwsCheckCapacityResponseType } from 'graphql/types/AwsCheckCapacity';

export type GetAwsCheckCapacityVariablesType = {
  cloudId: number;
  region: string;
  request: AwsCheckCapacityRequestType;
};

export type GetAwsCheckCapacityResponseDataType = {
  getAwsCheckCapacity: IGqlResponseNewData<AwsCheckCapacityResponseType>;
};

const lazyGetAwsCheckCapacity = () =>
  useLazyQuery<GetAwsCheckCapacityResponseDataType, GetAwsCheckCapacityVariablesType>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsCheckCapacity;
