import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import NetworkTitle from 'components/v3/NetworkTitle';
import lazyGetAwsIPSet from 'graphql/queries/getAwsIPSet';
import { AwsIPSet } from 'graphql/types/AwsIPSet';
import Table from 'components/v2/dataDisplay/Table';
import { RowType } from '@Types/v2/Table';
import { CLOUDFRONT_OPTION, CLOUDFRONT_REGION, getRegionFromArnsData, WafScopeEnum } from '../../Commons/Constant';
import updateAwsIPSetMutation, { IUpdateAwsIPSetVariables } from 'graphql/mutations/updateIPSet';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import EditIPSetModal from '../EditIPSet';
import AddIPAddressesModal from '../AddIPAddresses';
import DeleteIPAddressesModal from '../DeleteIPAddresses';
import { randomString } from 'utils/Common';

interface IIPSetDetailProps {
  cloudId: number;
  region: string;
  id: string;
  name: string;
  pageBackClick: () => void;
}

const IPSetDetail = ({ cloudId, region, id, name, pageBackClick }: IIPSetDetailProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [lockToken, setLockToken] = useState<string>('');
  const [ipSet, setIpSet] = useState<AwsIPSet>();
  const [ipAddressRows, setIpAddressRows] = useState<RowType[]>([]);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [getAwsIPSet] = lazyGetAwsIPSet();
  const [updateAwsIPSet] = updateAwsIPSetMutation();

  const scope = useMemo((): string => {
    return region === CLOUDFRONT_OPTION.value ? WafScopeEnum.CLOUDFRONT : WafScopeEnum.REGIONAL;
  }, [region]);

  const regionValue = useMemo((): string => {
    return region === CLOUDFRONT_OPTION.value ? CLOUDFRONT_REGION : region;
  }, [region]);

  const getIPSetData = {
    cloudId: cloudId,
    region: regionValue,
    request: {
      id: id,
      name: name,
      scope: scope,
    },
  };

  const getIpSet = () => {
    if (getIPSetData.region) {
      getAwsIPSet({ variables: getIPSetData }).then(({ data }) => {
        if (data) {
          const ipSetResponse = data.getAwsIPSet.data?.[0].ipSet;

          if (ipSetResponse) {
            setIpSet(ipSetResponse);
            setLockToken(data.getAwsIPSet.data?.[0].lockToken);
            if (ipSetResponse.addresses) {
              setIpAddressRows(
                ipSetResponse.addresses.map(ip => {
                  return {
                    id: randomString(),
                    ip: ip,
                  };
                }),
              );
            }
          }
        }
      });
    }
  };

  useEffect(() => {
    getIpSet();
  }, []);

  const handleUpdateAwsIPSet = async (
    reqVariable: IUpdateAwsIPSetVariables,
    successMessage: string,
    failMessage: string,
  ) => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      const { data } = await updateAwsIPSet({ variables: reqVariable });

      const nextLockToken = data?.updateAwsIPSet?.data?.[0]?.nextLockToken;
      if (nextLockToken) {
        getIpSet();
        useToast(ErrorCode.SUCCESS, successMessage);
        setIsOpenEdit(false);
        setIsOpenAdd(false);
        setIsOpenDelete(false);
        setCheckedList([]);
        setSelected('');
      } else {
        useToast(ErrorCode.UNKNOWN, failMessage);
      }
      setIsLoading(false);
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, failMessage);
      setIsLoading(false);
    }
  };

  // Edit IP set info
  const handleIsOpenEdit = () => {
    setIsOpenEdit(true);
  };

  const handleOnCloseEdit = () => {
    setIsOpenEdit(false);
  };

  const handleEdit = (description: string) => {
    if (!ipSet) return;

    const reqVariable: IUpdateAwsIPSetVariables = {
      cloudId: cloudId,
      region: regionValue,
      request: {
        id: ipSet?.id,
        name: ipSet?.name,
        scope: scope,
        lockToken: lockToken,
        description: description || undefined,
        addresses: ipSet?.addresses || [],
      },
    };

    handleUpdateAwsIPSet(reqVariable, 'Edit IP set successful.', 'Edit IP set failed.');
  };

  // Add IP addresses
  const handleIsOpenAdd = () => {
    setIsOpenAdd(true);
  };

  const handleOnCloseAdd = () => {
    setIsOpenAdd(false);
  };

  const handleAdd = (ipAddresses: string) => {
    if (!ipSet) return;

    const ipAddressesList: string[] = [];

    if (ipSet?.addresses) ipAddressesList.push(...ipSet.addresses);
    const ipAddressesUpdate: string[] = ipAddresses?.split('\n').filter(e => e);
    ipAddressesList.push(...ipAddressesUpdate);

    const reqVariable: IUpdateAwsIPSetVariables = {
      cloudId: cloudId,
      region: regionValue,
      request: {
        id: ipSet?.id,
        name: ipSet?.name,
        scope: scope,
        lockToken: lockToken,
        description: ipSet?.description || undefined,
        addresses: ipAddressesList || [],
      },
    };

    handleUpdateAwsIPSet(reqVariable, 'Add IP addresses successful.', 'Add IP addresses failed.');
  };

  // Delete IP addresses
  const isDisabledDelete = !checkedList?.length;

  const ipAddresses = useMemo(() => {
    const arr = checkedList.reduce((res: string[], cur: string) => {
      const item = ipAddressRows.find(e => e.id === cur);

      if (item) res.push(item.ip);

      return res;
    }, []);

    return arr;
  }, [checkedList]);

  const handleIsOpenDelete = () => {
    if (isDisabledDelete) return;

    setIsOpenDelete(true);
  };

  const handleOnCloseDelete = () => {
    setIsOpenDelete(false);
  };

  const handleDelete = () => {
    if (!ipSet) return;

    const ipAddressesUpdate = [...ipSet.addresses].filter(e => !ipAddresses.includes(e));

    const reqVariable: IUpdateAwsIPSetVariables = {
      cloudId: cloudId,
      region: regionValue,
      request: {
        id: ipSet.id,
        name: ipSet.name,
        scope: scope,
        lockToken: lockToken,
        description: ipSet.description || undefined,
        addresses: ipAddressesUpdate || [],
      },
    };

    handleUpdateAwsIPSet(reqVariable, 'Delete IP addresses successful.', 'Delete IP addresses failed.');
  };

  return (
    <>
      <div id="ipset-detail">
        <div className="row-1 flex j-between a-center">
          <div className="flex j-start a-center" id="title">
            <NetworkTitle
              pageTitle={'IP Set: ' + name}
              id={321}
              name={'IP Set: ' + name}
              hasPrefixIcon={false}
              hasFavorite={false}
              pageBackClick={pageBackClick}
            />
          </div>
        </div>
        <div className="resize-container vertical">
          <div className="details">
            <div className="row-3">
              <div className="title flex j-between a-center border-bottom">
                <p>Info</p>
                <div className="flex action a-center">
                  <button className="edit-btn" onClick={handleIsOpenEdit}>
                    Edit
                  </button>
                </div>
              </div>

              <div className="content flex">
                <div className="info border-right">
                  <p>Name</p>
                  <p>{ipSet?.name}</p>
                </div>
                <div className="info border-right padding-left">
                  <p>Region</p>
                  <p>{getRegionFromArnsData(ipSet?.arn)}</p>
                </div>
                <div className="info border-right padding-left">
                  <p>Description</p>
                  <p>{ipSet?.description}</p>
                </div>
                <div className="info padding-left">
                  <p>IP version</p>
                  <p>{ipSet?.ipAddressVersion}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="details">
            <div className="row-3">
              <div className="title flex j-between a-center">
                <div>
                  <p>IP addresses</p>
                </div>
                <div className="flex action a-center">
                  <button className="action-btn" onClick={handleIsOpenDelete} disabled={isDisabledDelete}>
                    Delete
                  </button>
                  <button className="action-btn" onClick={handleIsOpenAdd}>
                    Add IP address
                  </button>
                </div>
              </div>
            </div>
            {ipAddressRows.length == 0 ? (
              <div className="data-grid-wrap">
                <p className="empty-row">Empty</p>
              </div>
            ) : (
              <div className="data-grid-wrap">
                <Table
                  rows={ipAddressRows}
                  columns={[{ label: 'IP addresses', field: 'ip' }]}
                  reportCheckedList={(list: string[]) => {
                    setCheckedList(list);
                  }}
                  reportSelected={(id: string) => {
                    setSelected(id);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {isOpenEdit ? (
        <EditIPSetModal
          open={isOpenEdit}
          description={ipSet?.description || ''}
          onClose={handleOnCloseEdit}
          onSave={handleEdit}
        />
      ) : null}

      {isOpenAdd ? (
        <AddIPAddressesModal
          header={'Add IP addresses to ' + ipSet?.name}
          open={isOpenAdd}
          onClose={handleOnCloseAdd}
          onSave={handleAdd}
        />
      ) : null}

      {isOpenDelete ? (
        <DeleteIPAddressesModal
          resourceName={ipAddresses.join(', ')}
          open={isOpenDelete}
          onClose={handleOnCloseDelete}
          onSave={handleDelete}
        />
      ) : null}
    </>
  );
};
export default IPSetDetail;
