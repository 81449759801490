import { useMemo } from 'react';
import './index.scss';
import Icon from 'components/v2/atoms/Icon';
import RedCircleTag from 'assets/svgs/v2/ico_redcircle_tag.svg';
import RedCircleCard from 'assets/svgs/v2/ico_redcircle_card.svg';
import RedCircleReceipt from 'assets/svgs/v2/ico_redcircle_receipt.svg';

const Payment = () => {
  const cardMenu = useMemo(() => {
    return [
      {
        renderTitle: () => <> <Icon width={32} height={32} src={RedCircleTag} />Plan</>,
        renderContent: () => 
          <div className="flex a-center">
            <div className="level basic">
              Personal
            </div>
            Current plan
          </div>,
        button: 'Upgrade',
        hrefLInk: '/payment/plan'
      },
      {
        renderTitle: () => <> <Icon width={32} height={32} src={RedCircleCard} />Credit</>,
        renderContent: () => <>1234-xxxx-xxxx-1234 </>,
        button: 'Edit',
        hrefLInk: '/payment/card-management'
      },
      {
        renderTitle: () => <> <Icon width={32} height={32} src={RedCircleReceipt} />Billing</>,
        renderContent: () => <>A receipt was issued for October 2023.</>,
        button: 'Details',
        hrefLInk: '/payment/receipt'
      }
    ];
  }, []);

  return (
    <div id="payment-container">
      {
        cardMenu.map((val, idx) => (
          <div
            key={idx} 
            className="payment-card"
          >
            <h5 className="flex a-center">
              {val.renderTitle()}
            </h5>
            <div className="contents flex">
              {val.renderContent()}
            </div>
            <div className={`btns ${val.hrefLInk === '/payment/receipt' && 'disabled'}`}> {/* temp 개발완료전까진 영수증 비활성화 처리하기 */}
              <a 
                href={val.hrefLInk}
                target="_blank"
                className='flex j-center a-center big-main-btn-body2regular'
              >
              {val.button}
            </a>
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default Payment;
