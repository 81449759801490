import Header from 'components/v2/Header';
import UserTitleIcon from 'assets/svgs/v2/ico_user_info_title.svg';
import './index.scss';
import LabelInput from 'components/v2/LabelInput';
import LabelTextarea from 'components/v2/LabelTextarea';
import { useEffect, useState } from 'react';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import { useNavigate } from 'react-router-dom';
import VerticalSplitLinkGroup from 'components/v2/VerticalSplitLinkGroup';
import { termLinks } from 'utils/Links';
import addOrgan from 'graphql/mutations/addOrgan';
import { useAuth } from 'contexts/AuthProvider';
import InvitedCard from './components/InvitedCard';
import lazyGetMemberById from 'graphql/queries/getMemberById';
import lazyGetProjectMemberByMemberId from 'graphql/queries/getProjectMemberByMemberId';
import { MemberProject } from 'graphql/types/MemberProject';

/**
 * <>새 조직 만들기 - 로그인 후 조직이 없는 사람을 위한 페이지 + 조직에서 내보내기 당한 사람도 여기로 와야 함.</>
 */
const JoinOrgan = () => {
  const { userInfo, updateToken, role, logout } = useAuth();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [myInfo, setMyInfo] = useState({ userId: '', email: '' });
  const [inviteList, setInviteList] = useState<MemberProject[]>([]);
  
  /* 조직 만들기 */
  const [createOrganization] = addOrgan();  
  /* 내 정보 조회 (for userId, email) */
  const [getMemberById] = lazyGetMemberById();
  /* 초대된 프로젝트 내역 조회  */
  const [getProjectMemberByMemberId] = lazyGetProjectMemberByMemberId();

  const createOrgan = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const name = e.currentTarget['join-organ-name'].value;
    const info = e.currentTarget['join-organ-info'].value;

    createOrganization({ variables: {
      reqAddOrgan: {
        name: name,
        description: info
      }
    } }).then(({ data }) => {
      if (data) {
        if (data.addOrgan.result === ErrorCode.SUCCESS) {
          useToast(data.addOrgan.result, 'Organization is created successfully.');
          const token = data.addOrgan.data[0].userToken;
          updateToken(token);
          setTimeout(() => navigate(`/organ/${data.addOrgan.data[0].organId}/dashboard/overview`, { replace: true }), 1000);
        } else {
          // todo setError('이미 존재하는 조직 이름입니다.'); 에러 코드 모름
          useToast(data.addOrgan.result);
        }
      } else {
        useToast(ErrorCode.UNKNOWN);
      }
    });
  };

  /* 초대된 프로젝트 조회 */
  const getPjMemberByMemberId = (memId: string) => {
    getProjectMemberByMemberId({ variables: { memberId: memId } }).then(({ data }) => {
      if (data) {
        if (data.getProjectMemberByMemberId){
          setInviteList(data.getProjectMemberByMemberId.data);
        }
      }
    })
  }

  const reloadInviteList = () => {
    if ( role !== 'sy_admin' && userInfo?.nemo.memberId ) {
      getPjMemberByMemberId(userInfo.nemo.memberId);
    }
  };

  useEffect(() => {
    /* 혹여 organId가 있는데도 이 곳을 들어왔다면 강제로 리다이렉트 */
    if (userInfo?.nemo.organId !== null) { 
      navigate(`/organ/${userInfo?.nemo.organId}/dashboard/overview`); 
    } else {
      getMemberById({ variables: { memberId: userInfo.nemo.memberId }}).then(({ data }) => {
        if (data) {
            const result = data.getMemberById.data[0]

            /* 가입단계의 이메일 최종 인증 안 끝낸 상태라면 */
            if (result.statusCode === 'notverif') {
              useToast(ErrorCode.UNKNOWN, '이메일 인증을 먼저 완료해주세요.');
              logout();
            } else {
              setMyInfo({
                userId: result.userId,
                email: result.email
              })
            }
        } else {
          useToast(ErrorCode.UNKNOWN, '사용자 정보를 가져오지 못했습니다.');
        }
      });

      getPjMemberByMemberId(userInfo.nemo.memberId);
    }
  }, [role, userInfo]);

  return (
    <main 
      id="join-organ-page" 
      className="max-size flex-column-center"
    >
      <Header rightType="PROFILE" />
      <section className="max-size flex-column-center">
        <h2>Organization</h2>
        <h3>You can create a new organization or join an existing one.</h3>
          <article className="new-organ-content">
            <div className="flex-center title">
              <img src={UserTitleIcon} width={32} height={32} />Create new organization
            </div>
            <form onSubmit={createOrgan}>
              <LabelInput 
                title="Name" 
                placeholder="Input your organization name" 
                id="join-organ-name" 
                required 
                errorMsg={error} 
                onChangeValue={(value: string) => setName(value)}
              />
              <LabelTextarea 
                title="Description" 
                placeholder="Input organization information" 
                id="join-organ-info" 
                required={false} 
              />
              <button 
                className="big-main-btn" 
                disabled={name === ''}
              >
                Create
              </button>
            </form>
          </article>

          <article className="join-organ-content">
            <div className="flex-center-between">
              <div className="flex-center title">
                <img src={UserTitleIcon} width={32} height={32} />Join organization
              </div>
              <button 
                type="button" 
                className="reload-btn" 
                onClick={reloadInviteList}
              >
                Refresh
              </button>
            </div>
            {inviteList.length === 0 ? 
              <>
                <h4>There are no invitations yet.<br />
                Please request an invitation from the organization owner with the below.</h4>
                <div className="user-info-wrapper">
                  <div>
                    <span>ID</span>
                    {myInfo.userId}
                  </div>
                  <div>
                    <span>email</span>
                    {myInfo.email}
                  </div>
                </div>
              </> 
              : <> <h4>Review and accept the invited organization.</h4>
                {inviteList.map((item, idx) => <InvitedCard key={idx} item={item} /> )}
              </>
            }
          </article>
        <VerticalSplitLinkGroup list={termLinks} />
      </section>
    </main>
  );
};
export default JoinOrgan;