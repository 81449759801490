import './index.scss';
import { ColumnType, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';

interface IDeleteTermModalProps extends IBaseModalProps {
  data: RowType[]
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteTermModal = ({  
  data,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteTermModalProps) => {
  const COLUMNS:ColumnType[] = [
      {
        label: 'Name',
        field: 'title',
        sort: true
      },
      {
        label: 'Version',
        field: 'version',
        sort: true,
      },
      {
        label: 'Mandatory',
        field: 'mandatoryYn',
        sort: true,
        renderCell: (row: RowType) => <>{row.mandatoryYn ? 'Yes' : 'No'}</>
      },
      {
        label: 'Activated',
        field: 'useYn',
        sort: true,
        renderCell: (row: RowType) => <>{row.useYn ? 'Yes' : 'No'}</>
      }
    ];
  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-term-modal">
        <div className="content">
          <Table rows={data} columns={COLUMNS}/>
        </div>
        <div className="btns flex j-end">
          <button 
            className="big-sub-btn flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            Cancel
          </button>
          <button 
            className="big-main-btn flex j-center a-center" 
            onClick={onDelete}
          >
            Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteTermModal;
