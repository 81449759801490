import { useEffect, useRef, useState } from 'react';
import './index.scss';
import ClockIcon from 'assets/svgs/v2/ico_redcircle_clock.svg';
import Icon from 'components/v2/atoms/Icon';
import NotiInvitedCard from './components/NotiInvitedCard';
import { MemberProject } from 'graphql/types/MemberProject';


const NOTI_SAMPLE = [
  {
    thumbnail: '',
    type: 'invite',
    title: '프로젝트 초대',
    text: '#{조직 이름}의 #{프로젝트 명}에서 #{권한}로 초대했습니다.',
  },
  {
    thumbnail: '',
    type: 'invite',
    title: '프로젝트 초대',
    text: 'LG의 산티아고 보안 프로젝트에서 관리자로 초대했습니다.',
  },
  {
    thumbnail: '',
    type: 'notice',
    title: '공지사항',
    text: '서비스 업데이트 알림이 있습니다. 서비스 업데이트 알림이 있습니다. 서비스 업데이트 알림이 있습니다.',
  },
  {
    thumbnail: '',
    type: 'notice',
    title: '공지사항',
    text: '서비스 업데이트 알림이 있습니다. 서비스 업데이트 알림이 있습니다. 서비스 업데이트 알림이 있습니다.',
  }
]

interface INotiModalProps {
  open: boolean;
  onClose: () => void;
  inviteList: MemberProject[];
  /* notiList 공지사항 리스트도 밖에서 가져오기 */
  reloadInviteList: () => void;
}

const NotiModal = ({
  open,
  inviteList,
  /* notiList */
  onClose,
  reloadInviteList
}: INotiModalProps) => {
  const notiModalRef = useRef<HTMLDivElement>(null);

  /** 모달 고유 ID */
  const [id] = useState(Date.now());

  useEffect(() => {
    if (open) {
      document.body.setAttribute(`modal-id-${id}`, 'true');
      notiModalRef.current?.classList.remove('out', 'active');
      notiModalRef.current?.classList.add('active');

      reloadInviteList();

    } else {
      notiModalRef.current?.classList.add('out');
      document.body.removeAttribute(`modal-id-${id}`);
    }
  }, [open]);

  return (
    <div  
      ref={notiModalRef}
      className="noti-modal-container modal-duration-5"
      data-duration={3}
      data-bg-opacity={3}
    >
      <div 
        className="noti-modal-background"
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          if (e.target === e.currentTarget) onClose && onClose();
        }}
      >
        <div className="noti-modal">
          <div className="modal-head flex j-between a-center">
            <div className='title-wrap flex a-center'>
              <Icon width={32} height={32} src={ClockIcon} />
              Notification
            </div>
            <div 
              className='refresh-wrap flex a-center'
              onClick={reloadInviteList}
            >
              <div className='refresh-icon' />
              Refresh
            </div>
          </div>

          <div className="modal-content">
            <div className="content-wrap">
              {
                inviteList.length === 0 /* mark 추후 공지사항 list 도 관여시킬 것 */
                ? <div className='empty-noti flex col j-center a-center'>
                    <div className='empty-icon' />
                    <p>no notification</p>
                  </div> 
                : <div className='noti-list-wrap'>
                { inviteList.map((val, idx) => ( <NotiInvitedCard key={idx} val={val} /> )) }
                </div>
              }
            </div>
            {/* 알림이 5개이상 이라면? */}
            {
              inviteList.length !== 0  &&
              <div className="btns flex j-center">
                <button 
                  className="big-sub-btn flex j-center a-center" 
                  onClick={() => { }}
                >
                  More
                </button>
              </div>
            }
          </div>

        </div>
      </div>
    </div>
  );
};

export default NotiModal;
