import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import DetailTab from '../../../DetailTab';
import { handleLinkClicked } from 'pages/v2/Organ/Management/Utils';
import { useNavigate } from 'react-router-dom';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import { NETWORK_TAB_LIST } from '../../../../configs';
import { subnetFilterDropdown, vpcFilterDropdown } from '../../../../Constants';

function Details({ data }: any) {
  const navigate = useNavigate()

  const { routeTableId, associations, vpcId, ownerId } = data || {};

  const associationsDescription = !associations?.filter((item: any) => item.subnetId)?.length
    ? '-'
    : associations?.length == 1
    ? associations[0].subnetId || '-'
    : associations.map((item: any) => item.subnetId);

  const detailsData: IMgdDetailKeyValueProps[] = [
    {
      title: 'Route table ID',
      description: routeTableId,
      type: TextTypeEnum.COPY,
    },
    {
      title: 'Main',
      description: associations?.length == 1 ? (associations[0].main ? 'Yes' : 'No') : 'No',
    },
    {
      title: 'Explicit subnet associations',
      description: associationsDescription,
      type: associationsDescription === '-' ? TextTypeEnum.NORMAL : TextTypeEnum.LINK,
      isTooltip: true,
      handleItemClick: (value: string) => handleLinkClicked({
        navigate,
        link: '/organ/1/manage/network',
        type: ManagementTypeEnum.NETWORK,
        tabId: NETWORK_TAB_LIST[2].id,
        key: subnetFilterDropdown[0].value.toString(),
        value,
      })
    },
    {
      title: 'VPC',
      description: vpcId,
      type: TextTypeEnum.LINK,
      handleItemClick: () => handleLinkClicked({
        navigate,
        link: '/organ/1/manage/network',
        type: ManagementTypeEnum.NETWORK,
        tabId: NETWORK_TAB_LIST[0].id,
        key: vpcFilterDropdown[0].value.toString(),
        value: vpcId,
      })
    },
    {
      title: 'Owner ID',
      description: ownerId,
      type: TextTypeEnum.COPY,
    },
  ];

  return <DetailTab title="Details" data={detailsData} />;
}

export default Details;
