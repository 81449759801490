import { gql } from '@apollo/client';

const query = gql`
  query getAwsCheckCapacity($request: AwsCheckCapacityRequest, $cloudId: Long, $region: String) {
    getAwsCheckCapacity(checkCapacityRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsCheckCapacityResponse {
          capacity
        }
      }
    }
  }
`;
export default query;
