import { gql } from '@apollo/client';

const query = gql`
  mutation revokeAwsSecurityGroupIngress(
    $request: AwsRevokeSecurityGroupIngressRequest
    $cloudId: Long
    $region: String
  ) {
    revokeAwsSecurityGroupIngress(revokeSecurityGroupIngressRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsRevokeSecurityGroupIngressResponse {
          unknownIpPermissions {
            fromPort
            ipProtocol
            ipRanges {
              cidrIp
              description
            }
            ipv6Ranges {
              cidrIpv6
              description
            }
            toPort
            prefixListIds {
              prefixListId
              description
            }
            userIdGroupPairs {
              description
              groupId
              groupName
              peeringStatus
              userId
              vpcId
              vpcPeeringConnectionId
            }
          }
          returnValue
        }
      }
    }
  }
`;
export default query;
