import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsGetAccessKeyLastUsedResponse } from 'graphql/types/AwsGetAccessKeyLastUsedResponse';

export interface IAwsAccessKeyLastUsedVariables {
  cloudId: number;
  region: string;
  request: {
    accessKeyId: string;
  };
}

export interface IAwsAccessKeyLastUsedResponseData {
  getAwsAccessKeyLastUsed: IGqlResponseNewData<AwsGetAccessKeyLastUsedResponse>;
}

const lazyGetAwsAccessKeyLastUsed = () =>
  useLazyQuery<IAwsAccessKeyLastUsedResponseData, IAwsAccessKeyLastUsedVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsAccessKeyLastUsed;
