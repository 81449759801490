/* eslint-disable quotes */
import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import { useGModal } from 'contexts/v2/GlobalModalProvider';
import MemberDataGrid from './MemberDataGrid';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import EmptyProjectCard from 'pages/v2/Organ/Project/components/cards/EmptyProjectCard';
import InviteMemberModal from '../../InviteMemberModal';
import UserInfo from 'assets/svgs/v2/ico_user_info_title.svg';
import Icon from 'components/v2/atoms/Icon';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import ShareModal from '../../ShareModal';
import updateProjectMember from 'graphql/mutations/updateProjectMember';
import { useAuth } from 'contexts/AuthProvider';
import removeProjectMember from 'graphql/mutations/removeProjectMember';
import delegateOwner from 'graphql/mutations/delegateOwner';
import { OrganMemberType } from '@Types/v4/Member';
import { organMemberList } from 'utils/DummyData/Default';
import { useOutletContext, useParams } from 'react-router-dom';
import Labeled from 'components/v2/atoms/Labeled';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import banishOrganMember from 'graphql/mutations/banishOrganMember';
import lazyGetProjectsByMember, { IGetProjectsByMemberVariables } from 'graphql/queries/getProjectsByMember';

export type selectSettingType = {
  open: {
      id: string;
      fullName: string;
      userId: string;
      roleCode: string;
      projectId: number;
  };
  top: number;
  left: number;
}

interface IMemberProps {
  organId: number;
  projectList: ProjectInfo[];
}

const Member = ({
  organId,
  projectList
}:IMemberProps) => {
  const { userInfo, updateToken, role } = useAuth();
  const { tooltip } = useGModal();
  
  /* 구성원 역할 변경 (소유자 위임과는 별개) */
  const [updatePjMember] = updateProjectMember();
  /* 조직에서 내보내기 */
  const [banishMember] = banishOrganMember();
  /* 조직 소유자 위임하기 */
  const [delegateOrgOwner] = delegateOwner();
  const [lazyGetMemberProjectList] = lazyGetProjectsByMember();

  const [modalIsOpen, setModalIsOpen] = useState({
    invite: false,
    select: false,
    changeShareModal: false,
    target: '',
  });
  const [selectSetting, setSelectSetting] = useState<selectSettingType>({
    open: {
      id: '',
      fullName: '',
      userId: '',
      roleCode: '',
      projectId: -1
    },
    top: 0,
    left: 0
  });

  const [refetch, setRefetch] = useState({
    refresh: false,
    resetPage: false
  });
  const [memberProjectList, setMemberProjectList] = useState<{name: string, value: number}[]>([]);

  const projectListForDropdown = useMemo(() => projectList.map(val => {
    return {
      name: val.name,
      value: val.projectId
    };
  }), [projectList]);

  const changeShareModalData = useMemo(() => {
    if (modalIsOpen.changeShareModal) {
      /* 멤버로 변경 */
      if (modalIsOpen.target === 'member') {
        return {
          title: () => <>Grant member role</>,
          buttonTitle: 'Grant',
          content: () => <div className="grant-modal-content">
          <Labeled
            title="SelectProject"
            required
          >
            <DropdownAtom
              id={'project-dropdown'}
              placeholder="Select project" 
              data={memberProjectList}
              value={memberProjectList.find(li => li.value === selectSetting.open.projectId) || {value: ''}}
              handleClick={(val:DropdownListDataType) => setSelectSetting(prev => ({ ...prev, open: {...prev.open, projectId: Number(val.value)} })) }
            />
          </Labeled>
          Member role will be granted to {selectSetting.open.fullName} ({selectSetting.open.userId}) in {memberProjectList.find(li => li.value === selectSetting.open.projectId)?.name || '-'}.
        </div>,
          onConfirm: () => {
            if(selectSetting.open.projectId !== -1) {
              const data = {
                reqUpdProjectMember: {
                  memberId: selectSetting.open.id,
                  projectId: selectSetting.open.projectId,
                  roleCode: 'pj_membr'
                }
              };
              updatePjMember({ variables: data }).then(({ data }) => {
                if(data) {
                  if (data.updateProjectMember.result === ErrorCode.SUCCESS) {
                    useToast(ErrorCode.SUCCESS, 'Member role is granted successfully.');
                    setModalIsOpen(prev => ({
                      ...prev,
                      changeShareModal: false,
                      target: ''
                    }));
                    setSelectSetting({
                      open: {
                        id: '',
                        fullName: '',
                        userId: '',
                        roleCode: '',
                        projectId: -1
                      },
                      top: 0,
                      left: 0
                    });
                
                    setRefetch(prev => ({
                      ...prev,
                      refresh: true
                    }));

                  } else {
                    console.log(data.updateProjectMember.result);
                    useToast(ErrorCode.SUCCESS, 'Grant member role failed.');
                  }
                } else {
                  useToast(ErrorCode.SUCCESS, 'Grant member role failed.');
                }
              });
            }
          }
        }
      }
      /* 관리자로 변경 */
      else if (modalIsOpen.target === 'admin') {
        return {
          title: () => <>Grant administrator role</>,
          buttonTitle: 'Grant',
          content: () => <div className="grant-modal-content">
            <Labeled
              title="SelectProject"
              required
            >
              <DropdownAtom
                id={'project-dropdown'}
                placeholder="Select project" 
                data={memberProjectList}
                value={memberProjectList.find(li => li.value === selectSetting.open.projectId) || {value: ''}}
                handleClick={(val:DropdownListDataType) => setSelectSetting(prev => ({ ...prev, open: {...prev.open, projectId: Number(val.value)} })) }
              />
            </Labeled>
            Administrator role will be granted to {selectSetting.open.fullName} ({selectSetting.open.userId}) in {memberProjectList.find(li => li.value === selectSetting.open.projectId)?.name || '-'}.
          </div>,
          onConfirm: () => {
            if(selectSetting.open.projectId !== -1) {
              const data = {
                reqUpdProjectMember: {
                  memberId: selectSetting.open.id,
                  projectId: selectSetting.open.projectId,
                  roleCode: 'pj_admin'
                }
              };

              updatePjMember({ variables: data }).then(({ data }) => {
                if(data) {
                  if (data.updateProjectMember.result === ErrorCode.SUCCESS) {
                    useToast(ErrorCode.SUCCESS, 'Administrator role is granted successfully.');
                    setModalIsOpen(prev => ({
                      ...prev,
                      changeShareModal: false,
                      target:''
                    }));
                    setSelectSetting({
                      open: {
                        id: '',
                        fullName: '',
                        userId: '',
                        roleCode: '',
                        projectId: -1
                      },
                      top: 0,
                      left: 0
                    });

                    setRefetch(prev => ({
                      ...prev,
                      refresh: true
                    }));
                  } else {
                    console.log(data.updateProjectMember.result);
                    useToast(ErrorCode.UNKNOWN, 'Grant administrator role failed.');
                  }
                } else {
                  useToast(ErrorCode.UNKNOWN, 'Grant administrator role failed.');
                }
              });
            }
          }
        }
      }
      /* 내보내기 */
      else if (modalIsOpen.target === 'discharge') {
        return {
          title: () => <>Banish member from organization</>,
          buttonTitle: 'Banish',
          content: () => <>If you banish a member, that member will no longer have access to all projects and organization.</>,
          onConfirm: () => {
            const removeMemberData = {
              reqData: {
                organId: organId,
                memberId: selectSetting.open.id,
              }
            }

            banishMember({ variables: removeMemberData }).then(({data}) => {
              if (data) {
                if (data.banishMemberFromOrgan.result === ErrorCode.SUCCESS) {
                  useToast(ErrorCode.SUCCESS, 'Member is banished successfully.');

                  setRefetch({
                    refresh: true,
                    resetPage: true
                  });
                  
                  setModalIsOpen(prev => ({
                    ...prev,
                    changeShareModal: false,
                    target: ''
                  }));
      
                  setSelectSetting({
                    open: {
                      id: '',
                      fullName: '',
                      userId: '',
                      roleCode: '',
                      projectId: -1
                    },
                    top: 0,
                    left: 0
                  });
                } else {
                  useToast(ErrorCode.SUCCESS, 'Member banish failed.');
                }
              } else {
                useToast(ErrorCode.SUCCESS, 'Member banish failed.');
              }
            })
          }
        }
      } 
      /* 위임하기 */
      else if (modalIsOpen.target === 'delegate')  {
        return {
          title: () => <>Transfer organization ownership</>,
          buttonTitle: 'Transfer',
          content: () => <>
            {userInfo?.nemo.organName} ownership will be transfered to {selectSetting.open.fullName} ({selectSetting.open.userId}). {userInfo?.nemo.fullName} will be changed to administrator in all projects.
          </>,
          onConfirm: () => {
            const delegateOwnerData = {
              memberId: selectSetting.open.id,
            }

            delegateOrgOwner({ variables: delegateOwnerData }).then(({ data }) => {
              if(data) {
                if (data.delegateOwner.result === ErrorCode.SUCCESS) {
                  const token = data.delegateOwner.data[0].userToken;
                  updateToken(token);
                  useToast(ErrorCode.SUCCESS, 'Organization owership is transfered successfully.');
                  setModalIsOpen(prev => ({
                    ...prev,
                    changeShareModal: false,
                    target: ''
                  }));
                  setSelectSetting({
                    open: {
                      id: '',
                      fullName: '',
                      userId: '',
                      roleCode: '',
                      projectId: -1
                    },
                    top: 0,
                    left: 0
                  });

                  setRefetch(prev => ({
                    ...prev,
                    refresh: true
                  }));
                } else {
                  console.log(data.delegateOwner.result);
                  useToast(ErrorCode.UNKNOWN, 'Transfer organization owership failed.');
                }
              } else {
                useToast(ErrorCode.UNKNOWN, 'Transfer organization owership failed.');
              }
            })
          }
        }
      }
    }
  }, [modalIsOpen, selectSetting]);
  

  const renderMemberDataGrid = useMemo(() => {
      return <MemberDataGrid 
        organId={organId} 
        openModal={(key:string, value: boolean | string ) => setModalIsOpen(prev => ({
          ...prev,
          [key]: value
        }))}
        selectSetting={selectSetting}
        selectSett={(data: selectSettingType) => setSelectSetting({ ...data })}
        refetch={refetch}
        resetRefetch={() => { 
          setRefetch({
            refresh: false,
            resetPage: false
          })
        }}  
      />;
  }, [
      organId,
      selectSetting,
  ]);
  const getMemberProjectData = useMemo(() => ({
    reqData: selectSetting.open.id
  }), [selectSetting]);

  const getMemberProjectRows = (data:IGetProjectsByMemberVariables) => {
    lazyGetMemberProjectList({ variables: data }).then(({ data }) => {
      if(data) {
        if (data.getProjectsByMember) {
          const result = data.getProjectsByMember.data.map(val => ({
            name: val.name,
            value: val.projectId
          }));
          setMemberProjectList(result);
        }
      }
    }).catch(() => {
      setMemberProjectList([]);
    });
  }
  useEffect(() => {
    if(getMemberProjectData.reqData !== '') {
      getMemberProjectRows(getMemberProjectData);
    }
  },[getMemberProjectData]);

  return (
    <div 
      className="organ-member-container" 
      onScroll={tooltip.close}
    >
      <div className="flex a-center j-end">
        <button 
          className="big-main-btn"
          disabled={role !== 'pj_owner'}
          onClick={() => {
            setModalIsOpen(prev => ({
              ...prev,
              invite: true
            }));
          }}
        >
          Invite 
        </button> 
      </div>
      {renderMemberDataGrid}

      <InviteMemberModal 
        open={modalIsOpen.invite}
        projectList={projectListForDropdown}//project list(id, name 포함)
        title={() => 
          <>
            <Icon width={32} height={32} src={UserInfo} />
            Invite member 
          </>
        } 
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          invite: false,
          target: ''
        }))}
        onInviteSuccess={() => {
          useToast(ErrorCode.SUCCESS, 'Invitation is sent successfully.');
          setModalIsOpen(prev => ({
            ...prev,
            invite: false,
            target: ''
          }));
        }}
      />

      <ShareModal 
        open={modalIsOpen.changeShareModal}
        title={changeShareModalData?.title} 
        content={changeShareModalData?.content} 
        buttonTitle={changeShareModalData?.buttonTitle} 
        onConfirm={changeShareModalData?.onConfirm} 
        onClose={() => {
          setModalIsOpen(prev => ({
            ...prev,
            changeShareModal: false,
            target: ''
          }));
          setSelectSetting({
            open: {
              id: '',
              fullName: '',
              userId: '',
              roleCode: '',
              projectId: -1
            },
            top: 0,
            left: 0
          });
        }}
      />
    </div>
  );
}; 

export default Member;
