import {gql} from '@apollo/client';

const query = gql`
query getAwsListRoles($request: AwsListRolesRequest, $cloudId: Long, $region: String) {
  getAwsListRoles(listRolesRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsListRolesResponse {
       roles {
        path
        roleName
        roleId
        arn
        createDate
        assumeRolePolicyDocument
        description
        maxSessionDuration
        permissionsBoundary {
          permissionsBoundaryType
          permissionsBoundaryArn
        }
        tags {
          key
          value
        }
        roleLastUsed {
          lastUsedDate
          region
        }
      }
        isTruncated
        marker
        }
    }
  }
}
`;
export default query;