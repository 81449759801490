import { gql } from '@apollo/client';

const query = gql`
  query getAwsListAvailableManagedRuleGroupVersions(
    $request: AwsListAvailableManagedRuleGroupVersionsRequest
    $cloudId: Long
    $region: String
  ) {
    getAwsListAvailableManagedRuleGroupVersions(
      listAvailableManagedRuleGroupVersionsRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsListAvailableManagedRuleGroupVersionsResponse {
          nextMarker
          versions {
            name
            lastUpdateTimestamp
          }
          currentDefaultVersion
        }
      }
    }
  }
`;
export default query;
