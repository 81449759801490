import leaveOrgan from 'graphql/mutations/leaveOrgan';
import './index.scss';

import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import { useAuth } from 'contexts/AuthProvider';

interface ILeaveOrganizationModalProps extends IBaseModalProps {
  title: () => JSX.Element;
  onLeaveSuccess: () => void;
  organId: number;
  organName: string;
  memberId: string;
}

const LeaveOrganizationModal = ({
  title,
  onLeaveSuccess,
  organId,
  organName,
  memberId,
  ...baseModalProps
}:ILeaveOrganizationModalProps) => {

  const { updateToken } = useAuth();

  /* 조직나가기 */
  const [leaveOrg] = leaveOrgan();

  return (
    <BaseModal 
      {...baseModalProps}
      title={title}
    >
      <div className="leave-organization-modal">
        <h5>
        If you leave an organization, you leave all projects you were involved in.
        </h5>
        <div className="btns flex j-end">
          <button 
            className="big-sub-btn flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            Cancel
          </button>
          <button 
            className="dest-btn flex j-center a-center" 
            onClick={() => {

              const leaveOrganData = {
                reqData: {
                  organId: organId,
                  memberId: memberId
                }
              }

              /* 조직 나가기 */
              leaveOrg({ variables: leaveOrganData }).then(({ data }) => {
                if (data) {
                  if(data.leaveOrgan.result === ErrorCode.SUCCESS) {
                    updateToken(data.leaveOrgan.data[0].userToken);
                    useToast(ErrorCode.SUCCESS, 'Leaving the organization has been completed.');

                    onLeaveSuccess();
                  } else {
                    console.log(data.leaveOrgan.result)
                    useToast(ErrorCode.SUCCESS, 'Leaving the organization failed.');
                  }
                }
              })
            }}
          >
            Leave
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default LeaveOrganizationModal;
