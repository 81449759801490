import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BODY_CONTENT_TO_INSPECT,
  CONTENT_TO_INSPECT,
  CONTENT_TYPE,
  DEFAULT_CUSTOM_RULE,
  DEFAULT_REQUEST_AGGREGATION_KEY_ITEM,
  DEFAULT_STATEMENT_ITEM,
  HEADERS_MATCH_SCOPE,
  IP_ADDRESS_SELECTOR,
  JA3_FINGERPRINT,
  JSON_MATCH_SCOPE,
  JSON_REQUEST,
  MATCH_SCOPE,
  MISSING_IP_ADDRESS,
  POSITION_INSIDE_HEADER,
  RULE_BUILDER_REQUEST_CONDITION_DATA,
  RULE_BUILDER_STATEMENT_INSPECT_DATA,
  RULE_EDITOR_SELECTOR,
  RULE_SUB_TYPE_SELECTOR,
  RuleEditorSelectorEnum,
  RuleSubTypeSelectorEnum,
} from 'pages/v2/Organ/Management/WAF/CustomRuleModal/constant';
import VisualEditor from 'pages/v2/Organ/Management/WAF/CustomRuleModal/RuleBuilder/VisualEditor';
import Json from 'pages/v2/Organ/Management/WAF/CustomRuleModal/RuleBuilder/Json';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import _ from 'lodash';
import { AwsRuleGroup } from 'graphql/types/AwsRuleGroup';
import { EVALUATION_WINDOW, INSPECTION_AND_RATE_LIMIT, REQUEST_AGGREGATION } from './VisualEditor/RateBased/constant';
import { AwsRulelogType, AwsStatementlogType } from 'graphql/types/AwsUpdateRuleGroup';
import { AwsRulelogType as AwsRulelogFromCheckCapacityRequestType } from 'graphql/types/AwsCheckCapacity';
import lazyGetAwsValidateTemplate from 'graphql/queries/getAwsValidateTemplate';
import { WafScopeEnum } from '../../Commons/Constant';
import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';
import InputValidIcon from 'assets/svgs/ic_green_check.svg';
import './styles.scss';
import lazyGetAwsCheckCapacity from 'graphql/queries/getAwsCheckCapacity';
import { COUNTRY_CODES_DATA } from '../countryCodes';
import { pascalCaseKeys } from 'utils/Json';

type RuleBuilderPropsType = {
  cloudId: number;
  selectedRegion: DropdownListDataType;
  setRule: (rule: any) => void;
  rule: AwsRulelogType;
  webAcl: any;
  errors: {
    [key: string]: string;
  };
  onError: () => void;
  validateInputs: () => boolean;
  handleValidateRule: () => Promise<boolean>;
  validateLoading?: boolean;
  ipSets: any;
};

const RuleBuilder = (props: RuleBuilderPropsType) => {
  const {
    cloudId,
    selectedRegion,
    setRule,
    rule,
    webAcl,
    errors,
    onError,
    validateInputs,
    validateLoading,
    handleValidateRule,
    ipSets,
  } = props;

  const [validateTemplate, { loading: validateTemplateLoading }] = lazyGetAwsValidateTemplate();
  const [checkCapacity, { loading: checkCapacityLoading }] = lazyGetAwsCheckCapacity();

  const [ruleEditorSelector, setRuleEditorSelector] = useState(RULE_EDITOR_SELECTOR[0].value);
  const [statements, setStatements] = useState([{ ...DEFAULT_STATEMENT_ITEM }]);
  const [ruleSubType, setRuleSubType] = useState(RuleSubTypeSelectorEnum.REGULAR);
  const [dropdownConditionRequestValue, setDropdownConditionRequestValue] = useState(
    RULE_BUILDER_REQUEST_CONDITION_DATA[0],
  );
  const [isEnableBlockCustomResponse, setEnableBlockCustomResponse] = useState(false);
  const [customHeaderList, setCustomHeaderList] = useState<Array<any>>([]);
  const [labelList, setLabelList] = useState<Array<any>>([]);
  const [action, setAction] = useState('block');
  const [statementData, setStatementData] = useState(statements);
  const [rateLimit, setRateLimit] = useState('');
  const [evaluationWindowSec, setEvaluationWindowSec] = useState(EVALUATION_WINDOW[0]);
  const [requestAggregationKeyType, setRequestAggregationKeyType] = useState(REQUEST_AGGREGATION[0].value);
  const [scopeOfInspectionAndRateLimiting, setScopeOfInspectionAndRateLimiting] = useState(
    INSPECTION_AND_RATE_LIMIT[0].value,
  );
  const [headerFieldName, setHeaderFieldName] = useState('X-Forwarded-For');
  const [fallbackBehavior, setFallbackBehavior] = useState('MATCH');
  const [ruleName, setRuleName] = useState('');
  const [seconds, setSeconds] = useState('300');
  const [isCustomImmunityTime, setIsCustomImmunityTime] = useState(false);
  const [inspectionAndRateLimit, setInspectionAndRateLimit] = useState(INSPECTION_AND_RATE_LIMIT[0].value);
  const [ruleError, setRuleError] = useState('');
  const [ruleValid, setRuleValid] = useState('');

  const [requestAggregationKeyData, setRequestAggregationKeyData] = useState([
    { ...DEFAULT_REQUEST_AGGREGATION_KEY_ITEM },
  ]);

  const [jsonRule, setJsonRule] = useState({});

  const getInitialValue = useCallback(() => {
    let _dropdownConditionRequestValue = RULE_BUILDER_REQUEST_CONDITION_DATA[0];
    let _statements: any = [{ ...DEFAULT_STATEMENT_ITEM }];
    if (rule?.statement?.andStatement) {
      _dropdownConditionRequestValue = RULE_BUILDER_REQUEST_CONDITION_DATA[1];
      _statements = rule?.statement?.andStatement?.statements?.map(statement => {
        if (statement?.notStatement) {
          return {
            id: _.uniqueId('statement'),
            statementResults: true,
            inspect: {
              name: RULE_BUILDER_STATEMENT_INSPECT_DATA.find(
                data => data.value === _.camelCase(Object.keys(statement)?.[0]),
              )?.name,
              value: _.camelCase(Object.keys(statement)?.[0]),
            },
            countryCode: [],
            ipAddress: IP_ADDRESS_SELECTOR[0].value,
            headerFieldName: 'X-Forwarded-For',
            positionInsideHeader: POSITION_INSIDE_HEADER[0].value,
            missingIpAddress: MISSING_IP_ADDRESS[0].value,
            ipSet: '',
            matchScope: MATCH_SCOPE[0].value,
            matchKey: '',
            matchType: statement?.byteMatchStatement?.positionalConstraint ?? '',
            size: '',
            transformation: [
              {
                id: (Math.random() + 1).toString(36).substring(2, 8),
                value: '',
              },
            ],
            headerMatchScope: HEADERS_MATCH_SCOPE[0].value,
            contentToInspect: CONTENT_TO_INSPECT[0].value,
            keys: '',
            oversizeHandling: '',
            queryArgument: '',
            contentType: CONTENT_TYPE[0].value,
            jsonMatchScope: JSON_MATCH_SCOPE[0].value,
            jsonRequest: JSON_REQUEST[0].value,
            bodyContentToInspect: BODY_CONTENT_TO_INSPECT[0].value,
            includedElements: '',
            stringToMatch: '',
            ja3Fingerprint: JA3_FINGERPRINT[0].value,
          };
        }

        return {
          id: _.uniqueId('statement'),
          statementResults: false,
          inspect: {
            name: RULE_BUILDER_STATEMENT_INSPECT_DATA.find(
              data => data.value === _.camelCase(Object.keys(statement)?.[0]),
            )?.name,
            value: _.camelCase(Object.keys(statement)?.[0]),
          },
          countryCode:
            statement?.geoMatchStatement?.countryCodes?.map(code =>
              COUNTRY_CODES_DATA.find(data => data.value === code),
            ) ?? [],
          ipAddress: statement?.ipSetReferenceStatement?.ipSetForwardedIPConfig
            ? IP_ADDRESS_SELECTOR[1].value
            : IP_ADDRESS_SELECTOR[0].value,
          headerFieldName: statement?.ipSetReferenceStatement?.ipSetForwardedIPConfig?.headerName ?? 'X-Forwarded-For',
          positionInsideHeader:
            POSITION_INSIDE_HEADER.find(
              item => item.value === statement?.ipSetReferenceStatement?.ipSetForwardedIPConfig?.position,
            ) ?? POSITION_INSIDE_HEADER[0].value,
          missingIpAddress:
            MISSING_IP_ADDRESS.find(
              item => item.value === statement?.ipSetReferenceStatement?.ipSetForwardedIPConfig?.fallbackBehavior,
            ) ?? MISSING_IP_ADDRESS[0].value,
          ipSet: statement?.ipSetReferenceStatement?.arn ?? '',
          matchScope: statement?.labelMatchStatement?.scope ?? MATCH_SCOPE[0].value,
          matchKey: statement?.labelMatchStatement?.key ?? '',
          matchType: '',
          size: '',
          transformation: statement?.byteMatchStatement?.textTransformations?.map(transformation => ({
            id: _.uniqueId('textTransformation-'),
            value: transformation?.type,
          })) ?? [
            {
              id: (Math.random() + 1).toString(36).substring(2, 8),
              value: '',
            },
          ],
          headerMatchScope:
            statement?.byteMatchStatement?.fieldToMatch?.headers?.matchScope ?? HEADERS_MATCH_SCOPE[0].value,
          contentToInspect:
            Object.keys(statement?.byteMatchStatement?.fieldToMatch?.headers?.matchPattern ?? {})?.[0] ??
            CONTENT_TO_INSPECT[0].value,
          keys: '',
          oversizeHandling: '',
          queryArgument: '',
          contentType: CONTENT_TYPE[0].value,
          jsonMatchScope: JSON_MATCH_SCOPE[0].value,
          jsonRequest: JSON_REQUEST[0].value,
          bodyContentToInspect: BODY_CONTENT_TO_INSPECT[0].value,
          includedElements: '',
          stringToMatch: '',
          ja3Fingerprint: JA3_FINGERPRINT[0].value,
        };
      });
    }
    if (rule?.statement?.orStatement) {
      _dropdownConditionRequestValue = RULE_BUILDER_REQUEST_CONDITION_DATA[2];
    }
    if (rule?.statement?.notStatement) {
      _dropdownConditionRequestValue = RULE_BUILDER_REQUEST_CONDITION_DATA[3];
    }
    setDropdownConditionRequestValue(_dropdownConditionRequestValue);
    setStatementData(_statements);

    let _ruleSubType = RuleSubTypeSelectorEnum.REGULAR;
    if (rule?.statement?.rateBasedStatement) {
      _ruleSubType = RuleSubTypeSelectorEnum.RATE_BASED;
    }
    setRuleSubType(_ruleSubType);

    let _ruleName = '';
    if (rule?.name && !_.isEmpty(rule?.name)) {
      _ruleName = rule?.name;
    }
    setRuleName(_ruleName);

    let _action = 'block';
    if (rule?.action?.allow) {
      _action = 'allow';
    }
    if (rule?.action?.captcha) {
      _action = 'captcha';
    }
    if (rule?.action?.challenge) {
      _action = 'challenge';
    }
    if (rule?.action?.count) {
      _action = 'count';
    }
    setAction(_action);
  }, [rule]);

  useEffect(() => {
    getInitialValue();
  }, []);

  const handleChangeRuleValue = useCallback((_rule: AwsRulelogType) => {
    setJsonRule(pascalCaseKeys(_rule));
    setRule(_rule);
  }, []);

  const getStatementData = useCallback((statementItem: any) => {
    let newStatement: AwsStatementlogType = {};

    if (statementItem?.inspect?.value === 'geoMatchStatement') {
      newStatement = {};
      newStatement.geoMatchStatement = {};
      newStatement.geoMatchStatement.countryCodes = statementItem?.countryCode?.map((code: any) => code.value);
    }

    if (statementItem?.inspect?.value === 'ipSetReferenceStatement') {
      newStatement = {};
      newStatement.ipSetReferenceStatement = {};
      newStatement.ipSetReferenceStatement.arn = statementItem?.ipSet?.value;

      if (statementItem?.ipAddress === IP_ADDRESS_SELECTOR[1].value) {
        newStatement.ipSetReferenceStatement.ipSetForwardedIPConfig = {
          headerName: statementItem?.headerFieldName,
          fallbackBehavior: statementItem?.missingIpAddress,
          position: statementItem?.positionInsideHeader,
        };
      } else {
        delete newStatement.ipSetReferenceStatement.ipSetForwardedIPConfig;
      }
    }

    if (statementItem?.inspect?.value === 'labelMatchStatement') {
      newStatement = {};
      newStatement.labelMatchStatement = {
        scope: statementItem?.matchScope,
        key: statementItem?.matchKey,
      };
    }

    if (statementItem?.inspect?.value === 'SINGLE_HEADER') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {
        singleHeader: {
          name: statementItem?.headerFieldName,
        },
      };
      newStatement.byteMatchStatement.searchString = statementItem?.headerFieldName;
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );
    }

    if (statementItem?.inspect?.value === 'ALL_HEADER') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {};
      newStatement.byteMatchStatement.searchString = statementItem?.stringToMatch;
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );

      if (statementItem?.contentToInspect === 'all') {
        newStatement.byteMatchStatement.fieldToMatch = {
          headers: {
            matchScope: statementItem?.headerMatchScope,
            matchPattern: { all: {} },
            oversizeHandling: statementItem?.oversizeHandling?.value,
          },
        };
      }

      if (statementItem?.contentToInspect === 'includedHeaders') {
        newStatement.byteMatchStatement.fieldToMatch = {
          headers: {
            matchScope: statementItem?.headerMatchScope,
            matchPattern: { includedHeaders: statementItem?.keys.split('\n') },
            oversizeHandling: statementItem?.oversizeHandling?.value,
          },
        };
      }

      if (statementItem?.contentToInspect === 'excludedHeaders') {
        newStatement.byteMatchStatement.fieldToMatch = {
          headers: {
            matchScope: statementItem?.headerMatchScope,
            matchPattern: { excludedHeaders: statementItem?.keys.split('\n') },
            oversizeHandling: statementItem?.oversizeHandling?.value,
          },
        };
      }
    }

    if (statementItem?.inspect?.value === 'COOKIES') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {};
      newStatement.byteMatchStatement.searchString = statementItem?.stringToMatch;
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );

      if (statementItem?.contentToInspect === 'all') {
        newStatement.byteMatchStatement.fieldToMatch = {
          cookies: {
            matchScope: statementItem?.headerMatchScope,
            matchPattern: { all: {} },
            oversizeHandling: statementItem?.oversizeHandling?.value,
          },
        };
      }

      if (statementItem?.contentToInspect === 'includedHeaders') {
        newStatement.byteMatchStatement.fieldToMatch = {
          cookies: {
            matchScope: statementItem?.headerMatchScope,
            matchPattern: { includedCookies: statementItem?.keys.split('\n') },
            oversizeHandling: statementItem?.oversizeHandling?.value,
          },
        };
      }

      if (statementItem?.contentToInspect === 'excludedHeaders') {
        newStatement.byteMatchStatement.fieldToMatch = {
          cookies: {
            matchScope: statementItem?.headerMatchScope,
            matchPattern: { excludedCookies: statementItem?.keys.split('\n') },
            oversizeHandling: statementItem?.oversizeHandling?.value,
          },
        };
      }
    }

    if (statementItem?.inspect?.value === 'SINGLE_QUERY') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {
        singleQueryArgument: {
          name: statementItem?.headerFieldName,
        },
      };
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );
    }

    if (statementItem?.inspect?.value === 'ALL_QUERY') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {
        allQueryArguments: {},
      };
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );
    }

    if (statementItem?.inspect?.value === 'URI_PATH') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {
        uriPath: '',
      };
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );
    }

    if (statementItem?.inspect?.value === 'QUERY_STRING') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {
        queryString: '',
      };
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );
    }

    if (statementItem?.inspect?.value === 'BODY') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {};

      if (statementItem?.contentType === 'plainText') {
        newStatement.byteMatchStatement.fieldToMatch = {
          body: {
            oversizeHandling: statementItem?.oversizeHandling?.value,
          },
        };
      }

      if (statementItem?.contentType === 'json') {
        newStatement.byteMatchStatement.fieldToMatch = {
          jsonBody: {
            matchScope: statementItem?.jsonMatchScope,
            matchPattern: { all: {} },
            invalidFallbackBehavior: statementItem?.jsonRequest,
            oversizeHandling: statementItem?.oversizeHandling?.value,
          },
        };
      }

      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );
    }

    if (statementItem?.inspect?.value === 'HTTP_METHOD') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {
        method: '',
      };
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.searchString = statementItem?.stringToMatch;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );
    }

    if (statementItem?.inspect?.value === 'JA3_FIGERPRINT') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {
        ja3Fingerprint: {
          fallbackBehavior: statementItem?.ja3Fingerprint,
        },
      };
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.searchString = statementItem?.stringToMatch;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );
    }

    return newStatement;
  }, []);

  const customRule = useMemo(() => {
    let newRule = { ...DEFAULT_CUSTOM_RULE } as AwsRulelogType;

    newRule.name = ruleName;

    newRule.visibilityConfig = {
      sampledRequestsEnabled: true,
      cloudWatchMetricsEnabled: true,
      metricName: ruleName,
    };

    if (ruleSubType === RuleSubTypeSelectorEnum.REGULAR) {
      newRule.statement = {};

      if (dropdownConditionRequestValue === RULE_BUILDER_REQUEST_CONDITION_DATA[0]) {
        newRule.statement = getStatementData(statementData?.[0]);
      }

      if (dropdownConditionRequestValue === RULE_BUILDER_REQUEST_CONDITION_DATA[1]) {
        newRule.statement.andStatement = {};
        newRule.statement.andStatement.statements = statementData.map((item: any) => {
          if (item.statementResults) {
            return { notStatement: { statement: getStatementData(item) } };
          }

          return getStatementData(item);
        });
      }

      if (dropdownConditionRequestValue === RULE_BUILDER_REQUEST_CONDITION_DATA[2]) {
        newRule.statement.orStatement = {};
        newRule.statement.orStatement.statements = statementData.map((item: any) => {
          if (item.statementResults) {
            return { notStatement: { statement: getStatementData(item) } };
          }

          return getStatementData(item);
        });
      }

      if (dropdownConditionRequestValue === RULE_BUILDER_REQUEST_CONDITION_DATA[3]) {
        newRule.statement.notStatement = {};
        newRule.statement.notStatement.statement = getStatementData(statementData?.[0]);
      }
    }

    if (ruleSubType === RuleSubTypeSelectorEnum.RATE_BASED) {
      newRule.statement = {};
      newRule.statement.rateBasedStatement = {
        limit: Number(rateLimit) ?? 0,
        evaluationWindowSec: evaluationWindowSec.value,
        aggregateKeyType: requestAggregationKeyType,
      };

      if (requestAggregationKeyType === 'FORWARDED_IP') {
        newRule.statement.rateBasedStatement.forwardedIPConfig = {
          headerName: headerFieldName,
          fallbackBehavior: fallbackBehavior,
        };
      } else {
        delete newRule.statement.rateBasedStatement.forwardedIPConfig;
      }

      if (requestAggregationKeyType === 'CUSTOM_KEYS') {
        newRule.statement.rateBasedStatement.customKeys = requestAggregationKeyData?.map((item: any) => {
          return {
            header: {
              name: item.headerName,
              textTransformations: item.transformation.map((e: any, i: number) => ({
                type: e.value.value,
                priority: i,
              })),
            },
          };
        });
      } else {
        delete newRule.statement.rateBasedStatement.customKeys;
      }

      if (requestAggregationKeyType === 'CONSTANT') {
        newRule.statement.rateBasedStatement.scopeDownStatement = {};

        let scopeDownStatement: AwsStatementlogType = {};

        if (dropdownConditionRequestValue === RULE_BUILDER_REQUEST_CONDITION_DATA[0]) {
          scopeDownStatement = getStatementData(statementData?.[0]);
        }

        if (dropdownConditionRequestValue === RULE_BUILDER_REQUEST_CONDITION_DATA[1]) {
          scopeDownStatement.andStatement = {};
          scopeDownStatement.andStatement.statements = statementData.map((item: any) => {
            if (item.statementResults) {
              return { notStatement: { statement: getStatementData(item) } };
            }

            return getStatementData(item);
          });
        }

        if (dropdownConditionRequestValue === RULE_BUILDER_REQUEST_CONDITION_DATA[2]) {
          scopeDownStatement.orStatement = {};
          scopeDownStatement.orStatement.statements = statementData.map((item: any) => {
            if (item.statementResults) {
              return { notStatement: { statement: getStatementData(item) } };
            }

            return getStatementData(item);
          });
        }

        if (dropdownConditionRequestValue === RULE_BUILDER_REQUEST_CONDITION_DATA[3]) {
          scopeDownStatement.notStatement = {};
          scopeDownStatement.notStatement.statement = getStatementData(statementData?.[0]);
        }

        newRule.statement.rateBasedStatement.scopeDownStatement = scopeDownStatement;
      } else {
        delete newRule.statement.rateBasedStatement.scopeDownStatement;
      }
    }

    switch (action) {
      case 'allow':
        newRule.action = {};
        newRule.action.allow = {};

        if (!_.isEmpty(customHeaderList) || !_.isEmpty(labelList)) {
          newRule.action.allow.customRequestHandling = {};

          if (!_.isEmpty(customHeaderList)) {
            newRule.action.allow.customRequestHandling.insertHeaders = customHeaderList.map((headerItem: any) => ({
              name: headerItem.key,
              value: headerItem.value,
            }));
          }

          if (!_.isEmpty(labelList)) {
            newRule.ruleLabels = labelList.map(label => ({ name: label.value }));
          }
        }

        break;

      case 'block':
        newRule.action = {};
        newRule.action.block = {};

        if (!_.isEmpty(customHeaderList) || !_.isEmpty(labelList)) {
          newRule.action.block.customResponse = {};

          if (!_.isEmpty(customHeaderList)) {
            newRule.action.block.customResponse.responseHeaders = customHeaderList.map((headerItem: any) => ({
              name: headerItem.key,
              value: headerItem.value,
            }));
          }

          if (isEnableBlockCustomResponse) {
            //   newRule.action.block.customResponse.responseCode = 200; // TODO: Add response code
          }

          if (!_.isEmpty(labelList)) {
            newRule.ruleLabels = labelList.map(label => ({ name: label.value }));
          }
        }

        break;

      case 'count':
        newRule.action = {};
        newRule.action.count = {};

        if (customHeaderList || labelList) {
          newRule.action.count.customRequestHandling = {};

          if (customHeaderList) {
            newRule.action.count.customRequestHandling.insertHeaders = customHeaderList.map((headerItem: any) => ({
              name: headerItem.key,
              value: headerItem.value,
            }));
          }

          if (!_.isEmpty(labelList)) {
            newRule.ruleLabels = labelList.map(label => ({ name: label.value }));
          }
        }
        break;

      case 'captcha':
        newRule.action = {};
        newRule.action.captcha = {};

        if (customHeaderList || labelList) {
          newRule.action.captcha.customRequestHandling = {};

          if (customHeaderList) {
            newRule.action.captcha.customRequestHandling.insertHeaders = customHeaderList.map((headerItem: any) => ({
              name: headerItem.key,
              value: headerItem.value,
            }));
          }

          if (!_.isEmpty(labelList)) {
            newRule.ruleLabels = labelList.map(label => ({ name: label.value }));
          }

          if (isCustomImmunityTime) {
            newRule.captchaConfig = {
              immunityTimeProperty: {
                immunityTime: Number(seconds),
              },
            };
          }
        }

        break;

      case 'challenge':
        newRule.action = {};
        newRule.action.challenge = {};
        if (customHeaderList || labelList) {
          newRule.action.challenge.customRequestHandling = {};

          if (customHeaderList) {
            newRule.action.challenge.customRequestHandling.insertHeaders = customHeaderList.map((headerItem: any) => ({
              name: headerItem.key,
              value: headerItem.value,
            }));
          }

          if (!_.isEmpty(labelList)) {
            newRule.ruleLabels = labelList.map(label => ({ name: label.value }));
          }

          if (isCustomImmunityTime) {
            newRule.challengeConfig = {
              immunityTimeProperty: {
                immunityTime: Number(seconds),
              },
            };
          }
        }

        break;

      default:
        break;
    }

    return newRule;
  }, [
    ruleName,
    statementData,
    ruleSubType,
    dropdownConditionRequestValue,
    rateLimit,
    evaluationWindowSec,
    requestAggregationKeyType,
    headerFieldName,
    fallbackBehavior,
    requestAggregationKeyData,
    action,
    customHeaderList,
    labelList,
    seconds,
    isCustomImmunityTime,
  ]);

  const onValidateRuleButtonClicked = useCallback(async () => {
    const isValid = await handleValidateRule();

    if (isValid) {
      setRuleError('');
      setRuleValid('Rule is valid');
    } else {
      setRuleValid('');
      setRuleError('Rule is invalid');
    }
  }, [handleValidateRule, setRuleValid]);

  useEffect(() => {
    handleChangeRuleValue(customRule);
  }, [customRule]);

  const ruleEditorNode = useMemo(() => {
    switch (ruleEditorSelector) {
      case RuleEditorSelectorEnum.VISUAL:
        return (
          <VisualEditor
            cloudId={cloudId}
            selectedRegion={selectedRegion}
            dropdownConditionRequestValue={dropdownConditionRequestValue}
            seconds={seconds}
            setDropdownConditionRequestValue={setDropdownConditionRequestValue}
            setSeconds={setSeconds}
            isCustomImmunityTime={isCustomImmunityTime}
            setIsCustomImmunityTime={setIsCustomImmunityTime}
            inspectionAndRateLimit={inspectionAndRateLimit}
            requestAggregationKeyData={requestAggregationKeyData}
            setRequestAggregationKeyData={setRequestAggregationKeyData}
            handleValidateRule={onValidateRuleButtonClicked}
            ruleName={ruleName}
            setRuleName={setRuleName}
            ruleSubType={ruleSubType}
            setRuleSubType={setRuleSubType}
            statementData={statementData}
            setStatementData={setStatementData}
            rateLimit={rateLimit}
            setRateLimit={setRateLimit}
            evaluationWindowSec={evaluationWindowSec}
            setEvaluationWindowSec={setEvaluationWindowSec}
            requestAggregationKeyType={requestAggregationKeyType}
            setRequestAggregationKeyType={setRequestAggregationKeyType}
            scopeOfInspectionAndRateLimiting={scopeOfInspectionAndRateLimiting}
            setScopeOfInspectionAndRateLimiting={setScopeOfInspectionAndRateLimiting}
            headerFieldName={headerFieldName}
            setHeaderFieldName={setHeaderFieldName}
            fallbackBehavior={fallbackBehavior}
            setFallbackBehavior={setFallbackBehavior}
            action={action}
            setAction={setAction}
            isEnableBlockCustomResponse={isEnableBlockCustomResponse}
            setEnableBlockCustomResponse={setEnableBlockCustomResponse}
            customHeaderList={customHeaderList}
            setCustomHeaderList={setCustomHeaderList}
            labelList={labelList}
            setLabelList={setLabelList}
            errors={errors}
            validateLoading={validateLoading}
            ipSets={ipSets}
          />
        );

      case RuleEditorSelectorEnum.JSON:
        return (
          <Json
            json={jsonRule || {}}
            validateLoading={validateLoading}
            handleValidateRule={onValidateRuleButtonClicked}
          />
        );

      default:
        return null;
    }
  }, [
    cloudId,
    selectedRegion,
    rule,
    dropdownConditionRequestValue,
    seconds,
    isCustomImmunityTime,
    inspectionAndRateLimit,
    requestAggregationKeyData,
    handleChangeRuleValue,
    ruleEditorSelector,
    ruleName,
    setRuleName,
    ruleSubType,
    setRuleSubType,
    statementData,
    setStatementData,
    rateLimit,
    setRateLimit,
    evaluationWindowSec,
    setEvaluationWindowSec,
    requestAggregationKeyType,
    setRequestAggregationKeyType,
    scopeOfInspectionAndRateLimiting,
    setScopeOfInspectionAndRateLimiting,
    headerFieldName,
    setHeaderFieldName,
    fallbackBehavior,
    setFallbackBehavior,
    action,
    setAction,
    isEnableBlockCustomResponse,
    setEnableBlockCustomResponse,
    customHeaderList,
    setCustomHeaderList,
    labelList,
    setLabelList,
    errors,
    validateLoading,
    onValidateRuleButtonClicked,
    ipSets,
  ]);

  return (
    <>
      <div className="rule-container-group">
        {!!ruleError && (
          <div className="rule-error-container">
            <img src={InputErrorIcon} width={18} height={18} />
            <p className="rule-error-message">{ruleError}</p>
          </div>
        )}

        {!ruleError && !!ruleValid && (
          <div className="rule-valid-container">
            <img src={InputValidIcon} width={18} height={18} />
            <p className="rule-valid-message">{ruleValid}</p>
          </div>
        )}

        <p className="rule-container-group-title">Rule builder</p>

        <div className="flex">
          <p className="rule-container-group-description">
            You can use the JSON editor for complex statement nesting, for example to nest two OR statements inside an
            AND statement. The visual editor handles one level of nesting. For web ACLs and rule groups with complex
            nesting, the visual editor is disabled.
          </p>

          <div className="rule-container-group-action">
            {RULE_EDITOR_SELECTOR.map(({ id, value, label }) => (
              <button
                key={id}
                className={value === ruleEditorSelector ? 'active' : ''}
                onClick={() => setRuleEditorSelector(value)}
              >
                {label}
              </button>
            ))}
          </div>
        </div>
      </div>
      {ruleEditorNode}
    </>
  );
};

export default RuleBuilder;
