import { useMemo } from 'react';
import { ColumnType, RowType, OrderDirection } from '@Types/v2/Table';
import { handleFormatText } from 'utils/Common';
import './index.scss';

interface ITableRadioProps {
  name: string;
  className?: string;
  columns: ColumnType[];
  rows: RowType[];
  radioValue: string;
  onRowSelected: (select: string) => void;
  sortOption?: {
    target: string;
    direction: OrderDirection;
    onChangeSort: (target: string, dir: OrderDirection) => void;
  };
  isLoading?: boolean;
}

const TableRadio = ({
  name,
  className,
  rows,
  columns,
  radioValue,
  onRowSelected,
  sortOption,
  isLoading = false,
}: ITableRadioProps) => {
  const renderBody = useMemo(() => {
    if (isLoading) {
      return (
        <tr className="progresss">
          <td colSpan={columns.length + 1}>
            <div className="progress-container">
              <div className="progress-gif" />
              Loading ...
            </div>
          </td>
        </tr>
      );
    }

    return rows.map((row, rowIdx) => (
      <tr key={`row-${rowIdx}`}>
        <td>
          <div className="td-radio-container">
            <div onClick={e => e.stopPropagation()} className="td-radio">
              <div
                className="radio-atom"
                onClick={() => onRowSelected(String(row.id))}
                key={`radio_${name}_${String(row.id)}`}
              >
                <input
                  type="radio"
                  {...(String(row.id) && { id: String(row.id) })}
                  name={name}
                  checked={radioValue == String(row.id)}
                  onChange={() => onRowSelected(String(row.id))}
                />
                <span className="check-mark"></span>
              </div>
            </div>
          </div>
        </td>
        {columns.map((col, idx) => (
          <td key={`row-${rowIdx}-col-${idx}`}>
            <div>
              {col.renderCell ? col.renderCell(row) : handleFormatText((row as { [key: string]: any })[col.field])}
            </div>
          </td>
        ))}
      </tr>
    ));
  }, [isLoading, radioValue, rows, columns]);

  return (
    <div className={`data-table-radio ${className}`}>
      <table>
        <tbody>
          <tr className="horizontal-header">
            <th style={{ height: '47px', width: '88px', maxWidth: '88px', paddingLeft: '32px' }}></th>
            {columns.map((col, idx) => (
              <th key={`col-${idx}`} {...(col.width && { style: { width: col.width + 'px' } })}>
                <div>
                  {col.renderHeader ? col.renderHeader() : col.label ? col.label : col.field}
                  {sortOption && col.sort && (
                    <button
                      className={`sort-btn
                        ${sortOption.direction === OrderDirection.ASC && 'upside-down'} 
                        ${col.field === sortOption.target && 'sorted'}`}
                      onClick={() => {
                        if (sortOption.target !== col.field) {
                          sortOption.onChangeSort(col.field, OrderDirection.DES);
                        } else {
                          sortOption.onChangeSort(
                            col.field,
                            sortOption.direction === OrderDirection.ASC ? OrderDirection.DES : OrderDirection.ASC,
                          );
                        }
                      }}
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
          {renderBody}
        </tbody>
      </table>
    </div>
  );
};

export default TableRadio;
