import { gql } from '@apollo/client';

const query = gql`
  query getAwsListSSHPublicKeys($request: AwsListSshPublicKeysRequest, $cloudId: Long, $region: String) {
    getAwsListSSHPublicKeys(listSshPublicKeysRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListSshPublicKeysResponse {
          sshPublicKeys {
            userName
            sshPublicKeyId
            status
            uploadDate
          }
          isTruncated
          marker
        }
      }
    }
  }
`;
export default query;
