import { DropdownListDataType } from "components/Dropdown/types";
import { IMgdTabProps, TextTypeEnum } from "layouts/v3/MgdLayout";

export const CUSTOMER_KEY_FILTER_DROPDOWN: DropdownListDataType[] = [
  {id: 1, name: 'Aliases', value: 'name'},
  {id: 2, name: 'Key ID', value: 'key'}
];

export const DETAIL_TAB_LIST: IMgdTabProps[] = [
  {id: 'keyPolicy', name: 'Key policy', title: 'Key policy'},
  {id: 'cryptographicConfig', name: 'Cryptographic configuration', title: 'Cryptographic configuration'},
  {id: 'tags', name: 'Tags', title: 'Tags'},
  {id: 'keyRotation', name: 'Key rotation', title: 'Key rotation'},
  {id: 'pulicKey', name: 'Public Key', title: 'Public Key'},
  {id: 'regionality', name: 'Regionality', title: 'Regionality'},
  {id: 'aliases', name: 'Aliases', title: 'Aliases'}
]

export const DETAIL_TEXT_DESCRIPTION = [
  {id: 'name', value: 'Alias', type: TextTypeEnum.NORMAL},
  {id: 'status', value: 'Status', type: TextTypeEnum.STATUS},
  {id: 'createDate', value: 'Creation date', type: TextTypeEnum.NORMAL},
  {id: 'arn', value: 'ARN', type: TextTypeEnum.COPY},
  {id: 'description', value: 'Description', type: TextTypeEnum.NORMAL},
  {id: 'regionality', value: 'Regionality', type: TextTypeEnum.NORMAL}
]