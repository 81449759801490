import { IMgdTabProps } from "layouts/v3/MgdLayout";

export interface INetworkProcotolContentProps {
  protocol: string;
}

export interface INetworkProcotolProps {
  [key: string]: INetworkProcotolContentProps;
}

export interface INetworkIcmpTypeContentProps {
  icmpType: string;
}

export interface INetworkIcmpTypeProps {
  [key: string]: INetworkIcmpTypeContentProps;
}

export interface INetworkIcmpCodeContentProps {
  icmpCode: string;
}

export interface INetworkIcmpCodeProps {
  [key: string]: INetworkIcmpCodeContentProps;
}

export interface INetworkRuleTypeContentProps {
  type: string;
  key?: string;
}

export interface INetworkRuleTypeProps {
  [key: string]: INetworkRuleTypeContentProps;
}


export const NETWORK_RULES_PROTOCOL_MAPPING: INetworkProcotolProps  = {
  '-': {protocol: '-'},
  '-1': {protocol: 'All'},
  '0':	{protocol: 'HOPOPT (0)'},
  '1':	{protocol: 'ICMP (1)'},
  '2':	{protocol: 'IGMP (2)'},
  '3':	{protocol: 'GGP (3)'},
  '4':	{protocol: 'IPv4 (4)'},
  '5':	{protocol: 'ST (5)'},
  '6':	{protocol: 'TCP (6)'},
  '7':	{protocol: 'CBT (7)'},
  '8':	{protocol: 'EGP (8)'},
  '9':	{protocol: 'IGP (9)'},
  '10':	{protocol: 'BBN-RCC-MON (10)'},
  '11':	{protocol: 'NVP-II (11)'},
  '12':	{protocol: 'PUP (12)'},
  '13':	{protocol: 'ARGUS (deprecated) (13)'},
  '14':	{protocol: 'EMCON (14)'},
  '15':	{protocol: 'XNET (15)'},
  '16':	{protocol: 'CHAOS (16)'},
  '17':	{protocol: 'UDP (17)'},
  '18':	{protocol: 'MUX (18)'},
  '19':	{protocol: 'DCN-MEAS (19)'},
  '20':	{protocol: 'HMP (20)'},
  '21':	{protocol: 'PRM (21)'},
  '22':	{protocol: 'XNS-IDP (22)'},
  '23':	{protocol: 'TRUNK-1 (23)'},
  '24':	{protocol: 'TRUNK-2 (24)'},
  '25':	{protocol: 'LEAF-1 (25)'},
  '26':	{protocol: 'LEAF-2 (26)'},
  '27':	{protocol: 'RDP (27)'},
  '28':	{protocol: 'IRTP (28)'},
  '29':	{protocol: 'ISO-TP4 (29)'},
  '30':	{protocol: 'NETBLT (30)'},
  '31':	{protocol: 'MFE-NSP (31)'},
  '32':	{protocol: 'MERIT-INP (32)'},
  '33':	{protocol: 'DCCP (33)'},
  '34':	{protocol: '3PC (34)'},
  '35':	{protocol: 'IDPR (35)'},
  '36':	{protocol: 'XTP (36)'},
  '37':	{protocol: 'DDP (37)'},
  '38':	{protocol: 'IDPR-CMTP (38)'},
  '39':	{protocol: 'TP++ (39)'},
  '40':	{protocol: 'IL (40)'},
  '41':	{protocol: 'IPv6 (41)'},
  '42':	{protocol: 'SDRP (42)'},
  '43':	{protocol: 'IPv6-Route (43)'},
  '44':	{protocol: 'IPv6-Frag (44)'},
  '45':	{protocol: 'IDRP (45)'},
  '46':	{protocol: 'RSVP (46)'},
  '47':	{protocol: 'GRE (47)'},
  '48':	{protocol: 'DSR (48)'},
  '49':	{protocol: 'BNA (49)'},
  '50':	{protocol: 'ESP (50)'},
  '51':	{protocol: 'AH (51)'},
  '52':	{protocol: 'I-NLSP (52)'},
  '53':	{protocol: 'SWIPE (deprecated) (53)'},
  '54':	{protocol: 'NARP (54)'},
  '55':	{protocol: 'Min-IPv4 (55)'},
  '56':	{protocol: 'TLSP (56)'},
  '57':	{protocol: 'SKIP (57)'},
  '58':	{protocol: 'IPv6-ICMP (58)'},
  '59':	{protocol: 'IPv6-NoNxt (59)'},
  '60':	{protocol: 'IPv6-Opts (60)'},
  '61':	{protocol: ' (61)'},
  '62':	{protocol: 'CFTP (62)'},
  '63':	{protocol: ' (63)'},
  '64':	{protocol: 'SAT-EXPAK (64)'},
  '65':	{protocol: 'KRYPTOLAN (65)'},
  '66':	{protocol: 'RVD (66)'},
  '67':	{protocol: 'IPPC (67)'},
  '68':	{protocol: ' (68)'},
  '69':	{protocol: 'SAT-MON (69)'},
  '70':	{protocol: 'VISA (70)'},
  '71':	{protocol: 'IPCV (71)'},
  '72':	{protocol: 'CPNX (72)'},
  '73':	{protocol: 'CPHB (73)'},
  '74':	{protocol: 'WSN (74)'},
  '75':	{protocol: 'PVP (75)'},
  '76':	{protocol: 'BR-SAT-MON (76)'},
  '77':	{protocol: 'SUN-ND (77)'},
  '78':	{protocol: 'WB-MON (78)'},
  '79':	{protocol: 'WB-EXPAK (79)'},
  '80':	{protocol: 'ISO-IP (80)'},
  '81':	{protocol: 'VMTP (81)'},
  '82':	{protocol: 'SECURE-VMTP (82)'},
  '83':	{protocol: 'VINES (83)'},
  '84':	{protocol: 'IPTM (84)'},
  '85':	{protocol: 'NSFNET-IGP (85)'},
  '86':	{protocol: 'DGP (86)'},
  '87':	{protocol: 'TCF (87)'},
  '88':	{protocol: 'EIGRP (88)'},
  '89':	{protocol: 'OSPFIGP (89)'},
  '90':	{protocol: 'Sprite-RPC (90)'},
  '91':	{protocol: 'LARP (91)'},
  '92':	{protocol: 'MTP (92)'},
  '93':	{protocol: 'AX.25 (93)'},
  '94':	{protocol: 'IPIP (94)'},
  '95':	{protocol: 'MICP (deprecated) (95)'},
  '96':	{protocol: 'SCC-SP (96)'},
  '97':	{protocol: 'ETHERIP (97)'},
  '98':	{protocol: 'ENCAP (98)'},
  '99':	{protocol: ' (99)'},
  '100': {protocol: 'GMTP (100)'},
  '101': {protocol: 'IFMP (101)'},
  '102': {protocol: 'PNNI (102)'},
  '103': {protocol: 'PIM (103)'},
  '104': {protocol: 'ARIS (104)'},
  '105': {protocol: 'SCPS (105)'},
  '106': {protocol: 'QNX (106)'},
  '107': {protocol: 'A/N (107)'},
  '108': {protocol: 'IPComp (108)'},
  '109': {protocol: 'SNP (109)'},
  '110': {protocol: 'Compaq-Peer (110)'},
  '111': {protocol: 'IPX-in-IP (111)'},
  '112': {protocol: 'VRRP (112)'},
  '113': {protocol: 'PGM (113)'},
  '114': {protocol: ' (114)'},
  '115': {protocol: 'L2TP (115)'},
  '116': {protocol: 'DDX (116)'},
  '117': {protocol: 'IATP (117)'},
  '118': {protocol: 'STP (118)'},
  '119': {protocol: 'SRP (119)'},
  '120': {protocol: 'UTI (120)'},
  '121': {protocol: 'SMP (121)'},
  '122': {protocol: 'SM (deprecated) (122)'},
  '123': {protocol: 'PTP (123)'},
  '124': {protocol: 'ISIS over IPv4 (124)'},
  '125': {protocol: 'FIRE (125)'},
  '126': {protocol: 'CRTP (126)'},
  '127': {protocol: 'CRUDP (127)'},
  '128': {protocol: 'SSCOPMCE (128)'},
  '129': {protocol: 'IPLT (129)'},
  '130': {protocol: 'SPS (130)'},
  '131': {protocol: 'PIPE (131)'},
  '132': {protocol: 'SCTP (132)'},
  '133': {protocol: 'FC (133)'},
  '134': {protocol: 'RSVP-E2E-IGNORE (134)'},
  '135': {protocol: 'Mobility Header (135)'},
  '136': {protocol: 'UDPLite (136)'},
  '137': {protocol: 'MPLS-in-IP (137)'},
  '138': {protocol: 'manet (138)'},
  '139': {protocol: 'HIP (139)'},
  '140': {protocol: 'Shim6 (140)'},
  '141': {protocol: 'WESP (141)'},
  '142': {protocol: 'ROHC (142)'},
  '253': {protocol: '253 (253)'},
  '254': {protocol: '254 (254)'}
};

export const NETWORK_RULES_ICMP_TYPE_MAPPING: INetworkIcmpTypeProps  = {
  '-1': {icmpType: 'All'},
  '0': {icmpType: 'Echo Reply'},
  '3': {icmpType: 'Destination Unreachable'},
  '4': {icmpType: 'Source Quench'},
  '5': {icmpType: 'Redirect Message'},
  '6': {icmpType: 'Alternate Host Address'},
  '8': {icmpType: 'Echo Request'},
  '9': {icmpType: 'Router Advertisement'},
  '10': {icmpType: 'Router Solicitation'},
  '11': {icmpType: 'Time Exceeded'},
  '12': {icmpType: 'Parameter Problem'},
  '13': {icmpType: 'Timestamp'},
  '14': {icmpType: 'Timestamp Reply'},
  '15': {icmpType: 'Information Request'},
  '16': {icmpType: 'Information Reply'},
  '17': {icmpType: 'Address Mask Request'},
  '18': {icmpType: 'Address Mask Reply'},
  '30': {icmpType: 'Traceroute'},
  '31': {icmpType: 'Datagram Conversion Error'},
  '32': {icmpType: 'Mobile Host Redirect'},
  '33': {icmpType: 'Where Are You'},
  '34': {icmpType: 'Here I Am'},
  '35': {icmpType: 'Mobile Registration Request'},
  '36': {icmpType: 'Mobile Registration Reply'},
  '37': {icmpType: 'Domain Name Request'},
  '38': {icmpType: 'Domain Name Reply'},
  '39': {icmpType: 'SKIP Algorithm Discovery Protocol'},
  '40': {icmpType: 'Photuris, Security Failures'}
};

export const NETWORK_RULES_ICMP_CODE_MAPPING: INetworkIcmpCodeProps  = {
  '-1': {icmpCode: 'All'},
  '0': {icmpCode: 'Destination Network Unreachable'},
  '1': {icmpCode: 'Destination Host Unreachable'},
  '2': {icmpCode: 'Destination Protocol Unreachable'},
  '3': {icmpCode: 'Destination Port Unreachable'},
  '4': {icmpCode: 'Fragmentation Required, and DF Flag Set'},
  '5': {icmpCode: 'Source Route Failed'},
  '6': {icmpCode: 'Destination Network Unknown'},
  '7': {icmpCode: 'Destination Host Unknown'},
  '8': {icmpCode: 'Source Host Isolated'},
  '9': {icmpCode: 'Network Administratively Prohibited'},
  '10': {icmpCode: 'Host Administratively Prohibited'},
  '11': {icmpCode: 'Network Unreachable for TOS'},
  '12': {icmpCode: 'Host Unreachable for TOS'},
  '13': {icmpCode: 'Communication Administratively Prohibited'}
};


export const NETWORK_RULES_TYPE_MAPPING: INetworkRuleTypeProps  = {
  '22': {type: 'SSH (22)'},
  '23': {type: 'Telnet (23)'},
  '25': {type: 'SMTP (25)'},
  '42': {type: 'nameserver (42)'},
  '53_tcp': {type: 'DNS (TCP) (53)'},
  '53_udp': {type: 'DNS (UCP) (53)'},
  '80': {type: 'HTTP (80)'},
  '110': {type: 'POP3 (110)'},
  '143': {type: 'IMAP (143)'},
  '389': {type: 'LDAP (389)'},
  '443': {type: 'HTTPS (443)'},
  '445': {type: 'SMB (445)'},
  '465': {type: 'SMTPS (465)'},
  '993': {type: 'IMAPS (993)'},
  '995': {type: 'POP35 (995)'},
  '1433': {type: 'MS SQL (1433)'},
  '1521': {type: 'Oracle (1521)'},
  '2049': {type: 'NFS (2049)'},
  '3306': {type: 'MySQL/Aurora (3306)'},
  '33889': {type: 'RDP (3389)'},
  '5432': {type: 'PostgreSQL (5432)'},
  '5439': {type: 'Redshift (5439)'},
  '5985': {type: 'WinRM-HTTP (5985)'},
  '5986': {type: 'WinRM-HTTPS (5986)'},
  '8080': {type: 'HTTP* (8080)'},
  '8443': {type: 'HTTPS* (8443)'},
  'all_traffic': {type: 'All traffic'},
  'custom_protocol': {type: 'Custom Protocol'},
  'custom_tcp': {type: 'Custom TCP'},
  'custom_udp': {type: 'Custom UDP'},
  'custom_icmp_ipv4': {type: 'Custom ICMP - IPv4'},
  'custom_icmp_ipv6': {type: 'Custom ICMP - IPv6'},
  'all_tcp': {type: 'All TCP'},
  'all_udp': {type: 'All UDP'},
  'all_icmp_ipv4': {type: 'All ICMP - IPv4'},
  'all_icmp_ipv6': {type: 'All ICMP - IPv6'}
};

export const detailNetworkALCTabs: IMgdTabProps[] = [
  {id: 'details', name: 'Details', title: 'Details'},
  {id: 'inbound', name: 'Inbound', title:'Inbound'},
  {id: 'outbound', name: 'Outbound', title: 'Outbound'},
  {id: 'subnetAssociations', name: 'Subnet associations', title: 'Subnet associations'},
  {id: 'tags', name: 'Tags', title: 'Tags'}
]
