import './index.scss';
import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import Icon from 'components/v2/atoms/Icon';
import InboundIcon from 'assets/svgs/v3/ico_inbound.svg';
import OutboundIcon from 'assets/svgs/v3/ico_outbound.svg';
import { randomString } from 'utils/Common';
import revokeAwsSecurityGroupIngressMutation, {
  IRevokeAwsSecurityGroupIngressVariables,
} from 'graphql/mutations/revokeAwsSecurityGroupIngress';
import revokeAwsSecurityGroupEgressMutation, {
  IRevokeAwsSecurityGroupEgressVariables,
} from 'graphql/mutations/revokeAwsSecurityGroupEgress';
import { RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN, RULE_TYPE_DROPDOWN } from '../Constant';
import authorizeAwsSecurityGroupIngressMutation, {
  IAuthorizeAwsSecurityGroupIngressVariables,
} from 'graphql/mutations/authorizeAwsSecurityGroupIngress';
import authorizeAwsSecurityGroupEgressMutation, {
  IAuthorizeAwsSecurityGroupEgressVariables,
} from 'graphql/mutations/authorizeAwsSecurityGroupEgress';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import {
  handleConvertPortRange,
  handleConvertProtocol,
  handleGetFromPort,
  handleGetPortRange,
  handleGetPortRangeDataByType,
  handleGetProtocol,
  handleGetProtocolDataByType,
  handleGetToPort,
  handleGetTypeItem,
} from '../Common';

interface IEditSecurityGroupRuleModalProps extends IBaseModalProps {
  cloudId: number;
  region: string;
  groupId: string;
  header: string;
  subTitle?: string;
  sourceDestinationOption: any;
  isInbound: boolean;
  currentData: RowSecurityGroupRuleData[];
  onSave: () => void;
}

interface RuleRowData {
  ruleNumber: string;
  type: string;
  protocol: string;
  portRange: string;
  source: string;
  action: string;
  description: string;
}

export interface RowSecurityGroupRuleData {
  securityGroupRuleId: string;
  type: string;
  protocol: string;
  portRange: string;
  source: string;
  description: string;
}

const ruleDefault = {
  securityGroupRuleId: '',
  type: 'custom_tcp',
  ipProtocol: 'tcp',
  protocol: 'TCP',
  portRange: '0',
  source: '',
  description: '',
};

const EditSecurityGroupRuleModal = ({
  cloudId,
  region,
  groupId,
  header,
  isInbound,
  sourceDestinationOption,
  subTitle,
  currentData,
  onSave,
  ...baseModalProps
}: IEditSecurityGroupRuleModalProps) => {
  const [rows, setRows] = useState<RowSecurityGroupRuleData[]>([]);

  const [revokeAwsSecurityGroupIngress] = revokeAwsSecurityGroupIngressMutation();
  const [revokeAwsSecurityGroupEgress] = revokeAwsSecurityGroupEgressMutation();
  const [authorizeAwsSecurityGroupIngress] = authorizeAwsSecurityGroupIngressMutation();
  const [authorizeAwsSecurityGroupEgress] = authorizeAwsSecurityGroupEgressMutation();

  useEffect(() => {
    if (!currentData?.length) return;

    const arr: any = currentData.map((e: any) => {
      const type: any = RULE_TYPE_DROPDOWN.find(item => item.name === e.type);
      const protocol = handleConvertProtocol(type?.value, e.protocol, e.portRange);
      const portRange = handleConvertPortRange(type?.value, e.protocol, e.portRange);

      return {
        ...e,
        type: type?.value || RULE_TYPE_DROPDOWN[3].value,
        source: e?.cidr,
        ipProtocol: type?.protocol,
        protocol,
        portRange,
      };
    });

    setRows(arr);
  }, [currentData]);

  const handleCreate = () => {
    setRows(prevState => {
      const newState = prevState.concat(ruleDefault);
      return newState;
    });
  };

  const handleDelete = (indexToRemove: number) => {
    const newData = rows.filter((_, index) => index != indexToRemove);
    setRows(newData);
  };

  const handleChange = (index: number, value: any, propName: keyof RuleRowData) => {
    const newRows = rows.map((row, i) => {
      if (i == index) {
        const newRow: any = { ...row, [propName]: value };
        if (propName === 'type') {
          const item = handleGetTypeItem(value);
          const protocol = handleGetProtocol(value);
          newRow.ipProtocol = item?.protocol;
          newRow.protocol = protocol;
          newRow.portRange = item?.port || '0';
        }

        if (propName === 'protocol') {
          if (row.type === RULE_TYPE_DROPDOWN[3].value) {
            newRow.ipProtocol = value;
            newRow.protocol = value;
          }

          newRow.portRange = handleGetPortRange(row.type, value);
        }

        return newRow;
      }

      return row;
    });

    setRows(newRows);
  };

  const disabledByTypeAndProtocol = (type: string, protocol: string) => {
    let disabled = true;
    if (type === 'custom_tcp' || type === 'custom_udp') {
      disabled = false;
    }
    if (type === 'custom_icmp_ipv4' || type == 'custom_icmp_ipv6') {
      if (protocol === '3' || protocol === '5' || protocol === '12') {
        disabled = false;
      }
    }
    return disabled;
  };

  const disabledByType = (type: string) => {
    let disabled = true;
    if (type === 'custom_protocol' || type === 'custom_icmp_ipv4' || type == 'custom_icmp_ipv6') {
      disabled = false;
    }
    return disabled;
  };

  const handleGetNameSource = (value: string | undefined) => {
    for (let i = 0; i < sourceDestinationOption?.length; i++) {
      const item = sourceDestinationOption[i]?.children?.find((e: any) => {
        return e.value === value;
      });

      if (item?.name) return item.name;
    }

    return '';
  };

  const handleGetIpProtocol = (type: string, protocol: string) => {
    const result = handleGetProtocolDataByType(type);

    const item = result.find((e: any) => e.value === protocol);

    return item?.name ? item.name.split(' (')?.[0] : '';
  };

  const handleDeleteRule = () => {
    const variables: IRevokeAwsSecurityGroupIngressVariables | IRevokeAwsSecurityGroupEgressVariables = {
      cloudId,
      region,
      request: {
        groupId,
        securityGroupRuleIds: currentData.filter(e => e.securityGroupRuleId).map(e => e.securityGroupRuleId),
      },
    };

    return isInbound ? revokeAwsSecurityGroupIngress({ variables }) : revokeAwsSecurityGroupEgress({ variables });
  };

  const handleCreateRule = async () => {
    const variablesRuleInbound: IAuthorizeAwsSecurityGroupIngressVariables | undefined = rows?.length
      ? {
          cloudId,
          region,
          request: {
            groupId,
            ipPermissions: rows.map((e: any) => {
              return {
                ipProtocol: e.ipProtocol,
                fromPort: handleGetFromPort(e.type, e.protocol, e.portRange),
                toPort: handleGetToPort(e.type, e.portRange),
                ipRanges: [
                  {
                    cidrIp: e.source,
                    description: e.description,
                  },
                ],
              };
            }),
          },
        }
      : undefined;

    const variablesRuleOutbound: IAuthorizeAwsSecurityGroupEgressVariables | undefined = rows?.length
      ? {
          cloudId,
          region,
          request: {
            groupId,
            ipPermissions: rows.map((e: any) => {
              return {
                ipProtocol: e.ipProtocol,
                fromPort: handleGetFromPort(e.type, e.protocol, e.portRange),
                toPort: handleGetToPort(e.type, e.portRange),
                ipRanges: [
                  {
                    cidrIp: e.source,
                    description: e.description,
                  },
                ],
              };
            }),
          },
        }
      : undefined;

    if (isInbound && variablesRuleInbound) {
      return authorizeAwsSecurityGroupIngress({ variables: variablesRuleInbound });
    }

    if (variablesRuleOutbound) {
      return authorizeAwsSecurityGroupEgress({ variables: variablesRuleOutbound });
    }
  };

  const handleUpdateRule = async () => {
    try {
      const isDelete = currentData?.filter(e => e.securityGroupRuleId)?.length;
      if (isDelete) {
        await handleDeleteRule();
      }

      await handleCreateRule();
      onSave();
      useToast(ErrorCode.SUCCESS, 'Edit security group successful.');
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Edit security group failed.');
    }
  };

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={isInbound ? InboundIcon : OutboundIcon} />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-inbound-rules-model">
        <div className="edit-inbound-rules-sub-title">{subTitle}</div>
        <div className="horizontal-line"></div>
        <table className="table-rules">
          <thead className="table-header">
            <tr>
              <th>Security group rule ID</th>
              <th>Type</th>
              <th>Protocol</th>
              <th>Port range</th>
              <th>Source</th>
              <th>Description (optional)</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {!!rows?.length
              ? rows.map((row, index) => {
                  const isShowDropdow =
                    row.type === RULE_TYPE_DROPDOWN[2].value &&
                    [
                      RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN[2].value,
                      RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN[4].value,
                      RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN[10].value,
                    ].includes(row.protocol);

                  return (
                    <tr key={index}>
                      <td>
                        <label>{row.securityGroupRuleId}</label>
                      </td>
                      <td>
                        <DropdownAtom
                          id={randomString()}
                          data={RULE_TYPE_DROPDOWN}
                          value={{
                            name: RULE_TYPE_DROPDOWN.find(val => val.value === row.type)?.name || '',
                            value: row.type,
                          }}
                          handleClick={val => handleChange(index, val.value as string, 'type')}
                        />
                      </td>
                      <td>
                        {row.type === RULE_TYPE_DROPDOWN[2].value ? (
                          <DropdownAtom
                            id={randomString()}
                            disabled={disabledByType(row.type)}
                            data={handleGetProtocolDataByType(row.type)}
                            value={{
                              name: handleGetProtocolDataByType(row.type).find(val => val.value === row.protocol)?.name || '',
                              value: row.protocol,
                            }}
                            handleClick={val => handleChange(index, val.value as string, 'protocol')}
                          />
                        ) : (
                          <InputAtom
                            value={row.protocol}
                            disabled={row.type !== RULE_TYPE_DROPDOWN[3].value}
                            noClear={true}
                            onChangeValue={(value: string) => handleChange(index, value, 'protocol')}
                            error={!row.protocol}
                          />
                        )}
                      </td>
                      <td>
                        {isShowDropdow ? (
                          <DropdownAtom
                            id={randomString()}
                            disabled={disabledByTypeAndProtocol(row.type, row.protocol)}
                            data={handleGetPortRangeDataByType(row.type, row.protocol)}
                            value={{
                              name:
                                handleGetPortRangeDataByType(row.type, row.protocol).find(
                                  val => val.value === row.portRange,
                                )?.name || '',
                              value: row.portRange,
                            }}
                            handleClick={val => handleChange(index, val.value as string, 'portRange')}
                          />
                        ) : (
                          <InputAtom
                            value={row.portRange}
                            disabled={disabledByTypeAndProtocol(row.type, row.protocol)}
                            noClear={true}
                            onChangeValue={(value: string) => {
                              if (!value) {
                                handleChange(index, '0', 'portRange');
                                return;
                              }

                              handleChange(index, value, 'portRange');
                            }}
                          />
                        )}
                      </td>
                      <td>
                        <DropdownAtom
                          id={randomString()}
                          data={sourceDestinationOption}
                          value={{
                            name: handleGetNameSource(row?.source),
                            value: row?.source || '',
                          }}
                          handleClick={(val: any) => handleChange(index, val?.value, 'source')}
                        />
                      </td>
                      <td>
                        <InputAtom
                          id={randomString()}
                          value={row.description}
                          onChangeValue={(val: any) => handleChange(index, val, 'description')}
                        />
                      </td>
                      <td>
                        <button onClick={() => handleDelete(index)}>Remove</button>
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        <button className="add-button" onClick={handleCreate}>
          Add new tag
        </button>

        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="save-btn" onClick={handleUpdateRule}>
            Save changes{' '}
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditSecurityGroupRuleModal;
