import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import { RowType } from '@Types/v2/Table';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import SearchDropdown from 'components/v2/SearchDropdown';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';
import { useMutation } from 'react-query';
import { IUpdateDictionaryReq } from 'apis/v2/Manage/schema';
import apis from 'apis/v2';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import searchItems from 'graphql/queries/searchItems';

interface IDictionaryDetailProps {
  relObjTypeList: {
    name: string;
    value: string;
  }[];
  data: RowType;
  convertGridIsShowing: () => void;
  openDeleteModal: () => void;
  onEditSuccess: () => void;
}

const DictionaryDetail = ({
  relObjTypeList,
  data,
  convertGridIsShowing,
  openDeleteModal,
  onEditSuccess
}: IDictionaryDetailProps) => {

  const { tooltip } = useGModal();

  const [edit, setEdit] = useState(false);
  const [detail, setDetail] = useState({
    id: -1,
    relObjectId: -1,
    relObjectType: '',
    defaultName: '',
    domainName: ''
  });
  const [searchForItems,  setSearchForItems] = useState('');
  const [itemList, setItemList] = useState<{name: string; value: number}[]>([]);
  const [confirmItem, setConfirmItem] = useState(false);

  /* 딕셔너리 수정 */
  const { mutateAsync: _updateDictionary } = useMutation((data: IUpdateDictionaryReq) => apis.Manage.updateDictionary(data));
  const [searchItemList] = searchItems();

  const origin = useMemo(() => {
    return {
      id: Number(data.id),
      relObjectId: data.relObjectId,
      relObjectType: data.relObjectType,
      defaultName: data.defaultName,
      domainName: data.domainName
    }
  }, []);

  const enableEditDetail = useMemo(() => {
    if (
      detail.relObjectType === '' ||
      detail.relObjectId === -1 ||
      detail.defaultName === '' ||
      detail.domainName === ''
    ) {
      return true;
    } else return false;
  }, [detail]);

  const getItemList = (str:string) => {
    const searchItemData = {
      reqData: {
        relObjectType: detail.relObjectType,
        text: str
      }
    }
    searchItemList({ variables: searchItemData }).then(({ data }) => {
      if (data) {
        setItemList(data.searchItems.data.map(val => ({
          name: val.name,
          value: val.id
        })));
      }
    }); 
  }

  useEffect(() => {
    searchItemList({ 
      variables: {
        reqData: {
          relObjectType: detail.relObjectType,
          text: data.relObjectName
        }
      }
    }).then(({ data }) => {
      if (data) {
        setItemList(data.searchItems.data.map(val => ({
          name: val.name,
          value: val.id
        })));
      }
    }); 

    setDetail({
      id: Number(data.id),
      relObjectId: data.relObjectId,
      relObjectType: data.relObjectType,
      defaultName: data.defaultName,
      domainName: data.domainName
    });
    setSearchForItems(data.relObjectName);
    setConfirmItem(false);
  }, [data]);

  return (
    <div className='dictionary-detail'>
      <div className='dictionary-detail-header flex a-center'>
        <div className='back-btn' 
          onClick={convertGridIsShowing}
        />  
        {!edit ? String(itemList.find(val => val.value === detail.relObjectId)?.name) : '딕셔너리 수정'}
      </div>
      <div className='detail-contents'>
      <InfoCard
          title="Use type" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ?  enumFormatter(detail.relObjectType)
                : <DropdownAtom 
                  id="project-name" 
                  placeholder='Select use type'
                  data={relObjTypeList.map(val => ({ name: enumFormatter(val.value), value: val.value }))} 
                  value={{
                  name: relObjTypeList.find(val => val.value === detail.relObjectType)
                    ? enumFormatter(String(relObjTypeList.find(val => val.value === detail.relObjectType)?.value))
                    : '',
                  value: detail.relObjectType
                  }}
                  handleClick={(val) => {
                    setDetail(prev => ({
                      ...prev,
                      relObjectType: String(val.value),
                      relObjectId: -1
                    }))
                    setSearchForItems('');
                    setConfirmItem(false);
                  }}
               />
              }
            </>
          } 
        />
        <InfoCard
          title="Item" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? itemList.find(val => val.value === detail.relObjectId)?.name 
                  ? itemList.find(val => val.value === detail.relObjectId)?.name 
                  : ''
                : <SearchDropdown 
                  disabled={detail.relObjectType === ''}
                  id="search-item-list"
                  placeholder='Select item'
                  data={itemList}
                  searchValue={searchForItems}
                  isConfirmed={confirmItem}
                  onChangeSearch={(str) => {
                    getItemList(str);
                    setSearchForItems(str);
                  }}
                  handleClick={(val) => {
                    setDetail(prev => ({
                      ...prev,
                      relObjectId: Number(val.value)
                    }));
                    setSearchForItems(String(val.name));
                    setConfirmItem(true);
                  }}
                  resetIsConfirm={() => {
                    setConfirmItem(false);
                    setDetail(prev => ({
                      ...prev,
                      relObjectId: -1
                    }))
                    getItemList('');
                  }}
                />
              }
            </>
          } 
        />
        <InfoCard
          title="Ref. value" 
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.defaultName
                : <TextareaAtom
                    value={detail.defaultName}
                    placeholder='Input reference value'
                    onChangeValue={(val) => {
                      setDetail((prev) => ({
                        ...prev,
                        defaultName: val
                      }))
                    }
                  }
                />
              }
            </>
          } 
        />
        <InfoCard
          title="Setting value" 
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.domainName
                : <TextareaAtom
                    value={detail.domainName}
                    placeholder='Input setting value'
                    onChangeValue={(val) => {
                      setDetail((prev) => ({
                        ...prev,
                        domainName: val
                      }))
                    }
                  }
                />
              }
            </>
          } 
        />
        {
          !edit &&
        <>
        <InfoCard
          title="Created"
          content={() => 
            <>
            <u
              onMouseOver={(e) => {
                tooltip.userTooltip({ 
                  top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                    ? e.currentTarget.getBoundingClientRect().bottom - 115
                    : e.currentTarget.getBoundingClientRect().bottom + 12,
                  left: e.currentTarget.getBoundingClientRect().left, 
                  userInfo: { userId: data.createdByUserId, fullName: data.createdByName, createdAt: !data.createdAt ? '-' : data.createdAt, thumbnail: '' }
                });
              }}
              onMouseLeave={tooltip.close}
            >
            {data.createdByName}({data.createdByUserId})</u> / {!data.createdAt ? '-' : dateFormatter(data.createdAt, 'datetime')}
            </> 
          } 
        />
        <InfoCard
          title="Updated"
          content={() => 
            <>
                <u
                  onMouseOver={(e) => {
                    tooltip.userTooltip({ 
                      top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                        ? e.currentTarget.getBoundingClientRect().bottom - 115
                        : e.currentTarget.getBoundingClientRect().bottom + 12,
                      left: e.currentTarget.getBoundingClientRect().left, 
                      userInfo: { userId: data.modifiedByUserId, fullName: data.modifiedByName, createdAt: !data.modifiedAt ? '-' : data.modifiedAt, thumbnail: '' }
                    });
                  }}
                  onMouseLeave={tooltip.close}
                >
                  {data.modifiedByName}({data.modifiedByUserId})</u> / {!data.modifiedAt ? '-' : dateFormatter(data.modifiedAt, 'datetime')}
            </> 
          } 
        />
        </>
        }
      </div>
      <div className="btns flex j-end">
        {
          !edit 
            ? <>
              <button 
                className="big-sub-btn"
                onClick={() => setEdit(true)}
              >
                Edit
              </button>
              <button 
                className="big-main-btn"
                onClick={openDeleteModal}
              >
                Delete
              </button> 
            </>
            : <>
              <button
                className="big-sub-btn"
                onClick={() => {
                  setDetail({ ...origin });
                  setEdit(false);
                }}
              >
                Cancel
              </button>
              <button 
                className="big-main-btn"
                onClick={() => { 
                  const updateDictionaryData = {
                    ReqUpdDictionary: {
                      ...detail
                    }
                  }
                  
                  _updateDictionary(updateDictionaryData).then(({ data }) => {
                    if (data) {
                      if (data.result === ErrorCode.SUCCESS) {
                        useToast(ErrorCode.SUCCESS, 'Dictionary is updated successfully.');
                        setEdit(false);
                        onEditSuccess();
                      } else {
                        console.log(data.result);
                        useToast(ErrorCode.UNKNOWN, 'Update dictionary failed.');
                      }
                    } else {
                      useToast(ErrorCode.UNKNOWN, 'Update dictionary failed.');
                    }
                  })
                 }}
                disabled={ enableEditDetail }
              >
                Save
              </button>
            </>
        }
      </div>
    </div>
  )
}
export default DictionaryDetail;