import { TextTypeEnum } from "layouts/v3/MgdLayout";

export const DETAIL_TEXT_DESCRIPTION = [
  {id: 'createDate', value: 'Creation date', type: TextTypeEnum.NORMAL},
  {id: 'arn', value: 'ARN', type: TextTypeEnum.COPY},
  {id: 'lastActivity', value: 'Last activity', type: TextTypeEnum.NORMAL},
  {id: 'maxSessionDuration', value: 'Maximum session duration', type: TextTypeEnum.NORMAL}
]

export const DETAIL_TAB_LIST = [
  {id: 'permission', name: 'Permissions', title:'Permissions'},
  {id: 'trustRelationship', name: 'Trust relationship', title: 'Trust relationship'},
  {id: 'tags', name: 'Tags', title: 'Tags'},
  {id: 'accessAdvisor', name: 'Access advisor', title: 'Access advisor'},
];
