import './index.scss';
import { ColumnType, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import { handleFormatText } from 'utils/Common';
import { enumFormatter } from 'utils/Formatter';

interface IDeleteMemoModalProps extends IBaseModalProps {
  data: RowType[]
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteMemoModal = ({  
  data,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteMemoModalProps) => {
  const COLUMNS:ColumnType[] = [
      {
        label: 'Name',
        field: 'name'
      },
      {
        label: 'Type',
        field: 'relObjectType',
        sort: true,
        renderCell: (row:RowType) => <>{enumFormatter(row.relObjectType)}</>
      },
      {
        label: 'Body',
        field: 'text',
        renderCell: (row:RowType) => <>{handleFormatText(!row.text ? '-' : row.text)}</>
      },
      {
        label: 'Used',
        field: 'checkYn',
        sort: true,
        renderCell: (row:RowType) => <>{row.checkYn ? 'Yes' : 'No'}</>
      }
    ];
  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-memo-modal">
        <div className="content">
          <Table rows={data} columns={COLUMNS}/>
        </div>
        <div className="btns flex j-end">
          <button 
            className="big-sub-btn flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            Cancel
          </button>
          <button 
            className="big-main-btn flex j-center a-center" 
            onClick={onDelete}
          >
            Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteMemoModal;
