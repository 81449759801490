import { gql } from '@apollo/client';

const query = gql`
query getDashboardTemplatesByType($type: String) {
  getDashboardTemplatesByType(type: $type) {
    result
    data {
      ... on DashboardTemplate {
        id
        name
        type
        reqParams
        createdBy
      }
    }
  }
}
`;
export default query;