import { DropdownListDataType } from "components/Dropdown/types";
import { TextTypeEnum } from "layouts/v3/MgdTabLayout/configs";

export const DETAIL_KEYS = ['publicIp', 'type', 'allocationId', 'reverseDNSRecord', 'associationId', 'domain', 'associatedInstanceId', 'privateIpAddress', 'networkInterfaceId', 'networkInterfaceOwnerId', 'publicDns', 'natGatewayId', 'publicIpv4Pool', 'networkBorderGroup'];
export const DETAIL_TEXT_DESCRIPTION: {id: string, value: string, type: string}[] = [
  {id: 'publicIp', value: 'Allocated IPv4 addresses', type: TextTypeEnum.COPY},
  {id: 'type', value: 'Type', type: TextTypeEnum.NORMAL},
  {id: 'allocationId', value: 'Allocation ID', type: TextTypeEnum.COPY},
  {id: 'reverseDNSRecord', value: 'Reverse DNS record', type: TextTypeEnum.NORMAL},
  {id: 'associationId', value: 'Association ID', type: TextTypeEnum.COPY},
  {id: 'domain', value: 'Scope', type: TextTypeEnum.NORMAL},
  {id: 'associatedInstanceId', value: 'Associated instance ID', type: TextTypeEnum.NORMAL},
  {id: 'privateIpAddress', value: 'Private IP address', type: TextTypeEnum.NORMAL},
  {id: 'networkInterfaceId', value: 'Network interface ID', type: TextTypeEnum.NORMAL},
  {id: 'networkInterfaceOwnerId', value: 'Network interface owner account ID', type: TextTypeEnum.COPY},
  {id: 'publicDns', value: 'Public DNS', type: TextTypeEnum.COPY}, // TODO: DescribeNetworkInterfacesResponse.Association.PublicDnsName
  {id: 'natGatewayId', value: 'NAT gateway ID', type: TextTypeEnum.LINK}, // TODO: DescribeNatGatewaysResponse.NatGatewayId
  {id: 'publicIpv4Pool', value: 'Address Pool', type: TextTypeEnum.NORMAL},
  {id: 'networkBorderGroup', value: 'Network border group', type: TextTypeEnum.NORMAL}
];

export const ELASTIC_IP_SUMMARY_TAB_LIST = [
  {id: 'details', name: 'Details', title: 'Details'},
  {id: 'tags', name: 'Tags', title: 'Tags'}
];

export const DETAIL_TAB_LIST = [
  {id: 'tags', name: 'Tags', title: 'Tags'}
];

export const ELASTIC_IP_FILTER_DROPDOWN: DropdownListDataType[] = [
  {id: 1, name: 'Allocated IPv4 addresss', value: 'public-ip'},
  {id: 2, name: 'Name', value: 'tag:Name'},
  {id: 3, name: 'Allocation ID', value: 'allocation-id'}
];
