import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import { useMutation } from 'react-query';
import apis from 'apis/v2';
import { IGetTermsReq } from 'apis/v2/Terms/schema';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import FooterComponent from 'components/v2/FooterComponent';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthProvider';

interface IPolicyProps {
  type: 'Service' | 'Privacy'
}

const Policy = ({
  type
}: IPolicyProps) => {
  const navigate = useNavigate();
  const {userInfo} = useAuth();
  const [contents, setContents] = useState('');
  const { pathname } = useLocation();

  const isService = useMemo(() => {
    if ( type === 'Service') return true;
    else return false;
  },[type]); 

  const { mutateAsync: _getTerms } = useMutation((payload:IGetTermsReq) => apis.Terms.getTerms(payload));

  const getPolicyTerm = () => {
    _getTerms({ kindCode: isService ? 'service0' : 'privacy0', format: 'all00000' })
    .then(({ data }) => {
      if (data.result === 'sc0000') {
        setContents(data.data[0].text);
      } else {
        useToast(ErrorCode.UNKNOWN, '잠시 후 다시 시도해 주세요.');
      }
    });
  }

  useEffect(() => {
    getPolicyTerm();
  },[type]);

  return (
    <div id="policy-page">
      <div className='header'>
        <div className='header-logo' onClick={() => {
          if(userInfo) {
            navigate(`/organ/${userInfo.nemo.organId}/dashboard/overview`)
          } else {
            navigate('/');
          }
        }}/>
      </div>
      <div className='contents flex'>
        <aside className="terms-aside">
          <div className="title">
            Curidy Policies
          </div>
          <ul>
            <li 
              className={`flex a-center ${ pathname === "/terms" && 'select' }`}
            >
              <Link to="/terms">
                Terms of service
              </Link>
            </li>
            <li 
              className={`flex a-center ${ pathname === "/terms/secure" && 'select' }`}
            >
              <Link to="/terms/secure">
                Privacy policy
              </Link>
            </li>
            </ul>
        </aside>
        <div className="preview-scroll-wrap">
          <div className='preview-wrap'>
            <h5>{ type === 'Service' ? 'Terms of use' : 'Privacy policy' }</h5>
            <div 
              className='content-wrap'
              dangerouslySetInnerHTML={{__html: contents }} 
            />
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
}

export default Policy;