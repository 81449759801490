import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import './index.scss';
import LabelInput from 'components/v2/LabelInput';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { TABLE_OFFSET } from 'utils/DummyData/Dropdown';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import Table from 'components/v2/dataDisplay/Table';
import IsLoading from 'components/v2/atoms/IsLoading';
import Labeled from 'components/v2/atoms/Labeled';
import { useMemo, useState } from 'react';
import { enumFormatter } from 'utils/Formatter';
import { timeToKr } from 'utils/timeFormatter';

interface IMessageGridProps {
  msgUseTypeList: {
    name: string;
    value: string;
  }[]
  onSubmitSearch: (readyToSearch: {
    msgUseType: string;
    searchText: string;
  }) => void;
  total: {
    totalPage: number;
    totalElement: number;
  }
  rows: RowType[];
  tablePagination: {
    limit: number;
    target: string;
    direction: OrderDirection;
    currentPage: number;
  }
  updateTablePagination: (key: string, value: number | string | OrderDirection) => void;
  checkedList: string[];
  reportCheckedList: (list:string[]) => void;
  reportSelected: (id: string) => void;
  openCreateModal: () => void;
  openDeleteModal: () => void;
  isLoading?: boolean;
}

const MessageGrid = ({
  tablePagination,
  updateTablePagination,
  msgUseTypeList,
  onSubmitSearch,
  total,
  rows,
  checkedList,
  reportCheckedList,
  reportSelected,
  openCreateModal,
  openDeleteModal,
  isLoading
}:IMessageGridProps) => {

  const [readyToSearch, setReadyToSearch] = useState({
    msgUseType: 'all',
    searchText: ''
  });

  const COLUMNS:ColumnType[] = useMemo(() => {
    return [
      {
        label: 'Title',
        field: 'title',
        sort: true,
      },
      {
        label: 'Usage',
        field: 'relObjectType',
        sort: true,
        renderCell: (row: RowType) => <>{enumFormatter(row.relObjectType)}</>
      },
      { 
        label: 'Received',
        field: 'sendTypeCode',
        sort: true,
        renderCell: (row: RowType) => <>{enumFormatter(row.sendTypeCode)}</>
      },
      {
        label: 'Updated by',
        field: 'modifiedByName',
        sort: true
      },
      {
        label: 'Updated at',
        field: 'modifiedAt',
        renderCell: (row:RowType) => <>{!row.modifiedAt ? '-' : timeToKr(row.modifiedAt)}</>, 
        sort: true
      }
    ];
  }, [rows]);
  
  return (
    <div className='message-grid'>
      <form 
        className='search-wrap flex a-end'
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitSearch(readyToSearch);
        }}
      >
        <Labeled 
          title="Message type"
          required
          className="msg-use-type"
          >
          <DropdownAtom 
            id='msg-use'
            data={
              [
                {
                  name: 'All',
                  value: 'all'
                },
                ...msgUseTypeList
              ]
            } 
            value={{
              name: readyToSearch.msgUseType === 'all' ? 'All' : msgUseTypeList.find(val => val.value === readyToSearch.msgUseType)?.name,
              value: readyToSearch.msgUseType
            }} 
            handleClick={(val) => {setReadyToSearch(prev => ({
              ...prev,
              msgUseType: String(val.value)
            })) }} 
          />
        </Labeled>
        <LabelInput 
          title="Search" 
          required
          placeholder='Input message title'
          value={readyToSearch.searchText}
          onChangeValue={str => setReadyToSearch(prev => ({
            ...prev,
            searchText: str
          }))}
        />
        <div className="btns flex a-end">
        <button 
          type="submit"
          className='big-main-btn'
        >
          Search
        </button>
        </div>
      </form>
      <div className='data-contents'>
        <div className="data-grid-wrap">
          <div className="grid-header flex j-between">
            <div className="col-1 flex a-center">
              <div className="header-icon" /> Results <span>{total.totalElement}</span>
            </div>
            <div className="col-2 flex a-center">
              { checkedList.length > 0 && `${checkedList.length} selected` }
              <button 
                className="big-sub-btn delete"
                disabled={checkedList.length === 0 }
                onClick={openDeleteModal}
              >
                Delete
              </button>
              {
                total.totalElement !== 0 &&
                <>
                <button 
                  className="big-main-btn register"
                  onClick={openCreateModal}
                >
                  Create
                </button> 
                <DropdownAtom 
                  id="offset-limit"
                  data={TABLE_OFFSET}
                  value={{ 
                    name: TABLE_OFFSET.find(data => data.value === tablePagination.limit)?.name,
                    value: Number(tablePagination.limit)
                  }}
                  handleClick={(val) => { 
                    updateTablePagination('limit', Number(val.value))
                    updateTablePagination('currentPage', 1);
                  }}
                />
                </>
              }
            </div>
          </div>
          <div className="grid-main">
            { 
              rows.length === 0 
                ? 
                <div className="empty-rows flex j-center a-center">
                  <div className="flex col a-center">
                    <div className='empty-icon' />
                    <h5>There are no items.</h5>
                    <p>Create a new message</p>
                    <button 
                      className='big-main-btn'
                      onClick={openCreateModal}
                    >
                      Create
                    </button>
                  </div>
                </div> 
                : 
                <>
                  <Table 
                    rows={rows} 
                    columns={COLUMNS} 
                    reportCheckedList={reportCheckedList}
                    reportSelected={reportSelected}
                    sortOption={{
                      target: tablePagination.target,
                      direction: tablePagination.direction,
                      onChangeSort: (target: string, dir: OrderDirection) => {
                        updateTablePagination('target', target);
                        updateTablePagination('direction', dir);
                      }
                    }}
                  />
                  <div className="pagination-wrapper flex a-center">
                    <p className="flex a-center">
                      Total <span>{total.totalElement}</span>
                    </p>
                    <TablePagination
                      currentPage={tablePagination.currentPage}
                      updateCurrentPage={page => updateTablePagination('currentPage', page)}
                      totalPage={total.totalPage}
                    />
                  </div>
                </>
              }
          </div>
        </div>
        { isLoading && <IsLoading dimmed={rows.length !== 0} /> }
      </div>
    </div>
  )
}
export default MessageGrid;