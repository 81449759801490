import { Fragment, useCallback, useMemo, useState } from 'react';
import { DetailTablePropsType } from './types';
import Table from 'components/v2/dataDisplay/Table';
import { DEFAULT_TABLE_PAGINATION_STATE } from './configs';
import { OrderDirection } from '@Types/v2/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import './index.scss';
import { orderAlphabetical } from '../../../Utils/Sorting';
import ButtonGroup from '../../../WAF/WebACL/CreateWebACL/components/ButtonGroup';
import TableRadio from 'components/v2/dataDisplay/TableRadio';

const DetailTable = (props: DetailTablePropsType) => {
  const {
    data,
    columns,
    title,
    description,
    hasPagination = true,
    rightButtons,
    caption,
    isRadioTable = false,
    reportValue = '',
    reportSelected,
    reportCheckedList,
    isEmptyTableWithHeader,
    emptyMessage,
    isLoading,
    horizontalScrollable = true,
  } = props;

  const [tablePagination, setTablePagination] = useState(DEFAULT_TABLE_PAGINATION_STATE);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const currentPageRows = useMemo(() => {
    const firstItemIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const lastItemIndex = tablePagination.currentPage * tablePagination.itemPerPage - 1;

    if (hasPagination === false) {
      return orderAlphabetical(data, tablePagination.target, tablePagination.direction);
    }

    return orderAlphabetical(data, tablePagination.target, tablePagination.direction)?.filter(
      (_, index) => index >= firstItemIndex && index <= lastItemIndex,
    );
  }, [data, data.length, tablePagination, hasPagination]);

  const contentNode = useMemo(() => {
    if (isEmptyTableWithHeader) {
      return (
        <>
          <Table rows={[]} columns={columns} />
          <p className="empty-row">{emptyMessage}</p>
        </>
      );
    }

    if (isRadioTable && reportSelected) {
      return (
        <TableRadio
          name={'table-rule-group-list'}
          columns={columns}
          rows={currentPageRows}
          radioValue={reportValue}
          onRowSelected={reportSelected}
          isLoading={isLoading}
        />
      );
    }

    return (
      <Table
        rows={currentPageRows}
        columns={columns}
        sortOption={{
          target: tablePagination.target,
          direction: tablePagination.direction,
          onChangeSort: (target: string, dir: OrderDirection) => {
            updateTablePagination('target', target);
            updateTablePagination('direction', dir);
          },
        }}
        horizontalScrollable={horizontalScrollable}
        isLoading={isLoading}
        reportCheckedList={reportCheckedList}
        reportSelected={reportSelected}
      />
    );
  }, [
    isRadioTable,
    reportSelected,
    columns,
    currentPageRows,
    reportValue,
    isLoading,
    currentPageRows,
    columns,
    tablePagination,
    isEmptyTableWithHeader,
    horizontalScrollable,
  ]);

  return (
    <div className="detail-table-container">
      <div className="detail-info-title">
        <div>
          <p className="title">
            {title}
            {!!caption && <span className="caption">{caption}</span>}
          </p>
          <p className="description">{description}</p>
        </div>

        <ButtonGroup buttons={rightButtons ?? []} />
      </div>

      <div className="detail-table-content">
        {currentPageRows.length === 0 && isLoading === false && !isEmptyTableWithHeader ? (
          <p className="empty-row">Empty</p>
        ) : (
          <Fragment>
            <Fragment>{contentNode}</Fragment>
            {hasPagination && (
              <Fragment>
                {data?.length > 0 && !isLoading && (
                  <div className="pagination-wrapper flex a-center">
                    <p className="flex a-center">
                      Total <span>{data.length}</span>
                    </p>
                    <TableManagePagination
                      ableFetchMore={false}
                      currentPage={tablePagination.currentPage}
                      updateCurrentPage={page => updateTablePagination('currentPage', page)}
                      totalPage={Math.ceil(data.length / tablePagination.itemPerPage)}
                    />
                  </div>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default DetailTable;
