import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListServiceSpecificCredentialsResponse } from 'graphql/types/AwsListServiceSpecificCredentialsResponse';

export interface IAwsListServiceSpecificCredentialsVariables {
  cloudId: number;
  region: string;
  request: {
    userName?: string;
    serviceName?: string;
  };
}

export interface IAwsListServiceSpecificCredentialsResponseData {
  getAwsListServiceSpecificCredentials: IGqlResponseNewData<AwsListServiceSpecificCredentialsResponse>;
}

const lazyGetAwsListServiceSpecificCredentials = () =>
  useLazyQuery<IAwsListServiceSpecificCredentialsResponseData, IAwsListServiceSpecificCredentialsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListServiceSpecificCredentials;
