import DetailTable from '../../../DetailTable';
import { ColumnType, RowType } from '@Types/v2/Table';
import { useEffect, useState } from 'react';
import { handleFormatText } from 'utils/Common';

function Routes({ data = {} }: any) {
  const [dataTable, setDataTable] = useState([]);
  
  const { routes = [] } = data;
  
  useEffect(() => {
    let arr: any = [];
    routes.forEach((row: any) => {
      arr.push({
        destinationCidrBlock: row.destinationCidrBlock,
        target: row.natGatewayId ? row.natGatewayId : row.gatewayId,
        status: row.state,
        propagated: row.origin === 'EnableVgwRoutePropagation' ? 'Yes' : 'No',
      });
    });

    setDataTable(arr);
  }, [routes]);

  const columns: ColumnType[] = [
    {
      label: 'Destination',
      field: 'destinationCidrBlock',
      sort: true,
    },
    {
      label: 'Target',
      field: 'target',
      sort: true,
      renderCell: (row: RowType) => <div className="link">{handleFormatText(row.target)}</div>,
    },
    { label: 'Status', field: 'status', sort: true },
    { label: 'Propagated', field: 'propagated', sort: true },
  ];

  return <DetailTable title="Routes" data={dataTable} columns={columns} />;
}

export default Routes;
