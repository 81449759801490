import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import TableSelectModal from 'components/v2/modals/TableSelectModal';
import { useAuth } from 'contexts/AuthProvider';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import Table from 'components/v2/dataDisplay/Table';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import { ProjectMemberPageType } from 'graphql/types/ProjectMemberPage';
import lazyGetProjectMember, { IGetProjectMemberVariables } from 'graphql/queries/getProjectMemberPage';
import { timeToKr } from 'utils/timeFormatter';
import IsLoading from 'components/v2/atoms/IsLoading';
import { selectSettingType } from '..';
import { handleFormatText } from 'utils/Common';

interface IMemberDataGridProps {
  projectInfo: ProjectInfo;
  openModal: (key: string, value: boolean | string) => void;
  selectSetting: selectSettingType;
  selectSett: (data: selectSettingType) => void;
  refetch: {
    refresh: boolean;
    resetPage: boolean;
  }
  resetRefetch: () => void;
}

const MemberDataGrid = ({
  projectInfo,
  openModal,
  selectSett,
  selectSetting,
  refetch,
  resetRefetch
}:IMemberDataGridProps) => {

  const { userInfo } = useAuth();
  const { tooltip } = useGModal();

  const [rows, setRows] = useState<ProjectMemberPageType[] | null>(null);
  const [total, setTotal] = useState({
    totalPage: 0,
    totalElement: 0
  });
  const [currentPage, setCurrentPage] = useState(1);

  /* 구성원 조회하기 */
  const [lazyGetMemberList, { loading: loadingGetProjectMember }] = lazyGetProjectMember();

  const role = useMemo(() => {
    if (userInfo?.nemo.role === 'sy_admin') return 'pj_admin';
    else {
      if (userInfo?.nemo.ownership) { return 'pj_owner'} 
      else {
        const findMyRoleCode = userInfo?.nemo.projects.find(val => val.projectId === projectInfo.projectId)?.roleCode!
        return findMyRoleCode;
      }
    }
  }, [userInfo]);

  const OWNER_COLUMNS: ColumnType[] = useMemo(() => {
    if (role === 'pj_admin' || role === 'pj_owner') {
      return [
        {
          label: 'Name',
          field: 'fullName'
        },
        {
          label: 'ID',
          field: 'userId',
          renderCell: (row:RowType) => 
            <div className="user-info">
              <div 
                className="user-id"
                onMouseOver={(e) => {
                  tooltip.userTooltip({ 
                    top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                      ? e.currentTarget.getBoundingClientRect().bottom - 115
                      : e.currentTarget.getBoundingClientRect().bottom + 12,
                    left: e.currentTarget.getBoundingClientRect().left, 
                    userInfo: { userId: row.userId, fullName: row.fullName, createdAt: row.createdAt, thumbnail: '' } 
                  });
                }} 
                onMouseLeave={tooltip.close}
              >
                {handleFormatText(row.userId)}
              </div>
            </div>

        },
        {
          label: 'Role',
          field: 'roleCode',
          width: 91 + 16,
          renderCell: (row: RowType) => <>{row.ownerYn ? 'Owner' : row.roleCode === 'pj_admin' ? 'Admin' : 'Member'}</>
        },
        {
          label: 'Alert',
          field: 'alertYn',
          width: 91 + 16,
          renderCell: (row:RowType) => <>{row.alertYn ? 'Yes' : 'No'}</>
        },
        {
          label: 'Joined at',
          field: 'createdAt',
          width: 91 + 16,
          renderCell: (row:RowType) => <>{ !row.createdAt ? '-' : timeToKr(row.createdAt) }</>
        },
        {
          label: 'Manage',
          field: 'setting',
          width: 91 + 16,
          renderCell: (row: RowType) =>
            <div className="setting-card">
               <button 
                // disabled={row.ownerYn || row.roleCode === 'pj_owner' || row.id === userInfo?.nemo.memberId}
                className="hamburger-btn" 
                onClick={(e) => {
                  selectSett({
                    open: {
                      id: String(row.id),
                      fullName: row.fullName,
                      userId: row.userId,
                      alertYn: row.alertYn,
                      roleCode: row.roleCode,
                      projectId: projectInfo.projectId,
                      projectName: projectInfo.name,
                      rowsLength: Number(rows?.length),
                      totalPage: total.totalPage
                    },
                    top: (100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom 
                    ? e.currentTarget.getBoundingClientRect().bottom - 100
                    : e.currentTarget.getBoundingClientRect().bottom + 3),
                    left: e.currentTarget.getBoundingClientRect().left - 85
                  })
                  openModal('select', true);
                }}
              /> 
            {selectSetting.open.id === row.id &&
            <TableSelectModal
              myRole={role}
              roleCode={selectSetting.open.roleCode}
              position={{
                top: selectSetting.top, 
                left: selectSetting.left
              }}
              onClose={() => selectSett({
                open: {
                  id: '',
                  fullName: '',
                  userId: '',
                  alertYn: false,
                  roleCode: '',
                  projectId: -1,
                  projectName: '',
                  rowsLength: Number(rows?.length),
                  totalPage: total.totalPage
                },
                top: 0,
                left: 0
              })
              }
              openChangeModal={(target: string) => {
                if (target === 'roleCode') {
                  if (selectSetting.open.roleCode === 'pj_admin') {
                    openModal('target', 'member')
                  } else {
                    openModal('target', 'admin')
                  }
                } else {
                  openModal('target', target);
                }
                openModal('changeShareModal', true);
              }}
            />
          }
          </div>
        }
      ];
    } else {
      return [
        {
          label: 'Name',
          field: 'fullName'
        },
        {
          label: 'ID',
          field: 'userId',
          renderCell: (row:RowType) => 
            <div className="user-info">
              <div 
                className="user-id"
                onMouseOver={(e) => {
                  tooltip.userTooltip({ 
                    top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                      ? e.currentTarget.getBoundingClientRect().bottom - 115
                      : e.currentTarget.getBoundingClientRect().bottom + 12,
                    left: e.currentTarget.getBoundingClientRect().left, 
                    userInfo: { userId: row.userId, fullName: row.fullName, createdAt: row.createdAt, thumbnail: '' } 
                  });
                }} 
                onMouseLeave={tooltip.close}
              >
                {handleFormatText(row.userId)}
              </div>
            </div>

        },
        {
          label: 'Role',
          field: 'roleCode',
          width: 123 + 16,
          renderCell: (row: RowType) => <>{row.ownerYn ? 'Owner' : row.roleCode === 'pj_admin' ? 'Admin' : 'Member'}</>
        },
        {
          label: 'Alert',
          field: 'alertYn',
          width: 123 + 16,
          renderCell: (row:RowType) => <>{row.alertYn ? 'Yes' : 'No'}</>
        },
        {
          label: 'Joined at',
          field: 'createdAt',
          width: 123 + 16,
          renderCell: (row:RowType) => <>{ !row.createdAt ? '-' : timeToKr(row.createdAt) }</>
        }
      ];
    }
  }, [rows, userInfo, role, projectInfo, selectSetting]);

  const getProjectMemberData = useMemo(() => ({
    reqGetProjectMemberPage: {
      projectId: projectInfo.projectId,
      pageInfo: {
        page: currentPage < 0 ? 0 :currentPage - 1,
        size: 5, /* 무조건 고정 */
        orders: [{
          target: 'createdAt', /* 무조건 고정 */
          direction: OrderDirection.ASC
        }]
      }
    }
  }), [currentPage, projectInfo.projectId]);

  const getMemberRows = (data:IGetProjectMemberVariables) => {
    lazyGetMemberList({ variables: data }).then(({ data }) => {
      if(data) {
        if (data.getProjectMemberPage) {
          const result = data.getProjectMemberPage.content.filter(val => val.projectId === projectInfo.projectId);

          setRows(result);
          setTotal({
            totalPage: data.getProjectMemberPage.totalPages,
            totalElement: data.getProjectMemberPage.totalElements
          });
          resetRefetch();
        }
      }
    }).catch(() => {
      setRows([]);
      resetRefetch();
    });
  }
  
  useEffect(() => {
    if (!refetch.refresh) return;
    else {
      if (!refetch.resetPage) {
        getMemberRows(getProjectMemberData);
      } else {
        setCurrentPage(1);
      }
    }
  }, [refetch]);

  useEffect(() => {
    getMemberRows(getProjectMemberData);
  }, [getProjectMemberData])

  return (
    <figure className='member-component'>
    {
      rows === null
      ? (
        loadingGetProjectMember && 
          <div className='loading-null'>
            <div className='empty-grid' />
            <IsLoading />
          </div>
        )
      : <figure className='datagrid-wrap'>
      <figure className="member-datagrid">
      <div className="header flex j-between">
        <div className="col-1 flex a-center">
          <div className="folder-icon" /> {projectInfo.name}
        </div>
        <div className="col-2 flex a-center">
          <button 
            className="big-main-btn"
            disabled={role === 'pj_membr'}
            onClick={() => {
              openModal('invite', true);
              openModal('target', projectInfo.projectId.toString())
            }}
          >
            invite 
          </button> 
        </div>
      </div>
        <div className={`row-2 ${selectSetting.open.id !== '' && 'standby'}`}> 
           {rows.length !== 0
            ? <>
              <Table
                rows={rows}
                columns={OWNER_COLUMNS}
              />  
              <div className="pagination-wrapper flex a-center">
                <p className="flex a-center">Total <span>{total.totalElement}</span></p>
                <TablePagination
                  currentPage={currentPage}
                  totalPage={total.totalPage}
                  updateCurrentPage={(page) => {setCurrentPage(page)}}
                />
              </div>
            </>
            : <div className="empty-members flex col j-center a-center">
              <div className="empty-members-icon" /> 
              <h5>생성된 프로젝트에 구성원이 없습니다.</h5>
              {
                userInfo?.nemo.projects.find(val => val.projectId === projectInfo.projectId)?.roleCode !== 'pj_membr'
                  ? <p>구성원을 초대해보세요.</p>
                  : <p>프로젝트의 소유자와 관리자가 구성원을 초대할 수 있습니다.</p>
              }
            </div> }
      </div>
    </figure>
    { loadingGetProjectMember && <IsLoading dimmed={rows.length !== 0} /> }
    </figure>
    }
    </figure>
  );
};

export default MemberDataGrid;
