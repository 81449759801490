import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import TableSelectModal from 'components/v2/modals/TableSelectModal';
import { useAuth } from 'contexts/AuthProvider';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import Table from 'components/v2/dataDisplay/Table';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import { ProjectMemberPageType } from 'graphql/types/ProjectMemberPage';
import { timeToKr } from 'utils/timeFormatter';
import IsLoading from 'components/v2/atoms/IsLoading';
import { selectSettingType } from '..';
import { handleFormatText } from 'utils/Common';
import lazyGetMembersByOrganId, { IGetMembersByOrganIdVariables } from 'graphql/queries/getMembersByOrganId';
import { OrganMemberType } from '@Types/v4/Member';

interface IMemberDataGridProps {
  organId: number;
  openModal: (key: string, value: boolean | string) => void;
  selectSetting: selectSettingType;
  selectSett: (data: selectSettingType) => void;
  refetch: {
    refresh: boolean;
    resetPage: boolean;
  }
  resetRefetch: () => void;
}

const MemberDataGrid = ({
  organId,
  openModal,
  selectSett,
  selectSetting,
  refetch,
  resetRefetch
}:IMemberDataGridProps) => {

  const { userInfo } = useAuth();
  const { tooltip } = useGModal();

  const [rows, setRows] = useState<OrganMemberType[] | null>(null);
  const [total, setTotal] = useState({
    totalPage: 10,
    totalElement: 100
  });
  const [currentPage, setCurrentPage] = useState(1);

  /* 구성원 조회하기 */
  const [lazyGetMemberList, { loading: loadingGetProjectMember }] = lazyGetMembersByOrganId();

  const OWNER_COLUMNS: ColumnType[] = useMemo(() => {
    console.log(rows);
    return [
      {
        label: 'Name',
        field: 'fullName'
      },
      {
        label: 'ID',
        field: 'userId',
        renderCell: (row:RowType) => 
          <div className="user-info">
            <div 
              className="user-id"
              onMouseOver={(e) => {
                tooltip.userTooltip({ 
                  top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                    ? e.currentTarget.getBoundingClientRect().bottom - 115
                    : e.currentTarget.getBoundingClientRect().bottom + 12,
                  left: e.currentTarget.getBoundingClientRect().left, 
                  userInfo: { userId: row.userId!.toString(), fullName: row.fullName, createdAt: row.createdAt, thumbnail: '' } 
                });
              }} 
              onMouseLeave={tooltip.close}
            >
              {handleFormatText(row.userId)}
            </div>
          </div>

      },
      {
        label: 'Projects',
        field: 'projectCount',
        width: 147
      },
      {
        label: 'Last login',
        field: 'lastLogin',
        width: 147,
        renderCell: (row:RowType) => <>{ !row.lastLogin ? '-' : timeToKr(row.lastLogin) }</>
      },
      {
        label: 'Signed at',
        field: 'signedAt',
        width: 147,
        renderCell: (row:RowType) => <>{ !row.createdAt ? '-' : timeToKr(row.createdAt) }</>
      },
      {
        label: 'Manage',
        field: 'setting',
        width: 80,
        renderCell: (row: RowType) =>
          <div className="setting-card">
            <button 
              className="hamburger-btn" 
              disabled={row.ownerYn || row.roleCode === 'pj_owner' || row.id === userInfo?.nemo.memberId}
              onClick={(e) => {
                selectSett({
                  open: {
                    id: String(row.id),
                    fullName: row.fullName,
                    userId: row.userId,
                    roleCode: row.ownerYn ? 'pj_owner' : (row.sysAuthCode === "normal00" ? 'pj_member' : 'pj_admin'),
                    projectId: -1
                  },
                  top: (100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom 
                    ? e.currentTarget.getBoundingClientRect().bottom - 135
                    : e.currentTarget.getBoundingClientRect().bottom + 3),
                  left: e.currentTarget.getBoundingClientRect().left - 150
                })
                openModal('select' , true )
              }}
            /> 
            {selectSetting.open.id === row.id &&
            <TableSelectModal
              myRole="pj_owner"
              roleCode={selectSetting.open.roleCode}
              position={{
                top: selectSetting.top,
                left: selectSetting.left
              }}
              onClose={() => selectSett({
                open: {
                  id: '',
                  fullName: '',
                  userId: '',
                  roleCode: '',
                  projectId: -1
                },
                top: 0,
                left: 0
              })}
              openChangeModal={(target: string) => {
                if (target === 'roleCode') {
                  if (selectSetting.open.roleCode === 'pj_admin') {
                    openModal('target', 'member')
                  } else {
                    openModal('target', 'admin')
                  }
                } else {
                  openModal('target', target);
                }
                openModal('changeShareModal', true);
              }}
            />
            }
          </div>
      }
    ];
  }, [rows, userInfo, organId, selectSetting]);

  const getProjectMemberData = useMemo(() => ({
    reqData: {
      organId: organId,
      pageInfo: {
        page: currentPage < 0 ? 0 :currentPage - 1,
        size: 10,
        orders: [{
          target: 'modifiedAt', /* 무조건 고정 */
          direction: OrderDirection.DES
        }]
      }
    }
  }), [currentPage, organId]);

  const getMemberRows = (data:IGetMembersByOrganIdVariables) => {
    lazyGetMemberList({ variables: data }).then(({ data }) => {
      if(data) {
        if (data.getMembersByOrganId) {
          const result = data.getMembersByOrganId.content;
          setRows(result);
          setTotal({
            totalPage: data.getMembersByOrganId.totalPages,
            totalElement: data.getMembersByOrganId.totalElements
          });
          resetRefetch();
        }
      }
    }).catch(() => {
      setRows([]);
      resetRefetch();
    });
  }
  
  useEffect(() => {
    if (!refetch.refresh) return;
    else {
      if (!refetch.resetPage) {
        getMemberRows(getProjectMemberData);
      } else {
        if(currentPage === 1) {
          getMemberRows(getProjectMemberData);
        } else {
          setCurrentPage(1);
        }
      }
    }
  }, [refetch]);

  useEffect(() => {
    getMemberRows(getProjectMemberData);
  }, [getProjectMemberData])

  return (
    <figure className='organ-member-component'>
    {
      rows === null
      ? (
        loadingGetProjectMember && 
          <div className='loading-null'>
            <div className='empty-grid' />
            <IsLoading />
          </div>
        )
      : 
      <figure className='organ-datagrid-wrap'>
        <figure className="member-datagrid">
          <div className={`row-2 ${selectSetting.open.id !== '' && 'standby'}`}> 
            <Table
              rows={rows}
              columns={OWNER_COLUMNS}
            />  
            <div className="pagination-wrapper flex a-center">
              <p className="flex a-center">Total <span>{total.totalElement}</span></p>
              <TablePagination
                currentPage={currentPage}
                totalPage={total.totalPage}
                updateCurrentPage={(page) => {setCurrentPage(page)}}
              />
            </div>
        </div>
      </figure>
    { loadingGetProjectMember && <IsLoading dimmed={rows.length !== 0} /> }
    </figure>
    }
    </figure>
  );
};

export default MemberDataGrid;
