import DetailTable from '../../../DetailTable';
import { ColumnType, RowType } from '@Types/v2/Table';
import CurrentStatusCard from 'pages/v2/Organ/Report/components/CurrentStatusCard';
import { useEffect, useState } from 'react';

function CIDR({ data }: any) {
  const [dataTable, setDataTable] = useState([]);
  
  useEffect(() => {
    let arr: any = [];
    // get CIDR IPv4
    if (data?.cidrBlockAssociationSet[0]) {
      const status = data?.cidrBlockAssociationSet[0]?.cidrBlockState?.state
        ? data?.cidrBlockAssociationSet[0]?.cidrBlockState?.state
        : '?';

      arr.push({
        addressType: 'IPv4',
        cidr: data?.cidrBlockAssociationSet?.cidrBlock,
        networkGroup: '-',
        pool: '-',
        currentStatus: status,
      });
    }

    // get CIDR IPv6
    if (data?.ipv6CidrBlockAssociationSet) {
      arr.push({
        addressType: '...',
        cidr: data?.cidrBlockAssociationSet?.cidrBlock,
        networkGroup: '-',
        pool: '-',
        currentStatus: '?',
      });
    }

    setDataTable(arr);
  }, []);

  const columns: ColumnType[] = [
    { label: 'Address type', field: 'addressType', sort: true },
    { label: 'CIDR', field: 'cidr', sort: true },
    {
      label: 'Network border group',
      field: 'networkGroup',
      sort: true,
    },
    { label: 'Pool', field: 'pool', sort: true },
    {
      label: 'Status',
      field: 'currentStatus',
      sort: true,
      renderCell: (row: RowType) => <CurrentStatusCard status={row.currentStatus} />,
    },
  ];

  return <DetailTable title="CIDR" data={dataTable} columns={columns} />;
}

export default CIDR;
