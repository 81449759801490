import { DropdownListDataType } from "components/Dropdown/types";
import { TextTypeEnum } from "layouts/v3/MgdTabLayout/configs";

export const AWS_KEY_FILTER_DROPDOWN: DropdownListDataType[] = [
  {id: 1, name: 'Aliases', value: 'name'},
  {id: 2, name: 'Key ID', value: 'key'}
];

export const DETAIL_TAB_LIST = [
  {id: 'keyPolicy', name: 'Key policy', title: 'Key policy'},
  {id: 'cryptographicConfiguration', name: 'Cryptographic configuration', title: 'Cryptographic configuration'}
];

export const DETAIL_TEXT_DESCRIPTION = [
  {id: 'name', value: 'Alias', type: TextTypeEnum.NORMAL},
  {id: 'keyState', value: 'Status', type: TextTypeEnum.STATUS},
  {id: 'creationDate', value: 'Creation date', type: TextTypeEnum.NORMAL},
  {id: 'arn', value: 'ARN', type: TextTypeEnum.COPY},
  {id: 'description', value: 'Description', type: TextTypeEnum.NORMAL},
  {id: 'multiRegion', value: 'Regionality', type: TextTypeEnum.NORMAL}
]
