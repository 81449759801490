import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListUserTagsResponse } from 'graphql/types/AwsListUserTagsResponse';

export interface IAwsListUserTagsVariables {
  cloudId: number;
  region: string;
  request: {
    userName: string;
  };
}

export interface IAwsListUserTagsResponseData {
  getAwsListUserTags: IGqlResponseNewData<AwsListUserTagsResponse>;
}

const lazyGetAwsListUserTags = () =>
  useLazyQuery<IAwsListUserTagsResponseData, IAwsListUserTagsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListUserTags;
