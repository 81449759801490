import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InstancePropsType } from './types';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { useOutletContext } from 'react-router-dom';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import MgdTabLayout from 'layouts/v3/MgdTabLayout';
import { EC2_TAB_LIST } from './configs';
import { MgdTabLayoutTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import SpotRequest from './components/SpotRequest/ListUp';
import Instance from './components/Instance/InstanceList';
import Image from './components/Image/Image';
import EBSVolume from 'pages/v2/Organ/Management/EC2/components/EBSVolume/ListUp';
import EBSSnapshot from './components/EBSSnapshot/EBSSnapshot';
import KeyPair from './components/KeyPair/ListUp';
import NetworkInterface from './components/NetworkInterface/ListUp';
import LoadBalancer from './components/LoadBalancer/Listup';
import LBTargetGroup from './components/LBTargetGroup/LBTargetGroupListup';
import InstanceSummary from './components/Instance/InstanceSummary';
import SpotRequestSummary from 'pages/v2/Organ/Management/EC2/components/SpotRequest/Summary';
import ImageSummary from './components/Image/ImageSummary';
import EBSVolumeSummary from './components/EBSVolume/Summary';
import EBSSnapshotSummary from './components/EBSSnapshot/EBSSnapshotSummary';
import KeyRepairSummary from './components/KeyRepairSummary';
import NetworkInterfaceSummary from './components/NetworkInterface/Summary';
import LoadBalancerSummary from './components/LoadBalancer/Summary';
import LBTargetGroupSummary from './components/LBTargetGroup/LBTargetGroupSummary';
import { AwsImageType } from 'graphql/types/AwsImage';
import SpotRequestDetail from 'pages/v2/Organ/Management/EC2/components/SpotRequest/Detail';
import EBSVolumeDetail from 'pages/v2/Organ/Management/EC2/components/EBSVolume/Detail';
import ImageDetail from './components/Image/ImageDetail';
import { AwsInstanceType } from 'graphql/types/AwsDescribeInstance';
import { AwsSnapshotType } from 'graphql/types/AwsDescribeSnapshots';
import InstanceDetail from './components/Instance/Detail';
import LBTargetGroupDetail from './components/LBTargetGroup/LBTargetGroupDetail';
import EBSSnapshotDetail from './components/EBSSnapshot/EBSSnapshotDetail';
import { AwsLoadBalancer } from 'graphql/types/AwsLoadBalancer';
import NetworkInterfaceDetail from 'pages/v2/Organ/Management/EC2/components/NetworkInterface/Detail';
import LoadBalancerDetail from './components/LoadBalancer/Detail';
import { AwsTargetGroupRowType } from 'graphql/types/AwsDescribeTargetGroup';
import { SpotInstanceRowType } from './components/SpotRequest/types';

const EC2 = (props: InstancePropsType) => {
  const {
    onBackButtonClicked,
    projectId,
    cloudName,
    regionCode,
    regions,
    recentRelatedClouds,
    relatedCloudSelected,
    recentRelatedCloudOnChange,
  } = props;

  // Context
  const { defaultRegionList, updateDefaultRegionList } = useOutletContext<{
    defaultRegionList: { projectId: number; cloudId: number; name: string; value: string }[];
    updateDefaultRegionList: (projectId: number, cloudId: number, name: string, value: string) => void;
  }>();

  // API
  const [getEnumTypeCode, { loading: enumTypeLoading }] = lazyGetEnumTypeCode();

  // State
  const [currentRegion, setCurrentRegion] = useState<DropdownListDataType>({ name: '', value: '' });
  const [regionList, setRegionList] = useState<DropdownListDataType[]>([]);
  const [layoutType, setLayoutType] = useState<MgdTabLayoutTypeEnum>(MgdTabLayoutTypeEnum.MGD_LIST_SUMMARY);
  const [mainTabSelected, setMainTabSelected] = useState<IMgdTabProps>(EC2_TAB_LIST[0]);
  const [detailItem, setDetailItem] = useState(null);
  const [pageDetail, setPageDetail] = useState<any>(null);
  const [detailImage, setDetailImage] = useState<AwsImageType | undefined>();
  const [currentInstance, setCurrentInstance] = useState<AwsInstanceType | undefined>();
  const [currentSpotInstance, setCurrentSpotInstance] = useState<SpotInstanceRowType | undefined>();
  const [currentBalancer, setCurrentBalancer] = useState<AwsLoadBalancer | undefined>();
  const [detailSnapshot, setDetailSnapshot] = useState<AwsSnapshotType | AwsSnapshotType>();
  const [currentTargetGroup, setCurrentTargetGroup] = useState<AwsTargetGroupRowType | undefined>();

  const cloudId = useMemo((): number => {
    return Number(relatedCloudSelected.value);
  }, [relatedCloudSelected]);
  // Get initial region
  const getInitialRegion = useCallback(() => {
    const hasDefaultRegion = defaultRegionList.find(r => {
      return r.projectId === projectId && r.cloudId === cloudId;
    });

    if (!!hasDefaultRegion) {
      setCurrentRegion({
        name: hasDefaultRegion.name,
        value: hasDefaultRegion.value,
      });
    } else {
      getEnumTypeCode({
        variables: { text: regionCode || 'AwsRegionCode' },
      }).then(res => {
        if (res.data) {
          const regionsRes: DropdownListDataType[] = res.data.getEnumTypeCode.data.filter(val =>
            regions.includes(val.value),
          ).map((val) => ({
            ...val,
            name: val.value
          }));
          setRegionList(regionsRes);
          const region = regionsRes.find(r => r.name === currentRegion.name && r.value === currentRegion.value);
          setCurrentRegion(region || regionsRes[0]);
        }
      });
    }
  }, [cloudId]);

  // For initial data
  useEffect(() => {
    getInitialRegion();
  }, [getInitialRegion]);

  const resetMainStates = useCallback(() => {}, []);

  const mainTabClickHandler = useCallback(
    (tabSelected: IMgdTabProps) => {
      setMainTabSelected(EC2_TAB_LIST.find(tabItem => tabItem.id === tabSelected.id) || EC2_TAB_LIST[0]);
      resetMainStates();

      switch (tabSelected) {
        case EC2_TAB_LIST[0]:
          setCurrentInstance(undefined);
          break;
        case EC2_TAB_LIST[1]:
          setCurrentSpotInstance(undefined);
          break;
        case EC2_TAB_LIST[2]:
          setDetailImage(undefined);
          break;
        case EC2_TAB_LIST[3]:
          break;
        case EC2_TAB_LIST[4]:
          setDetailSnapshot(undefined);
          break;
        case EC2_TAB_LIST[5]:
          break;
        case EC2_TAB_LIST[6]:
          break;
        case EC2_TAB_LIST[7]:
          setCurrentBalancer(undefined);
          break;
        case EC2_TAB_LIST[8]:
          setCurrentTargetGroup(undefined);
          break;
        default:
          break;
      }
    },
    [setMainTabSelected, resetMainStates],
  );

  const pinButtonClickHandle = useCallback(() => {
    updateDefaultRegionList(
      projectId,
      Number(relatedCloudSelected.value),
      currentRegion.name || '',
      currentRegion.value.toString(),
    );
  }, [relatedCloudSelected]);

  const tabContentNode = useMemo(() => {
    switch (mainTabSelected) {
      default:
      case EC2_TAB_LIST[0]: {
        return (
          <Instance
            cloudId={cloudId}
            region={currentRegion}
            tabInformation={mainTabSelected}
            onInstanceSelected={setCurrentInstance}
            onInstanceIdClicked={() => setPageDetail(true)}
          />
        );
      }
      case EC2_TAB_LIST[1]: {
        return (
          <SpotRequest
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            onSpotInstanceSelected={setCurrentSpotInstance}
            onSpotInstanceIdClicked={() => setPageDetail(true)}
          />
        );
      }
      case EC2_TAB_LIST[2]: {
        return (
          <Image
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            tabInformation={mainTabSelected}
            onImageeSelected={setDetailImage}
            onImageIdClicked={() => {
              setPageDetail(true);
            }}
          />
        );
      }
      case EC2_TAB_LIST[3]: {
        return (
          <EBSVolume
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            setDetailItem={setDetailItem}
            setPageDetail={setPageDetail}
          />
        );
      }
      case EC2_TAB_LIST[4]: {
        return (
          <EBSSnapshot
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            tabInformation={mainTabSelected}
            onSnapshotSelected={setDetailSnapshot}
            onSnapshotIdClicked={() => {
              setPageDetail(true);
            }}
          />
        );
      }
      case EC2_TAB_LIST[5]: {
        return <KeyPair cloudId={cloudId} region={currentRegion.value.toString()} />;
      }
      case EC2_TAB_LIST[6]: {
        return (
          <NetworkInterface
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            setDetailItem={setDetailItem}
            setPageDetail={setPageDetail}
          />
        );
      }
      case EC2_TAB_LIST[7]: {
        return (
          <LoadBalancer
            cloudId={cloudId}
            region={currentRegion}
            tabInformation={mainTabSelected}
            onNameClicked={() => setPageDetail(true)}
            onLoadBalancerSelected={setCurrentBalancer}
          />
        );
      }
      case EC2_TAB_LIST[8]: {
        return (
          <LBTargetGroup
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            onNameClicked={() => setPageDetail(true)}
            onTargetGroupSelected={setCurrentTargetGroup}
          />
        );
      }
    }
  }, [mainTabSelected, currentRegion, cloudId, currentInstance]);

  const tabSummaryNode = useMemo(() => {
    switch (mainTabSelected) {
      default:
      case EC2_TAB_LIST[0]: {
        return (
          <InstanceSummary instance={currentInstance!} cloudId={cloudId} region={currentRegion} projectId={projectId} />
        );
      }
      case EC2_TAB_LIST[1]: {
        return (
          <SpotRequestSummary
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            currentSpotInstance={currentSpotInstance!}
          />
        );
      }
      case EC2_TAB_LIST[2]: {
        return <ImageSummary detailData={detailImage} cloudId={cloudId} region={currentRegion.value.toString()} />;
      }
      case EC2_TAB_LIST[3]: {
        return (
          <EBSVolumeSummary
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            detailItem={detailItem}
            setPageDetail={setPageDetail}
          />
        );
      }
      case EC2_TAB_LIST[4]: {
        return (
          <EBSSnapshotSummary detailData={detailSnapshot} cloudId={cloudId} region={currentRegion.value.toString()} />
        );
      }
      case EC2_TAB_LIST[5]: {
        return <KeyRepairSummary />;
      }
      case EC2_TAB_LIST[6]: {
        return (
          <NetworkInterfaceSummary cloudId={cloudId} region={currentRegion.value.toString()} detailItem={detailItem} />
        );
      }
      case EC2_TAB_LIST[7]: {
        return (
          <LoadBalancerSummary detailData={currentBalancer} cloudId={cloudId} region={currentRegion.value.toString()} />
        );
      }
      case EC2_TAB_LIST[8]: {
        return (
          <LBTargetGroupSummary
            targetGroup={currentTargetGroup!}
            cloudId={cloudId}
            region={currentRegion.value.toString()}
          />
        );
      }
    }
  }, [
    mainTabSelected,
    detailItem,
    detailImage,
    cloudId,
    currentRegion,
    currentInstance,
    detailSnapshot,
    currentTargetGroup,
    projectId,
    currentBalancer,
    currentSpotInstance,
  ]);

  const pageBackClick = () => {
    setDetailItem(null);
    setPageDetail(null);
    setCurrentInstance(undefined);
    setCurrentBalancer(undefined);
    setCurrentSpotInstance(undefined);
  };

  const tabDetailNode = useMemo(() => {
    if (!pageDetail) return null;

    switch (mainTabSelected) {
      case EC2_TAB_LIST[0]:
        return (
          <InstanceDetail
            instance={currentInstance!}
            cloudId={cloudId}
            region={currentRegion}
            onBackButtonClicked={pageBackClick}
          />
        );
      case EC2_TAB_LIST[1]:
        return (
          <SpotRequestDetail
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            currentSpotInstance={currentSpotInstance!}
            pageBackClick={pageBackClick}
          />
        );
      case EC2_TAB_LIST[2]:
        return (
          <ImageDetail
            detailData={detailImage}
            pageBackClick={pageBackClick}
            cloudId={cloudId}
            region={currentRegion.value.toString()}
          />
        );
      case EC2_TAB_LIST[3]:
        return (
          <EBSVolumeDetail
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            pageDetail={pageDetail}
            pageBackClick={pageBackClick}
          />
        );
      case EC2_TAB_LIST[4]:
        return (
          <EBSSnapshotDetail
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            detailData={detailSnapshot}
            pageBackClick={pageBackClick}
          />
        );
      case EC2_TAB_LIST[6]:
        return (
          <NetworkInterfaceDetail
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            pageDetail={pageDetail}
            pageBackClick={pageBackClick}
          />
        );
      case EC2_TAB_LIST[7]:
        return (
          <LoadBalancerDetail
            detailData={currentBalancer}
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            onBackButtonClicked={pageBackClick}
          />
        );
      case EC2_TAB_LIST[8]:
        return (
          <LBTargetGroupDetail
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            targetGroup={currentTargetGroup}
            onBackButtonClicked={pageBackClick}
          />
        );
      default:
        return null;
    }
  }, [
    mainTabSelected,
    pageDetail,
    cloudId,
    currentRegion,
    currentInstance,
    detailSnapshot,
    detailImage,
    currentTargetGroup,
    currentBalancer,
    currentSpotInstance,
  ]);

  return (
    <div className="ec2-container" style={{ height: '100%' }}>
      {pageDetail ? (
        tabDetailNode
      ) : (
        <MgdTabLayout
          layoutType={layoutType}
          pageTitle={cloudName}
          hasFavorite={true}
          onBackButtonClicked={onBackButtonClicked}
          pinBtnDisabled={false}
          isPined={true}
          pinBtnActHandle={pinButtonClickHandle}
          headerDropList={regionList}
          headerDropValue={currentRegion}
          headerDropOnchange={val => {
            setCurrentRegion(() => ({
              name: val.name ? val.name : '',
              value: String(val.value),
            }));
          }}
          mainTabList={EC2_TAB_LIST}
          mainTabItemClick={mainTabClickHandler}
          mainTitle={mainTabSelected?.title || ''}
          mainTabSelected={mainTabSelected}
          tabContentNode={tabContentNode}
          tabSummaryNode={tabSummaryNode}
          recentRelatedClouds={recentRelatedClouds}
          relatedCloudSelected={relatedCloudSelected}
          recentRelatedCloudOnChange={relatedCloud => recentRelatedCloudOnChange(relatedCloud)}
        />
      )}
    </div>
  );
};

export default EC2;
