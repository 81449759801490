import { gql } from '@apollo/client';

const query = gql`
query getAwsAssociateWebACL($request: AwsAssociateWebAclRequest, $cloudId: Long, $region: String) {
  getAwsAssociateWebACL(associateWebAclRequest: $request, cloudId: $cloudId, region: $region) {
    result
    messages
    }
  }
`;
export default query;