import { useEffect, useMemo, useState } from "react";
import { DETAIL_TEXT_DESCRIPTION, ELASTIC_IP_SUMMARY_TAB_LIST, DETAIL_KEYS } from "./configs";
import { ElasticIpSummaryPropsType } from "../types";
import DetailTab from "../DetailTab";
import { TextTypeEnum } from "layouts/v3/MgdTabLayout/configs";
import TagTab from "../../../EC2/components/TagTab";
import { ColumnType, RowType } from "@Types/v2/Table";
import { IMgdDetailKeyValueProps } from "layouts/v3/MgdLayout";

const ElasticIpSummary = (props: ElasticIpSummaryPropsType) => {
  const {elasticIp, isSummaryHidden} = props;
  const [tabSelected, setTabSelected] = useState(ELASTIC_IP_SUMMARY_TAB_LIST[0]);

  const elasticIpId = useMemo((): string => {
    return elasticIp?.publicIp ?? '';
  }, [elasticIp]);

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    if (elasticIp) {
      const detailsData: IMgdDetailKeyValueProps[] = [];
      const detailsDataOrdered: IMgdDetailKeyValueProps[] = [];
      Object.entries(elasticIp).map(([key, value]) => {
        if (DETAIL_KEYS.indexOf(key) > -1) {
          const textDes = DETAIL_TEXT_DESCRIPTION.find(text => text.id == key);
          let desValue = '-';
          if (typeof value === 'string') {
            desValue = value.toString();
          }
          if (textDes) {
            detailsData.push({
              id: key,
              type: textDes.type as TextTypeEnum,
              title: textDes.value,
              description: desValue,
            });
          }
        }
      });

      DETAIL_KEYS.map(key => {
        const dataByKey = detailsData.find(data => data.id == key);
        if (dataByKey) detailsDataOrdered.push(dataByKey);
      });
      return detailsDataOrdered;
    }
    return [];
  }, [elasticIp]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const tagRows = useMemo((): RowType[] => {
    return elasticIp?.tags ?? [];
  }, [elasticIp]);

  const tabNode = useMemo(() => {
    switch (tabSelected.id) {
      case ELASTIC_IP_SUMMARY_TAB_LIST[0].id:
        return <DetailTab title={'Details'} data={detailTabContentData} isApiLoading={false} />;

      case ELASTIC_IP_SUMMARY_TAB_LIST[1].id:
        return <TagTab title={'Tags'} rows={tagRows} columns={tagColumns} />;

      default:
        return null;
    }
  }, [tabSelected, elasticIp, tagRows, tagColumns, detailTabContentData]);

  useEffect(() => {
    setTabSelected(ELASTIC_IP_SUMMARY_TAB_LIST[0]);
  }, [elasticIp]);

  if (!elasticIp) {
    return (
      <div className="detail-data">
        <p className="empty-page">Select a item</p>
      </div>
    );
  }

  return (
    <div className="box-summary">
      {!isSummaryHidden && <div className="box-summary-id">{elasticIpId}</div>}

      <div className="detail-tab flex a-center">
        {ELASTIC_IP_SUMMARY_TAB_LIST.map(tab => {
          return (
            <button
              className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
              key={tab.id}
              data-tab={tab.id}
              onClick={e => setTabSelected(tab)}
            >
              <p>{tab.name}</p>
            </button>
          );
        })}
      </div>

      <div className="content-tab">{tabNode}</div>
    </div>
  );
}

export default ElasticIpSummary;