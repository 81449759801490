const policyTab = [
  { id: 'Permissions', name: 'Permissions', title: 'Permissions' },
  { id: 'Entities attached', name: 'Entities attached', title: 'Entities attached' },
  { id: 'Policy versions', name: 'Policy versions', title: 'Policy versions' },
  { id: 'Access advisor', name: 'Access advisor', title: 'Access advisor' },
];

export {
  policyTab
}

