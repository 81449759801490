import React from 'react';
import { ACTION, RATE_BASED_ACTION } from 'pages/v2/Organ/Management/WAF/CustomRuleModal/constant';
import RadioAtom from 'components/v2/atoms/RadioAtom';

type ActionPropsType = {
  action: string;
  setAction: (val: string) => void;
  isRateBasedStatement?: boolean;
};

function Action({ action, setAction, isRateBasedStatement }: ActionPropsType) {
  const actionListNode = () => {
    if (isRateBasedStatement) {
      return RATE_BASED_ACTION.map(({ id, label, value, name }) => (
        <RadioAtom key={id} label={label} value={value} name={name} checked={action} onChange={setAction} />
      ));
    }

    return ACTION.map(({ id, label, value, name }) => (
      <RadioAtom key={id} label={label} value={value} name={name} checked={action} onChange={setAction} />
    ));
  };

  return (
    <div>
      <p className="rule-container-group-content-label">Action</p>
      <p className="rule-container-group-content-description">
        Choose an action to take when a request originates from one of the IP addresses in this IP set.
      </p>
      <div className="space-8">{actionListNode()}</div>
    </div>
  );
}

export default Action;
