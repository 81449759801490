import React, { useCallback, useEffect, useMemo, useState } from 'react';
import RuleName from 'pages/v2/Organ/Management/WAF/CustomRuleModal/IpSet/RuleName';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import lazyGetAwsListRuleGroup from 'graphql/queries/getAwsListRuleGroup';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { RowType } from '@Types/v2/Table';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import { AwsRulelogType } from 'graphql/types/AwsUpdateRuleGroup';

type RuleGroupPropsType = {
  cloudId: number;
  selectedRegion: DropdownListDataType;
  setRule: (rule: AwsRulelogType) => void;
};

function RuleGroup({ cloudId, selectedRegion, setRule }: RuleGroupPropsType) {
  const [ruleName, setRuleName] = useState('');
  const [listRuleGroup, setListRuleGroup] = useState<RowType[]>([]);
  const [ruleGroup, setRuleGroup] = useState<DropdownListDataType>({
    name: '',
    value: '',
  });
  const [isEnable, setIsEnable] = useState(false);
  const [getAwsListRuleGroup] = lazyGetAwsListRuleGroup();

  const rule = useMemo((): AwsRulelogType => {
    const newRule: AwsRulelogType = {};
    newRule.name = ruleName;
    newRule.action = { allow: {} };
    newRule.statement = {};
    newRule.statement.ruleGroupReferenceStatement = {
      arn: listRuleGroup.find(rule => rule.name === ruleGroup.name)?.arn,
    };

    if (isEnable) {
      newRule.overrideAction = {
        count: {},
      };
    }

    return newRule;
  }, [isEnable, ruleName, listRuleGroup, ruleGroup]);

  useEffect(() => {
    setRule(rule);
  }, [rule]);

  const getVariableData = useMemo(
    () => ({
      cloudId: cloudId,
      region: selectedRegion.value !== 'CloudFront' ? String(selectedRegion.value) : 'us-east-1',
      request: {
        limit: 100,
        scope: selectedRegion.value !== 'CloudFront' ? 'REGIONAL' : 'CLOUDFRONT',
      },
    }),
    [selectedRegion],
  );

  const handleGetListRuleGroup = useCallback(
    (nextToken?: string) => {
      if (getVariableData.region) {
        getAwsListRuleGroup({ variables: variableCombineNextToken(getVariableData, nextToken) }).then(({ data }) => {
          if (data && data.getAwsListRuleGroup) {
            setListRuleGroup(data.getAwsListRuleGroup.data?.[0]?.ruleGroups);
          }
        });
      }
    },
    [selectedRegion],
  );

  useEffect(() => {
    handleGetListRuleGroup();
  }, []);

  const getOptionRuleGroup = useMemo(
    () =>
      listRuleGroup.map(e => ({
        name: e.name,
        value: e.name,
      })),
    [listRuleGroup],
  );

  const handleClearState = () => {
    setRuleName('');
    setListRuleGroup([]);
    setRuleGroup({
      name: '',
      value: '',
    });
    setIsEnable(false);
  };

  return (
    <>
      <RuleName className="rule-container-group-border" ruleName={ruleName} setRuleName={setRuleName} />

      <div className="rule-container-group rule-container-group-border">
        <p className="rule-container-group-title">Rule group</p>

        <div className="rule-container-group-content">
          <div className="mw-50">
            <p className="rule-container-group-content-label">Rule group</p>

            <DropdownAtom
              id=""
              data={getOptionRuleGroup}
              placeholder="Choose rule group"
              value={ruleGroup}
              handleClick={val => setRuleGroup(val)}
            />
          </div>

          <div>
            <div className="rule-container-group-content-label">
              <p className="">Override rule group action</p>

              <span>- optional</span>
            </div>

            <p className="rule-container-group-content-description">
              This only overrides the resulting rule group action to count. It doesn’t alter the actions for the rules
              in the rule group. To override the rule actions, save this rule group in your web ACL, and then edit it
              from your web ACL Rules listing and set the rule actions to count. Learn more {'>'}
            </p>

            <div className="checkbox-container space-8">
              <label>
                <CheckboxAtom checked={isEnable} onchange={() => setIsEnable(!isEnable)} />
                Enable
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RuleGroup;
