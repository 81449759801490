import { gql } from '@apollo/client';

const query = gql`
mutation deleteAwsLoggingConfiguration($reqData: AwsDeleteLoggingConfigurationRequest, $cloudId: Long, $region: String) {
  deleteAwsLoggingConfiguration(deleteLoggingConfigurationRequest: $reqData, cloudId: $cloudId, region: $region) {
    result
    data {
      __typename
      }
    }
  }
`;

export default query;