import { RowType } from '@Types/v2/Table';
import './index.scss';
import { useEffect, useMemo, useState } from 'react';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import InputAtom from 'components/v2/atoms/InputAtom';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import { numberRegex } from 'utils/Regex';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import updateCategory from 'graphql/mutations/updateCategory';

interface ICategoryDetailProps {
  cloudList: {
    id: number;
    value: string;
    name: string;
  }[]
  data: RowType;
  convertGridIsShowing: () => void;
  openDeleteModal: () => void;
  onEditSuccess: () => void;
}

const CategoryDetail = ({
  cloudList,
  data,
  convertGridIsShowing,
  openDeleteModal,
  onEditSuccess
}: ICategoryDetailProps) => {

  const { tooltip } = useGModal();

  const [edit, setEdit] = useState(false);
  const [detail, setDetail] = useState({
    categoryId: -1,
    cloudId: -1,
    name: '',
    maskCode: '',
    description: '',
    title: '',
    template: '',
  });

  const [editCategory] = updateCategory();

  const origin = useMemo(() => {
    return {
      categoryId: data.categoryId,
      cloudId: data.cloudId,
      name: data.name,
      maskCode: data.maskCode,
      description: data.description,
      title: data.title,
      template: data.template,
    }
  }, []);

  const enableButton = useMemo(() => {
    if (
        detail.cloudId === -1 || 
        detail.name === '' || 
        detail.maskCode.length < 8 || 
        detail.description === '' ||
        detail.title === '') {
          return true;
        } else return false;
  }, [detail])

  useEffect(() => {
    setDetail({
      categoryId: data.categoryId,
      cloudId: data.cloudId,
      name: data.name,
      maskCode: data.maskCode,
      description: data.description,
      title: data.title,
      template: data.template,
    });
  }, []);

  return (
    <div className='category-detail'>
      <div className='category-detail-header flex a-center'>
        <div className='back-btn' 
          onClick={convertGridIsShowing}
        />  
        {!edit ? detail.name : 'Edit category'}
      </div>
      <div className='detail-contents'>
        <InfoCard
          title="Name" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.name
                : <InputAtom
                    placeholder='Input category name'
                    value={detail.name}
                    onChangeValue={(val) => setDetail((prev) => ({
                      ...prev,
                      name: val
                    }))}
                  />
              }
            </>
          } 
        />
        <InfoCard
          title="Rule cloud" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? enumFormatter(String(cloudList.find(val => val.id === detail.cloudId)?.value))
                : <DropdownAtom 
                  id="cloud-kind"
                  placeholder='Select rule cloud'
                  data={cloudList} 
                  value={{
                    name: cloudList.find(val => val.id === detail.cloudId)?.name
                      ? cloudList.find(val => val.id === detail.cloudId)?.name
                      : '',
                    value: detail.cloudId
                  }}
                   handleClick={(val) => {
                    setDetail(prev => ({
                      ...prev,
                      cloudId: Number(val.id)
                    }))
                   }}                
                />
              }
            </>
          } 
        />
        <InfoCard
          title="Unique delimiter" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.maskCode
                : <InputAtom
                    placeholder='Input delimiter (mask code 8 digits)'
                    value={detail.maskCode}
                    onChangeValue={(val) => {
                      if (val.length > 8) return;

                      setDetail((prev) => ({
                      ...prev,
                      maskCode: val.replace(numberRegex, '')
                    }))
                    }
                  }
                  />
              }
            </>
          } 
        />
        <InfoCard
          title="Description" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.description
                : <InputAtom
                    placeholder='Input description'
                    value={detail.description}
                    onChangeValue={(val) => setDetail((prev) => ({
                      ...prev,
                      description: val
                    }))}
                  />
              }
            </>
          } 
        />
        <InfoCard
          title="Document title" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.title
                : <InputAtom
                    placeholder='Input document title'
                    value={detail.title}
                    onChangeValue={(val) => setDetail((prev) => ({
                      ...prev,
                      title: val
                    }))}
                  />
              }
            </>
          } 
        />
        <InfoCard
          title="Document format (Optional)"
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? !detail.template ? '-' : detail.template
                : <TextareaAtom
                    placeholder='Input document format.'
                    value={detail.template}
                    onChangeValue={(val) => setDetail((prev) => ({
                      ...prev,
                      template: val
                    }))}
                  />
              }
            </>
          } 
        />
        {
          !edit &&
        <>
        <InfoCard
          title="Created"
          content={() => 
            <>
            <u
              onMouseOver={(e) => {
                tooltip.userTooltip({ 
                  top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                    ? e.currentTarget.getBoundingClientRect().bottom - 115
                    : e.currentTarget.getBoundingClientRect().bottom + 12,
                  left: e.currentTarget.getBoundingClientRect().left, 
                  userInfo: { userId: data.createdByUserId, fullName: data.createdByName, createdAt: !data.createdAt ? '-' : data.createdAt, thumbnail: '' }
                });
              }}
              onMouseLeave={tooltip.close}
            >
            {data.createdByName}({data.createdByUserId})</u> / {!data.createdAt ? '-' : dateFormatter(data.createdAt, 'datetime')}
            </> 
          } 
        />
        <InfoCard
          title="Updated"
          content={() => 
            <>
                <u
                  onMouseOver={(e) => {
                    tooltip.userTooltip({ 
                      top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                        ? e.currentTarget.getBoundingClientRect().bottom - 115
                        : e.currentTarget.getBoundingClientRect().bottom + 12,
                      left: e.currentTarget.getBoundingClientRect().left, 
                      userInfo: { userId: data.modifiedByUserId, fullName: data.modifiedByName, createdAt: !data.modifiedAt ? '-' : data.modifiedAt, thumbnail: '' }
                    });
                  }}
                  onMouseLeave={tooltip.close}
                >
                  {data.modifiedByName}({data.modifiedByUserId})</u> / {!data.modifiedAt ? '-' : dateFormatter(data.modifiedAt, 'datetime')}
            </> 
          } 
        />
        </>
        }
      </div>
      <div className="btns flex j-end">
        {
          !edit 
            ? <>
              <button 
                className="big-sub-btn"
                onClick={() => setEdit(true)}
              >
                Edit
              </button>
              <button 
                className="big-main-btn"
                onClick={openDeleteModal}
              >
                Delete
              </button> 
            </>
            : <>
              <button
                className="big-sub-btn"
                onClick={() => {
                  setDetail({ ...origin });
                  setEdit(false);
                }}
              >
                Cancel
              </button>
              <button 
                className="big-main-btn"
                disabled={ enableButton }
                onClick={() => { 
                  const updateCategoryData = {
                    reqData: {
                      ...detail
                    }
                  }
                  editCategory({ variables: updateCategoryData}).then(({ data }) => {
                    if (data) {
                      if (data.updateCategory.result === ErrorCode.SUCCESS) {
                        useToast(ErrorCode.SUCCESS, 'Rule category is updated successfully.');
                        setEdit(false);
                        onEditSuccess(); 
                      } else {
                        console.log(data.updateCategory.result);
                        useToast(ErrorCode.UNKNOWN, 'Update rule category failed.');
                      }
                    } else {
                      useToast(ErrorCode.UNKNOWN, 'Update rule category failed.');
                    }
                  })
                }}
              >
              Save
              </button>
            </>
        }
      </div>
    </div>
  )
}
export default CategoryDetail;