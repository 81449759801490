import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import {
  AwsDescribeManagedRuleGroupRequestType,
  AwsDescribeManagedRuleGroupResponseType,
} from 'graphql/types/AwsDescribeManagedRuleGroup';

export type AwsDescribeManagedRuleGroupVariablesType = {
  cloudId: number;
  region: string;
  request: AwsDescribeManagedRuleGroupRequestType;
};

export type AwsDescribeManagedRuleGroupResponseDataType = {
  getAwsDescribeManagedRuleGroup: IGqlResponseNewData<AwsDescribeManagedRuleGroupResponseType>;
};

const lazyGetAwsDescribeManagedRuleGroup = () =>
  useLazyQuery<AwsDescribeManagedRuleGroupResponseDataType, AwsDescribeManagedRuleGroupVariablesType>(query, {
    fetchPolicy: 'no-cache',
  });

export default lazyGetAwsDescribeManagedRuleGroup;
