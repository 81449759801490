import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';

export interface IDeleteIPSetVariables {
  cloudId: number;
  region: string;
  request: {
    id: string;
    name: string;
    scope: string;
    lockToken: string;
  }
}

export interface IDeleteIPSetResponseData {
  deleteIPSet: IGqlResponseNewData<undefined>;
}

const deleteIPSetMutation = () => useMutation<IDeleteIPSetResponseData, IDeleteIPSetVariables>(query);
export default deleteIPSetMutation;