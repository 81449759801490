import { useLazyQuery } from '@apollo/client';
import { Paging } from 'graphql/types/Paging';
import { ResponsePage } from 'graphql/types/ResponsePage';
import query from './query';
import { OrganMemberType } from '@Types/v4/Member';

export interface IGetMembersByOrganIdVariables {
  reqData: {
    organId: number;
    pageInfo: Paging;
  }
}

export interface IGetMembersByOrganIdResponseData {
  getMembersByOrganId: ResponsePage<OrganMemberType>;
}

const lazyGetMembersByOrganId = () => useLazyQuery<IGetMembersByOrganIdResponseData, IGetMembersByOrganIdVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetMembersByOrganId;