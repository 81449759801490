import './style.scss'
import {IMgdDetailKeyValueProps} from "layouts/v3/MgdLayout";
import { getFormatedDateWithTimezone } from "utils/Common";
import DetailTab from '../../../../components/DetailTab';

function Rotations({data = {}}: any) {
  const {
    rotationEnabled,
    rotationRules,
    lastRotatedDate,
    nextRotationDate,
    rotationLambdaARN,
  } = data

  const detailTabData: IMgdDetailKeyValueProps[] = [
    {
      title: 'Rotation status',
      description: rotationEnabled || 'Disabled'
    },
    {
      title: 'Rotation schedule',
      description: rotationRules?.cheduleExpression
    },
    {
      title: 'Last rotated date (UTC)',
      description: getFormatedDateWithTimezone(lastRotatedDate)
    },
    {
      title: 'Net rotation date (UTC) \n The next rotation is scheduled to occur on or before this date.',
      description: getFormatedDateWithTimezone(nextRotationDate),
    },
    {
      title: 'Lambda rotation function \n The Lambda function that has permissions to rotate this secret.',
      description: rotationLambdaARN,
    },
  ]

  return (
    <div className='details-tab rotation-tab'>
      <DetailTab title='Secret valuRotations configuratione' data={detailTabData} />
    </div>
  );
}

export default Rotations;