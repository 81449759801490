import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import {
  AwsListAvailableManagedRuleGroupVersionsRequestType,
  AwsListAvailableManagedRuleGroupVersionsResponseType,
} from 'graphql/types/AwsListAvailableManagedRuleGroupVersions';

export type AwsListAvailableManagedRuleGroupVersionsVariablesType = {
  cloudId: number;
  region: string;
  request: AwsListAvailableManagedRuleGroupVersionsRequestType;
};

export type AwsListAvailableManagedRuleGroupVersionsResponseDataType = {
  getAwsListAvailableManagedRuleGroupVersions: IGqlResponseNewData<AwsListAvailableManagedRuleGroupVersionsResponseType>;
};

const lazyGetAwsListAvailableManagedRuleGroupVersions = () =>
  useLazyQuery<
    AwsListAvailableManagedRuleGroupVersionsResponseDataType,
    AwsListAvailableManagedRuleGroupVersionsVariablesType
  >(query, {
    fetchPolicy: 'no-cache',
  });

export default lazyGetAwsListAvailableManagedRuleGroupVersions;
