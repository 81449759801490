import classNames from "classnames";
import Button from "pages/v2/Organ/Management/WAF/WebACL/CreateWebACL/components/Button";
import { useRef } from "react";
import ClearIcon from 'assets/svgs/v2/ico_input_clear.svg';

type ValueDataType = {
  value?: string;
};

type InputFormPropsType = {
  type?: 'INPUT' | 'DROPDOWN' | 'VALUE';
  field?: string;
  index?: number;
  clearable?: boolean;
  value?: ValueDataType;
  error?: boolean;
  setValue?: (key: string, value: string, index: number) => void;
};
export const InputForm = (props: InputFormPropsType) => {
  const { type, value, setValue, field, index, error } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnchange = (value: string) => {
    if (setValue) {
      setValue(field ?? '', value, index ?? 0);
    }
  };

  const handleClear = () => {
    handleOnchange('');
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="input-form-container">
      {type === 'INPUT' && (
        <input
          className={classNames("input-form-border", error && 'error')}
          value={value?.value as string}
          onChange={event => {
            setValue && setValue('', event.target.value, 0);
          }}
        />
      )}
      {type === 'VALUE' && (
        <div className="value-wrapper input-form-border">
          <input
            className={classNames('value', error && 'error')}
            value={value?.value as string}
            onChange={event => {
              handleOnchange(event.target.value);
            }}
            ref={inputRef}
          />
          {value?.value && (
            <img
              src={ClearIcon}
              width={26}
              height={26}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleClear();
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export type TagPropsType = {
  key: string;
  value: string;
};

type TagsListPropsType = {
  tags?: TagPropsType[];
  handleRemoveTag?: (index: number) => void;
  handleUpdateTag?: (key: string, value: string, index: number) => void;
  isSubmit?: boolean;
};

export const TagsList = (props: TagsListPropsType) => {
  const { tags, handleRemoveTag, handleUpdateTag, isSubmit } = props;

  const handleUpdateTags = (key: string, value: string, index: number) => {
    if (handleUpdateTag) {
      handleUpdateTag(key, value, index);
    }
  };

  return (
    <div className="tags-list-container">
      <div className="tags-header">
        <div className="tags-header-item title">Key</div>
        <div className="tags-header-item title">Value-optional</div>
        <div style={{ width: 110 }}></div>
      </div>

      <div className="tags-body">
        {tags?.map((tag, index) => {
          return (
            <div key={index} className="tags-row">
              <div className="tags-column">
                <InputForm
                  type="VALUE"
                  value={{ value: tag.key }}
                  index={index}
                  field="key"
                  setValue={(key: string, value: string, index: number) => {
                    handleUpdateTags(key, value, index);
                  }}
                  error={isSubmit && !tag?.key}
                />
              </div>
              <div className="tags-column">
                <InputForm
                  type="VALUE"
                  value={{ value: tag.value }}
                  index={index}
                  field="value"
                  setValue={(key: string, value: string, index: number) => {
                    handleUpdateTags(key, value, index);
                  }}
                />
              </div>
              <Button
                label="Remove tag"
                onClick={() => {
                  handleRemoveTag && handleRemoveTag(index);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};