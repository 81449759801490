import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import Icon from 'components/v2/atoms/Icon';
import RedReport from 'assets/svgs/v2/ico_redcircle_report.svg';
import CurrentStatusCard from '../CurrentStatusCard';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import { ErrorCode } from '@Types/error';
import DocRegisterModal from 'components/v2/modals/DocRegisterModal';
import DeleteReportFileModal from 'components/v2/modals/DeleteReportFileModal';
import { HistReportFileInfo } from 'graphql/types/HistReportFileInfo';
import lazyGetMemos from 'graphql/queries/getMemos';
import lazyGetHistReportFile from 'graphql/queries/getHistReportFile';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import { useToast } from 'hooks/v2/useToast';
import { dateFormatter } from 'utils/Formatter';
import { EnumResFormatCode } from '@Types/Apis/Files';
import deleteReportFile from 'graphql/mutations/deleteReportFile';
import { useMutation } from 'react-query';
import { IUploadReportReq } from 'apis/File/schema';
import apis from 'apis/v2';
import { useAuth } from 'contexts/AuthProvider';
import { timeToKr } from 'utils/timeFormatter';
import { Order } from 'graphql/types/Paging';
import { MemoInfo } from 'graphql/types/MemoInfo';
import IsLoading from 'components/v2/atoms/IsLoading';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import addMemo from 'graphql/mutations/addMemo';
import Memo from 'components/v2/Memo';
import updateHistReportFile from 'graphql/mutations/updateHIstReportFile';
import { PANEL_SAMPLE } from 'utils/v2/DummyData/Chart';
import { ChartType, EnumViewType, Panel } from '@Types/v2/Chart';
import { handleFormatText } from 'utils/Common';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import { TABLE_OFFSET } from 'utils/DummyData/Dropdown';

interface IReportDetailProps {
  data: RowType;
  relObjectType: 'report_w' | 'report_m' | 'event_ri' | 'rep_chrt';
  getOffPage: () => void;
  onPublishSuccess: () => void;
}

const ReportDetail = ({
  data,
  relObjectType,
  getOffPage,
  onPublishSuccess
}:IReportDetailProps) => {
  const { token, role } = useAuth();
  const { tooltip } = useGModal();

  const [modalIsOpen, setModalIsOpen] = useState({
    delete: false,
    fileRegister: false
  });
  const [memoData, setMemoData] = useState<MemoInfo[]>([]);
  const [sort, setSort] = useState<Order>({ 
    target: 'modifiedAt', 
    direction: OrderDirection.DES
  });
  const [rows, setRows] = useState<HistReportFileInfo[]>([]);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selectForIssue, setSelectForIssue] = useState({
    id: -1,
    statusCode: ''
  });
  const [memoSaveTrigger, setMemoSaveTrigger] = useState(false);
  const [panels, setPanels] = useState<Panel[]>([]);
  const [total, setTotal] = useState({
    totalElement: 0,
    totalPage: 0
  });
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    currentPage: 1
  });

  /* memo 요청사항 조회 */
  const [lazyGetMemo] = lazyGetMemos();
  /* memo 추가 */
  const [addMemos] = addMemo();
  /* 문서 이력 조회 */
  const [lazyGetReportHis, { loading: loadingGetHistReportFile }] = lazyGetHistReportFile();
  /* 리포트 파일 삭제 */
  const [deleteReportFiles] = deleteReportFile();
  /* 리포트 발행 */
  const [updHistReportFile] = updateHistReportFile();
  /* 업로드 리포트 */
  const { mutateAsync: _uploadReport } = useMutation((payload:{ file: File, reqUploadReport: IUploadReportReq }) => apis.File.uploadReport(payload, token));

  const COLUMNS:ColumnType[] = useMemo(() => {
    return [
      {
        label: '리포트 이름',
        field: 'fileName',
        renderCell: (row: RowType) => <u 
          className='file-td'
          onClick={() => 
            download({ projectId: data.projectId, hisReportId: Number(row.id) /* === histReportId */, fileName: row.fileName })
          }
        >
          {handleFormatText(row.fileName)}
        </u>,
        sort: true,
      },
      {
        label: '상태',
        field: 'statusCode',
        renderCell: (row: RowType) => <CurrentStatusCard status={row.statusCode} />,
        sort: true
      },
      {
        label: '버전',
        field: 'version',
        sort: true
      },
      {
        label: '요청사항',
        field: 'comment',
        renderCell: (row:RowType) => <>{handleFormatText(!row.comment ? '-' : row.comment)}</>
      },
      {
        label: '수정한 사람',
        field: 'modifiedByName',
        sort: true
      },
      {
        label: '수정 시간',
        field: 'modifiedAt',
        renderCell: (row:RowType) => <>{timeToKr(row.modifiedAt)}</>,
        sort: true
      }
    ];
  }, []);

  /** 파일 다운로드 */
  const download = (params: { projectId: number, hisReportId: number, fileName: string }) => {
    return (apis.File.downloadReport(params, token)).then(p => {
      // blob형태로 데이터 저장 후 download url 연결
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const blob = new Blob([p.data as any], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const objectUrl = URL.createObjectURL(blob);

      const downloadLink = document.createElement('a');
      downloadLink.href = objectUrl;
      downloadLink.download = params.fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();

      setTimeout(() => {
        URL.revokeObjectURL(objectUrl);
      },1000);

    }).catch((e) => {
      console.log('e', e);
    });
  }; 

  const getReportHist = () => {
    const getHistData = {
      reqGetReportFileList: {
        reportId: data.reportId,
        adminYn: role === 'sy_admin' ? true : false, /* lg운영권한은 true, 일반사용자는 false */
        pageInfo: {
          page: 0,
          size: 100,
          orders: [{
            target: sort.target,
            direction: sort.direction
          }]
        }
      }
    }

    lazyGetReportHis({ variables: getHistData }).then(({ data }) => {
      if(data) {
        if (data.getHistReportFiles.result === ErrorCode.SUCCESS) {
          setRows(data.getHistReportFiles.content);
          setTotal({
            totalElement: data.getHistReportFiles.totalElements,
            totalPage: data.getHistReportFiles.totalPages
          });

          if (data.getHistReportFiles.content.length !== 0) {
            getReportChart(data.getHistReportFiles.content[0].id);
            setSelectForIssue({
              id: data.getHistReportFiles.content[0].id,
              statusCode: data.getHistReportFiles.content[0].statusCode
            })
          } else {
            setPanels([]);
          }
        } else {
          console.log(data.getHistReportFiles.result);
        }
      }
    });
  }

  /** 리포트 메모 재호출 */
  const refetchMemos = () => {
    const selectReport = data;
    if (selectReport) {
      lazyGetMemo({ variables: { reqGetMemo: { relObjectId: selectReport.reportId, relObjectType: relObjectType } }})
      .then(({ data }) => {
        if (data) {

          if (data.getMemos.data.length === 0) {
            addMemos({ variables: { memo: { relObjectId: selectReport.reportId, relObjectType: relObjectType, text: '', checkYn: true }} })
            .then(({ data }) => {
              if (data) {
                setMemoData(data.addMemo.data);
              }
            });
          } else {
            setMemoData(data.getMemos.data);
          }
        }
      });
    }
  };

  /* rep_chrt 조회 */
  const getReportChart = (id:number) => {
    lazyGetMemo({ 
      variables: {
        reqGetMemo: { 
          relObjectId: id, 
          relObjectType: 'rep_chrt'
      }
    }})
    .then(({ data }) => {
      if (data) {
        if (data.getMemos.data.length === 0) {

          const proccessed = PANEL_SAMPLE.map((val, idx) => ({
            viewType: val.viewType as EnumViewType,
              title: val.title,
              data: val.data,
              layout: {
                i: val.title,
                x: 4 * (idx % 3),
                y: 0,
                w: 4,
                h: 3,
                minW: 4,
                minH: 3 
              }
          }))
          setPanels(proccessed);

        } else {
          const result: ChartType[] = JSON.parse(data.getMemos.data[0].text).chart;

          const proccessed = result.map((val, idx) => ({
            viewType: val.viewType as EnumViewType,
            title: val.title,
            data: val.data,
            layout: {
              i: val.title,
              x: 4 * (idx % 3),
              y: 0,
              w: 4,
              h: 3,
              minW: 4,
              minH: 3 
            }
          }))
          setPanels(proccessed);
        }
      }
    })
  }

  useEffect(() => {
    getReportHist();
    refetchMemos();

    /* editor js codex-tooltip dom 삭제 */
    return () => {
      setPanels([]);
      const ct_list = document.querySelectorAll('.ct');

      if (ct_list) {
        ct_list.forEach(val => val.remove());
      }
    }
  }, []);

  useEffect(() => {
    const reportDetailPageEl = document.getElementById('report-detail-page');
    const weeklyReportPageEl = document.getElementById('weekly-report-page');

    if (reportDetailPageEl && weeklyReportPageEl) {
      if (weeklyReportPageEl.offsetHeight < reportDetailPageEl.scrollHeight) {
        weeklyReportPageEl.classList.remove('none-scroll');
      } else {
        weeklyReportPageEl.classList.add('none-scroll');
      }
    }

    return () => weeklyReportPageEl?.classList.remove('none-scroll');
  }, [rows.length]);

  return (
    <div id="report-detail-page">
      
      <div className="header flex a-center">
        <button 
          className="back-btn"
          onClick={() => getOffPage()}
        />
        {data.name}
      </div>

      <div className="content-wrap">
        <div className="information flex j-start">
          <div className="flex col">
            <InfoCard 
              title="Name"
              content={() => <>{data.name}</> } 
            />
            <InfoCard
              title="Period"
              content={() => <>{dateFormatter(data.startTime, 'date')} ~ {dateFormatter(data.endTime, 'date')}</> } 
            />
            <InfoCard 
              title="Project"
              content={() => <>{data.projectName}</> } 
            />
            <InfoCard 
              title="Status"
              content={() => <><CurrentStatusCard status={data.currentStatus} /></> } 
            />
          </div>
          <div className="flex col">
            <InfoCard 
              title="Version"
              content={() => <>{!data.currentVersion ? '-' : `v${data.currentVersion}`}</> } 
            />
            <InfoCard 
              title="Created"
              content={() => 
                <>
                  <b
                    onMouseOver={(e) => {
                      tooltip.userTooltip({ 
                        top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                          ? e.currentTarget.getBoundingClientRect().bottom - 115
                          : e.currentTarget.getBoundingClientRect().bottom + 12,
                        left: e.currentTarget.getBoundingClientRect().left, 
                        userInfo: { userId: data.createdByUserId, fullName: data.createdByName, createdAt: data.createdAt, thumbnail: '' }
                      });
                    }}
                    onMouseLeave={tooltip.close}
                  >
                    {data.createdByName}({data.createdByUserId})</b> / {dateFormatter(data.createdAt, 'datetime')}
                </> 
              } 
            />
            <InfoCard 
              title="Updated"
              content={() => 
                <>
                  <b
                    onMouseOver={(e) => {
                      tooltip.userTooltip({ 
                        top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                          ? e.currentTarget.getBoundingClientRect().bottom - 115
                          : e.currentTarget.getBoundingClientRect().bottom + 12,
                        left: e.currentTarget.getBoundingClientRect().left, 
                        userInfo: { userId: data.modifiedByUserId, fullName: data.modifiedByName, createdAt: data.modifiedAt, thumbnail: '' } 
                      });
                    }}
                    onMouseLeave={tooltip.close}
                  >
                    {data.modifiedByName}({(data.modifiedByUserId)})</b> / {dateFormatter(data.modifiedAt, 'datetime')}
                </> 
              } 
            />
          </div>
        </div>
        <div className="memo-wrap">
          <div className="memo-header flex a-center">
            <h5>Comments</h5>
          </div>
          <div className="memo-content flex a-end">
            <div className='editor-wrap'>
              <Memo 
                data={memoData} 
                reportId={data.reportId} 
                projectId={data.projectId} 
                saveTrigger={memoSaveTrigger} 
                resetSaveTrigger={() => {
                  setMemoSaveTrigger(false);
                }}
              />
            </div>
            <button 
              className="big-main-btn"
              onClick={() => {
                setMemoSaveTrigger(true);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>  

      <div className="history-wrap">
        <div className="history-header flex j-between a-center">
          <div className="header-left flex a-center">
            <div className="document-icon" />
            Documents <span>{rows.length !== 0 && rows.length}</span>
          </div>
          <div className="header-right flex a-center">
            { checkedList.length > 0 && `${checkedList.length} selected` }
            <button 
              className="big-sub-btn delete flex j-center a-center"
              disabled={checkedList.length === 0 }
              onClick={() => setModalIsOpen((prev) => ({
                ...prev,
                delete: true
              }))}
            >
              Delete
            </button>
            <button 
              className="big-main-btn flex j-center a-center"
              onClick={() => setModalIsOpen(prev => ({
                ...prev,
                fileRegister: true
              }))}
            >
              Add document
            </button>
            <DropdownAtom
              id="offset-limit"
              data={TABLE_OFFSET}
              value={{
                name: TABLE_OFFSET.find(data => data.value === tablePagination.limit)?.name,
                value: Number(tablePagination.limit)
              }}
              handleClick={(val) => {
                setTablePagination(prev => ({
                  ...prev,
                  limit: Number(val.value),
                  currentPage: 1
                }))
              }}
            />
          </div>
        </div>
        <div className='content-wrap'>
          <div className="content">
            <div className="data-grid-wrap"> 
              <Table
                rows={rows}
                columns={COLUMNS}
                reportCheckedList={(list) => setCheckedList(list)}
                reportSelected={(id) => getReportChart(Number(id)) /* 클릭한 row의 분석도표 불러오기 */}
                sortOption={{
                  target: sort.target,
                  direction: sort.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {setSort({
                    target: target,
                    direction: dir
                  })}
                }}
              />
              {
              rows.length === 0 
                ? <div className="empty flex col j-center a-center">
                  <div className="document-img" />
                  There is no report file.
                </div> 
                : 
                <div className="pagination-wrapper flex a-center">
                  <p className="flex a-center">Total <span>{total.totalElement}</span></p>
                  <TablePagination
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={(page) => setTablePagination(prev => ({
                      ...prev,
                      currentPage: page
                    }))}
                    totalPage={total.totalPage}
                  />
                </div>
              }
            </div> 
          </div>
          { loadingGetHistReportFile &&  <IsLoading dimmed={rows.length !== 0 } /> }
        </div>
      </div>
      {
        role === 'sy_admin' &&
        <div className='report-issue-wrap flex j-between a-center'>
          <div className='issue-title flex a-center'>
            <div className='doc-icon'/>
            Document release
          </div>
          <div className='select-issue flex a-center'>
            <DropdownAtom 
              id="report-issue" 
              disabled={rows.length === 0}
              placeholder={ rows.length === 0 ? 'No selectable report' : 'Select report' }
              data={ rows.map(val => ({
                  name: val.fileName,
                  value: val.id
              })) } 
              value={{
                name: rows.find(val => val.id === selectForIssue.id )?.fileName ? rows.find(val => val.id === selectForIssue.id )?.fileName : '',
                value: selectForIssue.id
              }} 
              handleClick={(val) => { setSelectForIssue({
                id: Number(val.value),
                statusCode: String(rows.find(row => row.id === Number(val.value))?.statusCode)
              }) }} 
            />
            <button 
              className='big-main-btn'
              disabled={selectForIssue.id === -1}
              onClick={() => {
                
                const updateHistReportFileData = {
                  reqData: { 
                    id:selectForIssue.id,
                    statusCode: 'publish0'
                  }
                }
                updHistReportFile({ variables: updateHistReportFileData }).then(({ data }) => {
                  if (data) {

                    if (data.updateHistReportFile.result === ErrorCode.SUCCESS) {
                      useToast(ErrorCode.SUCCESS, 'Report is released successfully.');
                      onPublishSuccess();
                      
                    } else {
                      console.log(data.updateHistReportFile.result);
                      useToast(ErrorCode.UNKNOWN, 'Release report failed.');
                    }
                  } else {
                    useToast(ErrorCode.UNKNOWN, 'Release report failed.');
                  }
                })
              }}
            >
              Release
            </button>
          </div>
        </div>
      }
      {/* {
        panels.length > 0 &&
        <div className='analyze-chart-wrap'>
        <div className='analyze-title flex a-center'>
          <div className='doc-icon'/>
            분석도표
        </div>
        <div className='chart-section-warp'>
          <Section panels={panels} />
        </div>
      </div>
          } */}

      <DeleteReportFileModal 
        open={modalIsOpen.delete}
        data={rows.filter(val => checkedList.includes(String(val.id)))} 
        title={() => 
          <>
            <Icon width={32} height={32} src={RedReport} />
            Delete documents
          </>
        } 
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          delete: false
        }))}
        onDelete={() => {
          const data = {
            reqDelReportFile: {
              histReportIds: checkedList.map(val => Number(val)),
              forcibly: false
            }
          };
          deleteReportFiles({ variables: data }).then(({ data }) => {
            if(data) {
              if (data.deleteReportFile.result === ErrorCode.SUCCESS) {
                setModalIsOpen(prev => ({
                  ...prev,
                  delete: false
                }));
                getReportHist();
                useToast(ErrorCode.SUCCESS, 'Document is deleted successfully.')
              } else {
                console.log(data.deleteReportFile.result);
                useToast(ErrorCode.UNKNOWN, 'Delete document failed.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, 'Delete document failed.');
            }
          });
        }}       
      />

      <DocRegisterModal
        open={modalIsOpen.fileRegister}
        title={ () =>
          <>
            <Icon width={32} height={32} src={RedReport} />
            Add document
          </>
        }
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          fileRegister: false
        }))}
        onRegister={(payload: {
          reportName: string;
          version: string;
          memo: string;
          file: File;
        }) => {
          const uploadRegisterData = {
            file: payload.file,
            reqUploadReport: {
              statusCode: 'initial0', 
              version: payload.version,
              comment: payload.memo,
              fileName: payload.reportName,
              resFormatCode: EnumResFormatCode.excel000, /* 'excel000' | 'picture0' */
              reportId: data.reportId,
              projectId: data.projectId
            }
          };
          _uploadReport((uploadRegisterData)).then(data => {
            if (data.data) {
              if (data.data.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, 'Adding document is completed successfully.');
                setModalIsOpen(prev => ({
                  ...prev,
                  fileRegister: false
                }));
                getReportHist();
              } else {
                console.log(data.data.result);
                useToast(ErrorCode.UNKNOWN, 'Adding document failed.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, 'Adding document failed');
            }
          });
        }}
      />
    </div>
  );
};

export default ReportDetail;
