import { NetworlAclDetailPropsType } from './types';
import PageDetailTitle from '../../../EC2/components/PageDetailTitle';
import { Fragment, ReactNode, useCallback, useMemo, useState } from 'react';
import { TextTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import { DETAIL_TAB_LIST, DETAIL_TEXT_DESCRIPTION } from './configs';
import { ColumnType, RowType } from '@Types/v2/Table';
import InboundOutbound from './Tabs/InboundOutbound';
import TagTab from '../../../EC2/components/TagTab';
import { ButtonType } from '../../../WAF/WebACL/CreateWebACL/components/types';
import { AwsNetworkAclAssociationType, AwsNetworkAclType, AwsPortRangeLog } from 'graphql/types/AwsNetworkAcl';
import SubnetAssociation from './Tabs/SubnetAssociation';
import DetailTab from '../DetailTab';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdLayout';
import { IMgdTabProps } from 'layouts/v3/MgdTabLayout/types';
import { useNavigate } from 'react-router-dom';
import { handleLinkClicked } from '../../../Utils';
import { ManagementTypeEnum } from '../../..';
import { NETWORK_TAB_LIST } from '../../configs';
import { subnetFilterDropdown, vpcFilterDropdown } from '../../Constants';
import EditNetworkRuleModal, { RuleRowData } from './Modals/EditNetworkRuleModal';
import UpdateTagsModal, { TagRowData } from './Modals/UpdateTagsModal';
import createAwsTagsMutation from 'graphql/mutations/createAwsTags';
import deleteAwsTagsMutation, { IDeleteTagsVariables } from 'graphql/mutations/deleteAwsTags';
import { AwsTagType } from 'graphql/types/AwsVpc';
import { ErrorCode } from '@Types/error';
import lazyGetAllAwsNetworkAcls, { IAwsNetworkAclsPageVariables } from 'graphql/queries/getAwsAllNetworkAcls';
import { useToast } from 'hooks/v2/useToast';
import { IGqlResponseData } from 'graphql/types';
import EditSubnetAssociationModal from './Modals/EditSubnetAssociationModal';
import lazyGetAwsSubnetsPageByParam, { IAwsSubnetsVariables } from 'graphql/queries/getAwsSubnets';
import { AwsSubnetType } from 'graphql/types/AwsSubnet';
import replaceNetworkAcIEntryMutation, {
  INetworkAclRuleEntryVariables,
  NetworkAclRuleEntry,
} from 'graphql/mutations/replaceAwsNetworkAcIEntry';
import createNetworkAcIEntryMutation, {
  ICreateNetworkAclRuleEntryVariables,
} from 'graphql/mutations/createAwsNetworkAclEntry';
import deleteNetworkAcIEntryMutation, {
  DeleteNetworkAclRuleEntry,
  IDeleteNetworkAclRuleEntryVariables,
} from 'graphql/mutations/deleteAwsNetworkAcIEntry';
import {
  generatePortRangeValue,
  getNetworkTypeName,
  getNetworkTypeValue,
} from '../../Function';
import _ from 'lodash';
import replaceAwsNetWorkAclAssociationMutation from 'graphql/mutations/replaceAwsNetWorkAclAssociation';

const NetworlAclDetail = (props: NetworlAclDetailPropsType) => {
  const navigate = useNavigate();
  const { cloudId, region, networkAcl, pageBackClick } = props;

  // API
  const [getAllAwsNetworkAcls, { loading: awsNetworkAlcLoading }] = lazyGetAllAwsNetworkAcls();
  const [getAwsSubnet, { loading: awsSubnetLoading }] = lazyGetAwsSubnetsPageByParam();
  const [createAwsTags, { loading: createTagLoading }] = createAwsTagsMutation();
  const [deleteAwsTags, { loading: deleteTagLoading }] = deleteAwsTagsMutation();

  // Inbount & Outbound
  const [replaceNetworkAclRuleEntry, { loading: replaceRuleLoading }] = replaceNetworkAcIEntryMutation();
  const [createNetworkAclRuleEntry, { loading: createRuleLoading }] = createNetworkAcIEntryMutation();
  const [deleteNetworkAclRuleEntry, { loading: deleteRuleLoading }] = deleteNetworkAcIEntryMutation();

  // Subnet association
  const [replaceAwsNetWorkAclAssociation] = replaceAwsNetWorkAclAssociationMutation();

  // State
  const [detailTabSelected, setDetailTabSelected] = useState<IMgdTabProps>(DETAIL_TAB_LIST[0]);
  const [detailNetworkAcl, setDetailNetworkAcl] = useState<AwsNetworkAclType>(networkAcl);
  const [isUpdateTagModalVisible, setUpdateTagModalVisible] = useState<boolean>(false);
  const [listSubnet, setListSubnet] = useState<AwsSubnetType[]>([]);
  const [isUpdateSubnetModalVisible, setUpdateSubnetModalVisible] = useState<boolean>(false);
  const [isUpdateInboundRuleModalVisible, setUpdateInboundRuleModalVisible] = useState<boolean>(false);
  const [isUpdateOutboundRuleModalVisible, setUpdateOutboundRuleModalVisible] = useState<boolean>(false);

  const networkAclId = useMemo((): string => {
    return detailNetworkAcl?.networkAclId ?? '';
  }, [detailNetworkAcl]);

  const currentTagData = useMemo((): TagRowData[] => {
    const tagRowData: TagRowData[] = [];
    if (detailNetworkAcl?.tags && detailNetworkAcl.tags.length > 0) {
      detailNetworkAcl.tags.map((tag, index) => {
        tagRowData.push({
          index: index,
          keyStr: tag.key,
          valueStr: tag.value,
        });
      });
    }
    return tagRowData;
  }, [detailNetworkAcl?.tags]);

  const handleGetLink = (key: string, value: string) => {
    switch (key) {
      case DETAIL_TEXT_DESCRIPTION[1].id:
        return handleLinkClicked({
          navigate,
          link: '/organ/1/manage/network',
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[2].id,
          key: subnetFilterDropdown[0].value.toString(),
          value,
        });

      case DETAIL_TEXT_DESCRIPTION[3].id:
        return handleLinkClicked({
          navigate,
          link: '/organ/1/manage/network',
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[0].id,
          key: vpcFilterDropdown[0].value.toString(),
          value,
        });

      default:
        return null;
    }
  };

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    if (detailNetworkAcl) {
      return DETAIL_TEXT_DESCRIPTION.map(item => {
        let description: any = '';
        if (item.id === DETAIL_TEXT_DESCRIPTION[1].id) {
          const listsubnetIDs = detailNetworkAcl?.associations?.map(
            (association: AwsNetworkAclAssociationType) => association?.subnetId,
          );
          description = listsubnetIDs?.length > 1 ? listsubnetIDs : detailNetworkAcl?.associations?.[0]?.subnetId;
        } else if (item.id === DETAIL_TEXT_DESCRIPTION[2].id) {
          description = detailNetworkAcl?.isDefault ? 'Yes' : 'No';
        } else {
          description = detailNetworkAcl[item.id as keyof AwsNetworkAclType];
        }

        return {
          id: item.id,
          type: item.type as TextTypeEnum,
          isTooltip: item?.isTooltip,
          description: description,
          title: item.value,
          handleItemClick: (value: string) => handleGetLink(item.id, value || description),
        };
      });
    }
    return [];
  }, [detailNetworkAcl]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const tagRows = useMemo((): RowType[] => {
    return detailNetworkAcl?.tags ?? [];
  }, [detailNetworkAcl]);

  const rightButtonsNode = useMemo((): Array<ButtonType> => {
    switch (detailTabSelected) {
      default:
      case DETAIL_TAB_LIST[0]:
        return [
          {
            id: 'eidt-inbound-rules',
            label: 'Edit inbound rules',
            onClick: () => setUpdateInboundRuleModalVisible(true),
          },
        ];
      case DETAIL_TAB_LIST[1]:
        return [
          {
            id: 'eidt-outbound-rules',
            label: 'Edit outbound rules',
            onClick: () => setUpdateOutboundRuleModalVisible(true),
          },
        ];
      case DETAIL_TAB_LIST[2]:
        return [
          {
            id: 'eidt-subnet-associations',
            label: 'Edit subnet associations',
            onClick: () => {
              getSubnets();
              setUpdateSubnetModalVisible(true);
            },
          },
        ];
      case DETAIL_TAB_LIST[3]:
        return [{ id: 'message-tags', label: 'Message tags', onClick: () => setUpdateTagModalVisible(true) }];
    }
  }, [detailTabSelected]);

  const detailContentNode = useMemo((): ReactNode => {
    if (awsNetworkAlcLoading) {
      return (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      );
    }
    switch (detailTabSelected) {
      default:
      case DETAIL_TAB_LIST[0]:
        return <InboundOutbound networkAcl={detailNetworkAcl} isInbound={true} rightButtons={rightButtonsNode} />;
      case DETAIL_TAB_LIST[1]:
        return <InboundOutbound networkAcl={detailNetworkAcl} isInbound={false} rightButtons={rightButtonsNode} />;
      case DETAIL_TAB_LIST[2]:
        return <SubnetAssociation networkAcl={detailNetworkAcl} rightButtons={rightButtonsNode} />;
      case DETAIL_TAB_LIST[3]:
        return <TagTab title={'Tags'} rows={tagRows} columns={tagColumns} rightButtons={rightButtonsNode} />;
    }
  }, [detailNetworkAcl, detailTabSelected, tagRows, tagColumns, rightButtonsNode, awsNetworkAlcLoading]);

  const updateDetailNetworkAcl = useCallback(() => {
    const reqVariable: IAwsNetworkAclsPageVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        maxResults: 50,
        filters: {
          name: 'network-acl-id',
          values: [networkAcl?.networkAclId],
        },
      },
    };
    getAllAwsNetworkAcls({ variables: reqVariable }).then(({ data: responseData }) => {
      const detailResponse = responseData?.getAwsAllNetworkAcls?.data?.[0];
      if (detailResponse) {
        setDetailNetworkAcl(detailResponse);
      }
    });
  }, [networkAcl, cloudId, region]);

  const handleDeleteTags = useCallback(
    async (tags: TagRowData[]): Promise<IGqlResponseData<undefined> | undefined> => {
      const awsTags: AwsTagType[] = tags.map(currentTag => {
        return { key: currentTag.keyStr, value: currentTag.valueStr };
      });
      const deleteReqVar: IDeleteTagsVariables = {
        cloudId: cloudId,
        region: region,
        request: {
          resources: [networkAclId],
          tags: awsTags,
        },
      };
      const { data: responseData } = await deleteAwsTags({ variables: deleteReqVar });

      return responseData?.deleteAwsTags;
    },
    [cloudId, region],
  );

  const handleCreateTags = useCallback(
    async (tags: TagRowData[]): Promise<IGqlResponseData<undefined> | undefined> => {
      const awsTags: AwsTagType[] = tags.map(currentTag => {
        return { key: currentTag.keyStr, value: currentTag.valueStr };
      });
      const createReqVar: IDeleteTagsVariables = {
        cloudId: cloudId,
        region: region,
        request: {
          resources: [networkAclId],
          tags: awsTags,
        },
      };
      const { data: responseData } = await createAwsTags({ variables: createReqVar });

      return responseData?.createAwsTags;
    },
    [cloudId, region],
  );

  const handleUpdateTags = useCallback(
    async (oldTags: TagRowData[], newTags: TagRowData[]) => {
      const isCurrentTagChanged = JSON.stringify(currentTagData) !== JSON.stringify(oldTags);
      if (isCurrentTagChanged) {
        const [deleteResponse, createResponse] = await Promise.all([
          handleDeleteTags(currentTagData),
          handleCreateTags([...oldTags, ...newTags]),
        ]);
        if (deleteResponse?.result === ErrorCode.SUCCESS && createResponse?.result === ErrorCode.SUCCESS) {
          setUpdateTagModalVisible(false);
          useToast(ErrorCode.SUCCESS, 'Update tags successful.');
          updateDetailNetworkAcl();
        } else {
          useToast(ErrorCode.UNKNOWN, 'Update tags failed.');
        }
      } else {
        if (newTags.length > 0) {
          const createResponse = await handleCreateTags(newTags);
          if (createResponse?.result === ErrorCode.SUCCESS) {
            setUpdateTagModalVisible(false);
            useToast(ErrorCode.SUCCESS, 'Update tags successful.');
            updateDetailNetworkAcl();
          } else {
            useToast(ErrorCode.UNKNOWN, 'Update tags failed.');
          }
        } else {
          setUpdateTagModalVisible(false);
        }
      }
    },
    [currentTagData, handleDeleteTags, handleCreateTags],
  );

  const getSubnets = useCallback(() => {
    const subnetReqVar: IAwsSubnetsVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        maxResults: 1000,
        filters: {
          name: 'vpc-id',
          values: [detailNetworkAcl?.vpcId],
        },
      },
    };
    getAwsSubnet({ variables: subnetReqVar }).then(({ data: responseData }) => {
      const subnetResponse = responseData?.getAwsSubnets?.data?.[0]?.subnets;
      setListSubnet(subnetResponse ?? []);
    });
    return [];
  }, [detailNetworkAcl, cloudId, region]);

  const listSubnetAssociated = useMemo((): string[] => {
    return detailNetworkAcl?.associations?.map(association => association.subnetId);
  }, [detailNetworkAcl]);

  const getAwsPortRangeLogObject = useCallback((portRange: string): AwsPortRangeLog | undefined => {
    if (portRange === 'All' || portRange === 'N/A' || portRange === 'null') {
      return undefined;
    }
    if (portRange?.includes('-')) {
      const portRangeSplited = portRange.replaceAll(' ', '').split('-');
      return { from: parseInt(portRangeSplited[0]), to: parseInt(portRangeSplited[1]) };
    } else {
      return { from: parseInt(portRange), to: parseInt(portRange) };
    }
  }, []);

  const currentRules = useMemo((): RuleRowData[] => {
    const ruleEntries: RuleRowData[] = [];
    detailNetworkAcl?.entries?.map(entry => {
      if (entry.egress === isUpdateOutboundRuleModalVisible && entry.ruleNumber != 32767) {
        const typeName = getNetworkTypeName(
          entry?.portRange,
          entry.icmpTypeCode,
          !!entry?.ipv6CidrBlock,
          entry.protocol,
        );
        const typeValue = getNetworkTypeValue(typeName);
        
        ruleEntries.push({
          ruleNumber: entry.ruleNumber.toString(),
          type: typeValue,
          icmpTypeCode: entry.icmpTypeCode,
          protocol: entry.protocol,
          portRange: generatePortRangeValue(entry?.portRange),
          cidrBlock: entry.cidrBlock,
          action: entry.ruleAction,
        });
      }
    });
    return _.sortBy(ruleEntries, 'ruleNumber');
  }, [detailNetworkAcl, isUpdateOutboundRuleModalVisible]);

  const onReplaceEntries = useCallback(
    async (entries: RuleRowData[], isInbound: boolean): Promise<IGqlResponseData<undefined> | undefined> => {
      if (entries.length > 0) {
        const egress = isInbound ? false : true;
        const replaceEntries: NetworkAclRuleEntry[] = [];
        entries.map(entry => {
          replaceEntries.push({
            networkAclId: detailNetworkAcl.networkAclId,
            egress: egress,
            cidrBlock: entry.cidrBlock,
            protocol: entry.protocol,
            ruleAction: entry.action,
            icmpTypeCode: entry.icmpTypeCode,
            portRange: getAwsPortRangeLogObject(entry.portRange),
            ruleNumber: parseInt(entry.ruleNumber),
          });
        });
        const reqVariable: INetworkAclRuleEntryVariables = {
          cloudId: cloudId,
          region: region,
          request: {
            replaceNetworkAclEntries: replaceEntries,
          },
        };
        const { data: responseData } = await replaceNetworkAclRuleEntry({ variables: reqVariable });

        return responseData?.replaceAwsNetworkAcIEntry;
      }
    },
    [detailNetworkAcl, cloudId, region],
  );

  const onCreateEntries = useCallback(
    async (entries: RuleRowData[], isInbound: boolean): Promise<IGqlResponseData<undefined> | undefined> => {
      if (entries.length > 0) {
        const egress = isInbound ? false : true;
        const createEntries: NetworkAclRuleEntry[] = [];
        entries.map(entry => {
          createEntries.push({
            networkAclId: detailNetworkAcl.networkAclId,
            egress: egress,
            cidrBlock: entry.cidrBlock,
            protocol: entry.protocol,
            ruleAction: entry.action,
            icmpTypeCode: entry.icmpTypeCode,
            portRange: getAwsPortRangeLogObject(entry.portRange),
            ruleNumber: parseInt(entry.ruleNumber),
          });
        });
        const reqVariable: ICreateNetworkAclRuleEntryVariables = {
          cloudId: cloudId,
          region: region,
          request: {
            createNetworkAclEntry: createEntries,
          },
        };
        const { data: responseData } = await createNetworkAclRuleEntry({ variables: reqVariable });

        return responseData?.createAwsNetworkAclEntry;
      }
    },
    [detailNetworkAcl, cloudId, region],
  );

  const onDeleteEntries = useCallback(
    async (entries: RuleRowData[], isInbound: boolean): Promise<IGqlResponseData<undefined> | undefined> => {
      if (entries.length > 0) {
        const egress = isInbound ? false : true;
        const deleteEntries: DeleteNetworkAclRuleEntry[] = [];
        entries.map(entry => {
          deleteEntries.push({
            networkAclId: detailNetworkAcl.networkAclId,
            egress: egress,
            ruleNumber: parseInt(entry.ruleNumber),
          });
        });
        const reqVariable: IDeleteNetworkAclRuleEntryVariables = {
          cloudId: cloudId,
          region: region,
          request: {
            deleteNetworkAclEntries: deleteEntries,
          },
        };
        const { data: responseData } = await deleteNetworkAclRuleEntry({ variables: reqVariable });

        return responseData?.deleteAwsNetworkAcIEntry;
      }
    },
    [detailNetworkAcl, cloudId, region],
  );

  const handleUpdateNetworkAclEntries = useCallback(
    async (updatedEntries: RuleRowData[], isInbound: boolean) => {
      const replaceEntries: RuleRowData[] = [];
      let deleteEntries: RuleRowData[] = currentRules;
      const createEntries: RuleRowData[] = [];
      const updatedSortedEntries = _.sortBy(updatedEntries, 'ruleNumber');
      updatedSortedEntries?.map(updateEntry => {
        const currentEntry = currentRules.find(currentRule => currentRule.ruleNumber === updateEntry.ruleNumber);
        if (currentEntry) {
          deleteEntries = deleteEntries.filter(entry => entry.ruleNumber !== currentEntry.ruleNumber);
          if (JSON.stringify(updateEntry) !== JSON.stringify(currentEntry)) {
            replaceEntries.push(updateEntry);
          }
        } else {
          createEntries.push(updateEntry);
        }
      });

      const [replaceResponse, createResponse, deleteResponse] = await Promise.all([
        onReplaceEntries(replaceEntries, isInbound),
        onCreateEntries(createEntries, isInbound),
        onDeleteEntries(deleteEntries, isInbound),
      ]);
      if (
        (deleteResponse === undefined || deleteResponse?.result === ErrorCode.SUCCESS) &&
        (createResponse === undefined || createResponse?.result === ErrorCode.SUCCESS) &&
        (replaceResponse === undefined || replaceResponse?.result === ErrorCode.SUCCESS)
      ) {
        if (isInbound) {
          setUpdateInboundRuleModalVisible(false);
        } else {
          setUpdateOutboundRuleModalVisible(false);
        }
        useToast(ErrorCode.SUCCESS, `Update ${isInbound ? 'inbound' : 'outbound'} successful.`);
        updateDetailNetworkAcl();
      } else {
        useToast(ErrorCode.UNKNOWN, `Update ${isInbound ? 'inbound' : 'outbound'} failed.`);
      }
    },
    [currentRules],
  );

  return (
    <div className="ec2-detail">
      <PageDetailTitle title={`Network ACL / ${networkAclId}`} pageBackClick={pageBackClick} />
      {detailNetworkAcl && (
        <Fragment>
          <DetailTab title={'Details'} data={detailTabContentData} isApiLoading={false} />
          <div className="tab-container">
            <div className="detail-tab flex a-center">
              {DETAIL_TAB_LIST.map(tab => {
                return (
                  <button
                    className={`detail-tab-items ${detailTabSelected?.id === tab.id && 'active'}`}
                    key={tab.id}
                    data-tab={tab.id}
                    onClick={e => setDetailTabSelected(tab)}
                  >
                    <p>{tab.name}</p>
                  </button>
                );
              })}
            </div>

            <div className="content-tab">{detailContentNode}</div>
          </div>
          {isUpdateInboundRuleModalVisible && (
            <EditNetworkRuleModal
              header={'Edit inbound rules'}
              currentEntries={currentRules}
              isInbound={true}
              open={isUpdateInboundRuleModalVisible}
              onClose={() => setUpdateInboundRuleModalVisible(false)}
              onSave={(updateEntries, isInbound) => handleUpdateNetworkAclEntries(updateEntries, isInbound)}
            />
          )}

          {isUpdateOutboundRuleModalVisible && (
            <EditNetworkRuleModal
              header={'Edit outbound rules'}
              currentEntries={currentRules}
              isInbound={false}
              open={isUpdateOutboundRuleModalVisible}
              onClose={() => setUpdateOutboundRuleModalVisible(false)}
              onSave={(updateEntries, isInbound) => handleUpdateNetworkAclEntries(updateEntries, isInbound)}
            />
          )}

          {isUpdateSubnetModalVisible && (
            <EditSubnetAssociationModal
              listSubnetAssociated={listSubnetAssociated}
              listSubnet={listSubnet}
              onSaveChange={() => {
                console.log('Modal Edit Subnet: ');
              }}
              open={isUpdateSubnetModalVisible}
              isLoading={awsSubnetLoading}
              onClose={() => setUpdateSubnetModalVisible(false)}
            />
          )}

          {isUpdateTagModalVisible && (
            <UpdateTagsModal
              header={'Tags'}
              subHeader={'Available subnets'}
              currentDatas={currentTagData}
              open={isUpdateTagModalVisible}
              onSave={(oldTags: TagRowData[], newTags: TagRowData[]) => handleUpdateTags(oldTags, newTags)}
              onClose={() => setUpdateTagModalVisible(false)}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default NetworlAclDetail;
