import { gql } from '@apollo/client';

const query = gql`
  mutation authorizeAwsSecurityGroupEgress(
    $request: AwsAuthorizeSecurityGroupEgressRequest
    $cloudId: Long
    $region: String
  ) {
    authorizeAwsSecurityGroupEgress(authorizeSecurityGroupEgressRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsAuthorizeSecurityGroupEgressResponse {
          returnValue
          securityGroupRules {
            securityGroupRuleId
            groupId
            groupOwnerId
            isEgress
            ipProtocol
            fromPort
            toPort
            cidrIpv4
            cidrIpv6
            prefixListId
            referencedGroupInfo {
              groupId
              peeringStatus
              userId
              vpcId
              vpcPeeringConnectionId
            }
            description
            tags {
              key
              value
            }
          }
        }
      }
    }
  }
`;
export default query;
