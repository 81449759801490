import { useMemo, useState } from 'react';
import { idRegex, pwRegex } from 'utils/Regex';
import Logo from 'assets/svgs/v2/auth_logo.svg';
import LabelInput from 'components/v2/LabelInput';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import apis from 'apis/v2/index';
import { ErrorCode, ErrorMessage } from '@Types/error';

interface ICheckIdProps {
  inputs: { id: string; pw: string; };
  updateInputs: (key: 'id' | 'pw', value: string) => void;
  goNextStep: () => void;
}

const CheckId = ({ inputs, updateInputs, goNextStep }:ICheckIdProps) => {
  
  const [error, setError] = useState<{ type: 'id' | 'pw', msg: string }>({ type: 'id', msg: '' });
  const { mutateAsync: _checkId } = useMutation(
    (payload:{ userId: string }) => apis.SignUp.checkId(payload)
  );

  const disabledNextBtn = useMemo(() => {
    if (inputs.id.length === 0 || inputs.pw.length === 0 || error.msg !== '') {
      return true;
    } else {
      return false;
    }
  },[inputs, error]);

  const resetError = () => {
    setError(prev => ({ ...prev, msg: '' }));
  };
  const tryJoin = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const id = e.currentTarget['join-id'].value;
    const pw = e.currentTarget['join-pw'].value;

    if (id.length < 2 || id.length > 15) { 
      setError({ type: 'id', msg: 'Please enter an ID with a minimum of 2 characters and a maximum of 15 characters.' });
    } else if (!idRegex.test(id)) {
      setError({ type: 'id', msg: 'Please enter your ID using letters and numbers.' });
    } else if (!pwRegex.test(pw) || pw.length > 12) { //todo 피그마 내용과 달리 서버에러는 영대문자, 소문자, 숫자,특수문자의 8자 이상 32글자 이하로 오류 발생함.
      setError({ type: 'pw', msg: 'Please enter a password that is between 8 and 12 characters long, consisting of a combination of numbers, letters, and special characters.' });
    } else {
      _checkId({ userId: id }).then(({ data }) => {
        if (data.result === ErrorCode.SUCCESS) {
          goNextStep();
        } else {
          if (data.result === ErrorCode.USER_ID_DUPLICATED) {
            setError({ type: 'id', msg: 'This ID is already in use. Please enter a different one.' });
          } else {
            setError({ type: 'id', msg: ErrorMessage[data.result] });
          }
        }
      });
    }
  };

  return (
    <article id="join-check-id-article">
      <div className="header">
        <img src={Logo} width={91} height={30} />
      </div>
      <div className="content">
        <h2>Sign up</h2>
        <form onSubmit={tryJoin}>
          <LabelInput
            title="ID" 
            required
            id="join-id" 
            placeholder="Input your ID"
            defaultValue={inputs.id} 
            onChangeValue={(value: string) => {
              error.type === 'id' && error.msg !== '' && resetError();
              updateInputs('id', value);
            }} 
            errorMsg={error.type === 'id' ? error.msg : ''}
          />
          <LabelInput 
            title="Password" 
            required
            type="password" 
            id="join-pw" 
            placeholder="Input your password"
            defaultValue={inputs.pw} 
            onChangeValue={(value: string) => {
              error.type === 'pw' && error.msg !== '' && resetError();
              updateInputs('pw', value);
            }} 
            errorMsg={error.type === 'pw' ? error.msg : ''}
          />
          <button className="submit-btn big-main-btn-body2regular" disabled={disabledNextBtn}>Next</button>
        </form>
        <Link to={'/auth'}>Have an account? Log in</Link>
      </div>
    </article>
  );
};
export default CheckId;