import { useEffect, useRef, useState } from 'react';
import './index.scss';

interface IAccordionNavItemProps {
  titleComponent: () => JSX.Element;
  originListLength: number;
  offset: number;
  listComponent: () => JSX.Element;
}

const AccordionNavItem = ({ 
  titleComponent, 
  offset,
  originListLength,
  listComponent
}:IAccordionNavItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(true);
  
  useEffect(() => {
    if (!ref.current) return;

    if (open) {
      setTimeout(() => {
        if (!ref.current) return;
        ref.current.style.maxHeight = ref.current.scrollHeight + 'px';
      }, 1);
    } else {
      ref.current.style.maxHeight = '0';
    }
  }, [open, originListLength, offset]);
  
  return (
    <div className="accordion-nav-item-comp">
      <div
        className={`collapsible-header flex`}
        onClick={() => setOpen(!open)}
      >
        <div className="flex a-center">
          <div className={`arrow-icon ${/* originListLength !== 0 && */ open && 'active'}`} />
          <div className="title-name flex j-between a-center">
            {titleComponent()}
          </div>
        </div>
      </div>
      <div 
        className="collapsible-body" 
        ref={ref}
      >
        {listComponent()}
      </div>
    </div>
  );
};
export default AccordionNavItem;