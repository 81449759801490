import { gql } from '@apollo/client';

const query = gql`
  query getAwsListAttachedUserPolicies($request: AwsListAttachedUserPoliciesRequest, $cloudId: Long, $region: String) {
    getAwsListAttachedUserPolicies(listAttachedUserPoliciesRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListAttachedUserPoliciesResponse {
          attachedPolicies {
            policyName
            policyArn
          }
          isTruncated
          marker
        }
      }
    }
  }
`;
export default query;
