import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsValidateTemplateRequestType, AwsValidateTemplateResponseType } from 'graphql/types/AwsValidateTemplate';

export type GetAwsValidateTemplateVariablesType = {
  cloudId: number;
  region: string;
  request: AwsValidateTemplateRequestType;
};

export type GetAwsValidateTemplateResponseDataType = {
  getAwsValidateTemplate: IGqlResponseNewData<AwsValidateTemplateResponseType>;
};

const lazyGetAwsValidateTemplate = () =>
  useLazyQuery<GetAwsValidateTemplateResponseDataType, GetAwsValidateTemplateVariablesType>(query, {
    fetchPolicy: 'no-cache',
  });
  
export default lazyGetAwsValidateTemplate;
