import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import Icon from 'components/v2/atoms/Icon';
import IconTokenDomain from 'assets/svgs/v3/ico_policy_variables.svg';
import './index.scss';

interface ITokenDomainListModalProps extends IBaseModalProps {
  header: string;
  subHeader?: string;
  currentDatas: IDomainRowData[];
  columns: string[];
  note?: string;
  onSave: (oldDomains: IDomainRowData[], newDomains: IDomainRowData[]) => void;
  submitLoading?: boolean;
}

export interface IDomainRowData {
  index: number;
  domain: string;
}

const TokenDomainListModal = ({
  header,
  subHeader,
  currentDatas = [],
  columns,
  note,
  onSave,
  submitLoading,
  ...baseModalProps
}: ITokenDomainListModalProps) => {
  const [domainRows, setDomainRows] = useState<IDomainRowData[]>([]);
  const [currentDomainRows, setCurrentDomainRows] = useState<IDomainRowData[]>([]);

  useEffect(() => {
    if (baseModalProps.open) {
      if (currentDatas.length == 0) setDomainRows([{ index: 0, domain: '' }]);
      setCurrentDomainRows(currentDatas);
    } else {
      setDomainRows([]);
      setCurrentDomainRows([]);
    }
  }, [baseModalProps.open]);

  const handleCreate = () => {
    setDomainRows(prevState => {
      const newState = prevState.concat({ index: domainRows.length, domain: '' });
      return newState;
    });
  };

  const handleDelete = (indexToRemove: number) => {
    const newData = domainRows.filter((_, index) => index != indexToRemove);
    setDomainRows(newData);
  };

  const handleChange = (index: number, value: string, propName: keyof IDomainRowData) => {
    const newRows = domainRows.map((row, i) => (i === index ? { ...row, [propName]: value } : row));
    setDomainRows(newRows);
  };

  const handleCurrentDomainDelete = (indexToRemove: number) => {
    const newData = currentDomainRows.filter((_, index) => index != indexToRemove);
    setCurrentDomainRows(newData);
  };

  const handleCurrentDomainChange = (index: number, value: string, propName: keyof IDomainRowData) => {
    const newRows = currentDomainRows.map((row, i) => (i === index ? { ...row, [propName]: value } : row));
    setCurrentDomainRows(newRows);
  };

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconTokenDomain} />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="token-domain-list-model">
        <div className="container-note">
          <div className="note-title">Token domain list - optional</div>
          <div className="note-description">
            Enable the use of tokens across multiple protected applications by entering the application domains here.
            Tokens are used by the challenge and CAPTCHA rule actions. The application integration SDKs, and the ATP and
            Bot control managed the rule groups. <span className="link">Learn more</span>
          </div>
        </div>

        {columns && (
          <div className="domain-row-container">
            <div className="domain-row-input">
              {columns.map((column, index) => {
                return (
                  <div key={`udpate-domain-table-column-${index}`} className="column">
                    <p>{column}</p>
                  </div>
                );
              })}
            </div>
            <div className="domain-row-btn"></div>
          </div>
        )}

        {currentDomainRows && (
          <>
            {currentDomainRows.map((row, index) => (
              <div className="domain-row-container" key={`udpate-token-domain-table-row-${index}`}>
                <div className="domain-row-input">
                  <div className="column">
                    <InputAtom
                      type={'text'}
                      value={row.domain}
                      onChangeValue={(value: string) => handleCurrentDomainChange(index, value, 'domain')}
                      defaultValue={''}
                      noClear={true}
                      hasPrefixIcon={true}
                    />
                  </div>
                </div>
                <div className="domain-row-btn">
                  <button className="button" onClick={() => handleCurrentDomainDelete(index)}>
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </>
        )}

        {domainRows && (
          <>
            {domainRows.map((row, index) => (
              <div className="domain-row-container" key={`udpate-token-domain-table-new-row-${index}`}>
                <div className="domain-row-input">
                  <div className="column">
                    <InputAtom
                      type={'text'}
                      placeholder={'Enter domain'}
                      value={row.domain}
                      onChangeValue={(value: string) => handleChange(index, value, 'domain')}
                      defaultValue={''}
                      noClear={true}
                      hasPrefixIcon={true}
                    />
                  </div>
                </div>
                <div className="domain-row-btn">
                  <button className="button" onClick={() => handleDelete(index)}>
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </>
        )}

        {domainRows.length + currentDomainRows.length < 10 && (
          <div className="add-new-domain-container">
            <button className="normal-btn" onClick={handleCreate}>
              Add token domain
            </button>
            {!!note && (
              <p className="note">
                {note.replace('<number>', (10 - (domainRows.length + currentDomainRows.length)).toString())}
              </p>
            )}
          </div>
        )}

        <div className="cancle-save-container">
          <button className="btn" onClick={baseModalProps.onClose}>
            Cancel
          </button>
          <button className="btn save-btn" onClick={() => onSave(currentDomainRows, domainRows)}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default TokenDomainListModal;
