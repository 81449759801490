import _ from 'lodash';

const RULE_TYPE_DATA = [
  {
    id: 'ipSet',
    value: 'ipSet',
    name: 'ip-set',
    label: 'IP set',
    description: 'Use IP sets to identify a specific list of IP addresses.',
  },
  {
    id: 'ruleBuilder',
    value: 'ruleBuilder',
    name: 'rule-builder',
    label: 'Rule builder',
    description:
      'Use a custom rule to inspect for patterns including query strings, headers, countries, and rate limit violations.',
  },
  {
    id: 'ruleGroup',
    value: 'ruleGroup',
    name: 'rule-group',
    label: 'Rule group',
    description: 'Use a rule group to combine rules into a single logical set,',
  },
];

export enum RuleTypeEnum {
  IP_SET = 'ipSet',
  RULE_BUILDER = 'ruleBuilder',
  RULE_GROUP = 'ruleGroup',
}

export enum RuleEditorSelectorEnum {
  VISUAL = 'ruleVisualEditor',
  JSON = 'ruleJsonEditor',
}

const RULE_EDITOR_SELECTOR = [
  {
    id: RuleEditorSelectorEnum.VISUAL,
    value: RuleEditorSelectorEnum.VISUAL,
    name: 'rule-visual-editor',
    label: 'Rule visual editor',
  },
  {
    id: RuleEditorSelectorEnum.JSON,
    value: RuleEditorSelectorEnum.JSON,
    name: 'rule-json-editor',
    label: 'Rule JSON editor',
  },
];

export enum RuleSubTypeSelectorEnum {
  REGULAR = 'regularRule',
  RATE_BASED = 'rateBasedRule',
}

const RULE_SUB_TYPE_SELECTOR = [
  {
    id: RuleSubTypeSelectorEnum.REGULAR,
    value: RuleSubTypeSelectorEnum.REGULAR,
    name: 'regular-rule',
    label: 'Regular rule',
  },
  {
    id: RuleSubTypeSelectorEnum.RATE_BASED,
    value: RuleSubTypeSelectorEnum.RATE_BASED,
    name: 'rate-based-rule',
    label: 'Rate-based rule',
    description:
      'Limits request rates for requests that match your criteria. Applies the action to matching requests when the limit is reached and removes the action when the rate falls below the limit.',
  },
];

const IP_ADDRESS_SELECTOR = [
  {
    id: 'sourceIpAddress',
    value: 'sourceIpAddress',
    name: 'source-ip-address',
    label: 'Source IP address',
  },
  {
    id: 'ipAddressInHeader',
    value: 'ipAddressInHeader',
    name: 'ip-address-in-header',
    label: 'IP address in header',
  },
];

const POSITION_INSIDE_HEADER = [
  {
    id: 'firstIpAddress',
    value: 'FIRST',
    name: 'first-ip-address',
    label: 'First IP address',
    description: 'Inspect the first IP address in the list of IP addresses in the header.',
  },
  {
    id: 'lastIpAddress',
    value: 'LAST',
    name: 'last-ip-address',
    label: 'Last IP address',
    description: 'Inspect the last IP addresses in the list of IP addresses in the header.',
  },
  {
    id: 'anyIpAddress',
    value: 'ANY',
    name: 'any-ip-address',
    label: 'Any IP address',
    description: 'Inspect all IP addresses in the header for a match. AWS WAF inspects the last 10 IP addresses.',
  },
];

const MISSING_IP_ADDRESS = [
  {
    id: 'match',
    value: 'MATCH',
    name: 'match',
    label: 'Match',
    description: 'Treat as matching',
  },
  {
    id: 'noMatch',
    value: 'NO_MATCH',
    name: 'no-match',
    label: 'No match',
    description: 'Treat as non matching.',
  },
];

const ACTION = [
  {
    id: 'allow',
    value: 'allow',
    name: 'allow',
    label: 'Allow',
  },
  {
    id: 'block',
    value: 'block',
    name: 'block',
    label: 'Block',
  },
  {
    id: 'count',
    value: 'count',
    name: 'count',
    label: 'Count',
  },
  {
    id: 'captcha',
    value: 'captcha',
    name: 'captcha',
    label: 'CAPTCHA',
  },
  {
    id: 'challenge',
    value: 'challenge',
    name: 'challenge',
    label: 'Challenge',
  },
];

const RATE_BASED_ACTION = [
  {
    id: 'block',
    value: 'block',
    name: 'block',
    label: 'Block',
  },
  {
    id: 'count',
    value: 'count',
    name: 'count',
    label: 'Count',
  },
  {
    id: 'captcha',
    value: 'captcha',
    name: 'captcha',
    label: 'CAPTCHA',
  },
  {
    id: 'challenge',
    value: 'challenge',
    name: 'challenge',
    label: 'Challenge',
  },
];

const MATCH_SCOPE = [
  {
    id: 'label',
    value: 'LABEL',
    name: 'label',
    label: 'Label',
  },
  {
    id: 'namespace',
    value: 'NAMESPACE',
    name: 'namespace',
    label: 'Namespace',
  },
];

const HEADERS_MATCH_SCOPE = [
  {
    id: 'allInsect',
    value: 'ALL',
    name: 'all-insect',
    label: 'All- insect keys and values',
  },
  {
    id: 'keys',
    value: 'KEY',
    name: 'keys',
    label: 'Keys',
  },
  {
    id: 'values',
    value: 'VALUE',
    name: 'values',
    label: 'Values',
  },
];

const CONTENT_TO_INSPECT = [
  {
    id: 'allHeaders',
    value: 'all',
    name: 'all-headers',
    label: 'All headers',
  },
  {
    id: 'include',
    value: 'includedHeaders',
    name: 'include',
    label: 'Include headers that have specified keys',
  },
  {
    id: 'exclude',
    value: 'excludedHeaders',
    name: 'exclude',
    label: 'Exclude headers that have specified keys',
  },
];

const CONTENT_TYPE = [
  {
    id: 'plainText',
    value: 'plainText',
    name: 'plain-text',
    label: 'Plain text',
  },
  {
    id: 'json',
    value: 'json',
    name: 'json',
    label: 'JSON',
  },
];

const JSON_MATCH_SCOPE = [
  {
    id: 'all',
    value: 'ALL',
    name: 'all',
    label: 'All',
  },
  {
    id: 'keys',
    value: 'KEY',
    name: 'keys',
    label: 'Keys',
  },
  {
    id: 'values',
    value: 'VALUE',
    name: 'values',
    label: 'Values',
  },
];

const JSON_REQUEST = [
  {
    id: 'none',
    value: '',
    name: 'none',
    label: 'None',
    description: 'Evaluate the tokes that AWS WAF parsed before encountering the invalid JSON',
  },
  {
    id: 'evaluateAsPlainText',
    value: 'EVALUATE_AS_STRING',
    name: 'evaluate-as-plain-text',
    label: 'Evaluate as plain text',
    description: 'Apply transformations and matching criteria to the request body as plain text',
  },
  {
    id: 'match',
    value: 'MATCH',
    name: 'match',
    label: 'Match',
    description: 'Return a match for the request',
  },
  {
    id: 'noMatch',
    value: 'NO_MATCH',
    name: 'no-match',
    label: 'No match',
    description: 'Return no match for the request',
  },
];

const BODY_CONTENT_TO_INSPECT = [
  {
    id: 'fullJsonContent',
    value: 'fullJsonContent',
    name: 'full-json-content',
    label: 'Full JSON content',
  },
  {
    id: 'onlyIncludedElements',
    value: 'onlyIncludedElements',
    name: 'only-included-elements',
    label: 'Only included elements',
  },
];

const JA3_FINGERPRINT = [
  {
    id: 'match',
    value: 'MATCH',
    name: 'match',
    label: 'Match',
    description: 'Treat the web request as matching the rule statement',
  },
  {
    id: 'noMatch',
    value: 'NO_MATCH',
    name: 'noMatch',
    label: 'No match',
    description: 'Treat the web request as non matching the rule statement',
  },
];

const RULE_BUILDER_REQUEST_CONDITION_DATA = [
  {
    id: 0,
    value: 'DEFAULT',
    name: 'matches the statement',
  },
  {
    id: 1,
    value: 'AND',
    name: 'matches all the statements (AND)',
  },
  {
    id: 2,
    value: 'OR',
    name: 'matches at least one of the statements (OR)',
  },
  {
    id: 3,
    value: 'NOT',
    name: 'doesn’t match the statement (NOT)',
  },
];

const RULE_BUILDER_STATEMENT_INSPECT_DATA = [
  {
    id: 0,
    value: 'geoMatchStatement',
    name: 'Originates from a country in',
  },
  {
    id: 1,
    value: 'ipSetReferenceStatement',
    name: 'Originates from an IP address in',
  },
  {
    id: 2,
    value: 'labelMatchStatement',
    name: 'Has a label',
  },
  {
    id: 3,
    value: 'byteMatchStatement',
    name: 'Request components',
    children: [
      {
        id: 4,
        value: 'SINGLE_HEADER',
        name: 'Single header',
      },
      {
        id: 5,
        value: 'ALL_HEADER',
        name: 'All headers',
      },
      {
        id: 6,
        value: 'COOKIES',
        name: 'Cookies',
      },
      {
        id: 7,
        value: 'SINGLE_QUERY',
        name: 'Single query parameter',
      },
      {
        id: 8,
        value: 'ALL_QUERY',
        name: 'All query parameters',
      },
      {
        id: 9,
        value: 'URI_PATH',
        name: 'URI path',
      },
      {
        id: 10,
        value: 'QUERY_STRING',
        name: 'Query string',
      },
      {
        id: 11,
        value: 'BODY',
        name: 'Body',
      },
      {
        id: 12,
        value: 'HTTP_METHOD',
        name: 'HTTP method',
      },
      {
        id: 13,
        value: 'JA3_FIGERPRINT',
        name: 'JA3 fingerprint',
      },
      {
        id: 14,
        value: 'HEADER_ORDER',
        name: 'Header order',
      },
    ],
  },
];

const MATCH_TYPE_DATA = [
  {
    id: 0,
    value: 'EXACTLY',
    name: 'Exactly matches string',
  },
  {
    id: 1,
    value: 'STARTS_WITH',
    name: 'Start with string',
  },
  {
    id: 2,
    value: 'ENDS_WITH',
    name: 'End with string',
  },
  {
    id: 3,
    value: 'CONTAINS',
    name: 'Contains string',
  },
  {
    id: 4,
    value: 'CONTAINS_WORD',
    name: 'Contains string',
  },
];

const TEXT_TRANSFORMATION_DATA = [
  {
    id: 0,
    value: 'none',
    name: 'None',
  },
  {
    id: 1,
    value: 'COMPRESS_WHITE_SPACE',
    name: 'Compress whitespace',
  },
  {
    id: 2,
    value: 'HTML_ENTITY_DECODE',
    name: 'HTML entity decode',
  },
  {
    id: 3,
    value: 'LOWERCASE',
    name: 'Lowercase',
  },
  {
    id: 4,
    value: 'CMD_LINE',
    name: 'Command line',
  },
  {
    id: 5,
    value: 'URL_DECODE',
    name: 'URL decode',
  },
  {
    id: 6,
    value: 'BASE64_DECODE',
    name: 'Base64 decode',
  },
  {
    id: 7,
    value: 'HEX_DECODE',
    name: 'Hex decode',
  },
  {
    id: 8,
    value: 'MD5',
    name: 'MD5',
  },
  {
    id: 9,
    value: 'REPLACE_COMMENTS',
    name: 'Replace comments',
  },
  {
    id: 10,
    value: 'ESCAPE_SEQ_DECODE',
    name: 'Escape sequences decode',
  },
  {
    id: 11,
    value: 'SQL_HEX_DECODE',
    name: 'SQL hex decode',
  },
  {
    id: 12,
    value: 'CSS_DECODE',
    name: 'CSS decode',
  },
  {
    id: 13,
    value: 'JS_DECODE',
    name: 'JS decode',
  },
  {
    id: 14,
    value: 'NORMALIZE_PATH',
    name: 'Normalize path',
  },
  {
    id: 15,
    value: 'NORMALIZE_PATH_WIN',
    name: 'Normalize path Windows',
  },
  {
    id: 16,
    value: 'REMOVE_NULLS',
    name: 'Remove nulls',
  },
  {
    id: 17,
    value: 'REPLACE_NULLS',
    name: 'Replace nulls',
  },
  {
    id: 18,
    value: 'BASE64_DECODE_EXT',
    name: 'Base64 decode extension',
  },
  {
    id: 19,
    value: 'URL_DECODE_UNI',
    name: 'URL decode Unicode',
  },
  {
    id: 20,
    value: 'UTF8_TO_UNICODE',
    name: 'UTF8 to Unicode',
  },
];

const OVERSIZE_HANDLING_DATA = [
  {
    id: 0,
    value: 'CONTINUE',
    name: 'Continue',
  },
  {
    id: 1,
    value: 'MATCH',
    name: 'Match',
  },
  {
    id: 2,
    value: 'NO_MATCH',
    name: 'No match',
  },
];

export {
  RULE_TYPE_DATA,
  RULE_EDITOR_SELECTOR,
  RULE_SUB_TYPE_SELECTOR,
  IP_ADDRESS_SELECTOR,
  POSITION_INSIDE_HEADER,
  MISSING_IP_ADDRESS,
  ACTION,
  MATCH_SCOPE,
  HEADERS_MATCH_SCOPE,
  CONTENT_TO_INSPECT,
  CONTENT_TYPE,
  JSON_MATCH_SCOPE,
  JSON_REQUEST,
  BODY_CONTENT_TO_INSPECT,
  JA3_FINGERPRINT,
  MATCH_TYPE_DATA,
  OVERSIZE_HANDLING_DATA,
  RULE_BUILDER_REQUEST_CONDITION_DATA,
  RULE_BUILDER_STATEMENT_INSPECT_DATA,
  TEXT_TRANSFORMATION_DATA,
  RATE_BASED_ACTION,
};

export const DEFAULT_REQUEST_AGGREGATION_KEY_ITEM: any = {
  id: (Math.random() + 1).toString(36).substring(2, 8),
  type: {
    name: '',
    value: '',
  },
  headerName: '',
  transformation: [
    {
      id: (Math.random() + 1).toString(36).substring(2, 8),
      name: '',
      value: '',
    },
  ],
  queryArgument: '',
  cookieName: '',
  labelNamespace: '',
  headerFieldName: '',
  positionInsideHeader: {
    name: 'First IP address',
    value: 'First IP address',
  },
  missingIpAddress: MISSING_IP_ADDRESS[0].value,
};

export const DEFAULT_STATEMENT_ITEM: any = {
  id: _.uniqueId('statement'),
  statementResults: false,
  inspect: {
    name: '',
    value: '',
  },
  countryCode: [],
  ipAddress: IP_ADDRESS_SELECTOR[0].value,
  headerFieldName: 'X-Forwarded-For',
  positionInsideHeader: POSITION_INSIDE_HEADER[0].value,
  missingIpAddress: MISSING_IP_ADDRESS[0].value,
  ipSet: '',
  matchScope: MATCH_SCOPE[0].value,
  matchKey: '',
  matchType: '',
  size: '',
  transformation: [
    {
      id: (Math.random() + 1).toString(36).substring(2, 8),
      value: '',
    },
  ],
  headerMatchScope: HEADERS_MATCH_SCOPE[0].value,
  contentToInspect: CONTENT_TO_INSPECT[0].value,
  keys: '',
  oversizeHandling: '',
  queryArgument: '',
  contentType: CONTENT_TYPE[0].value,
  jsonMatchScope: JSON_MATCH_SCOPE[0].value,
  jsonRequest: JSON_REQUEST[0].value,
  bodyContentToInspect: BODY_CONTENT_TO_INSPECT[0].value,
  includedElements: '',
  stringToMatch: '',
  ja3Fingerprint: JA3_FINGERPRINT[0].value,
};

export const DEFAULT_CUSTOM_RULE = {
  name: '',
  priority: 0,
  statement: {},
  action: {
    block: {},
  },
  visibilityConfig: {
    sampledRequestsEnabled: true,
    cloudWatchMetricsEnabled: true,
    metricName: '',
  },
};

export const RULE_NAME_REGEX = /^[A-Za-z0-9_-]{1,100}$/g;
