import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import EmailIcon from 'assets/svgs/v2/ico_email_verify.svg';
import { useMutation } from 'react-query';
import apis from 'apis/v2/index';
import { ErrorCode } from '@Types/error';
import { useAuth } from 'contexts/AuthProvider';
import { useToast } from 'hooks/v2/useToast';
import FooterComponent from 'components/v2/FooterComponent';

/** <>초대받은 멤버 인증 결과 -> 회원가입으로 이동 </> */
const VerifyInvitedMemberToken = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { logout, loggedIn, token, userInfo, updateToken } = useAuth();

  const [msg, setMsg] = useState('');
  const [signupInfo, setSignupInfo] = useState({
    memberId: '',
    email: ''
  });
  const [status, setStatus] = useState<'RENEW-MINE' | 'NEED-SIGN' | 'LOGOUT' | ''>('LOGOUT');

  const authToken = searchParams.get('authToken');
  
  const { mutateAsync: _checkMemberVerifyToken } = useMutation((token: string) => apis.User.invitedMemberVerify(token));
  const { mutateAsync: _getUserToken } = useMutation((token:string) => apis.User.getUserToken(token))

  const buttonText = useMemo(() => {
    switch (status) {
      default:
        return '확인';
      case 'RENEW-MINE':
        return '로그인';
      case 'NEED-SIGN':
        return '회원가입';
      }
  }, [status])

  useEffect(() => {
    if (authToken) {
      _checkMemberVerifyToken(authToken)
      .then(({ data }) => {
        if (data.result === ErrorCode.SUCCESS) {
          const result = data.data[0];

          if (result.fullName === '@' && result.userId === '@') {
            setMsg('초대를 통한 이메일 인증이 완료됐습니다. 회원가입을 진행합니다.');
            setStatus('NEED-SIGN');
            setSignupInfo({
              memberId: result.id,
              email: result.email
            });
            
            return;
          }

          if (!loggedIn) return setMsg('이메일 인증이 완료됐습니다. 해당 계정을 통해 로그인해주세요.');
          
          if (userInfo?.nemo.memberId !== result.id) return setMsg('이메일 인증이 완료됐습니다. 해당 계정을 통해 로그인해주세요.');
           
          _getUserToken(token).then(({ data }) => {
              if (data) {
                updateToken(data.data[0]);
                setMsg('이메일 인증이 완료됐습니다.');
                setStatus('RENEW-MINE');
              }
          })
        } 
        /* 이미회원 + 타조직 참여중 */ 
        else if (data.result === ErrorCode.ALREADY_ASSIGNED_ORGAN) {
          navigate('/invite-project', { state: true });
        } 
        else if (data.result === ErrorCode.EMAIL_TOKEN_ERROR) {
          setMsg('The verification time has already expired. Please proceed with verification again.');
        }
        else {
          console.log(data.result);
          setMsg('This is an unauthorized access.');
        }
      })
      .catch(() => setMsg('This is an unauthorized access.'));
    } else {
      useToast(ErrorCode.UNKNOWN, 'This is an unauthorized access.');
      navigate('/auth');
    }
  },[]);

  return (
    <>
      <article id="invited-verify-article">
        <img src={EmailIcon} width={56} height={56} />
        <h2 dangerouslySetInnerHTML={{ __html: msg }} />
          <button 
            className="big-main-btn"
            onClick={() => {
              switch (status) {
                default:
                  return logout();
                case 'RENEW-MINE':
                  return navigate('/auth');
                case 'NEED-SIGN':
                  return navigate('/auth/signup', { state: signupInfo });
                }
            }}
          >
            { buttonText }
        </button>  
      </article>
      <FooterComponent />
    </>
  );
};
export default VerifyInvitedMemberToken;