import BackIcon from "assets/svgs/v3/ico_back.svg";
import Icon from "components/v2/atoms/Icon";
import './index.scss'

const PageDetailTitle = (props: any) => {
  const {title, pageBackClick, isDelete, onDelete} = props;

  return (
    <div className='detail-header'>
      <div className='detail-header-title'>
        <Icon className="back-btn" width={12} height={12} src={BackIcon} onClick={pageBackClick}/>

        <p>{title}</p>
      </div>

      <div className='detail-header-action'>
        {
          isDelete ? (
            <button onClick={onDelete}>Delete</button>
          ) : null
        }
      </div>
    </div>
  );
}

export default PageDetailTitle;