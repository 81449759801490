import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IBanishOrganMemberVariables {
  reqData: {
    organId: number;
    memberId: string;
  }
}

export interface IBanishOrganMemberResponseData {
  banishMemberFromOrgan: IGqlResponseData<string>;
}

/* 프로젝트 나가기, 또는 프로젝트 내보내기 */
const banishOrganMember = () => useMutation<IBanishOrganMemberResponseData, IBanishOrganMemberVariables>(query);
export default banishOrganMember;