import { AwsNetworkAclICMPTypeCodeType, AwsPortRangeLog } from "graphql/types/AwsNetworkAcl";
import { NETWORK_RULES_ICMP_CODE_MAPPING, NETWORK_RULES_ICMP_TYPE_MAPPING, NETWORK_RULES_TYPE_MAPPING } from "../Constant";

export const getNetworkPortRangeValue = (portRange: AwsPortRangeLog, icmpTypeCode: AwsNetworkAclICMPTypeCodeType) => {
  if (portRange) {
    if (portRange.from == portRange.to) {
      return portRange.to;
    } else {
      return 'All';
    }
  }
  if (icmpTypeCode) {
    const imcpTypeMapping = NETWORK_RULES_ICMP_TYPE_MAPPING[icmpTypeCode.type.toString()];
    const imcpCodeMapping = NETWORK_RULES_ICMP_CODE_MAPPING[icmpTypeCode.code.toString()];
    
    let icmpValue;
    if (imcpTypeMapping.icmpType == 'All' && imcpCodeMapping.icmpCode == 'All') {
      return 'All';
    } else {
      if (imcpTypeMapping.icmpType != 'All') {
        icmpValue = imcpTypeMapping.icmpType;
      }
      if (imcpCodeMapping.icmpCode != 'All') {
        icmpValue += ' / ' + imcpCodeMapping.icmpCode;
      }
      return icmpValue;
    }
  }
  return 'All';
};

export const getNetworkTypeValue = (portRange: AwsPortRangeLog, icmpTypeCode: AwsNetworkAclICMPTypeCodeType, hasIpv6: boolean, protocolCode: string) => {
  let networkType;
  if (protocolCode == '-1') {
    networkType = NETWORK_RULES_TYPE_MAPPING['all_traffic'];
  }
  if (protocolCode == '1' || protocolCode == 'icmp') {
    if (icmpTypeCode.code.toString() == '-1' && icmpTypeCode.type.toString() == '-1'){
      networkType = NETWORK_RULES_TYPE_MAPPING['all_icmp_ipv4'];
    }
  }
  if (protocolCode == '58' || protocolCode == 'icmpv6') {
    if (icmpTypeCode.code.toString() == '-1' && icmpTypeCode.type.toString() == '-1'){
      networkType = NETWORK_RULES_TYPE_MAPPING['all_icmp_ipv6'];
    }
  }
  if (protocolCode == '6' || protocolCode == 'tcp') {
    if (portRange.from == 0 && portRange.to == 65535) {
      networkType = NETWORK_RULES_TYPE_MAPPING['all_tcp'];
    } else if (portRange.from != portRange.to
        || (portRange.from == portRange.to && !NETWORK_RULES_TYPE_MAPPING[portRange.from])){
      networkType = NETWORK_RULES_TYPE_MAPPING['custom_tcp'];
    }
  }
  if (protocolCode == '17' || protocolCode == 'udp') {
    if (portRange.from == 0 && portRange.to == 65535) {
      networkType = NETWORK_RULES_TYPE_MAPPING['all_udp'];
    } else if (portRange.from != portRange.to
        || (portRange.from == portRange.to && !NETWORK_RULES_TYPE_MAPPING[portRange.from])) {
      networkType = NETWORK_RULES_TYPE_MAPPING['custom_udp'];
    }
  }
  
  if (!networkType && portRange) {
    if (portRange.from == portRange.to) {
      if (portRange.from == 53) {
        if (protocolCode == '6') {
          networkType = NETWORK_RULES_TYPE_MAPPING['53_tcp'];
        }
        networkType = NETWORK_RULES_TYPE_MAPPING['53_udp'];
      }
      networkType = NETWORK_RULES_TYPE_MAPPING[portRange.to];
    }
  }

  if (!networkType) {
    if (hasIpv6 && !networkType) {
      networkType = NETWORK_RULES_TYPE_MAPPING['custom_icmp_ipv6'];
    } else {
      networkType = NETWORK_RULES_TYPE_MAPPING['custom_icmp_ipv4'];
    }
  }
  if (!networkType && protocolCode != '-1') {
    networkType = NETWORK_RULES_TYPE_MAPPING['custom_protocol'];
  }
  return networkType?.type;
}