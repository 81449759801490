import './index.scss';
import { useState, useEffect, useRef, useCallback, useMemo, Fragment } from 'react';
import PinIcon from 'assets/svgs/v3/ico_pin.svg';
import ResetIcon from 'assets/svgs/v3/ico_reset.svg';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import LinkIcon from 'assets/svgs/v3/ico_link.svg';
import CloseIcon from 'assets/svgs/v2/ico_close.svg';
import Icon from 'components/v2/atoms/Icon';
import InputAtom from 'components/v2/atoms/InputAtom';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import TablePagination from '../../../components/v2/dataDisplay/TablePagination';
import NetworkTitle from 'components/v3/NetworkTitle';
import CopyableText from 'components/v3/CopyableText';
import MgdTooltip from 'components/v2/MgdTooltip';
import Detail from './components/Detail';
import TableDetail from './components/TableDetail';
import DetailMultiColumn, { DetailMultiColumProps } from 'layouts/v3/MgdLayout/components/DetailMultiColumns';
import TableJsonView, { RowHasJsonType } from './components/TableJsonView';
import JsonViewer from 'layouts/v3/MgdLayout/components/JsonViewer';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import DropdownAtomRelatedCloud, { DropdownListDataType } from './components/DropdownAtomRelatedCloud';
// import { DropdownAtom as DropdownAtomRelatedCloud, DropdownListDataType } from './components/DropdownAtom';

export enum MgdLayoutTypeEnum {
  MGD_LIST = 'MGD_LIST',
  MGD_LIST_SUMMARY = 'MGD_LIST_SUMMARY',
  MGD_DETAIL = 'MGD_DETAIL',
  MGD_LIST_MORE = 'MGD_LIST_MORE',
  MGD_LIST_MORE_SUMMARY = 'MGD_LIST_MORE_SUMMARY',
}

export enum TextTypeEnum {
  COPY = 'C',
  LINK = 'L',
  STATUS = 'S',
  NORMAL = 'N',
  YELLOW = 'Y',
  RED = 'R',
  GREEN = 'G',
  COPY_LINK = 'CL',
}

export enum DetailTypeEnum {
  DETAIL = 'DETAIL',
  TABLE = 'TABLE',
  MULTICOLUMN = 'MULTICOLUMN',
  JSONVIEWER = 'JSONVIEWER',
}

export interface IMgdTotalPageProps {
  totalPage: number;
  totalElement: number;
}

export interface IMgdTablePaginationProps {
  limit: number;
  itemPerPage: number;
  target: string;
  direction: OrderDirection;
  currentPage: number;
}

export interface IMgdTabProps {
  id: string;
  name: string;
  title: string;
}

export interface IMgdDetailKeyValueProps {
  id?: string;
  type?: TextTypeEnum;
  title: string | string[];
  description: any;
  isTooltip?: boolean;
  handleItemClick?: any;
}

export interface IMgdDetailTableProps {
  title?: string;
  description?: string;
  isShowButtonGroups?: boolean;
  buttons?: {
    icon?: string;
    title?: string;
    onClick?: () => void;
    iconClassName?: string;
  }[];
  isShowDropdown?: boolean;
  dropdowns?: {
    dropList?: DropdownListDataType[];
    dropValue?: DropdownListDataType;
    dropOnchange?: (option: DropdownListDataType) => void;
  }[];
  rows: RowType[];
  columns: ColumnType[];
  reportCheckedList?: (list: string[]) => void;
  hasPagination?: boolean;
  total?: IMgdTotalPageProps;
  tablePagination?: IMgdTablePaginationProps;
  updateTablePagination?: (key: string, value: number | string | OrderDirection) => void;
}

export interface IMgdDetailContentProps {
  title?: string;
  description?: string;
  actionGroup?: {
    icon?: string;
    title?: string;
    onClick?: () => void;
  }[];
  dropdownGroups?: {
    dropList?: DropdownListDataType[];
    dropValue?: DropdownListDataType;
    dropOnchange?: (option: DropdownListDataType) => void;
  }[];
  type: DetailTypeEnum.DETAIL | DetailTypeEnum.TABLE | DetailTypeEnum.MULTICOLUMN | DetailTypeEnum.JSONVIEWER;
  numberOfColumns?: number;
  detailContentWidth?: number;
  detailHyperLinkClick?: (currTab: IMgdTabProps, data: any) => void;
  mainTabSelected?: IMgdTabProps;
  detailTabSelected?: IMgdTabProps;
  contents?: IMgdDetailKeyValueProps[] | IMgdDetailTableProps[] | DetailMultiColumProps[];
  totalContent?: IMgdDetailKeyValueProps[];
  jsonViewerData?: any;
  isCustomPagination?: boolean;
}

export interface IMgdMainExtraContentProps {
  rows: RowType[];
  columns: ColumnType[];
  total: IMgdTotalPageProps;
  tablePagination: IMgdTablePaginationProps;
  updateTablePagination: (key: string, value: number | string | OrderDirection) => void;
  checkedList: string[];
  reportCheckedList: (list: string[]) => void;
  currentReport: string;
  reportSelected: (id: string) => void;
  ableFetchMore: boolean;
}

export interface IMgdExtraOverviewDetailContentProps {
  title?: string;
  contents?: DetailMultiColumProps[];
}

export interface IMgdMainJsonTableProps {
  rows: RowHasJsonType[];
  columns: ColumnType[];
  total: IMgdTotalPageProps;
  tablePagination: IMgdTablePaginationProps;
  updateTablePagination: (key: string, value: number | string | OrderDirection) => void;
  checkedList: string[];
  reportCheckedList: (list: string[]) => void;
  currentReport: string;
  reportSelected: (id: string) => void;
}

interface IMgdListLayoutProps {
  layoutType?: MgdLayoutTypeEnum;
  pageTitle: string;
  hasPrefixIcon?: boolean;
  hasFavorite: boolean;
  isPined?: boolean;
  pageBackClick: () => void;
  recentRelatedClouds?: DropdownListDataType[];
  relatedCloudSelected?: DropdownListDataType;
  recentRelatedCloudOnChange?: (option: DropdownListDataType) => void;
  headerDropList?: DropdownListDataType[];
  headerDropValue?: DropdownListDataType;
  headerDropOnchange?: (option: DropdownListDataType) => void;
  pinBtnActHandle?: () => void;
  pinBtnDisabled?: boolean;
  mainTabList?: IMgdTabProps[];
  mainTabItemClick?: (tabItem: any) => void;
  mainTitle: string;
  mainTabSelected?: IMgdTabProps;
  mainDescription?: string;
  mainTypeList?: DropdownListDataType[];
  mainTypeValue?: DropdownListDataType;
  mainTypeDropdownOnchange?: (option: DropdownListDataType) => void;
  searchValue?: string;
  searchInputOnchange?: (value: string) => void;
  onSearchKeyDown?: (event: any) => void;
  mainSearchBtnVisible?: boolean;
  maimSearchBtnClicked?: () => void;
  mainDelBtnVisible?: boolean;
  mainDelBtnDisabled?: boolean;
  mainDelBtnClicked?: () => void;
  mainEditBtnVisible?: boolean;
  mainEditBtnDisabled?: boolean;
  mainEditBtnClicked?: () => void;
  mainResetBtnClicked?: () => void;
  mainActionList?: DropdownListDataType[];
  mainActionValue?: DropdownListDataType;
  mainActionDropdownOnchange?: (option: DropdownListDataType) => void;
  mainCreateBtnVisible?: boolean;
  mainCreateBtnLabel?: string;
  mainCreateBtnClicked?: () => void;
  rows: RowType[];
  nextToken?: string;
  fetchMoreTotalRows?: (nextToken: string) => void;
  columns: ColumnType[];
  total: IMgdTotalPageProps;
  tablePagination: IMgdTablePaginationProps;
  updateTablePagination: (key: string, value: number | string | OrderDirection) => void;
  checkedList: string[];
  reportCheckedList: (list: string[]) => void;
  currentReport: string;
  reportSelected: (id: string) => void;
  mainIsLoading?: boolean;
  extraMainContent?: IMgdMainExtraContentProps[];
  mainJsonTable?: IMgdMainJsonTableProps;
  detailStyle?: string;
  detailTitle?: string;
  detailTitleBtnDelVisible?: boolean;
  detailTitleBtnDelClicked?: () => void;
  detailTabSelected?: IMgdTabProps;
  detailDescription?: string;
  detailTabList?: IMgdTabProps[];
  detailTabItemClick?: (event: any) => void;
  hasDetailAction?: boolean;
  detailActionList?: DropdownListDataType[];
  detailActionValue?: DropdownListDataType;
  detailActionDropdownOnchange?: (option: DropdownListDataType) => void;
  detailsData?: IMgdDetailKeyValueProps[];
  detailsDataNumberOfColumns?: number;
  detailsExtraData?: IMgdDetailContentProps[];
  detailHyperLinkClick?: (currTab: IMgdTabProps, data: any) => void;
  detailsTblData?: {
    rows: RowType[];
    nextToken?: string;
    columns: ColumnType[];
    total: IMgdTotalPageProps;
    tablePagination: IMgdTablePaginationProps;
    updateTablePagination: (key: string, value: number | string | OrderDirection) => void;
  };
  detailActionTitle?: string;
  detailActionDescription?: string;
  detailContents?: IMgdDetailContentProps[];
  isLoading?: boolean;
  detailIsLoading?: boolean;
  extraOverviewDetailIsLoading?: boolean;
  moreBtnClicked?: () => void;
  searchMoreSumValue?: string;
  searchInpuMoreSumOnchange?: (value: string) => void;
  itemTitleMoreSum?: string;
  closeMoreSumBtnClicked?: () => void;
  moreSumContent?: {
    subTitle?: string;
    content?: {
      key: string;
      value: string;
    }[];
  }[];
  extraOverviewDetail?: IMgdExtraOverviewDetailContentProps;
  detailEditBtnVisible?: boolean;
  detailEditBtnTitle?: string;
  detailEditBtnClicked?: () => void;
  noDetailsDataMessage?: string;
  jsonViewerData?: any;
}

const MgdLayout = ({
  layoutType = MgdLayoutTypeEnum.MGD_LIST,
  isLoading,
  pageTitle,
  hasPrefixIcon,
  hasFavorite,
  isPined = false,
  pageBackClick,
  recentRelatedClouds,
  relatedCloudSelected,
  recentRelatedCloudOnChange,
  headerDropList,
  headerDropValue,
  headerDropOnchange,
  pinBtnActHandle,
  pinBtnDisabled,
  mainTabList,
  mainTabItemClick,
  mainTitle,
  mainTabSelected,
  mainDescription,
  mainTypeList,
  mainTypeValue,
  mainTypeDropdownOnchange,
  searchValue,
  searchInputOnchange,
  onSearchKeyDown,
  mainSearchBtnVisible,
  maimSearchBtnClicked,
  mainDelBtnVisible,
  mainDelBtnDisabled,
  mainDelBtnClicked,
  mainEditBtnVisible,
  mainEditBtnDisabled,
  mainEditBtnClicked,
  mainResetBtnClicked,
  mainActionList,
  mainActionValue,
  mainActionDropdownOnchange,
  mainCreateBtnVisible,
  mainCreateBtnLabel,
  mainCreateBtnClicked,
  // totalRows,
  rows,
  // setCurrentRows,
  nextToken,
  fetchMoreTotalRows,
  columns,
  total,
  tablePagination,
  updateTablePagination,
  checkedList,
  reportCheckedList,
  currentReport,
  reportSelected,
  mainIsLoading,
  extraMainContent,
  mainJsonTable,
  detailStyle,
  detailTitle,
  detailTitleBtnDelVisible,
  detailTitleBtnDelClicked,
  detailTabSelected,
  detailDescription,
  detailTabList,
  detailTabItemClick,
  hasDetailAction,
  detailActionList,
  detailActionValue,
  detailActionDropdownOnchange,
  detailsData,
  detailsDataNumberOfColumns,
  detailsExtraData,
  detailHyperLinkClick,
  detailsTblData,
  detailActionTitle,
  detailActionDescription,
  detailContents,
  moreBtnClicked,
  searchMoreSumValue,
  searchInpuMoreSumOnchange,
  itemTitleMoreSum,
  closeMoreSumBtnClicked,
  moreSumContent,
  extraOverviewDetail,
  detailEditBtnVisible,
  detailEditBtnTitle,
  detailEditBtnClicked,
  noDetailsDataMessage,
  detailIsLoading,
  extraOverviewDetailIsLoading,
}: IMgdListLayoutProps) => {
  const handleActiveTabClick = (tab: any) => {
    if (mainTabItemClick) {
      mainTabItemClick(tab);
    }
  };

  const handleActiveDetailTabClick = (tab: any) => {
    if (detailTabItemClick) {
      detailTabItemClick(tab);
    }
  };

  const detailInfoRef = useRef<HTMLDivElement>(null);

  const [detailInfoWidth, setDetailInfoWidth] = useState(0);

  const renderDetailHeader = useMemo(() => {
    if (layoutType === MgdLayoutTypeEnum.MGD_LIST) {
      return (
        <>
          <div className="detail-tab flex a-center">
            {detailTabList?.map(tab => {
              return (
                <button
                  className={`tab-items ${detailTabSelected?.id === tab.id && 'active'}`}
                  key={tab.id}
                  data-tab={tab.id}
                  onClick={e => handleActiveDetailTabClick(tab)}
                >
                  <p>{tab.title}</p>
                </button>
              );
            })}
          </div>

          <div className="detail-action flex j-between a-center">
            <div className="title">
              {detailActionTitle && <p>{detailActionTitle}</p>}
              {detailActionDescription && <p>{detailActionDescription}</p>}
            </div>
            <div className="flex action a-center">
              {detailActionList && detailActionValue && detailActionDropdownOnchange && (
                <DropdownAtom
                  id="actions-detail-dropdown"
                  className=""
                  data={detailActionList}
                  value={detailActionValue}
                  handleClick={val => detailActionDropdownOnchange(val)}
                />
              )}
              <button className="create-btn">Create</button>
            </div>
          </div>
        </>
      );
    }
    if (layoutType === MgdLayoutTypeEnum.MGD_LIST_SUMMARY) {
      return (
        <>
          {hasDetailAction && (
            <div className="detail-action flex j-between a-center">
              <div className="title">
                {detailActionTitle && <p>{detailActionTitle}</p>}
                {detailActionDescription && <p>{detailActionDescription}</p>}
              </div>
              <div className="flex action a-center">
                {detailActionList && detailActionValue && detailActionDropdownOnchange && (
                  <DropdownAtom
                    id="actions-detail-dropdown"
                    className=""
                    data={detailActionList}
                    value={detailActionValue}
                    handleClick={val => detailActionDropdownOnchange(val)}
                  />
                )}
                <button className="create-btn">Create</button>
              </div>
            </div>
          )}

          <div className="detail-tab flex a-center">
            {detailTabList?.map(tab => {
              return (
                <button
                  className={`tab-items ${detailTabSelected?.id === tab.id && 'active'}`}
                  key={tab.id}
                  data-tab={tab.id}
                  onClick={e => handleActiveDetailTabClick(tab)}
                >
                  <p>{tab.title}</p>
                </button>
              );
            })}
          </div>

          {detailIsLoading ? (
            <div className="progress-container">
              <div className="progress-gif" />
              Loading ...
            </div>
          ) : (
            <>
              {detailTitle && (
                <div className="detail-action flex j-between a-center">
                  <div className="title">
                    {detailTitle && <p>{detailTitle}</p>}
                    {detailDescription && <p>{detailDescription}</p>}
                  </div>
                </div>
              )}
            </>
          )}
        </>
      );
    }

    if (layoutType === MgdLayoutTypeEnum.MGD_DETAIL) {
      return (
        <>
          <div className="detail-action flex j-between a-center">
            <div className="title">
              {detailActionTitle && <p>{detailActionTitle}</p>}
              {detailActionDescription && <p>{detailActionDescription}</p>}
            </div>
            <div className="flex action a-center">
              {detailActionList && detailActionValue && detailActionDropdownOnchange && (
                <>
                  <DropdownAtom
                    id="actions-detail-dropdown"
                    className=""
                    data={detailActionList}
                    value={detailActionValue}
                    handleClick={val => detailActionDropdownOnchange(val)}
                  />
                  <button className="create-btn">Create</button>
                </>
              )}
              {detailEditBtnVisible && (
                <button className="detail-edit-btn" onClick={detailEditBtnClicked}>
                  {detailEditBtnTitle}
                </button>
              )}
            </div>
          </div>
        </>
      );
    }
  }, [handleActiveDetailTabClick, detailIsLoading]);

  useEffect((): any => {
    const observer = new ResizeObserver(entries => {
      setDetailInfoWidth(entries[0].contentRect.width);
    });

    if (detailInfoRef.current) {
      observer.observe(detailInfoRef.current);
      return () => detailInfoRef.current && observer.unobserve(detailInfoRef.current);
    }
    return () => detailInfoRef.current && observer.unobserve(detailInfoRef.current);
  }, [renderDetailHeader]);

  const renderDetailTabContent = useMemo(() => {
    if (detailsData && detailTabList && detailTabSelected?.id === detailTabList[0].id) {
      return (
        <div className="detail-info flex" ref={detailInfoRef}>
          {detailsData.map((detail, index) => (
            <div
              className={`col ${
                (index + 1) % Math.floor((detailInfoWidth > 1160 ? 1160 : detailInfoWidth) / 290) === 0 ? 'last' : ''
              }`}
              key={`${index}`}
            >
              <div className="content">
                <div className="title">
                  <p>{detail.title}</p>
                </div>
                {detail.type === TextTypeEnum.COPY && (
                  <>{detail.description != '-' ? <CopyableText text={detail.description} /> : <p>-</p>}</>
                )}
                {detail.type === TextTypeEnum.STATUS && (
                  <div className="status">
                    <p>{detail.description}</p>
                  </div>
                )}
                {detail.type === TextTypeEnum.LINK && (
                  <>
                    {detail.description ? (
                      <div className="value flex link">
                        <Icon
                          width={14}
                          height={14}
                          src={LinkIcon}
                          onClick={() => {
                            detailHyperLinkClick &&
                              detailHyperLinkClick(mainTabSelected ? mainTabSelected : ({} as IMgdTabProps), detail);
                          }}
                        />
                        {detail.description.type ? (
                          <MgdTooltip
                            id={`${detail.description.type}-tooltip`}
                            title={`${detail.description.values.length} ${detail.description.title}`}
                          >
                            {detail.description.values.map((value: string, index: number) => {
                              return (
                                <div
                                  key={index}
                                  className="link"
                                  style={{
                                    color: 'var(--grey-950)',
                                    fontSize: '13px',
                                    fontWeight: '400',
                                    lineHeight: '120%',
                                    whiteSpace: 'nowrap',
                                    marginRight: '24px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    detailHyperLinkClick &&
                                      detailHyperLinkClick(mainTabSelected ? mainTabSelected : ({} as IMgdTabProps), {
                                        ...detail,
                                        description: value,
                                      });
                                  }}
                                >
                                  {value}
                                </div>
                              );
                            })}
                          </MgdTooltip>
                        ) : (
                          <p
                            onClick={() => {
                              detailHyperLinkClick &&
                                detailHyperLinkClick(mainTabSelected ? mainTabSelected : ({} as IMgdTabProps), detail);
                            }}
                          >
                            {detail.description}
                          </p>
                        )}
                      </div>
                    ) : (
                      <p>-</p>
                    )}
                  </>
                )}
                {detail.type === TextTypeEnum.NORMAL && (
                  <div className="value">
                    <p>{detail.description}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      );
    }

    if (detailsData && detailsTblData && detailTabList && detailTabSelected?.id != detailTabList[0].id) {
      const { rows, nextToken, columns, total, tablePagination, updateTablePagination } = detailsTblData;
      if (rows && columns && total && tablePagination) {
        if (rows.length == 0) {
          return <p className="empty-row">Empty</p>;
        }
        return (
          <div
            className={`flex detail-table ${detailTabSelected?.title == 'Tags' ? 'tags-table' : ''}`}
            ref={detailInfoRef}
          >
            <Table
              rows={rows}
              columns={columns}
              sortOption={{
                target: tablePagination.target,
                direction: tablePagination.direction,
                onChangeSort: (target: string, dir: OrderDirection) => {
                  updateTablePagination('target', target);
                  updateTablePagination('direction', dir);
                },
              }}
            />
            {detailTabSelected?.title != 'Tags' && (
              <div className="pagination-wrapper flex a-center">
                <p className="flex a-center text-paging">
                  Total <span>{total.totalElement}</span>
                </p>
                <TableManagePagination
                  ableFetchMore={!!nextToken}
                  currentPage={tablePagination.currentPage}
                  updateCurrentPage={page => updateTablePagination('currentPage', page)}
                  totalPage={total.totalPage}
                />
              </div>
            )}
          </div>
        );
      }
    }
    return detailContents?.map((detailContent, index) => {
      switch (detailContent.type) {
        case DetailTypeEnum.DETAIL:
          return (
            <Fragment key={`detail_info_${index}`}>
              <div className="extra-detail-info flex" ref={detailInfoRef}>
                {detailContent?.title && <div className="detail-info-title">{detailContent.title}</div>}
                <Detail
                  {...detailContent}
                  detailContentWidth={detailInfoWidth}
                  detailHyperLinkClick={detailHyperLinkClick}
                  mainTabSelected={mainTabSelected}
                />
              </div>
            </Fragment>
          );
        case DetailTypeEnum.TABLE:
          return (
            <div
              className={`flex detail-table ${detailTabSelected?.title == 'Tags' ? 'tags-table' : ''}`}
              ref={detailInfoRef}
              key={`detail_info_${index}`}
            >
              <TableDetail {...detailContent} detailTabSelected={detailTabSelected} isCustomPagination={true} />
            </div>
          );
        case DetailTypeEnum.MULTICOLUMN:
          return (
            <div ref={detailInfoRef} key={`detail_info_${index}`}>
              <DetailMultiColumn
                data={detailContent.contents as DetailMultiColumProps[]}
                handleHDetailHyperlinkMainClick={detailHyperLinkClick}
                currentTab={mainTabSelected ? mainTabSelected : ({} as IMgdTabProps)}
              />
            </div>
          );
        case DetailTypeEnum.JSONVIEWER:
          return (
            <div ref={detailInfoRef} key={`detail_info_${index}`}>
              <JsonViewer data={detailContent.jsonViewerData} title={detailContent.title} />
            </div>
          );
        default:
          break;
      }
    });
  }, [detailTabSelected, detailsTblData, detailInfoWidth, detailContents, detailIsLoading]);

  const renderExtraOverviewDetail = useMemo(() => {
    return (
      <>
        {(mainIsLoading || extraOverviewDetailIsLoading) && extraOverviewDetail != undefined ? (
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        ) : (
          <>
            {extraOverviewDetail && (
              <div className="overview-container">
                <p className="title">{extraOverviewDetail?.title}</p>
                <DetailMultiColumn
                  data={extraOverviewDetail?.contents as DetailMultiColumProps[]}
                  handleHDetailHyperlinkMainClick={detailHyperLinkClick}
                  currentTab={mainTabSelected ? mainTabSelected : ({} as IMgdTabProps)}
                />
              </div>
            )}
          </>
        )}
      </>
    );
  }, [mainIsLoading, extraOverviewDetailIsLoading, extraOverviewDetail]);

  const renderSummaryInfo = useMemo(() => {
    if (currentReport === '' || currentReport == null || currentReport == undefined) {
      return (
        <div className="detail-data">
          <p className="empty-page">Select a item</p>
        </div>
      );
    } else {
      return (
        <div className={`detail-data ${detailStyle}`}>
          {renderExtraOverviewDetail}
          {renderDetailHeader}
          {detailIsLoading == false && <>{renderDetailTabContent}</>}
        </div>
      );
    }
  }, [layoutType, currentReport, detailTabSelected, renderDetailHeader, setDetailInfoWidth, detailIsLoading]);

  const renderMoreSumContent = useMemo(() => {
    if (moreSumContent && moreSumContent?.length > 0) {
      return (
        <div className="sub-container">
          {moreSumContent?.map((moreData, ind) => {
            return (
              <div className="sub-content" key={ind.toString()}>
                <p className="title">{moreData.subTitle}</p>
                {moreData?.content && (
                  <>
                    {moreData?.content?.map((content, i) => {
                      return (
                        <div className="flex" key={`${ind}_${i}`}>
                          <p>{content.key}</p>
                          <p>{content.value}</p>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            );
          })}
        </div>
      );
    } else {
      return <></>;
    }
  }, [moreSumContent]);

  const containerRef = useRef<HTMLDivElement>(null);
  const firstHalfRef = useRef<HTMLDivElement>(null);
  const secondHalfRef = useRef<HTMLDivElement>(null);
  const resizerRef = useRef<HTMLDivElement>(null);

  const mgdLayoutRef = useRef<HTMLDivElement>(null);
  const rowOneRef = useRef<HTMLDivElement>(null);
  const rowTwoRef = useRef<HTMLDivElement>(null);

  const [resizeHeight, setResizeHeight] = useState<number>();
  const [resizeMoreSumHeight, setResizeMoreSumHeight] = useState<number>();

  useEffect(() => {
    let heightDependLayout: number = 43;
    if (layoutType === MgdLayoutTypeEnum.MGD_LIST_MORE) {
      heightDependLayout = 107;
    }
    if (layoutType === MgdLayoutTypeEnum.MGD_LIST_MORE_SUMMARY) {
      heightDependLayout = 100;
    }
    const resizeContentHeight =
      Number(mgdLayoutRef.current?.clientHeight) -
      Number(rowOneRef.current?.clientHeight) -
      Number(rowTwoRef.current?.clientHeight) -
      heightDependLayout;
    setResizeHeight(resizeContentHeight);
    if (searchInpuMoreSumOnchange) {
      setResizeMoreSumHeight(resizeContentHeight - 50);
    } else {
      setResizeMoreSumHeight(resizeContentHeight);
    }
  }, [mgdLayoutRef, rowOneRef, rowTwoRef, layoutType]);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      const startPos = {
        x: e.clientX,
        y: e.clientY,
      };
      const currentFirstHeight = firstHalfRef.current?.getBoundingClientRect().height;
      const currentFirstWidth = firstHalfRef.current?.getBoundingClientRect().width;

      const handleMouseMove = (e: any) => {
        const dx = e.clientX - startPos.x;
        const dy = e.clientY - startPos.y;
        if (layoutType === MgdLayoutTypeEnum.MGD_LIST_MORE_SUMMARY) {
          updateStyle('width', currentFirstWidth, dy, dx);
        } else {
          updateStyle('height', currentFirstHeight, dy, dx);
        }

        updateCursor();
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        resetCursor();
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [layoutType],
  );

  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    const touch = e.touches[0];
    const startPos = {
      x: touch.clientX,
      y: touch.clientY,
    };
    const currentFirstHeight = firstHalfRef.current?.getBoundingClientRect().height;
    const currentFirstWidth = firstHalfRef.current?.getBoundingClientRect().width;

    const handleTouchMove = (e: any) => {
      const touch = e.touches[0];
      const dx = touch.clientX - startPos.x;
      const dy = touch.clientY - startPos.y;
      if (layoutType === MgdLayoutTypeEnum.MGD_LIST_MORE_SUMMARY) {
        updateStyle('width', currentFirstWidth, dy, dx);
      } else {
        updateStyle('height', currentFirstHeight, dy, dx);
      }
      updateCursor();
    };

    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
      resetCursor();
    };

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  }, []);

  const updateStyle = (type: string, currentFirstContent: any, dy: any, dx: any) => {
    const container = containerRef.current;
    const firstHalfEle = firstHalfRef.current;

    if (!container || !firstHalfEle) {
      return;
    }
    if (type === 'height') {
      const containerHeight = container.getBoundingClientRect().height;
      const delta = currentFirstContent + dy;
      const newFirstHalfHeight = (delta * 100) / containerHeight;
      firstHalfEle.style.height = `${newFirstHalfHeight}%`;
    } else {
      const containerWidth = container.getBoundingClientRect().width;
      const delta = currentFirstContent + dx;
      const newFirstHalfWidth = (delta * 100) / containerWidth;
      firstHalfEle.style.width = `${newFirstHalfWidth}%`;
    }
  };

  const updateCursor = () => {
    const container = containerRef.current;
    const firstHalfEle = firstHalfRef.current;
    const resizerEle = resizerRef.current;
    const secondHalfEle = secondHalfRef.current;

    if (!container || !firstHalfEle || !resizerEle || !secondHalfEle) {
      return;
    } else {
      resizerEle.style.cursor = 'ns-resize';
      document.body.style.cursor = 'ns-resize';
      firstHalfEle.style.userSelect = 'none';
      firstHalfEle.style.pointerEvents = 'none';
      secondHalfEle.style.userSelect = 'none';
      secondHalfEle.style.pointerEvents = 'none';
    }
  };

  const resetCursor = () => {
    const container = containerRef.current;
    const firstHalfEle = firstHalfRef.current;
    const resizerEle = resizerRef.current;
    const secondHalfEle = secondHalfRef.current;

    if (!container || !firstHalfEle || !resizerEle || !secondHalfEle) {
      return;
    }

    resizerEle.style.removeProperty('cursor');
    document.body.style.removeProperty('cursor');
    firstHalfEle.style.removeProperty('user-select');
    firstHalfEle.style.removeProperty('pointer-events');
    secondHalfEle.style.removeProperty('user-select');
    secondHalfEle.style.removeProperty('pointer-events');
  };

  const renderExtraMainContent = useMemo(() => {
    if (extraMainContent && extraMainContent.length > 0) {
      return (
        <>
          {extraMainContent.map((mainContent, index) => {
            const {
              rows,
              columns,
              reportCheckedList,
              reportSelected,
              tablePagination,
              updateTablePagination,
              total,
              ableFetchMore,
            } = mainContent;
            return (
              <Fragment key={`extra_main_content_${index}`}>
                {rows.length == 0 ? (
                  <div className="data-grid-wrap">
                    <p className="empty-row">Empty</p>
                  </div>
                ) : (
                  <div className="data-grid-wrap">
                    <Table
                      rows={rows}
                      columns={columns}
                      reportCheckedList={reportCheckedList}
                      reportSelected={reportSelected}
                      sortOption={{
                        target: tablePagination.target,
                        direction: tablePagination.direction,
                        onChangeSort: (target: string, dir: OrderDirection) => {
                          updateTablePagination('target', target);
                          updateTablePagination('direction', dir);
                        },
                      }}
                      horizontalScrollable={true}
                    />
                    <div className="pagination-wrapper flex a-center">
                      <p className="flex a-center">
                        Total <span>{total.totalElement}</span>
                      </p>
                      <TableManagePagination
                        ableFetchMore={ableFetchMore}
                        currentPage={tablePagination.currentPage}
                        updateCurrentPage={page => updateTablePagination('currentPage', page)}
                        totalPage={total.totalPage}
                      />
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })}
        </>
      );
    }
    return <></>;
  }, [extraMainContent]);

  const renderMainJsonTableContent = useMemo(() => {
    if (mainJsonTable) {
      const { rows, columns, reportCheckedList, reportSelected, tablePagination, updateTablePagination, total } =
        mainJsonTable;
      if (rows && columns && reportCheckedList && reportSelected && tablePagination && updateTablePagination && total) {
        if (rows.length == 0) {
          return (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          );
        } else {
          return (
            <div className="data-grid-wrap">
              <TableJsonView
                rows={rows}
                columns={columns}
                reportCheckedList={reportCheckedList}
                reportSelected={reportSelected}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
              />
              <div className="pagination-wrapper flex a-center">
                <p className="flex a-center">
                  Total <span>{total.totalElement}</span>
                </p>
                <TablePagination
                  currentPage={tablePagination.currentPage}
                  updateCurrentPage={page => updateTablePagination('currentPage', page)}
                  totalPage={total.totalPage}
                />
              </div>
            </div>
          );
        }
      }
    } else {
      return <></>;
    }
  }, [mainJsonTable]);

  const renderMainLayout = useMemo(() => {
    if (layoutType === MgdLayoutTypeEnum.MGD_LIST || layoutType === MgdLayoutTypeEnum.MGD_LIST_SUMMARY) {
      return (
        <>
          <div className="row-2 flex a-center" ref={rowTwoRef}>
            {mainTabList?.map(tab => {
              return (
                <button
                  className={`tab-items ${mainTabSelected?.id === tab.id && 'active'}`}
                  key={tab.id}
                  data-tab={tab.id}
                  onClick={e => handleActiveTabClick(tab)}
                >
                  <p>{tab.name}</p>
                </button>
              );
            })}
          </div>

          <div className="resize-container vertical" ref={containerRef} style={{ height: `${resizeHeight}px` }}>
            <div className="top-half" ref={firstHalfRef}>
              <div className="row-3 flex j-between a-center">
                <div className="title">
                  <p>{mainTitle}</p>
                  {mainDescription && <p>{mainDescription}</p>}
                </div>
                <div className="flex action a-center">
                  {mainTypeList && mainTypeValue && mainTypeDropdownOnchange && (
                    <DropdownAtom
                      id="types-dropdown"
                      className=""
                      data={mainTypeList}
                      value={mainTypeValue}
                      handleClick={val => mainTypeDropdownOnchange(val)}
                    />
                  )}

                  {searchValue !== undefined && searchInputOnchange && onSearchKeyDown && (
                    <>
                      <InputAtom
                        type={'text'}
                        value={searchValue}
                        defaultValue={''}
                        onChangeValue={str => searchInputOnchange(str)}
                        hasPrefixIcon={true}
                        srcPrefixIcon={SearchIcon}
                        prefixIconOnClick={() => {}}
                        onKeyDown={event => onSearchKeyDown(event)}
                      />
                      {mainSearchBtnVisible && (
                        <button className="action-btn" onClick={maimSearchBtnClicked}>
                          Search
                        </button>
                      )}
                    </>
                  )}
                  {mainEditBtnVisible && (
                    <button className="action-btn" onClick={mainEditBtnClicked} disabled={mainEditBtnDisabled}>
                      Edit
                    </button>
                  )}
                  {mainCreateBtnVisible && (
                    <button className="action-btn" onClick={mainCreateBtnClicked}>
                      {mainCreateBtnLabel ?? 'Create'}
                    </button>
                  )}
                  {mainDelBtnVisible && (
                    <button className="action-btn" onClick={mainDelBtnClicked} disabled={mainDelBtnDisabled}>
                      Delete
                    </button>
                  )}
                  {mainResetBtnClicked && (
                    <Icon className="reset-btn" width={32} height={28} src={ResetIcon} onClick={mainResetBtnClicked} />
                  )}
                  {mainActionList && mainActionValue && mainActionDropdownOnchange && (
                    <DropdownAtom
                      id="actions-dropdown"
                      className=""
                      data={mainActionList}
                      value={mainActionValue}
                      handleClick={val => mainActionDropdownOnchange(val)}
                    />
                  )}
                </div>
              </div>

              {rows.length == 0 && columns.length == 0 ? (
                <div className="data-grid-wrap">
                  <p className="empty-row">Empty</p>
                </div>
              ) : (
                <div className="data-grid-wrap">
                  <Table
                    rows={rows}
                    columns={columns}
                    reportCheckedList={reportCheckedList}
                    reportSelected={reportSelected}
                    sortOption={{
                      target: tablePagination.target,
                      direction: tablePagination.direction,
                      onChangeSort: (target: string, dir: OrderDirection) => {
                        updateTablePagination('target', target);
                        updateTablePagination('direction', dir);
                      },
                    }}
                    horizontalScrollable={true}
                    isLoading={mainIsLoading}
                  />
                  {rows && rows.length > 0 && mainIsLoading == false && (
                    <div className="pagination-wrapper flex a-center">
                      <p className="flex a-center">
                        Total <span>{total.totalElement}</span>
                      </p>
                      <TableManagePagination
                        ableFetchMore={!!nextToken}
                        currentPage={tablePagination.currentPage}
                        updateCurrentPage={page => updateTablePagination('currentPage', page)}
                        totalPage={total.totalPage}
                      />
                    </div>
                  )}
                </div>
              )}
              {renderExtraMainContent}
              {renderMainJsonTableContent}
            </div>
            <div
              className="resizer-bar"
              ref={resizerRef}
              onMouseDown={handleMouseDown}
              onTouchStart={handleTouchStart}
            />
            <div className="bottom-half" ref={secondHalfRef}>
              {renderSummaryInfo}
            </div>
          </div>
        </>
      );
    }

    if (layoutType === MgdLayoutTypeEnum.MGD_DETAIL) {
      return (
        <>
          <div className="one-page" style={{ height: '100%', overflowY: 'auto' }}>
            {noDetailsDataMessage != undefined && noDetailsDataMessage != '' ? (
              <>
                <div className="no-detail-data-message-container">
                  <p className="no-detail-messenge">{noDetailsDataMessage}</p>
                </div>
              </>
            ) : (
              <>
                {mainTitle && (
                  <div className="row-3 flex j-between a-center">
                    <div className="title">
                      <p>{mainTitle}</p>
                      {mainDescription && <p>{mainDescription}</p>}
                    </div>
                  </div>
                )}

                {extraOverviewDetail && (
                  <div className="overview-container">
                    <DetailMultiColumn
                      data={extraOverviewDetail?.contents as DetailMultiColumProps[]}
                      handleHDetailHyperlinkMainClick={detailHyperLinkClick}
                      currentTab={mainTabSelected ? mainTabSelected : ({} as IMgdTabProps)}
                    />
                  </div>
                )}

                {detailsData && (
                  <div className="detail-info flex" ref={detailInfoRef}>
                    <Detail
                      type={DetailTypeEnum.DETAIL}
                      numberOfColumns={detailsDataNumberOfColumns}
                      contents={detailsData}
                      detailContentWidth={detailInfoWidth}
                      detailHyperLinkClick={detailHyperLinkClick}
                      mainTabSelected={mainTabSelected}
                    />
                  </div>
                )}

                {detailContents?.map((detailContent, index) => {
                  switch (detailContent.type) {
                    case DetailTypeEnum.DETAIL:
                      return (
                        <Fragment key={`detail_info_${index}`}>
                          <div className="extra-detail-info flex" ref={detailInfoRef}>
                            {detailContent?.title && <div className="detail-info-title">{detailContent.title}</div>}
                            <Detail
                              {...detailContent}
                              detailContentWidth={detailInfoWidth}
                              detailHyperLinkClick={detailHyperLinkClick}
                              mainTabSelected={mainTabSelected}
                            />
                          </div>
                        </Fragment>
                      );
                    case DetailTypeEnum.TABLE:
                      return (
                        <div
                          className={`flex detail-table ${detailTabSelected?.title == 'Tags' ? 'tags-table' : ''}`}
                          ref={detailInfoRef}
                          key={`detail_info_${index}`}
                        >
                          <TableDetail
                            {...detailContent}
                            detailTabSelected={detailTabSelected}
                            isCustomPagination={true}
                          />
                        </div>
                      );
                    case DetailTypeEnum.MULTICOLUMN:
                      return (
                        <DetailMultiColumn
                          data={detailContent.contents as DetailMultiColumProps[]}
                          handleHDetailHyperlinkMainClick={detailHyperLinkClick}
                          currentTab={mainTabSelected ? mainTabSelected : ({} as IMgdTabProps)}
                          key={`detail_info_${index}`}
                        />
                      );
                    case DetailTypeEnum.JSONVIEWER:
                      return (
                        <JsonViewer
                          data={detailContent.jsonViewerData}
                          title={detailContent.title}
                          key={`detail_info_${index}`}
                        />
                      );
                    default:
                      break;
                  }
                })}

                {detailTabList && (
                  <div className="container">
                    {detailTabList.length > 0 && (
                      <div className="tabs-container flex">
                        {detailTabList?.map(tab => {
                          return (
                            <button
                              className={`tab-items ${detailTabSelected?.id === tab.id && 'active'}`}
                              key={tab.id}
                              data-tab={tab.id}
                              onClick={e => handleActiveDetailTabClick(tab)}
                            >
                              <p>{tab.title}</p>
                            </button>
                          );
                        })}
                      </div>
                    )}

                    <div className="tabs-content">
                      {detailIsLoading ? (
                        <div className="progress-container">
                          <div className="progress-gif" />
                          Loading ...
                        </div>
                      ) : (
                        <>
                          {rows && columns.length > 0 && (
                            <>
                              {renderDetailHeader}
                              {rows.length == 0 ? (
                                <div className="data-grid-wrap">
                                  <p className="empty-row">Empty</p>
                                </div>
                              ) : (
                                <div
                                  className={`detail-table ${detailTabSelected?.title == 'Tags' ? 'tags-table' : ''}`}
                                  ref={detailInfoRef}
                                >
                                  <Table
                                    rows={rows}
                                    columns={columns}
                                    sortOption={{
                                      target: tablePagination.target,
                                      direction: tablePagination.direction,
                                      onChangeSort: (target: string, dir: OrderDirection) => {
                                        updateTablePagination('target', target);
                                        updateTablePagination('direction', dir);
                                      },
                                    }}
                                  />
                                  {detailTabSelected?.title != 'Tags' && (
                                    <div className="pagination-wrapper flex a-center">
                                      <p className="flex a-center text-paging">
                                        Total <span>{total.totalElement}</span>
                                      </p>
                                      <TableManagePagination
                                        ableFetchMore={false}
                                        currentPage={tablePagination.currentPage}
                                        updateCurrentPage={page => updateTablePagination('currentPage', page)}
                                        totalPage={total.totalPage}
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                          {detailsExtraData?.map((detailContent, index) => {
                            switch (detailContent.type) {
                              case DetailTypeEnum.DETAIL:
                                return (
                                  <Fragment key={`detail_info_${index}`}>
                                    <div className="extra-detail-info flex" ref={detailInfoRef}>
                                      <div className="extra-detail-info-header-container">
                                        {detailContent?.title && (
                                          <div className="detail-info-title">{detailContent.title}</div>
                                        )}
                                        {detailContent.actionGroup &&
                                          detailContent.actionGroup.map((button, idx) => {
                                            return (
                                              <button
                                                key={`extra-detail-info-header-button-${idx}`}
                                                className="detail-info-btn"
                                                onClick={button.onClick}
                                              >
                                                {button.title}
                                              </button>
                                            );
                                          })}
                                      </div>
                                      <Detail
                                        {...detailContent}
                                        detailContentWidth={detailInfoWidth}
                                        detailHyperLinkClick={detailHyperLinkClick}
                                        mainTabSelected={mainTabSelected}
                                      />
                                    </div>
                                  </Fragment>
                                );
                              case DetailTypeEnum.TABLE:
                                return (
                                  <div
                                    className={`flex detail-table ${
                                      detailTabSelected?.title == 'Tags' ? 'tags-table' : ''
                                    }`}
                                    ref={detailInfoRef}
                                    key={`detail_info_${index}`}
                                  >
                                    <TableDetail
                                      {...detailContent}
                                      detailTabSelected={detailTabSelected}
                                      isCustomPagination={true}
                                    />
                                  </div>
                                );
                              case DetailTypeEnum.MULTICOLUMN:
                                return (
                                  <DetailMultiColumn
                                    data={detailContent.contents as DetailMultiColumProps[]}
                                    handleHDetailHyperlinkMainClick={detailHyperLinkClick}
                                    currentTab={mainTabSelected ? mainTabSelected : ({} as IMgdTabProps)}
                                    key={`detail_info_${index}`}
                                    actionGroup={detailContent.actionGroup}
                                  />
                                );
                              case DetailTypeEnum.JSONVIEWER:
                                return (
                                  <JsonViewer
                                    data={detailContent.jsonViewerData}
                                    title={detailContent.title}
                                    key={`detail_info_${index}`}
                                  />
                                );
                              default:
                                break;
                            }
                          })}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      );
    }

    if (layoutType === MgdLayoutTypeEnum.MGD_LIST_MORE) {
      return (
        <div className="one-page">
          <div className="row-2 flex a-center" ref={rowTwoRef}>
            {mainTabList?.map(tab => {
              return (
                <button
                  className={`tab-items ${mainTabSelected?.id === tab.id && 'active'}`}
                  key={tab.id}
                  data-tab={tab.id}
                  onClick={e => handleActiveTabClick(e)}
                >
                  <p>{tab.name}</p>
                </button>
              );
            })}
          </div>

          <div className="row-3 flex j-between a-center">
            <div className="title">
              <p>{mainTitle}</p>
              {mainDescription && <p>{mainDescription}</p>}
            </div>
            <div className="flex action a-center">
              {mainTypeList && mainTypeValue && mainTypeDropdownOnchange && (
                <DropdownAtom
                  id="types-dropdown"
                  className=""
                  data={mainTypeList}
                  value={mainTypeValue}
                  handleClick={val => mainTypeDropdownOnchange(val)}
                />
              )}

              {searchValue !== undefined && searchInputOnchange && (
                <>
                  <InputAtom
                    type={'text'}
                    value={searchValue}
                    defaultValue={''}
                    onChangeValue={str => searchInputOnchange(str)}
                    hasPrefixIcon={true}
                    srcPrefixIcon={SearchIcon}
                    prefixIconOnClick={() => {}}
                    onKeyDown={(event: any) => onSearchKeyDown && onSearchKeyDown(event)}
                  />
                  {mainSearchBtnVisible && (
                    <button className="action-btn" onClick={maimSearchBtnClicked}>
                      Search
                    </button>
                  )}
                  {mainDelBtnVisible && (
                    <button className="action-btn" onClick={mainDelBtnClicked}>
                      Delete
                    </button>
                  )}
                  <Icon className="reset-btn" width={32} height={28} src={ResetIcon} onClick={mainResetBtnClicked} />
                </>
              )}

              {mainActionList && mainActionValue && mainActionDropdownOnchange && (
                <DropdownAtom
                  id="actions-dropdown"
                  className=""
                  data={mainActionList}
                  value={mainActionValue}
                  handleClick={val => mainActionDropdownOnchange(val)}
                />
              )}

              {mainCreateBtnVisible && (
                <button className="action-btn" onClick={mainCreateBtnClicked}>
                  {mainCreateBtnLabel ?? 'Create'}
                </button>
              )}
            </div>
          </div>

          {rows.length == 0 ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap" style={{ height: `${resizeHeight}px` }}>
              <Table
                rows={rows}
                columns={columns}
                reportCheckedList={reportCheckedList}
                reportSelected={reportSelected}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
              />
              <div className="pagination-wrapper flex j-center">
                {moreBtnClicked ? (
                  <button className="action-btn" onClick={moreBtnClicked}>
                    More
                  </button>
                ) : (
                  <>
                    <p className="flex a-center">
                      Total <span>{total.totalElement}</span>
                    </p>
                    <TablePagination
                      currentPage={tablePagination.currentPage}
                      updateCurrentPage={page => updateTablePagination('currentPage', page)}
                      totalPage={total.totalPage}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      );
    }

    if (layoutType === MgdLayoutTypeEnum.MGD_LIST_MORE_SUMMARY) {
      return (
        <>
          <div className="row-2 flex a-center" ref={rowTwoRef}>
            {mainTabList?.map(tab => {
              return (
                <button
                  className={`tab-items ${mainTabSelected?.id === tab.id && 'active'}`}
                  key={tab.id}
                  data-tab={tab.id}
                  onClick={e => handleActiveTabClick(e)}
                >
                  <p>{tab.name}</p>
                </button>
              );
            })}
          </div>

          <div className="row-3 flex j-between a-center">
            <div className="title">
              <p>{mainTitle}</p>
              {mainDescription && <p>{mainDescription}</p>}
            </div>
            <div className="flex action a-center">
              {mainTypeList && mainTypeValue && mainTypeDropdownOnchange && (
                <DropdownAtom
                  id="types-dropdown"
                  className=""
                  data={mainTypeList}
                  value={mainTypeValue}
                  handleClick={val => mainTypeDropdownOnchange(val)}
                />
              )}

              {searchValue !== undefined && searchInputOnchange && (
                <>
                  <InputAtom
                    type={'text'}
                    value={searchValue}
                    defaultValue={''}
                    onChangeValue={str => searchInputOnchange(str)}
                    hasPrefixIcon={true}
                    srcPrefixIcon={SearchIcon}
                    prefixIconOnClick={() => {}}
                    onKeyDown={(event: any) => onSearchKeyDown && onSearchKeyDown(event)}
                  />
                  {mainSearchBtnVisible && (
                    <button className="action-btn" onClick={maimSearchBtnClicked}>
                      Search
                    </button>
                  )}
                  {mainDelBtnVisible && (
                    <button className="action-btn" onClick={mainDelBtnClicked}>
                      Delete
                    </button>
                  )}
                  <Icon className="reset-btn" width={32} height={28} src={ResetIcon} onClick={mainResetBtnClicked} />
                </>
              )}

              {mainActionList && mainActionValue && mainActionDropdownOnchange && (
                <DropdownAtom
                  id="actions-dropdown"
                  className=""
                  data={mainActionList}
                  value={mainActionValue}
                  handleClick={val => mainActionDropdownOnchange(val)}
                />
              )}

              {mainCreateBtnVisible && (
                <button className="action-btn" onClick={mainCreateBtnClicked}>
                  {mainCreateBtnLabel ?? 'Create'}
                </button>
              )}
            </div>
          </div>

          <div className="resize-container horizontal" ref={containerRef} style={{ height: `${resizeHeight}px` }}>
            <div className="top-half" ref={firstHalfRef} style={{ width: `${currentReport ? '50%' : '100%'}` }}>
              {searchInpuMoreSumOnchange && (
                <div className="row-3 flex j-between a-center">
                  <div></div>
                  <div className="flex action a-center">
                    <InputAtom
                      type={'text'}
                      value={searchMoreSumValue}
                      defaultValue={''}
                      onChangeValue={str => searchInpuMoreSumOnchange(str)}
                      hasPrefixIcon={true}
                      srcPrefixIcon={SearchIcon}
                      prefixIconOnClick={() => {}}
                      onKeyDown={(event: any) => onSearchKeyDown && onSearchKeyDown(event)}
                    />
                  </div>
                </div>
              )}

              {rows.length == 0 ? (
                <div className="data-grid-wrap">
                  <p className="empty-row">Empty</p>
                </div>
              ) : (
                <div className="data-grid-wrap" style={{ height: `${resizeMoreSumHeight}px` }}>
                  <Table
                    rows={rows}
                    columns={columns}
                    reportCheckedList={reportCheckedList}
                    reportSelected={reportSelected}
                    sortOption={{
                      target: tablePagination.target,
                      direction: tablePagination.direction,
                      onChangeSort: (target: string, dir: OrderDirection) => {
                        updateTablePagination('target', target);
                        updateTablePagination('direction', dir);
                      },
                    }}
                  />
                  <div className="pagination-wrapper flex a-center">
                    <p className="flex a-center">
                      Total <span>{total.totalElement}</span>
                    </p>
                    <TablePagination
                      currentPage={tablePagination.currentPage}
                      updateCurrentPage={page => updateTablePagination('currentPage', page)}
                      totalPage={total.totalPage}
                    />
                  </div>
                </div>
              )}
            </div>
            {currentReport && (
              <>
                <div
                  className="resizer-bar"
                  ref={resizerRef}
                  onMouseDown={handleMouseDown}
                  onTouchStart={handleTouchStart}
                />
                <div className="bottom-half" ref={secondHalfRef}>
                  {itemTitleMoreSum && closeMoreSumBtnClicked && (
                    <div className="summary-content flex j-between a-center">
                      <p>{itemTitleMoreSum}</p>
                      <Icon
                        className="close-btn"
                        width={12}
                        height={12}
                        src={CloseIcon}
                        onClick={closeMoreSumBtnClicked}
                      />
                    </div>
                  )}
                  {renderMoreSumContent}
                </div>
              </>
            )}
          </div>
        </>
      );
    }
  }, [
    layoutType,
    handleActiveTabClick,
    noDetailsDataMessage,
    mainIsLoading,
    detailIsLoading,
    detailContents,
    detailsExtraData,
    onSearchKeyDown,
  ]);

  return (
    <div id="mgd-list-layout" ref={mgdLayoutRef}>
      <div className="row-1 flex j-between a-center" ref={rowOneRef}>
        <div className="flex j-start a-center" id="title">
          <NetworkTitle
            pageTitle={pageTitle}
            id={321}
            name={pageTitle}
            hasPrefixIcon={hasPrefixIcon}
            hasFavorite={hasFavorite}
            pageBackClick={pageBackClick}
          />
        </div>

        {layoutType === MgdLayoutTypeEnum.MGD_DETAIL ? (
          <div className="flex a-center" id="action">
            {detailTitleBtnDelVisible && (
              <button className="action-btn" onClick={detailTitleBtnDelClicked}>
                Delete
              </button>
            )}
            {/* <Icon className="pin-btn" width={16} height={16} src={DeleteIcon} onClick={() => {}}/>
              {headerDropList && headerDropValue && headerDropOnchange &&
                <DropdownAtom 
                  id="actions-dropdown"
                  className="header-select-box"
                  data={headerDropList}
                  value={headerDropValue}
                  handleClick={(val) => headerDropOnchange(val)}/>
              }
              <button className="action-btn" onClick={() => {}}>Update</button>
              <Icon className="pin-btn" width={16} height={16} src={ResetIcon} onClick={() => {}}/> */}
          </div>
        ) : (
          <div className="flex a-center" id="action">
            {recentRelatedClouds && relatedCloudSelected && recentRelatedCloudOnChange && (
              <Fragment>
                {'Related Cloud:'}
                <DropdownAtomRelatedCloud
                  id="related-cloud-dropdown"
                  className="related-cloud-select-box"
                  data={recentRelatedClouds}
                  value={relatedCloudSelected}
                  handleClick={val => recentRelatedCloudOnChange(val)}
                />
              </Fragment>
            )}
            {headerDropList && headerDropValue && headerDropOnchange && (
              <Fragment>
                {'Region:'}
                <DropdownAtom
                  disabled={isPined}
                  id="regions-dropdown"
                  className="header-select-box"
                  data={headerDropList}
                  value={headerDropValue}
                  handleClick={val => headerDropOnchange(val)}
                />
              </Fragment>
            )}
            {pinBtnDisabled !== undefined && pinBtnActHandle && (
              <Icon
                className={`pin-btn ${pinBtnDisabled ? 'pin-btn-disabled' : ''}`}
                width={16}
                height={16}
                src={PinIcon}
                disabled={pinBtnDisabled}
                onClick={pinBtnActHandle}
              />
            )}
          </div>
        )}
      </div>
      {renderMainLayout}
    </div>
  );
};

export default MgdLayout;
