import './style.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import lazyGetAwsListAttachedGroupPolicies, {
  IGetAwsListAttachedGroupPoliciesVariables,
} from 'graphql/queries/getAwsListAttachedGroupPolicies';
import lazyGetAwsListGroupsForUser, { IAwsListGroupsForUserVariables } from 'graphql/queries/getAwsListGroupsForUser';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { useNavigate } from 'react-router-dom';
import Table from 'components/v2/dataDisplay/Table';
import { randomString } from 'utils/Common';

const Groups = (props: any) => {
  const { data = {}, cloudId, region } = props;

  const { userName } = data;

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<any>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [getAwsListGroupsForUser] = lazyGetAwsListGroupsForUser();
  const [getAwsListAttachedGroupPolicies] = lazyGetAwsListAttachedGroupPolicies();

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Group name',
        field: 'groupName',
        sort: true,
      },
      {
        label: 'Attached policies',
        field: 'attachedPolicies',
        sort: true,
      },
    ];
  }, []);

  const handleGetAwsListGroupsForUser = (userName: string) => {
    const requestVariable: IAwsListGroupsForUserVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        userName,
      },
    };
    return getAwsListGroupsForUser({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsListAttachedGroupPolicies = (groupName: string) => {
    const requestVariable: IGetAwsListAttachedGroupPoliciesVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        groupName,
      },
    };
    return getAwsListAttachedGroupPolicies({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleFormatArr = (values: string[]) => {
    if (!values?.length) return ''

    if (values?.length === 1) return values[0]

    if (values?.length === 2) return `${values[0]} and ${values[1]}`

    return `${values[0]}, ${values[1]} and ${values?.length} more`
  }

  const fetchData = useCallback(async () => {
    try {
      if (!userName) return;

      setIsLoading(true);
      const {data: listGroupsForUser} = await handleGetAwsListGroupsForUser(userName);

      if (!listGroupsForUser?.getAwsListGroupsForUser?.data[0]?.groups?.length) {
        setIsLoading(false)
        return;
      }

      const totalResult: RowType[] = [];
      for (let i = 0; i < listGroupsForUser?.getAwsListGroupsForUser?.data[0]?.groups?.length; i++) {
        const e = listGroupsForUser?.getAwsListGroupsForUser?.data[0]?.groups?.[i];

        const { data: listAttachedGroupPolicies } = await handleGetAwsListAttachedGroupPolicies(e.groupName);

          const item = {
            id: randomString(),
            groupName: e?.groupName,
            attachedPolicies: listAttachedGroupPolicies?.getAwsListAttachedGroupPolicies?.data?.[0]?.attachedPolicies?.length ? handleFormatArr(listAttachedGroupPolicies?.getAwsListAttachedGroupPolicies?.data?.[0]?.attachedPolicies?.map(e => e.policyName))  : ''
          };

          totalResult.push(item);
      }

      setMainTblRows(totalResult);
      setMainTblTotal({
        totalPage: Math.ceil(totalResult.length / tablePagination.itemPerPage),
        totalElement: totalResult.length,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [tablePagination, userName, cloudId, region, mainTblRows]);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    const arr: any = orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );

    return arr;
  }, [userName, mainTblTotal, tablePagination]);

  useEffect(() => {
    fetchData();
  }, [userName]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>User group membership</p>

        <p>
          A user group is a collection of IAM users. Use groups to specify permissions for a collection of users. A user
          can be a member of up to 10 groups at a time.
        </p>
      </div>
      {isLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <div className="detail-info-content">
          {!mainRowsCurrentPage.length && !isLoading ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <Table
                rows={mainRowsCurrentPage}
                columns={columns}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
                reportCheckedList={() => {}}
                reportSelected={() => {}}
              />

              {mainRowsCurrentPage?.length && !isLoading ? (
                <div className="fleet-instance pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{mainTblTotal.totalElement}</span>
                  </p>

                  <TableManagePagination
                    ableFetchMore={false}
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page => updateTablePagination('currentPage', page)}
                    totalPage={mainTblTotal.totalPage}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Groups;
