import { useMemo } from "react";
import { TrustedRelationshipPropsType } from "../types";
import './style.scss';

const TrustedRelationship = (props: TrustedRelationshipPropsType) => {
  const {role} = props;

  const trustedEntities = useMemo((): string => {
    return role?.assumeRolePolicyDocument ?? '';
  }, [role]);

  if (!role) {
    return null;
  }

  return (
    <div className="detail-info">
      <div className="detail-info-title detail-info-title-permissions">
        <div>
          <p>Trust entities</p>

          <p>Entities that can assume this role under specified conditions.</p>
        </div>
      </div>
      <div className="detail-info-content">
        <div className="json-content">
          <pre>{decodeURIComponent(trustedEntities)}</pre>
        </div>
      </div>
    </div>
  );
}

export default TrustedRelationship;