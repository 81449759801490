import './index.scss';

import { useNavigate } from 'react-router-dom';
import FooterComponent from 'components/v2/FooterComponent';
import { useAuth } from 'contexts/AuthProvider';

const Landing = () => {
  const {userInfo} = useAuth();
  const navigate = useNavigate();

  return (
    <div id="landing-page">
      <header className='flex j-between a-center'>
        <div className='header-logo' onClick={() => {
          if(userInfo) {
            navigate(`/organ/${userInfo.nemo.organId}/dashboard/overview`)
          } else {
            navigate('/');
          }
        }}/>
        <div className='btns flex j-between a-center'>
            <button 
              className='login-btn'
              onClick={() => navigate('/auth')}
            >
              Log in
          </button>
          <button 
            className='singup-btn'
            onClick={() => navigate('/auth/signup')}
          >
            Sign up
          </button>
        </div>
      </header>
      
      <div className='contents flex col a-center'>
        <div className='upper-bg' />
        <div className='signup-wrap flex col a-center'>
          <div className='logo' />
          <p>
            Everything starts with security.
          </p>
          <button 
            className='big-main-btn'
            onClick={() => navigate('/auth/signup')}
          >
            Sign up
          </button>
        </div>
        <div className='under-bg' />
      </div>
      <FooterComponent />
    </div>
  )
}
export default Landing;