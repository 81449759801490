import { useNavigate } from "react-router-dom";
import { AwsKeyListPropsType } from "../types";
import lazyGetAwsAllListAliasesPage, { IAwsAllListAliasesVariables } from "graphql/queries/getAwsAllListAliases";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ColumnType, OrderDirection, RowType } from "@Types/v2/Table";
import { AwsAlias, AwsAliasRowData } from "graphql/types/AwsAliases";
import { AWS_KEY_FILTER_DROPDOWN } from "./configs";
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { FilterPropsType } from "../../../EC2/components/Image/types";
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdTabLayout/types';
import { handleFormatText } from "utils/Common";
import { orderAlphabetical } from "../../../Utils/Sorting";
import { variableCombineNextToken } from "../../../Utils";
import lazyGetAwsDescribeKey, { IAwsDescribeKeyVariables } from "graphql/queries/getAwsDescribeKey";
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import InputAtom from "components/v2/atoms/InputAtom";
import Table from "components/v2/dataDisplay/Table";
import TableManagePagination from "components/v2/dataDisplay/TableManagePagination";

const AwsKeyList = (props: AwsKeyListPropsType) => {
  const { cloudId, region, tabInformation, onAwsKeySelected, onAwsKeyIdClicked, currentRouteState, setCurrentRouteState, onNavigateCreation } = props;

  // API
  const [getAwsAllListAliases, {loading: getAllAliasesLoading}] = lazyGetAwsAllListAliasesPage();
  const [getAwsDescribeKey, {loading: getAwsDescribeKeyLoading}] = lazyGetAwsDescribeKey();

  // State
  const [mainTblTotalRows, setMainTblTotalRows] = useState<RowType[]>([]);
  const [mainTblTotalSearchRows, setMainTblTotalSearchRows] = useState<RowType[]>([]);
  const [mainNextToken, setMainNextToken] = useState<string>('');
  const [awsKeysSelected, setAwsKeysSelected] = useState<AwsAlias[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const currentFilter = currentRouteState && AWS_KEY_FILTER_DROPDOWN.find(e => e.value === currentRouteState.key);
  const [awsKeyFilterDropdownValue, setAwsKeyFilterDropdownValue] = useState<DropdownListDataType>(
    currentFilter || AWS_KEY_FILTER_DROPDOWN[0],
  );
  const [awsKeyFilterInputValue, setAwsKeyFilterInputValue] = useState<FilterPropsType>({
    name: awsKeyFilterDropdownValue.value as string,
    value: currentRouteState?.value || '',
  });
  const [awsKeyFilter, setAwsKeyFilter] = useState<FilterPropsType | undefined>(currentRouteState?.value ? awsKeyFilterInputValue : undefined);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });

  const apiLoading = useMemo((): boolean => {
    return getAllAliasesLoading || getAwsDescribeKeyLoading;
  }, [getAllAliasesLoading, getAwsDescribeKeyLoading]);

  useEffect(() => {
    return () => {
      if (!currentRouteState) return;

      setCurrentRouteState(null);
    };
  }, [currentRouteState]);

  const mainTblColumns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Aliases',
        field: 'aliases',
        sort: true,
        renderCell: row => (
          <p
            className="link"
            onClick={e => {
              e.stopPropagation();
              onAwsKeyIdClicked();
              onAwsKeySelected(row as AwsAliasRowData)
            }}
          >
            {handleFormatText(row.aliases)}
          </p>
        ),
      },
      {
        label: 'Key',
        field: 'key',
        sort: true,
        renderCell: row =>
          row.key !== '-' ? (
            <p
              className="link"
              onClick={e => {
                e.stopPropagation();
                onAwsKeyIdClicked();
                onAwsKeySelected(row as AwsAliasRowData)
              }}
            >
              {handleFormatText(row.key)}
            </p>
          ) : (
            <p>{handleFormatText(row.key)}</p>
          ),
      },
      {
        label: 'Status',
        field: 'status',
        sort: true,
        renderCell: row => (
          <p className="status" onClick={() => {}}>
            {row.status}
          </p>
        ),
      },
    ];
  }, []);


  const handleSearchFunction = useCallback(() => {
    if (awsKeyFilterInputValue.value !== '') {
      setAwsKeyFilter({ name: awsKeyFilterInputValue.name, value: awsKeyFilterInputValue.value });
      setMainTblTotalSearchRows(mainTblTotalRows);
      const searchedRows = mainTblTotalRows?.filter(row => row[awsKeyFilterInputValue.name] === awsKeyFilterInputValue.value);
      setMainTblTotalRows(searchedRows);
      setMainTblTotal({
        totalPage: Math.ceil(searchedRows.length / mainTablePagination.itemPerPage),
        totalElement: searchedRows.length,
      });
    } else {
      setAwsKeyFilter(undefined);
      setMainTblTotalRows(mainTblTotalSearchRows);
      setMainTblTotalSearchRows([]);
      setMainTblTotal({
        totalPage: Math.ceil(mainTblTotalSearchRows.length / mainTablePagination.itemPerPage),
        totalElement: mainTblTotalSearchRows.length,
      });
    }
  }, [awsKeyFilterInputValue, mainTblTotalSearchRows, mainTblTotalRows]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setMainTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      fetchAwsAllAwsKeys(mainNextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    let totalRows = mainTblTotalRows;
    totalRows = mainTblTotalRows?.filter(row => {
      return row?.[awsKeyFilter?.name ?? '']?.includes(awsKeyFilter?.value || '');
    });

    return orderAlphabetical(mainTblTotalRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotalRows, mainTblTotal, mainTablePagination, mainNextToken, awsKeyFilter]);

  const awsAwsKeyRequestVariables = useMemo((): IAwsAllListAliasesVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: {
        limit: mainTablePagination.limit
      },
    };
  }, [cloudId, region, mainTablePagination]);

  const fetchAwsAllAwsKeys = useCallback(async (nextToken?: string, isInitial?: boolean) => {
    const combinedVariable = variableCombineNextToken(awsAwsKeyRequestVariables, nextToken);

    const data = await getAwsAllListAliases({ variables: combinedVariable }).then(({ data: listAliases }) => {
      return listAliases;
    });

    const listAliases = data?.getAwsAllListAliases?.data ?? [];

    if (listAliases.length > 0) {
      const awsKeyRows: RowType[] = [];
      for (const aliases of listAliases) {
        if (aliases.aliasName.startsWith('alias/aws/') && aliases.targetKeyId) {
          const variable: IAwsDescribeKeyVariables = {
            cloudId: cloudId,
            region: region,
            request: {
              keyId: aliases.targetKeyId,
            },
          };
          const keyData = await getAwsDescribeKey({ variables: variable }).then(({ data: keyData }) => {
            return keyData;
          });

          awsKeyRows.push({
            id: aliases.targetKeyId,
            name: aliases.aliasName,
            aliases: aliases.aliasName,
            key: keyData?.getAwsDescribeKey.data[0]?.keyMetadata?.keyId ?? '-',
            status: keyData?.getAwsDescribeKey.data[0]?.keyMetadata?.keyState ?? '-',
          });
        }
      }
      let allAwsKeys: RowType[] = [];
      if (isInitial) {
        allAwsKeys = awsKeyRows;
      } else {
        allAwsKeys = [...mainTblTotalRows, ...awsKeyRows];
      }
      setMainTblTotalRows(allAwsKeys);
      if (allAwsKeys.length > mainTablePagination.limit) {
        setMainNextToken(data?.getAwsAllListAliases?.nextToken as string);
      } else {
        setMainNextToken('');
      }
      setMainTblTotal({
        totalPage: Math.ceil(allAwsKeys.length / mainTablePagination.itemPerPage),
        totalElement: allAwsKeys.length,
      });
    }

  }, [awsAwsKeyRequestVariables, cloudId, region, mainTblTotalRows, mainTblTotal, mainTablePagination]);

  const handleAwsKeySelected = useCallback((listId: string[]) => {
    const awsKeys: AwsAlias[] = [];
    listId.map(rowId => {
      const rowSelected = mainTblTotalRows.find(row => row.id === rowId);
      if (rowSelected) {
        awsKeys.push(rowSelected as AwsAlias);
      }
    });
    setAwsKeysSelected(awsKeys);
  }, [mainTblTotalRows]);

  const handleMainRowSelected = useCallback(
    (rowId: string) => {
      const rowSelected = mainTblTotalRows.find(row => row.id === rowId);

      if (!rowSelected) return;
      handleAwsKeySelected([rowId]);
      onAwsKeySelected(rowSelected as AwsAliasRowData);
    },
    [mainTblTotalRows],
  );

  // For initial data
  useEffect(() => {
    fetchAwsAllAwsKeys('', true);
  }, [awsAwsKeyRequestVariables]);

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>{tabInformation.title}</p>
        </div>
        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={AWS_KEY_FILTER_DROPDOWN}
            value={awsKeyFilterDropdownValue}
            handleClick={val => {
              setAwsKeyFilterDropdownValue(val);
              setAwsKeyFilterInputValue(prev => ({ ...prev, name: val.value as string }));
            }}
          />
          <InputAtom
            type={'text'}
            value={awsKeyFilterInputValue.value}
            defaultValue={''}
            onChangeValue={str => setAwsKeyFilterInputValue(prev => ({ name: prev?.name, value: str }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {}}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSearchFunction();
              }
            }}
          />

          <button className="action-btn" onClick={handleSearchFunction}>
            Search
          </button>
          {/* <button className="action-btn" onClick={() => onNavigateCreation()}>
            Create
          </button>
          <button className="action-btn" onClick={() => setDeleteModalVisible(true)} disabled={deleteButtonDisabled}>
            Delete
          </button> */}
        </div>
      </div>
      <div className="data-grid-wrap">
        <Table
          rows={mainRowsCurrentPage}
          columns={mainTblColumns}
          reportCheckedList={listId => handleAwsKeySelected(listId)}
          reportSelected={id => handleMainRowSelected(id)}
          sortOption={{
            target: mainTablePagination.target,
            direction: mainTablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {
              updateTablePagination('target', target);
              updateTablePagination('direction', dir);
            },
          }}
          isLoading={apiLoading}
          horizontalScrollable={true}
        />
        {mainRowsCurrentPage && mainRowsCurrentPage.length > 0 && apiLoading === false && (
          <div className="pagination-wrapper flex a-center">
            <p className="flex a-center">
              Total <span>{mainTblTotal.totalElement}</span>
            </p>
            <TableManagePagination
              ableFetchMore={!!mainNextToken}
              currentPage={mainTablePagination.currentPage}
              updateCurrentPage={page =>
                setMainTablePagination(prev => ({
                  ...prev,
                  ['currentPage']: page,
                }))
              }
              totalPage={mainTblTotal.totalPage}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default AwsKeyList;