import React from 'react';

import './index.scss';
import Button, { ButtonTypeEnum } from '../../../WebACL/CreateWebACL/components/Button';

type FooterPropsType = {
  onCancel: () => void;
  onSubmit?: () => void;
  submitLoading?: boolean;
};

function Footer({ onCancel, onSubmit, submitLoading }: FooterPropsType) {
  return (
    <div className="footer-custom-rule-modal">
      <Button label="Cancel" type={ButtonTypeEnum.GENERAL} onClick={onCancel} />
      <Button label="Add Rule" type={ButtonTypeEnum.PRIMARY} loading={submitLoading} onClick={() => onSubmit?.()} />
    </div>
  );
}

export default Footer;
