import lazyGetAwsKeyRotationStatus, { IAwsKeyRotationStatusVariables } from 'graphql/queries/getAwsKeyRotationStatus';
import { KeyRotationDetailPropsType } from './types';
import lazyGetAwsListKeyRotations, { IAwsListKeyRotationsVariables } from 'graphql/queries/getAwsListKeyRotations';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';

const KeyRotation = (props: KeyRotationDetailPropsType) => {
  const { cloudId, region, customerKey } = props;

  const [getAwsKeyRotationStatus, { loading: keyRotationStatusLoading }] = lazyGetAwsKeyRotationStatus();
  const [getAwsListKeyRotations, { loading: keyRotationLoading }] = lazyGetAwsListKeyRotations();

  const [rotationContent, setRotationContent] = useState<IMgdDetailKeyValueProps[]>([]);

  const isApiLoading = useMemo((): boolean => {
    return keyRotationStatusLoading || keyRotationLoading;
  }, [keyRotationStatusLoading, keyRotationLoading]);

  const reqVariable = useMemo((): IAwsKeyRotationStatusVariables | IAwsListKeyRotationsVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: { keyId: customerKey.keyId },
    };
  }, [cloudId, region, customerKey]);

  const fetchKeyRotation = useCallback(async () => {
    const keyRotationStatus = await getAwsKeyRotationStatus({ variables: reqVariable }).then(
      ({ data: awsKeyRotationStatus }) => {
        return awsKeyRotationStatus?.getAwsKeyRotationStatus?.data?.[0];
      },
    );
    let lastDateRotation = '-';
    if (keyRotationStatus && keyRotationStatus.keyRotationEnabled) {
      const keyRotations = await getAwsListKeyRotations({ variables: reqVariable }).then(({ data: awsKeyRotation }) => {
        return awsKeyRotation?.getAwsListKeyRotations?.data?.[0].rotations;
      });
      lastDateRotation = keyRotations?.[0]?.rotationDate || '-';
    }
    
    setRotationContent([
      {
        id: 'status',
        type: TextTypeEnum.STATUS,
        title: 'Status',
        description: keyRotationStatus?.keyRotationEnabled ? 'Enabled' : 'Disabled',
      },
      {
        id: 'rotationPeriod',
        type: TextTypeEnum.NORMAL,
        title: 'Rotation period',
        description: keyRotationStatus?.rotationPeriodInDays || '-',
      },
      {
        id: 'lastDateRotation',
        type: TextTypeEnum.NORMAL,
        title: 'Date of last automatic rotation',
        description: lastDateRotation,
      },
      {
        id: 'nextRotationDate',
        type: TextTypeEnum.NORMAL,
        title: 'Next rotation date',
        description: keyRotationStatus?.nextRotationDate || '-',
      },
    ]);
  }, [reqVariable]);

  useEffect(() => {
    fetchKeyRotation();
  }, []);

  return (
    <DetailTab
      title={'Automatic key rotation'}
      description={'AWS KMS automatically rotates the key based on the rotation period that you define.'}
      data={rotationContent}
      isApiLoading={isApiLoading}
    />
  );
};

export default KeyRotation;
