import Box, { FlexDirection } from 'components/atoms/Box';
import Text, { TextType } from 'components/atoms/Text';
import './styles.scss';

type TitleGroupPropsType = {
  title?: string;
  description?: string;
  caption?: string;
};

const TitleGroup = (props: TitleGroupPropsType) => {
  const { title, description, caption } = props;

  return (
    <Box className="title-group-container">
      <Box direction={FlexDirection.ROW}>
        {!!title && <Text type={TextType.Body15Semibold} text={title} />}
        {!!caption && <Text text={`-${caption}`} />}
      </Box>
      {!!description && <Text type={TextType.SmallBodyRegular} text={description} />}
    </Box>
  );
};

export default TitleGroup;
