import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SectionContainer, StepContainer } from '../../../../WebACL/CreateWebACL/components';
import DetailTab from 'pages/v2/Organ/Management/EC2/components/DetailTab';
import DetailTable from 'pages/v2/Organ/Management/EC2/components/DetailTable';
import { RULES_COLUMN_LIST } from '../../configs';
import SectionItem from '../../../../WebACL/CreateWebACL/components/SectionItem';
import Button, { ButtonTypeEnum } from '../../../../WebACL/CreateWebACL/components/Button';

type ReviewPropsType = {
  creationData: any;
  onCancelButtonClicked: () => void;
  onPreviousButtonClicked: () => void;
  onCreateButtonClicked: () => void;
  submitLoading?: boolean;
};

const Review = (props: ReviewPropsType) => {
  const { creationData, onCancelButtonClicked, onPreviousButtonClicked, onCreateButtonClicked, submitLoading } = props;
  const { name, description, cloudWatchMetricName, region, rules = [], capacity } = creationData ?? {};

  const ruleGroupDetail = useMemo(() => {
    return [
      { id: 'name', title: 'Name', description: name },
      { id: 'metricName', title: 'CloudWatch metric name', description: cloudWatchMetricName },
      { id: 'description', title: 'Description', description: description },
      { id: 'region', title: 'Region', description: region.value },
    ];
  }, []);

  const ruleRows = useMemo(() => {
    return rules.map((rule: any) => {
      const { name, action } = rule;

      return {
        name: name,
        capacity: 1,
        action: Object.keys(action)[0],
      };
    });
  }, [rules]);

  return (
    <StepContainer>
      <StepContainer title="Step 1: Describe rule group">
        <SectionContainer customStyle="no-padding-bottom">
          <DetailTab data={ruleGroupDetail} title="Rule group details" />
        </SectionContainer>
      </StepContainer>
      <StepContainer title="Step 2 and step 3: Add rules, set capacity, and set priority">
        <DetailTable
          title="Rules"
          description="If a request matches a rule, take the corresponding action. The rules are prioritized in order they appear."
          data={ruleRows}
          columns={RULES_COLUMN_LIST}
        />
        <SectionContainer title="Capacity">
          <SectionItem.Container title={'Capacity'}>
            <SectionItem.StyledText text={`${capacity}`} />
          </SectionItem.Container>
        </SectionContainer>
      </StepContainer>

      <div className="button-group">
        <Button label="Cancel" onClick={onCancelButtonClicked} />
        <Button label="Previous" onClick={onPreviousButtonClicked} />
        <Button label="Create rule group" type={ButtonTypeEnum.PRIMARY} onClick={onCreateButtonClicked} loading={submitLoading}/>
      </div>
    </StepContainer>
  );
};

export default Review;
