import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListSigningCertificatesResponse } from 'graphql/types/AwsListSigningCertificatesResponse';

export interface IAwsListSigningCertificatesVariables {
  cloudId: number;
  region: string;
  request: {
    userName: string;
  };
}

export interface IAwsListSigningCertificatesResponseData {
  getAwsListSigningCertificates: IGqlResponseNewData<AwsListSigningCertificatesResponse>;
}

const lazyGetAwsListSigningCertificates = () =>
  useLazyQuery<IAwsListSigningCertificatesResponseData, IAwsListSigningCertificatesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListSigningCertificates;
