import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListAccessKeysResponse } from 'graphql/types/AwsListAccessKeysResponse';

export interface IAwsListAccesskeysVariables {
  cloudId: number;
  region: string;
  request: {
    maxItems?: number;
    userName?: string;
  };
}

export interface IAwsListAccesskeysResponseData {
  getAwsListAccesskeys: IGqlResponseNewData<AwsListAccessKeysResponse>;
}

const lazyGetAwsListAccesskeys = () =>
  useLazyQuery<IAwsListAccesskeysResponseData, IAwsListAccesskeysVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListAccesskeys;
