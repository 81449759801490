import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import LabelInput from 'components/v2/LabelInput';
import NotificationAtom from 'components/v2/atoms/NotificationAtom';
import { checkPw } from 'utils/v2/ValidCheck';

interface IResetPwModalProps extends IBaseModalProps {
  title: () => JSX.Element;
  onReset: (pw: string) => void;
}

const ResetPwModal = ({
  title,
  onReset,
  ...baseModalProps
}: IResetPwModalProps) => {

  const [pw, setPw] = useState({
    currentPw: '',
    newPw: '',
    checkPw: ''
  });
  const [error, setError] = useState('');

  const ableResetPwMemo = useMemo(() => {
    if (pw.currentPw === '' || pw.newPw !== pw.checkPw || checkPw(pw.newPw) || checkPw(pw.checkPw)) {
      return true;
    } else return false;
  }, [pw])


  useEffect(() => {
    if (!baseModalProps.open) {
      setPw({
        currentPw: '',
        newPw: '',
        checkPw: ''
      });
      setError('');
    }
  }, [baseModalProps.open]);
  
  useEffect(() => {
    if (pw.newPw !== '' && checkPw(pw.newPw)) {
      setError('Please enter a password that is between 8 and 12 characters long, consisting of a combination of numbers, letters, and special characters.');
    } else {
      if (pw.newPw !== pw.checkPw) {
        setError('New password and confirm password not match.');
      } else {
        setError('');
      }
    }
  }, [pw]);

  return (
    <BaseModal
      title={title} 
      {...baseModalProps}
    >
      <div className="reset-pw-modal">
        <div>
          <LabelInput 
            title="Current password" 
            type="password" 
            required
            id="reset-current-pw" 
            placeholder="Input current password"
            value={pw.currentPw} 
            onChangeValue={(value: string) => {
              if (value.length > 32) return;

              setPw(prev => ({
                ...prev,
                currentPw: value
              }));
            }} 
          />
          <LabelInput 
            title="New password" 
            type="password" 
            required
            id="reset-new-pw"
            placeholder="Input new password"
            value={pw.newPw} 
            onChangeValue={(value: string) => { 
              if (value.length > 32) return;

              setPw(prev => ({
                ...prev,
                newPw: value
              }));
            }}
          />
          <LabelInput 
            title="Confirm new password" 
            type="password" 
            required
            id="reset-pw-check"
            placeholder="Input new password"
            value={pw.checkPw} 
            onChangeValue={(value: string) => {
              if (value.length > 32) return;
              setPw(prev => ({
                ...prev,
                checkPw: value
              }));
            }}
          />
          {error !== '' && <NotificationAtom text={error} /> }
          <button 
            className="big-main-btn-body2regular"
            onClick={() => onReset(pw.newPw)}
            disabled={ ableResetPwMemo }
            >
            Done
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default ResetPwModal;
