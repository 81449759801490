import './style.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { getBetweenTwoDate, randomString } from 'utils/Common';
import lazyGetAwsListPolicyVersions, {
  IAwsListPolicyVersionsVariables,
} from 'graphql/queries/getAwsListPolicyVersions';
import lazyGetAwsPolicyVersion, { IAwsPolicyVersionVariables } from 'graphql/queries/getAwsPolicyVersion';
import TableJsonView from 'layouts/v3/MgdLayout/components/TableJsonView';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';

const PolicyVersions = (props: any) => {
  const { data = {}, cloudId, region } = props;
  const { arn = '' } = data;
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [getAwsListPolicyVersions, { loading: isLoading }] = lazyGetAwsListPolicyVersions();
  const [getAwsPolicyVersion] = lazyGetAwsPolicyVersion();

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Policy version',
        field: 'versionId',
        sort: true,
      },
      {
        label: 'Creation time',
        field: 'createDate',
        sort: true,
      },
    ];
  }, []);

  const handleGetData = (policyArn: string) => {
    const requestVariable: IAwsListPolicyVersionsVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        maxItems: tablePagination.limit,
        policyArn,
      },
    };
    return getAwsListPolicyVersions({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsPolicyVersion = (policyArn: string, versionId: string) => {
    const requestVariable: IAwsPolicyVersionVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        policyArn,
        versionId,
      },
    };
    return getAwsPolicyVersion({ variables: variableCombineNextToken(requestVariable) });
  };

  const fetchData = useCallback(async () => {
    if (!arn) return;

    const { data: listPolicyVersions } = await handleGetData(arn);

    if (!listPolicyVersions?.getAwsListPolicyVersions?.data?.[0]?.versions?.length) return;

    const result = await Promise.all(
      listPolicyVersions?.getAwsListPolicyVersions?.data?.[0]?.versions.map(e =>
        handleGetAwsPolicyVersion(arn, e.versionId),
      ),
    );

    const totalResult = listPolicyVersions?.getAwsListPolicyVersions?.data?.[0]?.versions.map((e, index) => ({
      ...e,
      id: randomString(),
      versionId: e.versionId,
      createDate: getBetweenTwoDate(e.createDate),
      jsonContent: {
        title: e.versionId,
        description: 'Allow Access Analyzer to analyze resource metadata',
        value: result[index]?.data?.getAwsPolicyVersion?.data?.[0]?.policyVersion?.document,
        jsonString: decodeURIComponent(
          result[index]?.data?.getAwsPolicyVersion?.data?.[0]?.policyVersion?.document || '',
        ),
      },
    }));

    setMainTblRows(totalResult);
    setMainTblTotal({
      totalPage: Math.ceil(totalResult.length / tablePagination.itemPerPage),
      totalElement: totalResult.length,
    });
  }, [tablePagination, data, cloudId, region, mainTblRows]);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    const arr: any = orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
    return arr;
  }, [data, mainTblTotal, tablePagination]);

  useEffect(() => {
    fetchData();
  }, [data]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>Versions of this policy</p>

        <p>Each time you update a policy, you create a new version.</p>
      </div>
      {isLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <div className="detail-info-content">
          {!mainRowsCurrentPage.length && !isLoading ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <TableJsonView
                rows={mainRowsCurrentPage}
                columns={columns}
                reportCheckedList={() => {}}
                reportSelected={id => {}}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
                isLoading={isLoading}
              />

              {mainRowsCurrentPage?.length && !isLoading ? (
                <div className="fleet-instance pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{mainTblTotal.totalElement}</span>
                  </p>

                  <TableManagePagination
                    ableFetchMore={false}
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page => updateTablePagination('currentPage', page)}
                    totalPage={mainTblTotal.totalPage}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PolicyVersions;
