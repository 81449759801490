import React, { useRef, useEffect } from 'react';
import { JSONEditor as LibJSONEditor, Content, ContentErrors, JSONPatchResult, Mode } from 'vanilla-jsoneditor';
import './styles.scss';

type JSONEditorPropsType = {
  content: Content;
  onChange: (
    content: Content,
    previousContent: Content,
    changeStatus: { contentErrors: ContentErrors | null; patchResult: JSONPatchResult | null },
  ) => void;
  className?: string;
};

const JSONEditor = (props: JSONEditorPropsType) => {
  const { className } = props;

  const refContainer = useRef<HTMLDivElement | null>(null);
  const refEditor = useRef<LibJSONEditor | null>(null);

  useEffect(() => {
    refEditor.current = new LibJSONEditor({
      target: refContainer.current!,
      props: {
        mode: Mode.text,
        mainMenuBar: false,
        navigationBar: false,
        statusBar: false,
      },
    });

    return () => {
      if (refEditor.current) {
        refEditor.current.destroy();
        refEditor.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (refEditor.current) {
      refEditor.current.updateProps(props);
    }
  }, [props]);

  return <div id="vanilla-jsoneditor-react" className={className} ref={refContainer}></div>;
};

export default JSONEditor;
