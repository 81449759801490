import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListDeliveryStreamsResponse } from 'graphql/types/AwsListDeliveryStreams';

export interface IGetAwsDeliveryStreamListVariables {
  cloudId: number;
  region: string;
  request: {
    limit: number;
  }
}

export interface IAwsDeliveryStreamListResponseData {
  getAwsListDeliveryStreams: IGqlResponseNewData<AwsListDeliveryStreamsResponse>;
}

const lazyGetAwsDeliveryStreams = () => useLazyQuery<IAwsDeliveryStreamListResponseData, IGetAwsDeliveryStreamListVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsDeliveryStreams;