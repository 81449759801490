import CheckTitleIcon from 'assets/svgs/v2/ico_check_title.svg';
import { useAuth } from 'contexts/AuthProvider';

const ResetDone = () => {
  const { logout } = useAuth();

  return (
    <>
      <article id="reset-pw-done-article">
        <h3><img src={CheckTitleIcon} width={32} height={32} />Your password has been changed.</h3>
        <h4>You can now log in with your new password.</h4>
        <div className="button-wrap">
          <button
            className='big-main-btn'
            onClick={() => logout()}
          >
            Login
          </button>
        </div>
      </article>
    </>
  );
};
export default ResetDone;