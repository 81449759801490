import { useEffect, useState } from 'react';
import './index.scss';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import { useAuth } from 'contexts/AuthProvider';
import { Histories } from '@Types/v2/SelectOrgan';
import { timeToKr } from 'utils/timeFormatter';
import lazySearchOrgan from 'graphql/queries/searchOrgan';
import { OrganById } from 'apis/v2/Organ/schema';
import SearchPrefixIcon from 'assets/svgs/v4/ico_search.svg';
import { useNavigate, useParams } from 'react-router-dom';

interface ISelectOrganModalProps extends IBaseModalProps {
  title: () => JSX.Element;
  searchForSelectOrgan: string;
  onChangeSearchForSelectOrgan: (str: string) => void;
  onSelect: (organId:number, organName: string ) => void;
}

const SelectOrganModal = ({
  title,
  searchForSelectOrgan,
  onChangeSearchForSelectOrgan,
  onSelect,
  ...baseModalProps
}:ISelectOrganModalProps) => {
  const navigate = useNavigate();
  const {organId} = useParams();
  const { userInfo } = useAuth();

  /* 조직리스트 */
  const [organList, setOrganList] = useState<OrganById[]>([]);
  /* 최근검색기록 */
  const [history, setHistory] = useState<{ id: number; name: string; date: string; }[]>([])

  // 조직 이름으로 조직 검색
  const [searchOrg] = lazySearchOrgan();

  useEffect(() => {
    onChangeSearchForSelectOrgan('');
    setOrganList([]);
  }, [baseModalProps.open]);

  useEffect(() => {
    if (!baseModalProps.open) return;

    if (searchForSelectOrgan === '') return;

    searchOrg({ variables: { text: searchForSelectOrgan }}).then(({ data }) => {
      if (data) {
        if (data.searchOrgan.data) {
          setOrganList(data.searchOrgan.data);
        }
      }
    })
  }, [baseModalProps.open, searchForSelectOrgan]);

  useEffect(() => {
    if (!userInfo) return;
    
    const getHistories = localStorage.getItem('histories');
    if (localStorage.histories === undefined || !getHistories) {
      localStorage.setItem('histories', JSON.stringify([
        {
          user: userInfo?.nemo.memberId,
          list: []
        }
      ]))
    } else {
      const histories:Histories = JSON.parse(getHistories);
      const findMyHist = histories.find(val => val.user === userInfo?.nemo.memberId);
      
      if (findMyHist === undefined) {
        histories.push({
          user: userInfo.nemo.memberId,
          list: []
        })
      } else {
        const sortCurrent = findMyHist.list.sort((a, b) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        }).reverse();
        setHistory(sortCurrent);
      }
    }
  }, [userInfo, baseModalProps.open])
  
  return (
    <BaseModal
      title={title} 
      {...baseModalProps}
    >
      <div className='select-organ-modal'>
        <div className='contents'>
          <div className='search-wrap'>
          <InputAtom
            hasPrefixIcon={true}
            srcPrefixIcon={SearchPrefixIcon}
            placeholder='Organization name'
            value={searchForSelectOrgan}
            onChangeValue={str => onChangeSearchForSelectOrgan(str)}
            />
          </div>
          <div className='border' />
          <p>
            {
              searchForSelectOrgan === '' 
              ? 'Recent'  
              : organList.length === 0 ? 'Results' : `Results ${organList.length}`
            }
          </p>
          <div className='list-wrap'>
            {searchForSelectOrgan === ''
              ? <>
                  {
                    history.length === 0 
                    ? <div className='empty-search flex col j-center a-center'>
                        There is no recent search.
                      </div> 
                    : <>{ history.map((val, idx) => (
                        <div 
                          key={idx} 
                          className='row flex j-between a-center'
                          onClick={() => onSelect(val.id, val.name)}
                        >
                          <div className='left-wrap flex a-center'>
                            <div className='clock-icon '/>
                            <p>{val.name}</p>
                          </div>
                          <div className='date-wrap'>
                            {timeToKr(val.date)}
                          </div>
                      </div>
                    ))}</>
                  }
                </> 
              : <>{ organList.length === 0 
                ? <div className='empty-search flex col j-center a-center'>
                    <div className='no-result-icon' />
                    There is no item.
                  </div> 
                : <>{ organList.map((val, idx) => (
                    <div
                     key={idx} 
                     className='row flex j-between a-center'
                     onClick={() => onSelect(val.organId, val.name)}
                    >
                      <div className='left-wrap flex a-center'>
                        <div className='organ-icon'/>
                        <p>{val.name}</p>
                      </div>
                    </div>
                  ))}</>
                }</>
            }
          </div>
          <div className='border' />
          <button className="big-main-btn" onClick={() => {
            navigate(`/organ/${organId}/select-organ`);
            baseModalProps.onClose && baseModalProps.onClose();
          }}>Show all organizations</button>
        </div>
      </div>
    </ BaseModal>
  )
};

export default SelectOrganModal;