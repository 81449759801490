import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';

export interface IAwsAssociateWebACLVariables {
  cloudId: number;
  region: string;
  request: {
    webACLArn: string;
    resourceArn: string;
  }
}

export interface IAwsAssociateWebACLResponseData {
  getAwsAssociateWebACL: IGqlResponseData;
}

const lazyAssociateAwsWebACL = () => useLazyQuery<IAwsAssociateWebACLResponseData, IAwsAssociateWebACLVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyAssociateAwsWebACL;