import './index.scss';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import InputAtom from 'components/v2/atoms/InputAtom';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { timeToKr } from 'utils/timeFormatter';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import Icon from 'components/v2/atoms/Icon';
import lazyGetCloudInfoPageByParam from 'graphql/queries/getCloudInfoPageByParam';
import { TABLE_OFFSET } from 'utils/DummyData/Dropdown';
import { lazyGetAllRuleCloud } from 'graphql/queries/getAllRuleCloud';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import getProjectInfoByOrgan from 'graphql/queries/getProjectInfoByOrgan';
import { AllRuleCloudType } from 'graphql/types/AllRuleCloud';
import { IConfigAccountProps } from '@Types/v3/content';
import { useAuth } from 'contexts/AuthProvider';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import Labeled from 'components/v2/atoms/Labeled';
import Waf from './WAF';
import Network from './Network';
import Firewall from './Firewall';
import KeyAndSecret from './KeyAndSecret';
import EC2 from './EC2';
import { handleFormatText } from 'utils/Common';
import { orderAlphabetical } from './Utils/Sorting';
import IAM from './IAM';
import { DropdownListDataType } from 'layouts/v3/MgdLayout/components/DropdownAtomRelatedCloud';

export enum ManagementTypeEnum {
  RELATE_CLOUD = 'RELATE_CLOUD',
  NETWORK = 'NETWORK',
  WAF = 'WAF',
  FIREWALL = 'FIREWALL',
  KEYSECRET = 'KEYSECRET',
  INSTANCE = 'INSTANCE',
  IAM = 'IAM',
}

const ManagementLayout = () => {
  const { role } = useAuth();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { projectList, organizationId } = useOutletContext<{
    projectList: ProjectInfo[];
    organizationId: number | null;
  }>();
  const [readyToSearch, setReadyToSearch] = useState({ project: -1, name: '' });
  const [search, setSearch] = useState({ project: -1, name: '' });
  const [total, setTotal] = useState({
    totalPage: 0,
    totalElement: 0,
  });
  const [isShowingRelateCloud, setIsShowingRelateCloud] = useState(true);
  const [isShowing, setIsShowing] = useState<string>('');
  const [rows, setRows] = useState<RowType[]>([]);
  const [pjList, setPjList] = useState<{ name: string; value: number }[]>([]);
  const [cloudTypeList, setCloudTypeList] = useState<AllRuleCloudType[]>([]);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [relatedCloudDropdownList, setRelatedCloudDropdownList] = useState<DropdownListDataType[]>([]);
  const [relatedCloudSelected, setRelatedCloudSelected] = useState<DropdownListDataType>(relatedCloudDropdownList[0]);
  const [currentRouteState, setCurrentRouteState] = useState<any>(null);

  const [getCloudInfoPageByParam] = lazyGetCloudInfoPageByParam();
  const [getAllRuleCloud] = lazyGetAllRuleCloud();
  const [getProjectListByOrganId] = getProjectInfoByOrgan();

  const COLUMNS: ColumnType[] = useMemo(() => {
    return [
      {
        label: 'Project',
        field: 'projectName',
        sort: true,
      },
      {
        label: 'Account ID',
        field: 'attrValue2',
        renderCell: (row: RowType) => <>{handleFormatText(getAccountId(row.attrValue2))}</>,
        sort: true,
      },
      {
        label: 'Name',
        field: 'name',
        sort: true,
      },
      {
        label: 'Cloud',
        field: 'cloudId',
        renderCell: (row: RowType) => (
          <>{handleFormatText(cloudTypeList.find(li => li.cloudId === row.cloudId)?.name)}</>
        ),
        sort: true,
      },
      {
        label: 'Region',
        field: 'regionCnt',
      },
      {
        label: 'Modifier',
        field: 'modifiedByName',
        sort: true,
      },
      {
        label: 'Date',
        field: 'modifiedAt',
        renderCell: (row: RowType) => <>{timeToKr(row.modifiedAt)}</>,
        sort: true,
      },
    ];
  }, [cloudTypeList]);

  const getCloudInfoPageData = useMemo(
    () => ({
      reqData: {
        organId: Number(organizationId),
        projectId: search.project === -1 ? undefined : search.project,
        text: search.name === '' ? undefined : search.name,
        pageInfo: {
          page: tablePagination.currentPage - 1,
          size: tablePagination.limit,
          orders: [
            {
              target: tablePagination.target === 'modifiedByName' ? 'modifiedBy' : tablePagination.target,
              direction: tablePagination.direction,
            },
          ],
        },
      },
    }),
    [organizationId, tablePagination, search],
  );

  useEffect(() => {
    if (role === 'sy_admin') {
      getProjectListByOrganId({ variables: { organId: Number(organizationId) } }).then(({ data }) => {
        if (data) {
          setPjList(
            data.getProjectInfoByOrganId.data.map(val => ({
              name: val.name,
              value: val.projectId,
            })),
          );
        }
      });
    } else {
      setPjList(
        projectList.map(val => ({
          name: val.name,
          value: val.projectId,
        })),
      );
    }
  }, [role, organizationId, projectList]);

  const getCloudList = () => {
    getAllRuleCloud().then(res => {
      if (res.data) {
        setCloudTypeList(res.data.getAllRuleCloud.data);
      }
    });
  };

  const getCloudInfoPageRows = () => {
    getCloudInfoPageByParam({ variables: getCloudInfoPageData }).then(({ data }) => {
      if (data) {
        setRows(data.getCloudInfoPageByParam.content);
        setTotal({
          totalPage: data.getCloudInfoPageByParam.totalPages,
          totalElement: data.getCloudInfoPageByParam.totalElements,
        });
      }
    });
  };

  const getAccountId = (attrString: string) => {
    try {
      const json: IConfigAccountProps = JSON.parse(attrString);
      return json.accountId;
    } catch {
      return attrString;
    }
  };

  const getRegionCode = (cloudId: number) => {
    return cloudId == 48 ? 'GcpRegionCode' : cloudId == 47 ? 'AzureRegionCode' : 'AwsRegionCode';
  };

  const getRegionsOfCloud = (attrString: string) => {
    try {
      const json: { region: string[] } = JSON.parse(attrString);
      return json.region;
    } catch {
      return [];
    }
  };

  useEffect(() => {
    getCloudList();
  }, []);

  useEffect(() => {
    renderContentHandler();
    setTablePagination({
      limit: 10,
      target: 'modifiedAt',
      direction: OrderDirection.DES,
      currentPage: 1,
    });
  }, [pathname]);

  useEffect(() => {
    getCloudInfoPageRows();
  }, [getCloudInfoPageData]);

  const findPathName = useCallback(
    (path: string) => {
      if (pathname.includes(path)) {
        return pathname;
      }
    },
    [pathname],
  );


  const handleRelatedCloudRowSelected = useCallback(
    (rowId: string) => {
      setSelected(rowId);
      const currentRow = rows.find(row => row.id == rowId);
      if (currentRow) {
        const firstFiveRows = orderAlphabetical(rows, 'modifiedAt', OrderDirection.DES).slice(0, 5);
        const isIncludeInArray = firstFiveRows.find(row => row.id == currentRow?.id);
        const dropdownList: DropdownListDataType[] = [];
        if (!!isIncludeInArray) {
          firstFiveRows.map(row => {
            dropdownList.push({
              id: Number(row.id),
              name: row?.name,
              value: Number(row?.id),
            });
          });
        } else {
          const fiveRows = firstFiveRows.slice(0, 4);
          fiveRows.push({ ...currentRow });
          fiveRows.map(row => {
            dropdownList.push({
              id: Number(row.id),
              name: row?.name,
              value: Number(row?.id),
              modifiedAt: row?.modifiedAt
            });
          });
        }

        dropdownList.push({
          id: 9999,
          name: 'Select other ‘related cloud’',
          value: 9999,
          type: 'btn',
        });
        setRelatedCloudSelected(dropdownList.find(dropdown => dropdown.id == currentRow.id)!);
        setRelatedCloudDropdownList(dropdownList);
      }
    },
    [rows],
  );

  useEffect(() => {
    if (rows.length > 0 && state) {
      setCurrentRouteState(state);
      navigate(pathname, {
        replace: true,
      });
    }
  }, [rows, state]);

  useEffect(() => {
    if (currentRouteState?.managementType) {
      setIsShowing(currentRouteState?.managementType);
    }
  }, [currentRouteState]);

  const renderContentHandler = useCallback(() => {
    switch (pathname) {
      case findPathName('network'):
        setIsShowing(ManagementTypeEnum.NETWORK);
        break;
      case findPathName('waf'):
        setIsShowing(ManagementTypeEnum.WAF);
        break;
      case findPathName('firewall'):
        setIsShowing(ManagementTypeEnum.FIREWALL);
        break;
      case findPathName('key-secret'):
        setIsShowing(ManagementTypeEnum.KEYSECRET);
        break;
      case findPathName('instance'):
        setIsShowing(ManagementTypeEnum.INSTANCE);
        break;
      case findPathName('iam'):
        setIsShowing(ManagementTypeEnum.IAM);
        break;
      default:
        break;
    }
  }, [pathname]);

  const handleShowRelatedCloud = () => {
    setIsShowing(ManagementTypeEnum.RELATE_CLOUD);
    setCurrentRouteState(null);
    setIsShowingRelateCloud(true);
  };

  useEffect(() => {
    if (!relatedCloudSelected?.type) return;

    handleShowRelatedCloud();
  }, [relatedCloudSelected]);

  const renderShowing = useMemo(() => {
    if (isShowingRelateCloud) {
      return (
        <div id="mgd-search-related-cloud">
          <div className="network-header">Related cloud account</div>
          <div className="resize-container vertical">
            <div className="row-1 flex j-between a-center">
              <div className="flex a-center">
                <Labeled title="Project" className="search-project">
                  <DropdownAtom
                    id="select-project"
                    data={[
                      {
                        name: 'All',
                        value: -1,
                      },
                      ...pjList,
                    ]}
                    value={{
                      name:
                        readyToSearch.project === -1
                          ? 'All'
                          : pjList.find(pj => pj.value === readyToSearch.project)?.name
                          ? pjList.find(pj => pj.value === readyToSearch.project)?.name
                          : '',
                      value: readyToSearch.project,
                    }}
                    handleClick={(val: DropdownListDataType) =>
                      setReadyToSearch(prev => ({
                        ...prev,
                        project: Number(val.value),
                      }))
                    }
                  />
                </Labeled>
              </div>
              <div className="flex action a-center">
                <InputAtom
                  placeholder="Account Id or related cloud name"
                  type={'text'}
                  value={readyToSearch.name}
                  onChangeValue={str =>
                    setReadyToSearch(prev => ({
                      ...prev,
                      name: str,
                    }))
                  }
                  defaultValue={''}
                  hasPrefixIcon={true}
                  srcPrefixIcon={SearchIcon}
                  prefixIconOnClick={() => {}}
                />

                <Icon
                  className="search-btn"
                  width={18}
                  height={18}
                  src={SearchIcon}
                  onClick={e => {
                    e.preventDefault();
                    setSearch({
                      project: readyToSearch.project,
                      name: readyToSearch.name,
                    });
                    setTablePagination(prev => ({
                      ...prev,
                      currentPage: 1,
                    }));
                  }}
                />
              </div>
            </div>

            {/* table data order */}
            <div className="data-grid-wrap">
              <Table
                rows={rows}
                columns={COLUMNS}
                reportCheckedList={list => setCheckedList(list)}
                reportSelected={(id: string) => {
                  handleRelatedCloudRowSelected(id);
                  renderContentHandler();
                  setIsShowingRelateCloud(false);
                }}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    setTablePagination(prev => ({
                      ...prev,
                      target: target,
                      direction: dir,
                    }));
                  },
                }}
              />
              {rows.length === 0 ? (
                <div className="empty-cloud flex j-center a-center">
                  <div className="flex col a-center">
                    <div />
                    <h5>등록된 연계 클라우드 계정이 없습니다.</h5>
                    <p>등록하기를 통해 연계 클라우드 계정을 등록해주세요.</p>
                  </div>
                </div>
              ) : (
                <div className="pagination-wrapper flex a-center">
                  <p className="flex">
                    Total <span>{total.totalElement}</span>
                  </p>
                  <TablePagination
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page =>
                      setTablePagination(prev => ({
                        ...prev,
                        currentPage: page,
                      }))
                    }
                    totalPage={total.totalPage}
                  />
                  <DropdownAtom
                    id="offset-limit"
                    data={TABLE_OFFSET}
                    value={{
                      name: TABLE_OFFSET.find(data => data.value === tablePagination.limit)?.name,
                      value: Number(tablePagination.limit),
                    }}
                    handleClick={val => {
                      setTablePagination(prev => ({
                        ...prev,
                        limit: Number(val.value),
                        currentPage: 1,
                      }));
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    const projectId = rows.find(val => val.id === Number(selected))?.projectId;
    const cloudName = rows.find(val => val.id === Number(selected))?.name;
    const regionCode = getRegionCode(rows.find(val => val.id === Number(selected))?.cloudId);
    const regions = getRegionsOfCloud(rows.find(val => val.id === Number(selected))?.msspAttrValue);

    switch (isShowing) {
      case ManagementTypeEnum.NETWORK:
        return (
          <Network
            projectId={projectId}
            cloudName={cloudName}
            regionCode={regionCode}
            regions={regions}
            pageBackClick={handleShowRelatedCloud}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            recentRelatedClouds={relatedCloudDropdownList}
            relatedCloudSelected={relatedCloudSelected}
            recentRelatedCloudOnChange={(relatedCloud: DropdownListDataType) => setRelatedCloudSelected(relatedCloud)}
          />
        );

      case ManagementTypeEnum.WAF:
        return (
          <Waf
            projectId={projectId}
            cloudName={cloudName}
            regionCode={regionCode}
            regions={regions}
            pageBackClick={handleShowRelatedCloud}
            recentRelatedClouds={relatedCloudDropdownList}
            relatedCloudSelected={relatedCloudSelected}
            recentRelatedCloudOnChange={relatedCloud => setRelatedCloudSelected(relatedCloud)}
          />
        );

      case ManagementTypeEnum.FIREWALL:
        return (
          <Firewall
            projectId={projectId}
            cloudName={cloudName}
            regionCode={regionCode}
            regions={regions}
            pageBackClick={handleShowRelatedCloud}
            recentRelatedClouds={relatedCloudDropdownList}
            relatedCloudSelected={relatedCloudSelected}
            recentRelatedCloudOnChange={relatedCloud => setRelatedCloudSelected(relatedCloud)}
          />
        );

      case ManagementTypeEnum.KEYSECRET:
        return (
          <KeyAndSecret
            projectId={projectId}
            cloudName={cloudName}
            regionCode={regionCode}
            regions={regions}
            onBackButtonClicked={handleShowRelatedCloud}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            recentRelatedClouds={relatedCloudDropdownList}
            relatedCloudSelected={relatedCloudSelected}
            recentRelatedCloudOnChange={(relatedCloud: DropdownListDataType) => setRelatedCloudSelected(relatedCloud)}
          />
        );

      case ManagementTypeEnum.INSTANCE: {
        return (
          <EC2
            projectId={projectId}
            cloudName={cloudName}
            regionCode={regionCode}
            regions={regions}
            onBackButtonClicked={handleShowRelatedCloud}
            recentRelatedClouds={relatedCloudDropdownList}
            relatedCloudSelected={relatedCloudSelected}
            recentRelatedCloudOnChange={relatedCloud => setRelatedCloudSelected(relatedCloud)}
          />
        );
      }

      case ManagementTypeEnum.IAM: {
        return (
          <IAM
            projectId={projectId}
            cloudName={cloudName}
            regionCode={regionCode}
            regions={regions}
            onBackButtonClicked={handleShowRelatedCloud}
            recentRelatedClouds={relatedCloudDropdownList}
            relatedCloudSelected={relatedCloudSelected}
            recentRelatedCloudOnChange={relatedCloud => setRelatedCloudSelected(relatedCloud)}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
          />
        );
      }

      default:
        return null;
    }
  }, [
    isShowingRelateCloud,
    isShowing,
    rows,
    selected,
    currentRouteState,
    relatedCloudSelected,
    relatedCloudDropdownList,
    readyToSearch
  ]);

  return <>{renderShowing}</>;
};

export default ManagementLayout;
