import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';
import { AwsCreateRegexPatternSetResponse } from 'apis/v2/Manage/WafSchema';
import { AwsCreateWebAclRequestType, AwsCreateWebAclResponseType } from 'graphql/types/AwsCreateWebAcl';

export type CreateWebAclVariablesType = {
  cloudId: number;
  region: string;
  request: AwsCreateWebAclRequestType 
}

export interface CreateWebAclResponseDataType {
  CreateWebACL: IGqlResponseNewData<AwsCreateWebAclResponseType>;
}

const createWebAclMutation = () => useMutation<CreateWebAclResponseDataType, CreateWebAclVariablesType>(query);
export default createWebAclMutation;