import './index.scss';
import { useState } from 'react';
import PageDetailTitle from '../../PageDetailTitle';
import Details from '../Common/Components/Details';
import Tags from '../Common/Components/Tags';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';
import Routes from '../Common/Components/Routes';
import SubnetAssociations from '../Common/Components/SubnetAssociations';
import EdgeAssociations from '../Common/Components/EdgeAssociations';
import RoutePropagation from '../Common/Components/RoutePropagation';

const detailTabs: IMgdTabProps[] = [
  { id: 'route', name: 'Routes', title: 'Routes' },
  { id: 'subnetAssociations', name: 'Subnet associations', title: 'Subnet associations' },
  { id: 'edgeAssociations', name: 'Edge associations', title: 'Edge associations' },
  { id: 'routePropagation', name: 'Route propagation', title: 'Route propagation' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

const RouteTableDetail = (props: any) => {
  const { cloudId, region, data, pageBackClick } = props;

  // @ts-ignore
  const [tabSelected, setTabSelected] = useState(detailTabs[0]);

  const renderTab = () => {
    switch (tabSelected.id) {
      case detailTabs[0].id:
        return <Routes data={data} />;

      case detailTabs[1].id:
        return <SubnetAssociations cloudId={cloudId} region={region} data={data} />;

      case detailTabs[2].id:
        return <EdgeAssociations cloudId={cloudId} region={region} data={data} />;

      case detailTabs[3].id:
        return <RoutePropagation cloudId={cloudId} region={region} data={data} />;

      case detailTabs[4].id:
        return <Tags data={data} />;

        default:
        return null;
    }
  };

  return (
    <div className="page-detail">
      <PageDetailTitle
        title={`Route Table / ${data?.id}`}
        pageBackClick={pageBackClick}
      />

      <>
        <Details data={data} />

        <div className="page-detail-tab">
          <div className="detail-tab flex a-center">
            {detailTabs.map(tab => {
              return (
                <button
                  className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
                  key={tab.id}
                  data-tab={tab.id}
                  onClick={e => setTabSelected(tab)}
                >
                  <p>{tab.name}</p>
                </button>
              );
            })}
          </div>

          <div className="content-tab">{renderTab()}</div>
        </div>
      </>
    </div>
  );
};

export default RouteTableDetail;
