import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import LabelInput from 'components/v2/LabelInput';
import Labeled from 'components/v2/atoms/Labeled';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import SearchDropdown from 'components/v2/SearchDropdown';
import lazySearchEvent from 'graphql/queries/searchEvent';
import insertEventSetting from 'graphql/mutations/insertEventSetting';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import lazySearchMyEventMessage from 'graphql/queries/searchMyEventMessage';

interface ICreateEventSettingModalProps extends IBaseModalProps {
  title: () => JSX.Element;
  onCreateSuccess: () => void;
  pjList: {
    name: string;
    value: number;
  }[];
  refetchPjList: () => void;
  priorityTypeList: {
    name: string;
    value: string;
  }[];
  msgReceiveTypeList: {
    name: string;
    value: string;
  }[];
}

const CreateEventSettingModal = ({
  title,
  pjList,
  refetchPjList,
  priorityTypeList,
  msgReceiveTypeList,
  onCreateSuccess,
  ...baseModalProps
}:ICreateEventSettingModalProps) => {

  const [payload, setPayload] = useState({
    projectId: -1,
    projectName: '',
    eventId: -1,
    eventRule: '',
    name: '', /* 이벤트설정이름 */
    priorityCode: '',
    msgSenderTypeCode: '',
    messageText: '',
    messageId: -1
  });
  const [confirmEventRule, setConfirmEventRule] = useState(false);
  const [search, setSearch] = useState('');
  const [eventRuleList, setEventRuleList] = useState<{name: string; value: number}[]>([]);
  const [msgTextTypeList, setMsgTextTypeList] = useState<{name: string; value: number}[]>([]);

  /* 이벤트 규칙 검색어에 따른 이벤트규칙리스트 조회 */
  const [lazySearchEventList] = lazySearchEvent();
  /* 수신 타입에 따른 메시지 조회 */
  const [lazyEventMessageList] = lazySearchMyEventMessage();
  /* 이벤트 설정 생성 */
  const [addEventSetting] = insertEventSetting();

  const EnableButton = useMemo(() => {
    if (
      payload.projectId === -1 ||
      payload.name === '' ||
      payload.eventId === -1 ||
      payload.priorityCode === '' ||
      payload.msgSenderTypeCode === '' ||
      payload.messageText === '' 
    ) {
      return true;
    } else return false;
  }, [payload]);

  const getEventList = (str:string) =>  {
    lazySearchEventList({ variables: { text: str } }).then(({ data }) => {
      if (data) {
        setEventRuleList(data.searchEvent.data.map(val => ({
          name: val.name,
          value: val.eventId
        })));
      }
    });
  };

  const getEventMessageList = (sel: string) => {
    lazyEventMessageList({variables: { sendTypeCode: sel }}).then(({ data }) => {
      if (data) {
        if (data.getEventMessagesByType.data) {
          setMsgTextTypeList(data.getEventMessagesByType.data.map(val => ({
            name: val.title,
            value: val.id
          })));
        }
      }
    })
  }

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload({
        projectId: -1,
        projectName: '',
        eventId: -1,
        eventRule: '',
        name: '',
        priorityCode: '',
        msgSenderTypeCode: '',
        messageText: '',
        messageId: -1
      });
      setSearch('');
      getEventList('');
      setConfirmEventRule(false);
      refetchPjList();
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal 
      {...baseModalProps}
      title={title}
    >
      <div className="create-eventSetting-modal">
        <div className="contents-wrap">
          <LabelInput 
            title="Name"
            value={payload.name}
            placeholder="Input event-setting's name"
            required={true}
            onChangeValue={(val) => setPayload((prev) => ({
              ...prev,
              name: val
            }))}
          />
          <Labeled
            title="Project"
            required={true}
          >
            <DropdownAtom 
              id="project-name" 
              placeholder='Select project'
              data={pjList} 
              value={{
                name: pjList.find(val => val.value === payload.projectId)?.name
                  ? pjList.find(val => val.value === payload.projectId)?.name
                  : '',
                value: payload.projectId
              }}
              handleClick={(val) => {
                setPayload(prev => ({
                  ...prev,
                  projectId: Number(val.value),
                  projectName: String(val.name)
                }))
              }}
          />
          </Labeled>
          <Labeled 
            title="Event-rule"
            required
          >
            <SearchDropdown 
              placeholder='Select event-rule'
              id="search-event-rule"
              data={eventRuleList}
              searchValue={search}
              isConfirmed={confirmEventRule}
              onChangeSearch={(str) => {
                setSearch(str);
                getEventList(str);
              }}
              handleClick={(val) => {
                setPayload(prev => ({
                  ...prev,
                  eventId: Number(val.value),
                  eventRule: String(val.name)
                }));
                setSearch(String(val.name));
                getEventList(String(val.name));
                setConfirmEventRule(true);
              } }
              resetIsConfirm={() => {
                setConfirmEventRule(false);
                setPayload(prev => ({
                  ...prev,
                  eventId: -1,
                  eventRule: ''
                }))
              }}
            />
          </Labeled>
          <Labeled title="Priority" required>
            <DropdownAtom 
              id="priority-code" 
              data={priorityTypeList} 
              placeholder='Select priority'
              value={{
              name: priorityTypeList.find(val => val.value === payload.priorityCode)?.name
               ? priorityTypeList.find(val => val.value === payload.priorityCode)?.name
               : '',
              value: payload.priorityCode
              }}
              handleClick={(val) => {
                setPayload(prev => ({
                  ...prev,
                  priorityCode: String(val.value)
                }))
              }}
            />
          </Labeled>
          <Labeled title="Received type" required>
          <DropdownAtom 
            id="receive-type" 
            data={msgReceiveTypeList}
            placeholder='Select received type'
            value={{
            name: msgReceiveTypeList.find(val => val.value === payload.msgSenderTypeCode)?.name
            ? msgReceiveTypeList.find(val => val.value === payload.msgSenderTypeCode)?.name
            : ''
            ,
            value: payload.msgSenderTypeCode
            }}
            handleClick={(val) => {
              /* 만약 receiveType이 기존 detail.msgSenderTypeCode와 다르다면 detail.msgText 초기화 */
              if (val.value === payload.msgSenderTypeCode) return;
              setPayload(prev => ({
                ...prev,
                msgSenderTypeCode: String(val.value),
                messageText: ''
              }))
              getEventMessageList(String(val.value));
            }}
          />
          </Labeled>
          <Labeled 
            title="Message" 
            required
          >
          <DropdownAtom 
            id="message-text" 
            disabled={payload.msgSenderTypeCode === ''}
            data={msgTextTypeList.length !== 0 ? msgTextTypeList : [
              {
                name: '메세지가 없습니다. 공통정보관리에서 메세지를 생성해주세요.',
                value: -1
              }
            ]} 
            placeholder='Select message'
            value={{
            name: payload.messageText,
            value: payload.messageId
            }}
            handleClick={(val) => {
              if (val.value === -1) return;

              setPayload(prev => ({
                ...prev,
                messageText: String(val.name),
                messageId: Number(val.value)
              }))
            }}
          />
          </Labeled>
        </div>
        <div className="btns">
          <button 
            className="big-main-btn flex j-center a-center"  
            disabled={EnableButton}
            onClick={() => {
              const insertEventSettingData = { 
                reqData: { 
                  projectId: payload.projectId,
                  eventId: payload.eventId,
                  name: payload.name,
                  priorityCode: payload.priorityCode,
                  messageId: payload.messageId
               }
              }

              addEventSetting({ variables: insertEventSettingData }).then(({ data }) => {
                if (data) {
                  if (data.insertEventSetting.result === ErrorCode.SUCCESS) {
                    useToast(ErrorCode.SUCCESS, 'Event-setting is created successfully.');                    
                    onCreateSuccess();
                  } else {
                    console.log(data.insertEventSetting.result);
                    useToast(ErrorCode.UNKNOWN, 'Create event-setting failed.');
                  }
                } else {
                  useToast(ErrorCode.UNKNOWN, 'Create event-setting failed.');
                }
              });
            }}
          >
            Create
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default CreateEventSettingModal;
