export interface IStagingLabelContentProps {
  label: string;
}

export interface IStagingLabelProps {
  [key: string]: IStagingLabelContentProps;
}

export const STAGING_LABLE_MAPPING: IStagingLabelProps = {
  'AWSCURRENT': {label: 'AWS current'},
  '': {label: '-'}
};