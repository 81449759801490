import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { AwsTagSpecification } from 'graphql/types/AwsDescribeSpotInstanceFleetRequestsCombinedResponse';

export interface IcreateAwsSecurityGroupVariables {
  cloudId: number;
  region: string;
  request: {
    groupName: string;
    vpcId: string;
    description: string;
    tagSpecifications?: AwsTagSpecification[];
  }
}

export interface IcreateAwsSecurityGroupResponseData {
  createAwsSecurityGroup: IGqlResponseData<undefined>;
}

const createAwsSecurityGroupMutation = () => useMutation<IcreateAwsSecurityGroupResponseData, IcreateAwsSecurityGroupVariables>(query);
export default createAwsSecurityGroupMutation;