import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import LabelInput from 'components/v2/LabelInput';
import Labeled from 'components/v2/atoms/Labeled';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { alphabetNumberRegex, numberRegex } from 'utils/Regex';
import LabelTextarea from 'components/v2/LabelTextarea';
import { useMutation } from 'react-query';
import { IAddCategoryReq } from 'apis/v2/Manage/schema';
import apis from 'apis/v2';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import addCategory from 'graphql/mutations/addCategory';

interface ICreateCategoryModalProps extends IBaseModalProps {
  cloudList: {
    id: number;
    value: string;
    name: string; 
  }[];
  title: () => JSX.Element;
  onCreateSuccess: () => void;
}

const CreateCategoryModal = ({
  cloudList,
  title,
  onCreateSuccess,
  ...baseModalProps
}:ICreateCategoryModalProps) => {

  const [payload, setPayload] = useState({
    cloudId: -1,
    name: '',
    maskCode: '',
    description: '',
    title: '',
    template: ''
  });

  const { mutateAsync: _addCategory } = useMutation((data: IAddCategoryReq) => apis.Manage.addCategory(data));
  const [createCategory] = addCategory();

  const enableButton = useMemo(() => {
    if (
      payload.cloudId === -1 ||
      payload.name === '' ||
      payload.maskCode.length < 8 ||
      payload.description === '' ||
      payload.title === ''
    ) {
      return true;
    } else return false;
  }, [payload])

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload({
        cloudId: -1,
        name: '',
        maskCode: '',
        description: '',
        title: '',
        template: ''
      });
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal 
      {...baseModalProps}
      title={title}
    >
      <div className="create-category-modal">
        <div className="contents-wrap">
          <LabelInput 
            title="Name"
            placeholder="Input rule category's name"
            required
            value={payload.name}
            onChangeValue={(val) => setPayload((prev) => ({
              ...prev,
              name: val
            }))}
          />
          <Labeled
            title="Rule cloud"
            required={true}
          >
            <DropdownAtom 
              id="cloud-kind"
              placeholder='Select rule cloud'
              data={cloudList} 
              value={{
                name: cloudList.find(val => val.id === payload.cloudId)?.name 
                ? cloudList.find(val => val.id === payload.cloudId)?.name
                : '',
                value: payload.cloudId
              }}
                handleClick={(val) => {
                setPayload(prev => ({
                  ...prev,
                  cloudId: Number(val.id)
                }))
              }}                
            />
          </Labeled>
          <LabelInput 
            title="Unique delimiter" 
            required
            placeholder='Input delimiter (mask code 8 digits)'
            value={payload.maskCode}
            onChangeValue={(val) => {
              if (val.length > 8) return;

              setPayload((prev) => ({
              ...prev,
              maskCode: val.replace(alphabetNumberRegex, '')
            }))
            }}
          />
          <LabelInput 
            title="Description" 
            required
            placeholder='Input description'
            value={payload.description}
            onChangeValue={(val) => {
              setPayload((prev) => ({
              ...prev,
              description: val
            }))
            }}
          />
          <LabelInput 
            title="Document title" 
            required
            placeholder='Input document title'
            value={payload.title}
            onChangeValue={(val) => {
              setPayload((prev) => ({
              ...prev,
              title: val
            }))
            }}
          />
          <LabelTextarea
            title="Document format" 
            placeholder='Input document format.'
            value={payload.template}
            onChangeValue={(val) => {
              setPayload((prev) => ({
              ...prev,
              template: val
            }))
            }}
          />
        </div>
        <div className="btns">
          <button 
            className="big-main-btn flex j-center a-center"  
            disabled={enableButton}
            onClick={() => {
              const addCategoryData = {
                reqData: {
                  ...payload,
                }
              }
    
              createCategory({ variables: addCategoryData }).then(({ data }) => {
                if (data) {
                  if (data.addCategory.result === ErrorCode.SUCCESS) {
                    useToast(ErrorCode.SUCCESS, 'Rule-category is created successfully.');
                    onCreateSuccess();
                  } else {
                    console.log(data.addCategory.result);
                    useToast(ErrorCode.UNKNOWN, 'Create rule-category failed.');
                  }
                } else {
                  useToast(ErrorCode.UNKNOWN, 'Create rule-category failed.');
                }
              });
            }}
          >
            Create
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default CreateCategoryModal;
