import './style.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import Table from 'components/v2/dataDisplay/Table';
import lazyGetAwsListEntitiesForPolicy, {
  IAwsListEntitiesForPolicyVariables,
} from 'graphql/queries/getAwsListEntitiesForPolicy';
import lazyGetAwsUser, { IAwsUserVariables } from 'graphql/queries/getAwsUser';
import lazyGetAwsRole, { IAwsRoleVariables } from 'graphql/queries/getAwsRole';
import { AwsGetRoleResponse } from 'graphql/types/AwsGetRoleResponse';
import { randomString } from 'utils/Common';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';

const EntitiesAttached = (props: any) => {
  const { data = {}, cloudId, region } = props;

  const { arn = '' } = data;

  const [isLoading, setIsLoading] = useState(false);
  const [mainTblTotalPolicy, setMainTblTotalPolicy] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblTotalBoundary, setMainTblTotalBoundary] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRowsPolicy, setMainTblRowsPolicy] = useState<RowType[]>([]);
  const [mainTblRowsBoundary, setMainTblRowsBoundary] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [getAwsListEntitiesForPolicy] = lazyGetAwsListEntitiesForPolicy();
  const [getAwsUser] = lazyGetAwsUser();
  const [getAwsRole] = lazyGetAwsRole();

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Entity name',
        field: 'entityName',
        sort: true,
      },
      {
        label: 'Entity type',
        field: 'entityType',
        sort: true,
      },
    ];
  }, []);

  const handleGetAwsListEntitiesForPolicy = (policyArn: string) => {
    const requestVariable: IAwsListEntitiesForPolicyVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        policyArn,
      },
    };
    return getAwsListEntitiesForPolicy({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsUser = (userName: string) => {
    const requestVariable: IAwsUserVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        userName,
      },
    };
    return getAwsUser({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsRole = (roleName: string) => {
    const requestVariable: IAwsRoleVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        roleName,
      },
    };
    return getAwsRole({ variables: variableCombineNextToken(requestVariable) });
  };

  const fetchData = useCallback(async () => {
    try {
      if (!arn) return;

      setIsLoading(true);
      const result = await handleGetAwsListEntitiesForPolicy(arn);

      if (!result?.data?.getAwsListEntitiesForPolicy?.data?.length) {
        setIsLoading(false);
        return;
      }

      const totalResultPolicy: RowType[] = [];
      const totalResultBoundary: RowType[] = [];

      for (let index = 0; index < result.data.getAwsListEntitiesForPolicy.data.length; index++) {
        const e = result.data.getAwsListEntitiesForPolicy.data[index];

        let awsRole: any;
        let awsUser: any;

        if (e.policyRoles?.length) {
          awsRole = await handleGetAwsRole(e.policyRoles[0].roleName);
        }

        if (e.policyUsers?.length) {
          const awsUser = await handleGetAwsUser(e.policyUsers[0].userName);
          awsUser?.data?.getAwsUser?.data?.[0]?.user?.permissionsBoundary;
        }

        const itemPolicy = {
          id: randomString(),
          entityName: e.policyRoles?.[0]?.roleName || e.policyUsers?.[0]?.userName || e.policyGroups?.[0]?.groupName,
          entityType: e.policyRoles?.[0]?.roleName
            ? 'Roles'
            : e.policyUsers?.[0]?.userName
            ? ' IAM Users'
            : e.policyGroups?.[0]?.groupName
            ? 'User groups'
            : '',
        };

        totalResultPolicy.push(itemPolicy);

        if (
          awsRole?.data?.getAwsRole?.data?.[0]?.role?.permissionsBoundary ===
          awsUser?.data?.getAwsUser?.data?.[0]?.user?.permissionsBoundary
        ) {
          const itemPolicy = {
            id: randomString(),
            entityName: e.policyRoles?.[0]?.roleName || e.policyUsers?.[0]?.userName,
            entityType: e.policyRoles?.[0]?.roleName ? 'Roles' : e.policyUsers?.[0]?.userName ? ' IAM Users' : '',
          };

          totalResultBoundary.push(itemPolicy);
        }
      }

      setMainTblRowsPolicy(totalResultPolicy);
      setMainTblTotalPolicy({
        totalPage: Math.ceil(totalResultPolicy.length / tablePagination.itemPerPage),
        totalElement: totalResultPolicy.length,
      });

      setMainTblRowsBoundary(totalResultPolicy);
      setMainTblTotalBoundary({
        totalPage: Math.ceil(totalResultPolicy.length / tablePagination.itemPerPage),
        totalElement: totalResultPolicy.length,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [tablePagination, data, cloudId, region, mainTblRowsPolicy, mainTblRowsBoundary]);

  const mainRowsCurrentPagePolicy = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblRowsPolicy, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [data, mainTblRowsPolicy, tablePagination]);

  const mainRowsCurrentPageBoundary = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblRowsBoundary, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [data, mainTblRowsBoundary, tablePagination]);

  useEffect(() => {
    fetchData();
  }, [data]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <>
      <div className="detail-info">
        <div className="detail-info-title">
          <p>Attached as a permissions policy</p>

          <p>To grant permissions to an entity, attac a permissions policy to it.</p>
        </div>
        {isLoading ? (
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        ) : (
          <div className="detail-info-content">
            {!mainRowsCurrentPagePolicy.length && !isLoading ? (
              <div className="data-grid-wrap">
                <p className="empty-row">Empty</p>
              </div>
            ) : (
              <div className="data-grid-wrap">
                <Table
                  rows={mainRowsCurrentPagePolicy}
                  columns={columns}
                  sortOption={{
                    target: tablePagination.target,
                    direction: tablePagination.direction,
                    onChangeSort: (target: string, dir: OrderDirection) => {
                      updateTablePagination('target', target);
                      updateTablePagination('direction', dir);
                    },
                  }}
                  isLoading={isLoading}
                  horizontalScrollable={true}
                />

                {mainRowsCurrentPagePolicy?.length && !isLoading ? (
                  <div className="fleet-instance pagination-wrapper flex a-center">
                    <p className="flex a-center">
                      Total <span>{mainTblTotalPolicy.totalElement}</span>
                    </p>

                    <TableManagePagination
                      ableFetchMore={false}
                      currentPage={tablePagination.currentPage}
                      updateCurrentPage={page => updateTablePagination('currentPage', page)}
                      totalPage={mainTblTotalPolicy.totalPage}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="detail-info">
        <div className="detail-info-title">
          <p>Atttached as a permissions boundary</p>

          <p>
            Use this policy as a permissions boundary to control the maximum permissions that an entity can have. This
            is an advanced feature used to delegate permissions management to others.
          </p>
        </div>
        {isLoading ? (
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        ) : (
          <div className="detail-info-content">
            {!mainRowsCurrentPageBoundary.length && !isLoading ? (
              <div className="data-grid-wrap">
                <p className="empty-row">Policy not set asa permissions boundary for any entity.</p>
              </div>
            ) : (
              <div className="data-grid-wrap">
                <Table
                  rows={mainRowsCurrentPageBoundary}
                  columns={columns}
                  sortOption={{
                    target: tablePagination.target,
                    direction: tablePagination.direction,
                    onChangeSort: (target: string, dir: OrderDirection) => {
                      updateTablePagination('target', target);
                      updateTablePagination('direction', dir);
                    },
                  }}
                  isLoading={isLoading}
                  horizontalScrollable={true}
                />

                {mainRowsCurrentPageBoundary?.length && !isLoading ? (
                  <div className="fleet-instance pagination-wrapper flex a-center">
                    <p className="flex a-center">
                      Total <span>{mainTblTotalBoundary.totalElement}</span>
                    </p>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default EntitiesAttached;
