import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import InputAtom from 'components/v2/atoms/InputAtom';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { FilterPropsType } from 'pages/v2/Organ/Management/EC2/components/Image/types';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { UserPropsType } from '../../types';
import { getFormatedDateWithTimezone, handleFormatText } from 'utils/Common';
import lazyGetAwsListUsers, { IAwsListUsersVariables } from 'graphql/queries/getAwsListUsers';
import lazyGetAwsListGroupsForUser, { IAwsListGroupsForUserVariables } from 'graphql/queries/getAwsListGroupsForUser';
import lazyGetAwsListMFADevices, { IAwsListMFADevicesVariables } from 'graphql/queries/getAwsListMFADevices';
import lazyGetAwsLoginProfile, { IAwsLoginProfileVariables } from 'graphql/queries/getAwsLoginProfile';
import { ErrorCode } from '@Types/error';
import lazyGetAwsListAccesskeys, { IAwsListAccesskeysVariables } from 'graphql/queries/getAwsListAccesskeys';
import lazyGetAwsAccessKeyLastUsed, { IAwsAccessKeyLastUsedVariables } from 'graphql/queries/getAwsAccessKeyLastUsed';
import lazyGetAwsListSigningCertificates, { IAwsListSigningCertificatesVariables } from 'graphql/queries/getAwsListSigningCertificates';

const User = (props: UserPropsType) => {
  const { cloudId, region, setDataItem, setIsDetailPage } = props;

  const [getAwsListUsers] = lazyGetAwsListUsers();
  const [getAwsListGroupsForUser] = lazyGetAwsListGroupsForUser();
  const [getAwsListMFADevices] = lazyGetAwsListMFADevices();
  const [getAwsListAccesskeys] = lazyGetAwsListAccesskeys();
  const [getAwsAccessKeyLastUsed] = lazyGetAwsAccessKeyLastUsed();
  const [getAwsLoginProfile] = lazyGetAwsLoginProfile();
  const [getAwsListSigningCertificates] = lazyGetAwsListSigningCertificates();

  const [isLoading, setIsLoading] = useState(false);
  const [nextToken, setNextToken] = useState<string>('');
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [searchValue, setSearchValue] = useState<FilterPropsType>({
    name: '',
    value: '',
  });
  const [scopeNFilterVariable, setScopeNFilterVariable] = useState<FilterPropsType>();

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'User name',
        field: 'userName',
        sort: true,
        renderCell: (row: RowType) => (
          <span
            className="link"
            onClick={e => {
              e.stopPropagation();
              setDataItem(row)
              setIsDetailPage();
            }}
          >
            {handleFormatText(row?.userName)}
          </span>
        ),
      },
      {
        label: 'Path',
        field: 'path',
        sort: true,
      },
      {
        label: 'Groups',
        field: 'groups',
        sort: true,
      },
      {
        label: 'Last activity',
        field: 'lastActivity',
        sort: true,
      },
      {
        label: 'MFA',
        field: 'mfa',
        sort: true,
      },
      {
        label: 'Password age',
        field: 'passwordAge',
        sort: true,
      },
      {
        label: 'Console last sign-in',
        field: 'consoleLastSignIn',
        sort: true,
      },
      {
        label: 'Access key ID',
        field: 'accessKeyId',
        sort: true,
      },
      {
        label: 'Active key age',
        field: 'activeKeyAge',
        sort: true,
      },
      {
        label: 'Access key last used',
        field: 'accessKeyLastUsed',
        sort: true,
      },
      {
        label: 'ARN',
        field: 'arn',
        sort: true,
      },
      {
        label: 'Creation time',
        field: 'createTime',
        sort: true,
      },
      {
        label: 'Console access',
        field: 'consoleAccess',
        sort: true,
      },
      {
        label: 'Signing certs',
        field: 'signingCerts',
        sort: true,
      },
    ];
  }, [mainTblRows]);

  const handleGetAwsListGroupsForUser = (userName: string) => {
    const requestVariable: IAwsListGroupsForUserVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        userName,
      },
    };

    const combinedVariable = variableCombineNextToken(requestVariable, nextToken);
    return getAwsListGroupsForUser({ variables: combinedVariable });
  };

  const handleGetAwsListMFADevices = (userName: string) => {
    const requestVariable: IAwsListMFADevicesVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        userName,
      },
    };

    const combinedVariable = variableCombineNextToken(requestVariable, nextToken);
    return getAwsListMFADevices({ variables: combinedVariable });
  };

  const handleGetAwsListAccesskeys = (userName: string) => {
    const requestVariable: IAwsListAccesskeysVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        userName,
      },
    };

    const combinedVariable = variableCombineNextToken(requestVariable, nextToken);
    return getAwsListAccesskeys({ variables: combinedVariable });
  };

  const handleGetAwsAccessKeyLastUsed = (accessKeyId: string) => {
    const requestVariable: IAwsAccessKeyLastUsedVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        accessKeyId,
      },
    };

    const combinedVariable = variableCombineNextToken(requestVariable, nextToken);
    return getAwsAccessKeyLastUsed({ variables: combinedVariable });
  };

  const handleGetAwsLoginProfile = (userName: string) => {
    const requestVariable: IAwsLoginProfileVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        userName,
      },
    };

    const combinedVariable = variableCombineNextToken(requestVariable, nextToken);
    return getAwsLoginProfile({ variables: combinedVariable });
  };

  const handleGetAwsListSigningCertificates = (userName: string) => {
    const requestVariable: IAwsListSigningCertificatesVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        userName,
      },
    };

    const combinedVariable = variableCombineNextToken(requestVariable, nextToken);
    return getAwsListSigningCertificates({ variables: combinedVariable });
  };

  const getBetweenTwoDate = (value: any) => {
    if (!value) return '';

    const currentDate = new Date();
    const prevTime = new Date(value);

    // @ts-ignore
    const diffMs = currentDate - prevTime;
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
    const diffMinutes = Math.floor(diffMs / (1000 * 60 * 60 * 24) / (1000 * 60));

    if (diffDays === 0) {
      if (diffHours > 0) {
        return `${diffHours} hours ago`;
      } else if (diffMinutes > 0) {
        return `${diffMinutes} minutes ago`;
      } else {
        return 'Just now';
      }
    } else if (diffDays === 1) {
      return 'Yesterday';
    } else {
      return `${diffDays} days ago`;
    }
  };

  const handleGetMFA = (mfa: string) => {
    if (!mfa) return '';

    if (mfa.includes('arn:aws:iam::')) return 'Virtual';

    if (mfa.includes('GAHT')) return 'Hardware';

    if (mfa.includes('u2f')) return 'U2F';

    return '';
  };

  const fetchUserGroups = useCallback(
    async (nextToken?: string, isInitial?: boolean) => {
      try {
        setIsLoading(true);
        const requestVariable: IAwsListUsersVariables = {
          cloudId: cloudId,
          region: region,
          request: {
            maxItems: mainTablePagination.limit,
          },
        };

        const combinedVariable = variableCombineNextToken(requestVariable, nextToken);
        const { data }: any = await getAwsListUsers({ variables: combinedVariable });

        if (data?.getAwsListUsers?.result !== ErrorCode.SUCCESS) {
          setIsLoading(false);
          return;
        }

        setNextToken(data?.getAwsListUsers?.nextToken || '');

        const rows = data?.getAwsListUsers?.data?.[0]?.users?.length
          ? await Promise.all(
              data.getAwsListUsers.data[0].users.map(async (e: any) => {
                const [listGroupsForUser, listMFADevices, loginProfile, listAccesskeys, listSigningCertificates] = await Promise.all([
                  handleGetAwsListGroupsForUser(e.userName),
                  handleGetAwsListMFADevices(e.userName),
                  handleGetAwsLoginProfile(e.userName),
                  handleGetAwsListAccesskeys(e.userName),
                  handleGetAwsListSigningCertificates(e.userName),
                ]);

                let accessKeyLastUsed = '';

                if (listAccesskeys?.data?.getAwsListAccesskeys?.data?.[0]?.accessKeyMetadata?.length) {
                  const result = await handleGetAwsAccessKeyLastUsed(
                    listAccesskeys?.data?.getAwsListAccesskeys?.data?.[0]?.accessKeyMetadata?.[0]?.accessKeyId,
                  );

                  accessKeyLastUsed = getBetweenTwoDate(
                    result?.data?.getAwsAccessKeyLastUsed?.data?.[0]?.accessKeyLastUsed?.lastUsedDate,
                  );
                }
                
                return {
                  ...e,
                  id: e.userId,
                  groups: listGroupsForUser?.data?.getAwsListGroupsForUser?.data?.[0]?.groups?.length || '',
                  path: e.path || '...',
                  lastActivity: getBetweenTwoDate(e.passwordLastUsed),
                  mfa: handleGetMFA(
                    listMFADevices?.data?.getAwsListMFADevices?.data?.[0]?.mfaDevices?.[0]?.serialNumber,
                  ),
                  passwordAge: '',
                  consoleLastSignIn: getFormatedDateWithTimezone(e.passwordLastUsed),
                  accessKeyId: listAccesskeys?.data?.getAwsListAccesskeys?.data?.[0]?.accessKeyMetadata
                    ?.map(e => `${e.status} - ${e.accessKeyId}`)
                    ?.join(', '),
                  activeKeyAge: getBetweenTwoDate(
                    listAccesskeys?.data?.getAwsListAccesskeys?.data?.[0]?.accessKeyMetadata?.[0]?.createDate,
                  ),
                  accessKeyLastUsed,
                  createTime: getBetweenTwoDate(e.createDate),
                  consoleAccess: loginProfile?.data?.getAwsLoginProfile?.data ? 'Enabled' : 'Disabled',
                  signingCerts: listSigningCertificates?.data?.getAwsListSigningCertificates?.data?.[0]?.certificates?.[0]?.status,
                };
              }),
            )
          : [];

        let totalUser: RowType[] = [];
        if (isInitial) {
          totalUser = rows;
        } else {
          totalUser = [...mainTblRows, ...rows];
        }

        setMainTblRows(totalUser);
        setMainTblTotal({
          totalPage: Math.ceil(totalUser.length / mainTablePagination.itemPerPage),
          totalElement: totalUser.length,
        });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    },
    [mainTablePagination, cloudId, region, mainTblRows],
  );

  const mainRowsCurrentPage = useMemo((): RowType[] => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!nextToken) {
      fetchUserGroups(nextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    let totalRows = mainTblRows;
    totalRows = mainTblRows.filter(row => {
      return row?.userName.includes(scopeNFilterVariable?.value || '');
    });

    setMainTblTotal({
      totalPage: Math.ceil(totalRows.length / mainTablePagination.itemPerPage),
      totalElement: totalRows.length,
    });

    return orderAlphabetical(totalRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, scopeNFilterVariable, mainTablePagination, nextToken]);

  useEffect(() => {
    fetchUserGroups('', true);
  }, [cloudId]);

  const handleSearch = useCallback(() => {
    updateTablePagination('currentPage', 1);

    if (searchValue?.value) {
      setScopeNFilterVariable(searchValue);
      return;
    }

    setScopeNFilterVariable(undefined);
  }, [searchValue]);

  const handleSelectItem = useCallback(
    (id: string) => {
      const rowSelected = mainTblRows.find(row => row.id === id);

      if (!rowSelected) return;

      setDataItem(rowSelected)
    },
    [mainTblRows],
  );

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>Users</p>

          <span className="title-description">
            An IAM user is an identity with long-term credentials that is used to interact with AWS in an account.
          </span>
        </div>

        <div className="flex action a-center">
          <InputAtom
            placeholder="Find users by name"
            type={'text'}
            value={searchValue.value}
            defaultValue={''}
            onChangeValue={e => setSearchValue(prev => ({ name: prev?.name, value: e }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={handleSearch}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSearch();
              }
            }}
          />

          {/* <button className="action-btn" onClick={handleSearch}>
            Search
          </button> */}
        </div>
      </div>

      {!mainRowsCurrentPage.length && !isLoading ? (
        <div className="data-grid-wrap">
          <p className="empty-row">Empty</p>
        </div>
      ) : (
        <div className="data-grid-wrap">
          <Table
            rows={mainRowsCurrentPage}
            columns={columns}
            reportCheckedList={() => {}}
            reportSelected={id => handleSelectItem(id)}
            sortOption={{
              target: mainTablePagination.target,
              direction: mainTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateTablePagination('target', target);
                updateTablePagination('direction', dir);
              },
            }}
            isLoading={isLoading}
            horizontalScrollable={true}
          />

          {mainRowsCurrentPage && mainRowsCurrentPage.length > 0 && !isLoading && (
            <div className="pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{mainTblTotal.totalElement}</span>
              </p>
              <TableManagePagination
                ableFetchMore={!!nextToken}
                currentPage={mainTablePagination.currentPage}
                updateCurrentPage={page =>
                  setMainTablePagination(prev => ({
                    ...prev,
                    ['currentPage']: page,
                  }))
                }
                totalPage={mainTblTotal.totalPage}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default User;
