import { useCallback, useEffect, useMemo, useState } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconReloadButton from 'assets/svgs/v3/ico_button_reload.svg';
import IconEnableLog from 'assets/svgs/v3/ico_enable_logging.svg';
import Icon from 'components/v2/atoms/Icon';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import AddFilterLogs from '../AddFilterLogs';
import InputAtom from 'components/v2/atoms/InputAtom';
import { CLOUDFRONT_OPTION, CLOUDFRONT_REGION, LOGGING_DESTINATION_DROPDOWN, WafScopeEnum } from '../Commons/Constant';
import './index.scss';
import lazyGetAwsDescribeLogGroups, {
  IGetAwsDescribeLogGroupListVariables,
} from 'graphql/queries/getAwsDescribeLogGroups';
import lazyGetAwsDeliveryStreams, { IGetAwsDeliveryStreamListVariables } from 'graphql/queries/getAwsDeliveryStreams';

interface IEnableLoggingModalProps extends IBaseModalProps {
  header: string;
  cloudId: number;
  region: string;
  onChangeValue?: () => void;
}

export interface redactedHeaderRowData {
  index: number;
  header: string;
}

const EnableLoggingModal = ({
  header,
  cloudId,
  region,
  onChangeValue,
  ...baseModalProps
}: IEnableLoggingModalProps) => {
  const [rows, setRows] = useState<Array<string>>(['']);
  const [destinationValue, setDestinationValue] = useState<string>(LOGGING_DESTINATION_DROPDOWN[0].value);
  const [redactedValues, setRedactedValues] = useState<string[]>([]);
  const [isShowReactedHeader, setIsShowReactedHeader] = useState<boolean>(false);
  const [redactedHeaderRows, setRedactedHeaderRows] = useState<redactedHeaderRowData[]>([]);

  const [loggingDestinationDropdown, setloggingDestinationDropdown] = useState<DropdownListDataType[]>([]);

  // API
  // getAwsDescribeLogGroups
  const [getAwsDescribeLogGroups, { loading: describeLogGroupLoading }] = lazyGetAwsDescribeLogGroups();
  // getAwsListDeliveryStreams
  const [getAwsDeliveryStreams, { loading: describeDeliveryStreamLoading }] = lazyGetAwsDeliveryStreams();
  // getAwsListBuckets
  // TODO: waiting for BE update API - getAwsListBuckets

  // const handleCreateAmazon = () => {
  //   setRows((prevState) => ([
  //     ...prevState,
  //     ''
  //   ]))
  // };

  const ldDesTitle = useMemo((): string => {
    if (destinationValue === LOGGING_DESTINATION_DROPDOWN[0].value) {
      return 'Amazon CloudWatch Logs log group';
    }
    if (destinationValue === LOGGING_DESTINATION_DROPDOWN[1].value) {
      return 'Amazon Data Firehose stream';
    }
    if (destinationValue === LOGGING_DESTINATION_DROPDOWN[2].value) {
      return 'Amazon S3 bucket';
    }
    return '';
  }, [destinationValue]);

  const ldDesSubTitle = useMemo((): string => {
    if (destinationValue === LOGGING_DESTINATION_DROPDOWN[0].value) {
      return "Select a log group in your account that begins with 'aws-waf-logs-' or create one in the Amazon CloudWatch console. You must use a log group that's associated with your account.";
    }
    if (destinationValue === LOGGING_DESTINATION_DROPDOWN[1].value) {
      return "Select a Firehose stream in your account that begins with 'aws-waf-logs-' or create one in the Amazon Data Firehose console. You must use a Firehose stream that's associated with your account.";
    }
    if (destinationValue === LOGGING_DESTINATION_DROPDOWN[2].value) {
      return "Select a S3 bucket in your account that begins with 'aws-waf-logs-' or create one in the Amazon Simple Storage Service (S3) console. You must use a S3 bucket that's associated with your account.";
    }
    return '';
  }, [destinationValue]);

  const ldDropdownPlaceHolder = useMemo((): string => {
    if (destinationValue === LOGGING_DESTINATION_DROPDOWN[0].value) {
      return 'Select a log group';
    }
    if (destinationValue === LOGGING_DESTINATION_DROPDOWN[1].value) {
      return 'Select a Firehose stream';
    }
    if (destinationValue === LOGGING_DESTINATION_DROPDOWN[2].value) {
      return 'Select a S3 bucket';
    }
    return '';
  }, [destinationValue]);

  const getLoggingGroupData = useCallback(() => {
    const requestVaribale: IGetAwsDescribeLogGroupListVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        logGroupNamePrefix: 'aws-waf-log',
      },
    };
    getAwsDescribeLogGroups({ variables: requestVaribale }).then(({ data: responseData }) => {
      const awsDescribeLogGroups = responseData?.getAwsDescribeLogGroups?.data?.[0]?.logGroups;
      const dropdownData: DropdownListDataType[] = [];
      if (awsDescribeLogGroups) {
        awsDescribeLogGroups.map((logGroup, index) => {
          dropdownData.push({
            id: index,
            description: logGroup.logGroupName,
            value: logGroup.logGroupName,
          });
        });
      }
      setloggingDestinationDropdown(dropdownData);
    });
  }, [cloudId, region]);

  const getAmazonFirehoseStreams = useCallback(() => {
    const requestVaribale: IGetAwsDeliveryStreamListVariables = {
      cloudId: cloudId,
      region: region === CLOUDFRONT_OPTION.value ? CLOUDFRONT_REGION : region,
      request: {
        limit: 100,
      },
    };
    getAwsDeliveryStreams({ variables: requestVaribale }).then(({ data: responseData }) => {
      const awsDeliveryStreams = responseData?.getAwsListDeliveryStreams?.data?.[0]?.deliveryStreamNames;
      const dropdownData: DropdownListDataType[] = [];
      if (awsDeliveryStreams) {
        awsDeliveryStreams.map((stream, index) => {
          dropdownData.push({
            id: index,
            description: stream,
            value: stream,
          });
        });
      }
      setloggingDestinationDropdown(dropdownData);
    });
  }, [cloudId, region]);

  const getS3Buckets = useCallback(() => {}, []);

  const isFetching = useMemo(() => {
    return describeDeliveryStreamLoading || describeLogGroupLoading; // TODO: || s3 bucket loading
  }, [describeLogGroupLoading, describeDeliveryStreamLoading]);

  const refetchLoggingDestination = useCallback(() => {
    switch (destinationValue) {
      case LOGGING_DESTINATION_DROPDOWN[0].value:
        getLoggingGroupData();
        break;
      case LOGGING_DESTINATION_DROPDOWN[1].value:
        getAmazonFirehoseStreams();
        break;
      case LOGGING_DESTINATION_DROPDOWN[2].value:
        getS3Buckets();
        break;
    }
  }, [destinationValue]);

  const handleChange = (index: number, value: string) => {
    const updatedRows = rows.map((row, i) => {
      if (i === index) {
        return value;
      }

      return row;
    });

    setRows(updatedRows);
  };

  const handleClickCheckbox = (value: string) => {
    if (redactedValues.find(val => val === value)) {
      if (value === 'single') setIsShowReactedHeader(false);
      setRedactedValues(() => {
        const newData = redactedValues.filter((val, index) => val != value);
        return newData;
      });
    } else {
      if (value === 'single') setIsShowReactedHeader(true);
      setRedactedValues(prevState => {
        const newData = prevState.concat(value);
        return newData;
      });
    }
  };

  const handleCreatRedactedHeader = () => {
    setRedactedHeaderRows(prevState => {
      const newState = prevState.concat({ index: redactedHeaderRows.length, header: '' });
      return newState;
    });
  };

  const handleDeleteRedactedHeader = (indexToRemove: number) => {
    const newData = redactedHeaderRows.filter((_, index) => index != indexToRemove);
    setRedactedHeaderRows(newData);
  };

  const handleChangeRedactedHeader = (index: number, value: string, propName: keyof redactedHeaderRowData) => {
    const newRows = redactedHeaderRows.map((row, i) => (i === index ? { ...row, [propName]: value } : row));
    setRedactedHeaderRows(newRows);
  };

  useEffect(() => {
    setRows(['']);
    getLoggingGroupData();
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEnableLog} /> {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="enable-logging-modal">
        <div className="enable-logging-content">
          <div className="row-1">
            <div className="header">
              <div className="text-title">Logging destination</div>
              <div className="text-description">Select a destination for your web ACL traffic logs.</div>
            </div>
            <div className="radio-content">
              <RadioAtom
                label={LOGGING_DESTINATION_DROPDOWN[0].label}
                value={LOGGING_DESTINATION_DROPDOWN[0].value}
                name={'logging-destination'}
                checked={destinationValue}
                onChange={() => {
                  getLoggingGroupData();
                  setDestinationValue(LOGGING_DESTINATION_DROPDOWN[0].value);
                }}
              />

              <RadioAtom
                label={LOGGING_DESTINATION_DROPDOWN[1].label}
                value={LOGGING_DESTINATION_DROPDOWN[1].value}
                name={'logging-destination'}
                checked={destinationValue}
                onChange={() => {
                  getAmazonFirehoseStreams();
                  setDestinationValue(LOGGING_DESTINATION_DROPDOWN[1].value);
                }}
              />

              <RadioAtom
                label={LOGGING_DESTINATION_DROPDOWN[2].label}
                value={LOGGING_DESTINATION_DROPDOWN[2].value}
                name={'logging-destination'}
                checked={destinationValue}
                onChange={() => {
                  getS3Buckets();
                  setDestinationValue(LOGGING_DESTINATION_DROPDOWN[2].value);
                }}
              />
            </div>
          </div>
          <div className="row-2">
            <div className="header">
              <div className="text-title">{ldDesTitle}</div>
              <div className="text-description">{ldDesSubTitle}</div>
            </div>
            <div className="dropdown-container">
              {rows.map((row, index) => (
                <div className="amazon-cloudwatch-log" key={`amazon-cloudwatch-log` + index}>
                  <div className="dropdown-enable-logging">
                    <DropdownAtom
                      id={'enable-logging' + `${index}`}
                      data={loggingDestinationDropdown}
                      placeholder={ldDropdownPlaceHolder}
                      value={{
                        name: loggingDestinationDropdown?.find(item => item.value === row)?.name,
                        value: row,
                      }}
                      disabled={isFetching}
                      handleClick={val => handleChange(index, val.value as string)}
                    />
                  </div>
                  <div className="btn-group">
                    <Icon
                      width={40}
                      height={40}
                      src={IconReloadButton}
                      className="reload-btn"
                      onClick={refetchLoggingDestination}
                    />
                    <button className="btn add-btn" onClick={() => {}}>
                      Create new
                    </button>
                  </div>
                </div>
              ))}
              <div></div>
            </div>
          </div>
          <div className="row-3">
            <div className="header">
              <div className="text-title">Redacted fields</div>
              <div className="text-description">Select the data fields that you want to omit from the lgos</div>
            </div>

            <div className="redacted-fields">
              <div className="text-note">Redacted fields</div>
              <div className="checkbox-container">
                <label className="checkbox-labeled">
                  <CheckboxAtom
                    checked={!!redactedValues.find(val => val === 'http')}
                    onchange={() => handleClickCheckbox('http')}
                  />
                  HTTP method
                </label>
                <label className="checkbox-labeled">
                  <CheckboxAtom
                    checked={!!redactedValues.find(val => val === 'query')}
                    onchange={() => handleClickCheckbox('query')}
                  />
                  Query string
                </label>
                <label className="checkbox-labeled">
                  <CheckboxAtom
                    checked={!!redactedValues.find(val => val === 'uri')}
                    onchange={() => handleClickCheckbox('uri')}
                  />
                  URI path
                </label>
                <label className="checkbox-labeled">
                  <CheckboxAtom
                    checked={!!redactedValues.find(val => val === 'single')}
                    onchange={() => handleClickCheckbox('single')}
                  />
                  Single header
                </label>
              </div>

              {isShowReactedHeader && (
                <div className="single-header-container">
                  <div className="header">
                    <div className="text-title">Redacted headers</div>
                    <div className="text-description">Specify the headers you want to redact form the logs.</div>
                  </div>

                  {redactedHeaderRows && (
                    <>
                      {redactedHeaderRows.map((row, index) => (
                        <div
                          className="redacted-header-row-container"
                          key={`udpate-single-header-table-new-row-${index}`}
                        >
                          <div className="redacted-header-row-input">
                            <div className="column">
                              <InputAtom
                                type={'text'}
                                placeholder={'Enter header'}
                                value={row.header}
                                onChangeValue={(value: string) => handleChangeRedactedHeader(index, value, 'header')}
                                defaultValue={''}
                                noClear={true}
                                hasPrefixIcon={true}
                              />
                            </div>
                          </div>
                          <div className="redacted-header-row-btn">
                            <button className="btn" onClick={() => handleDeleteRedactedHeader(index)}>
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </>
                  )}

                  <div className="add-new-header-container">
                    <button className="add-btn btn" onClick={handleCreatRedactedHeader}>
                      Add header
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row-4">
            <div className="header">
              <div className="text-title">Filter logs</div>
              <div className="text-description">
                Add filters to control with web requests are logged. If you add multiple filters, AWS WAF evaluates them
                starting from the top.
              </div>
            </div>
            <div className="filter-log">
              <AddFilterLogs dataDropdown={[]} columns={['Condition type', 'Condition value']} />
            </div>
          </div>
        </div>
        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="save-btn" onClick={() => {}}>
            Save changes
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EnableLoggingModal;
