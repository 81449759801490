import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { NetworkAclListPropsType } from '../types';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { NETWORK_ACL_FILTER_DROPDOWN } from './configs';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import InputAtom from 'components/v2/atoms/InputAtom';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { orderAlphabetical } from '../../../Utils/Sorting';
import { handleLinkClicked, variableCombineNextToken } from '../../../Utils';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdTabLayout/types';
import { FilterPropsType } from '../../../EC2/components/Image/types';
import { handleFormatText } from 'utils/Common';
import lazyGetAllAwsNetworkAcls, { IAwsNetworkAclsPageVariables } from 'graphql/queries/getAwsAllNetworkAcls';
import MgdTooltip from 'components/v2/MgdTooltip';
import { AwsNetworkAclType } from 'graphql/types/AwsNetworkAcl';
import DeleteNetworkModal from '../DeleteNetworkModal';
import deleteNetworkACLMutation, { IDeleteNetworkACLVariables } from 'graphql/mutations/deleteNetworkAcl';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import { ManagementTypeEnum } from '../../..';
import { NETWORK_TAB_LIST } from '../../configs';
import { subnetFilterDropdown, vpcFilterDropdown } from '../../Constants';
import { useNavigate } from 'react-router-dom';

const NetworlAclList = (props: NetworkAclListPropsType) => {
  const { cloudId, region, tabInformation, onNetworkAclSelected, onNetworkAclIdClicked, currentRouteState, setCurrentRouteState, onNavigateCreation } = props;
  
  const navigate = useNavigate()
  
  // API
  const [getAllAwsNetworkAcls, { loading: awsNetworkAlcLoading }] = lazyGetAllAwsNetworkAcls();
  const [deleteNetworkACL] = deleteNetworkACLMutation();

  // State
  const [mainTblTotalRows, setMainTblTotalRows] = useState<RowType[]>([]);
  const [mainNextToken, setMainNextToken] = useState<string>('');
  const [networkAclsSelected, setNetworkAclsSelected] = useState<AwsNetworkAclType[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const currentFilter = currentRouteState && NETWORK_ACL_FILTER_DROPDOWN.find(e => e.value === currentRouteState.key);
  const [networkAclFilterDropdownValue, setNetworkAclFilterDropdownValue] = useState<DropdownListDataType>(
    currentFilter || NETWORK_ACL_FILTER_DROPDOWN[0],
  );
  const [networkAclFilterInputValue, setNetworkAclFilterInputValue] = useState<FilterPropsType>({
    name: networkAclFilterDropdownValue.value as string,
    value: currentRouteState?.value || '',
  });
  const [networkAclFilter, setNetworkAclFilter] = useState<FilterPropsType | undefined>(currentRouteState?.value ? networkAclFilterInputValue : undefined);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });

  const regionValue = useMemo((): string => {
    return region.value as string;
  }, [region]);

  useEffect(() => {
    return () => {
      if (!currentRouteState) return;

      setCurrentRouteState(null);
    };
  }, [currentRouteState]);

  const mainTblColumns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Name',
        field: 'name',
        sort: true,
        renderCell: (row: RowType) => <>{handleFormatText(row?.name || '-')}</>,
      },
      {
        label: 'Network ACL ID',
        field: 'networkAclId',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <div
                className="link"
                onClick={e => {
                  e.stopPropagation();
                  onNetworkAclIdClicked();
                  onNetworkAclSelected(row as AwsNetworkAclType)
                }}
              >
                {handleFormatText(row?.networkAclId)}
              </div>
            }
          </>
        ),
      },
      {
        label: 'Associated with',
        field: 'associations',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {row?.associations?.length == 1 ? (
              <div
                className="link"
                onClick={e => {
                  handleLinkClicked({
                    navigate,
                    link: '/organ/1/manage/network',
                    type: ManagementTypeEnum.NETWORK,
                    tabId: NETWORK_TAB_LIST[2].id,
                    key: subnetFilterDropdown[0].value.toString(),
                    value: row?.associations?.[0]?.subnetId,
                  });
                }}
              >
                {handleFormatText(row?.associations?.[0]?.subnetId)}
              </div>
            ) : (
              <div id={`my-tooltip-${row?.networkAclId}`}>
                <MgdTooltip id={row?.networkAclId} title={`${row?.associations?.length} subnets`}>
                  {row?.associations?.length > 1 &&
                    row?.associations.map((item: any, index: number) => {
                      return (
                        <div
                          className="link"
                          onClick={e => {
                            handleLinkClicked({
                              navigate,
                              link: '/organ/1/manage/network',
                              type: ManagementTypeEnum.NETWORK,
                              tabId: NETWORK_TAB_LIST[2].id,
                              key: subnetFilterDropdown[0].value.toString(),
                              value: item?.subnetId,
                            });
                          }}
                          key={index}
                        >
                          {handleFormatText(item?.subnetId)}
                        </div>
                      );
                    })}
                </MgdTooltip>
              </div>
            )}
          </>
        ),
      },
      {
        label: 'Default VPC',
        field: 'isDefault',
        renderCell: (row: RowType) => <>{row.isDefault === false ? 'No' : 'Yes'}</>,
        sort: true,
      },
      {
        label: 'VPC',
        field: 'vpcId',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <div
                className="link"
                onClick={e => {
                  handleLinkClicked({
                    navigate,
                    link: '/organ/1/manage/network',
                    type: ManagementTypeEnum.NETWORK,
                    tabId: NETWORK_TAB_LIST[0].id,
                    key: vpcFilterDropdown[0].value.toString(),
                    value: row?.vpcId,
                  });
                }}
              >
                {handleFormatText(row?.vpcId)}
              </div>
            }
          </>
        ),
      },
      {
        label: 'Inbound rules count',
        field: 'inbound',
        sort: true,
      },
      {
        label: 'Outbound rules count',
        field: 'outbound',
        sort: true,
      },
      { label: 'Owner', field: 'ownerId', sort: true },
    ];
  }, []);

  const deleteButtonDisabled = useMemo((): boolean => {
    return networkAclsSelected.length !== 1;
  }, [networkAclsSelected]);

  const handleNetworkAclSelected = useCallback((listId: string[]) => {
    const networkAcls: AwsNetworkAclType[] = [];
    listId.map(rowId => {
      const rowSelected = mainTblTotalRows.find(row => row.id === rowId);
      if (rowSelected) {
        networkAcls.push(rowSelected as AwsNetworkAclType);
      }
    });
    setNetworkAclsSelected(networkAcls);
  }, [mainTblTotalRows]);

  const handleDeleteNetworkAcl = useCallback((networkAcls: AwsNetworkAclType[]) => {
    const networkAclIds = networkAcls.map(network => network.networkAclId);
    const reqVariable: IDeleteNetworkACLVariables = {
      cloudId: cloudId,
      region: regionValue,
      request: {
        networkAclId: networkAclIds
      }
    };
    deleteNetworkACL({variables: reqVariable}).then(({data: reponseData}) => {
      if (reponseData?.deleteAwsNetworkAcl?.result === ErrorCode.SUCCESS) {
        setDeleteModalVisible(false);
        useToast(ErrorCode.SUCCESS, 'Delete network ACLs successful.');
        setNetworkAclsSelected([]);
        onNetworkAclSelected(undefined);
        fetchAwsAllNetworkAcls('', true);
      } else {
        useToast(ErrorCode.UNKNOWN, 'Delete network ACLs failed.');
      }
    });
  }, [cloudId, regionValue]);

  const handleSearchFunction = useCallback(() => {
    if (networkAclFilterInputValue.value !== '') {
      setNetworkAclFilter({ name: networkAclFilterInputValue.name, value: networkAclFilterInputValue.value });
    } else {
      setNetworkAclFilter(undefined);
    }
  }, [networkAclFilterInputValue]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setMainTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      fetchAwsAllNetworkAcls(mainNextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(mainTblTotalRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotalRows, mainTblTotal, mainTablePagination, mainNextToken, networkAclFilterInputValue]);

  const awsNetworkAclRequestVariables = useMemo((): IAwsNetworkAclsPageVariables => {
    if (networkAclFilter) {
      return {
        cloudId: cloudId,
        region: regionValue,
        request: {
          maxResults: mainTablePagination.limit,
          filters: {
            name: networkAclFilter?.name || '',
            values: [networkAclFilter?.value.toLocaleLowerCase() || ''],
          },
        },
      };
    }
    return {
      cloudId: cloudId,
      region: regionValue,
      request: {
        maxResults: mainTablePagination.limit,
      },
    };
  }, [cloudId, regionValue, mainTablePagination, networkAclFilter]);

  const fetchAwsAllNetworkAcls = useCallback(
    (nextToken?: string, isInitial?: boolean) => {
      const combinedVariable = variableCombineNextToken(awsNetworkAclRequestVariables, nextToken);
      getAllAwsNetworkAcls({variables: combinedVariable}).then(({ data: awsNetworkAclData }) => {
        const netowrkAclRows: RowType[] = [];
        const getAwsAllNetworkAcls = awsNetworkAclData?.getAwsAllNetworkAcls;
        if (getAwsAllNetworkAcls) {
          getAwsAllNetworkAcls?.data?.map(networkAcl => {
            netowrkAclRows.push({
              ...networkAcl,
              id: networkAcl.networkAclId,
              name: networkAcl?.tags?.find(t => t?.key == 'Name')?.value,
              inbound: `${networkAcl?.entries?.filter((e: { egress: boolean }) => e?.egress == false)?.length} Inbound rules`,
              outbound: `${networkAcl?.entries?.filter((e: any) => e?.egress == true)?.length} Outbound rules`
            });
          });
          let allAwsNetworkAcls: RowType[] = [];
          if (isInitial) {
            allAwsNetworkAcls = netowrkAclRows;
          } else {
            allAwsNetworkAcls = [...mainTblTotalRows, ...netowrkAclRows];
          }
          setMainTblTotalRows(allAwsNetworkAcls);
          if (allAwsNetworkAcls.length > mainTablePagination.limit) {
            setMainNextToken(getAwsAllNetworkAcls?.nextToken as string);
          } else {
            setMainNextToken('');
          }
          setMainTblTotal({
            totalPage: Math.ceil(allAwsNetworkAcls.length / mainTablePagination.itemPerPage),
            totalElement: allAwsNetworkAcls.length,
          });
        }
      });
    },
    [awsNetworkAclRequestVariables, mainTblTotalRows, mainTblTotal, mainTablePagination],
  );

  const handleMainRowSelected = useCallback(
    (rowId: string) => {
      const rowSelected = mainTblTotalRows.find(row => row.id === rowId);

      if (!rowSelected) return;
      handleNetworkAclSelected([rowId]);
      onNetworkAclSelected(rowSelected as AwsNetworkAclType);
    },
    [mainTblTotalRows],
  );

  // For initial data
  useEffect(() => {
    fetchAwsAllNetworkAcls('', true);
  }, [awsNetworkAclRequestVariables]);

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>{tabInformation.title}</p>
        </div>
        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={NETWORK_ACL_FILTER_DROPDOWN}
            value={networkAclFilterDropdownValue}
            handleClick={val => {
              setNetworkAclFilterDropdownValue(val);
              setNetworkAclFilterInputValue(prev => ({ ...prev, name: val.value as string }));
            }}
          />
          <InputAtom
            type={'text'}
            value={networkAclFilterInputValue.value}
            defaultValue={''}
            onChangeValue={str => setNetworkAclFilterInputValue(prev => ({ name: prev?.name, value: str }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {}}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSearchFunction();
              }
            }}
          />

          <button className="action-btn" onClick={handleSearchFunction}>
            Search
          </button>
          <button className="action-btn" onClick={() => onNavigateCreation()}>
            Create
          </button>
          <button className="action-btn" onClick={() => setDeleteModalVisible(true)} disabled={deleteButtonDisabled}>
            Delete
          </button>
        </div>
      </div>
      <div className="data-grid-wrap">
        <Table
          rows={mainRowsCurrentPage}
          columns={mainTblColumns}
          reportCheckedList={listId => handleNetworkAclSelected(listId)}
          reportSelected={id => handleMainRowSelected(id)}
          sortOption={{
            target: mainTablePagination.target,
            direction: mainTablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {
              updateTablePagination('target', target);
              updateTablePagination('direction', dir);
            },
          }}
          isLoading={awsNetworkAlcLoading}
          horizontalScrollable={true}
        />
        {mainRowsCurrentPage && mainRowsCurrentPage.length > 0 && awsNetworkAlcLoading === false && (
          <div className="pagination-wrapper flex a-center">
            <p className="flex a-center">
              Total <span>{mainTblTotal.totalElement}</span>
            </p>
            <TableManagePagination
              ableFetchMore={!!mainNextToken}
              currentPage={mainTablePagination.currentPage}
              updateCurrentPage={page =>
                setMainTablePagination(prev => ({
                  ...prev,
                  ['currentPage']: page,
                }))
              }
              totalPage={mainTblTotal.totalPage}
            />
          </div>
        )}
      </div>
      {deleteModalVisible &&
        <DeleteNetworkModal
          networkAcls={networkAclsSelected}
          header={'Delete network ACLs'}
          onDelete={(networkAcls) => handleDeleteNetworkAcl(networkAcls)}
          open={deleteModalVisible}
          onClose={() => setDeleteModalVisible(!deleteModalVisible)}
        />
      }
    </Fragment>
  );
};

export default NetworlAclList;
