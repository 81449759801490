import { gql } from '@apollo/client';

const query = gql`
  query getAwsListAccesskeys($request: AwsListAccessKeysRequest, $cloudId: Long, $region: String) {
    getAwsListAccesskeys(listAccessKeysRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListAccessKeysResponse {
          accessKeyMetadata {
            userName
            accessKeyId
            createDate
            status
          }
          isTruncated
          marker
        }
      }
    }
  }
`;
export default query;
