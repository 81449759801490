import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import {
  AwsDescribeManagedProductsByVendorRequestType,
  AwsDescribeManagedProductsByVendorResponseType,
} from 'graphql/types/AwsDescribeManagedProductsByVendor';

export type AwsDescribeManagedProductsByVendorVariablesType = {
  cloudId: number;
  region: string;
  request: AwsDescribeManagedProductsByVendorRequestType;
};

export type AwsDescribeManagedProductsByVendorResponseDataType = {
  getAwsDescribeManagedProductsByVendor: IGqlResponseNewData<AwsDescribeManagedProductsByVendorResponseType>;
};

const lazyAwsDescribeManagedProductsByVendor = () =>
  useLazyQuery<AwsDescribeManagedProductsByVendorResponseDataType, AwsDescribeManagedProductsByVendorVariablesType>(
    query,
    {
      fetchPolicy: 'no-cache',
    },
  );

export default lazyAwsDescribeManagedProductsByVendor;
