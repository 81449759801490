import { useEffect, useState } from 'react';
import './index.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import EmailIcon from 'assets/svgs/v2/ico_email_verify.svg';
import { useMutation } from 'react-query';
import apis from 'apis/v2/index';
import { ErrorCode } from '@Types/error';
import { useAuth } from 'contexts/AuthProvider';
import { useToast } from 'hooks/v2/useToast';
import FooterComponent from 'components/v2/FooterComponent';

/** <>이메일 변경 인증 결과 -> 로그인으로 이동 </> */
const VerifyUpdEmailToken = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const [msg, setMsg] = useState('');

  const authToken = searchParams.get('authToken');
  
  const { mutateAsync: _checkEmailVerifyToken } = useMutation((token: string) => apis.User.updateEmailVerify(token));

  useEffect(() => {
    if (authToken) {
      _checkEmailVerifyToken(authToken).then(({ data }) => {
        if (data.result === ErrorCode.SUCCESS) {
          setMsg('Email verification is complete.');
        } else if (data.result === ErrorCode.EMAIL_TOKEN_ERROR) {
          setMsg('The verification time has already expired. Please proceed with verification again.');
        } else {
          setMsg('This is an unauthorized access.');
        }
      }).catch(() => setMsg('This is an unauthorized access.'));
    } else {
      useToast(ErrorCode.UNKNOWN, 'This is an unauthorized access.');
      navigate('/auth');
    }
  },[]);

  return (
    <>
      <article id="email-verify-article">
        <img src={EmailIcon} width={56} height={56} />
        <h2 dangerouslySetInnerHTML={{ __html: msg }} />
        <button 
          onClick={() => logout()} 
          className="big-main-btn"
        >
          Login
        </button>
      </article>
      <FooterComponent />
    </>
  );
};
export default VerifyUpdEmailToken;