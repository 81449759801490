import { useEffect, useMemo, useState } from 'react';
import { DETAIL_TEXT_DESCRIPTION, NAT_GW_SUMMARY_TAB_LIST, DETAIL_KEYS } from './configs';
import { NatGWSummaryPropsType } from '../types';
import DetailTab from '../DetailTab';
import { TextTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import TagTab from '../../../EC2/components/TagTab';
import { ColumnType, RowType } from '@Types/v2/Table';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdLayout';
import IpV4Address from './Tabs/IpV4Address';
import { handleLinkClicked } from '../../../Utils';
import { useNavigate } from 'react-router-dom';
import { ManagementTypeEnum } from '../../..';
import { elasticIpFilterDropdown, subnetFilterDropdown, vpcFilterDropdown } from '../../Constants';
import { NETWORK_TAB_LIST } from '../../configs';

const NatGWSummary = (props: NatGWSummaryPropsType) => {
  const navigate = useNavigate();

  const { natGW, isSummaryHidden } = props;
  const [tabSelected, setTabSelected] = useState(NAT_GW_SUMMARY_TAB_LIST[0]);

  const natGWId = useMemo((): string => {
    return natGW?.natGatewayId ?? '';
  }, [natGW]);

  const handleGetLink = (key: string, value: string) => {
    switch (key) {
      case DETAIL_KEYS[4]:
        return handleLinkClicked({
          navigate,
          link: '/organ/1/manage/network',
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[5].id,
          key: elasticIpFilterDropdown[0].value.toString(),
          value,
        });

      case DETAIL_KEYS[7]:
        return handleLinkClicked({
          navigate,
          link: '/organ/1/manage/network',
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[0].id,
          key: vpcFilterDropdown[0].value.toString(),
          value,
        });

      case DETAIL_KEYS[8]:
        return handleLinkClicked({
          navigate,
          link: '/organ/1/manage/network',
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[2].id,
          key: subnetFilterDropdown[0].value.toString(),
          value,
        });

      default:
        return null;
    }
  };

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    if (natGW) {
      const detailsData: IMgdDetailKeyValueProps[] = [];
      const detailsDataOrdered: IMgdDetailKeyValueProps[] = [];
      Object.entries(natGW).map(([key, value]) => {
        if (DETAIL_KEYS.indexOf(key) > -1) {
          const textDes = DETAIL_TEXT_DESCRIPTION.find(text => text.id == key);
          let desValue = '-';
          if (typeof value === 'string') {
            desValue = value.toString();
          }
          if (textDes) {
            detailsData.push({
              id: key,
              type: textDes.type as TextTypeEnum,
              title: textDes.value,
              description: desValue,
              handleItemClick: () => handleGetLink(key, desValue),
            });
          }
        }
      });

      DETAIL_KEYS.map(key => {
        const dataByKey = detailsData.find(data => data.id == key);
        if (dataByKey) detailsDataOrdered.push(dataByKey);
      });
      return detailsDataOrdered;
    }
    return [];
  }, [natGW]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const tagRows = useMemo((): RowType[] => {
    return natGW?.tags ?? [];
  }, [natGW]);

  const tabNode = useMemo(() => {
    switch (tabSelected.id) {
      case NAT_GW_SUMMARY_TAB_LIST[0].id:
        return <DetailTab title={'Details'} data={detailTabContentData} isApiLoading={false} />;

      case NAT_GW_SUMMARY_TAB_LIST[1].id:
        return <IpV4Address natGW={natGW} />;

      case NAT_GW_SUMMARY_TAB_LIST[2].id:
        return <TagTab title={'Tags'} rows={tagRows} columns={tagColumns} />;

      default:
        return null;
    }
  }, [tabSelected, natGW, tagRows, tagColumns, detailTabContentData]);

  useEffect(() => {
    setTabSelected(NAT_GW_SUMMARY_TAB_LIST[0]);
  }, [natGW]);

  if (!natGW) {
    return (
      <div className="detail-data">
        <p className="empty-page">Select a item</p>
      </div>
    );
  }

  return (
    <div className="box-summary">
      {!isSummaryHidden && <div className="box-summary-id">{natGWId}</div>}

      <div className="detail-tab flex a-center">
        {NAT_GW_SUMMARY_TAB_LIST.map(tab => {
          return (
            <button
              className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
              key={tab.id}
              data-tab={tab.id}
              onClick={e => setTabSelected(tab)}
            >
              <p>{tab.name}</p>
            </button>
          );
        })}
      </div>

      <div className="content-tab">{tabNode}</div>
    </div>
  );
};

export default NatGWSummary;
