import { MemberProject } from 'graphql/types/MemberProject';
import './index.scss';
import { enumFormatter } from 'utils/Formatter';
import { useNavigate } from 'react-router-dom';
import joinProject from 'graphql/mutations/joinProject';
import { useMutation } from 'react-query';
import { useEffect, useState } from 'react';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import apis from 'apis/v2';
import { useAuth } from 'contexts/AuthProvider';

interface INotiInvitedCardProps {
  val: MemberProject;
}

/** 알림 모달 내 초대 카드 */
const NotiInvitedCard = ({
  val
}:INotiInvitedCardProps) => {
  const { token, updateToken } = useAuth();
  const navigate = useNavigate();

  const [img, setImg] = useState('');

  /* 프로젝트 참여하기 */
  const [joinPj] = joinProject();
  /* 프로젝트 썸네일 이미지 다운로드 */
  const { mutateAsync: _downloadProfileImg } = useMutation((params:{ kind:string; id:number }) => apis.File.getDownloadProfileImage(token, params))

  useEffect(() => {
    const reqProfileData = {
      kind: 'project',
      id: val.projectId
    }

    _downloadProfileImg(reqProfileData).then(({ data }) => {
      if (data) { setImg(data.data[0])}
      else { useToast(ErrorCode.UNKNOWN, '프로젝트 썸네일을 불러오지 못했습니다.'); }
    })
  }, [])

  return (
  <div className='noti-inv-card flex'>
    <div
      className="invite-info-img" 
      {...(img && img !== '' && { 
        style: { backgroundImage: `url('${img}')`, backgroundSize: 'cover' } 
      })}
    />
    <div className='desc-wrap flex j-between'>
      <div className='description'>
        <h5>Project invitation</h5>
        <div className="text-wrap invite">
          invited from {val.organName}'s {val.projectName} project with {enumFormatter(val.roleCode)} permissions.
        </div>
      </div>
      <div className='btns flex a-center'>
        <button
          className='big-main-btn'
          onClick={() => {
            const joinProjectData = {
              reqData: {
                organId: val.organId,
                projectIds: [val.projectId]
              }
            }
  
            /* 프로젝트 참여하기 */
            joinPj({ variables: joinProjectData }).then(({ data }) => {
              if (data) {
                if (data.joinProject) {
                  const token = data.joinProject.data[0].userToken;
                  updateToken(data.joinProject.data[0].userToken);
                  localStorage.setItem('token', token);
                  navigate(`/organ/${val.organId}/project/${val.projectId}`);
                }
              }
            })
          }}
        >
          Join
        </button>
      </div>
    </div>
  </div>
  )
}
export default NotiInvitedCard;