import { Fragment, useEffect, useMemo, useState } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import IconEditSubnet from 'assets/svgs/v3/ico_edit_subnet.svg';
import IconClear from 'assets/svgs/v3/ico_input_clear.svg';
import Icon from 'components/v2/atoms/Icon';
import { IMgdTablePaginationProps } from 'layouts/v3/MgdLayout';
import './index.scss';
import { AwsSubnetType } from 'graphql/types/AwsSubnet';

interface IEditSubnetAssociationModalProps extends IBaseModalProps {
  listSubnetAssociated: string[];
  listSubnet: AwsSubnetType[];
  isLoading: boolean;
  onSaveChange: () => void;
}

const EditSubnetAssociationModal = ({
  listSubnetAssociated,
  listSubnet,
  isLoading,
  onSaveChange,
  ...baseModalProps
}: IEditSubnetAssociationModalProps) => {
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [subnetSelectedList, setSubnetSelectedList] = useState<{id: string, subnetId: string, name: string}[]>([]);

  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 10,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });

  const columns = useMemo((): ColumnType[] => {
    return [
      { label: 'Name', field: 'name', sort: true },
      { label: 'Subnet ID', field: 'subnetId', sort: true },
      { label: 'Associated with', field: 'networkAclId', sort: true },
      { label: 'Availability Zone', field: 'availabilityZone', sort: true },
      { label: 'IPv4 CIDR', field: 'cidrBlock', sort: true },
      { label: 'IPv6 CIDR', field: 'ipv6CidrBlock', sort: true },
    ];
  }, []);

  const rows = useMemo((): RowType[] => {
    return listSubnet.map(subnet => {
      return {
        id: subnet?.subnetId,
        ...subnet,
        name: subnet.tags?.find(t => t?.key == 'Name')?.value,
        ipv6CidrBlock: subnet?.ipv6CidrBlockAssociationSet?.[0]?.ipv6CidrBlock ?? '-',
      };
    });
  }, [listSubnet]);

  useEffect(() => {
    if (baseModalProps.open) {
      if (rows && rows?.length > 0) {
        const subnetList: {id: string, subnetId: string, name: string}[] = [];
        rows.map((row) => {
          if (row.id) subnetList.push({id: row.id.toString(), subnetId: row.id.toString(), name: row.name ?? '-'});
        })
        if (subnetList) setSubnetSelectedList(subnetList)
      }
    }
  }, [baseModalProps.open]);

  useEffect(() => {
    const subnetList: {id: string, subnetId: string, name: string}[] = [];
    checkedList.map((selected) => {
      const subnet = rows.find((row) => row.id === selected);
      if (subnet) {
        subnetList.push({id: subnet?.id?.toString() || '', subnetId: subnet?.id?.toString() || '', name: subnet?.name ?? '-'})
      }
    });
    if (subnetList) setSubnetSelectedList(subnetList)
  }, [checkedList]);

  return (
    <BaseModal
      title={() => <><Icon width={32} height={32} src={IconEditSubnet}/>{'Edit subnet associations'}</>}
      {...baseModalProps}>
      <div className="edit-subnet-network-modal">
        <div className="sub-header">
          <p>Enter the name for the response body. You’ll use the name to reference this response body in the web ACL rules.</p>
        </div>
        <div className="title">
          <p>Available subnets</p>
        </div>
        <Table
          rows={rows}
          className="info-network-modal"
          columns={columns}
          reportCheckedList={(list: string[]) => {
            setCheckedList(list);
          }}
          sortOption={{
            target: mainTablePagination.target,
            direction: mainTablePagination.direction,
            onChangeSort: () => {},
          }}
          isLoading={isLoading}
          isAllRowChecked={true}
        />
        {subnetSelectedList.length > 0 &&
          <Fragment>
            <div className="title">
              <p>Selected subnets</p>
            </div>
            <div className="subnet-selected">
              {subnetSelectedList?.map((subnet, index) => {
                return (
                  <div key={`subnet-item-${index}`} className="subnet">
                    <p>{`${subnet.subnetId}/${subnet.name}`}</p>
                    <Icon width={28} height={28} src={IconClear}/>
                  </div>
                );
              })}
            </div>
          </Fragment>
        }
        
        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" disabled={true} onClick={() => onSaveChange}>
            Save changes
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditSubnetAssociationModal;
