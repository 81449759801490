import { useEffect, useMemo, useState } from "react";
import { SECURITY_GROUP_SUMMARY_TAB_LIST } from "./configs";
import { SecurityGroupSummaryPropsType } from "../types";
import DetailTab from "../DetailTab";
import { TextTypeEnum } from "layouts/v3/MgdTabLayout/configs";
import InboundOutbound from "./Tabs/InboundOutbound";
import TagTab from "../../../EC2/components/TagTab";
import { ColumnType, RowType } from "@Types/v2/Table";
import { IMgdDetailKeyValueProps } from "layouts/v3/MgdLayout";
import { handleLinkClicked } from "../../../Utils";
import { ManagementTypeEnum } from "../../..";
import { NETWORK_TAB_LIST } from "../../configs";
import { vpcFilterDropdown } from "../../Constants";
import { useNavigate } from "react-router-dom";

const SecurityGroupSummary = (props: SecurityGroupSummaryPropsType) => {
  const navigate = useNavigate()

  const {securityGroup, cloudId, region, isSummaryHidden} = props;
  const [tabSelected, setTabSelected] = useState(SECURITY_GROUP_SUMMARY_TAB_LIST[0]);

  const securityGroupId = useMemo((): string => {
    return securityGroup?.groupId ?? '';
  }, [securityGroup]);

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    if (securityGroup) {
      return [
        {
          id: 'name',
          type: TextTypeEnum.NORMAL,
          description: securityGroup?.tags?.find((r: { key: string }) => r.key === 'Name')?.value || '-',
          title: 'Security group name',
        },
        {
          id: 'groupId',
          type: TextTypeEnum.NORMAL,
          description: securityGroup?.groupId,
          title: 'Security group ID',
        },
        {
          id: 'description',
          type: TextTypeEnum.NORMAL,
          description: securityGroup?.description,
          title: 'Description',
        },
        {
          id: 'vpcId',
          type: TextTypeEnum.LINK,
          description: securityGroup?.vpcId,
          title: 'VPC ID',
          handleItemClick: () => handleLinkClicked({
            navigate,
            link: '/organ/1/manage/network',
            type: ManagementTypeEnum.NETWORK,
            tabId: NETWORK_TAB_LIST[0].id,
            key: vpcFilterDropdown[0].value.toString(),
            value: securityGroup?.vpcId,
          })
        },
        {
          id: 'ownerId',
          type: TextTypeEnum.COPY,
          description: securityGroup?.ownerId,
          title: 'Owner',
        },
        {
          id: 'inboundRuleCount',
          type: TextTypeEnum.NORMAL,
          description:
          securityGroup?.ipPermissions?.length > 1
              ? `${securityGroup?.ipPermissions?.length} permission entries`
              : `${securityGroup?.ipPermissionsEgress?.length} permission entry`,
          title: 'inbound rules count',
        },
        {
          id: 'outboundRuleCount',
          type: TextTypeEnum.NORMAL,
          description:
          securityGroup?.ipPermissionsEgress?.length > 1
              ? `${securityGroup?.ipPermissionsEgress?.length} permission entries`
              : `${securityGroup?.ipPermissionsEgress?.length} permission entry`,
          title: 'outbound rules count',
        },
      ]
    }
    return [];
  }, [securityGroup]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const tagRows = useMemo((): RowType[] => {
    return securityGroup?.tags ?? [];
  }, [securityGroup]);

  const tabNode = useMemo(() => {
    switch (tabSelected.id) {
      case SECURITY_GROUP_SUMMARY_TAB_LIST[0].id:
        return <DetailTab title={'Details'} data={detailTabContentData} isApiLoading={false} />;

      case SECURITY_GROUP_SUMMARY_TAB_LIST[1].id:
        return <InboundOutbound securityGroup={securityGroup} cloudId={cloudId} region={region} isInbound={true} />;

      case SECURITY_GROUP_SUMMARY_TAB_LIST[2].id:
        return <InboundOutbound securityGroup={securityGroup} cloudId={cloudId} region={region} isInbound={false} />;

      case SECURITY_GROUP_SUMMARY_TAB_LIST[3].id:
        return <TagTab title={'Tags'} rows={tagRows} columns={tagColumns} />;

      default:
        return null;
    }
  }, [tabSelected, securityGroup, cloudId, region, tagRows, tagColumns, detailTabContentData]);

  useEffect(() => {
    setTabSelected(SECURITY_GROUP_SUMMARY_TAB_LIST[0]);
  }, [securityGroup]);

  if (!securityGroup) {
    return (
      <div className="detail-data">
        <p className="empty-page">Select a item</p>
      </div>
    );
  }

  return (
    <div className="box-summary">
      {!isSummaryHidden && <div className="box-summary-id">{securityGroupId}</div>}

      <div className="detail-tab flex a-center">
        {SECURITY_GROUP_SUMMARY_TAB_LIST.map(tab => {
          return (
            <button
              className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
              key={tab.id}
              data-tab={tab.id}
              onClick={e => setTabSelected(tab)}
            >
              <p>{tab.name}</p>
            </button>
          );
        })}
      </div>

      <div className="content-tab">{tabNode}</div>
    </div>
  );
}

export default SecurityGroupSummary;