import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IReplaceAwsNetWorkAclAssociationVariables {
  cloudId: number;
  region: string;
  request: {
    dryRun?: boolean;
    associationId: string;
    networkAclId: number;
  }
}

export interface IReplaceAwsNetWorkAclAssociationResponseData {
  replaceAwsNetWorkAclAssociation: IGqlResponseData<undefined>;
}

const replaceAwsNetWorkAclAssociationMutation = () => useMutation<IReplaceAwsNetWorkAclAssociationResponseData, IReplaceAwsNetWorkAclAssociationVariables>(query);
export default replaceAwsNetWorkAclAssociationMutation;