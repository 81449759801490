import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import LabelInput from 'components/v2/LabelInput';
import Labeled from 'components/v2/atoms/Labeled';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import InputAtom from 'components/v2/atoms/InputAtom';
import { ACCESS_KEYS } from 'utils/DummyData/Dropdown';
import { checkLinkedCloudName } from 'utils/v2/ValidCheck';
import { AllRuleCloudType } from 'graphql/types/AllRuleCloud';
import addProjectCloud, { IAddProjectCloudVariables } from 'graphql/mutations/addProjectCloud';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import MultiSelectAutocomplete from 'components/v2/atoms/AutocompleteInputAtom';
import ConfigureAccountModal from './ConfigureAccountModal';
import Icon from 'components/v2/atoms/Icon';
import RedCircleCloud from 'assets/svgs/v2/ico_redcircle_cloud.svg';
import ReviewAccountModal from './ReviewAccountModal';
import { IConfigAccountProps, IEnumTypeCodeProps, IFeatureProps } from '@Types/v3/content';
import { enumFormatter } from 'utils/Formatter';

interface IPayload {
  name: string;
  cloudId: number;
  serviceType: string;
  rootAccount: string;
  verifyInfo: {
    id: number;
    attrKey: string;
    attrValue: string;
  }[];
  region: string[];
  scope: string;
  features: IFeatureProps[];
  configAccount: IConfigAccountProps
}

interface ICreateCloudModalProps extends IBaseModalProps {
  cloudTypeList: AllRuleCloudType[];
  doRefecthCloudList: () => void
  projectId: number;
  title: () => JSX.Element;
  onCreateSuccess: () => void;
}

const CreateCloudModal = ({
  cloudTypeList,
  doRefecthCloudList,
  projectId,
  title,
  onCreateSuccess,
  ...baseModalProps
}:ICreateCloudModalProps) => {

  const [payload, setPayload] = useState<IPayload>({
    name: '', /* 연계 계정 이름 */
    cloudId: -1, /* 클라우드 종류 */
    serviceType: '',
    rootAccount: '', /* 클라우드 계정 */
    region: [],
    verifyInfo: [],
    scope: '',
    features:[],
    configAccount: {
      accountId: '', 
      externalId: '',
      roleArn: ''
    }
  });
  const [accessKeys, setAccessKeys] = useState<{ name: string; value: string; }[]>([]);  
  const [serviceTypeList, setServiceTypeList] = useState<IEnumTypeCodeProps[]>([]);  
  const [regionList, setRegionList] = useState<IEnumTypeCodeProps[]>([]); 
  const [msspScopeList, setMsspScopeList] = useState<IEnumTypeCodeProps[]>([]); 
  const [reviewStatus, setReviewStatus]= useState(false);
  const [functionTypeList, setFunctionTypeList] = useState<IEnumTypeCodeProps[]>([]); 
  const [modalIsOpen, setModalIsOpen] = useState({
    configAccount: false,
    reviewConfigAccount: false
  });

  const [getEnumTypeCode] = lazyGetEnumTypeCode();

  /** 연계 클라우드 생성 */
  const [addCloud] = addProjectCloud();

  const ableCreateCloud = useMemo(() => {
    if (checkLinkedCloudName(payload.name) ||
    payload.cloudId === -1 || 
    (payload.serviceType === 'mssp0000' && !reviewStatus) ||
    payload.serviceType === 'monitor0' && (
    payload.rootAccount === '' || 
    payload.verifyInfo.length === 0 ||
    payload.verifyInfo.some(val => val.attrKey === '' || val.attrValue === ''))) {
      return true;
    } else return false;
  }, [payload, reviewStatus])
  
  const accessKeysList = useMemo(() => {
    const attrKeys = payload.verifyInfo.map(val => val.attrKey);
    return accessKeys.filter(acc => !attrKeys.includes(acc.value))
  }, [accessKeys, payload])

  const attrJson = (arr: { id: number, attrKey: string, attrValue: string | boolean}[]) => {          
    const outputObject: { [key: string]: string | boolean }  = {};
    
    for (let i = 0; i < arr.length; i++) {
      const currentObj = arr[i];
      const currentKey = currentObj.attrKey;
      const currentValue = currentObj.attrValue;
    
      outputObject[currentKey] = currentValue;
    }

    return JSON.stringify(outputObject, null, 0);
  }

  const isDisabled = useMemo(() => {
    if (payload.cloudId === -1  ){
      return true;
    } else return false;
  }, [payload])

  const [chooseRegion,setChooseRegion] = useState('AwsRegionCode');

  useEffect(() => {
   
    if (baseModalProps.open) {
      getEnumTypeCode({variables: { text: 'ServiceTypeCode' }}).then(res => {
        if (res.data) {
          setServiceTypeList(res.data.getEnumTypeCode.data.map(val => ({
            name: enumFormatter(val.value),
            value: val.value
          })));
        }
      })

      getEnumTypeCode({variables: { text: 'MsspScopeCode' }}).then(res => {
        if (res.data) {
          setMsspScopeList(res.data.getEnumTypeCode.data.map(val => ({
            name: enumFormatter(val.value),
            value: val.value,
            description: val.value === 'organ000' ? 'secure your AWS Organization': 'secure your specific AWS account'
          })));
        }
      })

      getEnumTypeCode({variables: { text: 'FunctionType' }}).then(res => {
        if (res.data) {
          setFunctionTypeList(res.data.getEnumTypeCode.data.map(val => ({
            name: enumFormatter(val.value),
            description: val.description,
            value: val.value,
            defaultYn: val.defaultYn
          })));
          const features = res.data.getEnumTypeCode.data.map(val => {
            return {
              id: payload.features.length === 0 
              ? 1 
              : payload.features[payload.features.length - 1].id + 1,
              attrKey: val.value,
              attrValue: val.defaultYn || false
            };
          })
          setPayload(prev => ({
            ...prev,
            features: features
          }));
        }
      })
      setPayload({
        name: '', /* 연계 계정 이름 */
        cloudId: -1,
        serviceType: '',
        rootAccount: '', /* 클라우드 계정 */
        verifyInfo: [],
        region: [],
        scope: '',
        features:[],
        configAccount: {
          accountId: '', 
          externalId: '',
          roleArn: ''
        }
      });
      doRefecthCloudList();
      setAccessKeys(ACCESS_KEYS); //todo
    }
  }, [baseModalProps.open]);

  useEffect(() => {
    if(!chooseRegion) return;
    
    getEnumTypeCode({variables: { text:  chooseRegion }}).then(res => {
      if (res.data) {
        setRegionList(res.data.getEnumTypeCode.data.map(val => ({
          name: val.name,
          value: val.value
        })));
      }
    })
  }, [chooseRegion]);

  const createCloudData = (payload: IPayload) => {
    let cloudData: IAddProjectCloudVariables[] = [];
    if(payload.configAccount.members) {
      cloudData = payload.configAccount.members.map((member) => {
        return {
          reqAddProjectCloud: {
            projectId: projectId,
            name: `${payload.name}-${member.name}`, /* 연계 계정 이름 */
            cloudId: payload.cloudId, /* 클라우드 종류 */
            rootAccount: payload.rootAccount, /* 클라우드 계정 */
            attrKey1: 'Authentication',
            attrValue1: attrJson(payload.verifyInfo),
            serviceType: payload.serviceType,
            features: attrJson(payload.features),
            msspAttrKey: 'Mssp',
            msspAttrValue: JSON.stringify({'region':payload.region}, null, 0),
            attrKey2: 'AssumeRole',
            attrValue2: JSON.stringify({accountId: member.id, externalId: payload.configAccount.externalId, roleArn: member.arn}, null, 0),
          }
        }
      });
    } else {
      cloudData = [{
        reqAddProjectCloud: {
          projectId: projectId,
          name: payload.name, /* 연계 계정 이름 */
          cloudId: payload.cloudId, /* 클라우드 종류 */
          rootAccount: payload.rootAccount, /* 클라우드 계정 */
          attrKey1: 'Authentication',
          attrValue1: attrJson(payload.verifyInfo),
          serviceType: payload.serviceType,
          features: attrJson(payload.features),
          msspAttrKey: 'Mssp',
          msspAttrValue: JSON.stringify({'region':payload.region}, null, 0),
          attrKey2: 'AssumeRole',
          attrValue2: JSON.stringify(payload.configAccount, null, 0),
        }
      }];
    }

    return cloudData;
  }

  const handleClearState = () => {
    setPayload({
      name: '', /* 연계 계정 이름 */
      cloudId: -1, /* 클라우드 종류 */
      serviceType: '',
      rootAccount: '', /* 클라우드 계정 */
      region: [],
      verifyInfo: [],
      scope: '',
      features:[],
      configAccount: {
        accountId: '',
        externalId: '',
        roleArn: ''
      }
    });
    setAccessKeys([]);
    setServiceTypeList([]);
    setChooseRegion('');
    setRegionList([]);
    setMsspScopeList([]);
    setReviewStatus(false);
    setFunctionTypeList([]);
    setModalIsOpen({
      configAccount: false,
      reviewConfigAccount: false
    })
  }

  const handleOnClose = () => {
    handleClearState();
    // @ts-ignore
    baseModalProps.onClose();
  }

  return (
    <BaseModal 
      {...baseModalProps}
      title={title}
      onClose={handleOnClose}
    >
      <div className="create-cloud-modal">
        <div className={`row-1 flex col ${payload.serviceType !== '' && 'scroll'}`}>
          <LabelInput 
            title="Name"
            value={payload.name}
            required={true}
            placeholder="related cloud name"
            onChangeValue={(val) => setPayload((prev) => ({
              ...prev,
              name: val
            }))}
          />
          <Labeled
            title="Service type"
            required={true}
          >
            <DropdownAtom 
              id={'service-type'} 
              data={serviceTypeList} 
              placeholder="Select service type"
              value={{
                name: serviceTypeList.find(val => val.value === payload.serviceType)?.name 
                  ? serviceTypeList.find(val => val.value === payload.serviceType)?.name 
                  : '',
                value: payload.serviceType
              }} 
              handleClick={(val: DropdownListDataType) => 
                setPayload((prev) => ({
                  ...prev,
                  serviceType: String(val.value)
                }))
              } 
            />
          </Labeled>
          {
            payload.serviceType === 'monitor0' &&
            <>
              <Labeled title='Input your information to use monitoring service.' required />
              <Labeled
                title="Cloud provider"
                required={true}
              >
                <DropdownAtom
                  id={'cloud-type'}
                  data={cloudTypeList.map(val => {
                    return {
                      id: val.cloudId,
                      name: val.name,
                      value: val.cloudKind
                    };
                  })}
                  placeholder="Select cloud provider"
                  // error={}
                  value={{
                    name: cloudTypeList.find(cloud => cloud.cloudId === payload.cloudId)?.name
                      ? cloudTypeList.find(cloud => cloud.cloudId === payload.cloudId)?.name : '',
                    value: String(cloudTypeList.find(cloud => cloud.cloudId === payload.cloudId)?.cloudKind)
                  }}
                  handleClick={(val: DropdownListDataType) => setPayload((prev) => ({
                    ...prev,
                    cloudId: Number(val.id)
                  }))} />
              </Labeled>
              {
                payload.cloudId !== -1 &&
                <>
                  <LabelInput
                    title="Account ID"
                    placeholder="Input account ID" 
                    defaultValue={payload.rootAccount}
                    required={true}
                    onChangeValue={(val) => setPayload((prev) => ({
                      ...prev,
                      rootAccount: val
                    }))}
                  />
                  <Labeled
                    title="Credentials"
                    className="verify-information"
                    required={true}
                  >
                    <div className="add-info flex j-end a-center"
                      onClick={() => {
                        const info = {
                          id: payload.verifyInfo.length === 0 
                            ? 1 
                            : payload.verifyInfo[payload.verifyInfo.length - 1].id + 1,
                          attrKey: '',
                          attrValue: ''
                        };
                        setPayload(prev => ({
                          ...prev,
                          verifyInfo: [...payload.verifyInfo, info]
                        }));
                      }}>
                        { payload.verifyInfo.length < 3 && <><button className="add-btn" />Add</> }
                    </div>
                    {
                      payload.verifyInfo.length === 0 
                        ? <p className="empty-info">input cloud credentials</p>
                        : payload.verifyInfo.map(verify => (
                          <div 
                            key={verify.id}
                            className="access-info flex a-center"
                            data-focus={`${verify.id}`}
                          >
                            <DropdownAtom 
                              id={`access-key ${verify.id}`} 
                              data={ accessKeysList.length === 0 
                                  ? [{
                                    name: 'No more selectable key.',
                                    value: -1
                                  }]
                                : accessKeysList }
                              placeholder="Select key"
                              value={{
                                name: accessKeys.find(acc => acc.value === verify.attrKey)?.name 
                                ? accessKeys.find(acc => acc.value === verify.attrKey)?.name
                                : '',
                                value: verify.attrKey
                              }} 
                              handleClick={(val: DropdownListDataType) => {
                                if (val.value === -1) return;

                                const copy = payload.verifyInfo.map(val => val);
                                const findIdx = copy.findIndex(data => data.id === verify.id);
                                if (findIdx === -1) return;
                                
                                copy[findIdx].attrKey = String(val.value);
                                setPayload(prev => ({
                                  ...prev,
                                  verifyInfo: copy
                                }));
                              }} 
                            />
                            <InputAtom
                              placeholder="Input value"
                              value={verify.attrValue}
                              onChangeValue={(str) => {
                                const copy = payload.verifyInfo.map(val => val);
                                const findIdx = copy.findIndex(data => data.id === verify.id);

                                copy[findIdx].attrValue = str;
                                setPayload(prev => ({
                                  ...prev,
                                  verifyInfo: copy
                                }));
                              }}
                            />
                            <button 
                              className="delete-btn" 
                              onClick={() => {
                                const left = payload.verifyInfo.filter(val => val.id !== verify.id);
                                setPayload(prev => ({
                                  ...prev,
                                  verifyInfo: left
                                }));
                              }} 
                            />
                            {/* {errorMsg && <div className="error-wrapper"><div className="error-icon" />{errorMsg}</div>} */}
                          </div>
                        ))
                    }
                  </Labeled>
                </>
              }
            </>
          }
          {
            payload.serviceType === 'mssp0000' &&
            <>
              <Labeled className="info-text" title='If using organization scope, multi-cloud information will be registered starting with the your cloud information name.' required/>
              <Labeled
                title="Cloud provider"
                required={true}
              >
                <DropdownAtom
                  id={'cloud-type'}
                  data={cloudTypeList.map(val => {
                    return {
                      id: val.cloudId,
                      name: val.name,
                      value: val.cloudKind
                    };
                  })}
                  placeholder="Select cloud provider"
                  value={{
                    name: cloudTypeList.find(cloud => cloud.cloudId === payload.cloudId)?.name
                      ? cloudTypeList.find(cloud => cloud.cloudId === payload.cloudId)?.name : '',
                    value: String(cloudTypeList.find(cloud => cloud.cloudId === payload.cloudId)?.cloudKind)
                  }}
                  handleClick={(val: DropdownListDataType) =>{  
                    setChooseRegion(val.name === 'GCP' ? 'GcpRegionCode': val.name === 'AZURE' ? 'AzureRegionCode': 'AwsRegionCode');
                    setPayload((prev) => ({
                      ...prev,
                      cloudId: Number(val.id),
                      region: []
                    }))
                  } } />
              </Labeled>
              <Labeled
                title="Using Region"
                required={true}
              >
                <MultiSelectAutocomplete
                 className={isDisabled ? 'pe-none' : '' } 
                  data={regionList}
                  selectedValues={payload.region}
                  placeholder="Select your regions"
                  handleClick={(val:DropdownListDataType) => {
                    const newValue = String(val.value);
                    if (!payload.region.includes(newValue)) {
                      setPayload((prev) => ({
                        ...prev,
                        region: [...prev.region, newValue]
                      }))
                    }
                  }}
                  handleRemove={(val:string) => {
                    const updatedValues = payload.region.filter((item) => item !== val);
                    setPayload((prev) => ({
                      ...prev,
                      region: updatedValues
                    }))
                  }}
                  />

              </Labeled>
              <Labeled
                title="Scope"
                className='dropdown-scope'
                required={true}
              >
                <DropdownAtom
                  id={'scope'}
                  data={msspScopeList}
                  placeholder="Select scope"
                  value={{
                    name: msspScopeList.find(val => val.value === payload.scope)?.name 
                      ? msspScopeList.find(val => val.value === payload.scope)?.name 
                      : '',
                    value: payload.serviceType
                  }} 
                  handleClick={(val: DropdownListDataType) => setPayload((prev) => ({
                    ...prev,
                    scope: String(val.value)
                  }))} />
              </Labeled>
              <Labeled title='Security capabilities and permissions' required={true} className="security-permission-wrap">
              {
                functionTypeList.map(ft => (
                  <label className="labeled">
                    <CheckboxAtom 
                      checked={payload.features.find(f => f.attrKey == ft.value)?.attrValue}
                      onchange={() => {
                        const attrValue = !payload.features.find(f => f.attrKey == ft.value)?.attrValue;
                        setPayload(prev => ({
                          ...prev,
                          features: payload.features.map(el => (el.attrKey === ft.value ? {...el, attrValue} : el))
                        }));
                      }}/>
                    <ul>
                      <li className='function-name'>{ft.name}</li>
                      <li className='function-desc'>{ft.description}</li>
                    </ul>
                  </label>
                ))
              }
              </Labeled>
              <Labeled title='Configuration' required={true}>
                <button 
                  className="config-btn flex j-center a-center"
                  onClick={() => setModalIsOpen((prev) => ({
                    ...prev,
                    configAccount: true,
                  }))}>
                  Configure Account
                </button>
              </Labeled>
            </>
          }
        </div>
        <div className="row-2">
          <button 
            className="big-main-btn flex j-center a-center"
            disabled={ ableCreateCloud }
            onClick={async () => {

              const createPjCloudDataVars:IAddProjectCloudVariables[] = createCloudData(payload);

              for(const createPjCloudDataVar of createPjCloudDataVars) {
                const data = await addCloud({ variables: createPjCloudDataVar }).then(({ data }) => {
                  return data;
                });
                if(data) {
                  if (data.addProjectCloud.result === ErrorCode.SUCCESS) {
                    useToast(ErrorCode.SUCCESS, 'Related cloud is created successfully.');
                    onCreateSuccess();
                    handleClearState();
                  } else {
                    console.log(data.addProjectCloud.result);
                    useToast(ErrorCode.UNKNOWN, 'Create related cloud failed.');
                  }
                } else {
                  useToast(ErrorCode.UNKNOWN, 'Create related cloud failed.');
                }
              }
            }}
          >
            Create
          </button>
          <ConfigureAccountModal 
            open={modalIsOpen.configAccount} 
            scope={payload.scope}
            features={payload.features}
            configAccount={payload.configAccount}
            title={() =>  
              <>
                <Icon width={32} height={32} src={RedCircleCloud} />
                Step 1. Configure account
              </>
            } 
            onClose={() => {
              setModalIsOpen((prev) => ({
                ...prev,
                configAccount: false
              }))
              setPayload({
                ...payload,
                configAccount: {
                  accountId: '',
                  externalId: '',
                  roleArn: ''
                }
              })
            }}
            onReview={(config) => {
              setModalIsOpen((prev) => ({
                ...prev,
                configAccount: false,
                reviewConfigAccount: true
              }));
              setPayload((prev) => ({
                ...prev,
                configAccount: config
              }))
            }
          } 
          />
          <ReviewAccountModal 
            open={modalIsOpen.reviewConfigAccount} 
            features={payload.features}
            scope={payload.scope}
            configAccount={payload.configAccount}
            functionType={functionTypeList}
            region={payload.region}
            msspScopeList={msspScopeList}
            title={() =>  
              <>
                <Icon width={32} height={32} src={RedCircleCloud} />
                Step 2. Review status
              </>
            } 
            onClose={() => {
              setModalIsOpen((prev) => ({
                ...prev,
                reviewConfigAccount: false
              }))
              setPayload({
                ...payload,
                configAccount: {
                  accountId: '',
                  externalId: '',
                  roleArn: ''
                }
              })
            }}
            onReview={() => setModalIsOpen((prev) => ({
              ...prev,
              configAccount: true,
              reviewConfigAccount: false
            }))}
            onComplete={() => {
              setReviewStatus(true);
              setModalIsOpen((prev) => ({
                ...prev,
                reviewConfigAccount: false
              }));
            }} 
          />
        </div>
      </div>
    </BaseModal>
    
  );
};

export default CreateCloudModal;
