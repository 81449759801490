export const termLinks = [
  {
    link: '/terms/secure',
    text: 'Privacy policy',
    target: '_blank'
  }, {
    link: '/terms',
    text: 'Terms of service',
    target: '_blank'
  }
];
export const authLinks = [
  {
    link: '/auth/reset-pw-sendemail',
    text: 'Reset password'
  }, {
    link: '/auth/signup',
    text: 'Signup'
  }
];