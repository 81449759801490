import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import LabelInput from 'components/v2/LabelInput';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import Labeled from 'components/v2/atoms/Labeled';
import InputAtom from 'components/v2/atoms/InputAtom';
import Table from 'components/v2/dataDisplay/Table';
import { IConfigAccountProps, IFeatureProps } from '@Types/v3/content';
import { useMutation } from 'react-query';
import apis from 'apis/v2';
import { useAuth } from 'contexts/AuthProvider';
import lazyGetAllAccount from 'graphql/queries/getAllAccounts';
import { AwsAccount } from 'graphql/types/AwsAccount';

interface IConfigureAccountModalProps extends IBaseModalProps {
  scope: string;
  features: IFeatureProps[];
  configAccount: IConfigAccountProps;
  title: () => JSX.Element;
  onReview: (configAccount: IConfigAccountProps) => void;
}

const ConfigureAccountModal = ({
  scope,
  features,
  configAccount,
  title,
  onReview,
  ...baseModalProps
}:IConfigureAccountModalProps) => {

  const { token } = useAuth();
  const { mutateAsync: _downloadAssumeRoleCFT } = useMutation((
    data: { 
      scope: string; 
      features: string; 
      accountId: string; 
      externalId: string; 
    }
    ) => apis.Cloud.downloadAssumeRoleCFT(token, data));
  
  const [payload, setPayload] = useState<IConfigAccountProps>(configAccount);
  const [rows, setRows] = useState<AwsAccount[]>([]);
  const [loading, setLoading] = useState(false);

  const [getAllAccounts] = lazyGetAllAccount();

  const downloadAssumeRoleCFT = (action: string) => {
    if(scope && features && payload.accountId && payload.externalId){
      _downloadAssumeRoleCFT({ 
        scope: scope, 
        features: features.filter(obj => obj.attrValue === true).map(obj => obj.attrKey).join(','), 
        accountId: payload.accountId,
        externalId: payload.externalId
      }).then(({ data }) => {
        if(action === 'create' && data && data.data[0]){
          window.open(data.data[0], "_blank");
        }
        if (action === 'download' && data && data.data[1]) {
          window.open(data.data[1], "_blank");
        }}
      );
    }
  }

  const getAllAccountRows = () => {
    setLoading(true);
    if(payload.externalId && payload.roleArn) {
      getAllAccounts({ variables: {
        reqData: {
          externalId: payload.externalId,
          roleArn: payload.roleArn
        }
      } }).then(({ data }) => {
        setLoading(false);
        if (data) {
          const formatData = data?.getAllAccounts?.data.map((e) => {
            if (payload.accountId === e.id) {
              return {
                ...e,
                arn: payload.roleArn
              }
            }

            const arrRoleArn = payload.roleArn.split(':');
            arrRoleArn[4] = e.id;
            const roleArn = arrRoleArn.join(':')
            return {
              ...e,
              arn: roleArn + '-member'
            }
          })
          setRows(formatData)
        }
      })
    }
  }

  const ablePreview = useMemo(() => {
    return payload.accountId === '' || 
    payload.externalId === '' || 
    payload.roleArn === '' ||
    scope ==='organ000' && !payload?.members?.length
  }, [payload])

  const isDisabled = useMemo(() => {
    if (payload.accountId === '' || 
    payload.externalId === '' ){
      return true;
    } else return false;
  }, [payload])

  useEffect(() => {
    setLoading(false);

    if(baseModalProps.open && configAccount) {
      payload.accountId = configAccount.accountId || '';
      payload.externalId = configAccount.externalId || '';
      payload.roleArn = configAccount.roleArn || '';
      setPayload(configAccount);
    }

    if (!(configAccount.accountId && configAccount.externalId && configAccount.roleArn)) setRows([])
  }, [baseModalProps.open, configAccount])

  return (
    <BaseModal 
      {...baseModalProps}
      title={title} 
    >
      <div className="config-account-modal">
        <div className={`row-1 flex col`}>
          <Labeled title={scope === 'organ000' ? 'Organization account ID': 'Account ID'} required={true}>
            <InputAtom
              value={payload.accountId}
              onChangeValue={(val) => {
                setPayload((prev) => ({
                  ...prev,
                  accountId: val
                }))
                setRows([])
              }}
            />
            <div className='account-info'>Add the necessary details to apply permissions for capabilities and create stack</div>
          </Labeled>
          <LabelInput 
            title="External ID"
            value={payload.externalId}
            required={true}
            onChangeValue={(val) => {
              setPayload((prev) => ({
                ...prev,
                externalId: val
              }))
              setRows([])
            }}
          />
          <Labeled title='Apply permissions for cloud acount' required={true}>
            <div className='account-info'>Apply curidy IAM role to your cloud.</div>
            <div className='flex iam-btn a-center'>
              <button className="flex j-center a-center" disabled ={isDisabled} onClick={() => downloadAssumeRoleCFT('create')}>
                Create IAM Role
              </button>
              <span>or</span>
              <button className="flex j-center a-center"  disabled ={isDisabled} onClick={() => downloadAssumeRoleCFT('download')}>
                Download IAM Role CFT
              </button>
            </div>
          </Labeled>
          <LabelInput 
            title="IAM Role ARN"
            value={payload.roleArn}
            disabled={isDisabled}
            required={true}
            placeholder='eg - arn:aws:iam::12341234:role/hello'
            onChangeValue={(val) => {
              setPayload((prev) => ({
                ...prev,
                roleArn: val
              }))
              setRows([])
            }}
          />
          {scope === 'organ000' && 
            <Labeled title='Select member Accounts' required={true}>
              <div className='account-info'>select member accounts to include in managed cloud information</div>
              <div className='flex iam-btn'>
                <button className="flex j-center a-center " disabled={payload.roleArn === '' || loading} onClick={() => getAllAccountRows()}>
                  Check members
                </button>
                {loading && <div className='progress-gif' />}
              </div>
              {rows.length > 0 && 
                <Table
                  rows={rows}
                  className="member-account"
                  columns={[{ label: 'Name', field: 'name' },{ label: 'ID', field: 'id' }]}
                  reportCheckedList={(list: string[]) => {
                    setPayload((prev) => ({
                      ...prev,
                      members: rows.filter(r => list.includes(r.id))
                    }))
                  }}
                />
              }
            </Labeled>
          }
        </div>
        <div className="row-2">
          <div className="button-group">
            <button className="big-sub-btn"
              onClick={baseModalProps.onClose}
            >Cancel</button>
            <button className="big-main-btn"
              disabled={ ablePreview }
              onClick={() => onReview(payload)}
            >Review</button>
          </div>
          
        </div>
      </div>
    </BaseModal>
  );
};

export default ConfigureAccountModal;
