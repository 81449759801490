import LabelInput from 'components/v2/LabelInput';
import './index.scss';

import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import { useEffect, useState } from 'react';

interface IDeleteProjectModalProps extends IBaseModalProps {
  data: string;
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteProjectModal = ({  
  data,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteProjectModalProps) => {

  const [orgName, setOrgName] = useState('');

  useEffect(() => {
    if (!baseModalProps.open) {
      setOrgName('');
    }

  }, [baseModalProps.open]);

  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-project-modal">

        <div className="header">
        Deleting a project will delete all data. To complete this process, you need to input the project name.
        </div>
        <div className="content">
          <LabelInput 
            title={data}
            placeholder="Input your project name"
            value={orgName}
            onChangeValue={(str) => setOrgName(str)}
            required
          />
        </div>
        <div className="btns flex j-between">
          <button 
            className="big-sub-btn-body2regular flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            Cancel
          </button>
          <button 
            className="big-main-btn-body2regular flex j-center a-center" 
            onClick={onDelete}
            disabled={orgName !== data}
          >
          Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteProjectModal;
