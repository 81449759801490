import './index.scss';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';

interface IEmailSendedModal extends IBaseModalProps {
  title: () => JSX.Element;
}

const EmailSendedModal = ({
  title,
  ...baseModalProps
}: IEmailSendedModal) => {

  return (
    <BaseModal
      title={title} 
      closeIcon={false}
      {...baseModalProps}
    >
      <div className="email-sended-modal">
        <h5>
        A password-change email is sent.<br/>
        Open the email and click the 'Verify' button.
        </h5>
        <div className="flex j-between">
          <p>
          Didn’t receive the verification email? Check your spam folder.
          </p>
          <button  
            className="big-main-btn"
            onClick={baseModalProps.onClose}
          >
          Done
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EmailSendedModal;
