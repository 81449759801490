import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ReviewPropsType } from '../CreateWebACL/type';
import { PageTitle, SectionContainer, SectionTitle, StepContainer } from '../CreateWebACL/components';
import './styles.scss';
import DetailTab from '../../../EC2/components/DetailTab';
import DetailTable from '../../../EC2/components/DetailTable';
import { METRICS_COLUMNL_LIST, RULES_COLUMN_LIST, TOKEN_DOMAIN_COLUMN_LIST } from './configs';
import SectionItem from '../CreateWebACL/components/SectionItem';
import Button, { ButtonTypeEnum } from '../CreateWebACL/components/Button';
import _ from 'lodash';
import createWebAclMutation, { CreateWebAclVariablesType } from 'graphql/mutations/createWebAcl';
import { AwsCreateWebAclRequestType } from 'graphql/types/AwsCreateWebAcl';

const Review = (props: ReviewPropsType) => {
  const { onBackButtonClicked, creationData, cloudId } = props;

  const {
    name,
    description,
    cloudWatchMetricName,
    defaultAction,
    rules,
    domains,
    region,
    sampledRequestsEnabled,
    requestSamplingAction,
    exclusionRulesCheckedList,
  } = creationData;

  const webACLDetail = useMemo(() => {
    return [
      { id: 'name', title: 'Name', description: name },
      { id: 'scope', title: 'Scope', description: region.value !== 'CloudFront' ? 'REGIONAL' : 'CLOUDFRONT' },
      { id: 'description', title: 'Description', description: description },
      { id: 'region', title: 'Region', description: region.value !== 'CloudFront' ? 'regional' : 'global' },
      { id: 'metricName', title: 'CloudWatch metric name', description: cloudWatchMetricName },
    ];
  }, []);

  const sampleRequests = useMemo(() => {
    if (exclusionRulesCheckedList?.length > 0) {
      return [
        { id: 'sampledRequests', title: 'Sampled requests', description: sampledRequestsEnabled },
        {
          id: 'sampledRequestAction',
          title: 'Sampled requests for web ACL default actions',
          description: requestSamplingAction === 'disable' ? 'Disabled' : 'Enabled',
        },
        {
          id: 'exclusionRules',
          title: 'Rules with no sample requests',
          description: exclusionRulesCheckedList,
        },
      ];
    }

    return [
      { id: 'sampledRequests', title: 'Sampled requests', description: sampledRequestsEnabled },
      {
        id: 'sampledRequestAction',
        title: 'Sampled requests for web ACL default actions',
        description: requestSamplingAction === 'disable' ? 'Disabled' : 'Enabled',
      },
    ];
  }, [sampledRequestsEnabled, requestSamplingAction, exclusionRulesCheckedList]);

  const ruleRows = useMemo(() => {
    return rules.map((rule: any) => {
      const { name, action } = rule;

      return {
        name: name,
        capacity: 1,
        action: action ? Object.keys(action)?.[0] : 'Use rule actions',
      };
    });
  }, [rules]);

  const ruleMetricRows = useMemo(() => {
    return rules.map((rule: any) => {
      const { name, visibilityConfig } = rule;

      return {
        name: name,
        metricName: visibilityConfig?.metricName,
      };
    });
  }, [rules]);

  const totleWCUs = useMemo(() => {
    let sum = 0;
    rules.forEach((item: any) => {
      sum += item?.capacity ?? 1;
    });

    return sum;
  }, [rules]);

  const domainRows = useMemo(() => {
    return domains.map((domain: any) => {
      return {
        name: domain,
      };
    });
  }, [domains]);

  return (
    <div id="review-webacl-page-container">
      <SectionTitle title="Review and create web ACL" customStyle="white-background" />
      <StepContainer title="Step 1. Describe web ACL and associate it to AWS resources">
        <SectionContainer customStyle="no-padding-bottom">
          <DetailTab data={webACLDetail} title="Web ACL details" />
        </SectionContainer>
      </StepContainer>
      <StepContainer title="Step 2 and 3. Add rules and set rule priority">
        <DetailTable
          title="Rules"
          description="If a request matches a rule, take the corresponding action. The rules are prioritized in order they appear."
          data={ruleRows}
          columns={RULES_COLUMN_LIST}
        />
        <SectionContainer
          title="Web ACL capacity units (WCUs) used by your web ACL"
          description="The WCUs used by the web ACL will be less than equal to the sum of the capacities for all of the rules in the web ACL."
          customStyle="no-padding-bottom"
        >
          <SectionItem.Container
            title={'The total WCUs for a web ACL can’t exceed 5000. Using over 1500 WCUs affects your costs.'}
          >
            <SectionItem.StyledText text={`${totleWCUs}/5000 WCUs`} />
          </SectionItem.Container>
        </SectionContainer>
        <SectionContainer
          title="Default web ACL action for requests that don’t match any rules"
          customStyle="no-padding-bottom"
        >
          <SectionItem.Container title={'Action'}>
            <SectionItem.StyledText text={_.startCase(defaultAction)} />
          </SectionItem.Container>
        </SectionContainer>
        <SectionContainer customStyle="no-padding-bottom">
          <DetailTable
            title={`Token domain list (${domainRows.length})`}
            data={domainRows}
            columns={TOKEN_DOMAIN_COLUMN_LIST}
          />
        </SectionContainer>
      </StepContainer>
      <StepContainer title="Step 4. Configure metrics">
        <DetailTable title="Amazon CloudWatch metrics" data={ruleMetricRows} columns={METRICS_COLUMNL_LIST} />
        <SectionContainer customStyle="no-padding-bottom">
          <DetailTab data={sampleRequests} title="Sampled requests" />
        </SectionContainer>
      </StepContainer>
    </div>
  );
};

export default Review;
