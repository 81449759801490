import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteDashboardVariables {
  id: number;
}

export interface IDeleteDashboardResponseData {
  deleteDashboard: IGqlResponseData<undefined>;
}

/** 조직 추가 */
const deleteDashboard = () => useMutation<IDeleteDashboardResponseData, IDeleteDashboardVariables>(query);
export default deleteDashboard;