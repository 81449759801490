import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { IMgdTabProps, TextTypeEnum } from 'layouts/v3/MgdLayout';

export interface INetworkRuleTypeContentProps {
  type: string;
  key?: string;
}

export interface INetworkRuleTypeProps {
  [key: string]: INetworkRuleTypeContentProps;
}

export interface INetworkProcotolContentProps {
  protocol: string;
}

export interface INetworkProcotolProps {
  [key: string]: INetworkProcotolContentProps;
}

export interface INetworkIcmpTypeContentProps {
  icmpType: string;
}

export interface INetworkIcmpTypeProps {
  [key: string]: INetworkIcmpTypeContentProps;
}

export interface INetworkIcmpCodeContentProps {
  icmpCode: string;
}

export interface INetworkIcmpCodeProps {
  [key: string]: INetworkIcmpCodeContentProps;
}

export const NETWORK_RULES_TYPE_MAPPING: INetworkRuleTypeProps = {
  '22': { type: 'SSH (22)' },
  '23': { type: 'Telnet (23)' },
  '25': { type: 'SMTP (25)' },
  '42': { type: 'nameserver (42)' },
  '53_tcp': { type: 'DNS (TCP) (53)' },
  '53_udp': { type: 'DNS (UCP) (53)' },
  '80': { type: 'HTTP (80)' },
  '110': { type: 'POP3 (110)' },
  '143': { type: 'IMAP (143)' },
  '389': { type: 'LDAP (389)' },
  '443': { type: 'HTTPS (443)' },
  '445': { type: 'SMB (445)' },
  '465': { type: 'SMTPS (465)' },
  '993': { type: 'IMAPS (993)' },
  '995': { type: 'POP3S (995)' },
  '1433': { type: 'MSSQL (1433)' },
  '1521': { type: 'Oracle (1521)' },
  '2049': { type: 'NFS (2049)' },
  '3306': { type: 'MySQL/Aurora (3306)' },
  '3389': { type: 'RDP (3389)' },
  '5432': { type: 'PostgreSQL (5432)' },
  '5439': { type: 'Redshift (5439)' },
  '5985': { type: 'WinRM-HTTP (5985)' },
  '5986': { type: 'WinRM-HTTPS (5986)' },
  '8080': { type: 'HTTP* (8080)' },
  '8443': { type: 'HTTPS* (8443)' },
  all_traffic: { type: 'All traffic' },
  custom_protocol: { type: 'Custom Protocol' },
  custom_tcp: { type: 'Custom TCP' },
  custom_udp: { type: 'Custom UDP' },
  custom_icmp_ipv4: { type: 'Custom ICMP - IPv4' },
  custom_icmp_ipv6: { type: 'Custom ICMP - IPv6' },
  all_tcp: { type: 'All TCP' },
  all_udp: { type: 'All UDP' },
  all_icmp_ipv4: { type: 'All ICMP - IPv4' },
  all_icmp_ipv6: { type: 'All ICMP - IPv6' },
};

export const SECURITY_GROUP_RULES_TYPE_MAPPING: INetworkRuleTypeProps = {
  custom_tcp: { type: 'Custom TCP', key: 'custom_tcp' },
  custom_udp: { type: 'Custom UDP', key: 'custom_udp' },
  custom_icmp_ipv4: { type: 'Custom ICMP - IPv4', key: 'custom_icmp_ipv4' },
  custom_protocol: { type: 'Custom Protocol', key: 'custom_protocol' },
  all_tcp: { type: 'All TCP', key: 'all_tcp' },
  all_udp: { type: 'All UDP', key: 'all_udp' },
  all_icmp_ipv4: { type: 'All ICMP - IPv4', key: 'all_icmp_ipv4' },
  all_icmp_ipv6: { type: 'All ICMP - IPv6', key: 'all_icmp_ipv6' },
  all_traffic: { type: 'All traffic', key: 'all_traffic' },
  '22': { type: 'SSH', key: '22' },
  '25': { type: 'SMTP', key: '25' },
  '53_tcp': { type: 'DNS (TCP)', key: '53_tcp' },
  '53_udp': { type: 'DNS (UCP)', key: '53_udp' },
  '80': { type: 'HTTP', key: '80' },
  '110': { type: 'POP3', key: '110' },
  '143': { type: 'IMAP', key: '143' },
  '389': { type: 'LDAP', key: '389' },
  '443': { type: 'HTTPS', key: '443' },
  '445': { type: 'SMB', key: '445' },
  '465': { type: 'SMTPS', key: '465' },
  '993': { type: 'IMAPS', key: '993' },
  '995': { type: 'POP3S', key: '995' },
  '1433': { type: 'MSSQL', key: '1433' },
  '1521': {type: 'Oracle-RDS', key: '1521'},
  '2049': { type: 'NFS', key: '2049' },
  '3306': { type: 'MySQL/Aurora', key: '3306' },
  '3389': { type: 'RDP', key: '3389' },
  '5439': { type: 'Redshift', key: '5439' },
  '5432': { type: 'PostgreSQL', key: '5432' },
  '5985': { type: 'WinRM-HTTP', key: '5985' },
  '5986': { type: 'WinRM-HTTPS', key: '5986' },
  '2007': { type: 'Elastic Graphics', key: '2007' },
  '9142': { type: 'CQLSH / CASSANDRA', key: '9142' },
};

export const SECURITY_GROUP_RULES_PROTOCOL_MAPPING: INetworkProcotolProps = {
  '-1': { protocol: 'All' },
  '0': { protocol: 'Echo Reply' },
  '3': { protocol: 'Destination Unreachable' },
  '4': { protocol: 'Source Quench' },
  '5': { protocol: 'Redirect Message' },
  '6': { protocol: 'Alternate Host Address' },
  '8': { protocol: 'Echo Request' },
  '9': { protocol: 'Router Advertisement' },
  '10': { protocol: 'Router Solicitation' },
  '11': { protocol: 'Time Exceeded' },
  '12': { protocol: 'Parameter Problem' },
  '13': { protocol: 'Timestamp' },
  '14': { protocol: 'Timestamp Reply' },
  '15': { protocol: 'Information Request' },
  '16': { protocol: 'Information Reply' },
  '17': { protocol: 'Address Mask Request' },
  '18': { protocol: 'Address Mask Reply' },
  '30': { protocol: 'Traceroute' },
  '31': { protocol: 'Datagram Conversion Error' },
  '32': { protocol: 'Mobile Host Redirect' },
  '33': { protocol: 'Where Are You' },
  '34': { protocol: 'Here I Am' },
  '35': { protocol: 'Mobile Registration Request' },
  '36': { protocol: 'Mobile Registration Reply' },
  '37': { protocol: 'Domain Name Request' },
  '38': { protocol: 'Domain Name Reply' },
  '39': { protocol: 'SKIP Algorithm Discovery Protocol' },
  '40': { protocol: 'Photuris, Security Failures' },
};

export const NETWORK_RULES_PROTOCOL_MAPPING: INetworkProcotolProps = {
  '-': { protocol: '-' },
  '-1': { protocol: 'All' },
  '0': { protocol: 'HOPOPT (0)' },
  '1': { protocol: 'ICMP (1)' },
  '2': { protocol: 'IGMP (2)' },
  '3': { protocol: 'GGP (3)' },
  '4': { protocol: 'IPv4 (4)' },
  '5': { protocol: 'ST (5)' },
  '6': { protocol: 'TCP (6)' },
  '7': { protocol: 'CBT (7)' },
  '8': { protocol: 'EGP (8)' },
  '9': { protocol: 'IGP (9)' },
  '10': { protocol: 'BBN-RCC-MON (10)' },
  '11': { protocol: 'NVP-II (11)' },
  '12': { protocol: 'PUP (12)' },
  '13': { protocol: 'ARGUS (deprecated) (13)' },
  '14': { protocol: 'EMCON (14)' },
  '15': { protocol: 'XNET (15)' },
  '16': { protocol: 'CHAOS (16)' },
  '17': { protocol: 'UDP (17)' },
  '18': { protocol: 'MUX (18)' },
  '19': { protocol: 'DCN-MEAS (19)' },
  '20': { protocol: 'HMP (20)' },
  '21': { protocol: 'PRM (21)' },
  '22': { protocol: 'XNS-IDP (22)' },
  '23': { protocol: 'TRUNK-1 (23)' },
  '24': { protocol: 'TRUNK-2 (24)' },
  '25': { protocol: 'LEAF-1 (25)' },
  '26': { protocol: 'LEAF-2 (26)' },
  '27': { protocol: 'RDP (27)' },
  '28': { protocol: 'IRTP (28)' },
  '29': { protocol: 'ISO-TP4 (29)' },
  '30': { protocol: 'NETBLT (30)' },
  '31': { protocol: 'MFE-NSP (31)' },
  '32': { protocol: 'MERIT-INP (32)' },
  '33': { protocol: 'DCCP (33)' },
  '34': { protocol: '3PC (34)' },
  '35': { protocol: 'IDPR (35)' },
  '36': { protocol: 'XTP (36)' },
  '37': { protocol: 'DDP (37)' },
  '38': { protocol: 'IDPR-CMTP (38)' },
  '39': { protocol: 'TP++ (39)' },
  '40': { protocol: 'IL (40)' },
  '41': { protocol: 'IPv6 (41)' },
  '42': { protocol: 'SDRP (42)' },
  '43': { protocol: 'IPv6-Route (43)' },
  '44': { protocol: 'IPv6-Frag (44)' },
  '45': { protocol: 'IDRP (45)' },
  '46': { protocol: 'RSVP (46)' },
  '47': { protocol: 'GRE (47)' },
  '48': { protocol: 'DSR (48)' },
  '49': { protocol: 'BNA (49)' },
  '50': { protocol: 'ESP (50)' },
  '51': { protocol: 'AH (51)' },
  '52': { protocol: 'I-NLSP (52)' },
  '53': { protocol: 'SWIPE (deprecated) (53)' },
  '54': { protocol: 'NARP (54)' },
  '55': { protocol: 'Min-IPv4 (55)' },
  '56': { protocol: 'TLSP (56)' },
  '57': { protocol: 'SKIP (57)' },
  '58': { protocol: 'IPv6-ICMP (58)' },
  '59': { protocol: 'IPv6-NoNxt (59)' },
  '60': { protocol: 'IPv6-Opts (60)' },
  '61': { protocol: ' (61)' },
  '62': { protocol: 'CFTP (62)' },
  '63': { protocol: ' (63)' },
  '64': { protocol: 'SAT-EXPAK (64)' },
  '65': { protocol: 'KRYPTOLAN (65)' },
  '66': { protocol: 'RVD (66)' },
  '67': { protocol: 'IPPC (67)' },
  '68': { protocol: ' (68)' },
  '69': { protocol: 'SAT-MON (69)' },
  '70': { protocol: 'VISA (70)' },
  '71': { protocol: 'IPCV (71)' },
  '72': { protocol: 'CPNX (72)' },
  '73': { protocol: 'CPHB (73)' },
  '74': { protocol: 'WSN (74)' },
  '75': { protocol: 'PVP (75)' },
  '76': { protocol: 'BR-SAT-MON (76)' },
  '77': { protocol: 'SUN-ND (77)' },
  '78': { protocol: 'WB-MON (78)' },
  '79': { protocol: 'WB-EXPAK (79)' },
  '80': { protocol: 'ISO-IP (80)' },
  '81': { protocol: 'VMTP (81)' },
  '82': { protocol: 'SECURE-VMTP (82)' },
  '83': { protocol: 'VINES (83)' },
  '84': { protocol: 'IPTM (84)' },
  '85': { protocol: 'NSFNET-IGP (85)' },
  '86': { protocol: 'DGP (86)' },
  '87': { protocol: 'TCF (87)' },
  '88': { protocol: 'EIGRP (88)' },
  '89': { protocol: 'OSPFIGP (89)' },
  '90': { protocol: 'Sprite-RPC (90)' },
  '91': { protocol: 'LARP (91)' },
  '92': { protocol: 'MTP (92)' },
  '93': { protocol: 'AX.25 (93)' },
  '94': { protocol: 'IPIP (94)' },
  '95': { protocol: 'MICP (deprecated) (95)' },
  '96': { protocol: 'SCC-SP (96)' },
  '97': { protocol: 'ETHERIP (97)' },
  '98': { protocol: 'ENCAP (98)' },
  '99': { protocol: ' (99)' },
  '100': { protocol: 'GMTP (100)' },
  '101': { protocol: 'IFMP (101)' },
  '102': { protocol: 'PNNI (102)' },
  '103': { protocol: 'PIM (103)' },
  '104': { protocol: 'ARIS (104)' },
  '105': { protocol: 'SCPS (105)' },
  '106': { protocol: 'QNX (106)' },
  '107': { protocol: 'A/N (107)' },
  '108': { protocol: 'IPComp (108)' },
  '109': { protocol: 'SNP (109)' },
  '110': { protocol: 'Compaq-Peer (110)' },
  '111': { protocol: 'IPX-in-IP (111)' },
  '112': { protocol: 'VRRP (112)' },
  '113': { protocol: 'PGM (113)' },
  '114': { protocol: ' (114)' },
  '115': { protocol: 'L2TP (115)' },
  '116': { protocol: 'DDX (116)' },
  '117': { protocol: 'IATP (117)' },
  '118': { protocol: 'STP (118)' },
  '119': { protocol: 'SRP (119)' },
  '120': { protocol: 'UTI (120)' },
  '121': { protocol: 'SMP (121)' },
  '122': { protocol: 'SM (deprecated) (122)' },
  '123': { protocol: 'PTP (123)' },
  '124': { protocol: 'ISIS over IPv4 (124)' },
  '125': { protocol: 'FIRE (125)' },
  '126': { protocol: 'CRTP (126)' },
  '127': { protocol: 'CRUDP (127)' },
  '128': { protocol: 'SSCOPMCE (128)' },
  '129': { protocol: 'IPLT (129)' },
  '130': { protocol: 'SPS (130)' },
  '131': { protocol: 'PIPE (131)' },
  '132': { protocol: 'SCTP (132)' },
  '133': { protocol: 'FC (133)' },
  '134': { protocol: 'RSVP-E2E-IGNORE (134)' },
  '135': { protocol: 'Mobility Header (135)' },
  '136': { protocol: 'UDPLite (136)' },
  '137': { protocol: 'MPLS-in-IP (137)' },
  '138': { protocol: 'manet (138)' },
  '139': { protocol: 'HIP (139)' },
  '140': { protocol: 'Shim6 (140)' },
  '141': { protocol: 'WESP (141)' },
  '142': { protocol: 'ROHC (142)' },
  '253': { protocol: '253 (253)' },
  '254': { protocol: '254 (254)' },
};

export const NETWORK_RULES_ICMP_TYPE_MAPPING: INetworkIcmpTypeProps = {
  '-1': { icmpType: 'All' },
  '0': { icmpType: 'Echo Reply' },
  '3': { icmpType: 'Destination Unreachable' },
  '4': { icmpType: 'Source Quench' },
  '5': { icmpType: 'Redirect Message' },
  '6': { icmpType: 'Alternate Host Address' },
  '8': { icmpType: 'Echo Request' },
  '9': { icmpType: 'Router Advertisement' },
  '10': { icmpType: 'Router Solicitation' },
  '11': { icmpType: 'Time Exceeded' },
  '12': { icmpType: 'Parameter Problem' },
  '13': { icmpType: 'Timestamp' },
  '14': { icmpType: 'Timestamp Reply' },
  '15': { icmpType: 'Information Request' },
  '16': { icmpType: 'Information Reply' },
  '17': { icmpType: 'Address Mask Request' },
  '18': { icmpType: 'Address Mask Reply' },
  '30': { icmpType: 'Traceroute' },
  '31': { icmpType: 'Datagram Conversion Error' },
  '32': { icmpType: 'Mobile Host Redirect' },
  '33': { icmpType: 'Where Are You' },
  '34': { icmpType: 'Here I Am' },
  '35': { icmpType: 'Mobile Registration Request' },
  '36': { icmpType: 'Mobile Registration Reply' },
  '37': { icmpType: 'Domain Name Request' },
  '38': { icmpType: 'Domain Name Reply' },
  '39': { icmpType: 'SKIP Algorithm Discovery Protocol' },
  '40': { icmpType: 'Photuris, Security Failures' },
};

export const NETWORK_RULES_ICMP_CODE_MAPPING: INetworkIcmpCodeProps = {
  '-1': { icmpCode: 'All' },
  '0': { icmpCode: 'Destination Network Unreachable' },
  '1': { icmpCode: 'Destination Host Unreachable' },
  '2': { icmpCode: 'Destination Protocol Unreachable' },
  '3': { icmpCode: 'Destination Port Unreachable' },
  '4': { icmpCode: 'Fragmentation Required, and DF Flag Set' },
  '5': { icmpCode: 'Source Route Failed' },
  '6': { icmpCode: 'Destination Network Unknown' },
  '7': { icmpCode: 'Destination Host Unknown' },
  '8': { icmpCode: 'Source Host Isolated' },
  '9': { icmpCode: 'Network Administratively Prohibited' },
  '10': { icmpCode: 'Host Administratively Prohibited' },
  '11': { icmpCode: 'Network Unreachable for TOS' },
  '12': { icmpCode: 'Host Unreachable for TOS' },
  '13': { icmpCode: 'Communication Administratively Prohibited' },
};

export const NETWORK_RULES_ICMP_CODE_RED_MES_MAPPING: INetworkIcmpCodeProps = {
  '-1': { icmpCode: 'All' },
  '0': { icmpCode: 'Redirect Datagram For The Network' },
  '1': { icmpCode: 'Redirect Datagram For The Host' },
  '2': { icmpCode: 'Redirect Datagram For The TOS & Network' },
  '3': { icmpCode: 'Redirect Datagram For The TOS & Host' },
};

export const NETWORK_RULES_ICMP_CODE_PAR_PRO_MAPPING: INetworkIcmpCodeProps = {
  '-1': { icmpCode: 'All' },
  '0': { icmpCode: 'Pointer Indicates The Error' },
  '1': { icmpCode: 'Missing A Rquited Option' },
  '3': { icmpCode: 'Bad Length' },
};

export const mainTabs: IMgdTabProps[] = [
  { id: 'vpc', name: 'VPC', title: 'Your VPCs' },
  { id: 'routeTable', name: 'Route Table', title: 'Route Tables' },
  { id: 'subnet', name: 'Subnet', title: 'Subnets' },
  { id: 'internetGw', name: 'Internet G/W', title: 'Internet gateways' },
  { id: 'natGw', name: 'NAT G/W', title: 'NAT gateways' },
  { id: 'elasticIp', name: 'Elastic IP', title: 'Elastic IPs' },
  { id: 'networkAcl', name: 'Network ACL', title: 'Network ACLs' },
  { id: 'securityGroup', name: 'Security Group', title: 'Security groups' },
];

export const detailVpcTabs: IMgdTabProps[] = [
  { id: 'details', name: 'Details', title: 'Details' },
  { id: 'cidr', name: 'CIDR', title: 'CIDR' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const detailRouteTableTabs: IMgdTabProps[] = [
  { id: 'details', name: 'Details', title: 'Details' },
  { id: 'route', name: 'Routes', title: 'Routes' },
  { id: 'subnetAssociations', name: 'Subnet associations', title: 'Subnet associations' },
  { id: 'edgeAssociations', name: 'Edge associations', title: 'Edge associations' },
  { id: 'routePropagation', name: 'Route propagation', title: 'Route propagation' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const detailSubnetTabs: IMgdTabProps[] = [
  { id: 'details', name: 'Details', title: 'Details' },
  { id: 'routeTable', name: 'Route table', title: 'Route table' },
  { id: 'networkACLInbound', name: 'Network ACL Inbound', title: 'Network ACL Inbound' },
  { id: 'networkACLOutbound', name: 'Network ACL Outbound', title: 'Network ACL Outbound' },
  { id: 'cidrReservations', name: 'CIDR reservations', title: 'CIDR reservations' },
  { id: 'sharing', name: 'Sharing', title: 'Sharing' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const detailInternetGWTabs: IMgdTabProps[] = [
  { id: 'details', name: 'Details', title: 'Details' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const detailNATGWTabs: IMgdTabProps[] = [
  { id: 'details', name: 'Details', title: 'Details' },
  { id: 'secondaryIpv4Addresses', name: 'Secondary IPv4 addresses', title: 'Secondary IPv4 addresses' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const detailElasticIPTabs: IMgdTabProps[] = [
  { id: 'details', name: 'Details', title: 'Details' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const detailNetworkALCTabs: IMgdTabProps[] = [
  { id: 'details', name: 'Details', title: 'Details' },
  { id: 'inbound', name: 'Inbound', title: 'Inbound' },
  { id: 'outbound', name: 'Outbound', title: 'Outbound' },
  { id: 'subnetAssociations', name: 'Subnet associations', title: 'Subnet associations' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const detailSecurityGroupTabs: IMgdTabProps[] = [
  { id: 'details', name: 'Details', title: 'Details' },
  { id: 'inbound', name: 'Inbound', title: 'Inbound' },
  { id: 'outbound', name: 'Outbound', title: 'Outbound' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const actionDropdownData: DropdownListDataType[] = [
  { id: 1, name: 'CIDR', value: 'CIDR' },
  { id: 2, name: 'Tags', value: 'Tags' },
];

export const actionValue: DropdownListDataType = {
  name: '',
  value: '',
};

export const detailVpcKeys = [
  'vpcId',
  'state',
  'enableDnsHostnames',
  'enableDnsSupport',
  'instanceTenancy',
  'dhcpOptionsId',
  'routeTableId',
  'networkAclId',
  'isDefault',
  'cidrBlock',
  'ipv6Pool',
  'ipv6CidrBlock',
  'firewallRuleAssociations',
  'firewallRoute53Associations',
  'ownerId',
];
export const detailVpcTextDes: { id: string; value: string; type: string }[] = [
  { id: 'vpcId', value: 'VPC ID', type: TextTypeEnum.COPY },
  { id: 'state', value: 'Status', type: TextTypeEnum.STATUS },
  { id: 'enableDnsHostnames', value: 'DNS hostnames', type: TextTypeEnum.NORMAL },
  { id: 'enableDnsSupport', value: 'DNS resolution', type: TextTypeEnum.NORMAL },
  { id: 'instanceTenancy', value: 'Tenancy', type: TextTypeEnum.NORMAL },
  { id: 'dhcpOptionsId', value: 'DHCP option set', type: TextTypeEnum.NORMAL },
  { id: 'routeTableId', value: 'Main route table', type: TextTypeEnum.LINK },
  { id: 'networkAclId', value: 'Main network ACL', type: TextTypeEnum.LINK },
  { id: 'isDefault', value: 'Default VPC', type: TextTypeEnum.NORMAL },
  { id: 'cidrBlock', value: 'IPv4 CIDR', type: TextTypeEnum.NORMAL },
  { id: 'ipv6Pool', value: 'IPV6 pool', type: TextTypeEnum.NORMAL },
  { id: 'ipv6CidrBlock', value: 'IP6 CIDR', type: TextTypeEnum.NORMAL },
  { id: 'firewallRuleAssociations', value: 'Network address usage metrics', type: TextTypeEnum.NORMAL },
  { id: 'firewallRoute53Associations', value: 'Route 53 Resolver DNS Firewall rule groups', type: TextTypeEnum.NORMAL },
  { id: 'ownerId', value: 'Owner ID', type: TextTypeEnum.COPY },
];
export const detailRoutetableKeys = ['routeTableId', 'associations', 'subnetAssociations', 'vpcId', 'ownerId'];
export const detailRoutetableTextDes: { id: string; value: string; type: string }[] = [
  { id: 'routeTableId', value: 'Route table ID', type: TextTypeEnum.COPY },
  { id: 'associations', value: 'Main', type: TextTypeEnum.NORMAL },
  { id: 'subnetAssociations', value: 'Explicit subnet associations', type: TextTypeEnum.LINK },
  { id: 'vpcId', value: 'VPC', type: TextTypeEnum.LINK },
  { id: 'ownerId', value: 'Owner ID', type: TextTypeEnum.COPY },
];

export const detailSubnetKeys = [
  'subnetId',
  'subnetArn',
  'state',
  'cidrBlock',
  'availableIpAddressCount',
  'ipv6CidrBlock',
  'availabilityZone',
  'availabilityZoneId',
  'networkBorderGroup',
  'vpcId',
  'routetableId',
  'networkAclId',
  'defaultForAz',
  'mapPublicIpOnLaunch',
  'assignIpv6AddressOnCreation',
  'mapCustomerOwnedIpOnLaunch',
  'customerOwnedIpv4Pool',
  'outpostArn',
  'ipv4cidr',
  'ipv6cidr',
  'ipv6only',
  'hostnameType',
  'enableResourceNameDnsARecord',
  'enableResourceNameDnsAAAARecord',
  'enableDns64',
  'ownerId',
];
export const detailSubnetTextDes: { id: string; value: string; type: string }[] = [
  { id: 'subnetId', value: 'Subnet ID', type: TextTypeEnum.COPY },
  { id: 'subnetArn', value: 'Subnet ARN', type: TextTypeEnum.COPY },
  { id: 'state', value: 'State', type: TextTypeEnum.NORMAL },
  { id: 'cidrBlock', value: 'IPv4 CIDR', type: TextTypeEnum.COPY },
  { id: 'availableIpAddressCount', value: 'Available IPv4 addresses', type: TextTypeEnum.COPY },
  { id: 'ipv6CidrBlock', value: 'IPv6 CIDR', type: TextTypeEnum.NORMAL },
  { id: 'availabilityZone', value: 'Availability zone', type: TextTypeEnum.COPY },
  { id: 'availabilityZoneId', value: 'Availability zone ID', type: TextTypeEnum.COPY },
  { id: 'networkBorderGroup', value: 'Network border group', type: TextTypeEnum.NORMAL },
  { id: 'vpcId', value: 'VPC', type: TextTypeEnum.NORMAL },
  { id: 'routetableId', value: 'Route table', type: TextTypeEnum.LINK },
  { id: 'networkAclId', value: 'Network ACL', type: TextTypeEnum.LINK },
  { id: 'defaultForAz', value: 'Default subnet', type: TextTypeEnum.NORMAL },
  { id: 'mapPublicIpOnLaunch', value: 'Auto-assign public IPv4 address', type: TextTypeEnum.NORMAL },
  { id: 'assignIpv6AddressOnCreation', value: 'Auto-assign IPv6 address', type: TextTypeEnum.NORMAL },
  { id: 'mapCustomerOwnedIpOnLaunch', value: 'Auto-assign customer-owned IPv4 address', type: TextTypeEnum.NORMAL },
  { id: 'customerOwnedIpv4Pool', value: 'Customer-owned IPv4 pool', type: TextTypeEnum.NORMAL },
  { id: 'outpostArn', value: 'Outpost ID', type: TextTypeEnum.NORMAL },
  { id: 'ipv4cidr', value: 'IPv4 CIDR reservations', type: TextTypeEnum.NORMAL },
  { id: 'ipv6cidr', value: 'IPv6 CIDR reservations', type: TextTypeEnum.NORMAL },
  { id: 'ipv6only', value: 'IPv6-only', type: TextTypeEnum.NORMAL },
  { id: 'hostnameType', value: 'Hostname type', type: TextTypeEnum.NORMAL },
  { id: 'enableResourceNameDnsARecord', value: 'Resource name DNS a record', type: TextTypeEnum.NORMAL },
  { id: 'enableResourceNameDnsAAAARecord', value: 'Resource name DNS AAAA record', type: TextTypeEnum.NORMAL },
  { id: 'enableDns64', value: 'DNS64', type: TextTypeEnum.NORMAL },
  { id: 'ownerId', value: 'Owner', type: TextTypeEnum.COPY },
];

export const detailInternetGWKeys = ['internetGatewayId', 'state', 'vpcId', 'ownerId'];
export const detailInternetGWTextDes: { id: string; value: string; type: string }[] = [
  { id: 'internetGatewayId', value: 'Internet gateway ID', type: TextTypeEnum.COPY },
  { id: 'state', value: 'Status', type: TextTypeEnum.STATUS },
  { id: 'vpcId', value: 'VPC ID', type: TextTypeEnum.LINK },
  { id: 'ownerId', value: 'Owner ID', type: TextTypeEnum.COPY },
];

export const detailNatGWKeys = [
  'natGatewayId',
  'connectivityType',
  'state',
  'natGatewayArn',
  'primaryPublicIpv4Address',
  'primaryPrivateIpv4Address',
  'primaryNetworkInterfaceId',
  'vpcId',
  'subnetId',
  'createTime',
  'deleted',
];
export const detailNatGWTextDes: { id: string; value: string; type: string }[] = [
  { id: 'natGatewayId', value: 'NAT gateway ID', type: TextTypeEnum.COPY },
  { id: 'connectivityType', value: 'Connectivity type', type: TextTypeEnum.NORMAL },
  { id: 'state', value: 'State', type: TextTypeEnum.STATUS },
  { id: 'natGatewayArn', value: 'NAT gateway ARN', type: TextTypeEnum.NORMAL },
  { id: 'primaryPublicIpv4Address', value: 'Primary public IPv4 address', type: TextTypeEnum.COPY },
  { id: 'primaryPrivateIpv4Address', value: 'Primary private IPv4 address', type: TextTypeEnum.COPY },
  { id: 'primaryNetworkInterfaceId', value: 'Primary network interface ID', type: TextTypeEnum.NORMAL },
  { id: 'vpcId', value: 'VPC', type: TextTypeEnum.LINK },
  { id: 'subnetId', value: 'Subnet', type: TextTypeEnum.LINK },
  { id: 'createTime', value: 'Created', type: TextTypeEnum.NORMAL },
  { id: 'deleted', value: 'Deleted', type: TextTypeEnum.NORMAL },
];

export const detailElasticIPKeys = [
  'publicIp',
  'type',
  'allocationId',
  'reverseDNSRecord',
  'associationId',
  'domain',
  'associatedInstanceId',
  'privateIpAddress',
  'networkInterfaceId',
  'networkInterfaceOwnerId',
  'publicDns',
  'natGatewayId',
  'publicIpv4Pool',
  'networkBorderGroup',
];
export const detailElasticIPTextDes: { id: string; value: string; type: string }[] = [
  { id: 'publicIp', value: 'Allocated IPv4 addresses', type: TextTypeEnum.COPY },
  { id: 'type', value: 'Type', type: TextTypeEnum.NORMAL },
  { id: 'allocationId', value: 'Allocation ID', type: TextTypeEnum.COPY },
  { id: 'reverseDNSRecord', value: 'Reverse DNS record', type: TextTypeEnum.NORMAL },
  { id: 'associationId', value: 'Association ID', type: TextTypeEnum.COPY },
  { id: 'domain', value: 'Scope', type: TextTypeEnum.NORMAL },
  { id: 'associatedInstanceId', value: 'Associated instance ID', type: TextTypeEnum.NORMAL },
  { id: 'privateIpAddress', value: 'Private IP address', type: TextTypeEnum.NORMAL },
  { id: 'networkInterfaceId', value: 'Network interface ID', type: TextTypeEnum.NORMAL },
  { id: 'networkInterfaceOwnerId', value: 'Network interface owner account ID', type: TextTypeEnum.COPY },
  { id: 'publicDns', value: 'Public DNS', type: TextTypeEnum.COPY }, // TODO: DescribeNetworkInterfacesResponse.Association.PublicDnsName
  { id: 'natGatewayId', value: 'NAT gateway ID', type: TextTypeEnum.LINK }, // TODO: DescribeNatGatewaysResponse.NatGatewayId
  { id: 'publicIpv4Pool', value: 'Address Pool', type: TextTypeEnum.NORMAL },
  { id: 'networkBorderGroup', value: 'Network border group', type: TextTypeEnum.NORMAL },
];

export const detailNetworkAclKeys = ['networkAclId', 'associations', 'isDefault', 'vpcId', 'ownerId'];
export const detailNetworkAclTextDes = [
  { id: 'networkAclId', value: 'Network ACL ID', type: TextTypeEnum.COPY },
  { id: 'associations', value: 'Associated with', type: TextTypeEnum.LINK },
  { id: 'isDefault', value: 'Default', type: TextTypeEnum.NORMAL },
  { id: 'vpcId', value: 'VPC', type: TextTypeEnum.LINK },
  { id: 'ownerId', value: 'Owner ID', type: TextTypeEnum.COPY },
];

export const vpcFilterDropdown: DropdownListDataType[] = [
  { id: 1, name: 'VPC ID', value: 'vpc-id' },
  { id: 2, name: 'Name', value: 'tag:Name' },
  { id: 3, name: 'IPv4 CIDR', value: 'cidr-block-association.cidr-block' },
  { id: 4, name: 'IPv6 CIDR', value: 'ipv6-cidr-block-association.ipv6-cidr-block' },
];

export const routeTableFilterDropdown: DropdownListDataType[] = [
  { id: 1, name: 'Route table ID', value: 'route-table-id' },
  { id: 2, name: 'Name', value: 'tag:Name' },
  { id: 3, name: 'VPC', value: 'vpc-id' },
];

export const subnetFilterDropdown: DropdownListDataType[] = [
  { id: 1, name: 'Subnet ID', value: 'subnet-id' },
  { id: 2, name: 'Name', value: 'tag:Name' },
  { id: 3, name: 'VPC ID', value: 'vpc-id' },
];

export const internetGWFilterDropdown: DropdownListDataType[] = [
  { id: 1, name: 'Internet gateway ID', value: 'internet-gateway-id' },
  { id: 2, name: 'Name', value: 'tag:Name' },
  { id: 3, name: 'State', value: 'attachment.state' },
  { id: 4, name: 'VPC ID', value: 'attachment.vpc-id' },
];

export const natGWFilterDropdown: DropdownListDataType[] = [
  { id: 1, name: 'NAT gateway ID', value: 'nat-gateway-id' },
  { id: 2, name: 'Name', value: 'tag:Name' },
  { id: 3, name: 'VPC', value: 'vpc-id' },
  { id: 4, name: 'Subnet ID', value: 'subnet-id' },
];

export const elasticIPFilterDropdown: DropdownListDataType[] = [
  { id: 1, name: 'Allocated IPv4 addresss', value: 'public-ip' },
  { id: 2, name: 'Name', value: 'tag:Name' },
  { id: 3, name: 'Allocation ID', value: 'allocation-id' },
];

export const networkACLFilterDropdown: DropdownListDataType[] = [
  { id: 1, name: 'Network ACL ID', value: 'network-acl-id' },
  { id: 2, name: 'Name', value: 'tag:Name' },
  { id: 3, name: 'VPC ID', value: 'vpc-id' },
];

export const securityGroupFilterDropdown: DropdownListDataType[] = [
  { id: 1, name: 'Security group ID', value: 'group-id' },
  { id: 2, name: 'Name', value: 'tag:Name' },
  { id: 3, name: 'Security group name', value: 'group-name' },
  { id: 4, name: 'VPC ID', value: 'vpc-id' },
];

export const detailTagColumns = [
  { label: 'Key', field: 'key', sort: true },
  { label: 'Value', field: 'value', sort: true },
];

export const RULE_TYPE_DROPDOWN: any[] = [
  { name: 'Custom TCP', value: 'custom_tcp', protocol: 'tcp', port: '0' },
  { name: 'Custom UDP', value: 'custom_udp', protocol: 'udp', port: '0' },
  { name: 'Custom ICMP - IPv4', value: 'custom_icmp_ipv4', protocol: 'icmp', port: 'All' },
  { name: 'Custom Protocol', value: 'custom_protocol', protocol: '', port: 'All' },
  { name: 'All TCP', value: 'all_tcp', protocol: 'tcp', port: '0 - 65535' },
  { name: 'All UDP', value: 'all_udp', protocol: 'udp', port: '0 - 65535' },
  { name: 'All ICMP - IPv4', value: 'all_icmp_ipv4', protocol: 'icmp', port: 'All' },
  { name: 'All ICMP - IPv6', value: 'all_icmp_ipv6', protocol: 'icmpv6', port: 'All' },
  { name: 'All traffic', value: 'all_traffic', protocol: '-1', port: 'All' },
  { name: 'SSH', value: '22', protocol: 'tcp', port: '22' },
  { name: 'SMTP', value: '25', protocol: 'tcp', port: '25' },
  { name: 'DNS (TCP)', value: '53_tcp', protocol: 'tcp', port: '53' },
  { name: 'DNS (UCP)', value: '53_udp', protocol: 'udp', port: '53' },
  { name: 'HTTP', value: '80', protocol: 'tcp', port: '80' },
  { name: 'POP3', value: '110', protocol: 'tcp', port: '110' },
  { name: 'IMAP', value: '143', protocol: 'tcp', port: '143' },
  { name: 'LDAP', value: '389', protocol: 'tcp', port: '389' },
  { name: 'HTTPS', value: '443', protocol: 'tcp', port: '443' },
  { name: 'SMB', value: '445', protocol: 'tcp', port: '445' },
  { name: 'SMTPS', value: '465', protocol: 'tcp', port: '465' },
  { name: 'IMAPS', value: '993', protocol: 'tcp', port: '993' },
  { name: 'POP3S', value: '995', protocol: 'tcp', port: '995' },
  { name: 'MSSQL', value: '1433', protocol: 'tcp', port: '1433' },
  { name: 'NFS', value: '2049', protocol: 'tcp', port: '2049' },
  { name: 'MySQL/Aurora', value: '3306', protocol: 'tcp', port: '3306' },
  { name: 'RDP', value: '3389', protocol: 'tcp', port: '3389' },
  { name: 'Redshift', value: '5439', protocol: 'tcp', port: '5439' },
  { name: 'PostgreSQL', value: '5432', protocol: 'tcp', port: '5432' },
  { name: 'Oracle-RDS', value: '1521', protocol: 'tcp', port: '1521' },
  { name: 'WinRM-HTTP', value: '5985', protocol: 'tcp', port: '5985' },
  { name: 'WinRM-HTTPS', value: '5986', protocol: 'tcp', port: '5986' },
  { name: 'Elastic Graphics', value: '2007', protocol: 'tcp', port: '2007' },
  { name: 'CQLSH / CASSANDRA', value: '9142', protocol: 'tcp', port: '9142' },
];

export const RULE_PROTOCOL_DROPDOWN: DropdownListDataType[] = [
  { name: 'All', value: '-1' },
  { name: 'HOPOPT (0)', value: '0' },
  { name: 'ICMP (1)', value: '1' },
  { name: 'IGMP (2)', value: '2' },
  { name: 'GGP (3)', value: '3' },
  { name: 'IPv4 (4)', value: '4' },
  { name: 'ST (5)', value: '5' },
  { name: 'TCP (6)', value: '6' },
  { name: 'CBT (7)', value: '7' },
  { name: 'EGP (8)', value: '8' },
  { name: 'IGP (9)', value: '9' },
  { name: 'BBN-RCC-MON (10)', value: '10' },
  { name: 'NVP-II (11)', value: '11' },
  { name: 'PUP (12)', value: '12' },
  { name: 'ARGUS (deprecated) (13)', value: '13' },
  { name: 'EMCON (14)', value: '14' },
  { name: 'XNET (15)', value: '15' },
  { name: 'CHAOS (16)', value: '16' },
  { name: 'UDP (17)', value: '17' },
  { name: 'MUX (18)', value: '18' },
  { name: 'DCN-MEAS (19)', value: '19' },
  { name: 'HMP (20)', value: '20' },
  { name: 'PRM (21)', value: '21' },
  { name: 'XNS-IDP (22)', value: '22' },
  { name: 'TRUNK-1 (23)', value: '23' },
  { name: 'TRUNK-2 (24)', value: '24' },
  { name: 'LEAF-1 (25)', value: '25' },
  { name: 'LEAF-2 (26)', value: '26' },
  { name: 'RDP (27)', value: '27' },
  { name: 'IRTP (28)', value: '28' },
  { name: 'ISO-TP4 (29)', value: '29' },
  { name: 'NETBLT (30)', value: '30' },
  { name: 'MFE-NSP (31)', value: '31' },
  { name: 'MERIT-INP (32)', value: '32' },
  { name: 'DCCP (33)', value: '33' },
  { name: '3PC (34)', value: '34' },
  { name: 'IDPR (35)', value: '35' },
  { name: 'XTP (36)', value: '36' },
  { name: 'DDP (37)', value: '37' },
  { name: 'IDPR-CMTP (38)', value: '38' },
  { name: 'TP++ (39)', value: '39' },
  { name: 'IL (40)', value: '40' },
  { name: 'IPv6 (41)', value: '41' },
  { name: 'SDRP (42)', value: '42' },
  { name: 'IPv6-Route (43)', value: '43' },
  { name: 'IPv6-Frag (44)', value: '44' },
  { name: 'IDRP (45)', value: '45' },
  { name: 'RSVP (46)', value: '46' },
  { name: 'GRE (47)', value: '47' },
  { name: 'DSR (48)', value: '48' },
  { name: 'BNA (49)', value: '49' },
  { name: 'ESP (50)', value: '50' },
  { name: 'AH (51)', value: '51' },
  { name: 'I-NLSP (52)', value: '52' },
  { name: 'SWIPE (deprecated) (53)', value: '53' },
  { name: 'NARP (54)', value: '54' },
  { name: 'Min-IPv4 (55)', value: '55' },
  { name: 'TLSP (56)', value: '56' },
  { name: 'SKIP (57)', value: '57' },
  { name: 'IPv6-ICMP (58)', value: '58' },
  { name: 'IPv6-NoNxt (59)', value: '59' },
  { name: 'IPv6-Opts (60)', value: '60' },
  { name: ' (61)', value: '61' },
  { name: 'CFTP (62)', value: '62' },
  { name: ' (63)', value: '63' },
  { name: 'SAT-EXPAK (64)', value: '64' },
  { name: 'KRYPTOLAN (65)', value: '65' },
  { name: 'RVD (66)', value: '66' },
  { name: 'IPPC (67)', value: '67' },
  { name: ' (68)', value: '68' },
  { name: 'SAT-MON (69)', value: '69' },
  { name: 'VISA (70)', value: '70' },
  { name: 'IPCV (71)', value: '71' },
  { name: 'CPNX (72)', value: '72' },
  { name: 'CPHB (73)', value: '73' },
  { name: 'WSN (74)', value: '74' },
  { name: 'PVP (75)', value: '75' },
  { name: 'BR-SAT-MON (76)', value: '76' },
  { name: 'SUN-ND (77)', value: '77' },
  { name: 'WB-MON (78)', value: '78' },
  { name: 'WB-EXPAK (79)', value: '79' },
  { name: 'ISO-IP (80)', value: '80' },
  { name: 'VMTP (81)', value: '81' },
  { name: 'SECURE-VMTP (82)', value: '82' },
  { name: 'VINES (83)', value: '83' },
  { name: 'IPTM (84)', value: '84' },
  { name: 'NSFNET-IGP (85)', value: '85' },
  { name: 'DGP (86)', value: '86' },
  { name: 'TCF (87)', value: '87' },
  { name: 'EIGRP (88)', value: '88' },
  { name: 'OSPFIGP (89)', value: '89' },
  { name: 'Sprite-RPC (90)', value: '90' },
  { name: 'LARP (91)', value: '91' },
  { name: 'MTP (92)', value: '92' },
  { name: 'AX.25 (93)', value: '93' },
  { name: 'IPIP (94)', value: '94' },
  { name: 'MICP (deprecated) (95)', value: '95' },
  { name: 'SCC-SP (96)', value: '96' },
  { name: 'ETHERIP (97)', value: '97' },
  { name: 'ENCAP (98)', value: '98' },
  { name: ' (99)', value: '99' },
  { name: 'GMTP (100)', value: '100' },
  { name: 'IFMP (101)', value: '101' },
  { name: 'PNNI (102)', value: '102' },
  { name: 'PIM (103)', value: '103' },
  { name: 'ARIS (104)', value: '104' },
  { name: 'SCPS (105)', value: '105' },
  { name: 'QNX (106)', value: '106' },
  { name: 'A/N (107)', value: '107' },
  { name: 'IPComp (108)', value: '108' },
  { name: 'SNP (109)', value: '109' },
  { name: 'Compaq-Peer (110)', value: '110' },
  { name: 'IPX-in-IP (111)', value: '111' },
  { name: 'VRRP (112)', value: '112' },
  { name: 'PGM (113)', value: '113' },
  { name: ' (114)', value: '114' },
  { name: 'L2TP (115)', value: '115' },
  { name: 'DDX (116)', value: '116' },
  { name: 'IATP (117)', value: '117' },
  { name: 'STP (118)', value: '118' },
  { name: 'SRP (119)', value: '119' },
  { name: 'UTI (120)', value: '120' },
  { name: 'SMP (121)', value: '121' },
  { name: 'SM (deprecated) (122)', value: '122' },
  { name: 'PTP (123)', value: '123' },
  { name: 'ISIS over IPv4 (124)', value: '124' },
  { name: 'FIRE (125)', value: '125' },
  { name: 'CRTP (126)', value: '126' },
  { name: 'CRUDP (127)', value: '127' },
  { name: 'SSCOPMCE (128)', value: '128' },
  { name: 'IPLT (129)', value: '129' },
  { name: 'SPS (130)', value: '130' },
  { name: 'PIPE (131)', value: '131' },
  { name: 'SCTP (132)', value: '132' },
  { name: 'FC (133)', value: '133' },
  { name: 'RSVP-E2E-IGNORE (134)', value: '134' },
  { name: 'Mobility Header (135)', value: '135' },
  { name: 'UDPLite (136)', value: '136' },
  { name: 'MPLS-in-IP (137)', value: '137' },
  { name: 'manet (138)', value: '138' },
  { name: 'HIP (139)', value: '139' },
  { name: 'Shim6 (140)', value: '140' },
  { name: 'WESP (141)', value: '141' },
  { name: 'ROHC (142)', value: '142' },
  { name: '253 (253)', value: '253' },
  { name: '254 (254)', value: '254' },
];

export const RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN: DropdownListDataType[] = [
  { name: 'All', value: '-1' },
  { name: 'Echo Reply', value: '0' },
  { name: 'Destination Unreachable', value: '3' },
  { name: 'Source Quench', value: '4' },
  { name: 'Redirect Message', value: '5' },
  { name: 'Alternate Host Address', value: '6' },
  { name: 'Echo Request', value: '8' },
  { name: 'Router Advertisement', value: '9' },
  { name: 'Router Solicitation', value: '10' },
  { name: 'Time Exceeded', value: '11' },
  { name: 'Parameter Problem: Bad IP header', value: '12' },
  { name: 'Timestamp', value: '13' },
  { name: 'Timestamp Reply', value: '14' },
  { name: 'Information Request', value: '15' },
  { name: 'Information Reply', value: '16' },
  { name: 'Address Mask Request', value: '17' },
  { name: 'Address Mask Reply', value: '18' },
  { name: 'Traceroute', value: '30' },
  { name: 'Datagram Conversion Error', value: '31' },
  { name: 'Mobile Host Redirect', value: '32' },
  { name: 'Where Are You', value: '33' },
  { name: 'Here I Am', value: '34' },
  { name: 'Mobile Registration Request', value: '35' },
  { name: 'Mobile Registration Reply', value: '36' },
  { name: 'Domain Name Request', value: '37' },
  { name: 'Domain Name Reply', value: '38' },
  { name: 'SKIP Algorithm Discovery Protocol', value: '39' },
  { name: 'Photuris, Security Failures', value: '40' },
];

export const NETWORK_RULE_CUSTOM_ICMP_PORT_DROPDOWN: DropdownListDataType[] = [
  { name: 'All', value: '-1' },
  { name: 'Destination Network Unreachable', value: '0' },
  { name: 'Destination Host Unreachable', value: '1' },
  { name: 'Destination Protocol Unreachable', value: '2' },
  { name: 'Destination Port Unreachable', value: '3' },
  { name: 'Fragmentation Required, and DF Flag Set', value: '4' },
  { name: 'Source Route Failed', value: '5' },
  { name: 'Destination Network Unknown', value: '6' },
  { name: 'Destination Host Unknown', value: '7' },
  { name: 'Source Host Isolated', value: '8' },
  { name: 'Network Administratively Prohibited', value: '9' },
  { name: 'Host Administratively Prohibited', value: '10' },
  { name: 'Network Unreachable for TOS', value: '11' },
  { name: 'Host Unreachable for TOS', value: '12' },
  { name: 'Communication Administratively Prohibited', value: '13' },
];

export const NETWORK_RULE_CUSTOM_ICMP_PORT_RED_MES_DROPDOWN: DropdownListDataType[] = [
  { name: 'All', value: '-1' },
  { name: 'Redirect Datagram For The Network', value: '0' },
  { name: 'Redirect Datagram For The Host', value: '1' },
  { name: 'Redirect Datagram For The TOS & Network', value: '2' },
  { name: 'Redirect Datagram For The TOS & Host', value: '3' },
];

export const NETWORK_RULE_CUSTOM_ICMP_PORT_PAR_PRO_DROPDOWN: DropdownListDataType[] = [
  { name: 'All', value: '-1' },
  { name: 'Pointer Indicates The Error', value: '0' },
  { name: 'Missing A Rquited Option', value: '1' },
  { name: 'Bad Length', value: '3' },
];

export const SG_RULE_TYPE_DROPDOWN: DropdownListDataType[] = [
  { name: 'Custom TCP', value: 'custom_tcp' },
  { name: 'Custom UDP', value: 'custom_udp' },
  { name: 'Custom ICMP - IPv4', value: 'custom_icmp_ipv4' },
  { name: 'Custom Protocol', value: 'custom_protocol' },
  { name: 'All TCP', value: 'all_tcp' },
  { name: 'All UDP', value: 'all_udp' },
  { name: 'All ICMP - IPv4', value: 'all_icmp_ipv4' },
  { name: 'All ICMP - IPv6', value: 'all_icmp_ipv6' },
  { name: 'All traffic', value: 'all_traffic' },
  { name: 'SSH', value: '22' },
  { name: 'SMTP', value: '25' },
  { name: 'DNS (TCP)', value: '53_tcp' },
  { name: 'DNS (UCP)', value: '53_udp' },
  { name: 'HTTP', value: '80' },
  { name: 'POP3', value: '110' },
  { name: 'IMAP', value: '143' },
  { name: 'LDAP', value: '389' },
  { name: 'HTTPS', value: '443' },
  { name: 'SMB', value: '445' },
  { name: 'SMTPS', value: '465' },
  { name: 'IMAPS', value: '993' },
  { name: 'POP3S', value: '995' },
  { name: 'MSSQL', value: '1433' },
  { name: 'NFS', value: '2049' },
  { name: 'MySQL/Aurora', value: '3306' },
  { name: 'RDP', value: '3389' },
  { name: 'Redshift', value: '5439' },
  { name: 'PostgreSQL', value: '5432' },
  { name: 'WinRM-HTTP', value: '5985' },
  { name: 'WinRM-HTTPS', value: '5986' },
  { name: 'Elastic Graphics', value: '2007' },
  { name: 'CQLSH / CASSANDRA', value: '9142' },
];
