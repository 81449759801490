import './style.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import Table from 'components/v2/dataDisplay/Table';
import { getFormatedDateWithTimezone, randomString } from 'utils/Common';
import lazyGetAwsListMFADevices, { IAwsListMFADevicesVariables } from 'graphql/queries/getAwsListMFADevices';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';

const MFA = (props: any) => {
  const { data = {}, cloudId, region } = props;
  const { userName } = data;

  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [getAwsListMFADevices, { loading: isLoading }] = lazyGetAwsListMFADevices();

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Device type',
        field: 'deviceType',
        sort: true,
      },
      {
        label: 'Identifier',
        field: 'identifier',
        sort: true,
      },
      {
        label: 'Certifications',
        field: 'certifications',
        sort: true,
      },
      {
        label: 'Created on',
        field: 'createdOn',
        sort: true,
      },
    ];
  }, []);

  const handleGetAwsListMFADevices = (userName: string) => {
    const requestVariable: IAwsListMFADevicesVariables = {
      cloudId,
      region,
      request: {
        userName,
      },
    };

    const combinedVariable = variableCombineNextToken(requestVariable);
    return getAwsListMFADevices({ variables: combinedVariable });
  };

  const fetchData = useCallback(async () => {
    if (!userName) return;

    const { data: listMFADevices } = await handleGetAwsListMFADevices(userName);

    const totalResult = listMFADevices?.getAwsListMFADevices?.data?.map((e: any) => ({
      ...e,
      id: randomString(),
      deviceType: 'Virtual',
      identifier: e.mfaDevices?.[0]?.serialNumber,
      certifications: 'Not Applicable',
      createdOn: getFormatedDateWithTimezone(e.mfaDevices?.[0]?.enableDate),
    }));

    setMainTblRows(totalResult);
    setMainTblTotal({
      totalPage: Math.ceil(totalResult.length / tablePagination.itemPerPage),
      totalElement: totalResult.length,
    });
  }, [tablePagination, userName, cloudId, region, mainTblRows]);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [userName, mainTblTotal, tablePagination]);

  useEffect(() => {
    fetchData();
  }, [userName]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>Multi-factor authentication (MFA)</p>

        <p>
          Use MFA to increase the security of your AWS environment. Signing in with MFA requires an authentication code
          from an MFA device. Each user can have a maximum of 8 MFA devices assigned.
        </p>
      </div>
      {isLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <div className="detail-info-content">
          {!mainRowsCurrentPage.length && !isLoading ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <Table
                rows={mainRowsCurrentPage}
                columns={columns}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
                isLoading={isLoading}
                horizontalScrollable={true}
              />

              {mainRowsCurrentPage?.length && !isLoading ? (
                <div className="fleet-instance pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{mainTblTotal.totalElement}</span>
                  </p>

                  <TableManagePagination
                    ableFetchMore={false}
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page => updateTablePagination('currentPage', page)}
                    totalPage={mainTblTotal.totalPage}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MFA;
