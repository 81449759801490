import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import LabelInput from 'components/v2/LabelInput';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import Labeled from 'components/v2/atoms/Labeled';
import LabelTextarea from 'components/v2/LabelTextarea';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import ShareModal from '../../ShareModal';
import DeleteProjectModal from '../../DeleteProjectModal';
import Icon from 'components/v2/atoms/Icon';
import RedCircleFolder from 'assets/svgs/v2/ico_redcircle_folder.svg';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useAuth } from 'contexts/AuthProvider';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import updateProject from 'graphql/mutations/updateProject';
import deleteProject from 'graphql/mutations/deleteProject';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import apis from 'apis/v2';
import { useMutation } from 'react-query';
import { IUploadProfileImageReq } from 'apis/v2/File/schema';
import removeProjectMember from 'graphql/mutations/removeProjectMember';
import { Mb } from 'utils/v2/DummyData/File';
import { enumFormatter } from 'utils/Formatter';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import GreenRemoveIcon from 'assets/svgs/v4/ico_green_remove.svg';
import InputAtom from 'components/v2/atoms/InputAtom';

interface IProjectPreferenceProps {
  projectInfo: ProjectInfo;
  projectSettingOpen: boolean;
  onClose: () => void;
}

const ProjectPreference = ({
  projectInfo,
  projectSettingOpen,
  onClose
}:IProjectPreferenceProps) => {

  const navigate = useNavigate();
  const { 
    token,
    userInfo, 
    role, 
    updateToken 
  } = useAuth();
  const { 
    organizationId,
    projectList,
    editProject: outletEditProject,
    deleteProject: outletDeleteProject
  } = useOutletContext<{
    organizationId: number;
    projectList: ProjectInfo[];
    editProject: (data: ProjectInfo[]) => void,
    deleteProject: (projectId:number) => void;
  }>();

  const [project, setProject] = useState({
    id: -1,
    name: '',
    thirdPartTypeCode: '',
    attrValue1: '', /* 데이터 속성 value */
    msspYn: false,
    monitorYn: false
  });
  const [attributes, setAttributes] = useState<{key: string, value: string}[]>([{key: '', value: ''}]);

  const [imgFile, setImgFile] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState({
    delete: false,
    leave: false
  }); 
  const [thirdPartyCodeList, setThirdPartyCodeList] = useState<DropdownListDataType[]>([]); 

  const [getEnumTypeCode] = lazyGetEnumTypeCode();

  /* 프로젝트 업데이트 */
  const [editProject] = updateProject();
  /* 프로젝트 삭제 */
  const [deleteProj] = deleteProject();
  /* 프로젝트 나가기 */
  const [removePjMember] = removeProjectMember();
  /* 프로젝트썸네일 다운로드 */
  const { mutateAsync: _downloadProfileImage } = useMutation((data: {kind:string; id:number;}) => apis.File.getDownloadProfileImage(token, data));
  /* 프로젝트썸네일 업로드 */
  const { mutateAsync: _uploadProfileImage } = useMutation((data: IUploadProfileImageReq) => apis.File.uploadProfileImage(token, data));

  const isDisabled = useMemo(() => {
    if(project.monitorYn) {
      return false;
    } else {
      return true;
    }
  },[project]);

  const onClickSave = () => {
    if (projectInfo) {
      let attrValue:{[key: string]: string} = {};
      attributes.forEach(attr => {
        attrValue[attr.key] = attr.value;
      });
      // todo thirdPartTypeCode 추후 필수값 아닌걸로 변경 필요
        
      editProject({
        variables: {
          reqData: {
            organId: organizationId,
            projectId: project.id,
            name:  project.name !== projectInfo.name ? project.name : undefined,
            thirdPartTypeCode: project.thirdPartTypeCode === '' ? String(thirdPartyCodeList[0].value) : project.thirdPartTypeCode,
            ...(project.monitorYn && {
              attrKey1: 'data_attr',
              attrValue1: JSON.stringify(attrValue)
            })
          }
        }
      }).then(({ data }) => {
        if (data) {
          if (data.updateProject.result === ErrorCode.SUCCESS) {
            useToast(ErrorCode.SUCCESS, 'Project information is updated successfully.');
            onClose();
            outletEditProject(data.updateProject.data);
            updateToken(data.updateProject.data[0].userToken);
          } else {
            console.log(data.updateProject.result);
            useToast(ErrorCode.UNKNOWN, 'Update project information failed.');
          }
        } else {
          useToast(ErrorCode.UNKNOWN, 'Update project information failed.');
        }
      });
    }
  };

  const getPjThumbnail = () => {
    _downloadProfileImage({ kind: 'project', id: projectInfo.projectId })
    .then(({ data }) => {
      if (data) { setImgFile(data.data[0])} 
      else { useToast(ErrorCode.UNKNOWN, 'Get image failed.'); }}
    )
  }
  const changeAttributeData = (idx: number, key: 'key'|'value', value: string) => {
    setAttributes(prev => prev.map((d, dIdx) => {
      if(dIdx === idx) {
        return {...d, [key]: value};
      } else {
        return d;
      }
    }));
  }
  const removeAttributeData = (idx: number) => {
    setAttributes(prev => prev.filter((d, dIdx) => dIdx !== idx));
  }

  useEffect(() => {
    if(!projectSettingOpen) {
      setImgFile('')
      setProject({
        id: -1,
        name: '',
        thirdPartTypeCode: '',
        attrValue1: '', /* 데이터 속성 value */
        msspYn: false,
        monitorYn: false
      })
      setThirdPartyCodeList([])
      return;
    }

    getPjThumbnail();

    getEnumTypeCode({variables: { text: 'ThirdPartyCode' }}).then(res => {
      if (res.data) {
        setThirdPartyCodeList(res.data.getEnumTypeCode.data.map(val => ({
          name: enumFormatter(val.value),
          value: val.value
        })));
      }
    })

    setProject({
      id: projectInfo.projectId,
      name: projectInfo.name,
      thirdPartTypeCode: projectInfo.thirdPartTypeCode,
      attrValue1: projectInfo.attrValue1,
      msspYn: projectInfo.msspYn,
      monitorYn: projectInfo.monitorYn
    });
  }, [projectInfo, projectSettingOpen]);

  
  return (
    <div className="project-preference-container flex col">
      <div className='preference-wrap flex j-between'>
        <div className="pj-profile">
          <div 
          className="thumbnail-image"
          { ...(imgFile && imgFile !== '' && { 
              style: { backgroundImage: `url('${imgFile}')`, backgroundSize: 'cover' } 
            })
          }
          >
          </div>
          { (role === 'sy_admin' || role === 'pj_owner') &&
          <>
            <input
              id="read-pj-image"
              type="file"
              accept=".jpg, .png, .gif"
              onChange={(e) => {
                if (e.target.files) {
                  const file = e.target.files[0];
                  if (!file.name.toLowerCase().endsWith('jpg') && !file.name.toLowerCase().endsWith('png') && !file.name.toLowerCase().endsWith('gif')) {
                    useToast(ErrorCode.UNKNOWN, '.jpg, .png, .gif 확장자만 가능합니다.');

                    return e.target.value ='';
                  }

                  if (file.size > Mb) {
                    useToast(ErrorCode.UNKNOWN, '1MB이하 파일만 첨부해주세요.');

                    return e.target.value = '';
                  }

                  const uploadProfileImageData = {
                    file: file,
                    reqUploadProfileImage : {
                      id: projectInfo.projectId,
                      kind: 'project'
                    }
                  }
          
                  _uploadProfileImage(uploadProfileImageData).then(({data}) => {
                    if (data) {
                      if(data.result === ErrorCode.SUCCESS) {
                        getPjThumbnail();

                        useToast(ErrorCode.SUCCESS, 'Upload the project image successfully.');
                      } else {
                        if (data.result === ErrorCode.INVALID_EXTENSION) { useToast(ErrorCode.UNKNOWN, '.jpg, .png, .gif 확장자만 가능합니다.');} 
                        else {useToast(ErrorCode.UNKNOWN, 'Upload project image failed.');}
                        console.log(data.result);
                      }
                    } else {
                      useToast(ErrorCode.UNKNOWN, 'Upload project image failed.');
                    }
                  })
                }
              }} 
            />
            <label 
              htmlFor="read-pj-image" 
              className="flex a-center j-center"
            >
              <div className="add-btn" />
              Upload image
            </label>
          </>
          }
        </div> 
        <div className="content">
          <LabelInput
            title="Project name"
            required
            disabled={role === 'pj_membr'}
            placeholder="Input project name"
            value={project.name}
            onChangeValue={(str) => setProject(prev => ({
              ...prev,
              name: str
            }))}
          />
        </div>
      </div>
      <Labeled
        title="Select services"
        required={true}
      ></Labeled>
      <div>
        <label className="labeled flex a-center">
          <CheckboxAtom id='manageYn'
            checked={project.msspYn}
            onchange={() => {
              setProject((prev) => ({
                ...prev,
                msspYn: !prev.msspYn
              }))
            } 
              }
          />
          Managed security service
        </label>
        <div className='manage-security-info'>Manage cloud resources and cloud managed service.</div>
      </div>
      <label className="labeled flex a-center">
        <CheckboxAtom id='monitorYn'
          checked={project.monitorYn}
          onchange={() => {
            setProject((prev) => ({
              ...prev,
              monitorYn: !prev.monitorYn
            }))
          } 
            }
        />
          Monitoring service
      </label>
      <Labeled
        title="Data source"
        required={true}
      >
        <DropdownAtom
          disabled={isDisabled} 
          id={'data-inflow'} 
          data={thirdPartyCodeList} 
          placeholder="Select source"
          value={{
            name: thirdPartyCodeList.find(val => val.value === project.thirdPartTypeCode)?.name 
              ? thirdPartyCodeList.find(val => val.value === project.thirdPartTypeCode)?.name 
              : '',
              value: project.thirdPartTypeCode
          }} 
          handleClick={(val: DropdownListDataType) => {
            setProject((prev) => ({
              ...prev,
              thirdPartTypeCode: String(val.value)
            }))
          }
          } 
        />
      </Labeled>
      <Labeled title="Data attributes " className="attributes-box">
        <button className="add-attribute-btn flex a-center" onClick={() => setAttributes(prev => [...prev, {key: '', value: ''}])}>
          <span className="add-icon" />
          Add
        </button>
        {attributes.map((attr, attrIdx) => 
        <div className="flex attribute-group a-center" key={`attribute-data-${attrIdx}`}>
          <InputAtom
            placeholder="Key"
            value={attr.key}
            onChangeValue={(val) => changeAttributeData(attrIdx, 'key', val)}
            disabled={isDisabled} 
            noClear={true}
          />
          <InputAtom
            placeholder="Value"
            value={attr.value}
            onChangeValue={(val) => changeAttributeData(attrIdx, 'value', val)}
            disabled={isDisabled} 
            noClear={true}
          />
          {attributes.length > 1 && <Icon src={GreenRemoveIcon} width={24} height={24} onClick={() => removeAttributeData(attrIdx)}/>}
        </div>
        )}
      </Labeled>
      <div className="btns flex j-between a-end">
        {
          role !== 'pj_membr' &&
          <button 
            className="big-main-btn flex j-center a-center"
            onClick={() => { onClickSave(); }}
          >
            Update
          </button>
        }
        { (role === 'sy_admin' || role === 'pj_owner') &&
           <button 
            className="dest-btn flex j-center a-center"
            onClick={() => setModalIsOpen(prev => ({
              ...prev,
              delete: true
            }))
            }
          >
            Delete project
          </button>
        }
        {
          (role !== 'sy_admin' && role !== 'pj_owner') &&
          <button 
            className="dest-btn flex j-center a-center"
            onClick={() => setModalIsOpen(prev => ({
              ...prev,
              leave: true
            }))}
          >
            Out from project
          </button>
        }
      </div>

      <DeleteProjectModal 
        className="delete-project-modal-wrap"
        open={modalIsOpen.delete} 
        data={project.name} 
        title={() => 
          <>
            <Icon width={32} height={32} src={RedCircleFolder} />
            Are you sure you delete this project?
          </>
        } 
        closeIcon={false}
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          delete: false
        }))}
        onDelete={() => {
          const data = {
            reqDelProject: {
              projectId: project.id
            }
          };
          
          deleteProj({ variables: data }).then(({ data }) => {  
            if(data) {
              if (data.deleteProject.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, 'Deleting project has been completed.');
                setModalIsOpen(prev => ({
                  ...prev,
                  delete: false
                }));
                outletDeleteProject(project.id);
                updateToken(data.deleteProject.data[0].userToken);
                onClose();
                
              } else {
                console.log(data.deleteProject.result);
                useToast(ErrorCode.UNKNOWN, 'Deleting project failed.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, 'Deleting project failed.');
            }
          });
        }} 
      />

      <ShareModal 
        open={modalIsOpen.leave} 
        className="leave-project-modal-wrap"
        title={() => <>Are you sure you want to leave the project?</>} 
        content={() => <>If you leave a project, you will no longer be able to access it immediately.</>} 
        buttonTitle="Leave"
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          leave: false
        }))}
        onConfirm={() => {

          const removePjMemberData ={
            reqData: {
              projectId: projectInfo.projectId,
              memberIds: [String(userInfo?.nemo.memberId)]
            }
          }

          removePjMember({ variables: removePjMemberData}).then(({ data }) => {
            if (data) {
              if (data.removeProjectMember.result === ErrorCode.SUCCESS) {
                updateToken(data.removeProjectMember.data[0].userToken);
                useToast(ErrorCode.SUCCESS, 'Leaving the project has been completed.');
                setModalIsOpen(prev => ({
                  ...prev,
                  leave: false
                  }));
                  onClose();

                  if (projectList.length > 1) {
                    navigate(`/organ/${organizationId}/dashboard/overview`)
                  } else {
                    navigate('/join-organ', { replace: true });
                  }
              } else {
                console.log(data.removeProjectMember.result);
                useToast(ErrorCode.UNKNOWN, 'Leaving project failed.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, 'Leaving project failed.');
            }
          })
        }} 
      />
    </div>
  );
};

export default ProjectPreference;
