import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsGetUserResponse } from 'graphql/types/AwsGetUserResponse';

export interface IAwsUserVariables {
  cloudId: number;
  region: string;
  request: {
    userName: string;
  };
}

export interface IAwsUserResponseData {
  getAwsUser: IGqlResponseNewData<AwsGetUserResponse>;
}

const lazyGetAwsUser = () =>
  useLazyQuery<IAwsUserResponseData, IAwsUserVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsUser;
