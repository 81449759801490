import './index.scss';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import LabelInput from 'components/v2/LabelInput';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { TABLE_OFFSET } from 'utils/DummyData/Dropdown';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import Table from 'components/v2/dataDisplay/Table';
import IsLoading from 'components/v2/atoms/IsLoading';
import { enumFormatter } from 'utils/Formatter';
import { useMemo, useState } from 'react';
import { timeToKr } from 'utils/timeFormatter';

interface ICloudInformationGridProps {
  onSubmitSearch: (readyToSearch: string) => void;
  total: {
    totalPage: number;
    totalElement: number;
  }
  tablePagination: {
    limit: number;
    target: string;
    direction: OrderDirection;
    currentPage: number;
  }
  updateTablePagination: (key: string, value: number | string | OrderDirection) => void;
  rows: RowType[];
  checkedList: string[];
  reportCheckedList: (list:string[]) => void;
  reportSelected: (id: string) => void;
  openCreateModal: () => void;
  openDeleteModal: () => void;
  isLoading?: boolean;
}

const CloudInformationGrid = ({
  tablePagination,
  updateTablePagination,
  onSubmitSearch,
  total,
  rows,
  checkedList,
  reportCheckedList,
  reportSelected,
  openCreateModal,
  openDeleteModal,
  isLoading
}:ICloudInformationGridProps) => {

  const [readyToSearch, setReadyToSearch] = useState('');

  const COLUMNS:ColumnType[] = useMemo(() => {
    return [
      { 
        label: 'Name',
        field: 'name',
        sort: true
      },
      {
        label: 'Kind',
        field: 'cloudKind',
        sort: true,
        renderCell: (row: RowType) => <>{enumFormatter(row.cloudKind)}</>
      },
      {
        label: 'Delimiter',
        field: 'maskCode',
        sort: true,
      },
      {
        label: 'Update by',
        field: 'modifiedByName',
        sort: true
      },
      {
        label: 'Update at',
        field: 'modifiedAt',
        renderCell: (row:RowType) => <>{!row.modifiedAt ? '-' : timeToKr(row.modifiedAt)}</>, 
        sort: true
      }
    ];
  }, [rows]);
  
  return (
    <div className='cloud-information-grid'>
      {/* 검색영역 */}
      <form 
        className='search-wrap flex a-end'
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitSearch(readyToSearch);
        }}
      >
        <LabelInput 
          title="Search" 
          required
          placeholder='Input name, delimiter'
          value={readyToSearch}
          onChangeValue={str => setReadyToSearch(str)}
        />
        <div className="btns flex a-end">
        <button 
          type="submit"
          className='big-main-btn'
        >
          Search
        </button>
        </div>
      </form>
      {/* 테이블 데이타 그리드 영역 */}
      <div className='data-contents'>
        <div className="data-grid-wrap">
          <div className="grid-header flex j-between">
            <div className="col-1 flex a-center">
              <div className="header-icon" /> Results <span>{total.totalElement}</span>
            </div>
            <div className="col-2 flex a-center">
              { checkedList.length > 0 && `${checkedList.length} selected` }
              <button 
                className="big-sub-btn delete"
                disabled={checkedList.length === 0 }
                onClick={openDeleteModal}
              >
                Delete
              </button>
              {
                total.totalElement !== 0 &&
                <>
                <button 
                  className="big-main-btn register"
                  onClick={openCreateModal}
                >
                  Create
                </button> 
                <DropdownAtom 
                  id="offset-limit"
                  data={TABLE_OFFSET}
                  value={{ 
                    name: TABLE_OFFSET.find(data => data.value === tablePagination.limit)?.name,
                    value: Number(tablePagination.limit)
                  }}
                  handleClick={(val) => { 
                    updateTablePagination('limit', Number(val.value))
                    updateTablePagination('currentPage', 1);
                  }}
                />
                </>
              }
            </div>
          </div>
          <div className="grid-main">
            { 
              rows.length === 0 
                ? <div className="empty-rows flex j-center a-center">
                  <div className="flex col a-center">
                    <div className='empty-icon' />
                    <h5>There is no item.</h5>
                    <p>Create your cloud provider.</p>
                    <button 
                      className='big-main-btn'
                      onClick={openCreateModal}
                    >
                      Create
                    </button>
                  </div>
                </div> 
                : <>
                  <Table 
                    rows={rows} 
                    columns={COLUMNS} 
                    reportCheckedList={reportCheckedList}
                    reportSelected={reportSelected}
                    sortOption={{
                      target: tablePagination.target,
                      direction: tablePagination.direction,
                      onChangeSort: (target: string, dir: OrderDirection) => {
                        updateTablePagination('target', target);
                        updateTablePagination('direction', dir);
                      }
                    }}
                  />
                  <div className="pagination-wrapper flex a-center">
                    <p className="flex a-center">
                      Total <span>{total.totalElement}</span>
                    </p>
                    <TablePagination
                      currentPage={tablePagination.currentPage}
                      updateCurrentPage={page => updateTablePagination('currentPage', page)}
                      totalPage={total.totalPage}
                    />
                  </div>
                </>
            }
          </div>
        </div>
        { isLoading && <IsLoading dimmed={rows.length !== 0} /> }
      </div>
    </div>
  )
}
export default CloudInformationGrid;