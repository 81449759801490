import { ReactNode, useMemo } from 'react';
import './styles.scss';

export enum FlexDirection {
  ROW = 'row',
  COLUMN = 'column',
}

type BoxPropsType = {
  children?: ReactNode | Array<ReactNode>;
  direction?: FlexDirection;
  className?: string;
  center?: boolean;
  gap?: number;
};

const Box = (props: BoxPropsType) => {
  const { children, direction = FlexDirection.COLUMN, className, center, gap = 0, ...rest } = props;

  const extraStyle = useMemo(() => {
    return {
      flexDirection: direction,
      gap: `${gap}px`,
    };
  }, [direction, gap]);

  return (
    <div id="box-atom" className={`${center ? 'center' : ''} ${className}`} {...rest} style={extraStyle}>
      {children}
    </div>
  );
};

export default Box;
