import { ElasticIpDetailPropsType } from './types';
import PageDetailTitle from '../../../EC2/components/PageDetailTitle';
import { Fragment, ReactNode, useMemo, useState } from 'react';
import { TextTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import { DETAIL_KEYS, DETAIL_TAB_LIST, DETAIL_TEXT_DESCRIPTION } from './configs';
import { ColumnType, RowType } from '@Types/v2/Table';
import TagTab from '../../../EC2/components/TagTab';
import DetailTab from '../DetailTab';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdLayout';
import { IMgdTabProps } from 'layouts/v3/MgdTabLayout/types';

const ElasticIpDetail = (props: ElasticIpDetailPropsType) => {
  const { elasticIp, pageBackClick } = props;

  const [detailTabSelected, setDetailTabSelected] = useState<IMgdTabProps>(DETAIL_TAB_LIST[0]);

  const networkAclId = useMemo((): string => {
    return elasticIp?.publicIp ?? '';
  }, [elasticIp]);

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    if (elasticIp) {
      const detailsData: IMgdDetailKeyValueProps[] = [];
      const detailsDataOrdered: IMgdDetailKeyValueProps[] = [];
      Object.entries(elasticIp).map(([key, value]) => {
        if (DETAIL_KEYS.indexOf(key) > -1) {
          const textDes = DETAIL_TEXT_DESCRIPTION.find(text => text.id == key);
          let desValue = '-';
          if (typeof value === 'string') {
            desValue = value.toString();
          }
          if (textDes) {
            detailsData.push({
              id: key,
              type: textDes.type as TextTypeEnum,
              title: textDes.value,
              description: desValue,
            });
          }
        }
      });

      DETAIL_KEYS.map(key => {
        const dataByKey = detailsData.find(data => data.id == key);
        if (dataByKey) detailsDataOrdered.push(dataByKey);
      });
      return detailsDataOrdered;
    }
    return [];
  }, [elasticIp]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const tagRows = useMemo((): RowType[] => {
    return elasticIp?.tags ?? [];
  }, [elasticIp]);

  const detailContentNode = useMemo((): ReactNode => {
    switch (detailTabSelected) {
      default:
      case DETAIL_TAB_LIST[0]:
        return <TagTab title={'Tags'} rows={tagRows} columns={tagColumns} />;
    }
  }, [detailTabSelected, tagRows, tagColumns]);

  return (
    <div className="ec2-detail">
      <PageDetailTitle title={`Elactis Ip / ${networkAclId}`} pageBackClick={pageBackClick} />
      {elasticIp && (
        <Fragment>
          <DetailTab title={'Details'} data={detailTabContentData} isApiLoading={false} />
          <div className="tab-container">
            <div className="detail-tab flex a-center">
              {DETAIL_TAB_LIST.map(tab => {
                return (
                  <button
                    className={`detail-tab-items ${detailTabSelected?.id === tab.id && 'active'}`}
                    key={tab.id}
                    data-tab={tab.id}
                    onClick={e => setDetailTabSelected(tab)}
                  >
                    <p>{tab.name}</p>
                  </button>
                );
              })}
            </div>

            <div className="content-tab">{detailContentNode}</div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default ElasticIpDetail;
