import { useEffect, useMemo, useRef, useState } from 'react';
import './index.scss';
import { useAuth } from 'contexts/AuthProvider';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import { SandpackProvider } from '@codesandbox/sandpack-react';
import SandPackCode from 'components/v2/SandPackComp';
import { DummyMsgHtml } from 'utils/v2/DummyData/Html';
import addTerms from 'graphql/mutations/addTerms';
import Labeled from 'components/v2/atoms/Labeled';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import LabelInput from 'components/v2/LabelInput';
import { enumFormatter } from 'utils/Formatter';

export interface IAddTermModalProps {
  /** 모달이 켜지는 조건 */
  open: boolean;
  /** 모달이 꺼지는 함수 */
  onClose: () => void;
  /** className을 통한 외부 css 커스텀 @default 'default' */
  className?: string;
  /** 모달 바깥쪽 클릭을 통한 모달종료 @default true */
  closeOnClickBg?: boolean;
  /** 모달 애니메이션 재생시간 100ms단위, 설정범위: 양의정수 1~10 @default 3 */
  duration?: number;
  /** 모달 외부 장막의 opacity값 0.1단위, 설정범위: 양의정수 1~10 @default 3 */
  backgroundOpacity?: number;
  title: () => JSX.Element;
  onCreateSuccess: () => void; 
  termsKindList: {
    name: string;
    value: string;
  }[]
}

let timer:NodeJS.Timeout;

const  AddTermModal = ({
  open,
  onClose,
  title,
  className,
  closeOnClickBg = true,
  duration = 3,
  backgroundOpacity = 3,
  onCreateSuccess,
  termsKindList
}: IAddTermModalProps) => {

  const modalRef = useRef<HTMLDivElement>(null);
  const { userInfo } = useAuth();

  /** 모달 고유 ID */
  const [id] = useState(Date.now());

  const [payload, setPayload] = useState({
    kindCode: '',
    title: '',
    version: '',
    mandatoryYn: true, /* 필수여부 */
    useYn: true, /* 사용여부 */
  });

  /* 메세지 내용 */
  const [text, setText] = useState('');

  /* 약관 생성 */
  const [createTerms] = addTerms();

  const enableButton = useMemo(() => {
    if (
      payload.kindCode === '' ||
      payload.title === '' ||
      payload.version === '' ||
      text === ''
    ) {
      return true;
    } else return false;
  }, [payload])

  useEffect(() => {
    if (open) {
      document.body.setAttribute(`modal-id-${id}`, 'true');
      modalRef.current?.classList.remove('out', 'active');
      modalRef.current?.classList.add('active');

      clearTimeout(timer);
    } else {
      modalRef.current?.classList.add('out');
      document.body.removeAttribute(`modal-id-${id}`);

      timer = setTimeout(() => {
        setPayload({
          kindCode: '',
          title: '',
          version: '',
          mandatoryYn: true, /* 필수여부 */
          useYn: true, /* 사용여부 */
        });
        setText('');
      }, 3000);
    }
  }, [open, userInfo]);

    return (
      <div
        ref={modalRef}
        className="add-term-modal-container modal-duration-5"
        data-duration={duration}
        data-bg-opacity={backgroundOpacity}
      >
      <div 
        className="modal-background" 
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          if (!closeOnClickBg) return;
          if (e.target === e.currentTarget) onClose && onClose();
        }}
      >
        <div className={`add-term-modal ${className} flex`}>
          <div className="col-left">
            <div className="modal-head flex j-between a-center">
              <div className="modal-title flex a-center">{title()}</div>
              <button className="close-btn" onClick={onClose} />
            </div>
              <div className="contents-wrap flex col">
                <LabelInput 
                  title="Name"
                  value={payload.title}
                  placeholder="Input name"
                  required
                  onChangeValue={(val) => setPayload((prev) => ({
                    ...prev,
                    title: val
                  }))}
                />
                <Labeled 
                  title="Kind"
                  required
                >
                <DropdownAtom 
                  id="kind-code"
                  placeholder='Select terms kind'
                  data={termsKindList.map(val => ({ name: enumFormatter(val.value), value: val.value }))} 
                  value={{
                    name: termsKindList.find(val => val.value === payload.kindCode)
                      ? enumFormatter(String(termsKindList.find(val => val.value === payload.kindCode)?.value))
                      : ''
                    ,
                    value: payload.kindCode
                  }} 
                  handleClick={(val) => setPayload(prev => ({
                    ...prev,
                    kindCode: String(val.value)
                  }))}    
                />
              </Labeled>
              <LabelInput 
                title="Version"
                value={payload.version}
                placeholder="Input version"
                required
                onChangeValue={(val) => {
                  if (val.length > 10) return;
                  
                  setPayload((prev) => ({
                    ...prev,
                    version: val
                  }))

              }}
              />
              <Labeled
                title='Mandatory'
                required
              >
                <div className='radio-wrap flex a-center'>
                  <div className='flex a-center'>
                    <input 
                      type="radio" 
                      id="mandatory-rd1" 
                      name="mandatory" 
                      checked={payload.mandatoryYn} 
                      onChange={() => setPayload(prev => ({
                        ...prev,
                        mandatoryYn: true
                      }))}
                    />
                    <label 
                      htmlFor="mandatory-rd1" 
                      className={`mandatory-raido ${payload.mandatoryYn && 'checked'}`}
                    />
                    Yes 
                  </div>
                  <div className='flex a-center'>
                    <input 
                      type="radio" 
                      id="mandatory-rd2" 
                      name="mandatory" 
                      checked={!payload.mandatoryYn} 
                      onChange={() => setPayload(prev => ({
                        ...prev,
                        mandatoryYn: false
                      }))}
                    />
                    <label 
                      htmlFor="mandatory-rd2" 
                      className={`mandatory-raido ${!payload.mandatoryYn && 'checked'}`}
                    />
                    No
                  </div>
                </div>
              </Labeled>
              <Labeled
                title='Activated'
                required
              >
                <div className='radio-wrap flex a-center'>
                  <div className='flex a-center'>
                    <input 
                      type="radio" 
                      id="useYn-rd1" 
                      name="useYn" 
                      checked={payload.useYn} 
                      onChange={() => setPayload(prev => ({
                        ...prev,
                        useYn: true
                      }))}
                    />
                    <label 
                      htmlFor="useYn-rd1" 
                      className={`useYn-raido ${payload.useYn && 'checked'}`}
                    />
                    Yes
                  </div>
                  <div className='flex a-center'>
                    <input 
                      type="radio" 
                      id="useYn-rd2" 
                      name="useYn" 
                      checked={!payload.useYn} 
                      onChange={() => setPayload(prev => ({
                        ...prev,
                        useYn: false
                      }))}
                    />
                    <label 
                      htmlFor="useYn-rd2" 
                      className={`useYn-raido ${!payload.useYn && 'checked'}`}
                    />
                    No
                  </div>
                </div>
              </Labeled>
              {/* <LabelTextarea 
                title="약관 본문"
                placeholder='약관 본문 입력'
                required
                value={text}
                onChangeValue={str => setPayload(prev => ({
                  ...prev,
                  text: str
                }))}
              /> */}
              </div>
              <div className="btns flex a-center">
              <button 
                className="big-main-btn flex j-center a-center"  
                disabled={enableButton}
                onClick={() => {

                  const addTermsData = {
                    reqData: {
                      ...payload,
                      text: text
                    }
                  }

                  createTerms({ variables: addTermsData }).then(({ data }) => {
                    if (data) {
                      if (data.addTerms.result === ErrorCode.SUCCESS) {
                        useToast(ErrorCode.SUCCESS, 'Terms is created successfully.');
                        onCreateSuccess();
                      } else {
                        console.log(data.addTerms.result);
                        useToast(ErrorCode.UNKNOWN, 'Create terms failed.');
                      }
                    } else {
                      useToast(ErrorCode.UNKNOWN, 'Create terms failed.');
                    }
                  });
                }}
              >
                Create
              </button>
              </div>
          </div>
          <div className="col-right"> 
            <div>
              <div className="sandpack-label">
                <div><span>Html</span></div>
                <div><span>Preview</span></div>
              </div>
              { open &&
                <SandpackProvider template="static"> 
                  <SandPackCode value={DummyMsgHtml} onChangeText={(val:string) => setText(val)} />
                </SandpackProvider>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTermModal;
