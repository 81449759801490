import { DropdownListDataType } from 'components/Dropdown/types';
import {
  NETWORK_RULE_CUSTOM_ICMP_PORT_DROPDOWN,
  NETWORK_RULE_CUSTOM_ICMP_PORT_PAR_PRO_DROPDOWN,
  NETWORK_RULE_CUSTOM_ICMP_PORT_RED_MES_DROPDOWN,
  NETWORK_RULES_ICMP_CODE_MAPPING,
  NETWORK_RULES_ICMP_CODE_PAR_PRO_MAPPING,
  NETWORK_RULES_ICMP_CODE_RED_MES_MAPPING,
  NETWORK_RULES_ICMP_TYPE_MAPPING,
  RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN,
  RULE_PROTOCOL_DROPDOWN,
  RULE_TYPE_DROPDOWN,
  SECURITY_GROUP_RULES_PROTOCOL_MAPPING,
  SECURITY_GROUP_RULES_TYPE_MAPPING,
} from '../Constant';

const handleGetProtocolDataByType = (type: string) => {
  let data = RULE_PROTOCOL_DROPDOWN;
  if (type === 'custom_icmp_ipv4') {
    data = RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN;
  }
  return data;
};

const handleGetPortRangeDataByType = (type: string, protocol: string) => {
  let data: DropdownListDataType[] = [
    {
      name: '',
      value: '',
    },
  ];

  if (type === RULE_TYPE_DROPDOWN[2].value) {
    if (protocol === RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN[2].value) {
      data = NETWORK_RULE_CUSTOM_ICMP_PORT_DROPDOWN;
    }
    if (protocol === RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN[4].value) {
      data = NETWORK_RULE_CUSTOM_ICMP_PORT_RED_MES_DROPDOWN;
    }
    if (protocol === RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN[10].value) {
      data = NETWORK_RULE_CUSTOM_ICMP_PORT_PAR_PRO_DROPDOWN;
    }
  }

  return data;
};

const handleGetTypeItem = (type: string) => {
  const item = RULE_TYPE_DROPDOWN.find(e => e.value === type);

  return item;
};

const handleGetProtocol = (type: string) => {
  switch (type) {
    case RULE_TYPE_DROPDOWN[2].value:
      return '-1';

    case RULE_TYPE_DROPDOWN[3].value:
      return '';

    case RULE_TYPE_DROPDOWN[7].value:
      return 'IPv6 ICMP';

    case RULE_TYPE_DROPDOWN[8].value:
      return 'All';

    default:
      const item = handleGetTypeItem(type);
      return item?.protocol?.toUpperCase() || '';
  }
};

const handleGetPortRange = (type: string, protocol?: string) => {
  if (type === RULE_TYPE_DROPDOWN[2].value && protocol) {
    let portRange = '';

    if (protocol === '11') {
      portRange = 'All';
    } else if (protocol === '3' || protocol === '5' || protocol === '12') {
      portRange = '-1';
    } else {
      portRange = 'N/A';
    }

    return portRange;
  }

  if (!protocol) {
    const item = RULE_TYPE_DROPDOWN.find(e => e.value === type);

    return item?.port || '0';
  }

  return 'All';
};

const handleGetFromPort = (type: string, protocol: string, portRange: string) => {
  switch (type) {
    case RULE_TYPE_DROPDOWN[0].value:
    case RULE_TYPE_DROPDOWN[1].value:
      return parseInt(portRange?.split('-')?.[0]) || 0;

    case RULE_TYPE_DROPDOWN[2].value:
      return parseInt(protocol) || 0;

    case RULE_TYPE_DROPDOWN[3].value:
    case RULE_TYPE_DROPDOWN[4].value:
    case RULE_TYPE_DROPDOWN[5].value:
      return 0;
    case RULE_TYPE_DROPDOWN[6].value:
    case RULE_TYPE_DROPDOWN[7].value:
    case RULE_TYPE_DROPDOWN[8].value:
      return -1;

    default:
      const item = handleGetTypeItem(type);
      return parseInt(item?.port) || -1;
  }
};

const handleGetToPort = (type: string, portRange: string) => {
  switch (type) {
    case RULE_TYPE_DROPDOWN[0].value:
    case RULE_TYPE_DROPDOWN[1].value:
      return parseInt(portRange?.split('-')?.[2]) || parseInt(portRange?.split('-')?.[1]) || 0;

    case RULE_TYPE_DROPDOWN[2].value:
      return parseInt(portRange) || -1;

    case RULE_TYPE_DROPDOWN[3].value:
      return 0;

    case RULE_TYPE_DROPDOWN[4].value:
    case RULE_TYPE_DROPDOWN[5].value:
      return 65535;

    case RULE_TYPE_DROPDOWN[6].value:
    case RULE_TYPE_DROPDOWN[7].value:
    case RULE_TYPE_DROPDOWN[8].value:
      return -1;

    default:
      const item = handleGetTypeItem(type);
      return parseInt(item?.port) || -1;
  }
};

const handleConvertSecurityGroupRule = (protocol: string, fromPort: number, toPort: number) => {
  const protocolToInt = parseInt(protocol);
  if (protocolToInt) {
    if (protocolToInt === -1) {
      return {
        typeName: RULE_TYPE_DROPDOWN[8].name,
        typeValue: RULE_TYPE_DROPDOWN[8].value,
        protocol: 'All',
        portRange: 'All',
      };
    }

    return {
      typeName: RULE_TYPE_DROPDOWN[3].name,
      typeValue: RULE_TYPE_DROPDOWN[3].value,
      protocol,
      portRange: 'All',
    };
  }

  if (fromPort === toPort) {
    if (fromPort === -1) {
      const item = RULE_TYPE_DROPDOWN.find(e => e.protocol === protocol);

      return {
        typeName: item?.name,
        typeValue: item?.value,
        protocol: protocol === RULE_TYPE_DROPDOWN[6].protocol ? protocol?.toUpperCase() : 'IPv6 ICMP',
        portRange: 'All',
      };
    }

    const item = RULE_TYPE_DROPDOWN.find(e => e.protocol === protocol && e.port === fromPort?.toString());

    return {
      typeName: item?.name,
      typeValue: item?.value,
      protocol: protocol?.toUpperCase(),
      portRange: fromPort,
    };
  }

  return {
    typeName: '',
    typeValue: '',
    protocol: '',
    portRange: '',
  };
};

const handleConvertProtocol = (type: string, protocol: string, portRange: string) => {
  const typeItem = handleGetTypeItem(type);

  switch (typeItem?.protocol) {
    case 'tcp':
      return 'TCP';

    case 'udp':
      return 'UDP';

    case 'all_traffic':
      return 'All';

    case 'icmp':
      switch (typeItem?.value) {
        case 'custom_icmp_ipv4':
          const protocolItem = RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN.find(e => e.name === protocol);
          return protocolItem?.value;

        default:
          return protocol;
      }

    default:
      return protocol;
  }
};

const handleConvertPortRange = (type: string, protocol: string, portRange: string) => {
  const typeItem = handleGetTypeItem(type);

  switch (typeItem?.value) {
    case 'custom_icmp_ipv4':
      const protocolItem = RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN.find(e => e.name === protocol);

      switch (protocolItem?.value) {
        case RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN[2].value:
          const portRangeDestinationUnreachable = NETWORK_RULE_CUSTOM_ICMP_PORT_DROPDOWN.find(e => e.name === portRange)
          return portRangeDestinationUnreachable?.value;

        case RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN[4].value:
          const portRangeRedirectMessage = NETWORK_RULE_CUSTOM_ICMP_PORT_RED_MES_DROPDOWN.find(e => e.name === portRange)
          return portRangeRedirectMessage?.value;

        case RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN[10].value:
          const portRangeParameterProblem = NETWORK_RULE_CUSTOM_ICMP_PORT_RED_MES_DROPDOWN.find(e => e.name === portRange)
          return portRangeParameterProblem?.value;

        default:
          return portRange;
      }

    default:
      return portRange;
  }
};

const handleGetSecurityGroupRuleProtocolValue = (type: string, fromPort: number) => {
  let securityGroupRuleProtocol;
  if (type == 'custom_tcp' || type == 'all_tcp' || type == '22' || type == '25' || type == '53_tcp') {
    securityGroupRuleProtocol = 'TCP';
  }
  if (type == 'custom_udp' || type == 'all_udp' || type == '53_udp') {
    securityGroupRuleProtocol = 'UDP';
  }
  if (type == 'all_icmp_ipv4') {
    securityGroupRuleProtocol = 'ICMP';
  }
  if (type == 'all_icmp_ipv6') {
    securityGroupRuleProtocol = 'IPv6 ICMP';
  }
  if (type == 'all_traffic') {
    securityGroupRuleProtocol = 'All';
  }
  if (type == 'custom_icmp_ipv4') {
    securityGroupRuleProtocol = SECURITY_GROUP_RULES_PROTOCOL_MAPPING[fromPort].protocol;
  }
  if (type == 'custom_protocol') {
    if (fromPort == -1) {
      securityGroupRuleProtocol = 'All (-1)';
    }
    if (fromPort == 1) {
      securityGroupRuleProtocol = 'ICMP (1)';
    }
    if (fromPort == 6) {
      securityGroupRuleProtocol = 'TCP (6)';
    }
    if (fromPort == 17) {
      securityGroupRuleProtocol = 'UDP (17)';
    }
  }
  if (!securityGroupRuleProtocol) {
    securityGroupRuleProtocol = `${SECURITY_GROUP_RULES_TYPE_MAPPING[type]?.type || ''} (${fromPort})`;
  }
  return securityGroupRuleProtocol;
};

const handleGetSecurityGroupPortRange = (from: number, to: number) => {
  let portRange;
  if (to > 0 && ((from == 0 && to) || (from && to))) {
    portRange = `${from} - ${to}`;
  }
  if (from == 0 && to == 65535) {
    portRange = '0 - 65535';
  }
  if (from == to && from != 0) {
    portRange = from;
    if (from == -1) {
      portRange = 'All';
    }
  }
  if (from == 3) {
    portRange = NETWORK_RULES_ICMP_CODE_MAPPING[to].icmpCode;
  }
  if (from == 5) {
    portRange = NETWORK_RULES_ICMP_CODE_RED_MES_MAPPING[to].icmpCode;
  }
  if (from == 12) {
    portRange = NETWORK_RULES_ICMP_CODE_PAR_PRO_MAPPING[to].icmpCode;
  }
  if (from == to && from == 0) {
    portRange = '-';
  }

  return portRange || 'N/A';
};

const handleGetSecurityGroupRuleTypeValue = (fromPort: number, toPort: number, ipProtocol: string) => {
  let securityGroupRuleType;
  if (ipProtocol == '-1' && fromPort == -1 && toPort == -1) {
    securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['all_traffic'];
  }
  if (ipProtocol == 'tcp') {
    if (fromPort == 0 && toPort == 65535) {
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['all_tcp'];
    } else if (fromPort != toPort || (fromPort == toPort && !SECURITY_GROUP_RULES_TYPE_MAPPING[fromPort])) {
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['custom_tcp'];
    }
  }
  if (ipProtocol == 'udp') {
    if (fromPort == 0 && toPort == 65535) {
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['all_udp'];
    } else if (fromPort != toPort || (fromPort == toPort && !SECURITY_GROUP_RULES_TYPE_MAPPING[fromPort])) {
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['custom_udp'];
    }
  }
  if (ipProtocol == 'icmp') {
    if (fromPort == 0 && toPort == 0) {
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['custom_protocol'];
    }
    if (fromPort == -1 && toPort == -1) {
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['all_icmp_ipv4'];
    } else if (NETWORK_RULES_ICMP_TYPE_MAPPING[fromPort] && NETWORK_RULES_ICMP_CODE_MAPPING[toPort]) {
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['custom_icmp_ipv4'];
    }
  }
  if (ipProtocol == 'icmpv6') {
    if (fromPort == -1 && toPort == -1) {
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['all_icmp_ipv6'];
    }
  }
  if (!securityGroupRuleType && fromPort == toPort) {
    if (fromPort == 53) {
      if (ipProtocol == 'tcp') {
        securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['53_tcp'];
      }
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['53_udp'];
    }
    securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING[fromPort];
  }

  if (fromPort == toPort && fromPort == 53) {
    if (ipProtocol == 'tcp') {
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['53_tcp'];
    } else {
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['53_udp'];
    }
  }
  const protocol = handleGetSecurityGroupRuleProtocolValue(securityGroupRuleType?.key || '', fromPort);
  const portRange = handleGetSecurityGroupPortRange(fromPort, toPort);
  return {
    type: securityGroupRuleType?.type || ipProtocol,
    protocol: securityGroupRuleType?.type ? protocol : ipProtocol,
    portRange,
  };
};

export {
  handleGetTypeItem,
  handleGetProtocolDataByType,
  handleGetPortRangeDataByType,
  handleGetProtocol,
  handleGetPortRange,
  handleGetFromPort,
  handleGetToPort,
  handleConvertSecurityGroupRule,
  handleConvertProtocol,
  handleConvertPortRange,
  handleGetSecurityGroupRuleTypeValue,
};
