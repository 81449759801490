import './index.scss';

const FooterComponent = () => {
  return (
    <div className='footer-container flex j-between a-center'> 
        <div className="foot-info flex j-between">
          <div className='foot-logo' />
          <div className='desc'>
            <p>Address: Building E13 and E14, LG Science park, 71, Magokjungang 8-ro, Gangseo-gu, Seoul, Republic of Korea</p>
            <p>Contact: curidy@lgcns.com</p>
          </div>
        </div>
        <div className='foot-links flex col a-end'>
          <div className='flex j-between a-center'>
            <a
              href="/terms/secure"
              target="_blank"
            >
              Privacy policy
            </a>
            <a 
              href="/terms"
              target="_blank"
            >
              Terms of use
            </a>
          </div>
          © 2023 LG CNS, Inc.
        </div>
      </div>
  )
}
export default FooterComponent;