import { useEffect, useMemo, useState } from 'react';
import { OrderDirection, RowType } from '@Types/v2/Table';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import Icon from 'components/v2/atoms/Icon';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import DictionaryDetail from './components/DictionaryDetail';
import CreateDictionaryModal from 'components/v2/modals/CreateDictionaryModal';
import DeleteDictionaryModal from 'components/v2/modals/DeleteDictionaryModal';
import DictionaryGrid from './components/DictionaryGrid';
import DictCircle from 'assets/images/v2/ico_redcircle_dictionary.png';
import lazyGetDictionaryPageByParam from 'graphql/queries/getDictionaryPageByParam';
import deleteDictionary from 'graphql/mutations/deleteDictionary';


const Dictionary = () => {
  const [modalIsOpen, setModalIsOpen] = useState({
    create: false,
    delete: false
  }); 
  
  const [isShowing, setIsShowing] = useState<'DATA_GRID' | 'DETAIL'>('DATA_GRID');
  /* 사용종류 리스트 */
  const [relObjTypeList, setRelObjTypeList] = useState<{name: string; value: string}[]>([]);

  const [search, setSearch] = useState({
    relObjectType: 'all',
    searchText: ''
  });
  const [total, setTotal] = useState({
    totalPage: 0,
    totalElement: 0
  });
  const [rows, setRows] = useState<RowType[]>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');

  /* 사용종류 조회 */
  const [getEnumTypeCode] = lazyGetEnumTypeCode();
  /* 딕셔너리 조회 */
  const [getDictionaryPageByParam, { loading: loadingGetDictionary }] = lazyGetDictionaryPageByParam();
  /* 딕셔너리 삭제 */
  const [delDictionary] = deleteDictionary();

  const getDictionaryData = useMemo(() => ({
    reqData: {
      relObjectType: search.relObjectType === 'all' ? undefined : search.relObjectType, /* 사용종류 */
      text: search.searchText === '' ? undefined : search.searchText,
      pageInfo: {
        page: tablePagination.currentPage - 1,
        size: tablePagination.limit,
        orders: [{
          target: tablePagination.target === 'relObjectName' ? 'relObjectId' : tablePagination.target, 
          direction: tablePagination.direction 
        }]
      }
    }
  }), [
    tablePagination,
    search,
    isShowing,
  ]);

  const renderShowing = useMemo(() => {
    switch (isShowing) {
      default:
        return <DictionaryGrid
          relObjTypeList={relObjTypeList}
          onSubmitSearch={(readyToSearch: {
            relObjectType: string;
            searchText: string;
          }) => { 
            setSearch({ ...readyToSearch })
            setTablePagination(prev => ({
              ...prev,
              currentPage: 1
            }));
          }}
          tablePagination={tablePagination}
          updateTablePagination={(key:string, value: number | string | OrderDirection) => setTablePagination(prev => ({
            ...prev,
            [key]: value
          }))}
          total={total}
          rows={rows}
          checkedList={checkedList}
          reportCheckedList={(list:string[]) => setCheckedList(list)}
          reportSelected={(id:string) => {
            setSelected(id);
            setIsShowing('DETAIL');
          }}
          openCreateModal={() => setModalIsOpen(prev => ({
            ...prev,
            create: true
          }))}
          openDeleteModal={() => setModalIsOpen(prev => ({
            ...prev,
            delete: true
          }))}
          isLoading={loadingGetDictionary}
        />
      case 'DETAIL': 
        return <DictionaryDetail
          relObjTypeList={relObjTypeList}
          data={rows.find(val => val.id === Number(selected)) as RowType}
          convertGridIsShowing={() => {
            setSelected('');
            setIsShowing('DATA_GRID');
          }}
          openDeleteModal={() =>  {
            setCheckedList([selected]);
            setModalIsOpen(prev => ({
              ...prev,
              delete: true
            }))

         }}    
          onEditSuccess={() => {
            getDictionaryRows();  
          }}
        />
    }
  }, [
    tablePagination,
    isShowing, 
    rows, 
    total,
    checkedList, 
    selected,
    relObjTypeList,
    loadingGetDictionary
  ])

  const getDictionaryRows = () => {
    getDictionaryPageByParam({ variables: getDictionaryData}).then(({ data }) => {
      if (data) {
        setRows(data.getDictionaryPageByParam.content);
        setTotal({
          totalPage: data.getDictionaryPageByParam.totalPages,
          totalElement: data.getDictionaryPageByParam.totalElements
        })
      }
    })
  }

  /* 딕셔너리 rows 받기 */
  useEffect(() => {
    getDictionaryRows();
  }, [getDictionaryData]);


  useEffect(() => {
    getEnumTypeCode({ variables: { text: 'DictRelObjectType'}}).then(({ data }) => {
      if (data) {
        if (data.getEnumTypeCode.data) {
          
          setRelObjTypeList(data.getEnumTypeCode.data.map(val => ({
            name: val.name,
            value: val.value
          })))
        }
      }
    })
  }, [])

  return (
    <div className='dictionary'>
      {renderShowing}

      <CreateDictionaryModal
        open={modalIsOpen.create}
        relObjTypeList={relObjTypeList}
        title={() => <>
          <Icon width={32} height={32} src={DictCircle} />
          Create dictionary
        </>}
        onCreateSuccess={() => {
          setModalIsOpen(prev => ({
            ...prev,
            create: false
          }))
          getDictionaryRows();
        }}

        onClose={() => {
          setModalIsOpen(prev => ({
            ...prev,
            create: false
          }));
        }}  
      />

      <DeleteDictionaryModal 
        open={modalIsOpen.delete} 
        data={rows.filter(val => checkedList.includes(String(val.id)))} 
        title={() => 
          <>
            <Icon width={32} height={32} src={DictCircle}  />
            Delete dictionaries
          </>
        } 
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          delete: false
        }))}
        onDelete={() => {
          const deleteDictionaryData =  {
            reqData: {
              ids: checkedList.map(val => Number(val))
            }
          }

          delDictionary({ variables: deleteDictionaryData }).then(({ data }) => {
            if (data) {
              if(data.deleteDictionary.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, 'Dictionary is deleted successfully.');
                setModalIsOpen(prev => ({
                  ...prev,
                  delete: false
                }))

                if (isShowing === 'DETAIL') {
                  setIsShowing('DATA_GRID');
                }

                if ((rows.length === checkedList.length) && total.totalPage !== 1) {
                  setTablePagination(prev => ({
                    ...prev,
                    currentPage: tablePagination.currentPage - 1
                  }));
                } else {
                  getDictionaryRows();
                }

                setCheckedList([]);
              } else {
                console.log(data.deleteDictionary.result);
                useToast(ErrorCode.UNKNOWN, 'Delete dictionary failed.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, 'Delete dictionary failed.');
            }
          })
        }} 
      />
    </div>
  );
}
export default Dictionary;