import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { AwsTagType } from 'graphql/types/AwsVpc';

export interface ICreateAwsTagVariables {
  cloudId: number;
  region: string;
  request: {
    dryRun?: boolean;
    resources: string[];
    tags: AwsTagType[];
  }
}

export interface ICreateAwsTagResponseData {
  createAwsTags: IGqlResponseData<undefined>;
}

const createAwsTagsMutation = () => useMutation<ICreateAwsTagResponseData, ICreateAwsTagVariables>(query);
export default createAwsTagsMutation;