import { useAuth } from 'contexts/AuthProvider';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.scss';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';

const InviteProject = () => {
  const { role, userInfo, loggedIn, logout } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state) {
      useToast(ErrorCode.UNKNOWN, 'This is an unauthorized access.');
      navigate('/auth');
    }
  }, [state])

  return (
    <main id="invite-project-page">
      <div className="bg" />
      <section>
        <h4>Already belong to an organization.</h4>
        <h5>
        You can only join in one organization. To join in another organization's project, you must leave your current organization.
        </h5>
        <button 
          className="big-main-btn confirm-btn"
          onClick={() => {
            if (!loggedIn) logout();
            
            /* 지금 로그인 한 유저의 role상태에 따라 페이지 이동 */
            if (!userInfo?.nemo.organId) { navigate('/join-organ'); } 
            else { navigate(`/organ/${userInfo?.nemo.organId}/dashboard/overview`); }
          }}
        >
          Done
        </button>
      </section>
    </main>);
};
export default InviteProject;