import { RowType } from '@Types/v2/Table';
import './index.scss';
import { useEffect, useMemo, useState } from 'react';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import InputAtom from 'components/v2/atoms/InputAtom';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import CurrentStatusCard from 'pages/v2/Organ/Report/components/CurrentStatusCard';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import lazyGetMyEventById from 'graphql/queries/getMyEvent';
import SearchDropdown from 'components/v2/SearchDropdown';
import lazySearchEvent from 'graphql/queries/searchEvent';
import updateEventSetting from 'graphql/mutations/updateEventSetting';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import lazySearchMyEventMessage from 'graphql/queries/searchMyEventMessage';
import prettify from 'html-prettify';

interface IEventSettingDetailProps {
  pjList: {
    name: string;
    value: number;
  }[];
  refetchPjList: () => void;
  priorityTypeList: {
    name: string;
    value: string;
  }[]
  msgReceiveTypeList: {
    name: string;
    value: string;
  }[]
  data: RowType;
  convertGridIsShowing: () => void;
  openDeleteModal: () => void;
  onEditSuccess: () => void;
}

const EventSettingDetail = ({
  pjList,
  refetchPjList,
  priorityTypeList,
  data,
  msgReceiveTypeList,
  convertGridIsShowing,
  openDeleteModal,
  onEditSuccess,
}: IEventSettingDetailProps) => {

  const { tooltip } = useGModal();

  const [edit, setEdit] = useState(false);
  const [detail, setDetail] = useState({
    eventId: -1,
    eventRule: '',
    settingId: -1,
    projectName: '',
    projectId: -1,
    name: '', /* 이벤트설정이름 */
    priorityCode: '',
    msgSenderTypeCode: '',
    msgId: -1,
    messageText: '' /* 메세지 */
  });
  const [confirmEventRule, setConfirmEventRule] = useState(false);
  const [search, setSearch] = useState('');
  const [eventRuleList, setEventRuleList] = useState<{name: string; value: number}[]>([]);
  const [msgTextTypeList, setMsgTextTypeList] = useState<{name: string; value: number}[]>([]);
  
  const [lazyGetEventById] = lazyGetMyEventById();
  /* 이벤트 규칙 검색어에 따른 이벤트규칙리스트 조회 */
  const [lazySearchEventList] = lazySearchEvent();
  /* 수신 타입에 따른 메시지 조회 */
  const [lazyEventMessageList] = lazySearchMyEventMessage();
  /* 이벤트 설정 수정변경 요청 */
  const [editEventSetting] = updateEventSetting();

  const origin = useMemo(() => {
    return {
      eventId: data.eventId, /* 이벤트규칙 id */
      eventRule: '', /* 이벤트규칙 name */
      settingId: data.settingId,
      projectName: data.projectName,
      projectId: data.projectId,
      name: data.name, /* 이벤트 설정 이름 */
      priorityCode: data.priorityCode,
      msgSenderTypeCode: data.msgSenderTypeCode ?  data.msgSenderTypeCode : '-',
      msgId: data.messageId,
      messageText: data.messageText ? data.messageText :'-'
    }
  }, []);

  const htmlPre = useMemo(() => {
    try {
      const html = prettify(data.messageText);

      if (html === '') {
        return data.messageText;
      } else {
        return html;
      }
    } catch {
      return data.messageText;
    }
  },[data]);

  const enableEditEventSettingDetail = useMemo(() => {
    if (
      detail.projectId === -1 ||
      detail.name === '' ||
      detail.eventId === -1 ||
      detail.priorityCode === '' ||
      detail.msgSenderTypeCode === '' ||
      detail.messageText === '' 
    ) {
      return true;
    } else return false;
  }, [detail]);

  const getEventList = (str:string) =>  {
    lazySearchEventList({ variables: { text: str } }).then(({ data }) => {
      if (data) {
        setEventRuleList(data.searchEvent.data.map(val => ({
          name: val.name,
          value: val.eventId
        })));
      }
    });
  };

  const getEventMessageList = (sel: string) => {
    lazyEventMessageList({variables: { sendTypeCode: sel }}).then(({data}) => {
      if (data) {
        if (data.getEventMessagesByType.data) {
          setMsgTextTypeList(data.getEventMessagesByType.data.map(val => ({
            name: val.title,
            value: val.id
          })));
        }
      }
    })
  }

  useEffect(() => {
    refetchPjList();
    getEventMessageList(data.msgSenderTypeCode);
    lazyGetEventById({variables : { eventId: data.eventId } }).then(({ data }) => {
      if(data) {
        if (data.getEventById.data) {
          setDetail(prev => ({
            ...prev,
            eventRule: data.getEventById.data[0].name
          }))
          setSearch(data.getEventById.data[0].name);
          getEventList(data.getEventById.data[0].name);
        }
      }
    })
    setConfirmEventRule(false);
    setDetail(prev => ({
      ...prev,
      eventId: data.eventId, 
      eventRule: '', 
      settingId: data.settingId,
      projectName: data.projectName,
      projectId: data.projectId,
      name: data.name,
      priorityCode: data.priorityCode,
      msgSenderTypeCode: data.msgSenderTypeCode ?  data.msgSenderTypeCode : '-',
      msgId: data.messageId,
      messageText: data.messageText ? data.messageText :'-'
    }));
  }, []);

  return (
    <div className='eventSetting-detail'>
      <div className='eventSetting-detail-header flex a-center'>
        <div className='back-btn' 
          onClick={convertGridIsShowing}
        />  
        {!edit ? data?.name : 'Edit event setting'}
      </div>
      <div className='detail-contents'>
        <InfoCard
          title="Name" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.name
                : <InputAtom
                  value={detail.name}
                  onChangeValue={(val) => setDetail((prev) => ({
                    ...prev,
                    name: val
                  }))}
                />
              }
            </>
          } 
        />
      <InfoCard
          title="Project" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.projectName
                : <DropdownAtom 
                  id="project-name" 
                  data={pjList} 
                  value={{
                  name: detail.projectName,
                  value: detail.projectId
                  }}
                  handleClick={(val) => {
                    setDetail(prev => ({
                      ...prev,
                      projectId: Number(val.value),
                      projectName: String(val.name)
                    }))
                  }}
               />
              }
            </>
          } 
        />
        <InfoCard
          title="Event-rule" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.eventRule
                : <SearchDropdown 
                  id="search-event-rule"
                  placeholder='Select event-rule'
                  data={eventRuleList}
                  searchValue={search}
                  isConfirmed={confirmEventRule}
                  onChangeSearch={(str) => {
                    setSearch(str);
                    getEventList(str);
                  }}
                  handleClick={(val) => {
                    setDetail(prev => ({
                      ...prev,
                      eventId: Number(val.value),
                      eventRule: String(val.name)
                    }));
                    setSearch(String(val.name));
                    getEventList(String(val.name));
                    setConfirmEventRule(true);
                  }}
                  resetIsConfirm={() => {
                    setConfirmEventRule(false);
                    setDetail(prev => ({
                      ...prev,
                      eventId: -1,
                      eventRule: ''
                    }))
                  }}
                />
              }
            </>
          } 
        />
        <InfoCard
          title="Priority" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? <CurrentStatusCard status={detail.priorityCode} /> 
                : <DropdownAtom 
                id="priority-code" 
                data={priorityTypeList} 
                value={{
                name: priorityTypeList.find(val => val.value === detail.priorityCode)?.name,
                value: detail.priorityCode
                }}
                handleClick={(val) => {
                  setDetail(prev => ({
                    ...prev,
                    priorityCode: String(val.value)
                  }))
                }}
              />
              }
            </>
          } 
        />
        <InfoCard
          title="Received type" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? enumFormatter(detail.msgSenderTypeCode)
                : <DropdownAtom 
                id="receive-type" 
                data={msgReceiveTypeList} 
                value={{
                name: msgReceiveTypeList.find(val => val.value === detail.msgSenderTypeCode)?.name,
                value: detail.msgSenderTypeCode
                }}
                handleClick={(val) => {
                  if (val.value === detail.msgSenderTypeCode) return;
                  setDetail(prev => ({
                    ...prev,
                    msgSenderTypeCode: String(val.value),
                    messageText: ''
                  }));
                  getEventMessageList(String(val.value));
                }}
              />
              }
            </>
          } 
        />
        <InfoCard
          title="Message" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? <pre>{htmlPre}</pre>
                : <DropdownAtom 
                id="message-text" 
                data={msgTextTypeList} 
                placeholder='Select message'
                value={{
                name: detail.messageText,
                value: detail.msgId
                }}
                handleClick={(val) => {
                  setDetail(prev => ({
                    ...prev,
                    messageText: String(val.name),
                    messageId: Number(val.value)
                  }))
                }}
              />
              }
            </>
          } 
        />
        {
          !edit &&
        <>
        <InfoCard
          title="Created"
          content={() => 
            <>
            <u
              onMouseOver={(e) => {
                tooltip.userTooltip({ 
                  top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                    ? e.currentTarget.getBoundingClientRect().bottom - 115
                    : e.currentTarget.getBoundingClientRect().bottom + 12,
                  left: e.currentTarget.getBoundingClientRect().left, 
                  userInfo: { userId: data.createdByUserId, fullName: data.createdByName, createdAt: !data.createdAt ? '-' : data.createdAt, thumbnail: '' }
                });
              }}
              onMouseLeave={tooltip.close}
            >
            {data.createdByName}({data.createdByUserId})</u> / {!data.createdAt ? '-' : dateFormatter(data.createdAt, 'datetime')}
            </> 
          } 
        />
        <InfoCard
          title="Updated"
          content={() => 
            <>
                <u
                  onMouseOver={(e) => {
                    tooltip.userTooltip({ 
                      top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                        ? e.currentTarget.getBoundingClientRect().bottom - 115
                        : e.currentTarget.getBoundingClientRect().bottom + 12,
                      left: e.currentTarget.getBoundingClientRect().left, 
                      userInfo: { userId: data.modifiedByUserId, fullName: data.modifiedByName, createdAt: !data.modifiedAt ? '-' : data.modifiedAt, thumbnail: '' }
                    });
                  }}
                  onMouseLeave={tooltip.close}
                >
                  {data.modifiedByName}({data.modifiedByUserId})</u> / {!data.modifiedAt ? '-' : dateFormatter(data.modifiedAt, 'datetime')}
            </> 
          } 
        />
        </>
        }
      </div>
      <div className="btns flex j-end">
        {
          !edit 
            ? <>
              <button 
                className="big-sub-btn"
                onClick={() => setEdit(true)}
              >
                Edit
              </button>
              <button 
                className="big-main-btn"
                onClick={openDeleteModal}
              >
                Delete
              </button> 
            </>
            : <>
              <button
                className="big-sub-btn"
                onClick={() => {
                  setDetail({ ...origin });
                  setEdit(false);
                }}
              >
                Cancel
              </button>
              <button 
                className="big-main-btn"
                disabled={ enableEditEventSettingDetail }
                onClick={() => {
                  const reqEditData = {
                    reqData: {
                      settingId: detail.settingId,
                      projectId: detail.projectId,
                      eventId: detail.eventId,
                      name: detail.name,
                      priorityCode: detail.priorityCode,
                      messageId: detail.msgId
                    }
                  }

                  editEventSetting({variables: reqEditData }).then(({ data }) => {
                    if (data) {
                      if (data.updateEventSetting.result === ErrorCode.SUCCESS) {
                        useToast(ErrorCode.SUCCESS, 'Event-rule is updated successfully.');
                        setEdit(false);
                        onEditSuccess();
                      } else {
                        console.log(data.updateEventSetting.result);
                        useToast(ErrorCode.UNKNOWN, 'Update event-rule failed.');
                      }
                    } else {
                      useToast(ErrorCode.UNKNOWN, 'Update event-rule failed.');
                    }
                  })
                }}
              >
              Save
              </button>
            </>
        }
      </div>
    </div>
  )
}
export default EventSettingDetail;