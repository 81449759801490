/* eslint-disable max-len */
import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import LinkedCloudDetail from '../LinkedCloudDetail';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import Icon from 'components/v2/atoms/Icon';
import Table from 'components/v2/dataDisplay/Table';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import DeleteCloudModal from 'components/v2/modals/DeleteCloudModal';
import CreateCloudModal from 'components/v2/modals/CreateCloudModal';
import ProjectSettingModal from 'components/v2/modals/ProjectSettingModal';
import { TABLE_OFFSET } from 'utils/DummyData/Dropdown';
import RedCircleCloud from 'assets/svgs/v2/ico_redcircle_cloud.svg';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import { useOutletContext } from 'react-router-dom';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import lazyGetProjectCloudPage from 'graphql/queries/getProjectCloudPage';
import deleteProjectCloud from 'graphql/mutations/deleteProjectCloud';
import { lazyGetAllRuleCloud } from 'graphql/queries/getAllRuleCloud';
import { ProjectCloudType } from 'graphql/types/ProjectCloud';
import { AllRuleCloudType } from 'graphql/types/AllRuleCloud';
import InfoCard from '../cards/InfoCard';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import { timeToKr } from 'utils/timeFormatter';
import lazyGetProjectMember from 'graphql/queries/getProjectMemberPage';
import IsLoading from 'components/v2/atoms/IsLoading';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { IEnumTypeCodeProps } from '@Types/v3/content';
import { handleFormatText } from 'utils/Common';

interface IProjectOccupiedProps {
  project: ProjectInfo;
}

const ProjectOccupied = ({
  project
}: IProjectOccupiedProps) => {
  const { tooltip } = useGModal();
  const { 
    favoriteList,
    toggleFavorite
  } = useOutletContext<{ 
    favoriteList: { id:number; name: string }[], 
    toggleFavorite: (id: number, name: string) => void 
  }>();

  const [memberList, setMemberList] = useState<string[]>([]);
  const [cloudTypeList, setCloudTypeList] = useState<AllRuleCloudType[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState({
    delete: false,
    create: false,
    project: false
  });
  const [total, setTotal] = useState({
    totalPage: 0,
    totalElement: 0
  });
  const [rows, setRows] = useState<ProjectCloudType[]>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');
  const [serviceTypeList, setServiceTypeList] = useState<IEnumTypeCodeProps[]>([]);  
  const [projectServiceType] = useState<'Managed'|'Monitoring'|'Both'>('Both');

  /* 연계 클라우드 조회 */
  const [lazyGetCloudList] = lazyGetProjectCloudPage();
  /** 연계 클라우드 삭제 */
  const [deleteCloud] = deleteProjectCloud();

  /** 클라우드 종류 (기준 클라우드 정보 조회) */
  const [getAllRuleCloud, { loading: loadingGetAllRuleCloud }] = lazyGetAllRuleCloud();
  /** 해당 프로젝트 구성원 정보 불러오기 */
  const [lazyGetMemberList] = lazyGetProjectMember();
  const [getEnumTypeCode] = lazyGetEnumTypeCode();

  /* 프로젝트 연계 클라우드 요청 request payload */
  const getProjectCloudPageData = useMemo(() => {
    return ({
    projectCloud: {
      projectId: project.projectId,
      pageInfo: {
        page: tablePagination.currentPage - 1,
        size: tablePagination.limit,
        orders: [{
          target: tablePagination.target, 
          direction: tablePagination.direction 
        }]
      }
    }
    
  })}, [project.projectId, tablePagination]);

  const COLUMNS:ColumnType[] = useMemo(() => {
    return [
      {
        label: 'Name',
        field: 'name',
        sort: true
      },
      {
        label: 'Service type',
        field: 'serviceType',
        renderCell: (row: RowType) => <>{enumFormatter(row.serviceType)}</>,
        sort: true
      },
      {
        label: 'Providers',
        field: 'cloudId',
        renderCell: (row: RowType) => <>{handleFormatText(cloudTypeList.find(li => li.cloudId === row.cloudId)?.name)}</>,
        sort: true
      },
      {
        label: 'Account ID',
        field: 'rootAccount',
        sort: true,
        renderCell: (row: RowType) => {
          try {
          const parse = JSON.parse(row.attrValue2);

            return <>{handleFormatText(parse.accountId ? parse.accountId : '-')}</>
          } catch {
            return <>{row.attrValue2}</>
          }
        },
      },
      {
        label: 'Created',
        field: 'createdByUserId',
        sort: true
      },
      {
        label: 'Updated',
        field: 'modifiedAt',
        renderCell: (row:RowType) => <>{timeToKr(row.modifiedAt)}</>, 
        sort: true
      }
    ];
  }, [cloudTypeList, rows]);

  const incomeJson = useMemo(() => {
    try {
      const json = JSON.parse(project.attrValue1);
      return json;
    } catch (e) {
      return project.attrValue1;
    }
  }, [project]);

  const doRefecthCloudList = () => {
    getAllRuleCloud().then(res => {
      if (res.data) {
        setCloudTypeList(res.data.getAllRuleCloud.data);
      }
    })
  }

  /* 연계 클라우드 rows 호출 */
  const getCloudRows = () => {
    lazyGetCloudList({ variables: getProjectCloudPageData }).then(({ data }) => {
      if(data) {
        if (data.getProjectCloudPage) {
          setRows(data.getProjectCloudPage.content);
          setTotal({
            totalPage: data.getProjectCloudPage.totalPages,
            totalElement: data.getProjectCloudPage.totalElements
          });
        }
      }
    });
  }

  useEffect(() => {
    if (project.projectId) {
    setSelected('');
    getCloudRows();
    doRefecthCloudList();

    lazyGetMemberList({ variables: {
      reqGetProjectMemberPage: {
        projectId: project.projectId,
        pageInfo: {
          page: 0,
          size: 50,
          orders: [{
            target: 'createdAt',
            direction: OrderDirection.ASC
          }]
        }
      }
    } }).then(({ data }) => {
      if(data) {
        if (data.getProjectMemberPage.totalElements !== 0) {
          lazyGetMemberList({ variables: {
            reqGetProjectMemberPage: {
              projectId: project.projectId,
              pageInfo: {
                page: 0,
                size: data.getProjectMemberPage.totalElements,
                orders: [{
                  target: 'createdAt',
                  direction: OrderDirection.ASC
                }]
              }
            }
          } }).then(({ data }) => {
            if (data) {
              setMemberList(data.getProjectMemberPage.content.map(val => val.roleCode));
            }
          });
        } else {
          setMemberList([]);
        }
      }
    });
    }
  }, [project.projectId]);

  useEffect(() => getCloudRows(), [getProjectCloudPageData]);

  const handleGetEnumTypeCode = async () => {
    const {data} = await getEnumTypeCode({ variables: { text: 'ThirdPartyCode' } });

    if(!data) return;
    
    setServiceTypeList(data.getEnumTypeCode.data.map(val => ({
      name: enumFormatter(val.value),
      value: val.value
    })));
  }

  useEffect(() => {
    handleGetEnumTypeCode()
  }, []);

  const handleGetTextTransform = (value: string) => {
    const item = serviceTypeList?.length ? serviceTypeList.find((e) => e?.value === value) : null
    return item?.name || value
  }

  return (
    <div id="project-occupied-page">
      <div className="row-1 flex j-between a-center">
        <div className="flex j-start a-center">
          {project.name}
          <button 
            className={`favorite-btn ${favoriteList.find(val => val.id === project.projectId)?.id === project.projectId && 'active'}`}
            onClick={() => { toggleFavorite(project.projectId, project.name); }}
          />
        </div>
        <button 
          className="setting-btn" 
          onClick={() => setModalIsOpen((prev) => ({
            ...prev,
            project: true
          }))}
        />
      </div>
      <div className="row-2 flex j-start">
        <div className="flex col"> 
          <InfoCard
            title="Services"
            content={() => <>
            {projectServiceType !== 'Monitoring' && "Managed security service"}
            {projectServiceType === 'Both' && ", "} 
            {projectServiceType !== 'Managed' && "Monitoring service"}
            </>} 
          />
          <InfoCard 
            title="Members" 
            content={() => <>{memberList.length} Users <span>(
              {`owner ${memberList.filter(val => val === 'pj_owner').length}`}
              {`, admin ${memberList.filter(val => val === 'pj_admin').length}`}
              {`, member ${memberList.filter(val => val === 'pj_membr').length}`}
               )</span></>} 
          />
        </div>
        <div className="flex col">
          <InfoCard 
            title="Created" 
            className="column-2" 
            content={() => 
              <>
                <u
                  onMouseOver={(e) => {
                    tooltip.userTooltip({ 
                      top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                        ? e.currentTarget.getBoundingClientRect().bottom - 115
                        : e.currentTarget.getBoundingClientRect().bottom + 12,
                      left: e.currentTarget.getBoundingClientRect().left, 
                      userInfo: { userId: project.createdByUserId, fullName: project.createdByName, createdAt: !project.createdAt ? '' : project.createdAt, thumbnail: '' }
                    });
                  }}
                  onMouseLeave={tooltip.close}
                >
                  {project.createdByName}({(project.createdByUserId)})</u> / {!project.createdAt ? '' :dateFormatter(project.createdAt, 'datetime')}
              </> 
            }
          />
          <InfoCard 
            title="Updated" 
            className="column-2"
            content={() => 
              <>
                <u
                  onMouseOver={(e) => {
                    tooltip.userTooltip({ 
                      top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                        ? e.currentTarget.getBoundingClientRect().bottom - 115
                        : e.currentTarget.getBoundingClientRect().bottom + 12,
                      left: e.currentTarget.getBoundingClientRect().left, 
                      userInfo: { userId: project.modifiedByUserId, fullName: project.modifiedByName, createdAt: !project.modifiedAt ? '' : project.modifiedAt, thumbnail: '' } /* mark: 추후 실제 이 유저의 마지막 접속일 데이터로 수정필요 */
                    });
                  }}
                  onMouseLeave={tooltip.close}
                >
                  {project.modifiedByName}({(project.modifiedByUserId)})</u> / {!project.modifiedAt ? '' :dateFormatter(project.modifiedAt, 'datetime')}
              </> 
            } 
          />
        </div>
      </div>
      {projectServiceType !== 'Monitoring' && <div className="row-2 flex j-start col">
        <h3>Managed security service</h3>
        <div className="flex col"> 
          <InfoCard
            title="Clounds count"
            content={() => <>4</>} 
          />
          <InfoCard 
            title="Providers" 
            content={() => <>AWS, GCP</>} 
          />
        </div>
      </div>
      }
      {projectServiceType !== 'Managed' && <div className="row-2 flex j-start col">
        <h3>Monitoring service</h3>
        <div className="flex col"> 
          <InfoCard 
            title="Clouds count" 
            content={() => <>3</>} 
          />
          <InfoCard
            title="Data source"
            content={() => <>{handleGetTextTransform(project.thirdPartTypeCode)}</>} 
          />
          <InfoCard 
            title="Data attributes" 
            content={() => 
              <>
                {incomeJson !== null && typeof incomeJson === 'object' && Object.keys(incomeJson).map(key => 
                  <div className='data-inflow flex' key={`data-inflow-${key}`}>
                    <div className="key">{key}</div>
                    <div className="value">{incomeJson[key]}</div>
                  </div> 
                )}
              </>
            }
          />
        </div>
      </div>
      }
      <div className='data-contents'>
        <div className="row-3">
          <div className="row-1 flex j-between">
            <div className="col-1 flex a-center">
              <div className="cloud-icon" />Related clouds
            </div>
            <div className="col-2 flex a-center">
              { checkedList.length > 0 && `${checkedList.length} selected` }
              <button 
                className="big-sub-btn delete"
                disabled={checkedList.length === 0 }
                onClick={() => setModalIsOpen((prev) => ({
                  ...prev,
                  delete: true
                }))}
              >
                Delete
              </button>
              <button 
                className="big-main-btn register"
                onClick={() => setModalIsOpen((prev) => ({
                  ...prev,
                  create: true
                }))}
              >
                Create
              </button> 
              <DropdownAtom 
                id="offset-limit"
                data={TABLE_OFFSET}
                value={{ 
                  name: TABLE_OFFSET.find(data => data.value === tablePagination.limit)?.name,
                  value: Number(tablePagination.limit)
                }}
                handleClick={(val) => { 
                  setTablePagination(prev => ({
                    ...prev,
                    limit: Number(val.value),
                    currentPage: 1
                  }));
                }}
              />
            </div>
          </div>
          <div className="row-2">
            <Table // default sort: 수정시간 최신순(오름차순)  */
              rows={rows} 
              columns={COLUMNS} 
              reportCheckedList={ (list) => setCheckedList(list)}
              reportSelected={(id) => setSelected(id)}
              sortOption={{
                target: tablePagination.target,
                direction: tablePagination.direction,
                onChangeSort: (target: string, dir: OrderDirection) => {
                  setTablePagination(prev => ({
                    ...prev,
                    target: target,
                    direction: dir
                  }))
              }
              }}
            />
            { 
              rows.length === 0 ? 
                <div className="empty-cloud flex j-center a-center">
                  <div className="flex col a-center">
                    <div />
                    <h5>There is no related clouds.</h5>
                    <p>Register your cloud information with ‘Register’.</p>
                  </div>
                </div> 
                : <div className="pagination-wrapper flex a-center">
                  <p className="flex a-center">Total <span>{total.totalElement}</span></p>
                  <TablePagination 
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={(page) => setTablePagination(prev => ({
                      ...prev,
                      currentPage: page
                    }))}
                    totalPage={total.totalPage}
                  />
                </div>
            }
          </div>
        </div>
        { loadingGetAllRuleCloud && <IsLoading dimmed={rows.length !== 0} /> }
      </div>

      <DeleteCloudModal
        open={modalIsOpen.delete}
        data={rows.filter(row => checkedList.includes(String(row.id))).map(val => {
          return {
            serviceType: enumFormatter(val.serviceType),
            account: val.rootAccount,
            name: val.name
          };
        })}
        title= {() => 
          <>
            <Icon width={32} height={32} src={RedCircleCloud} />
            Delete related clouds
          </>
        } 
        onClose={() => setModalIsOpen((prev) => ({
          ...prev,
          delete: false
        }))}
        onDelete={() => {
          const data = {
            reqDelProjectCloud: checkedList.map(id => Number(id))
          };

          deleteCloud({ variables: data }).then(({ data }) => {
            if(data) {
              if (data.deleteProjectCloud.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, 'Selected related clouds are deleted successfully.');
                setModalIsOpen((prev) => ({
                  ...prev,
                  delete: false
                }));

                if ((rows.length === checkedList.length) && total.totalPage !== 1) {
                  setTablePagination(prev => ({
                    ...prev,
                    currentPage: tablePagination.currentPage - 1
                  }));
                } else {
                  getCloudRows();
                }

                setSelected('');
                setCheckedList([]);
              } else {
                console.log(data.deleteProjectCloud.result);
                useToast(ErrorCode.SUCCESS, 'Delete selected related clouds failed.');
              }
            } else {
              useToast(ErrorCode.SUCCESS, 'Delete selected related clouds failed.');
            }
          });
        }}
      /> 

      <CreateCloudModal 
        className="default create-cloud-modal-wrap"
        open={modalIsOpen.create} 
        cloudTypeList={cloudTypeList}
        doRefecthCloudList={doRefecthCloudList}
        projectId={project.projectId}
        title={() =>  
          <>
            <Icon width={32} height={32} src={RedCircleCloud} />
            New related cloud
          </>
        } 
        onClose={() => setModalIsOpen((prev) => ({
          ...prev,
          create: false
        }))}
        onCreateSuccess={() => {
          setModalIsOpen((prev) => ({
            ...prev,
            create: false
          }));
          getCloudRows();
        }} 
      />
      <ProjectSettingModal
        projectInfo={project}
        projectSettingOpen={modalIsOpen.project}
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          project: false
        }))}
      />
      
      {
        selected !== '' &&
        <LinkedCloudDetail
          data={rows.find(val => val.id === Number(selected)) as ProjectCloudType}
          cloudTypeList={cloudTypeList}
          doRefecthCloudList={doRefecthCloudList}
          getOffPage={() => {
            setSelected('');
            setCheckedList([]);
          }} 
          onDelete={() => {
            setCheckedList([selected]);
            setModalIsOpen((prev) => ({
              ...prev,
              delete: true
            }))
          }}
          onEditSuccess={() => {
            setCheckedList([]);
            getCloudRows();
          }}
        />
      }
    </div>
  );
};

export default ProjectOccupied;
