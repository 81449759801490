import './index.scss'; 
import { useAuth } from 'contexts/AuthProvider';

interface IEmptyProjectCardProps {
  className?: string;
  openCreatePJModal?: () => void;
}

const EmptyProjectCard = ({
  className,
  openCreatePJModal
}: IEmptyProjectCardProps) => {
  const { role } = useAuth();
  
  return (
    <div className={`empty-project-card ${className}`}>
      <div> 
        <div className="row-1 flex col a-center">
          <div className="folder-icon" /> 
          There is no project yet.
        </div>
        <div className="row-2 flex col a-center">
          { role === 'pj_owner'
          ? <>
            Create your project and invite your member as a role.
            <button 
              className="big-main-btn"
              onClick={() => openCreatePJModal && openCreatePJModal()}
            > 
              Create new project
            </button>
          </>
          : 
          <>
          You can invite your member as a role or get invitation<br/>
          after your organization owner create it.
          </>
          }
        </div> 
      </div>
    </div>
  );
};

export default EmptyProjectCard;
