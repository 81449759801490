/* eslint-disable quotes */
import { useMemo, useState } from 'react';
import './index.scss';

import { useGModal } from 'contexts/v2/GlobalModalProvider';
import MemberDataGrid from './MemberDataGrid';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import EmptyProjectCard from 'pages/v2/Organ/Project/components/cards/EmptyProjectCard';
import InviteMemberModal from '../../InviteMemberModal';
import UserInfo from 'assets/svgs/v2/ico_user_info_title.svg';
import Icon from 'components/v2/atoms/Icon';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import ShareModal from '../../ShareModal';
import updateProjectMember from 'graphql/mutations/updateProjectMember';
import { useAuth } from 'contexts/AuthProvider';
import removeProjectMember from 'graphql/mutations/removeProjectMember';
import delegateOwner from 'graphql/mutations/delegateOwner';

export type selectSettingType = {
  open: {
      id: string;
      fullName: string;
      userId: string;
      roleCode: string;
      alertYn: boolean;
      projectId: number;
      projectName: string;
      rowsLength: number;
      totalPage: number;
  };
  top: number;
  left: number;
}

interface IMemberProps {
  projectInfo: ProjectInfo;
}

const Member = ({
  projectInfo
}:IMemberProps) => {
  const { userInfo, updateToken } = useAuth();
  const { tooltip } = useGModal();

  /* 구성원 역할 변경 (소유자 위임과는 별개) */
  const [updatePjMember] = updateProjectMember();
  /* 프로젝트 내보내기 */
  const [removePjMember] = removeProjectMember();
  /* 조직 소유자 위임하기 */
  const [delegateOrgOwner] = delegateOwner();

  const [modalIsOpen, setModalIsOpen] = useState({
    invite: false,
    select: false,
    changeShareModal: false,
    target: '',
  });
  const [selectSetting, setSelectSetting] = useState<selectSettingType>({
    open: {
      id: '',
      fullName: '',
      userId: '',
      roleCode: '',
      alertYn: false,
      projectId: -1,
      projectName: '',
      rowsLength: -1,
      totalPage: -1
    },
    top: 0,
    left: 0
  });

  const [refetch, setRefetch] = useState({
    refresh: false,
    resetPage: false
  });

  const projectNameAndId = useMemo(() => {
    if (modalIsOpen.invite) {
      const data = {
        value: projectInfo.projectId,
        name: projectInfo.name
      }
      return data;
    }
  }, [modalIsOpen, projectInfo])

  const changeShareModalData = useMemo(() => {
    if (modalIsOpen.changeShareModal) {
      /* 멤버로 변경 */
      if (modalIsOpen.target === 'member') {
        return {
          title: () => <>Grant member role</>,
          buttonTitle: 'Grant',
          content: () => <>Member role will be granted to {selectSetting.open.fullName} ({selectSetting.open.userId}) in {selectSetting.open.projectName}.</>,
          onConfirm: () => {
            const data = {
              reqUpdProjectMember: {
                memberId: selectSetting.open.id,
                projectId: selectSetting.open.projectId,
                roleCode: 'pj_membr',
                alertYn: selectSetting.open.alertYn
              }
            };
            updatePjMember({ variables: data }).then(({ data }) => {
              if(data) {
                if (data.updateProjectMember.result === ErrorCode.SUCCESS) {
                  useToast(ErrorCode.SUCCESS, 'Member role is granted successfully.');
                  setModalIsOpen(prev => ({
                    ...prev,
                    changeShareModal: false,
                    target: ''
                  }));
                  setSelectSetting({
                    open: {
                      id: '',
                      fullName: '',
                      userId: '',
                      roleCode: '',
                      alertYn: false,
                      projectId: -1,
                      projectName: '',
                      rowsLength: -1,
                      totalPage: -1
                    },
                    top: 0,
                    left: 0
                  });
              
                  setRefetch(prev => ({
                    ...prev,
                    refresh: true
                  }));

                } else {
                  console.log(data.updateProjectMember.result);
                  useToast(ErrorCode.SUCCESS, 'Grant member role filed.');
                }
              } else {
                useToast(ErrorCode.SUCCESS, 'Grant member role filed.');
              }
            });
          }
        }
      }
      /* 관리자로 변경 */
      else if (modalIsOpen.target === 'admin') {
        return {
          title: () => <>Grant administrator role</>,
          buttonTitle: 'Grant',
          content: () =>  <>
            Administrator role will be granted to {selectSetting.open.fullName} ({selectSetting.open.userId}) in {selectSetting.open.projectName}.
          </>,
          onConfirm: () => {
            const data = {
              reqUpdProjectMember: {
                memberId: selectSetting.open.id,
                projectId: selectSetting.open.projectId,
                roleCode: 'pj_admin',
                alertYn: selectSetting.open.alertYn
              }
            };

            updatePjMember({ variables: data }).then(({ data }) => {
              if(data) {
                if (data.updateProjectMember.result === ErrorCode.SUCCESS) {
                  useToast(ErrorCode.SUCCESS, 'Administrator role is granted successfully.');
                  setModalIsOpen(prev => ({
                    ...prev,
                    changeShareModal: false,
                    target:''
                  }));
                  setSelectSetting({
                    open: {
                      id: '',
                      fullName: '',
                      userId: '',
                      roleCode: '',
                      alertYn: false,
                      projectId: -1,
                      projectName: '',
                      rowsLength: -1,
                      totalPage: -1
                    },
                    top: 0,
                    left: 0
                  });

                  setRefetch(prev => ({
                    ...prev,
                    refresh: true
                  }));
                } else {
                  console.log(data.updateProjectMember.result);
                  useToast(ErrorCode.UNKNOWN, 'Grant administrator role failed.');
                }
              } else {
                useToast(ErrorCode.UNKNOWN, 'Grant administrator role failed.');
              }
            });
          }
        }
      }
      /* 내보내기 */
      else if (modalIsOpen.target === 'discharge') {
        return {
          title: () => <>Banish member from project</>,
          buttonTitle: 'Banish',
          content: () => <>If you banish a member, that member will no longer have access to this project.</>,
          onConfirm: () => {
            const removeProjectMemberData = {
              reqData: {
                projectId: selectSetting.open.projectId,
                memberIds: [selectSetting.open.id],
              }
            }

            removePjMember({ variables: removeProjectMemberData }).then(({data}) => {
              if (data) {
                if (data.removeProjectMember.result === ErrorCode.SUCCESS) {
                  useToast(ErrorCode.SUCCESS, 'Member is banished successfully.');

                  setRefetch({
                    refresh: true,
                    resetPage: true
                  });
                  
                  setModalIsOpen(prev => ({
                    ...prev,
                    changeShareModal: false,
                    target: ''
                  }));
      
                  setSelectSetting({
                    open: {
                      id: '',
                      fullName: '',
                      userId: '',
                      roleCode: '',
                      alertYn: false,
                      projectId: -1,
                      projectName: '',
                      rowsLength: -1,
                      totalPage: -1
                    },
                    top: 0,
                    left: 0
                  });
                } else {
                  console.log(data.removeProjectMember.result);
                  useToast(ErrorCode.SUCCESS, 'Banish member failed.');
                }
              } else {
                useToast(ErrorCode.SUCCESS, 'Banish member failed.');
              }
            })
          }
        }
      }
    }
  }, [modalIsOpen, selectSetting]);
  

  const renderMemberDataGrid = useMemo(() => {
    /* 프로젝트에서 들어온 것이라면 => 해당 프로젝트만 렌더 */    
    return <MemberDataGrid 
      projectInfo={projectInfo as ProjectInfo} 
      openModal={(key:string, value: boolean | string ) => setModalIsOpen(prev => ({
        ...prev,
        [key]: value
      }))}
      selectSetting={selectSetting}
      selectSett={(data: selectSettingType) => setSelectSetting({ ...data })}
      refetch={refetch}
      resetRefetch={() => { 
        setRefetch({
          refresh: false,
          resetPage: false
        })
      }}
    />;
  }, [
      projectInfo,
      selectSetting,
  ]);

  return (
    <div 
      className="member-container" 
      onScroll={tooltip.close}
    >
      {renderMemberDataGrid}

      <InviteMemberModal 
        open={modalIsOpen.invite}
        projectList={projectNameAndId ? [projectNameAndId] : []}
        title={() => 
          <>
            <Icon width={32} height={32} src={UserInfo} />
            Invite member 
          </>
        } 
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          invite: false,
          target: ''
        }))}
        onInviteSuccess={() => {
          useToast(ErrorCode.SUCCESS, 'Invitation is sent successfully.');
          setModalIsOpen(prev => ({
            ...prev,
            invite: false,
            target: ''
          }));
        }}
      />

      <ShareModal 
        className="project-member-share-modal-wrap"
        open={modalIsOpen.changeShareModal}
        title={changeShareModalData?.title} 
        content={changeShareModalData?.content} 
        buttonTitle={changeShareModalData?.buttonTitle} 
        onConfirm={changeShareModalData?.onConfirm} 
        onClose={() => {
          setModalIsOpen(prev => ({
            ...prev,
            changeShareModal: false,
            target: ''
          }));
          setSelectSetting({
            open: {
              id: '',
              fullName: '',
              userId: '',
              roleCode: '',
              alertYn: false,
              projectId: -1,
              projectName: '',
              rowsLength: -1,
              totalPage: -1
            },
            top: 0,
            left: 0
          });
        }}
      />
    </div>
  );
}; 

export default Member;
