import { useNavigate } from 'react-router-dom';
import './index.scss';
import FooterComponent from "components/v2/FooterComponent";
import { useAuth } from 'contexts/AuthProvider';

const DESC = [
  {
    type: 'service-error',
    title: 'We apologize for your inconvenience.',
    text: () => <>A server error has occurred. Please try again.</>
  },
  {
    type: 'service-error',
    title: 'PageNotFound',
    text: () => <>The address you just entered has been changed to another page. Please check the address again.</>
  }
]


const PageNotFound = () => {
  const {userInfo} = useAuth();
  const navigate = useNavigate();

  return (
    <div id="page-not-found">
      <header className='flex j-between a-center'>
        <div className='header-logo' onClick={() => {
          if(userInfo) {
            navigate(`/organ/${userInfo.nemo.organId}/dashboard/overview`)
          } else {
            navigate('/');
          }
        }}/>
      </header>
      <div className='contents flex j-center a-center'>
        <div className="content-wrap flex col a-center">
          <div className='not-found-icon' />
          <h5>{DESC[1].title}</h5>
          <p>{DESC[1].text()}</p>
          <button 
            className='big-main-btn'
            onClick={() => navigate('/auth')}
          >
            Home
          </button>
        </div>
      </div>
      <FooterComponent />
    </div>
  )
}

export default PageNotFound;