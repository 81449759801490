import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import NetworkTitle from 'components/v3/NetworkTitle';
import { NoItem, TagPropsType } from '../NetWorkACLCreation';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import {
  disabledByType,
  disabledByTypeAndProtocol,
} from '../../Function';
import lazyGetAllAwsVpc, { IAllAwsVpcVariables } from 'graphql/queries/getAwsAllVPC';
import { NetworkRuleTable, RuleRowData } from '../NetworkRuleModal';
import lazyGetAllAwsSecurityGroups, { IAwsSecurityGroupsPageVariables } from 'graphql/queries/getAwsAllSecurityGroups';
import { variableCombineNextToken } from '../../../Utils';
import { randomString } from 'utils/Common';
import createAwsSecurityGroupMutation, {
  IcreateAwsSecurityGroupVariables,
} from 'graphql/mutations/createAwsSecurityGroup';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import authorizeAwsSecurityGroupIngressMutation, {
  IAuthorizeAwsSecurityGroupIngressVariables,
} from 'graphql/mutations/authorizeAwsSecurityGroupIngress';
import authorizeAwsSecurityGroupEgressMutation, {
  IAuthorizeAwsSecurityGroupEgressVariables,
} from 'graphql/mutations/authorizeAwsSecurityGroupEgress';
import { RULE_TYPE_DROPDOWN } from '../SecurityGroup/Constant';
import {
  handleGetPortRangeDataByType,
  handleGetFromPort,
  handleGetPortRange,
  handleGetProtocol,
  handleGetProtocolDataByType,
  handleGetToPort,
  handleGetTypeItem,
} from '../SecurityGroup/Common';
import { InputForm, TagsList } from '../SecurityGroup/Tabs/TagsList';

type sgCreationProps = {
  cloudId: number;
  region: string;
  pageBackClick: () => void;
};

type ruleNetworkType = 'INBOUND' | 'OUTBOUND';

const sourceDestinationOptionDefault = {
  id: randomString(),
  name: 'CIDR blocks',
  value: 'cidr-blocks',
  children: [
    {
      value: '0.0.0.0/0',
      name: '0.0.0.0/0',
    },
    {
      value: '0.0.0.0/8',
      name: '0.0.0.0/8',
    },
    {
      value: '0.0.0.0/16',
      name: '0.0.0.0/16',
    },
    {
      value: '0.0.0.0/24',
      name: '0.0.0.0/24',
    },
    {
      value: '0.0.0.0/32',
      name: '0.0.0.0/32',
    },
    {
      value: '::/0',
      name: '::/0',
    },
    {
      value: '::/16',
      name: '::/16',
    },
    {
      value: '::/32',
      name: '::/32',
    },
    {
      value: '::/48',
      name: '::/48',
    },
    {
      value: '::/64',
      name: '::/64',
    },
  ],
};

const ruleDefault = {
  type: 'custom_tcp',
  ipProtocol: 'tcp',
  protocol: 'TCP',
  portRange: '0',
  source: {
    name: '',
    value: '',
  },
  description: '',
};

const SecurityGroupCreation = (props: sgCreationProps) => {
  const { cloudId, region, pageBackClick } = props;
  const [listVpc, setListVpc] = useState<DropdownListDataType[]>([]);
  const [vpc, setVpc] = useState<DropdownListDataType>({ value: '' });
  const [isShow, setIsShow] = useState<boolean>(false);
  const [listTags, setListTags] = useState<TagPropsType[]>([]);
  const [securityGroupName, setSecurityGroupName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [ruleInboundRows, setRuleInboundRows] = useState<RuleRowData[]>([]);
  const [ruleOutboundRows, setRuleOutboundRows] = useState<RuleRowData[]>([]);
  const [sourceDestinationOption, setSourceDestinationOption] = useState<any>([{ ...sourceDestinationOptionDefault }]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const [getAllAwsVpc] = lazyGetAllAwsVpc();
  const [getAwsAllSecurityGroups] = lazyGetAllAwsSecurityGroups();
  const [createAwsSecurityGroup] = createAwsSecurityGroupMutation();
  const [authorizeAwsSecurityGroupIngress] = authorizeAwsSecurityGroupIngressMutation();
  const [authorizeAwsSecurityGroupEgress] = authorizeAwsSecurityGroupEgressMutation();

  const fetchAwsVpc = useCallback(() => {
    const reqVariable: IAllAwsVpcVariables = {
      cloudId,
      region,
      request: {
        maxResults: 1000,
      },
    };
    getAllAwsVpc({ variables: reqVariable }).then(({ data: responseData }) => {
      const vpcData = responseData?.getAwsAllVPC?.data;
      if (vpcData) {
        const dropdownData: DropdownListDataType[] = [];
        vpcData.map((vpc, idx) => {
          dropdownData.push({
            id: idx,
            name: `${vpc?.vpcId} (${vpc?.tags?.find(t => t?.key == 'Name')?.value})`,
            value: vpc?.vpcId,
            isBetween: true,
            description: vpc?.cidrBlock,
          });
        });
        setListVpc(dropdownData);
      }
    });
  }, [cloudId, region]);

  const awsSecurityGroupRequestVariables = useMemo((): IAwsSecurityGroupsPageVariables => {
    return {
      cloudId: cloudId,
      region: region as string,
      request: {
        maxResults: 1000,
      },
    };
  }, [cloudId, region]);

  const fetchAwsAllSecurityGroups = useCallback(
    (nextToken?: string) => {
      const arr = [...sourceDestinationOption];
      const combinedVariable = variableCombineNextToken(awsSecurityGroupRequestVariables, nextToken);
      getAwsAllSecurityGroups({ variables: combinedVariable }).then(({ data: awsSecurityGroupData }) => {
        const getAwsAllSecurityGroup = awsSecurityGroupData?.getAwsAllSecurityGroups;
        if (!getAwsAllSecurityGroup?.data?.length) return [];

        const children: any = [];

        getAwsAllSecurityGroup?.data?.map(sg => {
          children.push({
            id: randomString(),
            name: `${sg.groupName} | ${sg.groupId}`,
            value: sg.groupId,
          });
        });

        arr.push({
          id: randomString(),
          value: 'Security group',
          name: 'Security group',
          children,
        });

        setSourceDestinationOption(arr);
      });
    },
    [awsSecurityGroupRequestVariables],
  );

  useEffect(() => {
    fetchAwsVpc();
    fetchAwsAllSecurityGroups();
  }, []);

  const handleRemoveTag = (index: number) => {
    if (index > -1) {
      const newTags = listTags.slice();
      newTags.splice(index, 1);
      setListTags(newTags);
    }
  };

  const handleUpdateTag = (key: string, value: string, index: number) => {
    const newTags = listTags.map((tag, i) => {
      if (index === i) {
        return {
          ...tag,
          [key]: value,
        };
      }
      return tag;
    });

    setListTags(newTags);
  };

  const handleShowTagsList = () => {
    setIsShow(true);
  };

  const handleAddTag = () => {
    const newTags = listTags.slice();
    newTags.push({
      key: '',
      value: '',
    });

    setListTags(newTags);
  };

  const handleCreate = async () => {
    try {
      setIsSubmit(true);

      const isValidateInboundRule =
        ruleInboundRows?.length &&
        ruleInboundRows.some(e => !e.source?.value || (e.type === RULE_TYPE_DROPDOWN[3].value && !e.protocol));
      const isValidateOutboundRule =
        ruleOutboundRows?.length &&
        ruleOutboundRows.some(e => !e.source?.value || (e.type === RULE_TYPE_DROPDOWN[3].value && !e.protocol));
      const isValidateListTags = listTags?.length && listTags.some(e => !e.key);

      if (
        !securityGroupName ||
        !description ||
        !vpc?.value ||
        isValidateListTags ||
        isValidateInboundRule ||
        isValidateOutboundRule
      )
        return;

      const variables: IcreateAwsSecurityGroupVariables = {
        cloudId,
        region,
        request: {
          groupName: securityGroupName,
          description: description,
          vpcId: vpc.value?.toString(),
          tagSpecifications: listTags?.length
            ? [
                {
                  resourceType: 'security-group',
                  tags: listTags,
                },
              ]
            : undefined,
        },
      };

      const { data: responseData }: any = await createAwsSecurityGroup({ variables });

      if (responseData?.createAwsSecurityGroup?.result !== ErrorCode.SUCCESS) {
        useToast(ErrorCode.UNKNOWN, 'Create security group failed.');
        return;
      }

      if (!ruleInboundRows?.length && !ruleOutboundRows?.length) {
        useToast(ErrorCode.SUCCESS, 'Create security group successful.');
        pageBackClick();
        return;
      }

      const variablesRuleInbound: IAuthorizeAwsSecurityGroupIngressVariables | undefined = ruleInboundRows?.length
        ? {
            cloudId,
            region,
            request: {
              groupId: responseData?.createAwsSecurityGroup?.data?.[0]?.groupId,
              ipPermissions: ruleInboundRows.map((e: any) => {
                return {
                  ipProtocol: e.ipProtocol,
                  fromPort: handleGetFromPort(e.type, e.protocol, e.portRange),
                  toPort: handleGetToPort(e.type, e.portRange),
                  ipRanges: [
                    {
                      cidrIp: e.source?.value,
                      description: e.description
                    },
                  ],
                };
              }),
            },
          }
        : undefined;

      const variablesRuleOutbound: IAuthorizeAwsSecurityGroupEgressVariables | undefined = ruleOutboundRows?.length
        ? {
            cloudId,
            region,
            request: {
              groupId: responseData?.createAwsSecurityGroup?.data?.[0]?.groupId,
              ipPermissions: ruleOutboundRows.map((e: any) => {
                return {
                  ipProtocol: e.ipProtocol,
                  fromPort: handleGetFromPort(e.type, e.protocol, e.portRange),
                  toPort: handleGetToPort(e.type, e.portRange),
                  ipRanges: [
                    {
                      cidrIp: e.source?.value,
                      description: e.description
                    },
                  ],
                };
              }),
            },
          }
        : undefined;

      if (variablesRuleInbound && variablesRuleOutbound) {
        await Promise.all([
          authorizeAwsSecurityGroupIngress({ variables: variablesRuleInbound }),
          authorizeAwsSecurityGroupEgress({ variables: variablesRuleOutbound }),
        ]);
      } else if (variablesRuleInbound) {
        await authorizeAwsSecurityGroupIngress({ variables: variablesRuleInbound });
      } else if (variablesRuleOutbound) {
        await authorizeAwsSecurityGroupEgress({ variables: variablesRuleOutbound });
      }

      useToast(ErrorCode.SUCCESS, 'Create security group successful.');
      pageBackClick();
    } catch (error) {
      console.log(error);
      useToast(ErrorCode.UNKNOWN, 'Create security group failed.');
    }
  };

  const handleCreateRow = (type: ruleNetworkType) => {
    if (type === 'INBOUND') {
      setRuleInboundRows(prevState => {
        return prevState.concat({ ...ruleDefault });
      });

      return;
    }

    setRuleOutboundRows(prevState => {
      return prevState.concat({ ...ruleDefault });
    });
  };

  const handleChange = (index: number, value: any, propName: keyof RuleRowData, type: ruleNetworkType) => {
    const rows = type === 'INBOUND' ? ruleInboundRows : ruleOutboundRows;
    const newRows = rows.map((row, i) => {
      if (i == index) {
        const newRow = { ...row, [propName]: value };
        if (propName === 'type') {
          const item = handleGetTypeItem(value);
          const protocol = handleGetProtocol(value);
          newRow.ipProtocol = item?.protocol;
          newRow.protocol = protocol;
          newRow.portRange = item?.port || '0';
        }

        if (propName === 'protocol') {
          if (row.type === RULE_TYPE_DROPDOWN[3].value) {
            newRow.ipProtocol = value;
            newRow.protocol = value;
          }

          newRow.portRange = handleGetPortRange(row.type, value);
        }

        return newRow;
      }

      return row;
    });

    if (type === 'INBOUND') {
      setRuleInboundRows(newRows);
      return;
    }

    setRuleOutboundRows(newRows);
  };

  const handleDelete = (indexToRemove: number, type: ruleNetworkType) => {
    const row = type === 'INBOUND' ? ruleInboundRows : ruleOutboundRows;
    const newData = row.filter((_, index) => index != indexToRemove);
    if (type === 'INBOUND') {
      setRuleInboundRows(newData);
    } else {
      setRuleOutboundRows(newData);
    }
  };

  return (
    <div id="mgd-list-layout">
      <div className="row-1 flex j-between a-center">
        <div className="flex j-start a-center" id="title">
          <NetworkTitle
            pageTitle={'Create security group'}
            id={321}
            name={'name'}
            hasPrefixIcon={false}
            hasFavorite={false}
            pageBackClick={pageBackClick}
          />
        </div>
        {/* <div className="flex a-center" id="action">
          <button className="action-btn" onClick={() => {}}>
            Delete
          </button>
        </div> */}
      </div>

      <div className="one-page create-security-group" style={{ height: 'calc(100% - 70px)', overflowY: 'auto' }}>
        <div className="row-3 flex j-between a-center">
          <div className="title">
            <p>Basic details</p>
          </div>
        </div>

        <div className="overview-container">
          <div className="nw-acl-screen">
            <div className="nw-acl-container">
              <div className="nw-acl-setting-container">
                <div className="nw-acl-flex">
                  <div className="title">Security group name</div>
                  <InputForm
                    type="INPUT"
                    value={{ value: securityGroupName }}
                    setValue={(key: string, value: string, number: number) => {
                      setSecurityGroupName(value);
                    }}
                    error={isSubmit && !securityGroupName}
                  />
                </div>

                <div className="nw-acl-flex">
                  <div className="title">Description</div>
                  <InputForm
                    type="INPUT"
                    value={{ value: description }}
                    setValue={(key: string, value: string, number: number) => {
                      setDescription(value);
                    }}
                    error={isSubmit && !description}
                  />
                </div>

                <div className="nw-acl-flex">
                  <div className="title">VPC</div>
                  <div>
                    <DropdownAtom
                      id=""
                      className=""
                      data={listVpc || []}
                      value={vpc}
                      handleClick={value => {
                        setVpc(value);
                      }}
                      error={isSubmit && !vpc?.value}
                    />
                  </div>
                </div>
              </div>

              <div className="nw-acl-tags-container">
                <div className="tags-title">Inbound rules</div>
                <div className="tags-body-container">
                  <div className="edit-inbound-rules-model" style={{ width: '100%', padding: '16px' }}>
                    <NetworkRuleTable
                      btnName="Delete"
                      rows={ruleInboundRows}
                      sourceDestinationOption={sourceDestinationOption}
                      handleChange={(index: number, value: string, propName: keyof RuleRowData) => {
                        handleChange(index, value, propName, 'INBOUND');
                      }}
                      disabledByType={(type: string) => disabledByType(type)}
                      getProtocolDataByType={(type: string) => handleGetProtocolDataByType(type)}
                      disabledByTypeAndProtocol={(type: string, protocol: string) =>
                        disabledByTypeAndProtocol(type, protocol)
                      }
                      getPortRangeDataByType={(type: string, protocol: string) =>
                        handleGetPortRangeDataByType(type, protocol)
                      }
                      handleDelete={(indexToRemove: number) => {
                        handleDelete(indexToRemove, 'INBOUND');
                      }}
                      handleCreate={() => {
                        handleCreateRow('INBOUND');
                      }}
                      isSubmit={isSubmit}
                    />
                  </div>
                </div>
              </div>

              <div className="nw-acl-tags-container">
                <div className="tags-title">Outbound rules</div>
                <div className="tags-body-container">
                  <div className="edit-inbound-rules-model" style={{ width: '100%', padding: '16px' }}>
                    <NetworkRuleTable
                      btnName="Delete"
                      rows={ruleOutboundRows}
                      sourceDestinationOption={sourceDestinationOption}
                      handleChange={(index: number, value: string, propName: keyof RuleRowData) => {
                        handleChange(index, value, propName, 'OUTBOUND');
                      }}
                      disabledByType={(type: string) => disabledByType(type)}
                      getProtocolDataByType={(type: string) => handleGetProtocolDataByType(type)}
                      disabledByTypeAndProtocol={(type: string, protocol: string) =>
                        disabledByTypeAndProtocol(type, protocol)
                      }
                      getPortRangeDataByType={(type: string, protocol: string) =>
                        handleGetPortRangeDataByType(type, protocol)
                      }
                      handleDelete={(indexToRemove: number) => {
                        handleDelete(indexToRemove, 'OUTBOUND');
                      }}
                      handleCreate={() => {
                        handleCreateRow('OUTBOUND');
                      }}
                      isSubmit={isSubmit}
                    />
                  </div>
                </div>
              </div>

              <div className="nw-acl-tags-container">
                <div className="tags-title">Tags</div>
                <div className="tags-body-container">
                  {isShow ? (
                    <TagsList
                      tags={listTags}
                      handleRemoveTag={handleRemoveTag}
                      handleUpdateTag={(key: string, value: string, index: number) => {
                        handleUpdateTag(key, value, index);
                      }}
                      isSubmit={isSubmit}
                    />
                  ) : (
                    <NoItem
                      handleShowTagsList={() => {
                        handleShowTagsList();
                      }}
                    />
                  )}
                  {isShow && (
                    <button
                      className="button add-tag-button"
                      style={{ marginLeft: 16, marginBottom: 16 }}
                      onClick={() => {
                        handleAddTag();
                      }}
                    >
                      Add tag
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="nw-acl-container-bottom">
              <button
                className="button"
                style={{ margin: 0 }}
                onClick={() => {
                  pageBackClick();
                }}
              >
                Cancel
              </button>
              <button
                className="button add-tag-button"
                style={{ marginLeft: 8 }}
                onClick={() => {
                  handleCreate();
                }}
              >
                Create security group
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityGroupCreation;
