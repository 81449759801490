import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import LabelInput from 'components/v2/LabelInput';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import Labeled from 'components/v2/atoms/Labeled';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { enumFormatter } from 'utils/Formatter';
import lazyGetDashboardTemplatesByType from 'graphql/queries/getDashboardTemplatesByType';
import { DashboardTemplatesType } from 'graphql/types/DashboardTemplatesType';
import addDashboard, { IAddDashboardVariables } from 'graphql/mutations/addDashboard';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';

interface ICreateUserDashboardModalProps extends IBaseModalProps {
  organId: string | undefined;
  content?: () => JSX.Element;
  onConfirm?: (data: string) => void;
}

const CreateUserDashboardModal = ({
  organId,
  title,
  content,
  onConfirm,
  ...baseModalProps
}:ICreateUserDashboardModalProps) => {
  const [parameter, setParameter] = useState({
    name: '',
    type: '',
    template: -1
  });
  const [requireParams, setRequireParams] = useState<{[key: string]: string}>({});
  const [typeList, setTypeList] = useState<{name: string, value: string}[]>([]);
  const [templateList, setTemplateList] = useState<DashboardTemplatesType[]>([]);
  const [getEnumTypeCode] = lazyGetEnumTypeCode();
  const [getTemplateList] = lazyGetDashboardTemplatesByType();

  const [createDashboard] = addDashboard();

  const findOneTemplateData = useMemo(() => templateList.find(val => val.id === parameter.template),[parameter.template]);

  const onConfirmClick = () => {
    if(organId) {
      const addDashboardData:IAddDashboardVariables = {
        reqData: {
          name: parameter.name,
          organId: Number(organId),
          templateId: parameter.template,
          parameters: JSON.stringify(requireParams)
        }
      }

      createDashboard({ variables: addDashboardData }).then(({ data }) => {
        if (data) {
          if (data.addDashboard.result === ErrorCode.SUCCESS) {
            useToast(ErrorCode.SUCCESS, 'Dashboard is created successfully.');
            onConfirm && onConfirm(parameter.name);
          } else {
            useToast(ErrorCode.UNKNOWN, 'Create dashboard failed.');
          }
        } else {
          useToast(ErrorCode.UNKNOWN, 'Create dashboard failed.');
        }
      });
    }
  }

  useEffect(() => {
    if(baseModalProps.open) {
      getEnumTypeCode({variables: { text: 'DashboardType' }}).then(res => {
        if (res.data) {
          setTypeList(res.data.getEnumTypeCode.data.map(val => ({
            name: enumFormatter(val.value),
            value: val.value
          })));
        }
      });

      setParameter({
        name: '',
        type: '',
        template: -1
      });
      setRequireParams({});
    }
  },[baseModalProps.open]);

  useEffect(() => {
    if(baseModalProps.open) {
      if(parameter.type !== '') {
        getTemplateList({ variables: { type: parameter.type }}).then(res => {
          setParameter(prev => ({...prev, template: -1}))
          if(res.data) {
            setTemplateList(res.data.getDashboardTemplatesByType.data);
          }
        })
      }
    }
  },[parameter.type]);
  useEffect(() => {
    if(baseModalProps.open) {
      if(findOneTemplateData) {
        const paramArray = JSON.parse(findOneTemplateData.reqParams) as Array<string>;
        let newObject = {};
        paramArray.forEach(element => {
          newObject = {...newObject, [element]: ''};
        });
        setRequireParams(newObject);
      } else {
        setRequireParams({});
      }
    }
  },[findOneTemplateData]);

  return (
    <BaseModal
      title={title}
      className="uniform"
      closeIcon
      {...baseModalProps}
    >
      <div className="add-user-dashboard-modal">
        <div className="input-box">
          <LabelInput title="Name"
            value={parameter.name}
            onChangeValue={(val: string) => setParameter(prev => ({
              ...prev,
              name: val
            }))}
            required
            placeholder='Input dashboard name'
          />
          <Labeled 
            title="Type"
            required
          >
          <DropdownAtom 
            id="type"
            placeholder='Select your user dashboard type'
            data={typeList} 
            value={{
              name: typeList.find(val => val.value === parameter.type)
                ? typeList.find(val => val.value === parameter.type)?.name
                : ''
              ,
              value: parameter.type
            }} 
            handleClick={(val) => setParameter(prev => ({
              ...prev,
              type: val.value as string
            }))}
          />
          </Labeled>
          <Labeled 
            title="Template"
            required
          >
          <DropdownAtom
            id="template"
            placeholder='Select your user dashboard template'
            data={templateList.map(d => ({name: d.name, value: d.id}))} 
            value={{
              name: templateList.find(val => val.id === parameter.template)
                ? templateList.find(val => val.id === parameter.template)?.name
                : ''
              ,
              value: parameter.template
            }} 
            handleClick={(val) => setParameter(prev => ({
              ...prev,
              template: val.value as number
            }))}    
          />
          </Labeled>
          <pre className="template-description">
            {parameter.template === -1 ? 
              ' - Select your dashboard template -' : 
              `Resource usage dashboard is show ... \n${Object.keys(requireParams).map(d => `${d}: ...`).join('\n')}`
            }
          </pre>
          {parameter.template !== -1 && 
            <div className="require-parameter-wrap">
              {Object.keys(requireParams).map(key => 
                <LabelInput 
                  key={key}
                  title={key}
                  value={requireParams[key]}
                  onChangeValue={(val: string) => setRequireParams(prev => ({...prev, [key]: val}))}
                  required
                  placeholder='Input value'
                />
              )}
            </div>
          }
        </div>
        <div className="btns flex j-end">
          <button 
            className="big-main-btn-body2regular flex j-center a-center"
            onClick={onConfirmClick}
          >
            Add
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default CreateUserDashboardModal;
