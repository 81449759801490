import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

type IpRangesType = {
  cidrIp: string;
  description?: string;
};

type IpPermissionsType = {
  ipProtocol: string;
  fromPort: number;
  toPort: number;
  ipRanges: IpRangesType[];
};

export interface IAuthorizeAwsSecurityGroupIngressVariables {
  cloudId: number;
  region: string;
  request: {
    groupId: string;
    ipPermissions?: IpPermissionsType[];
  }
}

export interface IAuthorizeAwsSecurityGroupIngressResponseData {
  authorizeAwsSecurityGroupIngress: IGqlResponseData<undefined>;
}

const authorizeAwsSecurityGroupIngressMutation = () => useMutation<IAuthorizeAwsSecurityGroupIngressResponseData, IAuthorizeAwsSecurityGroupIngressVariables>(query);
export default authorizeAwsSecurityGroupIngressMutation;