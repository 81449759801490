import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IRevokeAwsSecurityGroupIngressVariables {
  cloudId: number;
  region: string;
  request: {
    groupId: string;
    securityGroupRuleIds: string[];
  };
}

export interface IRevokeAwsSecurityGroupIngressResponseData {
  revokeAwsSecurityGroupIngress: IGqlResponseData<undefined>;
}

const revokeAwsSecurityGroupIngressMutation = () =>
  useMutation<IRevokeAwsSecurityGroupIngressResponseData, IRevokeAwsSecurityGroupIngressVariables>(query);
export default revokeAwsSecurityGroupIngressMutation;
