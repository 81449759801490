import { useState, useEffect, useCallback, useMemo } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import Icon from 'components/v2/atoms/Icon';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import InputAtom from 'components/v2/atoms/InputAtom';
import IconRequest from 'assets/svgs/v3/ico_edit_vpc_subnets.svg';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';
import { CONTENT_TYPE_MAPPING } from '../Commons/Constant';
import './index.scss';

interface ResponseBodyModalPropsType extends IBaseModalProps {
  header: string;
  name: string;
  contentType: string;
  responseBody: string;
  onSave: (data: any) => void;
}

const CustomResponseModal = (props: ResponseBodyModalPropsType) => {
  const { header, name, contentType, responseBody, onSave, ...baseModalProps } = props;

  const [editName, setEditName] = useState<string>(name);
  const [editContentType, setEditContentType] = useState<string>(contentType);
  const [editResponseBody, setEditResponseBody] = useState<string>(responseBody);

  const [nameValid, setNameValid] = useState<boolean>(false);
  const [responseBodyValid, setResponseBodyValid] = useState<boolean>(false);

  const responseBodyPlaceholder = useMemo((): string => {
    if (editContentType === CONTENT_TYPE_MAPPING['TEXT_HTML'].value) {
      return '<div>error: access denied</div>';
    }
    if (editContentType === CONTENT_TYPE_MAPPING['TEXT_PLAIN'].value) {
      return '';
    }
    return '{\n    "error":"access denied"\n}';
  }, [editContentType]);

  const validateName = useCallback((inputString: string): boolean => {
    if (inputString.length < 1) {
      return false;
    }
    const pattern = /^[A-Za-z0-9_-]+$/;
    return pattern.test(inputString);
  }, []);

  const isTextExceeding4KB = useCallback((text: string): boolean => {
    const byteLength = new Blob([text]).size; // Calculate the size in bytes
    return byteLength > 4 * 1024; // 4KB = 4096 bytes
  }, []);

  const onSaveBtnClicked = useCallback(() => {
    setNameValid(editName === '');
    setResponseBodyValid(editResponseBody.trim() === '');
    if (name !== '' || editResponseBody.trim() !== '') {
      onSave({ name: editName, contentType: editContentType, responseBody: editResponseBody });
    }
  }, [editName, editResponseBody, onSave, editContentType]);

  useEffect(() => {
    if (baseModalProps.open) {
      setEditName(name);
      setEditContentType(contentType);
      setEditResponseBody(responseBody);
    }
  }, [name, contentType, responseBody, baseModalProps.open]);

  return (
    <BaseModal
      {...baseModalProps}
      title={() => (
        <>
          <Icon width={32} height={32} src={IconRequest} />
          <div className="title-header-modal">{header}</div>
        </>
      )}
    >
      <div className="response-body-modal">
        <div className={'content-container flex col'}>
          <div className="header-container">
            <p className="header">Details</p>
            <p className="title">Response body object name</p>
            <p className="description">
              Enter the name for the response body. You’ll use the name to reference this response body in the web ACL
              rules.
            </p>
          </div>
          <div className="input-container">
            <InputAtom
              placeholder="Custom response body name"
              value={editName}
              onChangeValue={val => {
                if (val.length <= 128) setEditName(val);
              }}
              error={nameValid}
            />
            <p className="input-note">
              The name must have 1-128 characters. Valid characters: A-Z, a-z, 0-9, - (hyphen), and _ (underscore).
            </p>
          </div>
          <div className="header-container">
            <p className="description">Content type</p>
          </div>
          <div className="input-container">
            <RadioAtom
              label={CONTENT_TYPE_MAPPING['APPLICATION_JSON'].radioLable}
              value={CONTENT_TYPE_MAPPING['APPLICATION_JSON'].value}
              name={'content-type'}
              checked={editContentType}
              onChange={() => setEditContentType(CONTENT_TYPE_MAPPING['APPLICATION_JSON'].value)}
            />
            <RadioAtom
              label={CONTENT_TYPE_MAPPING['TEXT_HTML'].radioLable}
              value={CONTENT_TYPE_MAPPING['TEXT_HTML'].value}
              name={'content-type'}
              checked={editContentType}
              onChange={() => setEditContentType(CONTENT_TYPE_MAPPING['TEXT_HTML'].value)}
            />
            <RadioAtom
              label={CONTENT_TYPE_MAPPING['TEXT_PLAIN'].radioLable}
              value={CONTENT_TYPE_MAPPING['TEXT_PLAIN'].value}
              name={'content-type'}
              checked={editContentType}
              onChange={() => setEditContentType(CONTENT_TYPE_MAPPING['TEXT_PLAIN'].value)}
            />
          </div>
          <div className="header-container">
            <p className="title">Response body</p>
            <p className="description">The response body can be plain text, HTML, or JSON</p>
          </div>
          <div className="input-container">
            <TextareaAtom
              required
              value={editResponseBody}
              onChangeValue={setEditResponseBody}
              placeholder={responseBodyPlaceholder}
              error={responseBodyValid}
            />
            <p className="input-note">Response body cannot exceed 4 KB in size.</p>
          </div>
        </div>
        <div className="button-group">
          <button className="big-sub-btn" onClick={baseModalProps.onClose}>
            Cancel
          </button>
          <button
            className="big-main-btn"
            disabled={!validateName(editName) || editResponseBody.length === 0 || isTextExceeding4KB(editResponseBody)}
            onClick={onSaveBtnClicked}
          >
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default CustomResponseModal;
