import { gql } from '@apollo/client';

const query = gql`
  query getAwsValidateTemplate($request: AwsValidateTemplateRequest, $cloudId: Long, $region: String) {
    getAwsValidateTemplate(validateTemplateRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsValidateTemplateResponse {
          parameters {
            parameterKey
            defaultValue
            noEcho
            description
          }
          description
          capabilities
          capabilitiesReason
          declaredTransforms
        }
      }
    }
  }
`;
export default query;
