import './style.scss';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdLayout';
import DetailTab from '../../../DetailTab';
import { getFormatedDateWithTimezone } from 'utils/Common';
import { useEffect, useState } from 'react';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import lazyGetAwsUser, { IAwsUserVariables } from 'graphql/queries/getAwsUser';
import lazyGetAwsListMFADevices, { IAwsListMFADevicesVariables } from 'graphql/queries/getAwsListMFADevices';
import lazyGetAwsListAccesskeys, { IAwsListAccesskeysVariables } from 'graphql/queries/getAwsListAccesskeys';
import lazyGetAwsAccessKeyLastUsed, { IAwsAccessKeyLastUsedVariables } from 'graphql/queries/getAwsAccessKeyLastUsed';
import lazyGetAwsLoginProfile, { IAwsLoginProfileVariables } from 'graphql/queries/getAwsLoginProfile';

function Summary({ cloudId, region, data = {} }: any) {
  const { userName, group } = data;

  const { groupName, createDate, arn } = group || {};

  const [isLoading, setIsLoading] = useState(false);
  const [sumaryData, setSumaryData] = useState<any>(undefined);

  const [getAwsUser] = lazyGetAwsUser();
  const [getAwsListMFADevices] = lazyGetAwsListMFADevices();
  const [getAwsListAccesskeys] = lazyGetAwsListAccesskeys();
  const [getAwsAccessKeyLastUsed] = lazyGetAwsAccessKeyLastUsed();
  const [getAwsLoginProfile] = lazyGetAwsLoginProfile();

  const handleGetAwsUser = (userName: string) => {
    const requestVariable: IAwsUserVariables = {
      cloudId,
      region,
      request: {
        userName,
      },
    };
    return getAwsUser({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsListMFADevices = (userName: string) => {
    const requestVariable: IAwsListMFADevicesVariables = {
      cloudId,
      region,
      request: {
        userName,
      },
    };

    const combinedVariable = variableCombineNextToken(requestVariable);
    return getAwsListMFADevices({ variables: combinedVariable });
  };

  const handleGetAwsListAccesskeys = (userName: string) => {
    const requestVariable: IAwsListAccesskeysVariables = {
      cloudId,
      region,
      request: {
        userName,
      },
    };

    const combinedVariable = variableCombineNextToken(requestVariable);
    return getAwsListAccesskeys({ variables: combinedVariable });
  };

  const handleGetAwsAccessKeyLastUsed = (accessKeyId: string) => {
    const requestVariable: IAwsAccessKeyLastUsedVariables = {
      cloudId,
      region,
      request: {
        accessKeyId,
      },
    };

    const combinedVariable = variableCombineNextToken(requestVariable);
    return getAwsAccessKeyLastUsed({ variables: combinedVariable });
  };

  const handleGetAwsLoginProfile = (userName: string) => {
    const requestVariable: IAwsLoginProfileVariables = {
      cloudId,
      region,
      request: {
        userName,
      },
    };

    const combinedVariable = variableCombineNextToken(requestVariable);
    return getAwsLoginProfile({ variables: combinedVariable });
  };

  const getBetweenTwoDate = (value: any) => {
    if (!value) return '';

    const currentDate = new Date();
    const prevTime = new Date(value);

    // @ts-ignore
    const diffMs = currentDate - prevTime;
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
    const diffMinutes = Math.floor(diffMs / (1000 * 60 * 60 * 24) / (1000 * 60));

    if (diffDays === 0) {
      if (diffHours > 0) {
        return `${diffHours} hours ago`;
      } else if (diffMinutes > 0) {
        return `${diffMinutes} minutes ago`;
      } else {
        return 'Just now';
      }
    } else if (diffDays === 1) {
      return 'Yesterday';
    } else {
      return `${diffDays} days ago`;
    }
  };

  const handleGetDetail = async () => {
    try {
      setIsLoading(true);
      const [user, listMFADevices, loginProfile, listAccesskeys] = await Promise.all([
        handleGetAwsUser(userName),
        handleGetAwsListMFADevices(userName),
        handleGetAwsLoginProfile(userName),
        handleGetAwsListAccesskeys(userName),
      ]);

      let accessKeyLastUsed = '';

      if (listAccesskeys?.data?.getAwsListAccesskeys?.data?.[0]?.accessKeyMetadata?.length) {
        const result = await handleGetAwsAccessKeyLastUsed(
          listAccesskeys?.data?.getAwsListAccesskeys?.data?.[0]?.accessKeyMetadata?.[0]?.accessKeyId,
        );

        accessKeyLastUsed = getBetweenTwoDate(
          result?.data?.getAwsAccessKeyLastUsed?.data?.[0]?.accessKeyLastUsed?.lastUsedDate,
        );
      }

      setSumaryData({
        arn: user?.data?.getAwsUser?.data?.[0]?.user?.arn,
        consoleAccess: loginProfile?.data?.getAwsLoginProfile?.data
          ? `Enabled ${
              listMFADevices?.data?.getAwsListMFADevices?.data?.[0]?.hasMfaDevices ? 'with MFA' : 'without MFA)'
            }`
          : 'Disabled',
        accessKey: listAccesskeys?.data?.getAwsListAccesskeys?.data?.[0]?.accessKeyMetadata?.map(e => ({
          ...e,
          accessKeyLastUsed,
        })),
        created: getFormatedDateWithTimezone(user?.data?.getAwsUser?.data?.[0]?.user?.createDate),
        lastConsoleSignIn: getBetweenTwoDate(user?.data?.getAwsUser?.data?.[0]?.user?.passwordLastUsed),
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!userName) return;

    handleGetDetail();
  }, [userName]);

  const summary: IMgdDetailKeyValueProps[] = [
    {
      title: 'ARN',
      description: sumaryData?.arn,
    },
    {
      title: 'Console access',
      description: getFormatedDateWithTimezone(createDate, 'MMM DD, YYYY HH:MM'),
    },
    {
      title: `Access key ${sumaryData?.accessKey?.length ? 1 : ''}`,
      description: `${sumaryData?.accessKey?.[0]?.accessKeyId || ''} - ${sumaryData?.accessKey?.[0]?.status || ''}`,
    },
    {
      title: 'Created',
      description: sumaryData?.created,
    },
    {
      title: 'Last console sign-in',
      description: sumaryData?.lastConsoleSignIn,
    },
  ];

  if (isLoading) {
    return (
      <div className="detail-info">
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      </div>
    );
  }

  return (
    <div className="user-group-details-tab">
      <DetailTab title="Summary" data={summary} />
    </div>
  );
}

export default Summary;
