import { gql } from '@apollo/client';

const query = gql`
  query getAwsListPolicies($request: AwsListPoliciesRequest, $cloudId: Long, $region: String) {
    getAwsListPolicies(listPoliciesRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListPoliciesResponse {
          policies {
            policyName
            policyId
            arn
            path
            defaultVersionId
            attachmentCount
            permissionsBoundaryUsageCount
            isAttachable
            description
            createDate
            updateDate
            tags {
              key
              value
            }
          }
          isTruncated
          marker
        }
      }
    }
  }
`;
export default query;
