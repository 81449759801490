import { Fragment, ReactNode, useCallback, useEffect, useState } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import './index.scss';
import Icon from 'components/v2/atoms/Icon';
import InboundIcon from 'assets/svgs/v3/ico_inbound.svg';
import OutboundIcon from 'assets/svgs/v3/ico_outbound.svg';
import {
  disabledByTypeAndProtocol,
  getIcmpTypeCode,
  getPortRangeDataByType,
  getProtocolValue,
  getProtocolData,
  getPortRangeValue,
  getProtocolDisabled,
  getPortRangeDisabled,
  getPortRangeData,
  getProtocolData2,
} from '../../../../Function';
import { RULE_TYPE_DROPDOWN } from '../../../../Constants';
import CidrRegex from 'utils/Regex/CidrRegex';
import { AwsIcmpTypeCodeLog } from 'graphql/types/AwsNetworkAcl';

interface IEditNetworkRuleModalPropsType extends IBaseModalProps {
  header: string;
  isInbound: boolean;
  currentEntries: RuleRowData[];
  onSave: (data: RuleRowData[], isInbound: boolean) => void;
}

export interface RuleRowData {
  ruleNumber: string;
  type: string;
  icmpTypeCode?: AwsIcmpTypeCodeLog;
  ipv6CidrBlock?: string;
  protocol: string;
  portRange: string;
  cidrBlock: string;
  action: string;
  ruleNumberError?: string;
  portRanegError?: string;
  cidrBlockError?: string;
}

const actionList: DropdownListDataType[] = [
  { name: 'Allow', value: 'allow' },
  { name: 'Deny', value: 'deny' },
];

interface IEditNetworkRuleTable {
  ruleEntries: RuleRowData[];
  handleChange: (index: number, value: string, propName: keyof RuleRowData) => void;
  getProtocolDisabled: (type: string) => boolean;
  getProtocolData: (type: string) => DropdownListDataType[];
  disabledByTypeAndProtocol: (type: string, protocol: string) => boolean;
  getPortRangeDataByType: (type: string, protocol: string) => DropdownListDataType[];
  handleDelete: (indexToRemove: number) => void;
  handleCreate: () => void;
  btnName?: string;
}

export const NetworkRuleTable = ({
  ruleEntries,
  handleChange,
  getProtocolDisabled,
  getProtocolData,
  disabledByTypeAndProtocol,
  getPortRangeDataByType,
  handleDelete,
  handleCreate,
  btnName,
}: IEditNetworkRuleTable) => {
  const protocolNode = useCallback(
    (index: number, entry: RuleRowData): ReactNode => {
      let protocolValue = entry.icmpTypeCode ? entry.icmpTypeCode.type : entry.protocol;

      // if (entry?.icmpTypeCode?.type === -1 || entry?.icmpTypeCode?.type == 3 || entry?.icmpTypeCode?.type == 5) {
      //   protocolValue = entry.protocol;
      // }
      if (entry?.icmpTypeCode?.type) {
        protocolValue = entry?.icmpTypeCode?.type;
      }

      const protocolData = getProtocolData2(entry.type, entry?.icmpTypeCode);
      const protocolName = protocolData.find(item => item.value === protocolValue.toString())?.name ?? '';

      return (
        <DropdownAtom
          id={`protocol-rules-${index}`}
          disabled={getProtocolDisabled(entry.type)}
          data={protocolData}
          value={{
            name: protocolName,
            value: protocolValue,
          }}
          handleClick={val => handleChange(index, val.value as string, 'protocol')}
        />
      );
    },
    [getProtocolDisabled, getProtocolData, handleChange],
  );

  const portRangeNode = useCallback(
    (index: number, entry: RuleRowData): ReactNode => {
      // render dropdown with ICMP-Type-Code
      // protocol: Destination Unreachable
      if (entry?.icmpTypeCode?.code && entry?.icmpTypeCode?.type) {
        // getPortRangeData
        const portRangeValue = entry.icmpTypeCode.code;
        const portRangeData = getPortRangeData(entry.type, entry?.icmpTypeCode);
        const portRangeName =
          portRangeData?.find(item => item.value === entry?.icmpTypeCode?.code.toString())?.name ?? '';
        return (
          <DropdownAtom
            id={`port-range-${index}`}
            disabled={getPortRangeDisabled(entry.type, entry.protocol, entry?.icmpTypeCode)}
            data={portRangeData}
            value={{
              name: portRangeName,
              value: entry?.icmpTypeCode?.code,
            }}
            handleClick={val => handleChange(index, val.value as string, 'protocol')}
          />
        );
      }
      return (
        <InputAtom
          id={`port-range-${index}`}
          value={entry.portRange}
          error={entry?.portRanegError}
          disabled={getPortRangeDisabled(entry.type, entry.protocol)}
          noClear={true}
          onChangeValue={(value: string) => handleChange(index, value, 'portRange')}
        />
      );
    },
    [getPortRangeDisabled, handleChange, getProtocolDisabled],
  );

  return (
    <Fragment>
      <table className="table-rules">
        <thead className="table-header">
          <tr>
            <th>Rule number</th>
            <th>Type</th>
            <th>Protocol</th>
            <th>Port range</th>
            <th>Source</th>
            <th>Allow/Deny</th>
          </tr>
        </thead>

        <tbody className="table-body">
          {ruleEntries.map((entry, index) => {
            return (
              <tr key={index}>
                {/* Rule Number */}
                <td>
                  <InputAtom
                    id={`rule-number-${index}`}
                    value={entry.ruleNumber}
                    noClear={true}
                    error={entry?.ruleNumberError}
                    onChangeValue={(value: string) => handleChange(index, value, 'ruleNumber')}
                  />
                </td>
                {/* Type */}
                <td>
                  <DropdownAtom
                    id={`type-rules-${index}`}
                    data={RULE_TYPE_DROPDOWN}
                    value={{
                      name: RULE_TYPE_DROPDOWN.find(val => val.value === entry.type)?.name || '',
                      value: entry.type,
                    }}
                    handleClick={val => handleChange(index, val.value as string, 'type')}
                  />
                </td>
                {/* Protocol */}
                <td>{protocolNode(index, entry)}</td>
                {/* Port Range */}
                <td>{portRangeNode(index, entry)}</td>
                {/* CIRD Source */}
                <td>
                  <InputAtom
                    id={`source-${index}`}
                    value={entry.cidrBlock}
                    noClear={true}
                    error={entry?.cidrBlockError}
                    onChangeValue={(value: string) => handleChange(index, value, 'cidrBlock')}
                  />
                </td>
                {/* Action */}
                <td>
                  <DropdownAtom
                    id={`action-rules-${index}`}
                    data={actionList}
                    value={{
                      name: actionList.find(val => val.value === entry.action)?.name
                        ? actionList.find(val => val.value === entry.action)?.name
                        : '',
                      value: entry.action,
                    }}
                    handleClick={(val: any) => handleChange(index, val.value, 'action')}
                  />
                </td>
                <td>
                  <button onClick={() => handleDelete(index)}>{`${btnName ? btnName : 'Remove'}`}</button>
                </td>
              </tr>
            );
          })}
          <tr className="row-default-rules">
            <td>
              <InputAtom value={'*'} disabled={true} />
            </td>
            <td>
              <DropdownAtom
                id={'type-rules'}
                disabled={true}
                placeholder={'All traffic'}
                data={[]}
                value={{
                  name: '',
                  value: '',
                }}
                handleClick={() => {}}
              />
            </td>
            <td>
              <DropdownAtom
                id={'protocol-rules'}
                disabled={true}
                placeholder={'All'}
                data={[]}
                value={{
                  name: '',
                  value: '',
                }}
                handleClick={() => {}}
              />
            </td>
            <td>
              <InputAtom disabled={true} value={'All'} />
            </td>
            <td>
              <InputAtom disabled={true} value={'0.0.0.0/0'} />
            </td>
            <td>
              <DropdownAtom
                id={'action-rules'}
                disabled={true}
                placeholder={'Deny'}
                data={[]}
                value={{
                  name: '',
                  value: '',
                }}
                handleClick={() => {}}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div className="add-rule-btn-container">
        <button className="add-rule-btn" onClick={() => handleCreate()}>
          Add new rule
        </button>
      </div>
    </Fragment>
  );
};

const EditNetworkRuleModal = (props: IEditNetworkRuleModalPropsType) => {
  const { header, isInbound, currentEntries, onSave, ...baseModalProps } = props;
  const [ruleEntries, setRuleEntries] = useState<RuleRowData[]>([]);

  useEffect(() => {
    if (baseModalProps.open) {
      setRuleEntries([...currentEntries]);
    }
  }, [baseModalProps.open]);

  const handleCreate = useCallback(() => {
    setRuleEntries(prevState => {
      return prevState.concat({
        ruleNumber: '',
        type: 'custom_tcp',
        protocol: '6',
        portRange: '0',
        cidrBlock: '0.0.0.0/0',
        action: 'allow',
        // icmpTypeCode: undefined,
        // ipv6CidrBlock: undefined
      });
    });
  }, []);

  const handleDelete = (indexToRemove: number) => {
    const newData = ruleEntries.filter((_, index) => index != indexToRemove);
    setRuleEntries(newData);
  };

  const handleChange = useCallback(
    (onChangeIndex: number, value: string, propName: keyof RuleRowData) => {
      const newRows = ruleEntries.map((row, idx) => {
        if (idx == onChangeIndex) {
          const newRow = { ...row, [propName]: value };
          if (propName === 'type') {
            const protocolValue = getProtocolValue(value);
            newRow.portRange = getPortRangeValue(value, protocolValue);
            newRow.protocol = protocolValue;
            // get icmpTypeCode
            newRow.icmpTypeCode = getIcmpTypeCode(value, row.protocol, row?.portRange);
          }
          if (propName === 'protocol') {
            newRow.protocol = value;
            newRow.portRange = getPortRangeValue(row.type, value);
          }
          if (propName === 'portRange') {
            if (newRow.protocol === '3') {
              const imcpTypeCode: AwsIcmpTypeCodeLog = {
                type: parseInt(newRow.protocol),
                code: parseInt(value),
              };
              newRow.icmpTypeCode = imcpTypeCode;
            }
          }
          return newRow;
        } else {
          return row;
        }
      });
      setRuleEntries(newRows);
    },
    [ruleEntries],
  );

  const validateRuleNumber = useCallback(
    (ruleNumber: string): string | undefined => {
      //Rule number must be a number in the range 1 to 32766.
      //Rule number is already in use.
      if (ruleNumber === '') {
        return 'Rule number must be a number in the range 1 to 32766.';
      } else {
        if (ruleEntries.filter(entry => entry.ruleNumber === ruleNumber).length > 1) {
          return 'Rule number is already in use.';
        }
      }
      return undefined;
    },
    [ruleEntries],
  );

  const validatePortRange = useCallback((portRange: string): string | undefined => {
    if (portRange === 'null') {
      return undefined;
    }
    //The port range must be a number between 0 and 65535, or two numbers separated by a dash (e.g. 21-940).
    let portRangeMsg: string | undefined = undefined;
    const erroMessage =
      'The port range must be a number between 0 and 65535, or two numbers separated by a dash (e.g. 21-940).';
    const portRangeRegex =
      /^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])(?:-([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?$/;

    const portRangeReplaced = portRange.replaceAll(' ', '');
    if (!portRangeRegex.test(portRangeReplaced)) {
      portRangeMsg = erroMessage;
    } else {
      if (portRangeReplaced.includes('-')) {
        const portRangeSplited = portRangeReplaced.split('-');
        if (parseInt(portRangeSplited[1]) < parseInt(portRangeSplited[0])) {
          portRangeMsg = erroMessage;
        }
      }
    }
    if (portRange === '' || portRange === 'All' || portRange === 'N/A') {
      portRangeMsg = undefined;
    }
    return portRangeMsg;
  }, []);

  const validateCidrBlock = useCallback((cidrBlock: string): string | undefined => {
    //The source needs to be an IPv4 or IPv6 CIDR block.
    let cirdErrorMsg: string | undefined = '';

    if (!CidrRegex({ exact: true }).test(cidrBlock)) {
      cirdErrorMsg = 'The source needs to be an IPv4 or IPv6 CIDR block.';
    } else {
      cirdErrorMsg = undefined;
    }
    return cirdErrorMsg;
  }, []);

  const handleOnSaveEntries = useCallback(() => {
    let dataValidated = true;
    const ruleValitedEntries: RuleRowData[] = [];
    ruleEntries.map(entry => {
      const validateEntry = { ...entry };
      const ruleNumberErrorMsg = validateRuleNumber(entry.ruleNumber);
      const portRangeErrorMsg = validatePortRange(entry?.portRange);
      const cidrBlockErrorMsg = validateCidrBlock(entry.cidrBlock);
      if (typeof ruleNumberErrorMsg !== 'undefined') {
        dataValidated = false;
        validateEntry.ruleNumberError = ruleNumberErrorMsg;
      }
      if (typeof portRangeErrorMsg !== 'undefined') {
        dataValidated = false;
        validateEntry.portRanegError = portRangeErrorMsg;
      }
      if (typeof cidrBlockErrorMsg !== 'undefined') {
        dataValidated = false;
        validateEntry.cidrBlockError = cidrBlockErrorMsg;
      }
      ruleValitedEntries.push(validateEntry);
    });
    if (dataValidated) {
      onSave(ruleEntries, isInbound);
    } else {
      setRuleEntries(ruleValitedEntries);
    }
  }, [ruleEntries, isInbound, validateRuleNumber, validatePortRange, validateCidrBlock]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={isInbound ? InboundIcon : OutboundIcon} />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-inbound-rules-model">
        <NetworkRuleTable
          ruleEntries={ruleEntries}
          handleChange={(index: number, value: string, propName: keyof RuleRowData) => {
            handleChange(index, value, propName);
          }}
          getProtocolDisabled={(type: string) => getProtocolDisabled(type)}
          getProtocolData={(type: string) => getProtocolData(type)}
          disabledByTypeAndProtocol={(type: string, protocol: string) => disabledByTypeAndProtocol(type, protocol)}
          getPortRangeDataByType={(type: string, protocol: string) => getPortRangeDataByType(type, protocol)}
          handleDelete={(indexToRemove: number) => {
            handleDelete(indexToRemove);
          }}
          handleCreate={() => {
            handleCreate();
          }}
        />

        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="save-btn" onClick={handleOnSaveEntries}>
            Save changes
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditNetworkRuleModal;
