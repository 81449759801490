import './index.scss';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';

interface IDeleteUserDashboardModalProps extends IBaseModalProps {
  onConfirm: () => void;
}

const DeleteUserDashboardModal = ({
  title,
  onConfirm,
  ...baseModalProps
}:IDeleteUserDashboardModalProps) => {
  return (
    <BaseModal
      title={title}
      className="uniform"
      closeIcon
      {...baseModalProps}
    >
      <div className="delete-user-dashboard-modal">
        <div className="text-box">
        Remove dashboard from your menu.
        </div>
        <div className="btns flex j-end">
          <button 
            className="big-main-btn flex j-center a-center"
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteUserDashboardModal;
