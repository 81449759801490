import { useState, useMemo, useCallback, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconCloud from 'assets/svgs/v3/ico_cloud.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import TableCheckbox from 'components/v2/dataDisplay/TableCheckbox';

interface IEditCloudworkMetricsModalProps extends IBaseModalProps {
  header: string;
  currentRows: RowType[];
  currentColumns: ColumnType[];
  reportCheckedList?: (list: string[]) => void;
  reportSelected?: (select: string) => void;
  sortOption?: {
    target: string;
    direction: OrderDirection;
    onChangeSort: (target: string, dir: OrderDirection) => void;
  };
  numberOption?: number;
  horizontalScrollable?: boolean;
  isAllRowChecked?: boolean;
  isLoading?: boolean;
  onChangeValue?: (index: number, value: string) => void;
  onSaveButtonClicked: (rules: any) => void;
}

const EditCloudworkMetricsModal = ({
  header,
  currentRows,
  currentColumns,
  className,
  reportCheckedList,
  reportSelected,
  sortOption,
  numberOption = undefined,
  horizontalScrollable = false,
  isAllRowChecked = false,
  isLoading = false,
  onChangeValue,
  onSaveButtonClicked,
  ...baseModalProps
}: IEditCloudworkMetricsModalProps) => {
  const [checkedList, setCheckedList] = useState<string[]>([]);

  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [newRowsData, setNewRowsData] = useState(currentRows);
  useEffect(() => {
    if (isAllRowChecked) {
      const ids = currentRows.map(row => String(row.id));
      setCheckedList(ids);
      reportCheckedList && reportCheckedList(ids);
    } else {
      setCheckedList([]);
      reportCheckedList && reportCheckedList([]);
    }
  }, [currentRows]);

  const renderInputCell = useCallback(
    (rowIdx: number) => {
      return (
        <div>
          <input
            value={newRowsData[rowIdx].cloudmetricName}
            onChange={value => {
              const updatedRows = newRowsData.map((r, i) => {
                if (i === rowIdx) {
                  return {
                    ...r,
                    cloudmetricName: value?.target?.value ?? '',
                  };
                }
                return r;
              });
              if (value?.target?.value !== currentRows[rowIdx].cloudmetricName) {
                setButtonDisabled(true);
              }
              setNewRowsData(updatedRows);
            }}
          ></input>
        </div>
      );
    },
    [newRowsData, currentRows],
  );

  const renderBody = useMemo(() => {
    if (isLoading) {
      return (
        <tr className="progresss">
          <td colSpan={currentColumns.length + 1}>
            <div className="progress-container">
              <div className="progress-gif" />
              Loading ...
            </div>
          </td>
        </tr>
      );
    }

    return currentRows.map((row, rowIdx) => (
      <tr
        key={`row-${rowIdx}`}
        onClick={() => {
          reportSelected && reportSelected(String(row.id));
          setCheckedList([]);

          if (horizontalScrollable && reportCheckedList) {
            setCheckedList([String(row.id)]);
          }
        }}
      >
        {reportCheckedList && (
          <td>
            <div className="td__checkbox">
              <div onClick={e => e.stopPropagation()} className="td__checkbox__wrap">
                <TableCheckbox
                  defaultValue={checkedList.includes(String(row.id))}
                  onChange={() => {
                    if (checkedList.includes(String(row.id))) {
                      const left = checkedList.filter(li => li !== String(row.id));
                      setCheckedList(left);
                      reportCheckedList(left);
                    } else {
                      setCheckedList([...checkedList, String(row.id)]);
                      reportCheckedList([...checkedList, String(row.id)]);
                    }
                  }}
                />
              </div>
            </div>
          </td>
        )}
        {numberOption && (
          <td>{numberOption === 1 ? rowIdx + 1 : currentRows.length * (numberOption - 1) + rowIdx + 1}</td>
        )}
        {currentColumns.map((col, idx) => (
          <td key={`row-${rowIdx}-col-${idx}`}>
            {idx % 2 == 0 && <div>{currentRows[rowIdx].rules}</div>}
            {idx % 2 != 0 && renderInputCell(rowIdx)}
          </td>
        ))}
      </tr>
    ));
  }, [currentRows, currentColumns, sortOption, checkedList, isLoading, renderInputCell]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconCloud} />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-cloudwork-metrics-modal">
        <div className="edit-cloudwork-metrics">
          <div className="row-1">
            <p className="text-title">Nemo Cloudwatch metrics</p>
            <p className="text-description">
              CloudWatch metrics allow you to monitor web requests, web ACLs, and rules.
            </p>
          </div>

          <div className="row-2">
            <div className={`data-table ${className}`}>
              <table>
                {!horizontalScrollable && (
                  <thead>
                    <tr className="header">
                      {reportCheckedList && (
                        <th style={{ width: '87px', maxWidth: '87px' }}>
                          <div className="th__checkbox">
                            <TableCheckbox
                              defaultValue={checkedList.length > 0 && checkedList.length === currentRows.length}
                              onChange={() => {
                                const ids = currentRows.map(row => String(row.id));
                                if (checkedList.length === currentRows.length) {
                                  setCheckedList([]);
                                  reportCheckedList([]);
                                } else {
                                  setCheckedList(ids);
                                  reportCheckedList(ids);
                                }
                              }}
                            />
                          </div>
                        </th>
                      )}
                      {numberOption && <th style={{ width: '88px', maxWidth: '88px', paddingLeft: '32px' }}>#</th>}
                      {currentColumns.map((col, idx) => (
                        <th key={`col-${idx}`} {...(col.width && { style: { width: col.width + 'px' } })}>
                          <div>
                            {col.renderHeader ? col.renderHeader() : col.label ? col.label : col.field}
                            {sortOption && col.sort && (
                              <button
                                className={`sort-btn
                          ${sortOption.direction === OrderDirection.ASC && 'upside-down'} 
                          ${col.field === sortOption.target && 'sorted'}`}
                                onClick={() => {
                                  if (sortOption.target !== col.field) {
                                    sortOption.onChangeSort(col.field, OrderDirection.DES);
                                  } else {
                                    sortOption.onChangeSort(
                                      col.field,
                                      sortOption.direction === OrderDirection.ASC
                                        ? OrderDirection.DES
                                        : OrderDirection.ASC,
                                    );
                                  }
                                }}
                              />
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                )}
                <tbody>
                  {horizontalScrollable && (
                    <tr className="horizontal-header">
                      {reportCheckedList && (
                        <th style={{ width: '87px', maxWidth: '87px' }}>
                          <div className="th__checkbox">
                            <TableCheckbox
                              defaultValue={checkedList.length > 0 && checkedList.length === currentRows.length}
                              // mixed={checkedList.length !== 0 && checkedList.length < rows.length}
                              onChange={() => {
                                const ids = currentRows.map(row => String(row.id));
                                if (checkedList.length === currentRows.length) {
                                  setCheckedList([]);
                                  reportCheckedList([]);
                                } else {
                                  setCheckedList(ids);
                                  reportCheckedList(ids);
                                }
                              }}
                            />
                          </div>
                        </th>
                      )}
                      {numberOption && <th style={{ width: '88px', maxWidth: '88px', paddingLeft: '32px' }}>#</th>}
                      {currentColumns.map((col, idx) => (
                        <th key={`col-${idx}`} {...(col.width && { style: { width: col.width + 'px' } })}>
                          <div>
                            {col.renderHeader ? col.renderHeader() : col.label ? col.label : col.field}
                            {sortOption && col.sort && (
                              <button
                                className={`sort-btn
                          ${sortOption.direction === OrderDirection.ASC && 'upside-down'} 
                          ${col.field === sortOption.target && 'sorted'}`}
                                onClick={() => {
                                  if (sortOption.target !== col.field) {
                                    sortOption.onChangeSort(col.field, OrderDirection.DES);
                                  } else {
                                    sortOption.onChangeSort(
                                      col.field,
                                      sortOption.direction === OrderDirection.ASC
                                        ? OrderDirection.DES
                                        : OrderDirection.ASC,
                                    );
                                  }
                                }}
                              />
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  )}
                  {renderBody}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className={'button-group'}>
          <button className="btn" onClick={baseModalProps.onClose}>
            Cancel
          </button>
          <button className="btn save-btn" disabled={!isButtonDisabled} onClick={() => onSaveButtonClicked(newRowsData)}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditCloudworkMetricsModal;
