import { useState, useMemo, useEffect, useCallback } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import InputAtom from 'components/v2/atoms/InputAtom';
import IconDelete from 'assets/svgs/v3/ico_delete_network.svg';
import Icon from 'components/v2/atoms/Icon';
import { IMgdTablePaginationProps } from 'layouts/v3/MgdLayout';
import { AwsNetworkAclType } from 'graphql/types/AwsNetworkAcl';
import IconWarning from 'assets/svgs/v3/ico_warning.svg';
import './index.scss';
import { orderAlphabetical } from '../../../Utils/Sorting';

interface IDeleteNetworkModalProps extends IBaseModalProps {
  header: string;
  onDelete: (networkAcls: AwsNetworkAclType[]) => void;
  networkAcls: AwsNetworkAclType[];
}

const DeleteNetworkModal = ({ header, onDelete, networkAcls, ...baseModalProps }: IDeleteNetworkModalProps) => {
  const [payload, setPayload] = useState<string>('');

  const isDisableDeleteButton = useMemo(() => {
    return payload != 'delete';
  }, [payload]);

  const [deleteTablePagination, setDeleteTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 100,
    itemPerPage: 100,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [notDeleteTablePagination, setNotDeleteTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 100,
    itemPerPage: 100,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const willBeDeleteNetwork = useMemo((): AwsNetworkAclType[] => {
    const deleteNetworks = networkAcls.filter(network => network?.isDefault === false);
    return orderAlphabetical(
      deleteNetworks,
      deleteTablePagination.target,
      deleteTablePagination.direction,
    ) as AwsNetworkAclType[];
  }, [networkAcls, deleteTablePagination]);

  const cannotBeDeleteNetwork = useMemo(() => {
    const notDeleteNetowrks = networkAcls.filter(network => network?.isDefault === true);
    return orderAlphabetical(
      notDeleteNetowrks,
      notDeleteTablePagination.target,
      notDeleteTablePagination.direction,
    ) as AwsNetworkAclType[];
  }, [networkAcls, notDeleteTablePagination]);

  const columns: ColumnType[] = [
    { label: 'Name', field: 'name', sort: true, renderCell: row => <>{row?.name ?? '-'}</> },
    { label: 'Network ACL ID', field: 'networkAclId', sort: true },
    { label: 'VPC ID', field: 'vpcId', sort: true },
  ];

  const updateTablePagination = useCallback((tableType: string, key: string, value: any) => {
    if (tableType === 'delete') {
      setDeleteTablePagination(prevState => {
        return {
          ...prevState,
          [key]: value,
        };
      });
    } else {
      setNotDeleteTablePagination(prevState => {
        return {
          ...prevState,
          [key]: value,
        };
      });
    }
  }, []);

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload('');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconDelete} />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="delete-network-acl-modal">
        <div className="modal-container">
          {cannotBeDeleteNetwork.length > 0 && (
            <div className="delete-warning-header">
              <div className="delete-warning-title">
                <Icon width={32} height={32} src={IconWarning} />
                <p>Some network ACLs cannot be deleted</p>
              </div>
              <div className="delete-description">
                {`${willBeDeleteNetwork.length} out of ${
                  willBeDeleteNetwork.length + cannotBeDeleteNetwork.length
                } network ACLs can be deleted. To delete the remaining ${
                  cannotBeDeleteNetwork.length
                } network ACLs, first make the following
                changes. You can refresh each section when you are done.`}
              </div>
            </div>
          )}

          <div className="delete-table-container">
            <div className="title-container">
              <p className="title">Will be deleted</p>
              <p className="description">
                The following network ACLs will be deleted permanently and cannot be recovered later.
              </p>
            </div>
            <Table
              rows={willBeDeleteNetwork}
              columns={columns}
              sortOption={{
                target: deleteTablePagination.target,
                direction: deleteTablePagination.direction,
                onChangeSort: (target: string, dir: OrderDirection) => {
                  updateTablePagination('delete', 'target', target);
                  updateTablePagination('delete', 'direction', dir);
                },
              }}
            />
          </div>
          {cannotBeDeleteNetwork.length > 0 && (
            <div className="delete-table-container">
              <div className="title-container">
                <p className="title">Cannot be deleted</p>
                <p className="description">The following default network ACLs cannot be deleted.</p>
              </div>
              <Table
                rows={cannotBeDeleteNetwork}
                columns={columns}
                sortOption={{
                  target: notDeleteTablePagination.target,
                  direction: notDeleteTablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('not-delete', 'target', target);
                    updateTablePagination('not-delete', 'direction', dir);
                  },
                }}
              />
            </div>
          )}

          <div className="delete-warning">
            <p>To confirm deletion, type delete in the field.</p>
            <InputAtom
              type={'text'}
              placeholder={'delete'}
              value={payload}
              noClear={true}
              hasPrefixIcon={true}
              onChangeValue={val => setPayload(val)}
            />
          </div>
        </div>

        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" disabled={isDisableDeleteButton} onClick={() => onDelete(willBeDeleteNetwork)}>
            Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteNetworkModal;
