import './style.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import Table from 'components/v2/dataDisplay/Table';
import { getBetweenTwoDate, randomString } from 'utils/Common';
import lazyGetAwsListSigningCertificates, {
  IAwsListSigningCertificatesVariables,
} from 'graphql/queries/getAwsListSigningCertificates';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';

const SigningCertificates = (props: any) => {
  const { data = {}, cloudId, region } = props;
  const { userName } = data;

  const [isLoading, setIsLoading] = useState(false);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [getAwsListSigningCertificates] = lazyGetAwsListSigningCertificates();

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Creation time',
        field: 'creationTime',
        sort: true,
      },
      {
        label: 'Thumbprint',
        field: 'thumbprint',
        sort: true,
      },
      {
        label: 'Status',
        field: 'status',
        sort: true,
      },
    ];
  }, []);

  const handleGetAwsListSigningCertificates = (userName: string) => {
    const requestVariable: IAwsListSigningCertificatesVariables = {
      cloudId,
      region,
      request: {
        userName,
      },
    };

    const combinedVariable = variableCombineNextToken(requestVariable);
    return getAwsListSigningCertificates({ variables: combinedVariable });
  };

  const fetchData = useCallback(async () => {
    try {
      if (!userName) return;

      setIsLoading(true);
      const { data: listSigningCertificates } = await handleGetAwsListSigningCertificates(userName);

      if (!listSigningCertificates?.getAwsListSigningCertificates?.data?.[0]?.certificates) {
        setIsLoading(false);
        return;
      }

      const totalResult = listSigningCertificates?.getAwsListSigningCertificates?.data?.[0]?.certificates?.map(e => ({
        ...e,
        id: randomString(),
        creationTime: getBetweenTwoDate(e.uploadDate),
        thumbprint: '',
        status: e.status,
      }));

      setMainTblRows(totalResult || []);
      setMainTblTotal({
        totalPage: Math.ceil(totalResult.length / tablePagination.itemPerPage),
        totalElement: totalResult.length,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [tablePagination, userName, cloudId, region, mainTblRows]);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [userName, mainTblTotal, tablePagination]);

  useEffect(() => {
    fetchData();
  }, [userName]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>X.509 Signing certificates</p>

        <p>
          Use X.509 certificates to make secure SOAP-protocol requests to some AWS services. You can have a maximum of
          two X.509 certificates (active or inactive) at a time.
        </p>
      </div>
      {isLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <div className="detail-info-content">
          {!mainRowsCurrentPage.length && !isLoading ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <Table
                rows={mainRowsCurrentPage}
                columns={columns}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
                isLoading={isLoading}
                horizontalScrollable={true}
              />

              {mainRowsCurrentPage?.length && !isLoading ? (
                <div className="fleet-instance pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{mainTblTotal.totalElement}</span>
                  </p>

                  <TableManagePagination
                    ableFetchMore={false}
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page => updateTablePagination('currentPage', page)}
                    totalPage={mainTblTotal.totalPage}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SigningCertificates;
