import { useState } from 'react';
import './index.scss';
import { RowType } from '@Types/v2/Table';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import { dateFormatter } from 'utils/Formatter';
import { maskingFormatter } from 'utils/v2/Formatter';

interface IVerificationInfoDetailProps {
  data: RowType;
  convertGridIsShowing: () => void;
}

const VerificatoinInformationDetail = ({
  data,
  convertGridIsShowing,
}: IVerificationInfoDetailProps) => {

  const [hide, setHide] = useState(true)

  return (
    <div className='verification-info-detail'>
      <div className='verification-info-detail-header flex a-center'>
        <div className='back-btn' 
          onClick={convertGridIsShowing}
        />  
        {data.userId}
      </div>
      <div className='detail-contents'>
        <InfoCard
          title="Name"
          content={() => <>{data.fullName}</> } 
        />
        <InfoCard
          title="Organization"
          content={() => <>{data.organName}</> } 
        />
        <InfoCard
          title="ID"
          content={() => <>{data.userId}</> } 
        />
        <InfoCard
          title="Authentication by"
          content={() => <>{data.authTypeCode === 'password' ? '비밀번호' : '외부인증'}</> } 
        />
        <InfoCard
          title="Authentication"
          content={() => 
          <div className='auth-token-wrap flex a-center'>
            {maskingFormatter(data.authToken, hide)}
            <button 
              className={`hide-btn ${hide && 'hide'}`} 
              onClick={() => setHide(!hide)}
            />
          </div> 
          } 
        />
        <InfoCard
          title="Checked at"
          content={() => <>{dateFormatter(data.createdAt, 'datetime')}</>} 
        />
      </div>
    </div>
  )
}
export default VerificatoinInformationDetail;