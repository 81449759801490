import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { AwsIcmpTypeCodeLog, AwsPortRangeLog } from 'graphql/types/AwsNetworkAcl';

export interface NetworkAclRuleEntry {
  dryRun?: boolean;
  cidrBlock: string;
  egress: boolean;
  icmpTypeCode?: AwsIcmpTypeCodeLog;
  ipv6CidrBlock?: string;
  networkAclId: string;
  portRange?: AwsPortRangeLog;
  protocol: string;
  ruleAction: string;
  ruleNumber: number;
}
export interface INetworkAclRuleEntryVariables {
  cloudId: number;
  region: string;
  request: {
    replaceNetworkAclEntries: NetworkAclRuleEntry[];
  };
}

export interface IReplaceNetworkAclEntryResponseData {
  replaceAwsNetworkAcIEntry: IGqlResponseData<undefined>;
}

const replaceNetworkAcIEntryMutation = () => useMutation<IReplaceNetworkAclEntryResponseData, INetworkAclRuleEntryVariables>(query);
export default replaceNetworkAcIEntryMutation;