import { useMemo } from 'react';
import './styles.scss';
import { UIFreeRuleGroupType } from '../..';
import DetailItem, { ContentTypeEnum } from 'components/molecules/DetailItem';
import { AwsManagedRuleGroupVersionType } from 'graphql/types/AwsListAvailableManagedRuleGroupVersions';
import { DropdownListDataType } from 'components/molecules/Dropdown/types';

type DescriptionTablePropsType = {
  ruleGroup?: UIFreeRuleGroupType;
  versions?: Array<AwsManagedRuleGroupVersionType>;
  defaultVersion?: string;
  selectedVersion?: DropdownListDataType;
  currentVersion?: DropdownListDataType;
  setCurrentVersion?: (value: DropdownListDataType) => void;
};

const DescriptionTable = (props: DescriptionTablePropsType) => {
  const { ruleGroup, versions, defaultVersion, selectedVersion, currentVersion, setCurrentVersion } = props;
  const { productDescription, isVersioningSupported, capacity, snsTopicArn } = ruleGroup ?? {};

  const versionDropdownData = useMemo(() => {
    if (versions?.length === 0 || !defaultVersion) {
      return [];
    }

    return [
      {
        id: 0,
        name: `Default (using ${defaultVersion})`,
        value: defaultVersion ?? '',
      },
      ...(versions?.map((version, index) => ({
        id: index + 1,
        name: version?.name ?? '',
        value: version?.name ?? '',
      })) ?? []),
    ];
  }, [defaultVersion, versions]);

  return (
    <div className="description-table-container">
      <div className="description-table-column">
        <DetailItem title={'Description'} contentType={ContentTypeEnum.TEXT} contentText={productDescription} />
      </div>
      <div className="description-table-column">
        {isVersioningSupported && (
          <DetailItem
            title={'Version'}
            contentType={ContentTypeEnum.DROPDOWN}
            contentDropdown={{
              data: versionDropdownData ?? [],
              value: currentVersion ?? versionDropdownData[0],
              handleClick: value => setCurrentVersion?.(value),
            }}
          />
        )}
        <DetailItem title={'Capacity'} contentType={ContentTypeEnum.TEXT} contentText={capacity?.toString()} />
        {snsTopicArn && (
          <DetailItem
            title={'Amazon SNS topic'}
            contentType={ContentTypeEnum.TEXT}
            contentText={`Subscribe to notifications about the rule group from its provider\n${snsTopicArn}`}
          />
        )}
      </div>
    </div>
  );
};

export default DescriptionTable;
