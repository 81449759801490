import lazyGetAwsSecurityGroupRules from 'graphql/queries/getAwsSecurityGroupRules';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import DetailTable from '../../DetailTable';
import { handleGetSecurityGroupRuleTypeValue } from '../Common';

const InboundOutbound = (props: any) => {
  const { securityGroup, cloudId, region, isInbound, rightButtons } = props;
  const [getAwsSecurityGroupRules, { loading: awsSecurityGroupsLoading }] = lazyGetAwsSecurityGroupRules();

  const [tablePagination, setTablePagination] = useState({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [rows, setRows] = useState<RowType[]>([]);

  const handleHyperLinkClick = useCallback((row: RowType) => {}, []);

  const columns = useMemo((): ColumnType[] => {
    return [
      { label: 'Name', field: 'name', sort: true },
      {
        label: 'Security group rule ID',
        field: 'securityGroupRuleId',
        sort: true,
      },
      { label: 'IP version', field: 'ipVersion', sort: true },
      { label: 'Type', field: 'type', sort: true },
      { label: 'Protocol', field: 'protocol', sort: true },
      { label: 'Port range', field: 'portRange', sort: true },
      {
        label: isInbound ? 'Source' : 'Destination',
        field: 'cidr',
        sort: true,
      },
      { label: 'Description', field: 'description', sort: true },
    ];
  }, [isInbound]);

  const apiIsLoading = useMemo(() => {
    return awsSecurityGroupsLoading;
  }, [awsSecurityGroupsLoading]);

  const securityGroupId = useMemo(() => {
    return securityGroup?.groupId || '-';
  }, [securityGroup]);

  const fetchAwsSecurityGroups = useCallback(() => {
    const securityGroupRuleVariable = {
      cloudId: cloudId,
      region: region,
      request: {
        maxResults: 50,
        filters: {
          name: 'group-id',
          values: [securityGroupId],
        },
      },
    };
    getAwsSecurityGroupRules({
      variables: securityGroupRuleVariable,
    }).then(({ data }) => {
      const securityGroupBound: RowType[] = [];
      data?.getAwsSecurityGroupRules?.data?.[0]?.securityGroupRules
        ?.filter(s => (isInbound ? s.isEgress === false : s.isEgress === true))
        .forEach(item => {
          const sgRuleDataConvert = handleGetSecurityGroupRuleTypeValue(item.fromPort, item.toPort, item.ipProtocol);
          securityGroupBound.push({
            name: item.tags?.find((r: { key: string }) => r.key === 'Name')?.value,
            securityGroupRuleId: item.securityGroupRuleId,
            ipVersion: item.cidrIpv4 ? 'IPv4' : item.cidrIpv6 ? 'IPv6' : '-',
            type: sgRuleDataConvert.type,
            cidr: item.cidrIpv4 ? item.cidrIpv4 : item.cidrIpv6 ? item.cidrIpv6 : '-',
            protocol: sgRuleDataConvert.protocol,
            portRange: sgRuleDataConvert.portRange,
            description: item.description ? item.description : '-',
          });
        });
      setRows(securityGroupBound);
    });
  }, [cloudId, region, securityGroupId, isInbound]);

  const currentRowPage = useMemo((): RowType[] => {
    return orderAlphabetical(rows, tablePagination.target, tablePagination.direction);
  }, [rows, tablePagination]);

  useEffect(() => {
    fetchAwsSecurityGroups();
  }, [isInbound]);

  const onRightButtons = rightButtons
    ? [{ ...rightButtons[0], onClick: () => rightButtons?.[0].onClick(rows) }]
    : undefined;

  return (
    <Fragment>
      {apiIsLoading ? (
        <div className="storage-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="listeners-info">
            <div className="table-container">
              <DetailTable
                title={isInbound ? 'Inbound' : 'Outbond'}
                data={currentRowPage}
                columns={columns}
                hasPagination={true}
                rightButtons={onRightButtons}
              />
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default InboundOutbound;
