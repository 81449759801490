import { useEffect, useState } from 'react';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import DetailTab from '../../../DetailTab';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdLayout';
import lazyGetAwsUser, { IAwsUserVariables } from 'graphql/queries/getAwsUser';

import lazyGetAwsListAccountAliases, { IAwsListAccountAliasesVariables } from 'graphql/queries/getAwsListAccountAliases';

function ConsoleSignIn({ cloudId, region, data = {} }: any) {
  const { userName } = data;

  const [isLoading, setIsLoading] = useState(false);
  const [detailData, setDetailData] = useState<any>(undefined);

  const [getAwsListAccountAliases] = lazyGetAwsListAccountAliases();
  const [getAwsUser] = lazyGetAwsUser();

  const handleGetAwsListAccountAliases = () => {
    const requestVariable: IAwsListAccountAliasesVariables = {
      cloudId,
      region,
      request: {
        maxItems: 1000,
      },
    };
    return getAwsListAccountAliases({ variables: variableCombineNextToken(requestVariable) });
  };

  const handleGetAwsUser = (userName: string) => {
    const requestVariable: IAwsUserVariables = {
      cloudId,
      region,
      request: {
        userName,
      },
    };
    return getAwsUser({ variables: variableCombineNextToken(requestVariable) });
  };

  const getBetweenTwoDate = (value: any) => {
    if (!value) return '';

    const currentDate = new Date();
    const prevTime = new Date(value);

    // @ts-ignore
    const diffMs = currentDate - prevTime;
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
    const diffMinutes = Math.floor(diffMs / (1000 * 60 * 60 * 24) / (1000 * 60));

    if (diffDays === 0) {
      if (diffHours > 0) {
        return `${diffHours} hours ago`;
      } else if (diffMinutes > 0) {
        return `${diffMinutes} minutes ago`;
      } else {
        return 'Just now';
      }
    } else if (diffDays === 1) {
      return 'Yesterday';
    } else {
      return `${diffDays} days ago`;
    }
  };

  const handleGetDetail = async () => {
    try {
      setIsLoading(true);
      const [listAccountAliases, user] = await Promise.all([
        handleGetAwsListAccountAliases(),
        handleGetAwsUser(userName),
      ]);

      setDetailData({
        link: listAccountAliases?.data?.getAwsListAccountAliases?.data?.[0]?.accountAliases
          ?.length
          ? listAccountAliases?.data?.getAwsListAccountAliases?.data?.[0]?.accountAliases.map(
              (e: string) => `.https://${e}.signin.aws.amazon.com/console`,
            )
          : '',
        lastConsoleSignIn: getBetweenTwoDate(user?.data?.getAwsUser?.data?.[0]?.user?.passwordLastUsed),
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!userName) return;

    handleGetDetail();
  }, [userName]);

  const summary: IMgdDetailKeyValueProps[] = [
    {
      title: 'Console sign-in link',
      description: detailData?.link,
    },
    {
      title: 'Console password',
      description: '',
    },
    {
      title: `Last console sign-in`,
      description: detailData?.lastConsoleSignIn,
    },
  ];

  return <DetailTab title="Console sign-in" data={summary} isApiLoading={isLoading} />;
}

export default ConsoleSignIn;
