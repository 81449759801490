import './index.scss';
import { useState } from 'react';
import PageDetailTitle from '../../PageDetailTitle';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';
import Details from '../Common/Components/Details';
import RouteTable from '../Common/Components/RouteTable';
import NetworkACL from '../Common/Components/NetworkACL';
import CIDRReservations from '../Common/Components/CIDRReservations';
import Sharing from '../Common/Components/Sharing';
import Tags from '../Common/Components/Tags';

const detailTabs: IMgdTabProps[] = [
  { id: 'routeTable', name: 'Route table', title: 'Route table' },
  { id: 'networkACLInbound', name: 'Network ACL Inbound', title: 'Network ACL Inbound' },
  { id: 'networkACLOutbound', name: 'Network ACL Outbound', title: 'Network ACL Outbound' },
  { id: 'cidrReservations', name: 'CIDR reservations', title: 'CIDR reservations' },
  { id: 'sharing', name: 'Sharing', title: 'Sharing' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

const SubnetDetail = (props: any) => {
  const { cloudId, region, data, pageBackClick } = props;

  // @ts-ignore
  const [tabSelected, setTabSelected] = useState(detailTabs[0]);

  const renderTab = () => {
    switch (tabSelected.id) {
      case detailTabs[0].id:
        return <RouteTable cloudId={cloudId} region={region} data={data} />;

      case detailTabs[1].id:
        return <NetworkACL cloudId={cloudId} region={region} data={data} currentTab={detailTabs[1]} />;

      case detailTabs[2].id:
        return <NetworkACL cloudId={cloudId} region={region} data={data} currentTab={detailTabs[2]} />;

      case detailTabs[3].id:
        return <CIDRReservations cloudId={cloudId} region={region} data={data} />;

      case detailTabs[4].id:
        return <Sharing cloudId={cloudId} region={region} data={data} />;

      case detailTabs[5].id:
        return <Tags data={data} />;

      default:
        return null;
    }
  };

  return (
    <div className="page-detail">
      <PageDetailTitle title={`Subnet / ${data?.id}`} pageBackClick={pageBackClick} />

      <>
        <Details data={data} />

        <div className="page-detail-tab">
          <div className="detail-tab flex a-center">
            {detailTabs.map(tab => {
              return (
                <button
                  className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
                  key={tab.id}
                  data-tab={tab.id}
                  onClick={e => setTabSelected(tab)}
                >
                  <p>{tab.name}</p>
                </button>
              );
            })}
          </div>

          <div className="content-tab">{renderTab()}</div>
        </div>
      </>
    </div>
  );
};

export default SubnetDetail;
