import './index.scss';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconDeleteRed from 'assets/svgs/v3/ico_red_delete.svg';
import IconWarning from 'assets/svgs/v3/ico_warning.svg';
import { useEffect, useMemo, useState } from 'react';
import Icon from 'components/v2/atoms/Icon';
import LabelInput from 'components/v2/LabelInput';
import InputAtom from 'components/v2/atoms/InputAtom';

interface DeleteIPAddressesModalPropsType extends IBaseModalProps {
  resourceName: string;
  onSave: () => void;
}

const DeleteIPAddressesModal = (props: DeleteIPAddressesModalPropsType) => {
  const { resourceName, onSave, ...baseModalProps } = props;
  const [payload, setPayload] = useState<string>('');

  const ableDelete = useMemo(() => {
    return payload !== 'delete';
  }, [payload]);

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload('');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      {...baseModalProps}
      title={() => (
        <>
          <Icon width={32} height={32} src={IconDeleteRed} />
          <div className="title-header-modal">{`Delete IP addresses from ${resourceName}`}</div>
        </>
      )}
    >
      <div className="delete-ip-addresses-modal">
        <div className={'content-container flex col'}>
          <div className="header">
            <div className="delete-warning">
              <Icon width={32} height={32} src={IconWarning} />
              <p>Are you sure you want to delete the following IP addresses?</p>
            </div>
            <InputAtom
              placeholder={''}
              className={'input-add'}
              onChangeValue={() => {}}
              value={resourceName}
              disabled
            />
          </div>
          <LabelInput
            title="To confirm deletions, write “delete” in this field."
            placeholder="delete"
            value={payload}
            onChangeValue={val => setPayload(val)}
          />
        </div>
        <div className="button-group">
          <button className="big-sub-btn" onClick={baseModalProps.onClose}>
            Cancel
          </button>
          <button className="big-main-btn" disabled={ableDelete} onClick={onSave}>
            Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
};
export default DeleteIPAddressesModal;
