import { useCallback, useEffect, useMemo, useState } from 'react';
import { AccessAdvisorPropsType } from '../types';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import lazyGetAwsGenerateServiceLastAccessedDetails, {
  IAwsGenerateServiceLastAccessedDetailsVariables,
} from 'graphql/queries/getAwsGenerateServiceLastAccessedDetails';
import lazyGetAwsServiceLastAccessedDetails, {
  IAwsServiceLastAccessedDetailsVariables,
} from 'graphql/queries/getAwsServiceLastAccessedDetails';
import { getDateTimeBetweenTwoDate } from 'utils/Common';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import Table from 'components/v2/dataDisplay/Table';
import lazyGetAwsListPoliciesGrantingServiceAccess, {
  IAwsListPoliciesGrantingServiceAccessVariables,
} from 'graphql/queries/getAwsListPoliciesGrantingServiceAccess';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';

const AccessAdvisor = (props: AccessAdvisorPropsType) => {
  const { cloudId, region, role } = props;

  // const [isLoading, setIsLoading] = useState(false);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [getAwsGenerateServiceLastAccessedDetails, { loading: generateSeriveLoading }] =
    lazyGetAwsGenerateServiceLastAccessedDetails();
  const [getAwsServiceLastAccessedDetails, { loading: serviceLoading }] = lazyGetAwsServiceLastAccessedDetails();
  const [getAwsListPoliciesGrantingServiceAccess, { loading: policiesGrantLoading }] =
    lazyGetAwsListPoliciesGrantingServiceAccess();

  const isApiLoading = useMemo((): boolean => {
    return generateSeriveLoading || serviceLoading || policiesGrantLoading;
  }, [generateSeriveLoading, serviceLoading, policiesGrantLoading]);

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Service',
        field: 'service',
        sort: true,
      },
      {
        label: 'Policies granting permissions',
        field: 'policiesGrantPermission',
        sort: true,
      },
      {
        label: 'Last accessed',
        field: 'lastAccessed',
        sort: true,
      },
    ];
  }, []);

  const handleGetAwsListGroupsForUser = useCallback(
    (arn: string) => {
      const requestVariable: IAwsGenerateServiceLastAccessedDetailsVariables = {
        cloudId: cloudId,
        region: region,
        request: {
          arn,
        },
      };
      return getAwsGenerateServiceLastAccessedDetails({ variables: requestVariable });
    },
    [cloudId, region],
  );

  const handleGetAwsServiceLastAccessedDetails = useCallback(
    (jobId: string) => {
      const requestVariable: IAwsServiceLastAccessedDetailsVariables = {
        cloudId: cloudId,
        region: region,
        request: {
          jobId,
        },
      };
      return getAwsServiceLastAccessedDetails({ variables: requestVariable });
    },
    [cloudId, region],
  );

  const handleGetAwsServiceLastAccessedDetailsServiceName = useCallback(
    (arn: string, serviceNamespace: string) => {
      const requestVariable: IAwsListPoliciesGrantingServiceAccessVariables = {
        cloudId: cloudId,
        region: region,
        request: {
          arn,
          serviceNamespaces: serviceNamespace,
        },
      };
      return getAwsListPoliciesGrantingServiceAccess({ variables: requestVariable });
    },
    [cloudId, region],
  );

  const fetchData = useCallback(async () => {
    try {
      if (!role?.arn) return;

      const result = await handleGetAwsListGroupsForUser(role.arn);

      if (!result?.data?.getAwsGenerateServiceLastAccessedDetails?.data?.length) {
        return;
      }

      const totalResult: RowType[] = [];

      result.data.getAwsGenerateServiceLastAccessedDetails.data.forEach(async e => {
        let jobStatus;
        let servicesLastAccessed;
        do {
          const serviceLastAccessedDetailsResponse = await handleGetAwsServiceLastAccessedDetails(e.jobId);
          const serviceLastAccessedDetailsData =
            serviceLastAccessedDetailsResponse?.data?.getAwsServiceLastAccessedDetails?.data?.[0];
          jobStatus = serviceLastAccessedDetailsData?.jobStatus;
          servicesLastAccessed = serviceLastAccessedDetailsData?.servicesLastAccessed;
        } while (jobStatus != 'COMPLETED');

        if (servicesLastAccessed && servicesLastAccessed.length > 0) {
          for (let i = 0; i < servicesLastAccessed.length; i++) {
            const servicesLastAccessedItem = servicesLastAccessed[i];
            const serviceLastAccessedDetailsServiceName = await handleGetAwsServiceLastAccessedDetailsServiceName(
              role.arn,
              servicesLastAccessedItem.serviceNamespace,
            );

            const item = {
              service: servicesLastAccessedItem.serviceName,
              lastAccessed: getDateTimeBetweenTwoDate(servicesLastAccessedItem.lastAuthenticated),
              policiesGrantPermission:
                serviceLastAccessedDetailsServiceName?.data?.getAwsListPoliciesGrantingServiceAccess?.data?.[0]
                  ?.policiesGrantingServiceAccess?.[0]?.serviceNamespace,
            };

            totalResult.push(item);
          }

          setMainTblRows(totalResult);
          setMainTblTotal({
            totalPage: Math.ceil(totalResult.length / tablePagination.itemPerPage),
            totalElement: totalResult.length,
          });
        }
      });
    } catch (error) {}
  }, [tablePagination, role, cloudId, region, mainTblRows]);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotal, tablePagination, mainTblRows]);

  useEffect(() => {
    fetchData();
  }, [role]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>Allowed services</p>

        <p>IAM reports activity for services and management actions.</p>
      </div>
      {isApiLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <div className="detail-info-content">
          {!mainRowsCurrentPage.length && !isApiLoading ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <Table
                rows={mainRowsCurrentPage}
                columns={columns}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
                isLoading={isApiLoading}
                horizontalScrollable={true}
              />

              {mainRowsCurrentPage?.length && !isApiLoading ? (
                <div className="fleet-instance pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{mainTblTotal.totalElement}</span>
                  </p>
                  <TableManagePagination
                    ableFetchMore={false}
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page =>
                      setMainTablePagination(prev => ({
                        ...prev,
                        ['currentPage']: page,
                      }))
                    }
                    totalPage={mainTblTotal.totalPage}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AccessAdvisor;
