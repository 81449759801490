import Icon from 'components/v2/atoms/Icon';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconEditRequest from 'assets/svgs/v3/ico_edit_vpc_subnets.svg';
import { useEffect, useState } from 'react';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';
import './index.scss';

interface AddIPAddressesModalPropsType extends IBaseModalProps {
  header: string;
  onSave: (ipAddreses: string) => void;
}

const AddIPAddressesModal = (props: AddIPAddressesModalPropsType) => {
  const { header, onSave, ...baseModalProps } = props;
  const [ipAddreses, setIPAddreses] = useState<string>('');

  useEffect(() => {
    if (baseModalProps.open) {
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditRequest} />
          <div className="title-header-modal">{header}</div>
        </>
      )}
      {...baseModalProps}
    >
      <div className="add-ip-addresses-model">
        <div className="content-container">
          <div className="title">
            <p>IP addresses</p>
          </div>
          <div className="input-container">
            <TextareaAtom
              placeholder={'10.0.0.0/32'}
              className={'input-add'}
              onChangeValue={setIPAddreses}
              value={ipAddreses}
              required={true}
              noClear={false}
            />
            <p className="text-note">Enter one IP address per line in CIDR format</p>
          </div>
        </div>

        <div className="button-group">
          <button className="btn" onClick={baseModalProps.onClose}>
            Cancel
          </button>
          <button className="btn save-btn" onClick={() => onSave(ipAddreses)} disabled={ipAddreses.trim() === ''}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default AddIPAddressesModal;
