import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import Labeled from 'components/v2/atoms/Labeled';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import SearchDropdown from 'components/v2/SearchDropdown';
import LabelTextarea from 'components/v2/LabelTextarea';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import searchItems from 'graphql/queries/searchItems';
import addDictionary from 'graphql/mutations/addDictionary';
import { enumFormatter } from 'utils/Formatter';

interface ICreateDictionaryModalProps extends IBaseModalProps {
  title: () => JSX.Element;
  onCreateSuccess: () => void;
  relObjTypeList: {
    name: string;
    value: string;
  }[];
}

const CreateDictionaryModal = ({
  title,
  relObjTypeList,
  onCreateSuccess,
  ...baseModalProps
}:ICreateDictionaryModalProps) => {

  const [payload, setPayload] = useState({
    relObjectType: '', /* 사용 종류 */
    relObjectId: -1, /* 아이템id */
    defaultName: '', /* 기준 값 */
    domainName: '' /* 설정값 */
  });
  const [searchForItems,  setSearchForItems] = useState('');
  const [itemList, setItemList] = useState<{name: string; value: number}[]>([]);
  const [confirmItem, setConfirmItem] = useState(false);
  
  /* 딕셔너리 추가 */
  const [createDictionary] = addDictionary();
  /* 검색어에 따른 아이템 리스트 */
  const [searchItemList] = searchItems();

  const enableButton = useMemo(() => {
    if (
      payload.relObjectType === '' ||
      payload.relObjectId === -1 ||
      payload.defaultName === '' ||
      payload.domainName === ''
    ) {
      return true;
    } else return false;
  }, [payload]);

  const getItemList = (str:string) => {
    const searchItemData = {
      reqData: {
        relObjectType: payload.relObjectType,
        text: str
      }
    }

    searchItemList({ variables: searchItemData }).then(({ data }) => {
      if (data) {
        setItemList(data.searchItems.data.map(val => ({
          name: val.name,
          value: val.id
        })));
      }
    }); 
  }

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload({
        relObjectType: '', 
        relObjectId: -1, 
        defaultName: '', 
        domainName: '' 
      });
      setSearchForItems('');
      setConfirmItem(false);
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal 
      {...baseModalProps}
      title={title}
    >
      <div className="create-dictionary-modal">
        <div className="contents-wrap">
          <Labeled
            title="Use type"
            required={true}
          >
            <DropdownAtom 
              id="rel-object-type" 
              placeholder='Select use type'
              data={relObjTypeList.map(val => ({ name: enumFormatter(val.value), value: val.value }))} 
              value={{
              name: relObjTypeList.find(val => val.value === payload.relObjectType)
                ? enumFormatter(String(relObjTypeList.find(val => val.value === payload.relObjectType)?.value))
                : '',
              value: payload.relObjectType
              }}
              handleClick={(val) => {
                setPayload(prev => ({
                  ...prev,
                  relObjectType: String(val.value),
                  relObjectId: -1
                }))
                setSearchForItems('');
                setConfirmItem(false);
              }}
            />
          </Labeled>
          <Labeled 
            title="Item"
            required
          >
            <SearchDropdown 
              disabled={payload.relObjectType === ''}
              id="search-item-list"
              placeholder='Select item'
              data={itemList}
              searchValue={searchForItems}
              isConfirmed={confirmItem}
              onChangeSearch={(str) => {
                setSearchForItems(str);
                getItemList(str);
              }}
              handleClick={(val) => {
                setPayload(prev => ({
                  ...prev,
                  relObjectId: Number(val.value)
                }));
                setSearchForItems(String(val.name));
                getItemList(String(val.name));
                setConfirmItem(true);
              }}
              resetIsConfirm={() => {
                setConfirmItem(false);
                setPayload(prev => ({
                  ...prev,
                  relObjectId: -1
                }));
                getItemList('');
              }}
            />
          </Labeled>
          <LabelTextarea 
            title="Reference value"
            required
            value={payload.defaultName}
            placeholder='Input reference value'
            onChangeValue={(val) => {
              setPayload((prev) => ({
                ...prev,
                defaultName: val
              }))
            }}
          />
          <LabelTextarea 
            title="Setting value"
            required
            value={payload.domainName}
            placeholder='Input setting value'
            onChangeValue={(val) => {
              setPayload((prev) => ({
                ...prev,
                domainName: val
              }))
            }}
          />
        </div>
        <div className="btns">
          <button 
            className="big-main-btn flex j-center a-center"  
            disabled={enableButton}
            onClick={() => {
              const addDictionaryData = { reqData: { ...payload } };

              createDictionary({ variables: addDictionaryData }).then(({ data }) => {
                if (data) {
                  if (data.addDictionary.result === ErrorCode.SUCCESS) {
                    useToast(ErrorCode.SUCCESS, 'Dictionary is created successfully.');
                    onCreateSuccess();
                  } else {
                    console.log(data.addDictionary.result);
                    useToast(ErrorCode.UNKNOWN, 'Create dictionary failed.');
                  }
                } else {
                  useToast(ErrorCode.UNKNOWN, 'Create dictionary failed.');
                }
              });
            }}
          >
            Create
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default CreateDictionaryModal;
