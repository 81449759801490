export const CREATE_WEB_ACL_STEPS = [
  { id: 'describeWebAcl', title: 'Step 1. Describe web ACL and associate it to AWS resources' },
  {
    id: 'addRulesAndRuleGroups',
    title: 'Step 2. Add rules and rule groups',
    description:
      'A rule defines attach patterns to look for in web requests and the action to take when a request matches the patterns. Rule groups are reusable collections of rules. You can use managed rule groups offered by AWS and AWS Marketplace sellers. You can also write your own rules and use your own rule groups.',
  },
  { id: 'setRulePriority', title: 'Step 3. Set rule priority' },
  { id: 'configureMetrics', title: 'Step 4. Configure metrics' },
  { id: 'review', title: 'Create web ACL: Review' },
];

export const ASSOCIATED_AWS_RESOURCES_COLUMN_LIST = [
  { field: 'name', label: 'Name', sort: true },
  { field: 'resourceType', label: 'Resource type', sort: true },
  { field: 'region', label: 'Region', sort: true },
];

export const RULES_COLUMN_LIST = [
  { field: 'name', label: 'Name', sort: true },
  { field: 'capacity', label: 'Capacity', sort: true },
  { field: 'action', label: 'Action', sort: true },
];

export const METRICS_COLUMN_LIST = [
  { field: 'rules', label: 'Rules', sort: true },
  { field: 'cloudWatchMetricName', label: 'CloudWatch metric name', sort: true },
];

export const DEFAULT_ACTION_RADIO_LIST = [
  { id: 'allow', name: 'allow', label: 'Allow', value: 'allow' },
  { id: 'block', name: 'block', label: 'Block', value: 'block' },
];

export const REQUEST_SAMPLING_OPTIONS_RADIO_LIST = [
  {
    id: 'enableSampledRequests',
    name: 'enableSampledRequests',
    label: 'Enable sampled requests',
    value: 'enableSampledRequests',
  },
  {
    id: 'disableSampledRequests',
    name: 'disableSampledRequests',
    label: 'Disable sampled requests',
    value: 'disableSampledRequests',
  },
  {
    id: 'enableSampledRequestsWithExclusions',
    name: 'enableSampledRequestsWithExclusions',
    label: 'Enable sampled requests with exclusions',
    value: 'enableSampledRequestsWithExclusions',
  },
];

export const RULES_EXCLUSION_COLUMN_LIST = [{ field: 'name', label: 'Name ', sort: true }];

export enum DefaultActionEnum {
  ALLOW = 'allow',
  BLOCK = 'block',
}

export const REQUEST_SAMPLING_ACTION_RADIO_LIST = [
  { id: 'enable', name: 'enable', label: 'Enable', value: 'enable' },
  { id: 'disable', name: 'disable', label: 'Disable', value: 'disable' },
];
