import { DropdownListDataType } from "components/Dropdown/types";

export const SECURITY_GROUP_SUMMARY_TAB_LIST = [
  {id: 'details', name: 'Details', title: 'Details'},
  {id: 'inbound', name: 'Inbound', title: 'Inbound'},
  {id: 'outbound', name: 'Outbound', title: 'Outbound'},
  {id: 'tags', name: 'Tags', title: 'Tags'}
];

export const DETAIL_TAB_LIST = [
  {id: 'inbound', name: 'Inbound', title: 'Inbound'},
  {id: 'outbound', name: 'Outbound', title: 'Outbound'},
  {id: 'tags', name: 'Tags', title: 'Tags'}
];

export const SECURITY_GROUP_FILTER_DROPDOWN: DropdownListDataType[] = [
  {id: 1, name: 'Security group ID', value: 'group-id'},
  {id: 2, name: 'Name', value: 'tag:Name'},
  {id: 3, name: 'Security group name', value: 'group-name'},
  {id: 4, name: 'VPC ID', value: 'vpc-id'}
];
